// NOTE: If we later change the algorithm we can increment the number beind
// algo, and we can ignore/remove the previous values.
import { defineNuxtPlugin } from 'nuxt/app';
import { useMediaHelperStore } from '~~/common/stores/mediaHelper';

const COOKIE_NAME_WEBP_SUPPORT = `isWebpFormatSupportedAlgo${0/* algorithm version */}`;
export default defineNuxtPlugin(function (nuxtApp) {
  const { $cookies, $logger, $pinia } = nuxtApp;
  // NOTE: initial value is used both during SSR and as a fallback in case
  // of an unexected err.
  let isWebpSupportedInBrowser = true;
  const mediaHelperStore = useMediaHelperStore($pinia);
  try {
    const previousRunResult = $cookies.get(COOKIE_NAME_WEBP_SUPPORT);
    if (previousRunResult !== undefined) {
      isWebpSupportedInBrowser = previousRunResult;
    } else if (typeof document !== 'undefined') {
      isWebpSupportedInBrowser = (function () {
        try {
          const c = document.createElement('canvas');
          c.width = 1;
          c.height = 1;
          return c.toDataURL('image/webp').startsWith('data:image/webp');
        } catch (err) {
          $logger.error(err);
          return false;
        }
      })();
      // NOTE: maxAge is added, because web suport may change for the user in the future (Browser/OS update).
      $cookies.set(COOKIE_NAME_WEBP_SUPPORT, isWebpSupportedInBrowser, { path: '/', maxAge: 7 * 24 * 60 * 60 });
    }
  } catch (err) {
    $logger.error(err);
  }
  // WARNING: setTimeout-ing mediaHelperStore.setIsWebpSupportedInBrowser
  // mutation is necessary on clientSide and it can not be set before
  // the set timeout on the clientSide.
  //
  // Test Case Example:
  // ```
  // <b :data-a="mediaHelperStore.isWebpSupportedInBrowser.toString()"> <!-- attribute is NOT reactive -->
  //   {{ mediaHelperStore.isWebpSupportedInBrowser }} <!-- string interpolation is reactive -->
  // </b>
  // <b :data-a="mediaHelperStore.isWebpSupportedInBrowser.toString()" v-text="mediaHelperStore.isWebpSupportedInBrowser" /> <!-- both attribute and v-text directive are reactive -->
  // ```
  if (import.meta.client) {
    setTimeout(() => {
      mediaHelperStore.setIsWebpSupportedInBrowser(isWebpSupportedInBrowser);
    });
  } else {
    mediaHelperStore.setIsWebpSupportedInBrowser(isWebpSupportedInBrowser);
  }

  nuxtApp.provide('addWebpPostfixIfSupported', ({ url, webpUrl }) => {
    return (typeof url === 'string' && url && mediaHelperStore.isWebpSupportedInBrowser && !url.endsWith('.webp')) ? webpUrl : url;
  });
});
