<template>
  <p :class="[$style.paragraph, $style[alignment], $style[`gutter-${gutter}`], $style[`size-${size}`], {[$style.leading] : leading}, {[$style.trailing] : trailing}]">
    <slot />
  </p>
</template>

<script>
  const validAlignment = ['left', 'center', 'right'];
  const validSize = ['sm', 'md', 'lg'];
  const validGutter = ['sm', 'md', 'lg', 'xl'];

  export default {
    name: 'LvParagraph',
    props: {
      size: {
        type: String,
        required: false,
        default: undefined,
        validator: size => validSize.includes(size),
      },
      gutter: {
        type: String,
        required: false,
        default: undefined,
        validator: gutter => validGutter.includes(gutter),
      },
      alignment: {
        type: String,
        required: false,
        default: undefined,
        validator: alignment => validAlignment.includes(alignment),
      },
      leading: {
        type: Boolean,
        default: false,
      },
      trailing: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
$paragraph-sm-font-size: 12px !default;
$paragraph-sm-line-height: 14px !default;

$paragraph-md-font-size: 14px !default;
$paragraph-md-line-height: 18px !default;

$paragraph-lg-font-size: 16px !default;
$paragraph-lg-line-height: 19px !default;

.paragraph {
  &.size-sm {
    font-size: $paragraph-sm-font-size;
    line-height: $paragraph-sm-line-height;
  }

  &.size-md {
    font-size: $paragraph-md-font-size;
    line-height: $paragraph-md-line-height;
  }

  &.size-lg {
    font-size: $paragraph-lg-font-size;
    line-height: $paragraph-lg-line-height;
  }

  &.gutter-sm {
    @include lg() {
      margin: $gutter-size-sm 0;
    }

    margin: $gutter-size-xs 0;
  }

  &.gutter-md {
    @include md() {
      margin: $gutter-size-sm 0;
    }

    @include lg() {
      margin: $gutter-size-md 0;
    }

    margin: $gutter-size-xs 0;
  }

  &.gutter-lg {
    @include md() {
      margin: $gutter-size-md 0;
    }

    @include lg() {
      margin: $gutter-size-lg 0;
    }

    margin: $gutter-size-sm 0;
  }

  &.gutter-xl {
    @include md() {
      margin: $gutter-size-lg 0;
    }

    @include lg() {
      margin: $gutter-size-xl 0;
    }

    margin: $gutter-size-md 0;
  }

  &.leading {
    margin-bottom: 0;
  }

  &.trailing {
    margin-top: 0;
  }

  &.center {
    text-align: center;
  }

  &.right {
    text-align: right;
  }

  &.left {
    text-align: left;
  }
}
</style>
