<template>
  <component :is="tag" :class="[typeClasses, $style[`gutter${gutter}`], $style[`wrap${wrap}`], $style[`alignItems${alignItems}`], $style[`alignContent${alignContent}`], $style[`alignSelf${alignSelf}`], $style[`justifyItems${justifyItems}`], $style[`justifyContent${justifyContent}`], $style[`justifySelf${justifySelf}`], $style[`xs${xs}`], $style[`sm${sm}`], $style[`md${md}`], $style[`lg${lg}`], $style[`xl${xl}`]]">
    <slot />
  </component>
</template>

<script>
  // import StyleVariables from '~~/common/assets/styles/_variables.scss';

  function sizeValidator (size) {
    if (size === 'min' || size === 'expand') {
      return true;
    }
    // todo fix this
    const delimeter = '/';// StyleVariables.gridWidthsDelimiter.replace(/\\/g, '');
    const ratio = size.split(delimeter);
    if (ratio.length !== 2) {
      return false;
    }
    const numerator = parseInt(ratio[0]);
    const denominator = parseInt(ratio[1]);
    const gridCount = parseInt(16);// StyleVariables.gridCount);
    if (denominator > gridCount) {
      return false;
    }
    if (numerator > denominator) {
      return false;
    }
    return (numerator !== denominator || denominator === 1) && (numerator % 2 !== 0 || denominator % 2 !== 0) && (numerator % 3 !== 0 || denominator % 3 !== 0) && (numerator % 5 !== 0 || denominator % 5 !== 0);
  }

  const validSelfAligns = ['start', 'baseline', 'center', 'stretch', 'end'];

  const validItemAligns = ['start', 'baseline', 'center', 'stretch', 'end'];

  const validContentAligns = ['start', 'center', 'stretch', 'space-between', 'space-around', 'end'];

  const validSelfJustifies = ['start', 'baseline', 'center', 'stretch', 'end'];

  const validItemJustifies = ['start', 'baseline', 'center', 'stretch', 'end'];

  const validContentJustifies = ['start', 'center', 'space-between', 'space-around', 'space-evenly', 'end'];

  const validWraps = ['wrap', 'nowrap', 'wrap-reverse'];

  const validGutters = ['none', 'xs', 'sm', 'md', 'lg', 'xl'];

  export default {
    name: 'LvGrid',
    props: {
      type: {
        type: String,
        required: true,
      },
      gutter: {
        type: String,
        default: 'sm',
        validator: gutter => validGutters.includes(gutter),
      },
      wrap: {
        type: String,
        default: undefined,
        validator: wrap => validWraps.includes(wrap),
      },
      alignItems: {
        type: String,
        default: undefined,
        validator: alignItems => validItemAligns.includes(alignItems),
      },
      alignContent: {
        type: String,
        default: undefined,
        validator: alignContent => validContentAligns.includes(alignContent),
      },
      alignSelf: {
        type: String,
        default: undefined,
        validator: alignSelf => validSelfAligns.includes(alignSelf),
      },
      justifyItems: {
        type: String,
        default: undefined,
        validator: justifyItems => validItemJustifies.includes(justifyItems),
      },
      justifyContent: {
        type: String,
        default: undefined,
        validator: justifyContent => validContentJustifies.includes(justifyContent),
      },
      justifySelf: {
        type: String,
        default: undefined,
        validator: justifySelf => validSelfJustifies.includes(justifySelf),
      },
      xs: {
        type: String,
        default: undefined,
        validator: sizeValidator,
      },
      sm: {
        type: String,
        default: undefined,
        validator: sizeValidator,
      },
      md: {
        type: String,
        default: undefined,
        validator: sizeValidator,
      },
      lg: {
        type: String,
        default: undefined,
        validator: sizeValidator,
      },
      xl: {
        type: String,
        default: undefined,
        validator: sizeValidator,
      },
      tag: {
        type: String,
        default: 'div',
      },
    },
    computed: {
      typeClasses () {
        const classes = this.type.split(/\s+/);
        if (classes.includes('row') || classes.includes('column')) {
          classes.push('gridParent');
        }
        return classes.map(type => this.$style[type]).join(' ');
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
.row {
  flex-direction: row;
}

.column {
  flex-direction: column;
}

.gridParent {
  display: flex;

  > .item {
    min-width: 1px;
  }

  &.wrap {
    &nowrap {
      flex-wrap: nowrap;
    }

    &wrap {
      flex-wrap: wrap;
    }

    &wrap-reverse {
      flex-wrap: wrap-reverse;
    }
  }

  &.gutter {
    &none {
      margin: 0;

      > .item {
        padding: 0;
      }
    }

    &xs {
      margin: 0 $gutter-size-xs * -1;

      > .item {
        padding: 0 $gutter-size-xs;
      }
    }

    &sm {
      margin: 0 $gutter-size-sm * -1;

      > .item {
        padding: 0 $gutter-size-sm;
      }
    }

    &md {
      margin: 0 $gutter-size-md * -1;

      > .item {
        padding: 0 $gutter-size-md;
      }
    }

    &lg {
      margin: 0 $gutter-size-lg * -1;

      > .item {
        padding: 0 $gutter-size-lg;
      }
    }

    &xl {
      margin: 0 $gutter-size-xl * -1;

      > .item {
        padding: 0 $gutter-size-xl;
      }
    }
  }

  /* region Alignment */
  &.alignItems {
    &start {
      align-items: start;
    }

    &baseline {
      align-items: baseline;
    }

    &center {
      align-items: center;
    }

    &stretch {
      align-items: stretch;
    }

    &end {
      align-items: end;
    }
  }

  &.alignContent {
    &start {
      align-content: start;
    }

    &center {
      align-content: center;
    }

    &stretch {
      align-content: stretch;
    }

    &space-between {
      align-content: space-between;
    }

    &space-around {
      align-content: space-around;
    }

    &end {
      align-content: end;
    }
  }

  &.justifyItems {
    &start {
      justify-items: start;
    }

    &baseline {
      justify-items: baseline;
    }

    &center {
      justify-items: center;
    }

    &stretch {
      justify-items: stretch;
    }

    &end {
      justify-items: end;
    }
  }

  &.justifyContent {
    &start {
      justify-content: start;
    }

    &center {
      justify-content: center;
    }

    &space-between {
      justify-content: space-between;
    }

    &space-around {
      justify-content: space-around;
    }

    &space-evenly {
      justify-content: space-evenly;
    }

    &end {
      justify-content: flex-end;
    }
  }

  /* endregion */
  .item {

    /* region Alignment */
    &.alignSelf {
      &start {
        align-self: start;
      }

      &baseline {
        align-self: baseline;
      }

      &center {
        align-self: center;
      }

      &stretch {
        align-self: stretch;
      }

      &end {
        align-self: end;
      }

    }

    &.justifySelf {
      &start {
        justify-self: start;
      }

      &baseline {
        justify-self: baseline;
      }

      &center {
        justify-self: center;
      }

      &stretch {
        justify-self: stretch;
      }

      &end {
        justify-self: end;
      }
    }

    /* endregion */

    /* region Size */
    @include grid-items();

    @include sm() {
      &.smmin {
        flex: 0 1 auto;
      }

      &.smexpand {
        flex: 1 1 0;
      }
    }

    @include md() {
      &.mdmin {
        flex: 0 1 auto;
      }

      &.mdexpand {
        flex: 1 1 0;
      }
    }

    @include lg() {
      &.lgmin {
        flex: 0 1 auto;
      }

      &.lgexpand {
        flex: 1 1 0;
      }
    }

    @include lg() {
      &.xlmin {
        flex: 0 1 auto;
      }

      &.xlexpand {
        flex: 1 1 0;
      }
    }

    &.xsmin {
      flex: 0 1 auto;
    }

    &.xsexpand {
      flex: 1 1 0;
    }

    /* endregion */
  }
}
</style>
