<template>
  <div
    :class="[$style.wrapper, {[$style.card]: card, [$style.hasPadding]: hasPadding, [$style.smallCardPadding]: smallCardPadding, [$style.extraSmallCardPadding]: extraSmallCardPadding}]"
  >
    <div v-if="sectionTitle" :class="[$style.title, {[$style.hasTitlePadding]: hasTitlePadding}]">
      <lv-heading level="2" tag="h2" version2>
        {{ sectionTitle }}
      </lv-heading>
    </div>
    <div :class="[$style.content]">
      <aw-spinner
        v-if="isLoading"
        color="#433F32"
        :class="$style.contentLoading"
        width-with-unit="4rem"
        height-with-unit="4rem"
      />
      <slot name="content" :content-events="{ contentLoading }" />
    </div>
  </div>
</template>

<script>
  import { defineAsyncComponent } from 'vue';
  import { LvHeading } from '~~/common/components/loginet-vue-shop/index.mjs';

  export default {
    name: 'AwSectionWrapper',
    components: {
      LvHeading,
      AwSpinner: defineAsyncComponent(() => import('~~/common/components/Common/AwSpinner')),
    },
    props: {
      sectionTitle: {
        type: String,
        default: null,
      },
      hasPadding: {
        type: Boolean,
        default: true,
      },
      hasTitlePadding: {
        type: Boolean,
        default: true,
      },
      card: {
        type: Boolean,
        default: false,
      },
      preloader: {
        type: Boolean,
        default: false,
      },
      smallCardPadding: {
        type: Boolean,
        default: false,
      },
      extraSmallCardPadding: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        isLoading: this.preloader,
      };
    },
    methods: {
      contentLoading (isLoading) {
        this.isLoading = isLoading;
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
.wrapper {
  .title {
    padding: 0 16px 20px;

    @include tablet(min) {
      padding: 0 16px 40px;
    }

    &.hasTitlePadding {
      padding: 0 0 40px;
    }
  }

  &.card {
    .content {

      margin-bottom: 40px;
      padding: 24px 16px;
      border-radius: 24px;
      background-color: $color-background-3;
      box-shadow: 0 16px 32px rgba(67, 63, 50, 0.04);
      @include tablet(min) {
        margin-bottom: 80px;
        padding: 40px;
      }

      &Loading {
        border-radius: 24px;
        background: $color-background-3--50;
      }
    }

    &.smallCardPadding {
      .content {
        margin-bottom: 20px;
        padding: 20px 16px;
        @include tablet(min) {
          margin-bottom: 40px;
          padding: 40px;
        }
      }
    }

    &.extraSmallCardPadding {
      .content {
        margin-bottom: 0;
        padding: 20px 16px;

        @include tablet(min) {
          padding: 24px;
        }
      }
    }
  }

  &:not(.card) {
    &.hasPadding {
      padding-bottom: 20px;
      @include tablet(min) {
        padding-bottom: 40px;
      }
    }

    .content {
      margin-bottom: 0;
      @include tablet(min) {
        margin-bottom: 0;
      }
    }

  }

  .content {
    position: relative;
    z-index: 0;
    margin-bottom: 20px;
    isolation: isolate;

    @include tablet(min) {
      margin-bottom: 40px;
    }

    &Loading {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      background: $color-background-1--50;
    }
  }
}
</style>
