<script>
  import { mapState } from 'pinia';
  import { h, defineAsyncComponent, resolveComponent } from 'vue';
  import { useUserInterfaceStore } from '~~/common/stores/userInterface';

  const AwSpinner = defineAsyncComponent(() => import('~~/common/components/Common/AwSpinner'));

  const validType = ['submit', 'button', 'reset'];
  const whiteLoading = new Set(['primary', 'primary-just-icon', 'primary-small', 'primary-dark', 'primary-dark-just-icon', 'small-primary-dark', 'small-primary-dark-just-icon']);
  const darkLoading = new Set(['secondary-dark', 'small-secondary-dark-just-icon', 'primary-light', 'primary-light-just-icon']);

  export default {
    name: 'LvButton',
    components: {
      AwSpinner,
    },
    props: {
      tag: {
        type: String,
        default: '',
      },
      type: {
        type: String,
        default: 'button',
        validator: type => validType.includes(type),
      },
      styling: {
        type: String,
        default: 'primary',
      },
      shrink: {
        type: Boolean,
        default: false,
      },
      stretch: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      disabledStyle: {
        type: Boolean,
        default: null,
      },
      to: {
        type: [String, Boolean],
        default: null,
      },
      target: {
        type: String,
        default: null,
      },
      hasIcon: {
        type: [Boolean, String],
        default: false,
      },
      justIcon: {
        type: [Boolean, String],
        default: false,
      },
      centerIcon: {
        type: Boolean,
        default: false,
      },
      loading: {
        type: Boolean,
        default: false,
      },
      uppercase: {
        type: Boolean,
        default: false,
      },
      radiusOff: {
        type: Boolean,
        default: false,
      },
      darker: {
        type: Boolean,
        default: false,
      },
      loadingId: {
        type: [String, Array],
        default: 'lv-button-default-id',
      },
      loadingStyle: {
        type: String,
        default: 'spinner',
        validator: style => ['spinner', 'disable'].includes(style),
      },
      loadingSpinnerAttributes: {
        type: Object,
        default: () => ({}),
      },
      round: {
        type: String,
        default: 'none',
        validator: val => ['none', 'xs-min', 'xs-max', 'sm-min', 'sm-max', 'md-min', 'md-max'].includes(val),
      },
      spinnerColor: {
        type: String,
        default: '',
      },
    },
    computed: {
      getSpinnerColor () {
        if (this.spinnerColor) {
          return this.spinnerColor;
        } else if (whiteLoading.has(this.styling)) {
          return 'white';
        } else if (darkLoading.has(this.styling)) {
          return '#433F32';
        } else {
          return null;
        }
      },
      ...mapState(useUserInterfaceStore, {
        apiIsLoading (state) {
          if (Array.isArray(this.loadingId)) {
            const lids = new Set(this.loadingId);
            return state.loading.some(lid => lids.has(lid));
          } else {
            return state.loading.includes(this.loadingId);
          }
        },
      }),
      calcType () {
        return this.calcTag === 'button' ? this.type : null;
      },
      calcDisabled () {
        if (this.to || this.$attrs.href) {
          if (this.disabled) {
            this.$logger.warn('hyperlink cannot be disabled');
          }
          return null;
        }
        if (this.apiIsLoading && this.loadingStyle === 'disable') {
          return true;
        }
        return this.disabled;
      },
      calcTag () {
        if (this.tag) {
          return this.tag;
        }
        if (this.to) {
          return resolveComponent('nuxt-link');
        }

        return this.$attrs.href ? 'a' : 'button';
      },
    },
    render () {
      const { $style } = this;
      return h(
        this.calcTag,
        {
          to: this.to || null,
          type: this.calcType,
          target: this.target,
          disabled: this.calcDisabled,
          'aria-disabled': (this.calcDisabled ? false : this.loading) || null,
          ...this.$attrs,
          class: [
            $style.button,
            $style[this.styling],
            {
              [$style.block]: this.stretch,
              [$style.shrink]: this.shrink,
              [$style.loading]: this.loading,
              [$style.uppercase]: this.uppercase,
              [$style.radiusOff]: this.radiusOff,
              [$style.disabled]: this.disabledStyle === null ? this.calcDisabled : this.disabledStyle,
              [$style.hasIcon]: this.hasIcon,
              [$style[this.hasIcon]]: $style[this.hasIcon],
              [$style.centerIcon]: this.centerIcon,
              [$style.justIcon]: this.justIcon,
              [$style.darker]: this.darker,
            },
            $style[this.justIcon],
            $style[`round${this.round}`],
          ],
        },
        {
          default: () => {
            return [
              this.$slots.default?.({
                iconClass: $style.buttonIcon,
                textClass: $style.buttonText,
                buttonClasses: $style,
              }),
              ((this.apiIsLoading && this.loadingStyle === 'spinner')
                ? h(
                  AwSpinner,
                  {
                    class: $style.buttonLoadingIcon,
                    color: this.getSpinnerColor,
                    ...this.loadingSpinnerAttributes,
                  },
                )
                : null
              ),
            ];
          },
        },
      );
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
$button-hover-focus-opacity: .2 !default;
$button-max-width: 220px !default;
$button-border-radius: 4px !default;

$button-reset-border: none !default;
$button-reset-border-color: transparent !default;
$button-reset-background: transparent !default;
$button-reset-hover-focus-background: transparent !default;
$button-reset-active-background: transparent !default;
$button-reset-disabled-background: transparent !default;

@mixin round() {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;

  &::before, &::after {
    border-radius: 100% !important;
  }
}

.button {
  font-size: 16px;
  font-style: normal;
  position: relative;
  z-index: 0;
  overflow: hidden;
  width: 100%;
  max-width: $button-max-width;
  cursor: pointer;
  transition: opacity $animation-speed-fast $animation-timing-function, box-shadow $animation-speed-fast $animation-timing-function, border-color $animation-speed-fast $animation-timing-function;
  text-align: center;
  vertical-align: middle;
  text-decoration: none;
  letter-spacing: normal;
  text-overflow: ellipsis;
  border: none;

  /* region Animations */

  &::after,
  &::before {
    position: absolute;
    content: "";
    border-radius: $button-border-radius;
  }

  &::before {
    z-index: -2;
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    transition: opacity $animation-speed-fast $animation-timing-function, background-color $animation-speed-fast $animation-timing-function;
    opacity: 0;
  }

  &::after {
    z-index: -1;
    top: 50%;
    right: auto;
    bottom: auto;
    left: 50%;
    width: 0;
    padding-bottom: 0;
    transform: translate3d(-50%, -50%, 0);
    border-radius: 100%;
  }

  &:active {
    &::after {
      width: 110%;
      padding-bottom: 110%;
      transition: width $animation-speed-fast $animation-timing-function, padding-bottom $animation-speed-fast $animation-timing-function;
    }
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 1;

    &::after,
    &::before {
      content: none;
      border-radius: $button-border-radius;
    }
  }

  /* endregion Animations */

  .buttonIconCenterWrapper {
    display: flex;
    align-items: center;
    margin-right: -6px;
    margin-left: -6px;
  }

  .buttonIconCenter {
    margin: -10px 6px;
  }

  .buttonCenterText {
    margin-right: 6px;
    margin-left: 6px;
  }

  &.loading {
    cursor: not-allowed;
    pointer-events: none;
  }

  &.uppercase {
    text-transform: uppercase;
  }

  &Text {
    vertical-align: middle;
  }

  &Icon + &Text {
    line-height: 1;
    margin-left: 5px;
  }

  &LoadingIcon {
    position: absolute;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background: rgba(255, 255, 255, 0.5);
  }

  @each $button-type, $button-value in $button-types {
    &.#{$button-type} {
      font-size: map-get($button-value, fontSize);

      /* endregion Shadows */
      font-weight: map-get($button-value, fontWeight);
      line-height: map-get($button-value, lineHeight);
      padding: map-get($button-value, padding);
      color: map-get($button-value, color);
      border: map-get($button-value, border);
      border-radius: map-get($button-value, borderRadius);
      background: map-get($button-value, background);
      /* region Shadows */
      box-shadow: map-get($button-value, boxShadow);

      &:focus-visible {
        box-shadow: map-get($button-value, boxShadowFocus);
      }

      &:not(.disabled) {
        &:focus {
          box-shadow: map-get($button-value, boxShadowFocus);
        }

        &:hover {
          box-shadow: map-get($button-value, boxShadowHover);
        }

        &:active {
          box-shadow: map-get($button-value, boxShadowActive);
        }
      }

      @if map-get($button-value, width) {
        width: map-get($button-value, width);
      }
      @if map-get($button-value, height) {
        height: map-get($button-value, height);
      }

      &:focus {
        color: map-get($button-value, colorFocus);
        border: map-get($button-value, borderFocus);
        background: map-get($button-value, backgroundFocus);

        &::before {
          opacity: $button-hover-focus-opacity;
          @if map-get($button-value, backgroundBeforeHoverFocus) {
            border: map-get($button-value, backgroundBeforeHoverFocus);
            background-color: map-get($button-value, backgroundBeforeHoverFocus);
          }
        }
      }

      &:active {
        color: map-get($button-value, colorActive);
        border: map-get($button-value, borderActive);
        background: map-get($button-value, backgroundActive);

        &::before {
          opacity: 1;
          border-radius: $button-border-radius;
          background-color: map-get($button-value, backgroundBeforeActive);
        }
      }

      &:hover,
      &.darker {
        color: map-get($button-value, colorHover);
        border: map-get($button-value, borderHover);
        background: map-get($button-value, backgroundHover);

        &::before {
          opacity: $button-hover-focus-opacity;
          @if map-get($button-value, backgroundBeforeHoverFocus) {
            border: map-get($button-value, backgroundBeforeHoverFocus);
            background-color: map-get($button-value, backgroundBeforeHoverFocus);
          }
        }
      }

      &.disabled {
        opacity: 1;
        color: map-get($button-value, colorDisabled);
        border-color: map-get($button-value, borderColorDisabled);
        background: map-get($button-value, backgroundDisabled);
      }

      &.hasIcon {
        &.centerIcon {
          padding: map-get($button-value, padding);
        }
      }
    }
  }

  &.radiusOff {
    border-radius: 0;
  }

  &.hasIcon {
    &.small,
    &.medium {
      padding: 0;
    }

    &.small {
      width: 32px;
      height: 32px;
    }

    &.medium {
      width: 48px;
      height: 48px;
    }

    .buttonLeftText {
      line-height: 14px;
      text-align: left;
    }

    .buttonRightText {
      line-height: 14px;
      text-align: right;
    }

    &.round {
      &xs-min {
        @include xs(min) {
          @include round;
        }
      }

      &xs-max {
        @include xs(max) {
          @include round;
        }
      }

      &sm-min {
        @include sm(min) {
          @include round;
        }
      }

      &sm-max {
        @include sm(max) {
          @include round;
        }
      }

      &md-min {
        @include md(min) {
          @include round;
        }
      }

      &md-max {
        @include md(max) {
          @include round;
        }
      }
    }

    .buttonIcon,
    .buttonLeftIcon,
    .buttonRightIcon {
      position: absolute;
      top: 50%;
      bottom: auto;
      width: 40px;
      transform: translateY(-50%);
    }

    .buttonIcon,
    .buttonLeftIcon {
      right: auto;
      left: 0;
    }

    .buttonRightIcon {
      right: 0;
      left: auto;
    }
  }

  &.justIcon {
    width: 56px;
    height: 56px;
    padding: 16px;
    text-align: center;
    border: none;
    border-radius: 100%;

    &.small {
      width: 32px;
      height: 32px;
      padding: 0;
    }
  }

}

%button-reset {
  border: $button-reset-border;
  border-color: $button-reset-border-color;
  background: $button-reset-background;

  &:hover,
  &:focus {
    background: $button-reset-hover-focus-background;
  }

  &:active {
    background: $button-reset-active-background;
  }

  &.disabled {
    opacity: 1;
    background: $button-reset-disabled-background;
  }
}

.clear {
  @extend %button-reset;
}

.reset {
  @extend %button-reset;
  font-weight: $font-weight-book;
  padding: 0;
  text-transform: none;
}

@each $link-key, $link-value in $link-types {
  .link-#{$link-key} {
    @extend %button-reset;

    @if map-get($link-value, fontWeight) {
      font-weight: map-get($link-value, fontWeight);
    } @else {
      font-weight: $font-weight-book;
    }

    color: map-get($link-value, color);

    &:hover,
    &:focus {
      &:not(.disabled) {
        text-decoration: map-get($link-value, decoration);
      }
    }

    &:hover {
      color: map-get($link-value, colorHover);
    }

    &:focus {
      color: map-get($link-value, colorFocus);
    }

    &:active {
      color: map-get($link-value, colorActive);
    }

    &.disabled {
      text-decoration: map-get($link-value, decorationDisabled);
      color: map-get($link-value, colorDisabled);
    }

  }
}

.block {
  max-width: none !important;
}

.shrink {
  display: inline-block;
  width: auto;
  padding: 0;
  text-align: left;
}
</style>
