<template>
  <default-layout v-if="error?.statusCode === 404" is-aw-spring>
    <aw-not-found-error-page
      v-if="$awMergedConfig.isShop"
    />
    <aw-simple-error-page-with-image
      v-else
      :error-code="error.statusCode"
    />
  </default-layout>
  <aw-simple-error-page-with-image
    v-else
    :error-code="error.statusCode"
    :class="$style.layoutlessError"
  />
</template>
<script setup>
  import { useNuxtApp } from 'nuxt/app';
  import { useBreadcrumbStore } from '~~/common/stores/breadcrumb';
  import { updateSeoHeadReactive } from '~~/common/mixins/seoMixin';
  import DefaultLayout from '~/layouts/default';
  import AwSimpleErrorPageWithImage from '~~/common/components/AwSimpleErrorPageWithImage.vue';
  import AwNotFoundErrorPage from '~~/common/components/AwNotFoundErrorPage.vue';

  const props = defineProps({
    error: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  });

  const { $logger } = useNuxtApp();

  if (props.error.statusCode !== 404) {
    $logger.error(props.error);
  }

  updateSeoHeadReactive();
  useBreadcrumbStore().setBreadcrumbs([]);
</script>

<style module lang="scss" rel="stylesheet/scss">
.layoutlessError {
  min-height: 100vh;
  margin: 0 8px;
}
</style>
