<template>
  <div ref="activeFilters">
    <div v-show="displayFilters?.length" :class="[$style.activeFilters, {[$style.activeFiltersHeader]: useInHeader}]">
      <client-only>
        <ul :class="[$style.activeFiltersList,'ulReset']">
          <lv-button
            v-for="filter in displayFilters"
            :key="filter.id"
            :class="$style.activeFiltersListFilter"
            tabindex="0"
            tag="li"
            styling="small-secondary-dark"
            shrink
            :aria-label="`${$awt('aw.product_list.active_filters.delete_filter')} ${filter && filter.name}: ${filter.values.join(',\xA0')}`"
            @click="clearFilter(filter.id)"
          >
            <span :title="getFilterValues(filter)" :class="$style.activeFiltersListFilterTitle">
              {{ getFilterValues(filter) }}
            </span>
            <lv-icon name="close-24" :size="16" :class="$style.activeFiltersListFilterIcon" />
          </lv-button>

          <lv-button
            styling="link"
            type="button"
            shrink
            @click="clearAllFilters"
          >
            <span :class="$style.activeFiltersListDeleteButtonContent">
              {{ $awt('aw.product_list.active_filters.delete_all') }}
            </span>
          </lv-button>
        </ul>
      </client-only>
    </div>
  </div>
</template>

<script>
  import { LvButton, LvIcon } from '~~/common/components/loginet-vue-shop/index.mjs';
  import AwProductListFilterMixin from '~~/common/components/Page/ProductList/AwProductListFilterMixin.js';
  import { useProductsStore } from '~~/common/stores/products';
  import { collectLeaves } from '~~/common/components/Page/ProductList/Filters/CategorySelectionFilterHelper.js';

  const filterSelectionTypes = {
    SINGLE_SELECTION: 'singleSelection',
    RANGE: 'range',
    CHECKBOX: 'checkbox',
    CATEGORY_SELECTION: 'categorySelection',
  };

  export default {
    name: 'AwProductListActiveFilters',
    components: {
      LvButton,
      LvIcon,
    },
    mixins: [
      AwProductListFilterMixin,
    ],
    props: {
      useInHeader: {
        type: Boolean,
        default: false,
      },
      controlsVisibleStatus: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        observer: null,
      };
    },
    computed: {
      displayFilters () {
        return (Object.keys(this.filterBy)
          .map((currentFilterId) => {
            const filterContext = this.filters.find(filter => filter.id === currentFilterId);
            if (!filterContext) {
              return null;
            }
            const currentFilterValueOptions = this.filterBy[currentFilterId];
            const values = [];
            if (filterContext.selectionType === filterSelectionTypes.CATEGORY_SELECTION) {
              const filterValues = collectLeaves(filterContext.values);
              values.push(filterValues.find(valueOption => String(valueOption.value) === String(currentFilterValueOptions))?.name);
            }
            if (filterContext.selectionType === filterSelectionTypes.SINGLE_SELECTION) {
              values.push(filterContext.values.find(valueOption => String(valueOption.value) === String(currentFilterValueOptions))?.name);
            }
            if (filterContext.selectionType === filterSelectionTypes.RANGE) {
              values.push(`${currentFilterValueOptions[0][0]} - ${currentFilterValueOptions[0][1]}`);
            }
            if (filterContext.selectionType === filterSelectionTypes.CHECKBOX) {
              values.push(filterContext.values.filter(val => currentFilterValueOptions.includes(val.value)).map(val => val?.name));
            }
            return {
              id: filterContext.id,
              name: filterContext?.name,
              values,
            };
          })
          .filter(f => f !== null)
        );
      },
    },
    mounted () {
      if (this.controlsVisibleStatus) {
        this.addIntersectionObserver();
      }
    },
    beforeUnmount () {
      this.removeObserver();
    },
    methods: {
      addIntersectionObserver () {
        const element = this.$refs.activeFilters;
        const callback = (entries) => {
          const isIntersecting = entries?.[0]?.isIntersecting;
          const productsStore = useProductsStore();
          productsStore.filterPanelVisible = !isIntersecting;
        };
        this.observer = new IntersectionObserver(callback, {
          root: null,
          rootMargin: '0px',
          threshold: 1.0,
        });
        this.observer.observe(element);
      },
      removeObserver () {
        this.observer?.disconnect();
      },
      getFilterValues (filter) {
        return `${filter && filter.name}: ${filter.values.join(',\xA0')}`;
      },
      async clearFilter (filterId) {
        this.deleteSpecifiedFilter(filterId);
        const productsStore = useProductsStore();
        await productsStore.throttledRefresh();
      },
      clearAllFilters () {
        this.resetFilter();
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
.activeFilters {
  font-family: $secondary-font;
  padding: 20px 0;

  color: $color-olive-750;
  border-top: 1px dashed $color-dash-separator;
  border-bottom: 1px dashed $color-dash-separator;

  &Header {
    padding: 0;
    border-top: none;
    border-bottom: none;
  }

  &List {
    display: flex;
    overflow-x: auto;
    flex-direction: row;
    max-height: calc(3 * 32px + 2 * 8px);
    gap: 8px;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    @include tablet(min) {
      overflow: hidden;
      flex-wrap: wrap;
    }

    &Filter {
      display: grid;
      align-items: center;
      max-width: 100%;
      text-align: center;
      grid-template-columns: auto 16px;
      gap: 8px;

      &Title {
        font-size: 12px;
        font-weight: $font-weight-medium-v2;
        font-style: normal;
        overflow: hidden;
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      &Icon {
        width: 16px;
        height: 16px;

        &:hover {
          color: $color-strawberry-450;
        }
      }
    }

    &DeleteButtonContent {
      font-family: $secondary-font;
      font-size: 14px;
      font-weight: $font-weight-medium-v2;
      font-style: normal;
      line-height: 20px;
      white-space: nowrap;
      color: $color-text-primary;
      text-decoration-line: underline;

      &:hover {
        text-decoration-line: none;
      }
    }
  }
}
</style>
