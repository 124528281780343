// NOTE: Corresponding stylesheet:
// common/assets/styles/_generic/_transitions.scss
export const transitionOpacity = generateTransition('Opacity');
export const transitionX = generateTransition('X');

function generateTransition (transitionName) {
  const prefix = `awTransition${transitionName}`;
  return {
    'enter-active-class': `${prefix}EnterActive`,
    'enter-from-class': `${prefix}EnterFrom`,
    'leave-active-class': `${prefix}LeaveActive`,
    'leave-to-class': `${prefix}LeaveTo`,
    mode: 'out-in',
  };
}
