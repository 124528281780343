<template>
  <div :class="$style.productImageWrapper">
    <lv-image :class="$style.productImage" :sources="sources" />
  </div>
</template>

<script setup>
  import { webpFallback } from '~~/common/utils/index.js';
  import { computed } from 'vue';
  import { LvImage } from '~~/common/components/loginet-vue-shop/index.mjs';

  const props = defineProps({
    item: {
      type: Object,
      required: true,
    },
  });

  const sources = computed(() => {
    const media = props.item.variant?.media;
    return webpFallback([{ url: media?.listImage || media?.mainImage || media?.images?.[0] || '' }]);
  });
</script>


<style module lang="scss" rel="stylesheet/scss">
.productImage {
  width: 24px;
  height: 24px;

  &Wrapper {
    width: 40px;
    height: 40px;
    padding: 8px;
    border-radius: 8px;
    background: $color-background-4;
  }
}

</style>
