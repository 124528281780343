<template>
  <div ref="wrapperElement" :class="$style.selfcareDropdownMenu" @mouseleave="hide">
    <lv-button
      v-for="item in menuItems"
      :key="item.name"
      :to="item.click ? null : $path(localePath(item.to))"
      :disabled="item.disabled"
      :class="[$style.selfcareDropdownMenuItem]"
      styling="link-primary-version2"
      @click="onClick(item)"
    >
      <span v-text="item.name" />
    </lv-button>
  </div>
</template>

<script setup>
  import { ref, computed, watch, onBeforeUnmount } from 'vue';
  import { navigateTo, useNuxtApp } from 'nuxt/app';
  import { LvButton } from '~~/common/components/loginet-vue-shop/index.mjs';
  import { URL_PREFIX } from '~~/shop/awPlugins/app-config';
  import { clickLogout } from '~~/common/mixins/orderDataMixin.js';
  import { useOrderStore } from '~~/common/stores/order';
  import { useCheckoutStore } from '~~/common/stores/checkout';

  const nuxtApp = useNuxtApp();
  const { isShop } = nuxtApp.$awMergedConfig;
  const props = defineProps({
    show: {
      type: Boolean,
      required: true,
    },
  });
  const emits = defineEmits(['update:show']);

  const wrapperElement = ref(null);

  const menuItems = computed(() => {
    const p = URL_PREFIX;
    const { $awt, $path, $localePath } = nuxtApp;
    const orderStore = useOrderStore();
    const checkoutStore = useCheckoutStore();
    const logoutBtn = {
      name: 'aw.common.header.user.logout',
      click: clickLogout,
    };
    return (isShop
      ? [
        {
          name: 'aw.common.header.selfcare_dropdown_menu.loyalty_program',
          to: `${p}/profile/loyalty-program`,
        },
        {
          name: 'aw.common.header.selfcare_dropdown_menu.orders',
          to: `${p}/profile/orders`,
        },
        {
          name: 'aw.common.header.selfcare_dropdown_menu.saved_baskets',
          to: `${p}/profile/saved-baskets`,
        },
        {
          name: 'aw.common.header.selfcare_dropdown_menu.user_details',
          to: `${p}/profile/data`,
        },
        logoutBtn,
      ]
      : [
        {
          name: 'awd.common.header.selfcare_dropdown_menu.start_order',
          disabled: Boolean(orderStore.getModStrategy),
          click: async () => {
            await Promise.all([
              checkoutStore.deleteCheckout(),
              checkoutStore.deleteReservations(),
              orderStore.removeAllItem('DEALER'),
            ]);
            navigateTo($path($localePath('/')));
          },
        },
        {
          name: 'awd.common.header.selfcare_dropdown_menu.orders',
          to: '/orders-list',
        },
        {
          name: 'awd.common.header.selfcare_dropdown_menu.label_print',
          to: '/label-print',
        },
        {
          name: 'awd.common.header.selfcare_dropdown_menu.quick_view',
          to: '/results/quick-view',
        },
        {
          name: 'awd.common.header.selfcare_dropdown_menu.my_results',
          to: '/results/my-results',
        },
        logoutBtn,
      ]
    ).map((o) => {
      o.name = $awt(o.name);
      return o;
    });
  });
  let timeoutId = null;

  watch(() => props.show, (newVal, oldVal) => {
    if (newVal && !oldVal) {
      cleanup();
      timeoutId = setTimeout(() => {
        cleanup();
        document.body.addEventListener('click', hideDropdownOutsideClick);
      }, 400);
    }
  }, { immediate: true });

  onBeforeUnmount(cleanup);

  function cleanup () {
    clearTimeout(timeoutId);
    document.body.removeEventListener('click', hideDropdownOutsideClick);
  }

  function hideDropdownOutsideClick (event) {
    if (wrapperElement.value && !wrapperElement.value?.contains?.(event.target)) {
      hide();
    }
  }
  function onClick (item) {
    if (typeof item?.click === 'function') {
      item.click();
    }
    hide({ focusInside: true });
  }
  function hide ({ focusInside } = {}) {
    cleanup();
    emits('update:show', false, { focusInside });
  }
</script>

<style module lang="scss" rel="stylesheet/scss">
.selfcareDropdownMenu {
  font-family: $secondary-font;
  width: 256px;
  padding: 8px 8px 0;
  color: $color-text-primary;
  border: none;
  border-radius: 12px;
  background-color: $color-background-4;
  box-shadow: 0 8px 24px 0 change-color($color-olive-750, $alpha: 0.12);

  &Item {
    font-size: 14px;
    font-weight: $font-weight-normal-v2;
    line-height: 20px;
    padding: 8px;

    text-align: left;
    border-radius: 8px;
  }
}
</style>
