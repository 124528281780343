<template>
  <div
    :class="[
      $style.page,
      {[$style.pageBackgroundFill]: background.backgroundRepeat === 'fill'},
      {[$style.pageBackgroundRepeat]: background.backgroundRepeat === 'repeat'},
      {[$style.pageBackgroundFixed]: background.backgroundRepeat === 'fix'},
    ]"
    :style="{
      backgroundColor: backgroundColor,
      ...imageStyle,
    }"
  >
    <picture v-if="backgroundImages" :class="$style.backgroundImage">
      <template v-for="(img, idx) in backgroundImages" :key="idx">
        <template v-if="img?.url">
          <source
            v-for="url in [`${img.url}.webp`, img.url]"
            :key="url"
            :srcset="url"
            :type="url.endsWith('.webp') ? 'image/webp' : null"
            :media="!imageRemoved ? img.media : null"
          >
        </template>
      </template>
      <img
        ref="imgElement"
        src="data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
        :alt="altTag"
        loading="lazy"
        :class="$style.backgroundImageTag"
        data-background-image
        @load="onImageLoad"
        @error="onImageError"
      >
    </picture>
    <a
      :href="`#${skipLinks.toMain.id}`"
      :class="$style.skipLink"
      @click.prevent="skipLinks.toMain.click($event, skipLinks.toMain)"
      v-text="skipLinks.toMain.text"
    />
    <header
      :id="Navigation[ID_HEADER]"
      ref="headerElement"
      :class="[$style.header, {[$style.headerSmall]: smallHeader} ]"
    >
      <div ref="notificationBarWrapper">
        <aw-notification-bar @notification-changed="onNotificationBarSizeChange" />
      </div>
      <div :style="editedOrderBg">
        <aw-user-header-dh
          ref="dhHeaderElement"
          v-model:is-menu-opened="isDhHeaderMenuOpened"
          :is-pinned="isHeaderPinned"
          :is-on-top="isHeaderOnTop"
          :class="[
            $style.userHeader,
            {
              [$style.userHeaderRadiusOff]: (showFilterPanel && (screenRange['tablet-min'] && screenRange['tablet-max'])) || !isAwTeleportTargetSubHeaderHeightZero,
              [$style.headerShadow]: isAwTeleportTargetSubHeaderHeightZero && !showFilterPanel && !isHeaderOnTop,
            }
          ]"
          :is-ecom-page="isEcomPage"
          :is-product-list-page="isProductListPage"
          :is-boutique="isBoutique"
        />
      </div>
      <div ref="awTeleportTargetSubHeaderElement">
        <aw-order-modify-title-row v-if="editedOrderId" :order-ident="editedOrderId" :style="editedOrderBg" />
        <div id="awTeleportTargetSubHeader" />
      </div>

      <aw-header-filter-panel v-model:show="showFilterPanel" :class="$style.filterPanel" />

      <aw-category-header
        v-if="!$slots.categoryMenu && isEcomPage && !(isBasketPage || isCheckoutPage || isCheckoutSuccessPage)"
        :class="$style.categoryHeader"
        @open-online-store-menu="openOnlineStoreMenu"
        @open-online-store-menu-enter="openOnlineStoreMenuEnter"
      />
    </header>

    <main
      :class="[
        $style.mainContent,
        {
          [$style.isCheckoutSuccess]: isCheckoutSuccessPage,
          [$style.isCheckout]: isCheckoutPage,
          [$style.isBasket]: isBasketPage,
          [$style.isEcom]: isEcomPage,
          [$style.isDh]: !isEcomPage,
        },
      ]"
      :style="{
        '--notification-bar-height': `${notificationBarHeight ?? 48}px`,
        '--isEditedOrderBarShown': editedOrderId ? 1 : 0,
      }"
      data-aw-layout-transition
    >
      <p
        :id="skipLinks.toMain.id"
        :key="skipLinks.toMain.key"
        :ref="skipLinks.toMain.id"
        tabindex="-1"
        :class="$style.skipLinkTarget"
        v-text="skipLinks.toMain.targetText"
      />
      <component :is="springComponent" id="container" v-bind="springAttributes">
        <slot name="content" :default-layout-classes="$style">
          <div :class="[$style.container]">
            <slot />
          </div>
        </slot>
      </component>
    </main>
    <slot name="modal" />
    <div id="awTeleportTargetModal" />

    <LazyHydrate v-if="isShop && !isCheckoutPage && !isCheckoutSuccessPage" when-visible>
      <aw-footer :class="$style.footer" />
    </LazyHydrate>

    <aw-compare-popup v-if="showComparePopup" />
    <aw-api-confirm-modal v-if="isApiConfirmModalOpened" :key="`api-confirm-modal-${isApiConfirmModalOpened}`" />
    <aw-confirm-modal v-if="isConfirmModalOpened" :key="`confirm-modal-${isConfirmModalOpened}`" />
    <aw-confirm-modal
      v-if="isQueueSafeConfirmModalOpened"
      :key="`confirm-modal-queue-safe-${isQueueSafeConfirmModalOpened}`"
      :generic-modal="queueSafeConfirmModal"
      :uuid="queueSafeConfirmModalUuid"
    />
    <aw-alert-modal v-if="isAlertModalOpened" :key="`alert-modal-${isAlertModalOpened}`" />
    <aw-confirm-user-password-modal
      v-if="isConfirmUserPwdOpened"
      :key="`confirm-user-password-modal-${isConfirmUserPwdOpened}`"
    />
    <aw-another-category-confirm-modal
      v-if="isCompareCatOpened"
      :key="`another-category-confirm-modal-${isCompareCatOpened}`"
    />
    <aw-login-and-registration-modal v-if="isLoginModalOpened" :key="`login-modal-${isLoginModalOpened}`" />
    <!-- See: 8cbe590a9dd6f5340ca8685fbfcc22164bf966dd <aw-product-stock-notification-request-modal v-if="isShop" :key="`product-stock-notification-request-modal-${isProdStockOpened}`" /> -->
    <!--    <aw-list-search-modal />-->
    <aw-barcode-modal v-if="!isShop" />
    <aw-toaster :toasters="toasters" />
    <iframe
      v-if="customerDiamondLogoutIframeKey"
      :key="customerDiamondLogoutIframeKey"
      :src="$awRuntimeConfig.customerDiamondLogoutUrl"
      width="0"
      height="0"
    />
  </div>
</template>

<script>
  import { defineAsyncComponent, nextTick } from 'vue';
  import Headroom from 'headroom.js';
  import { mapState } from 'pinia';
  import { defineNuxtComponent, useHead, useRoute, useRuntimeConfig, useError } from 'nuxt/app';
  import {
    Navigation,
    ID_HEADER,
    ID_MAIN,
  } from '~~/common/config/Navigation.js';
  import {
    M_CONFIRM_USER_PWD,
    M_COMPARE_CAT,
    M_ALERT,
    M_LOG_AND_REG,
    M_QUEUE_SAFE_CONFIRM,
    /* See: 8cbe590a9dd6f5340ca8685fbfcc22164bf966dd M_PROD_STOCK, */
  } from '~~/common/config/Modal.js';
  import { LvSpring } from '~~/common/components/loginet-vue-shop/index.mjs';
  import AwUserHeaderDh from '~~/common/components/Common/Header/AwUserHeaderDh.vue';
  import pageInstanceMixin from '~~/common/mixins/pageInstanceMixin';
  import { clickLogout } from '~~/common/mixins/orderDataMixin.js';
  import { colorModelToHex } from '~~/common/utils';
  import AwSpring from '~~/common/components/AwSpring';
  import { useCookieConsentStore } from '~~/shop/stores/cookieConsent';
  import { useToasterStore } from '~~/common/stores/toaster';
  import { useUrlResourceStore } from '~~/common/stores/urlResource';
  import { useModalStore } from '~~/common/stores/modal';
  import { useUserInterfaceStore } from '~~/common/stores/userInterface';
  import { useContentMenusStore } from '~~/common/stores/contentMenus';
  import { useCategoryStore } from '~~/common/stores/category';
  import { useCompareStore } from '~~/common/stores/compare';
  import { useProductsStore } from '~~/common/stores/products';
  import { useUserStore } from '~~/common/stores/user';
  import { useOrderStore } from '~~/common/stores/order';
  import { useAuthenticationStore } from '~~/common/stores/authentication';
  import AwHeaderFilterPanel from '~~/common/components/Common/Header/AwHeaderFilterPanel.vue';
  import pageTypeMixin from '~~/common/mixins/pageTypeMixin.js';
  import analyticsQueueMixin from '~~/common/mixins/analyticsQueueMixin.js';
  import AwToaster from '~~/common/components/Common/AwToaster';
  import AwNotificationBar from '~~/common/components/Common/Header/AwNotificationBar';
  import { useContentsStore } from '~~/common/stores/contents';
  import { ecomShopPages } from '~~/common/config/EcomPagesConfig.js';
  import { getBackgroundImages, onImageComplete, removeImageSourceTagOnError } from '~~/common/utils/imageHelper.js';

  export default defineNuxtComponent({
    name: 'CommonDefaultLayout',
    components: {
      AwToaster,
      AwBarcodeModal: defineAsyncComponent(() => import('~~/common/components/Common/Modals/AwBarcodeModal')),
      // AwListSearchModal: defineAsyncComponent(() => import('~~/common/components/Common/Modals/AwListSearchModal')),
      AwConfirmUserPasswordModal: defineAsyncComponent(() => import('~~/common/components/Common/Modals/AwConfirmUserPasswordModal')),
      // See: 8cbe590a9dd6f5340ca8685fbfcc22164bf966dd AwProductStockNotificationRequestModal: defineAsyncComponent(() => import('~~/common/components/Page/Product/AwProductStockNotificationRequestModal')),
      LvSpring,
      AwSpring,
      AwUserHeaderDh,
      AwNotificationBar,
      AwHeaderFilterPanel,
      AwCategoryHeader: defineAsyncComponent(() => import('~~/common/components/Common/Header/AwCategoryHeader')),
      AwFooter: defineAsyncComponent(() => import('~~/common/components/Common/Footer/AwFooter')),
      AwAnotherCategoryConfirmModal: defineAsyncComponent(() => import('~~/common/components/Page/Compare/AwAnotherCategoryConfirmModal')),
      AwComparePopup: defineAsyncComponent(() => import('~~/common/components/Common/AwComparePopup')),
      AwConfirmModal: defineAsyncComponent(() => import('~~/common/components/Common/Modals/AwConfirmModal')),
      AwAlertModal: defineAsyncComponent(() => import('~~/common/components/Common/Modals/AwAlertModal')),
      AwApiConfirmModal: defineAsyncComponent(() => import('~~/common/components/Common/Modals/AwApiConfirmModal')),
      AwLoginAndRegistrationModal: defineAsyncComponent(() => import('~~/common/components/Common/Modals/LoginAndRegistrationModal/AwLoginAndRegistrationModal')),
      AwOrderModifyTitleRow: defineAsyncComponent(() => import('~~/common/components/Page/Common/AwOrderModifyTitleRow.vue')),
    },
    mixins: [
      pageInstanceMixin,
      pageTypeMixin,
    ],
    props: {
      springSize: {
        type: String,
        default: 'md',
      },
      springDesktopSize: {
        type: Number,
        default: undefined,
      },
      isAwSpring: {
        type: Boolean,
        default: false,
      },
      headerGutter: {
        type: String,
        default: undefined,
      },
      smallHeader: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      const oldDesignPageNames = [
        'shop-compare',
      ].reduce((acc, curr) => {
        acc.push(
          `${curr}___hu`,
          `${curr}___en`,
        );
        return acc;
      }, []);
      return {
        isDhHeaderMenuOpened: false,
        Navigation,
        ID_HEADER,
        skipLinks: {
          toMain: {
            id: Navigation[ID_MAIN],
            text: this.$awt('aw.common.skip_links.to_main.link'),
            targetText: this.$awt('aw.common.skip_links.to_main.target'),
            key: 1,
            click: (evt, self) => {
              // On VO/iOS13 only the first focus works unless the whole
              // HTMLElement is fully replaced.
              if (this.$refs[self.id]) {
                self.key = -self.key;
                nextTick(() => {
                  this.$refs[self.id].focus();
                });
              }
            },
          },
        },
        isHeaderOnTop: true,
        isHeaderPinned: true,
        hasFilters: false,
        notificationBarHeight: 0,
        oldDesignPageNames,
        isAwTeleportTargetSubHeaderHeightZero: true,
        resizeObserver: null,
        imageData: null,
        imageRemoved: false,
      };
    },
    setup () {
      // eslint-disable-next-line no-undef
      const i18nHead = useLocaleHead({
        addSeoAttributes: {
          addSeoAttributes: true,
        },
      });

      analyticsQueueMixin();

      const facebookDomainVerification = useRuntimeConfig().public.facebookDomainVerificationId
        ? [
          {
            name: 'facebook-domain-verification',
            content: useRuntimeConfig().public.facebookDomainVerificationId,
          },
        ]
        : [];

      useHead({
        htmlAttrs: {
          lang: i18nHead.value.htmlAttrs.lang,
        },
        link: [...(i18nHead.value.link || [])],
        meta: [
          {
            name: 'build_identifier',
            content: __AW_BUILD_IDENTIFIER__,
          },
          ...facebookDomainVerification,
          ...(i18nHead.value.meta || []),
        ],
        __dangerouslyDisableSanitizers: ['script'],
      });

      const route = useRoute();
      const currentError = useError();
      const isBasketPage = computed(() => useUserInterfaceStore().isBasketPage);
      const isCheckoutPage = computed(() => useUserInterfaceStore().isCheckoutPage);
      const isCheckoutSuccessPage = computed(() => useUserInterfaceStore().isCheckoutSuccessPage);
      const is404Page = computed(() => {
        return currentError.value?.statusCode === 404;
      });
      return {
        route,
        is404Page,
        isBasketPage,
        isCheckoutPage,
        isCheckoutSuccessPage,
      };
    },
    computed: {
      ...mapState(useAuthenticationStore, {
        customerDiamondLogoutIframeKey: state => state.customerDiamondLogoutIframeKey,
      }),
      ...mapState(useOrderStore, {
        editedOrderId: state => state.editedOrder?.id,
      }),
      ...mapState(useModalStore, {
        isLoginModalOpened (state) {
          return state.modals.genericModalQueue.some(m => m.type === M_LOG_AND_REG);
        },
        isAlertModalOpened (state) {
          return state.activeGenericModal.type === M_ALERT;
        },
        isConfirmUserPwdOpened (state) {
          return state.activeGenericModal.type === M_CONFIRM_USER_PWD;
        },
        isCompareCatOpened (state) {
          return state.activeGenericModal.type === M_COMPARE_CAT;
        },
        isApiConfirmModalOpened: state => state.activeApiConfirmModal.show,
        isConfirmModalOpened: state => state.modals.confirmModal.show,
        queueSafeConfirmModal (state) {
          return state.activeGenericModal.type?.startsWith(M_QUEUE_SAFE_CONFIRM) ? state.activeGenericModal : null;
        },
        queueSafeConfirmModalUuid (state) {
          return state.activeGenericModal.type?.slice(M_QUEUE_SAFE_CONFIRM.length) || '';
        },
        isQueueSafeConfirmModalOpened () {
          return Boolean(this.queueSafeConfirmModal);
        },

        /* See: 8cbe590a9dd6f5340ca8685fbfcc22164bf966dd
  isProdStockOpened (state) {
  return state.activeGenericModal.type === M_PROD_STOCK;
  }, */
      }),
      ...mapState(useCookieConsentStore, {
        agreement: state => state.agreement,
      }),
      ...mapState(useCompareStore, {
        compareItems: state => state.compareItems,
      }),
      ...mapState(useUrlResourceStore, {
        urlResource: state => state.data,
      }),
      ...mapState(useUserInterfaceStore, {
        screenRange: state => state.mediaQueries,
        background: state => state.background,
      }),
      ...mapState(useUserStore, {
        user: state => state.data,
      }),
      ...mapState(useToasterStore, {
        toasters: state => state.toasters,
      }),
      ...mapState(useProductsStore, {
        filterPanelVisible: state => state.filterPanelVisible,
      }),
      ...mapState(useContentsStore, {
        isEcomPage (state) {
          const hasEcomStylePage = ecomShopPages.includes(this.route.name);
          const contentData = state.data || state.previewData;
          const isNewTypeContet = contentData?.leadTitle?.length;

          //check dealer or ecom  page by static page name
          if (this.isShop && this.is404Page) {
            return false;
          }
          if (!this.isShop || hasEcomStylePage) {
            return true;
          }

          //check urlResource store data and the urlResource data contains to the current page
          if (this.urlResource?.resourceType && this.urlResource?.slug === this.route.fullPath) {

            //cms content page, ecom header need if there isn't any lead title
            if (this.urlResource.resourceType === 'cms_content') {
              return !isNewTypeContet;
            }
            return true;
          }
          return false;
        },
      }),
      showComparePopup () {
        // show if available compared products AND if we not on the compare page
        return Boolean(this.compareItems.length) && !/compare/.test(this.route.path);
      },
      altTag () {
        return this.background?.backgroundMedia?.largeBackgroundImage?.alt || this.background?.backgroundMedia?.smallBackgroundImage?.alt || '';
      },
      imageStyle () {
        return (this.imageData?.currentSrc
          ? {
            'background-image': `url("${this.imageData.currentSrc}")`,
          }
          : null
        );
      },
      backgroundImages () {
        const images = [
          {
            ...this.background?.backgroundMedia?.largeBackgroundImage,
            media: this.background?.backgroundMedia?.largeBackgroundImage && this.background?.backgroundMedia?.smallBackgroundImage
              ? `(min-width: ${this.$styleVariables.breakpointTablet})`
              : undefined,
          },
          this.background?.backgroundMedia?.smallBackgroundImage,
        ];

        return getBackgroundImages(images);
      },
      backgroundColor () {
        return (this.background?.backgroundColor?.hex && this.background?.backgroundColor?.alpha)
          ? colorModelToHex(this.background.backgroundColor)
          : 'transparent';
      },
      springComponent () {
        return this.isAwSpring ? AwSpring : LvSpring;
      },
      springAttributes () {
        return this.isAwSpring
          ? {
            desktopSize: this.springDesktopSize,
          }
          : {
            size: this.springSize,
          }
        ;
      },
      showFilterPanel: {
        get () {
          const headerOnTop = this.filterPanelVisible;
          const isRequiredScreenSize = this.screenRange['mobile-max'];
          const isRequiredPage = this.isProductListPage || this.isSearchPage || this.isBoutique || this.isShortList;
          return headerOnTop && (this.hasFilters || isRequiredScreenSize) && isRequiredPage;
        },
        set (newVal) {
          this.hasFilters = newVal;
        },
      },
      editedOrderBg () {
        const backgroundColor = this.editedOrderId ? this.$styleVariables.colorBackground2 : null;
        return {
          backgroundColor,
        };
      },
    },
    watch: {
      '$route': {
        handler (newUrl) {
          this.calculateBodyExtraClass(newUrl);
        },
        deep: true,
      },
      isEcomPage (newValue) {
        if (newValue) {
          const categoryStore = useCategoryStore();
          categoryStore.fetchCategories();
        }
      },

      '$i18n.locale' (newValue) {
        nextTick(() => {
          setTimeout(() => {
            const categoryStore = useCategoryStore();
            const contentMenusStore = useContentMenusStore();
            contentMenusStore.initMenu('footer-menu');
            categoryStore.fetchCategories();
            // handle cookiebot language change
            if (window.Cookiebot) {
              document.getElementById('Cookiebot').setAttribute('data-culture', newValue.toUpperCase());
              // existence of Cookiebot cannot be determined by checking
              // the existence of `window.Cookiebot` because of browsers
              // declaring variables for each HTMLElement with an id. It's
              // possible that window.Cookiebot is a script tag because
              // A: A network error (adblock, internet connection, etc)
              // B: The script is async and probably haven't loaded yet.
              if (!(window.Cookiebot instanceof HTMLScriptElement) && this.agreement === null) {
                window.Cookiebot.renew();
              }
            }
          }, 100);
        });
      },

    },
    mounted () {
      window.addEventListener('storage', this.userDataListener);
      this.addResizeObserver();
      const config = useRuntimeConfig();
      const { $style } = this;
      new Headroom(this.$refs.headerElement, {
        offset: 30,
        tolerance: {
          up: 2,
          down: 2,
        },
        classes: {
          initial: $style.header,
          pinned: $style.headerPinned,
          unpinned: $style.headerUnpinned,
          top: $style.headerTop,
          notTop: $style.headerNotTop,
          bottom: $style.headerBottom,
          notBottom: $style.headerNotBottom,
          frozen: $style.headerFrozen,
        },
        onTop: () => {
          this.isHeaderOnTop = true;
        },
        onNotTop: () => {
          this.isHeaderOnTop = false;
        },
        onPin: () => {
          this.isHeaderPinned = true;
        },
        onUnpin: () => {
          this.isHeaderPinned = false;
        },
      }).init();

      // Don't touch it, acquire init load after the content and everything loaded
      if (config.public.acquireEnabled && this.urlResource?.resourceType !== 'cms_content') {
        window.addEventListener('load', () => {
          window.requestAnimationFrame(() => {
            setTimeout(() => {
              const scriptElement = document.createElement('script');
              scriptElement.innerHTML = '(function(){var script=`https://s.acquire.io/a-f7346/init.js?full`; ' +
                'var x=document.createElement(`script`);x.src=script;x.async=true;' +
                'var sx=document.getElementsByTagName(`script`)[0]; sx.parentNode.insertBefore(x, sx);})()';
              scriptElement.defer = true;
              document.head.appendChild(scriptElement);
            }, 7500);
          });
        }, false);
      }
      if (config.public.onetrustEnabled && config.public.onetrustId && config.public.onetrustDelayEnabled) {
        window.addEventListener('load', () => {
          window.requestAnimationFrame(() => {
            setTimeout(() => {
              this.$insertScripts(this.$oneTrustScripts);
            }, config.public.onetrustDelayMs);
          });
        }, false);
      }
      if (this.isEcomPage) {
        const categoryStore = useCategoryStore();
        categoryStore.fetchCategories();
      }
      nextTick(() => {
        this.notificationBarHeight = this.$refs?.notificationBarWrapper?.getBoundingClientRect()?.height;
      });
      this.calculateBodyExtraClass(this.route);

      onImageComplete(this.$refs?.imgElement, { onImageLoad: this.onImageLoad, onImageError: this.onImageError });
    },
    beforeUnmount () {
      window.removeEventListener('storage', this.userDataListener);
      this.resizeObserver?.disconnect();
    },
    methods: {
      addResizeObserver () {
        this.resizeObserver = new ResizeObserver((entries) => {
          for (const entry of entries) {
            const height = entry.contentRect.height;
            this.isAwTeleportTargetSubHeaderHeightZero = height === 0;
          }
        });
        this.resizeObserver.observe(this.$refs.awTeleportTargetSubHeaderElement);
      },
      openOnlineStoreMenu () {
        this.isDhHeaderMenuOpened = !this.isDhHeaderMenuOpened;
        this.$refs.dhHeaderElement.openOnlineStoreMenu();
        this.$refs.dhHeaderElement.focusOnTop();
      },
      openOnlineStoreMenuEnter () {
        this.isDhHeaderMenuOpened = !this.isDhHeaderMenuOpened;
        this.$refs.dhHeaderElement.focusOnTop();
      },
      userDataListener (event) {
        if (event.storageArea === window.localStorage && event.key === 'clearDataInAllTabs') {
          clickLogout(false);
        }
      },
      onNotificationBarSizeChange () {
        nextTick(() => {
          this.notificationBarHeight = this.$refs?.notificationBarWrapper?.getBoundingClientRect()?.height;
        });
      },
      calculateBodyExtraClass (newRoute) {
        const userInterfaceStore = useUserInterfaceStore();
        if (this.oldDesignPageNames.includes(newRoute.name)) {
          userInterfaceStore.resetBackground();
          userInterfaceStore.setExtraBodyClassNeeded(false);
        } else {
          if (!(this.urlResource?.resourceType === 'cms_content' && this.urlResource?.slug === newRoute.fullPath)) {
            userInterfaceStore.setBackground({
              backgroundColor: {
                hex: '#F3F0ED',
                alpha: 1,
              },
            });
          }
          userInterfaceStore.setExtraBodyClassNeeded(true);
        }
      },
      onImageError ({ target }) {
        if (target) {
          removeImageSourceTagOnError(target);
          this.imageRemoved = true;
        }
      },
      onImageLoad ({ target }) {
        const { currentSrc } = target;
        this.imageData = { currentSrc };
      },
    },
  });
</script>

<style module lang="scss" rel="stylesheet/scss">

$z-index-menu: 100;

.categoryHeader {
  transition: visibility $animation-speed-medium $animation-timing-function;
}

.skipLink {
  z-index: $z-index-menu + 5;
  display: flex;
  align-items: center;
  transition: transform 600ms;
  color: $color-white;
  border: 2px solid $color-brand-primary;
  border-top: none;
  background: $color-brand-primary;
  box-shadow: 0 0 0 2px $color-white, 0 0 0 4px $color-brand-primary;

  &:not(:focus):not(:active):not(:hover) {
    transform: translateY(calc(-100% - 4px));
  }

  &:focus,
  &:active,
  &:hover {
    text-decoration: underline;
  }

  @include mobile(max) {
    width: 100%;
    padding: 1em;
    border-right: none;
    border-left: none;
  }

  @include tablet(min) {
    margin: 0 auto;
    padding: 1em 1.75em;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  &Target {
    overflow: hidden;
    width: 100%;
    height: 0;
  }
}

.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-position: top center;
  object-position: top center;
  background-size: auto;

  &BackgroundFill, &BackgroundFixed {
    background-repeat: no-repeat;
  }

  &BackgroundFill {
    background-attachment: initial;
    background-size: contain;
  }

  &BackgroundRepeat {
    background-repeat: repeat;
  }

  &BackgroundFixed {
    background-attachment: fixed;
    background-size: cover;
  }
}

.container {
  flex: 1;
  padding-top: 76px;
  padding-bottom: 20px;
}

.isCheckoutSuccess {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  > [data-spring-wrapper] {
    margin-top: auto;
    margin-bottom: auto;
  }

  .container {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.isCheckout {
  .container {
    padding-top: 20px;

    @include tablet (min) {
      padding-top: 40px;
    }
  }
}

.isBasket {
  .container {
    padding-top: 20px;

    @include tablet(min) {
      padding-top: 0;
    }
  }
}

.overflowHidden {
  overflow: hidden;
}

.header {
  position: fixed;
  z-index: $z-index-menu;
  top: 0;
  right: 0;
  left: 0;
  display: block;
  transition: top $animation-speed-medium $animation-timing-function;

  &Top,
  &Frozen,
  &Pinned,
  &NotTop,
  &Bottom,
  &NotBottom {
    /*! These classes have to exist in order for the Headroom.js to work */
  }

  &NotTop {
    .categoryHeader {
      display: none;
    }
  }

  &Small {
    &.headerUnpinned {
      /*! These classes have to exist in order for the Headroom.js to work */
    }

  }

  .headerShadow {
    box-shadow: 0 2px 8px 0 #433F3214;
  }
}

.is {
  &Ecom {
    --mainOffset: 100px;
    @include tablet(min) {
      --mainOffset: 200px;
    }
    @include desktop-small(min) {
      --mainOffset: 140px;
    }
  }

  &Dh {
    --mainOffset: 40px;
    @include tablet(min) {
      --mainOffset: 120px;
    }
    @include desktop-small(min) {
      --mainOffset: 60px;
    }
  }

  &Checkout,
  &CheckoutSuccess {
    --mainOffset: 60px;
    @include tablet(min) {
      --mainOffset: 64px;
    }
    @include desktop-small (min) {
      --mainOffset: 68px;
    }
  }
}

.mainContent {
  --editedOrderBarHeight: calc(var(--isEditedOrderBarShown, 0) * 56px);
  /* NOTE: On FF mobile browsers scrollbar shows through the header
  unless z-index is explicitly set to an arbitrary value. But it
  shouldn't be higher than the sticky header's value else we will
  overlap the content. */
  z-index: 5;
  padding-top: calc(var(--mainOffset) + var(--notification-bar-height, 0) + var(--editedOrderBarHeight));

  @include tablet(min) {
    --editedOrderBarHeight: calc(var(--isEditedOrderBarShown, 0) * 72px);
  }
}

.footer {
  z-index: 0; /* something smaller than the mainContent's */
  margin-top: auto;
}

.staticContent {
  margin-bottom: 16px;

  @include tablet(max) {
    padding: 0 16px;
  }
}

.userHeader {
  position: relative;
  z-index: 100;

  &RadiusOff {
    border-radius: 0;

    @include mobile(max) {
      padding-bottom: 4px;
    }
  }
}

.filterPanel {
  position: relative;
  z-index: 99;
}

.backgroundImage {
  display: none;
}

.backgroundImageTag {
  opacity: 0.01;
}
</style>
