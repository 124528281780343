import { defineStore } from 'pinia';
import { useNuxtApp } from 'nuxt/app';
import { ref } from 'vue';
import { setErrorNotification } from '~~/common/utils/helper';
import { AW_AX_CACHE_DEFAULT } from '~~/common/plugins/axios/awAxCache.js';

export const useStoresAndPetrolsStore = defineStore('storesAndPetrolsStore', () => {
  const nuxtApp = useNuxtApp();

  const stores = ref([]);
  const petrolStations = ref([]);

  function setAuchanStores (payload) {
    stores.value = payload;
  }

  function setAuchanPetrolStations (payload) {
    petrolStations.value = payload;
  }

  async function fetchAuchanStores () {
    const {
      $api,
      $logger,
    } = nuxtApp;

    try {
      const result = await $api.$get('store/list', { awAxCache: AW_AX_CACHE_DEFAULT });
      setAuchanStores(result);
    } catch (error) {
      $logger.error(error);
      setErrorNotification({ nuxtApp, error });
    }

  }

  async function fetchAuchanPetrolStations () {
    const {
      $api,
      $logger,
    } = nuxtApp;

    try {
      const result = await $api.$get('petrol/list', { awAxCache: AW_AX_CACHE_DEFAULT });
      setAuchanPetrolStations(result);
    } catch (error) {
      $logger.error(error);
      setErrorNotification({ nuxtApp, error });
    }
  }

  return {
    stores,
    petrolStations,
    fetchAuchanStores,
    fetchAuchanPetrolStations,
  };

});
