import { defineStore } from 'pinia';
import { useNuxtApp } from 'nuxt/app';
import { ref } from 'vue';

export const useContentMenusStore = defineStore('contentMenus', () => {
  const nuxtApp = useNuxtApp();
  const footerMenu = ref([]);
  const petrolMenu = ref([]);
  const loyaltyProgramMenu = ref([]);
  const careerMenu = ref([]);

  async function initMenu (path) {
    const { $logger } = nuxtApp;
    try {
      const menu = await fetchContentMenu(path);
      if (!menu) {
        return;
      }

      switch (path) {
        case 'loyalty-program-menu':
          loyaltyProgramMenu.value = menu.children?.map(menuTransformer);
          break;
        case 'career-menu':
          careerMenu.value = menu.children?.map(menuTransformer);
          break;
        case 'petrol-menu':
          petrolMenu.value = menu.children?.map(menuTransformer);
          break;
        case 'footer-menu':
          footerMenu.value = menu;
          break;
      }
    } catch (err) {
      $logger.error(err);
    }
  }

  async function fetchContentMenu (contentMenuId) {
    const { $api, $logger } = nuxtApp;
    try {
      if (contentMenuId) {
        return await $api.$get(`/content_menus/${contentMenuId}`);
      } else {
        throw new Error(`contentMenuId is ${contentMenuId}`);
      }
    } catch (err) {
      $logger.error(err);
    }
  }

  function getFirstLinkFromContentMenu (contentMenu) {
    return contentMenu?.children?.[0]?.link;
  }

  return {
    footerMenu,
    petrolMenu,
    careerMenu,
    loyaltyProgramMenu,
    initMenu,
    fetchContentMenu,
    getFirstLinkFromContentMenu,
  };
});

function menuTransformer (menu) {
  return {
    label: menu.title,
    to: menu.link,
    target: menu.newTab ? '_blank' : null,
  };
}
