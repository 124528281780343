import { defineNuxtPlugin } from 'nuxt/app';
import colors from '~~/common/assets/styles/variables/colors.module.scss';
import awUserInterface from '~~/common/assets/styles/variables/aw-user-interface.module.scss';
import grids from '~~/common/assets/styles/variables/grids.module.scss';

export default defineNuxtPlugin((nuxtApp) => {
  const styleVariables = {
    ...colors,
    ...awUserInterface,
    ...grids,
  };
  const styleVariablesProxy = new Proxy(
    styleVariables,
    {
      get (obj, key) {
        const value = obj[key];
        if (value === undefined) {
          (nuxtApp?.$logger || console).error(`Style variable ${key} not found.`);
        }
        return value;
      },
    },
  );
  nuxtApp.provide('styleVariables', styleVariablesProxy);
});
