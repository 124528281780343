<template>
  <div :class="$style.toasterWrapper" aria-live="polite">
    <client-only>
      <div v-if="visibleToaster" :class="$style.toaster">
        <div>
          <h4 :class="$style.toasterTitle">
            {{ visibleToaster.title }}
          </h4>
          <div :class="$style.toasterText" v-html="visibleToaster.text" />
          <div
            v-if="visibleToaster.showModifyButton"
            :class="$style.toasterModifyButton"
            @click="visibleToaster.modifyButtonCallback"
          >
            {{ $awt('aw.common.toaster.modify') }}
          </div>
          <div v-if="visibleToaster.promise">
            <div :class="$style.actionButtons">
              <lv-button
                v-for="(v, k) in visibleToaster.promise"
                :key="k"
                type="button"
                :styling="v.styling || 'small-primary-light'"
                :shrink="screenRange['tablet-min']"
                :stretch="screenRange['mobile-max']"
                @click="resolveClose(k, visibleToaster)"
              >
                {{ $awt(v.textToken) }}
              </lv-button>
            </div>
          </div>
        </div>
        <button :class="['buttonReset', $style.toasterClose]" @click="resolveClose('close', visibleToaster)">
          <lv-icon name="close-24" :size="16" style="vertical-align: unset;" />
        </button>
      </div>
    </client-only>
    <div v-if="screenReaderOnlyToaster" :class="[$style.toaster, 'awSrOnly']">
      <div>
        <h4 :class="$style.toasterTitle">
          {{ screenReaderOnlyToaster.title }}
        </h4>
        <div :class="$style.toasterText" v-html="screenReaderOnlyToaster.text" />
        <div
          v-if="screenReaderOnlyToaster.showModifyButton"
          :class="$style.toasterModifyButton"
          @click="screenReaderOnlyToaster.modifyButtonCallback"
        >
          {{ $awt('aw.common.toaster.modify') }}
        </div>
      </div>
      <button
        v-if="screenReaderOnlyToaster.manualClose"
        :class="['buttonReset', $style.toasterClose]"
        @click="resolveClose('close',screenReaderOnlyToaster)"
      >
        <lv-icon name="close-24" :size="16" style="vertical-align: unset;" />
      </button>
    </div>
  </div>
</template>

<script>

  import { useToasterStore } from '~~/common/stores/toaster';
  import { LvButton, LvIcon } from '~~/common/components/loginet-vue-shop/index.mjs';
  import { mapState } from 'pinia';
  import { useUserInterfaceStore } from '~~/common/stores/userInterface.js';

  export default {
    name: 'AwToaster',
    components: {
      LvButton,
      LvIcon,
    },
    props: {
      toasters: {
        type: Object,
        required: true,
      },
      closeAfter: {
        type: Number,
        default: 6000,
      },
    },
    emits: ['remove-toaster'],
    computed: {
      ...mapState(useUserInterfaceStore, {
        screenRange: state => state.mediaQueries,
      }),
      visibleToaster () {
        return Object.values(this.toasters).find(toaster => !toaster.screenReaderOnly && (toaster.text || toaster.title || toaster.showModifyButton || toaster.promise));
      },
      screenReaderOnlyToaster () {
        return Object.values(this.toasters).find(toaster => toaster.screenReaderOnly);
      },
    },
    watch: {
      visibleToaster: {
        immediate: true,
        handler () {
          const hasCloseAfter = this.closeAfter || Object.values(this.toasters).filter(toaster => toaster.closeAfter);
          if (import.meta.server || !hasCloseAfter) {
            return;
          }
          const freshToaster = Object.entries(this.toasters).filter(([, val]) => val.uuid === this.visibleToaster?.uuid);
          for (const [key, val] of freshToaster) {
            //start the timer if manualClose false or the toaster has a promise property
            if (!val.manualClose && !val.promise) {
              setTimeout(() => {
                this.close(key);
              }, val.closeAfter || this.closeAfter);
            }
          }
        },
      },
      screenReaderOnlyToaster: {
        immediate: true,
        handler () {
          if (import.meta.server || !this.closeAfter) {
            return;
          }
          const freshToaster = Object.entries(this.toasters).filter(([, val]) => val.uuid === this.screenReaderOnlyToaster?.uuid);
          for (const [key, val] of freshToaster) {
            if (!val.manualClose) {
              setTimeout(() => {
                this.close(key);
              }, this.closeAfter);
            }
          }
        },
      },
    },
    methods: {
      resolveClose (k, toaster) {
        if (toaster.promise?.[k]) {
          toaster.promise[k].cb(k);
        }
        this.close(toaster.toasterKey);
      },
      close (key) {
        const toasterStore = useToasterStore();
        if (this.toasters === toasterStore.toasters) {
          toasterStore.remove(key);
        } else {
          //add remove-roaster event handler if toasters is not from toasterStore
          this.$emit('remove-toaster', key);
        }
      },

    },
  };

</script>

<style module lang="scss" rel="stylesheet/scss">
.toaster {
  position: fixed;
  z-index: 1000;
  bottom: 16px;
  left: calc(50% - 164px);
  display: grid;
  align-items: flex-start;
  width: 100%;
  max-width: 328px;
  padding: 20px 16px;
  color: $color-text-inverse;
  border-radius: 16px;
  background: $color-olive-750;
  grid-template-columns: auto 16px;
  gap: 8px;

  &Wrapper {
    position: relative;
    z-index: 6002;
  }

  &Close {
    height: 16px;
    margin-top: 2px;
  }

  &Title {
    font-size: $heading-4-font-size-desktop-version2;
    font-weight: $heading-4-font-weight-desktop-version2;
    line-height: $heading-4-line-height-desktop-version2;
    word-break: break-word;
    hyphens: auto;
  }

  &Text {
    font-size: 14px;
    font-weight: $font-weight-normal-v2;
    line-height: 20px;
    margin-top: 8px;
    word-break: break-word;
    hyphens: auto;
  }

  &ModifyButton {
    font-size: 14px;
    font-weight: $font-weight-medium-v2;
    line-height: 20px;
    cursor: pointer;
    text-decoration: underline;
  }

  .actionButtons {
    margin-top: 20px;

    button:not(:last-child) {
      @include tablet(min) {
        margin-right: 8px;
      }
      @include mobile(max) {
        margin-bottom: 16px;
      }
    }
  }
}
</style>
