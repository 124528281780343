import { mapState } from 'pinia';
import { useProductsStore } from '~~/common/stores/products';
import { PUSH_FILTER_PRODUCT } from '~~/common/plugins/aw-analytics.js';

export default {
  name: 'AwProductListFilterMixin',
  data () {
    return {
      activeFilters: {},
    };
  },
  computed: {
    ...mapState(useProductsStore, {
      filters: state => state.filters,
      filterBy: state => state.filterBy,
    }),
  },
  watch: {
    filterBy: {
      handler () {
        this.initActiveFilters();
      },
      deep: true,
    },
  },
  methods: {
    initActiveFilters () {
      let deepCopyFilterBy = false;
      // if available filterBy state
      if (this.filterByIsActive) {
        deepCopyFilterBy = JSON.parse(JSON.stringify(this.filterBy));
        // activate filters if before stored filter data in filterBy state, except in modal
        if (!this.useInModal) {
          this.$emit('activate-filter');
        }
      }
      // init local activeFilters, use this for input components eg. checkbox, radios, buttons etc.
      this.filters.forEach((filter) => {
        const deepC = deepCopyFilterBy[filter.id];
        if (filter.selectionType === 'range') {
          this.activeFilters[filter.id] = (deepC?.length ? deepC[0] : filter.values.map(value => parseInt(value.value)));
        } else if (filter.selectionType === 'singleSelection') {
          this.activeFilters[filter.id] = deepC ? deepC[0] : '';
        } else {
          this.activeFilters[filter.id] = deepC || [];
        }
      });
    },
    activateFilter (value, isSingleValue = false) {
      const productsStore = useProductsStore();
      isSingleValue ? productsStore.setActiveFilterSingleValue(value) : productsStore.setActiveFilter(value);
      if (value.price) {
        this.activeFilters.price = value.price;
      }
      if (!this.useInModal) {
        this.$emit('activate-filter');
      }
      try {
        Object.entries(value).forEach(([key, value]) => {
          if (Object.prototype.hasOwnProperty.call(this.filterBy, key)) {
            if (!Array.isArray(this.filterBy[key]) || this.filterBy[key].includes(value)) {
              const filter = this.filters.find(({ id }) => id === key);
              this.$awAnalytics[PUSH_FILTER_PRODUCT]({
                key: filter?.name,
                value: filter?.selectionType === 'range' ? value?.join?.('-') : filter?.values?.find?.(fv => fv.value === value)?.name,
              });
            }
          }
        });
      } catch (err) {
        this.$logger.error(err);
      }
    },
    resetFilter () {
      const productsStore = useProductsStore();
      productsStore.resetFilterBy();
      productsStore.throttledRefresh();
    },
    async deleteSpecifiedFilter (filterId) {
      const productsStore = useProductsStore();
      await productsStore.resetFilterByType(filterId);
      this.initActiveFilters();
    },
  },
};
