import { defineNuxtPlugin, useRuntimeConfig } from 'nuxt/app';
import { injectionNameClient, injectionNameGtm } from './_logging-configuration';

export default defineNuxtPlugin(function (nuxtApp) {
  const {
    warn,
    error,
    info,
    debug,
    trace,
  } = console;
  const LOG_LEVEL_TRACE = 'trace';
  const LOG_LEVEL_DEBUG = 'debug';
  const LOG_LEVEL_INFO = 'info';
  const LOG_LEVEL_WARN = 'warn';
  const LOG_LEVEL_ERROR = 'error';
  const LOG_LEVEL_FATAL = 'fatal';

  const LOG_LEVEL_HIERARCHY = [
    LOG_LEVEL_TRACE,
    LOG_LEVEL_DEBUG,
    LOG_LEVEL_INFO,
    LOG_LEVEL_WARN,
    LOG_LEVEL_ERROR,
    LOG_LEVEL_FATAL,
  ];

  const logger = {
    trace,
    debug,
    info,
    warn,
    error,
    fatal: error,
  };

  const generateLogger = ({
    selectedLogLevel,
    categoryName,
  }) => {
    if (!selectedLogLevel) {
      /* eslint-disable-next-line no-console */
      console.error(`selectedLogLevel is incorrect for categoryName ${categoryName}:`, selectedLogLevel);
    }
    const IDX_COMPARISON = LOG_LEVEL_HIERARCHY.indexOf(selectedLogLevel);
    return Object.fromEntries(Object.entries(logger).map(([k, v]) => {
      const isLogDisabled = LOG_LEVEL_HIERARCHY.indexOf(k) < IDX_COMPARISON;
      return [
        k,
        (isLogDisabled
          ? () => {
          }
          : v.bind(console, categoryName)
        ),
      ];
    }));
  };

  const {
    logLevelGtm,
  } = useRuntimeConfig().public;

  const loggers = {
    [injectionNameClient]: generateLogger({
      selectedLogLevel: LOG_LEVEL_TRACE,
      categoryName: 'aw -',
    }),
    [injectionNameGtm]: generateLogger({
      selectedLogLevel: logLevelGtm,
      categoryName: 'awGtm -',
    }),
  };

  for (const injectionName in loggers) {
    nuxtApp.provide(injectionName, loggers[injectionName]);
  }
});
