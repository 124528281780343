<template>
  <div :class="$style.social">
    <lv-grid
      type="row"
      align-items="center"
      wrap="wrap"
      gutter="md"
    >
      <lv-grid type="item" xs="1/1" lg="min">
        <lv-heading tag="h2" level="5">
          {{ $awt('aw.common.social.title') }}
        </lv-heading>
      </lv-grid>
      <lv-grid type="item" xs="1/1" lg="expand">
        <ul :class="$style.socialLinks">
          <li v-for="(s, i) in socials" :key="i" :class="$style.socialLinkItem">
            <lv-icon :name="s.icon" :size="24" />
            <lv-button
              v-bind="$hyperlinkComputeBindFromUrl($awt(s.href))"
              styling="link-primary"
              shrink
              rel="noreferrer"
              v-on="bannerEvtListeners[i]"
            >
              {{ $awt(s.text) }}
            </lv-button>
          </li>
        </ul>
      </lv-grid>
    </lv-grid>
  </div>
</template>

<script>
  import { LvGrid, LvHeading, LvButton, LvIcon } from '~~/common/components/loginet-vue-shop/index.mjs';
  import analyticsBannerInteractionMixin from '~~/common/mixins/analyticsBannerInteractionMixin.js';

  export default {
    name: 'AwSocial',
    components: {
      LvGrid,
      LvHeading,
      LvButton,
      LvIcon,
    },
    mixins: [analyticsBannerInteractionMixin],
    inheritAttrs: false,
    data () {
      return {
        socials: [
          {
            icon: 'facebook-24',
            href: 'aw.common.social.facebook_url',
            text: 'aw.common.social.facebook',
          },
          {
            icon: 'youtube-24',
            href: 'aw.common.social.youtube_url',
            text: 'aw.common.social.youtube',
          },
          {
            icon: 'instagram-24',
            href: 'aw.common.social.instagram_url',
            text: 'aw.common.social.instagram',
          },
        ],
      };
    },
    computed: {
      bannerEvtListeners () {
        const titlePrefix = this.$awt('aw.common.social.title');
        return this.socials.map(social => this.genBannerInteractionClickEventHandlers({ title: `${titlePrefix} ${this.$awt(social.text)}` }));
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
.social {
  @include lg() {
    padding-bottom: 16px;
  }

  padding: 16px 16px 0;
  border-radius: 8px;
  background: $color-white;
}

.socialLinks {
  @include lg() {
    display: grid;
    grid-auto-flow: column;
    padding: 0;
  }

  display: inline-grid;
  justify-content: space-between;
  gap: 24px;
  margin: 0;
  padding: 24px 0;
  list-style-type: none;
  text-align: left;
}

.socialLinkItem {
  display: grid;
  grid-auto-flow: column;
  justify-content: flex-start;
  gap: 12px;
}
</style>
