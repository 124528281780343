import { defineNuxtPlugin } from 'nuxt/app';
import { useRootStore } from '../stores/root';
import { setDdosHashCookie } from '~~/common/utils/ddosChecker.js';

export default defineNuxtPlugin(async (nuxtApp) => {
  const rootStore = useRootStore(nuxtApp.$pinia);
  if (import.meta.server) {
    await setDdosHashCookie(nuxtApp);
    await rootStore.nuxtServerInit();
  } else {
    await rootStore.clientInit();
  }
});
