<template>
  <aw-banner-content
    :content="content"
    position="BottomCenter"
    :size="size"
    :translation="translation"
    styling="tertiary"
  >
    <template #content>
      <div :class="$style.narwhalBanner">
        <lv-heading
          tag="h2"
          level="5"
          regular
          :class="$style.narwhalTitle"
        >
          {{ translation.title }}
        </lv-heading>
        <div v-if="translation.ctaText && hasLink">
          <lv-button styling="primary-small-outlined" v-bind="link" shrink>
            {{ translation.ctaText }}
          </lv-button>
        </div>
      </div>
    </template>
  </aw-banner-content>
</template>

<script>
  import AwBannerContent from './BannerPartials/AwBannerContent';
  import { LvHeading, LvButton } from '~~/common/components/loginet-vue-shop/index.mjs';
  import bannerMixin from '~~/shop/mixins/bannerMixin';

  /**
   * name: AwNarwhal
   * size: quarter
   * textPosition: bottom center
   * buttonPosition: bottom center
   * button_theme: text
   */
  export default {
    name: 'AwNarwhal',
    components: {
      LvHeading,
      LvButton,
      AwBannerContent,
    },
    mixins: [bannerMixin],
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
.narwhal {
  &Banner {
    display: flex;
    flex-direction: column;
    color: $color-black;
  }

  &Title {
    margin-bottom: 16px;
  }
}
</style>
