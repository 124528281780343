import { defineStore } from 'pinia';
import { useNuxtApp } from 'nuxt/app';
import { computed, ref } from 'vue';
import { useDeliveryStore } from './delivery';
import ObjectCache from '~~/common/utils/ObjectCache.js';

export const usePromotionStore = defineStore('promotion', () => {
  const nuxtApp = useNuxtApp();
  const deliveryStore = useDeliveryStore();

  const tiles = ref({});
  const contentSeoText = ref({});
  const banners = ref({});
  const carousels = ref({});
  const shortlists = ref({});
  const categoryBanners = ref([]);
  const catalog = ref({});

  // actions
  function setShortlist (payload) {
    shortlists.value[payload.id] = payload;
  }

  async function fetchCarousel ({ id }) {
    const { $api, $i18n } = nuxtApp;
    const postCode = deliveryStore.getPostCode;
    const cacheKey = ['carousels', id, $i18n.locale.value, postCode];
    cache.initCache(cacheKey, () => $api.$get(`promotion_carousel/${id}?page=1`));
    carousels.value[id] = await cache.getCache(cacheKey).value;
  }

  async function fetchContentSeoText ({ id }) {
    const { $api, $logger, $i18n } = nuxtApp;
    try {
      const postCode = deliveryStore.getPostCode;
      const cacheKey = ['contentSeoText', id, $i18n.locale.value, postCode];
      cache.initCache(cacheKey, () => $api.$get(`seo_text/${id}`));
      contentSeoText.value[id] = await cache.getCache(cacheKey).value;
    } catch (err) {
      if (err?.response?.status !== 404) {
        $logger.error(err);
      }
    }
  }

  async function fetchBanner ({ id }) {
    const { $api, $i18n } = nuxtApp;
    const postCode = deliveryStore.getPostCode;
    const cacheKey = ['banners', id, $i18n.locale.value, postCode];
    cache.initCache(cacheKey, () => $api.$get(`promotion/banner/${id}`));
    banners.value[id] = await cache.getCache(cacheKey).value;
  }

  async function fetchTiles ({ id }) {
    const { $api, $i18n } = nuxtApp;
    const postCode = deliveryStore.getPostCode;
    const cacheKey = ['tiles', id, $i18n.locale.value, postCode];
    cache.initCache(cacheKey, async () => {
      return (await $api.$get(`/promotions/tile/${id}`)).sort(tilePromotionSorter);
    });
    tiles.value[id] = await cache.getCache(cacheKey).value;
  }

  async function fetchShortlists ({ identifier, type }) {
    const { $api, $logger, $i18n } = nuxtApp;
    try {
      const postCode = deliveryStore.getPostCode;
      const cacheKey = ['shortlists', identifier, type, $i18n.locale.value, postCode];
      identifier = encodeURIComponent(identifier);
      cache.initCache(cacheKey, () => $api.$get(`/shortlist/${identifier}?type=${type}`));
      const result = await cache.getCache(cacheKey).value;
      setShortlist(result);
      return result?.id;
    } catch (err) {
      if (err?.response?.status !== 404) {
        $logger.error(err);
      }
    }
  }

  async function fetchRecommendedProducts (carouselId) {
    const { $api, $i18n } = nuxtApp;
    const postCode = deliveryStore.getPostCode;
    const cacheKey = ['recommendationCarousel', carouselId, $i18n.locale.value, postCode];
    cache.initCache(cacheKey, () => $api.$get(`products?page=1&itemsPerPage=15&recommendationCarouselId=${carouselId}`));
    const value = await cache.getCache(cacheKey).value;
    return value?.results || [];
  }

  async function fetchRecentlyViewedProducts () {
    const { $api, $logger } = nuxtApp;
    try {
      return (await $api.$get('/me/recently_viewed?itemsPerPage=12&page=1'))?.results || [];
    } catch (err) {
      $logger.error(err);
      return [];
    }
  }
  async function fetchRelatedProducts (productId) {
    const { $api, $logger } = nuxtApp;
    try {
      return (await $api.$get(`/products/${productId}/related_products?itemsPerPage=15&page=1`))?.results || [];
    } catch (err) {
      $logger.error(err);
      return [];
    }
  }

  async function fetchCatalog (id) {
    const { $api, $logger, $i18n } = nuxtApp;
    try {
      const postCode = deliveryStore.getPostCode;
      const cacheKey = ['catalog', id, $i18n.locale.value, postCode];
      cache.initCache(cacheKey, () => $api.$get(`catalog/${id}`));
      catalog.value = await cache.getCache(cacheKey).value;
      return false;
    } catch (err) {
      if (err?.response?.status !== 404) {
        $logger.error(err);
      }
      return err;
    }

  }

  // getters
  const getShortlistByUrl = computed(() => (url) => {
    const shortListKey = Object.keys(shortlists.value).find(key => url.includes(key));
    return shortlists.value[shortListKey];
  });

  return {
    tiles,
    contentSeoText,
    banners,
    carousels,
    shortlists,
    categoryBanners,
    catalog,
    setShortlist,
    fetchCarousel,
    fetchContentSeoText,
    fetchBanner,
    fetchTiles,
    fetchShortlists,
    fetchRecommendedProducts,
    fetchRecentlyViewedProducts,
    fetchRelatedProducts,
    fetchCatalog,
    getShortlistByUrl,
  };
});

const cache = new ObjectCache('promotion', '__');

/**
 * @param {{position: number}} a
 * @param {{position: number}} b
 */
function tilePromotionSorter (a, b) {
  if (a.position < b.position) {
    return -1;
  } else if (a.position > b.position) {
    return 1;
  } else {
    return 0;
  }
}
