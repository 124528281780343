import axios from 'axios';

/**
 * @description https://developer.vimeo.com/api/oembed/videos
 */
export async function vimeoMeta (videoId, $logger = console) {
  if (typeof videoId !== 'string') {
    $logger.warn(`Video id ${videoId} is not string.`);
    return;
  }
  return await axios({
    method: 'GET',
    url: 'https://vimeo.com/api/oembed.json',
    params: {
      url: `https://vimeo.com/${videoId}`,
    },
  }).then(({ data: { thumbnail_url } }) => {
    return {
      thumbnail_url,
    };
  }).catch((error) => {
    if (error?.response?.status === 404) {
      $logger.error(`Video not found: ${videoId}`);
    } else {
      $logger.error(error);
    }
    return {
      thumbnail_url: '',
    };
  });
}
