import { useRoute } from 'nuxt/app';

export default {
  computed: {
    isHomePage () {
      const route = useRoute();
      return route.path.split('/').length === 2;
    },
    isProductListPage () {
      const route = useRoute();
      return /.*?\.c-([0-9]+)/gi.test(route.path);
    },
    isProductDetailsPage () {
      const route = useRoute();
      return /.*?\.p-([0-9]+)/gi.test(route.path);
    },
    isSearchPage () {
      const route = useRoute();
      return /.*?\/search\?(.*)/gi.test(route.fullPath);
    },
    isBoutique () {
      const route = useRoute();
      return /.*?\.b-([0-9]+)/gi.test(route.path);
    },
    isShortList () {
      const route = useRoute();
      return /.*?\/list/gi.test(route.path);
    },
    isLoyaltyOffer () {
      const route = useRoute();
      return /.*?\/loyalty-offers/gi.test(route.path);
    },
    isPreviouslyOrderedProducts () {
      const route = useRoute();
      return /.*?\/order-again/gi.test(route.path);
    },
    isFaqPage () {
      const route = useRoute();
      return /.*?\/faq/gi.test(route.path);
    },
  },
};
