import { ref } from 'vue';
import { defineStore } from 'pinia';
import { UnCachedProduct, updateProdWithUnCachedData, useProductsStore } from '~~/common/stores/products.js';

export const useAwSfRecommendationStore = defineStore('awSfRecommendation', () => {
  const recommendations = ref([]);
  async function fetchSFRecommendation (recos) {
    clearRecommendation();

    if (recos.length > 0) {
      const productsStore = useProductsStore();
      // only first reco display needed
      const reco = recos[0];

      const ids = reco.items.map(product => {
        return product.product_code;
      });

      const params = {
        isCached: true,
        page: 1,
        itemsPerPage: 16,
        auchanCodes: ids,
        filterParams: {
          available: 1,
        },
      };

      const {
        value: data,
        isCached,
      } = await productsStore.fetchCachedProductOrFallback({ params });

      recommendations.value = [{
        ...reco,
        products: data?.results,
      }];

      if (isCached) {
        const resp = await productsStore.getUnCachedPartsReq({ productIds: (ids || []).map(p => p.id) });
        if (resp?.list && Object.keys(resp.list).length) {
          for (const k in resp.list) {
            resp.list[k] = new UnCachedProduct(resp.list[k]);
          }
          updateProdWithUnCachedData({
            productsToMutate: recommendations.value[reco.name].products,
            payload: resp.list,
            $logger: this.$logger,
          });
        }
      }

      recommendations.value = recommendations.value.sort((a, b) => (a.priority > b.priority) ? 1 : -1);
    }
  }
  function clearRecommendation () {
    recommendations.value = [];
  }

  return {
    recommendations,
    fetchSFRecommendation,
    clearRecommendation,
  };
});
