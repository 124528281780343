export default {
  inject: {
    awFormUuid: {
      from: 'uuid',
    },
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },
  emits: ['update:modelValue'],
  data () {
    return {
      modelValueData: {},
      backendValidation: { errors: {}, propertyPaths: {} },
      blur: false,
    };
  },
  created () {
    this.modelValueData = this.modelValue;
  },
  computed: {
    rowUniqueId () {
      // NOTE: uuid4 is not suitable for id start as id has to start with
      // letter. Using uuid4 could result in querySelector not working.
      return `aw_${this.awFormUuid}_${this.modelValue.name}`;
    },
    widgetUniqueId () {
      return this.modelValue?.widgetAttrs?.id || `${this.rowUniqueId}_widget`;
    },
    labelUniqueId () {
      return (this.modelValue.label || this.modelValue.outerLabel) ? this.modelValue?.labelAttrs?.id || `${this.rowUniqueId}_label` : null;
    },
    rowValidator () {
      const nestedProperty = this.getNestedProperty(this.modelValue.validation, this.modelValue.name);
      return nestedProperty?.model ?? undefined;
    },
    hasError () {
      if (this.rowValidator) {
        return this.rowValidator.$error && this.rowValidator.$dirty;
      }
      return false;
    },
    isInvalid () {
      if (this.rowValidator) {
        return this.rowValidator.$invalid;
      }
      return false;
    },

    hasSuccess () {
      if (this.rowValidator) {
        return !this.rowValidator.$invalid && this.rowValidator.$dirty;
      }
      return false;
    },
    isRequired () {
      if (this.rowValidator) {
        return this.hasProperty(this.rowValidator, 'required');
      }
      return false;
    },
    isAsterisk () {
      const showAsterisk = this.modelValue?.labelAttrs?.showAsterisk;
      if ([true, false].includes(showAsterisk)) {
        return showAsterisk;
      } else if (showAsterisk !== undefined) {
        this.$logger.warn(`Invalid showAsterisk value in AwFormLabel: ${showAsterisk}`);
      }
      return this.isRequired;
    },
    labelValue () {
      return this.modelValue.label + (this.isAsterisk ? '<sup>*</sup>' : '');
    },
  },
  watch: {
    modelValue: {
      handler () {
        this.modelValueData = this.modelValue;
      },
      deep: true,
    },

    'modelValueData.model': {
      handler () {
        this.$emit('update:modelValue', this.modelValueData);
      },
    },
  },
  methods: {
    onBlur () {
      // This is important in safari, to make sure validation triggered after blur
      setTimeout(this.validateTheRow, 200);
      this.blur = true;
    },
    hasProperty (object, oKey) {
      return object && Object.prototype.hasOwnProperty.call(object, oKey);
    },

    getNestedProperty (object, oKey) {
      if (!object) {
        return undefined;
      }
      if (this.hasProperty(object, oKey)) {
        return object[oKey];
      }
      for (const nestedValue of Object.values(object).filter(value => typeof value === 'object' && !Array.isArray(value))) {
        if (this.hasProperty(nestedValue, oKey)) {
          return nestedValue[oKey];
        }
      }
      return undefined;
    },
    validateTheRow () {
      if (this.rowValidator) {
        this.rowValidator.$touch();
      }
    },
    emitData (data) {
      this.$emit('update:modelValue', data);
    },
    findComponent (componentName) {
      let component = null;
      let parent = this.$parent;
      while (parent && !component) {
        if (parent.$options.name === componentName) {
          component = parent;
        }
        parent = parent.$parent;
      }
      return component;
    },
  },
};
