<template>
  <label
    :class="[
      $style.radio,
      {[$style.radioDisabled]: disabled}
    ]"
    :for="radioId"
  >
    <input
      :id="radioId"
      type="radio"
      :name="radioName"
      :disabled="disabled"
      :checked="checked"
      @change="$parent.$emit('update:modelValue', value)"
      @blur="$emit('blur', $event)"
      @keydown.enter="event => $emit('enter', event)"
    >
    <lv-icon
      :size="24"
      :name="iconName"
      fill
      :class="$style.radioIcon"
      aria-hidden="true"
    />
    <div
      :class="[
        $style.radioLabel,
        {[$style.radioLabelDisabled]: disabled}
      ]"
    >
      <span v-if="$slots.label?.()?.[0]?.children?.length">
        <slot name="label" />
      </span>
      <span v-else-if="label" v-text="label" />
    </div>
  </label>
</template>

<script>
  import { LvIcon } from '~~/common/components/loginet-vue-shop/index.mjs';
  import { uuid4 } from '~~/common/utils';

  export default {
    name: 'AwRadioVersion2',
    components: {
      LvIcon,
    },
    props: {
      disabled: {
        type: Boolean,
        default: false,
      },
      label: {
        type: String,
        default: '',
      },
      value: {
        type: [Boolean, String, Number],
        default: '',
        required: true,
      },
    },
    emits: [
      'blur',
      'enter',
      'update:modelValue',
    ],
    data () {
      return {
        inputId: null,
      };
    },
    computed: {
      radioId () {
        return `radio-${this.$parent.groupId}-|-${this.inputId}`;
      },
      radioName () {
        return `radio-${this.$parent.groupId}`;
      },
      checked () {
        return this.value.toString() && this.$parent.modelValue.toString() === this.value.toString();
      },
      iconName () {
        return this.checked
          ? !this.disabled ? 'radio-checked-version2-24' : 'radio-checked-disabled-v2-24'
          : !this.disabled ? 'radio-unchecked-version2-24' : 'radio-unchecked-disabled-v2-24';
      },
    },
    mounted () {
      this.inputId = uuid4();
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
.radio {
  display: grid;
  grid-template-columns: min-content auto;
  gap: 8px;
  align-items: center;
  cursor: pointer;

  &Disabled {
    cursor: not-allowed;
  }

  &Label {
    font-family: $secondary-font;
    font-size: 14px;
    font-weight: $font-weight-normal-v2;
    line-height: 14px;
    color: $color-text-primary;

    &Disabled {
      cursor: not-allowed;
      color: $color-text-tertiary;
    }
  }

  &Icon {
    width: 24px;
    height: 24px;
  }

  input {
    appearance: none;
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0.01;
  }

  input:focus-visible + * + .radioLabel {
    text-decoration: underline;
  }

}
</style>
