<template>
  <aw-radio-group
    v-model="radioValue"
    v-bind="appliedAttrs"
  >
    <aw-radio
      v-for="(radio, i) in modelValue.radioOptions"
      :key="i"
      :label="radio.label"
      :label-size="labelSize"
      :value="radio.value"
      :disabled="radio.disabled"
      v-bind="awListeners"
      @blur="onBlur"
    >
      <template #label>
        <slot name="label" />
      </template>
    </aw-radio>
  </aw-radio-group>
</template>

<script>
  import formMixin from '~~/common/mixins/formMixin';
  import awListenersMixin from '~~/common/mixins/awListenersMixin.js';
  import AwRadioGroup from '~~/common/components/Common/Widgets/AwRadioGroup';
  import AwRadio from '~~/common/components/Common/Widgets/AwRadio';

  export default {
    name: 'AwFormRadioGroup',
    components: {
      AwRadio,
      AwRadioGroup,
    },
    mixins: [
      formMixin,
      awListenersMixin,
    ],
    inheritAttrs: false,
    computed: {
      radioValue: {
        get () {
          return this.modelValue.model;
        },
        set (value) {
          this.modelValueData.model = value;
          if (this.blur) {
            this.validateTheRow();
          }
          this.emitData(this.modelValueData);
        },
      },
      labelSize () {
        return this.modelValue?.widgetAttrs?.labelSize;
      },
      appliedAttrs () {
        return {
          ...this.awAttrsNoListeners,
          ...this.modelValue.widgetAttrs,
        };
      },
    },
  };
</script>
