import { defineNuxtPlugin } from 'nuxt/app';

export default defineNuxtPlugin((nuxtApp) => {
  const {
    $awMergedConfig,
    $logger,
  } = nuxtApp;
  let scriptTags = [];

  const oneTrustScripts = [
    // {
    //   properties: {
    //     id: 'OneTrust',
    //     src: `https://cdn.cookielaw.org/consent/${$awMergedConfig.onetrustId}/OtAutoBlock.js`,
    //     async: true,
    //   },
    //   attributes: {},
    // },
    {
      properties: {
        src: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
        defer: true,
      },
      attributes: {
        'data-document-language': true,
        'data-domain-script': $awMergedConfig.onetrustId,
      },
    },
    {
      properties: {
        innerHTML: 'function OptanonWrapper () { if (window.awRefreshConsent) {window.awRefreshConsent();} }',
      },
      attributes: {},
    },
  ];

  if ($awMergedConfig.onetrustEnabled && $awMergedConfig.onetrustId && !$awMergedConfig.onetrustDelayEnabled) {
    scriptTags = scriptTags.concat(oneTrustScripts);
  }

  if ($awMergedConfig.cookiebotId) {
    scriptTags.push({
      properties: {
        id: 'Cookiebot',
        src: 'https://consent.cookiebot.com/uc.js',
        defer: true, // If async is not present and defer is present: The script is executed when the page has finished parsing
        preconnect: true,
      },
      attributes: {
        'data-cbid': $awMergedConfig.cookiebotId,
        'data-blockingmode': 'auto',
      },
    });
  }
  if ($awMergedConfig.optinMonsterAccountId && $awMergedConfig.optinMonsterUserId) {
    scriptTags.push({
      properties: {
        innerHTML: `(function(d,u,ac){var s=d.createElement('script');s.src='https://a.omappapi.com/app/js/api.min.js';s.async=true;s.dataset.user=u;s.dataset.account=ac;d.head.appendChild(s);})(document,${$awMergedConfig.optinMonsterUserId},${$awMergedConfig.optinMonsterAccountId});`,
      },
      attributes: {},
    });
  }

  if ($awMergedConfig.isPrefixboxEnabled && $awMergedConfig.prefixboxWebsiteTracker) {
    scriptTags = scriptTags.concat([
      {
        properties: {
          innerHTML: 'window.prefixboxAnalytics=window.prefixboxAnalytics||function(t,s,p){(window.pfbxQueue=window.pfbxQueue||[]).push({type:t,source:s,params:p});};',
        },
        attributes: {},
      },
      {
        properties: {
          src: 'https://cdn.prefixbox.com/plugin/integration.js',
          id: 'prefixbox-integration',
          defer: true, // If async is not present and defer is present: The script is executed when the page has finished parsing
        },
        attributes: {
          website: $awMergedConfig.prefixboxWebsiteTracker,
        },
      },
    ]);
  }
  //

  if ($awMergedConfig.chatbotEnabled && $awMergedConfig.chatbotId && $awMergedConfig.chatbotUrl) {
    scriptTags.push({
      properties: {
        innerHTML: `
        !function (a, b, c, e, f,) {
          var d = a.createElement("script");
          d.id = "wcw_socket_script", d.src = b + "/js/WCWSocket2.js?cid=" + c + "&uri=" + encodeURIComponent(a.location) + "&native=" + e + "&language=" + f, document.head.appendChild(d)
        }(document, '${$awMergedConfig.chatbotUrl}', '${$awMergedConfig.chatbotId}', true, "HU");
        window.addEventListener("message", function (e) {
          if (e.data === "closeWebview") {
            window.tabWebviewClose();
          }
        }, false);`,
      },
      attributes: {},
    });
  }

  if ($awMergedConfig.optimonkAccountId) {
    scriptTags.push({
      properties: {
        src: `https://onsite.optimonk.com/script.js?account=${$awMergedConfig.optimonkAccountId}`,
        async: true,
      },
      attributes: {},
    });
  }

  const isDelayable = ['', '1', 'true'].includes(new URLSearchParams(location.search).get('delayThirdParty')) && !Number.isNaN($awMergedConfig.thirdPartyScriptDelayMs);

  if (isDelayable) {
    setTimeout(() => {
      insertScripts(scriptTags);
    }, $awMergedConfig.thirdPartyScriptDelayMs);
  } else {
    insertScripts(scriptTags);
  }

  nuxtApp.provide('oneTrustScripts', oneTrustScripts);
  nuxtApp.provide('insertScripts', insertScripts);

  function insertScripts (scriptsToInsert) {
    const df = new DocumentFragment();
    for (const script of scriptsToInsert) {
      try {
        const scriptElement = document.createElement('script');
        for (const p in script.properties) {
          scriptElement[p] = script.properties[p];
        }
        for (const a in script.attributes) {
          scriptElement.setAttribute(a, script.attributes[a]);
        }
        df.appendChild(scriptElement);
      } catch (err) {
        $logger.error(err);
      }
    }
    document.head.appendChild(df);
  }
});
