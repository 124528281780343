import { ref } from 'vue';
import { defineStore } from 'pinia';
import { useNuxtApp, useRuntimeConfig } from 'nuxt/app';
import ObjectCache from '~~/common/utils/ObjectCache.js';
import { setErrorNotification } from '~~/common/utils/helper';
import { M_DELIVERY_METHOD } from '~~/common/config/Modal.js';
import { PUSH_REVIEW_PRODUCT } from '~~/common/plugins/aw-analytics.js';
import { commitAlcoholAlertModal, commitAlcoholConfirmModal } from '~~/common/utils/alcoholManagement';
import { useModalStore } from '~~/common/stores/modal';
import { useCategoryStore } from '~~/common/stores/category';
import { useConfigStore } from '~~/common/stores/config';
import { useUserStore } from '~~/common/stores/user';
import { useDeliveryStore } from '~~/shop/stores/delivery';
import { useAuthenticationStore } from '~~/common/stores/authentication.js';

const VARIANT_PROP = 'selectedVariant';

const productReqCache = new ObjectCache('productReq', '__');

export const useProductStore = defineStore('product', () => {
  const nuxtApp = useNuxtApp();
  const config = useRuntimeConfig();
  const configStore = useConfigStore();
  const deliveryStore = useDeliveryStore();
  const categoryStore = useCategoryStore();
  const userStore = useUserStore();
  const modalStore = useModalStore();
  const authenticationStore = useAuthenticationStore();

  const data = ref({});
  const loading = ref(false);

  function setProductLoading (newLoadingValue) {
    loading.value = newLoadingValue;
  }

  function setProduct (product) {
    data.value = product;
  }

  function setStockInfos (payload) {
    data.value[VARIANT_PROP].stockInfos = payload;
  }

  function setBundles (payload) {
    data.value[VARIANT_PROP].bundles = payload;
  }

  function setDetails (payload) {
    data.value[VARIANT_PROP].details = payload;
  }

  function setDeliveryPrice (payload) {
    data.value[VARIANT_PROP].deliveryPrice = payload;
  }

  function setIngredients (payload) {
    data.value[VARIANT_PROP].ingredients = payload;
  }

  function setIngredientsDetailed (payload) {
    data.value[VARIANT_PROP].ingredientsDetailed = payload;
  }

  function setNutrition (payload) {
    data.value[VARIANT_PROP].nutrition = payload;
  }

  function setAllergens (payload) {
    data.value[VARIANT_PROP].allergens = payload;
  }

  function setAllergensDetailed (payload) {
    data.value[VARIANT_PROP].allergensDetailed = payload;
  }

  async function fetchMainProductForProductPage ({
    loc,
    identifierType,
    id,
  }) {
    const {
      $i18n,
    } = nuxtApp;

    // PL store code mapping
    const companies = (configStore.configCommon?.companies || []);
    const QP_STORE_CODE = loc !== undefined ? `company_id=${companies.find(c => c.name === loc)?.id}` : '';
    const cacheKey = [$i18n.locale.value, deliveryStore.getPostCode, identifierType, id, QP_STORE_CODE];
    productReqCache.initCache(cacheKey, () => fetchMainProduct({
      identifierType,
      id,
      params: QP_STORE_CODE,
    }));
    const product = await productReqCache.getCache(cacheKey).value;
    productReqCache.deleteCache(cacheKey);
    return product;
  }

  async function fetchMainProduct ({
    identifierType,
    id,
    params,
    specialErrorMessage,
  }) {
    const {
      $api,
      $awt,
      $logger,
    } = nuxtApp;

    await authenticationStore.reInitToken();

    try {
      setProductLoading(true);
      const categoryIdBeforeVisitProductPage = categoryStore.categoryIdBeforeVisitProductPage;
      let queryString = `${categoryIdBeforeVisitProductPage ? `?category_id=${categoryIdBeforeVisitProductPage}` : ''}`;
      queryString = params ? `${queryString}${queryString ? '&' : '?'}${params}` : queryString;
      const result = await $api.$get(`/products/${identifierType}/${id}${queryString}`);
      let hasBundles = false;
      if (data.value?.[VARIANT_PROP]?.bundles) {
        hasBundles = true;
      }
      setProduct(result);

      if (hasBundles) {
        await fetchBundles({
          id,
          variantId: data.value[VARIANT_PROP].id,
        });
      }

      // alcohol product alert when...
      // - adultsOnly is true
      // - not opened delivery modal
      if (result?.adultsOnly && modalStore.activeGenericModal.type !== M_DELIVERY_METHOD) {
        if (userStore.isEcomUser && !result?.ageConfirmed && !userStore.showAlcoholAlertModalBeforeLogIn) {
          commitAlcoholConfirmModal($awt);
        } else if (!userStore.isEcomUser) {
          commitAlcoholAlertModal({ $awt });
        }
      }
      return result;
    } catch (error) {
      setProduct(false);
      if (error?.response?.status !== 404) {
        $logger.error(specialErrorMessage ?? error);
      }
      await setErrorNotification({ nuxtApp, error: specialErrorMessage ?? error });
      return false;
    } finally {
      setProductLoading(false);
    }
  }

  async function fetchStockInfos ({
    id,
    variantId,
  }) {
    const {
      $api,
      $logger,
    } = nuxtApp;
    try {
      (config.public.isShop
        ? $api.$get(`/products/${id}/variants/${variantId}/stock_infos`)
        : Promise.resolve([])
      ).then((payload) => {
        setStockInfos(payload);
      });
    } catch (error) {
      if (error?.response?.status !== 404) {
        setProduct(false);

        $logger.error(error);
        await setErrorNotification({ nuxtApp, error });
      }
    } finally {
      setProductLoading(false);
    }
  }

  async function fetchBundles ({
    id,
    variantId,
  }) {
    const {
      $api,
      $logger,
    } = nuxtApp;
    try {
      (config.public.isShop
        ? $api.$get(`/products/${id}/variants/${variantId}/bundles?itemsPerPage=100&page=1`)
        : Promise.resolve({
          itemCount: 0,
          pageCount: 1,
          currentPage: 1,
          result: [],
        })
      ).then((payload) => {
        setBundles(payload);
      });
    } catch (error) {
      setProductLoading(false);
      setProduct(false);
      $logger.error(error);
      await setErrorNotification({ nuxtApp, error });
    }
  }

  async function fetchReviews ({
    id,
    variantId,
  }) {
    const {
      $api,
      $logger,
    } = nuxtApp;
    try {
      return (config.public.isShop
        ? $api.$get(`/products/${id}/variants/${variantId}/reviews?page=1`)
        : Promise.resolve([])
      );
    } catch (error) {
      $logger.error(error);
      await setErrorNotification({ nuxtApp, error });
    }
  }

  async function fetchDeliveryPrice ({
    id,
    variantId,
  }) {
    const {
      $api,
      $logger,
    } = nuxtApp;
    try {
      (config.public.isShop
        ? $api.$get(`/products/${id}/variants/${variantId}/delivery_price`)
        : Promise.resolve({})
      ).then((payload) => {
        setDeliveryPrice(payload);
      });
    } catch (error) {
      setProductLoading(false);
      setProduct(false);
      $logger.error(error);
      await setErrorNotification({ nuxtApp, error });
    }
  }

  async function fetchDetails ({
    id,
    variantId,
  }) {
    const {
      $api,
      $logger,
    } = nuxtApp;
    try {
      $api.$get(`/products/${id}/variants/${variantId}/details`).then((payload) => {
        setDetails(payload || []);
      });
    } catch (error) {
      setProductLoading(false);
      setProduct(false);
      $logger.error(error);
      await setErrorNotification({ nuxtApp, error });
    }
  }

  async function fetchDescription ({
    id,
    variantId,
  }) {
    const {
      $api,
      $logger,
    } = nuxtApp;
    try {
      return await $api.$get(`/products/${id}/variants/${variantId}/details/description`);
    } catch (error) {
      if (error?.response?.status !== 404) {
        $logger.error(error);
        await setErrorNotification({ nuxtApp, error });
      }
    }
    return { description: '' };
  }

  async function fetchIngredients ({
    id,
    variantId,
  }) {
    const {
      $api,
      $logger,
    } = nuxtApp;
    try {
      const result = await $api.$get(`/products/${id}/variants/${variantId}/details/ingredients`);
      setIngredients(result);
    } catch (error) {
      if (error?.response?.status !== 404) {
        setProduct(false);
        $logger.error(error);
        await setErrorNotification({ nuxtApp, error });
      }
    } finally {
      setProductLoading(false);
    }
  }

  async function fetchIngredientsDetailed ({
    id,
    variantId,
  }) {
    const {
      $api,
      $logger,
    } = nuxtApp;
    try {
      const result = await $api.$get(`/products/${id}/variants/${variantId}/details/ingredientsDetailed`);
      setIngredientsDetailed(result);
    } catch (error) {
      if (error?.response?.status !== 404) {
        setProduct(false);
        $logger.error(error);
        await setErrorNotification({ nuxtApp, error });
      }
    } finally {
      setProductLoading(false);
    }
  }

  async function fetchParameterList ({
    id,
    variantId,
  }) {
    const {
      $api,
      $logger,
    } = nuxtApp;
    try {
      return await $api.$get(`/products/${id}/variants/${variantId}/details/parameterList`);
    } catch (error) {
      if (error?.response?.status !== 404) {
        $logger.error(error);
        await setErrorNotification({ nuxtApp, error });
      }
    }
    return { parameters: [] };
  }

  async function fetchNutrition ({
    id,
    variantId,
  }) {
    const {
      $api,
      $logger,
    } = nuxtApp;
    try {
      const result = await $api.$get(`/products/${id}/variants/${variantId}/details/nutrition`);
      setNutrition(result);
    } catch (error) {
      if (error?.response?.status !== 404) {
        setProduct(false);
        $logger.error(error);
        await setErrorNotification({ nuxtApp, error });
      }
    } finally {
      setProductLoading(false);
    }
  }

  async function fetchAllergens ({
    id,
    variantId,
  }) {
    const {
      $api,
      $logger,
    } = nuxtApp;
    try {
      const result = await $api.$get(`/products/${id}/variants/${variantId}/details/allergens`);
      setAllergens(result);
    } catch (error) {
      if (error?.response?.status !== 404) {
        setProduct(false);
        $logger.error(error);
        await setErrorNotification({ nuxtApp, error });
      }
    } finally {
      setProductLoading(false);
    }
  }

  async function fetchAllergensDetailed ({
    id,
    variantId,
  }) {
    const {
      $api,
      $logger,
    } = nuxtApp;
    try {
      const result = await $api.$get(`/products/${id}/variants/${variantId}/details/allergensDetailed`);
      setAllergens(result);
    } catch (error) {
      if (error?.response?.status !== 404) {
        setProduct(false);
        $logger.error(error);
        await setErrorNotification({ nuxtApp, error });
      }
    } finally {
      setProductLoading(false);
    }
  }

  async function fetchProductCategory ({ categoryId }) {
    const { $logger } = nuxtApp;
    const categoryStore = useCategoryStore();
    try {
      await categoryStore.fetchCategoryById(categoryId);
    } catch (error) {
      $logger.error(error);
      await setErrorNotification({ nuxtApp, error });
    }
  }

  async function fetchProductParts ([id, result]) {
    const {
      $api,
      $logger,
    } = nuxtApp;
    try {
      const categoryId = result.categoryId;
      const inCategories = result.inCategories;

      const commiter = (action, payload) => {
        if (data.value?.id?.toString() === result?.id.toString()) {
          action(payload);
        }
      };
      const categoryStore = useCategoryStore();
      // TODO: find a better solution which than config.public.isShop check
      await Promise.all(
        [
          (config.public.isShop
            ? $api.$get(`/products/${id}/variants/${result[VARIANT_PROP].id}/stock_infos`)
            : Promise.resolve([])
          ).then((payload) => {
            commiter(setStockInfos, payload);
          }),
          (config.public.isShop
            ? $api.$get(`/products/${id}/variants/${result[VARIANT_PROP].id}/bundles?itemsPerPage=100&page=1`)
            : Promise.resolve({
              itemCount: 0,
              pageCount: 1,
              currentPage: 1,
              result: [],
            })
          ).then((payload) => {
            commiter(setBundles, payload);
          }),
          $api.$get(`/products/${id}/variants/${result[VARIANT_PROP].id}/details`).then((payload) => {
            commiter(setDetails, payload || []);
          }),
          (config.public.isShop
            ? $api.$get(`/products/${id}/variants/${result[VARIANT_PROP].id}/delivery_price`)
            : Promise.resolve({})
          ).then((payload) => {
            commiter(setDeliveryPrice, payload);
          }),
          categoryStore.fetchCategoryByIdSafe({
            categoryId,
            inCategories,
          }),
        ],
      );
    } catch (error) {
      setProduct(false);
      $logger.error(error);
      await setErrorNotification({ nuxtApp, error });
    } finally {
      setProductLoading(false);
    }
  }

  async function pushNewReview ({
    id,
    variantId,
    variantSku,
    rating,
    editorName,
    body,
    anonym,
    status,
  }) {
    const {
      $api,
      $logger,
      $awAnalytics,
    } = nuxtApp;
    try {
      const result = await $api.post(`products/${id}/variants/${variantId}/reviews`, {
        rating,
        editorName,
        body,
        anonym,
        status,
      });
      try {
        $awAnalytics[PUSH_REVIEW_PRODUCT]({
          productSku: variantSku,
          numberOfStars: rating,
        });
      } catch (err) {
        $logger.error(err);
      }
      return [true, result];
    } catch (err) {
      $logger.error(err);
      return [false, err];
    }
  }

  async function removeReview ({ reviewId }) {
    const {
      $api,
      $logger,
    } = nuxtApp;
    try {
      return [true, await $api.$delete(`/me/reviews/${reviewId}`)];
    } catch (err) {
      $logger.error(err);
      return [false, err];
    }
  }

  const isPriceDiscounted = price => price.discounted || price.isDiscounted;

  return {
    data,
    loading,
    setProductLoading,
    setProduct,
    setStockInfos,
    setBundles,
    setDetails,
    setDeliveryPrice,
    setIngredients,
    setIngredientsDetailed,
    setNutrition,
    setAllergens,
    setAllergensDetailed,
    fetchMainProductForProductPage,
    fetchMainProduct,
    fetchStockInfos,
    fetchBundles,
    fetchReviews,
    pushNewReview,
    removeReview,
    fetchDeliveryPrice,
    fetchDetails,
    fetchDescription,
    fetchIngredients,
    fetchIngredientsDetailed,
    fetchParameterList,
    fetchNutrition,
    fetchAllergens,
    fetchAllergensDetailed,
    fetchProductCategory,
    fetchProductParts,
    isPriceDiscounted,
  };
});
