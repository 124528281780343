<template>
  <a v-if="content.image.resources.length" :href="content.externalUrl" :class="[$style.link, {[$style.withImage]: content.image.resources.length}]">
    <lv-image
      auto-height
      type="default"
      :sources="content.image.resources"
      :style="imageStyle"
      :alt="altAttr"
    />
  </a>
</template>

<script>
  import { LvImage } from '~~/common/components/loginet-vue-shop/index.mjs';
  import bannerMixin from '~~/shop/mixins/bannerMixin';

  /**
   * name: AwTile
   * size: general
   */
  export default {
    name: 'AwTile',
    components: { LvImage },
    mixins: [bannerMixin],
    props: {
      imageStyle: {
        type: String,
        default: '',
      },
    },
    computed: {
      altAttr () {
        return this.content.image.resources?.find?.(r => r?.alt)?.alt || '';
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
.link {
  display: flex;
  height: 100%;

  &.withImage {
    align-items: center;
  }
}

.picture {
  flex-grow: 1;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 8px;

  &.withImage {
    height: auto;
  }
}
</style>
