<template>
  <div :class="$style.recommendation">
    <client-only>
      <lv-heading tag="h3" :level="screenRange['tablet-min'] ? '2' : '3'" version2>
        {{ $awt('aw.404page.recommendation_module.title') }}
      </lv-heading>
    </client-only>
    <div :class="$style.recommendationContainer">
      <lv-link
        v-for="(item, idx) in items"
        :key="idx"
        :to="item.link"
        :class="$style.recommendationItem"
      >
        <div :class="$style.iconWrapperHover" />
        <div :class="$style.iconWrapper">
          <lv-icon name="birdy-48" :size="48" :class="$style.iconHover" />
          <lv-icon :name="item.icon" :size="24" :class="$style.icon" />
        </div>
        <div>
          <span :class="$style.recommendationItemTitle" v-html="item.title" />
          <span :class="[$style.recommendationItemTitle, $style.recommendationItemTitleHover]" aria-hidden="true" v-html="item.title" />
        </div>
        <client-only>
          <lv-icon name="404-page-auchan-logo-104" :size="screenRange['tablet-min'] ? 104 : 72" :class="$style.auchanLogo" />
        </client-only>
      </lv-link>
    </div>
  </div>
</template>

<script setup>
  import { useNuxtApp } from 'nuxt/app';
  import { LvHeading, LvLink, LvIcon } from '~~/common/components/loginet-vue-shop/index.mjs';
  import { useUserInterfaceStore } from '~~/common/stores/userInterface.js';

  const {
    $awt,
  } = useNuxtApp();

  const screenRange = computed(() => {
    return useUserInterfaceStore().mediaQueries;
  });

  const items = [
    {
      link: $awt('aw.404page.recommendation_module.items.link1'),
      icon: 'pig-24',
      title: $awt('aw.404page.recommendation_module.items.title1'),
    },
    {
      link: $awt('aw.404page.recommendation_module.items.link2'),
      icon: 'bird-24',
      title: $awt('aw.404page.recommendation_module.items.title2'),
    },
    {
      link: $awt('aw.404page.recommendation_module.items.link3'),
      icon: 'business-24',
      title: $awt('aw.404page.recommendation_module.items.title3'),
    },
    {
      link: $awt('aw.404page.recommendation_module.items.link4'),
      icon: 'flash-24',
      title: $awt('aw.404page.recommendation_module.items.title4'),
    },
  ];

</script>

<style module lang="scss" rel="stylesheet/scss">
.recommendation {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 16px;
  border-radius: 32px;
  background-color: $color-background-4;
  gap: 20px;

  @include tablet(min) {
    gap: 40px;
    padding: 40px 24px;
  }

  @include desktop-small(min) {
    padding: 40px;
  }

  &Container {
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    gap: 12px;

    @include tablet(min) {
      gap: 20px;
      grid-template-rows: unset;
      grid-template-columns: 1fr 1fr;
    }

    @include desktop-small(min) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    @include desktop-medium(min) {
      gap: 40px;
    }
  }

  &Item {
    position: relative;
    display: flex;
    overflow: hidden;
    align-items: center;
    flex-direction: row;
    padding: 19px 15px;
    border: 1px solid $color-border;

    border-radius: 24px;
    gap: 24px;

    @include tablet(min) {
      align-items: start;
      flex-direction: column;
      height: 176px;
      padding: 23px;
    }

    @include desktop-small(min) {
      height: 200px;
    }

    @include desktop-large(min) {
      height: 176px;
    }

    &Title {
      &Hover {
        position: absolute;
        margin: 24px 0 0;
        opacity: 0;
      }

      @include tablet(min) {
        position: absolute;
        padding-right: 23px;
        transition: margin 0.4s cubic-bezier(0.4, 1, 0.3, 1.2);
      }

      @include font(900, 14px, 20px);

      @include tablet(only) {
        @include font(900, 16px, 24px);
      }

      @include desktop-medium(min) {
        @include font(900, 16px, 24px);
      }
    }

    .iconWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 56px;
      min-width: 56px;
      height: 56px;
      min-height: 56px;
      color: $color-background-3;

      border-radius: 200px;
      background-color: $color-strawberry-500;

      &Hover {
        position: absolute;
        width: 56px;
        height: 56px;
        margin: 0;

        transition: all 0.3s cubic-bezier(0.4, 1, 0.3, 1.2);
        border-radius: 200px;
      }

      .icon {
        stroke-width: 1.5;

        transition: margin 0.4s cubic-bezier(0.4, 1, 0.3, 1.2);
      }

      .iconHover {
        position: absolute;
        z-index: 1;
        margin: -24px 0 0;

        transition: margin 0.4s cubic-bezier(0.4, 1, 0.3, 1.2);
        opacity: 0;
        stroke: none;
      }
    }

    .auchanLogo {
      position: absolute;
      right: 16px;
      bottom: -10px;
      width: 72px;
      min-width: 72px;
      height: 72px;
      min-height: 72px;

      transition: bottom 0.4s cubic-bezier(0.4, 1, 0.3, 1.2);
      stroke: none;

      @include tablet(min) {
        width: 104px;
        min-width: 104px;
        height: 104px;
        min-height: 104px;
      }
    }

    @include mobile(only) {
      &:active {
        border: 1px solid $color-olive-750;
      }
    }

    @include tablet(min) {
      &:active {
        border: 2px solid $color-background-4;
      }

      &:hover, &:active {
        color: $color-background-3;

        .icon {
          position: absolute;
          margin: 0 0 -24px;
          opacity: 0;
        }

        .iconHover {
          margin: 0;
          opacity: 1;
        }

        .iconWrapperHover {
          width: 100%;
          height: 100%;
          margin: -23px;
          border-radius: 24px;
          background-color: $color-strawberry-500;
        }

        .auchanLogo {
          bottom: -50px;
        }
      }

      &:hover &Title {
        margin: 24px 0 0;
        opacity: 0;
      }

      &:hover &TitleHover {
        z-index: 1;
        margin: 0;
        opacity: 1;
      }
    }
  }
}

@keyframes title-hover {
  from { margin-top: 24px; opacity: 0 }
  to { margin-top: 0; opacity: 1 }
}

</style>
