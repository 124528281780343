import slugify from 'slugify';

export function categorySlugify (newCategories) {
  // collect parent categories and slugify eg.: elelmiszer/zoldseg-gyumolcs/gyumolcs
  const setParentCategory = (category, oldSlugName, oldNameArr = []) => {
    category.forEach((child) => {
      const oldSlugNamePlusCurrentSlugName = (oldSlugName ? oldSlugName + '/' : '') +
          slugify(
            child.name
              .replace(/&/, '-')
              .replace(/%/g, 'pct')
              .replace(/-+/g, '-')
            , { lower: true, strict: true },
          );
      child.parentSlug = oldSlugNamePlusCurrentSlugName;

      const oldNamePlusCurrentNameArr = oldNameArr.concat([{
        categoryId: child.id,
        name: child.name,
        slug: '',
      }]);

      const slugsArr = oldSlugNamePlusCurrentSlugName.split('/');
      let tmpSlug = '';
      for (let i = 0; i < slugsArr.length; i++) {
        tmpSlug = (tmpSlug ? tmpSlug + '/' : '') + slugsArr[i];
        oldNamePlusCurrentNameArr[i].slug = tmpSlug;
      }

      child.parentsNameArr = oldNamePlusCurrentNameArr;
      setParentCategory(child.children, oldSlugNamePlusCurrentSlugName, oldNamePlusCurrentNameArr);
    });
  };

  if (Array.isArray(newCategories?.children)) {
    setParentCategory(newCategories.children, '');
  }

  return newCategories;
}
