<template>
  <div v-if="percentage >= 10" :class="$style.badge">
    -{{ percentage }}%
  </div>
</template>

<script>

  export default {
    name: 'AwDiscountPercentageBadge',
    props: {
      percentage: {
        type: Number,
        required: true,
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">

.badge {
  font-weight: 500;
  width: 50px;
  padding: 4px;
  text-align: center;
  color: $color-white;
  border-radius: 99px;
  background: $color-monza;
}
</style>
