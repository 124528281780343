import { defineStore } from 'pinia';
import { useNuxtApp, useRuntimeConfig } from 'nuxt/app';
import { ref } from 'vue';
import fallbackShopHu from '~~/shop/locales/hu.json';
import fallbackDealerHu from '~~/dealer/locales/hu.json';
import ObjectCache from '~~/common/utils/ObjectCache.js';
import { PUSH_LOCALE_CODE_CHANGE } from '~~/common/plugins/aw-analytics.js';
import { useNotificationStore } from '~~/common/stores/notification';

const translationCache = new ObjectCache('translation', '__');

const flatten = (obj, roots = [], sep = '.') =>
  Object.keys(obj).reduce((memo, prop) =>
    Object.assign({}, memo, Object.prototype.toString.call(obj[prop]) === '[object Object]'
      ? flatten(obj[prop], roots.concat([prop]))
      : { [roots.concat([prop]).join(sep)]: obj[prop] }), {})
;

export const useTranslationStore = defineStore('translation', () => {
  const nuxtApp = useNuxtApp();
  const config = useRuntimeConfig();

  const hu = ref(false);
  const en = ref(false);
  const pl = ref(false);

  const locales = {
    hu,
    en,
    pl,
  };

  function setTranslations ({ values: translationResult, localeCode }) {
    locales[localeCode].value = translationResult;
  }

  async function fetchTranslations ({ localeCode }) {
    const { $i18n, $awAnalytics, $logger, $messageTokenNetwork } = nuxtApp;
    try {
      try {
        // NOTE: import.meta.client is checked so only *changes* are tracked.
        if (import.meta.client) {
          $awAnalytics[PUSH_LOCALE_CODE_CHANGE]({ localeCode });
        }
      } catch (err) {
        $logger.error(err);
      }

      if (this[localeCode]) {
        return;
      }

      const cacheKey = ['translation', $i18n.locale.value];
      translationCache.initCache(cacheKey, () => $messageTokenNetwork.$get(`message-tokens.${localeCode}.js`));
      const translations = await translationCache.getCache(cacheKey).value;

      const filteredTranslations = Object.fromEntries(Object.entries(translations).filter(([key]) => !(
        key.startsWith('admin.') ||
          key.startsWith('api.') ||
          key.startsWith('delivery_info.') ||
          key.startsWith('delivery_newsletter.') ||
          key.startsWith('mail.') ||
          key.startsWith('mainmenu.') ||
          key.startsWith('store_selector.')
      )));

      const fallbacksTokens = {
        shop: {
          hu: fallbackShopHu,
        },
        dealer: {
          hu: fallbackDealerHu,
        },
      };

      const instanceTokens = config.public.isShop ? ['shop'] : ['shop', 'dealer'];

      const values = {
        ...instanceTokens.reduce((prev, curr/* , idx */) => ({
          ...prev,
          ...flatten(fallbacksTokens[curr][localeCode] || {}),
        }), {}),
        ...filteredTranslations,
      };

      for (const i in values) {
        values[i] = values[i].text || values[i] || '';
      }

      setTranslations({
        localeCode,
        values,
      });
    } catch (err) {
      $logger.error(err);
      const notificationStore = useNotificationStore();
      notificationStore.pushError({
        text: {
          title: `Could not fetch "${localeCode}" language tokens!`,
        },
      });
    }
  }

  return {
    hu,
    en,
    pl,
    fetchTranslations,
  };
});
