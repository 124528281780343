import { defineNuxtPlugin, useRouter } from 'nuxt/app';
import { nextTick } from 'vue';
import { focusAndScrollToElement, ID_MAIN, Navigation } from '~~/common/config/Navigation.js';
import { extractResourceFromPath } from '~~/common/utils/urlResource.js';
import { useUserInterfaceStore } from '~~/common/stores/userInterface';
import { FLUSH_VIEW_BANNER } from '~~/common/plugins/aw-analytics.js';

export default defineNuxtPlugin(function (nuxtApp) {
  const pathAutofocusBlock = new Set();
  const { $pinia, $logger, $localePath } = nuxtApp;
  const userInterfaceStore = useUserInterfaceStore($pinia);
  if (import.meta.client) {
    const router = useRouter();
    let isFirstPageLoad = true;
    // Automatic fragment focus
    // based on: node_modules/vue-router/dist/vue-router.esm.js
    router.afterEach(async (curr, prev) => {
      const isAutofocusBlocked = pathAutofocusBlock.has(true);
      pathAutofocusBlock.delete(true);
      const id = curr.hash.slice(1) || (!isFirstPageLoad && Navigation[ID_MAIN]);
      if (isFirstPageLoad) {
        isFirstPageLoad = false;
      }
      if (!id || ['search', 'category', 'product'].includes(extractResourceFromPath(curr.fullPath)?.resourceType)) {
        return;
      }
      let resolveNavigationPromise;
      const idNavigationPromise = new Promise((resolve) => {
        resolveNavigationPromise = resolve;
      });
      const e = document.getElementById(id);
      if (e) {
        resolveNavigationPromise(e);
      } else {
        if (!router.app) {
          let r;
          const p = new Promise((resolve) => {
            r = resolve;
          });
          setTimeout(r, 2000);
          await p;
        }
        if (!router.app) {
          return;
        }
        await nextTick();
        const e = document.getElementById(id);
        if (e) {
          resolveNavigationPromise(e);
        } else {
          try {
            if (!isAutofocusBlocked) {
              await router.options?.scrollBehavior?.call?.(router, curr, prev, null);
            }
            resolveNavigationPromise(document.getElementById(id));
          } catch (err) {
            $logger.error(err);
          }
        }
      }

      const navigationElement = await idNavigationPromise || document.getElementById(Navigation[ID_MAIN]);
      /* setTimeout is here so this runs later than the vue-router's
       * scrolling.
       * */
      setTimeout(() => {
        if (!userInterfaceStore.isBodyLocked && !isAutofocusBlocked) {
          focusAndScrollToElement(navigationElement);
        }
        try {
          const { $awAnalytics } = nuxtApp;
          $awAnalytics[FLUSH_VIEW_BANNER]({ isHistoryFlushed: true });
        } catch (err) {
          $logger.error(err);
        }
      }, 320);
    });
  }
  const resolvePath = (entity = {}) => {
    if (typeof entity === 'string') {
      return $localePath(entity);
    }

    /* TODO: Resource routing */
    return {
      path: $localePath('/'),
    };
  };

  nuxtApp.provide('path', resolvePath);
  nuxtApp.provide('pathAutofocusBlock', pathAutofocusBlock);
});
