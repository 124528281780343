import { removeLocaleFromUrl } from '../utils/urlTransformation.js';

const routePageTypeMap = {
  '': 'home_page',
  '/shop': 'ecom_home_page',
  '/faq': 'faq_page',
  '/food-delivery-map': 'food_delivery_map_page',
  '/payment-redirect': 'payment_redirect_page',
  '/unsubscribe': 'unsubscribe_page',
  '/forgotten-password': 'forgotten_password_page',
  '/login/Salesforce': 'salesforce_login_page',
  '/otp/back': 'payment_redirect_page',
  '/shop/basket': 'cart_page',
  '/shop/compare': 'compare_page',
  '/simple/back': 'payment_redirect_page',
  '/test/recon': 'recon_test_page',
  '/shop/profile/billing-addresses': 'profile_billing_addresses_page',
  '/shop/profile/data': 'profile_data_page',
  '/shop/profile/delivery-addresses': 'profile_delivery_addresses_page',
  '/shop/profile/loyalty-card': 'loyalty_card_page',
  '/shop/profile/loyalty-card/stickers': 'loyalty_stickers_page',
  '/shop/profile/loyalty-card/coupons': 'loyalty_coupons_page',
  '/shop/profile/loyalty-card/transactions': 'loyalty_transactions_page',
  '/shop/profile/manage': 'profile_manage_page',
  '/shop/profile/order-again': 'order_again_page',
  '/shop/profile/orders': 'orders_page',
  '/shop/profile/saved-baskets': 'saved_baskets_page',
  '/admin/cms/content_preview': 'cms_content_preview_page',
  '/shop/checkout': 'checkout_page',
  '/shop/list': 'shortlist_page',
  '/shop/loyalty-offers': 'loyalty_offers_page',
  '/activate_registration': 'activate_registration_page',
  '/content': 'cms_content_page',
  '/password_change': 'password_change_page',
  '/product-list': 'category_page',
  '/product': 'item_page',
  '/user_email_update': 'user_email_update_page',
  '/catalog': 'catalog_page',
  '/csr': 'csr_page',
  '/inspirations': 'inspiration_page',
  '/news': 'news_page',
  '/press': 'press_release_page',
};

export function calculatePageType (originalUrl, urlResourceStoreData) {
  let url = removeLocaleFromUrl(originalUrl) // no locale
    .split(/(#|\?)/, 1)[0] // no fragment and no query
    .replace(/\/$/, '') // no trailing slash
  ;

  // remove last part and check again
  if (!routePageTypeMap[url] && !urlResourceStoreData?.resourceType) {
    const urlParts = url.split('/');
    urlParts.pop();
    url = urlParts.join('/').replace(/\/$/, '') || undefined;
  }

  let pageType = routePageTypeMap[url];
  if (!pageType && urlResourceStoreData?.resourceType) {
    switch (urlResourceStoreData.resourceType.toLowerCase()) {
      case 'product':
        pageType = 'item_page';
        break;
      case 'search':
        pageType = 'search_page';
        break;
      case 'category':
        pageType = 'category_page';
        break;
      case 'boutique':
        pageType = 'category_page';
        break;
      case 'cms_content':
        pageType = 'cms_content_page';
        break;
      default:
        pageType = 'fallback_page';
        break;
    }
  }
  if (!pageType) {
    pageType = 'fallback_page';
  }
  return pageType;
}
