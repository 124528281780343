/* global _etmc */
import { defineNuxtPlugin, useRouter, useRuntimeConfig } from 'nuxt/app';
import {
  OPTANON_CATEGORY_2,
  OPTANON_CATEGORY_3,
  OPTANON_CATEGORY_4,
  OptanonCategories,
} from '~~/common/config/OptanonCategoryConfig.js';
import {
  PUSH_CATEGORY_VIEW,
  PUSH_LOGIN_SUCCESS,
  PUSH_PRODUCT_VIEW,
  PUSH_TRACK_CART_STATE,
  PUSH_TRACK_PAGE_VIEW,
} from '~~/common/plugins/aw-analytics.js';

import { useUserStore } from '~~/common/stores/user.js';
import { extractResourceFromPath } from '~~/common/utils/urlResource.js';
import { useCategoryStore } from '~~/common/stores/category.js';
import { useOrderStore } from '~~/common/stores/order.js';
import { useAwSfRecommendationStore } from '~~/common/stores/awSfRecommendation.js';

const LISTEN_FOR_PAGE_CHANGE = Symbol('listenForPageChange');
export const PUSH_TRACK_CONVERSION = Symbol('pushTrackConversion');

export default defineNuxtPlugin((nuxtApp) => {

  const {
    $pinia,
    $getHref,
  } = nuxtApp;
  const router = useRouter();
  const config = useRuntimeConfig();
  let counter = 0;

  const userStore = useUserStore($pinia);
  const categoryStore = useCategoryStore($pinia);
  const orderStore = useOrderStore($pinia);
  const sfRecommendationStore = useAwSfRecommendationStore($pinia);

  const acceptedCookieLevels = {
    [OPTANON_CATEGORY_2]: false,
    [OPTANON_CATEGORY_3]: false,
    [OPTANON_CATEGORY_4]: false,
  };

  const SFMC = {
    updateAcceptedCookieLevels () {
      Object.entries(OptanonCategories).forEach(([key]) => {
        const scriptBlock = document.getElementById(key);
        if (scriptBlock) {
          acceptedCookieLevels[key] = scriptBlock.type === 'text/javascript';
        }
      });
    },
    insertTrackingAndDoNotTrackScripts () {
      if (config.public.SfmcMid && config.public.isSfmcEnabled) {
        if (acceptedCookieLevels[OPTANON_CATEGORY_4]) {
          if (userStore.isEcomUser) {
            if (typeof _etmc !== 'undefined') {
              _etmc.tracking_disabled = false;
              _etmc.push(['setOrgId', `${config.public.SfmcMid}`]);
              _etmc.push(['setUserInfo', { 'email': `${userStore.data.personalInfo.cdEncodedExternalId512}` }]);
              this[PUSH_TRACK_PAGE_VIEW]();
            }
          }
        } else if (acceptedCookieLevels[OPTANON_CATEGORY_2] || acceptedCookieLevels[OPTANON_CATEGORY_3]) {
          if (typeof _etmc !== 'undefined') {
            _etmc.push(['setOrgId', `${config.public.SfmcMid}`]);
            _etmc.push(['doNotTrack']);
          }

        }

      }
    },
    getRecommendation (recommendations) {
      sfRecommendationStore.fetchSFRecommendation(recommendations);
    },
    [LISTEN_FOR_PAGE_CHANGE] () {
      if (config.public.SfmcMid && config.public.isSfmcEnabled) {
        router.afterEach(() => {
          counter++;
          if (counter > 1) {
            this.insertTrackingAndDoNotTrackScripts();
          }
        });
      }
    },
    [PUSH_TRACK_PAGE_VIEW] () {
      const newHref = $getHref();
      const urlResource = extractResourceFromPath(newHref) || {};
      if (urlResource.resourceType === 'product') {
        //do nothing, product page view is already tracked in the productStore
      } else if (urlResource.resourceType === 'category') {
        const currentCat = categoryStore.getCategoryFromTreeById({ treeId: parseInt(urlResource.resourceId) });
        this[PUSH_CATEGORY_VIEW]({ categoryName: currentCat.name });
      } else {
        if (typeof _etmc !== 'undefined') {
          _etmc.push(['trackPageView']);
        }
      }
    },
    [PUSH_LOGIN_SUCCESS] () {
      if (config.public.SfmcMid && config.public.isSfmcEnabled) {
        if (typeof _etmc !== 'undefined') {
          _etmc.push(['setOrgId', `${config.public.SfmcMid}`]);
          _etmc.push(['setUserInfo', { 'email': `${userStore.data.personalInfo.cdEncodedExternalId512}` }]);
          this[PUSH_TRACK_PAGE_VIEW]();
        }
      }
    },
    [PUSH_CATEGORY_VIEW] ({ categoryName }) {
      if (config.public.SfmcMid && config.public.isSfmcEnabled && userStore.isEcomUser) {
        if (typeof _etmc !== 'undefined') {
          _etmc.push(['trackPageView', { 'category': `${categoryName}` }]);
        }
      }
    },
    [PUSH_PRODUCT_VIEW] ({ sku }) {
      if (config.public.SfmcMid && config.public.isSfmcEnabled && userStore.isEcomUser) {
        if (typeof _etmc !== 'undefined') {
          _etmc.push(['trackPageView', { 'item': `${sku}` }]);
        }
      }
    },
    [PUSH_TRACK_CART_STATE] ({ cart }) {
      if (config.public.SfmcMid && config.public.isSfmcEnabled && userStore.isEcomUser) {
        const items = orderStore.getFlattenedOrderItems(cart);
        if (items.length === 0) {
          if (typeof _etmc !== 'undefined') {
            _etmc.push(['trackCart', { 'clear_cart': true }]);
          }
        } else {
          const mappedCartItems = items.map((item) => {
            return {
              item: item.variant.sku,
              quantity: item.quantity.quantity,
              unique_id: item.variant.sku,
              price: item.variant.piecePrice.grossDiscounted,
            };
          });
          if (typeof _etmc !== 'undefined') {
            _etmc.push(['trackCart', { 'cart': mappedCartItems }]);
          }
        }
      }
    },
    [PUSH_TRACK_CONVERSION] ({
      orderedCart,
      remainingCart,
    }) {
      if (config.public.SfmcMid && config.public.isSfmcEnabled && userStore.isEcomUser) {
        let orderedCartItems = orderStore.getFlattenedOrderItems(orderedCart);
        const remainingCartItems = orderStore.getFlattenedOrderItems(remainingCart);

        const mappedOrderedCartItems = orderedCartItems.map((item) => {
          return {
            item: item.variant.sku,
            quantity: item.quantity.quantity,
            unique_id: item.variant.sku,
            price: item.variant.piecePrice.grossDiscounted,
          };
        });
        if (typeof _etmc !== 'undefined') {
          _etmc.push(['trackConversion', { 'cart': mappedOrderedCartItems }]);
        }

        if (remainingCartItems.length > 0) {
          const mappedRemainingCartItems = remainingCartItems.map((item) => {
            return {
              item: item.variant.sku,
              quantity: item.quantity.quantity,
              unique_id: item.variant.sku,
              price: item.variant.piecePrice.grossDiscounted,
            };
          });
          if (typeof _etmc !== 'undefined') {
            _etmc.push(['trackCart', { 'cart': mappedRemainingCartItems }]);
          }
        }
      }
    },
  };

  if (import.meta.client) {
    SFMC[LISTEN_FOR_PAGE_CHANGE]();

    window.awRefreshConsent = function () {
      SFMC.updateAcceptedCookieLevels();
      SFMC.insertTrackingAndDoNotTrackScripts();
    };
  }

  nuxtApp.provide('awSFMCClient', SFMC);
  nuxtApp.provide('awAcceptedCookieLevel', acceptedCookieLevels);
});


