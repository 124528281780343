import { useNuxtApp } from 'nuxt/app';
import { camelToSnakeCase } from '~~/common/utils';

export default {
  methods: {
    formatAddress (address) {
      return formatAddress(address);
    },
    formatAddressLine (address) {
      return formatAddressLine(address, this.$awt);
    },
  },
};
export function formatAddress (address) {
  return [
    [address?.postCode, address?.city].filter(Boolean).join(' '),
    formatAddressLine(address),
  ].filter(Boolean).join(', ');
}

function formatAddressLine (address, $awt = useNuxtApp().$awt) {
  let str = '';
  const format = addressTokenizer.bind(null, $awt, address);
  for (const key of [
    'streetName', // aw.address.street_name
    'streetType', // aw.address.street_type
    'streetNo', // aw.address.street_no
    'building', // aw.address.building
    'staircase', // aw.address.staircase
    'floor', // aw.address.floor
    'door', // aw.address.door
  ]) {
    str += ' ' + format(key);
  }

  if (address?.floor && Number.isNaN(Number.parseInt(address?.floor))) {
    str = str.replace($awt('aw.address.floor', { floor: '' }), '');
  }

  str = str.trim().replace(/\s+/g, ' ');
  return str;
}

function addressTokenizer ($awt, address, key) {
  return address?.[key] ? $awt(`aw.address.${camelToSnakeCase(key)}`, { [key]: address[key] }) : '';
}
