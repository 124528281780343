import { useModalStore } from '~~/common/stores/modal';
import { useUserStore } from '~~/common/stores/user';

export function commitAlcoholAlertModal ({ $awt: awt }) {
  const userStore = useUserStore();
  const modalStore = useModalStore();

  modalStore.showAlertModal({
    closable: true,
    backgroundBlured: true,
    data: {
      alertTitle: awt('aw.common.alert_modal.alcohol_warning.title'),
      alertText: awt('aw.common.alert_modal.alcohol_warning.text'),
      alertButton: awt('aw.common.alert_modal.alcohol_warning.button'),
      isAlcoholModal: true,
      alcoholModalUniquenessGuarantee: true,
    },
  });
  userStore.setShowAlcoholAlertModalBeforeLogIn(true);
}

export function commitAlcoholConfirmModal (awt) {
  const modalStore = useModalStore();

  modalStore.showConfirmModal({
    closable: true,
    backgroundBlured: true,
    data: {
      confirmTitle: awt('aw.common.confirm_modal.alcohol_age_confirm.title'),
      confirmText: awt('aw.common.confirm_modal.alcohol_age_confirm.text'),
      confirmOkButton: awt('aw.common.confirm_modal.alcohol_age_confirm.yes_button'),
      confirmOkActionType: 'ageConfirm',
      confirmCancelActionType: 'ageConfirm',
      confirmCancelButton: awt('aw.common.confirm_modal.alcohol_age_confirm.no_button'),
      confirmOkButtonStyle: 'secondary',
      confirmOkButtonReverse: true,
      confirmCancelButtonStyle: 'senary',
      confirmTitleStyle: [{ color: '#e0001a' }],
      alcoholModalUniquenessGuarantee: true,
    },
  });
}
