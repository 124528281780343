import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useTabStore = defineStore('tab', () => {
  const tabs = ref({});

  function setTab ({ uuid, index }) {
    tabs.value[uuid] = index;
  }
  function removeTab ({ uuid }) {
    delete tabs.value[uuid];
  }
  return {
    tabs,
    setTab,
    removeTab,
  };
});
