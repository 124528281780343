<template>
  <aw-header-info-panel :show="show">
    <client-only>
      <aw-product-list-active-filters v-if="screenRange['tablet-min']" use-in-header />
      <aw-product-list-order-by-and-mobile-filter-modal v-else has-switcher-icon />
    </client-only>
  </aw-header-info-panel>
</template>

<script>
  import { mapState } from 'pinia';
  import { defineAsyncComponent } from 'vue';
  import AwHeaderInfoPanel from '~~/common/components/Common/Header/AwHeaderInfoPanel.vue';
  import AwProductListFilterMixin from '~~/common/components/Page/ProductList/AwProductListFilterMixin.js';
  import { useUserInterfaceStore } from '~~/common/stores/userInterface';

  export default {
    name: 'AwHeaderFilterPanel',
    components: {
      AwHeaderInfoPanel,
      AwProductListActiveFilters: defineAsyncComponent(() => import('~~/common/components/Page/ProductList/AwProductListActiveFilters')),
      AwProductListOrderByAndMobileFilterModal: defineAsyncComponent(() => import('~~/common/components/Page/ProductList/AwProductListOrderByAndMobileFilterModal')),
    },
    mixins: [
      AwProductListFilterMixin,
    ],
    props: {
      show: {
        type: Boolean,
        required: true,
      },
    },
    emits: [
      'update:show',
    ],
    computed: {
      hasFilters () {
        return Object.keys(this.filterBy).filter(key => this.filterBy?.[key]?.length).length > 0;
      },
      ...mapState(useUserInterfaceStore, {
        screenRange: state => state.mediaQueries,
      }),
    },
    watch: {
      hasFilters () {
        this.$emit('update:show', this.hasFilters);
      },
    },
    mounted () {
      this.$emit('update:show', this.hasFilters);
    },
  };
</script>
