import revive_payload_client_4sVQNw7RlN from "/builds/auchan-webshop/auchan-vue-client/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/builds/auchan-webshop/auchan-vue-client/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/builds/auchan-webshop/auchan-vue-client/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/builds/auchan-webshop/auchan-vue-client/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/builds/auchan-webshop/auchan-vue-client/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/builds/auchan-webshop/auchan-vue-client/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/builds/auchan-webshop/auchan-vue-client/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/builds/auchan-webshop/auchan-vue-client/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/builds/auchan-webshop/auchan-vue-client/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_SA8kJfdGM8 from "/builds/auchan-webshop/auchan-vue-client/.nuxt/shop/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/builds/auchan-webshop/auchan-vue-client/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_iGLjBbor9q from "/builds/auchan-webshop/auchan-vue-client/node_modules/nuxt-jsonld/dist/runtime/plugin.js";
import useDirectives_W0tXrzvhuP from "/builds/auchan-webshop/auchan-vue-client/node_modules/@bootstrap-vue-next/nuxt/dist/runtime/useDirectives.mjs";
import createBootstrap_ywvW1KIeJ9 from "/builds/auchan-webshop/auchan-vue-client/node_modules/@bootstrap-vue-next/nuxt/dist/runtime/createBootstrap.mjs";
import switch_locale_path_ssr_5csfIgkrBP from "/builds/auchan-webshop/auchan-vue-client/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/builds/auchan-webshop/auchan-vue-client/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import aw_runtime_config_oKYQSG8Etn from "/builds/auchan-webshop/auchan-vue-client/common/plugins/aw-runtime-config.js";
import aw_cookies_qSrqguL2DE from "/builds/auchan-webshop/auchan-vue-client/common/plugins/aw-cookies.js";
import post_translation_2hbcvgUGfk from "/builds/auchan-webshop/auchan-vue-client/common/plugins/post-translation.js";
import app_config_AWGYMNByQi from "/builds/auchan-webshop/auchan-vue-client/shop/awPlugins/app-config.js";
import aw_events_apKpZUv1yz from "/builds/auchan-webshop/auchan-vue-client/common/plugins/aw-events.js";
import style_variables_yILMcwzlnp from "/builds/auchan-webshop/auchan-vue-client/common/plugins/style-variables.js";
import aw_user_interface_client_XX3pS5XoTN from "/builds/auchan-webshop/auchan-vue-client/common/plugins/aw-user-interface/aw-user-interface.client.js";
import path_yXCzXC1Rul from "/builds/auchan-webshop/auchan-vue-client/common/plugins/path.js";
import price_L9v9wN1t4q from "/builds/auchan-webshop/auchan-vue-client/common/plugins/price.js";
import url_parts_9T5wxlwEpM from "/builds/auchan-webshop/auchan-vue-client/common/plugins/url-parts.js";
import date_QoP0L7DCBA from "/builds/auchan-webshop/auchan-vue-client/common/plugins/date.js";
import logging_client_H92MH4blaw from "/builds/auchan-webshop/auchan-vue-client/common/plugins/logging/logging.client.js";
import index_EelglwHu3a from "/builds/auchan-webshop/auchan-vue-client/common/plugins/axios/index.js";
import aw_lazy_media_wiG7pnnBtv from "/builds/auchan-webshop/auchan-vue-client/common/plugins/aw-lazy-media.js";
import aw_gtm_Du68kRriyo from "/builds/auchan-webshop/auchan-vue-client/common/plugins/aw-gtm.js";
import aw_analytics_rpGsIYh2Xu from "/builds/auchan-webshop/auchan-vue-client/common/plugins/aw-analytics.js";
import aw_sfmc_client_gNs11cGItx from "/builds/auchan-webshop/auchan-vue-client/common/plugins/aw-sfmc.client.js";
import vue_lazy_hydration_DBBPS5FQoj from "/builds/auchan-webshop/auchan-vue-client/common/plugins/vue-lazy-hydration.js";
import third_party_scripts_client_SK0INFZFQP from "/builds/auchan-webshop/auchan-vue-client/common/plugins/third-party-scripts.client.js";
import bootstrap_vue_UJJS1wrCC8 from "/builds/auchan-webshop/auchan-vue-client/shop/awPlugins/bootstrap-vue.js";
import queue_client_sAggZBps3G from "/builds/auchan-webshop/auchan-vue-client/common/plugins/queue.client.js";
import media_helpers_vjtaAaFI3c from "/builds/auchan-webshop/auchan-vue-client/common/plugins/media-helpers.js";
import google_sign_in_client_DtZowqHRpd from "/builds/auchan-webshop/auchan-vue-client/shop/awPlugins/google-sign-in.client.js";
import language_change_4OMA2rdOgQ from "/builds/auchan-webshop/auchan-vue-client/shop/awPlugins/language-change.js";
import pinia_init_Xq0j7lQ5YT from "/builds/auchan-webshop/auchan-vue-client/shop/awPlugins/pinia-init.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_SA8kJfdGM8,
  prefetch_client_5tzzN0oIVL,
  plugin_iGLjBbor9q,
  useDirectives_W0tXrzvhuP,
  createBootstrap_ywvW1KIeJ9,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  aw_runtime_config_oKYQSG8Etn,
  aw_cookies_qSrqguL2DE,
  post_translation_2hbcvgUGfk,
  app_config_AWGYMNByQi,
  aw_events_apKpZUv1yz,
  style_variables_yILMcwzlnp,
  aw_user_interface_client_XX3pS5XoTN,
  path_yXCzXC1Rul,
  price_L9v9wN1t4q,
  url_parts_9T5wxlwEpM,
  date_QoP0L7DCBA,
  logging_client_H92MH4blaw,
  index_EelglwHu3a,
  aw_lazy_media_wiG7pnnBtv,
  aw_gtm_Du68kRriyo,
  aw_analytics_rpGsIYh2Xu,
  aw_sfmc_client_gNs11cGItx,
  vue_lazy_hydration_DBBPS5FQoj,
  third_party_scripts_client_SK0INFZFQP,
  bootstrap_vue_UJJS1wrCC8,
  queue_client_sAggZBps3G,
  media_helpers_vjtaAaFI3c,
  google_sign_in_client_DtZowqHRpd,
  language_change_4OMA2rdOgQ,
  pinia_init_Xq0j7lQ5YT
]