export const unitNames = {
  szt: 0,
  db: 0,
  piece: 0,
  kg: 1,
  weight: 1,
};

export function getAnaliticsUnit ({ quantityType }) {
  if (unitNames[quantityType] === 0) {
    return 'db';
  } else if (unitNames[quantityType] === 1) {
    return 'kg';
  } else {
    throw new ErrorUnitNameConversion(quantityType);
  }
}

export function unitConverter ({ from: { value, unit }, to: targetUnit, weightPerPiece }) {
  const o = { unit: targetUnit };
  if (unitNames[targetUnit] === unitNames[unit]) {
    o.value = value;
  } else if (unitNames[targetUnit] === 0 && unitNames[unit] === 1) { // kg -> db
    o.value = value / weightPerPiece;
  } else if (unitNames[targetUnit] === 1 && unitNames[unit] === 0) { // db -> kg
    o.value = value * weightPerPiece;
  } else {
    throw new ErrorUnitConversion(unit, value, targetUnit);
  }
  return o;
}

export function itemToQuantity (i) {
  return (i
    ? unitConverter({
      from: {
        value: i.quantity?.quantity,
        unit: i.quantity?.type,
      },
      to: 'piece',
      weightPerPiece: i.variant?.loose?.weightPerPiece,
    })?.value
    : 0
  );
}

export function getQuantityStepSizeForUnit (variant, unit) {
  return ((unitNames[unit] === 1 || (unitNames[unit] === 0 && variant.loose.loose === false))
    ? variant.cartInfo.quantityStepSize
    : 1
  );
}

/* export function getCurrencyQuantityStepSizeForUnit (variant, unit) {
  return (unitNames[unit] === 1
    ? variant.cartInfo.quantityStepSize
    : 1
  );
} */

function ErrorUnitConversion (unit, value, targetUnit) {
  this.name = 'ErrorUnitConversion';
  this.message = `value: ${value}, unit: ${unit} cannot be converted into ${targetUnit}`;
}

function ErrorUnitNameConversion (unit) {
  this.name = 'ErrorUnitNameConversion';
  this.message = `unit name: ${unit} cannot be converted.`;
}
