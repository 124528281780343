import { defineNuxtPlugin } from 'nuxt/app';
import EventEmitter from '~~/common/utils/event-emitter';

export default defineNuxtPlugin((nuxtApp) => {
  const { $awMergedConfig: { googleApiClientId } } = nuxtApp;
  const Google = new EventEmitter();

  let googleLoading = false;
  let auth2;
  let gapi = {};
  let isFailure;

  function load () {
    ((documentElement, tag, id) => {
      if (documentElement.getElementById(id)) {
        return;
      }
      googleLoading = true;
      const firstScript = documentElement.getElementsByTagName(tag)[0];
      const injectedScript = documentElement.createElement(tag);
      injectedScript.id = id;
      injectedScript.src = '//apis.google.com/js/api:client.js?onload=googleAsyncInit';
      firstScript.parentNode.insertBefore(injectedScript, firstScript);
      injectedScript.onerror = () => {
        isFailure = true;
        Google.emit('failed');
      };
    })(document, 'script', 'google-js-sdk');

    window.googleUser = window.googleUser || {};

    window.googleAsyncInit = () => {
      gapi = {
        ...window.gapi,
      };

      gapi.load('auth2', () => {
        gapi.auth2.init({
          client_id: googleApiClientId,
        }).then((response) => {
          auth2 = response;
          setTimeout(() => {
            googleLoading = false;
            Google.emit('loaded');
          }, 0);
        });
      });
    };
  }

  nuxtApp.provide('google', {
    login () {
      return new Promise((resolve, reject) => {
        new Promise((resolve, reject) => {
          new Promise((resolve, reject) => {
            if (googleLoading || !auth2) {
              if (isFailure) {
                reject(new Error('Google Login'));
              }
              Google.on('loaded', () => {
                Google.off('loaded');
                Google.off('failed');
                resolve();
              });
              Google.on('failed', () => {
                Google.off('loaded');
                Google.off('failed');
                reject(new Error('Google Login'));
              });
            } else {
              resolve();
            }
          }).then(() => {
            const options = new gapi.auth2.SigninOptionsBuilder();
            options.setPrompt('select_account');
            auth2.signIn(options).then(resolve).catch(reject);
          }).catch(reject);
        }).then((response) => {
          resolve(response.getAuthResponse(true));
        }).catch(reject);
      });
    },
    logout () {
      return new Promise((resolve, reject) => {
        if (googleLoading) {
          Google.on('loaded', () => {
            Google.off('loaded');
            try {
              resolve(auth2.signOut());
            } catch (error) {
              reject(error);
            }
          });
        } else {
          try {
            resolve(auth2.signOut());
          } catch (error) {
            reject(error);
          }
        }
      });
    },
    load,
  });
});
