import { defineNuxtPlugin } from 'nuxt/app';

export default defineNuxtPlugin(function (nuxtApp) {
  nuxtApp.hook('i18n:localeSwitched', ({ newLocale }) => {
    const { $api, $authentication } = nuxtApp;
    for (const api of [$api, $authentication]) {
      api.defaults.headers['Accept-Language'] = newLocale;
    }
  });
});
