import { defineNuxtPlugin } from 'nuxt/app';

// functional approach
function addEvent (evts, evtName, ...callbacks) {
  if (!evts[evtName]) {
    evts[evtName] = [];
  }
  evts[evtName].push(...callbacks);
}
function removeEvent (evts, evtName, ...callbacks) {
  if (evts[evtName]) {
    if (callbacks?.length) {
      evts[evtName] = evts[evtName].filter(cb => !callbacks.includes(cb));
    } else {
      delete evts[evtName];
    }
  }
}
function triggerEvent (evts, evtName, evtPayload) {
  if (evts[evtName]) {
    for (let i = 0; i < evts[evtName].length; i++) {
      evts[evtName][i](evtPayload);
    }
  }
}

// obj oriented approach
function AwEvents () {
  this._events = {};
}

AwEvents.prototype.addEvent = function () {
  addEvent(this._events, ...arguments);
};

AwEvents.prototype.removeEvent = function () {
  removeEvent(this._events, ...arguments);
};

AwEvents.prototype.triggerEvent = function () {
  triggerEvent(this._events, ...arguments);
};

export default defineNuxtPlugin(function (nuxtApp) {
  nuxtApp.provide('awEvents', new AwEvents());
});
