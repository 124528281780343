import { defineNuxtPlugin, useRuntimeConfig } from 'nuxt/app';
import { parseGuestCheckoutStringToArray } from '~~/common/utils/guestCheckout.js';

export default defineNuxtPlugin(function ({ provide }) {
  const config = useRuntimeConfig();
  const normalizedConfig = {
    ...config,
    public: {
      ...config.public,
      enabledGuestCheckouts: parseGuestCheckoutStringToArray(config.public.enabledGuestCheckouts),
    },
  };
  provide('awMergedConfig', generateMergedRuntimeConfig(normalizedConfig));
  provide('awRuntimeConfig', generateAwRuntimeConfig(normalizedConfig));
});

function generateAwRuntimeConfig (config) {
  const instance = config.public.isShop ? 'Shop' : 'Dealer';

  return {
    apiHost: import.meta.server ? config[`auchanApiHostPrivate${instance}`] : config.public[`auchanApiHostPublic${instance}`],
    auchanApiBrowserHost: config.public[`auchanApiBrowserHost${instance}`],
    apiNetworkBaseURL: (import.meta.server ? config[`auchanApiHostPrivate${instance}`] : config.public[`auchanApiBrowserHost${instance}`]) + config.public.auchanApiPrefix,
    authenticationNetworkBaseURL: (import.meta.server ? config[`auchanApiHostPrivate${instance}`] : config.public[`auchanApiBrowserHost${instance}`]) + config.public.clientAuthenticationPrefix,
    messageTokenURL: `${(import.meta.server ? config[`auchanMessageTokenHostPrivate${instance}`] : config.public[`auchanMessageTokenHostPublic${instance}`])}${config.public.auchanMessageTokenPrefix}`,
    authMode: config.public.auchanCustomerDiamondEnabled ? 'CD' : 'local',
    mapAreaBounds: [
      {
        latitude: config.public.mapX1,
        longitude: config.public.mapY1,
      },
      {
        latitude: config.public.mapX2,
        longitude: config.public.mapY2,
      },
    ],
    customerDiamondLoginUrl: `${config.public.auchanCustomerDiamondHost}${config.public.auchanCustomerDiamondPathLogin}?client_id=${config.public.auchanCustomerDiamondClientId}&redirect_uri=${config.public.auchanCustomerDiamondRedirectUri}`,
    customerDiamondRegisterUrl: `${config.public.auchanCustomerDiamondHost}${config.public.auchanCustomerDiamondPathRegister}?client_id=${config.public.auchanCustomerDiamondClientId}&redirect_uri=${config.public.auchanCustomerDiamondRedirectUri}`,
    customerDiamondLogoutUrl: `${config.public.auchanCustomerDiamondHost}${config.public.auchanCustomerDiamondPathLogout}`,
    customerDiamondAccountDeleteUrl: config.public.auchanCustomerDiamondPathAccountDelete,
    searchMinLength: config.public[`searchMinLength${instance}`],
    searchLogType: config.public[`searchLogType${instance}`],
    barcodeScannerDetectionOrder: config.public.barcodeScannerDetectionOrder.split(/\s/),
    cetelemCalculatorUrl: `${config.public.cetelemBaseUrl}${config.public.cetelemCalculatorPath}`,
    cetelemScriptTagUrl: `${config.public.cetelemBaseUrl}${config.public.cetelemScriptTagPath}`,
    ...(instance === 'Dealer' ? {
      adminHost: import.meta.server ? config.auchanAdminHostPrivateDealer : config.public.auchanAdminHostPublicDealer,
    } : {}),

  };
}

function generateMergedRuntimeConfig (runtimeConfig) {
  return {
    ...runtimeConfig.public,
    ...(import.meta.server
      ? {
        ...runtimeConfig,
        public: undefined,
      }
      : null
    ),
  };
}
