export const matchCaseInsensitiveArr = values => new RegExp(`^${values.join('|')}$`, 'i');
export const caseInsensitiveKeyReplace = (oldObj, newObj) => {
  const oldObjCopy = { ...oldObj };
  const keyRegex = matchCaseInsensitiveArr(Object.keys(newObj));
  for (const oldKey in oldObj) {
    if (keyRegex.test(oldKey)) {
      delete oldObjCopy[oldKey];
    }
  }
  return { ...oldObjCopy, ...newObj };
};
