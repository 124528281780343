import { defineAsyncComponent } from 'vue';

const SWT_TEXT = 'text';
const SWT_TYPEAHEAD = 'typeahead';
const SWT_PASSWORD = 'password';
const SWT_EMAIL = 'email';
const SWT_PHONE = 'tel';
const SWT_NUMBER = 'number';
const SWT_TEXTAREA = 'textarea';
const SWT_CHECKBOX = 'checkbox';
const SWT_RADIO = 'radio';
const SWT_RTOGGLE = 'radio-toggle';
const SWT_SELECT = 'select';
const SWT_DATE = 'date';
const SWT_FILE = 'swtFile';

export default {
  [SWT_TEXT]: {
    component: defineAsyncComponent(() => import('../components/Form/Widget/AwFormInput')),
  },
  [SWT_EMAIL]: {
    component: defineAsyncComponent(() => import('../components/Form/Widget/AwFormInput')),
  },
  [SWT_PASSWORD]: {
    component: defineAsyncComponent(() => import('../components/Form/Widget/AwFormInput')),
  },
  [SWT_TEXTAREA]: {
    component: defineAsyncComponent(() => import('../components/Form/Widget/AwFormTextarea')),
  },
  [SWT_PHONE]: {
    component: defineAsyncComponent(() => import('../components/Form/Widget/AwFormPhoneInput')),
  },
  [SWT_NUMBER]: {
    component: defineAsyncComponent(() => import('../components/Form/Widget/AwFormInput')),
  },
  [SWT_CHECKBOX]: {
    component: defineAsyncComponent(() => import('../components/Form/Widget/AwFormCheckbox')),
  },
  [SWT_RADIO]: {
    component: defineAsyncComponent(() => import('../components/Form/Widget/AwFormRadioGroup')),
  },
  [SWT_RTOGGLE]: {
    component: defineAsyncComponent(() => import('../components/Form/Widget/AwFormRadioToggleGroup')),
  },
  [SWT_SELECT]: {
    component: defineAsyncComponent(() => import('../components/Form/Widget/AwFormSelect')),
  },
  [SWT_TYPEAHEAD]: {
    component: defineAsyncComponent(() => import('../components/Form/Widget/AwFormTypeahead')),
  },
  [SWT_DATE]: {
    component: defineAsyncComponent(() => import('../components/Form/Widget/AwFormDatePicker')),
  },
  [SWT_FILE]: {
    component: defineAsyncComponent(() => import('../components/Form/Widget/AwFormFile')),
  },
};
