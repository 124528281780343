<template>
  <div aria-atomic="true">
    <span class="awSrOnly" v-text="$awt('aw.common.components.rating.label', { rating_avg: modelValue })" />
    <div :class="$style.rating">
      <div
        v-for="star in stars"
        :key="`${star.type}-${star.value}`"
        aria-hidden="true"
        @click="emitChange(star.value)"
      >
        <slot v-if="star.type === 'full'" name="icon-full">
          <lv-icon name="star-24" :size="24" :class="[$style.icon, $style.full, {[$style.interactive]: interactive}]" />
        </slot>
        <slot v-if="star.type === 'half'" name="icon-half">
          <lv-icon name="star-24" :size="24" :class="[$style.icon, $style.half, {[$style.interactive]: interactive}]" />
        </slot>
        <slot v-if="star.type === 'empty'" name="icon-empty">
          <lv-icon name="star-24" :size="24" :class="[$style.icon, $style.empty, {[$style.interactive]: interactive}]" />
        </slot>
      </div>
    </div>
  </div>
</template>

<script>

  import { LvIcon } from '~~/common/components/loginet-vue-shop/index.mjs';

  export default {
    name: 'AwRating',
    components: { LvIcon },
    props: {
      modelValue: {
        type: Number,
        default: 0,
      },
      interactive: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['update:modelValue'],
    computed: {
      stars () {
        const fullStars = Math.floor(this.modelValue);
        const halfStars = Math.ceil(this.modelValue - fullStars);
        const emptyStars = 5 - fullStars - halfStars;

        const fullArray = Array(fullStars).fill({ type: 'full' });
        const halfArray = Array(halfStars).fill({ type: 'half' });
        const emptyArray = Array(emptyStars).fill({ type: 'empty' });
        return [...fullArray, ...halfArray, ...emptyArray].map((star, index) => ({ ...star, value: index + 1 }));
      },
    },
    methods: {
      emitChange (index) {
        this.$emit('update:modelValue', index);
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
.rating {
  display: grid;
  grid-template-columns: repeat(5, 24px);
  align-items: center;
  justify-content: center;

  .icon {
    stroke: none;

    &.empty {
      --color1: #{$color-alto};
      --color2: #{$color-alto};
    }

    &.half {
      --color1: #{$color-candlelight};
      --color2: #{$color-alto};
    }

    &.full {
      --color1: #{$color-candlelight};
      --color2: #{$color-candlelight};
    }

    &.interactive {
      cursor: pointer;
    }
  }
}
</style>
