<template>
  <div
    :class="[
      $style.userHeaderDh,
      {
        [$style.isPinned]: isPinned,
        [$style.isOnTop]: isOnTop,
        [$style.isCheckoutOrSuccessPage]: isCheckoutOrSuccessPage,
      },
    ]"
  >
    <div v-if="isShop && !isCheckoutOrSuccessPage" :class="$style.sectionDelivery">
      <lv-icon name="location-16" :size="16" :class="$style.sectionDeliveryIcon" />
      <client-only>
        <span v-text="deliveryLines.line0" />
        <lv-button
          shrink
          styling="link-primary-version2"
          :class="[
            'awFocusV',
            'awBr8',
            $style.sectionDeliveryButton,
            AnaliticsClasses.timeSlotReservationPopup,
          ]"
          @click="openDeliveryModal"
        >
          {{ deliveryLines.line1 }}
        </lv-button>
        <span v-text="deliveryLines.line2" />
      </client-only>
    </div>
    <div :class="$style.sectionLogo">
      <nuxt-link :to="localePath('/')" :aria-label="$awt('aw.common.header.logo.label')" class="awFocusV awBr8">
        <lv-icon
          name="auchan-logo"
          :size="32"
          fill
          :square="false"
          :class="$style.logoMobile"
        />
        <lv-icon
          name="auchan-logo-full-HU"
          :size="999"
          fill
          :square="false"
          :class="$style.logoDesktop"
        />
      </nuxt-link>
    </div>
    <div v-if="isCheckoutOrSuccessPage" :class="$style.sectionCheckout">
      <client-only>
        <aw-checkout-indicator />
      </client-only>
    </div>
    <div v-if="!isCheckoutOrSuccessPage" :class="$style.sectionSearch">
      <div :class="$style.searchInputWrapper">
        <client-only>
          <aw-search-input :class="$style.searchInput" @show-header-search="isSearchBackdropShown = $event" />
        </client-only>
      </div>
    </div>
    <div :class="$style.sectionMenu">
      <aw-square-link
        ref="menuBtnElement"
        tag="button"
        type="button"
        :aria-label="$awt(isMenuOpened ? 'aw.dh_header.menu_block.menu_btn.label_close' : 'aw.dh_header.menu_block.menu_btn.label_open')"
        :aria-expanded="isMenuOpened"
        :[`data-aw-${buttonDataAttr}`]="1"
        @click="toggleIsMenu"
        @touchstart="prefetchCats"
        @mouseover="prefetchCats"
      >
        <template #icon>
          <lv-icon name="hamburger-menu-16" :size="16" />
        </template>
        <template #text>
          <span :class="$style.hideOnMobile" v-text="$awt('aw.dh_header.menu_block.menu_btn.text')" />
        </template>
      </aw-square-link>
      <aw-svg-border :class="[$style.svgBorder, $style.hideOnMobile]" />
      <transition v-bind="transitionX" style="--translateXDir: -1">
        <aw-menu-sidebar
          v-if="isMenuOpened"
          ref="menuSidebar"
          :show="isMenuOpened"
          :is-ecom-page="isEcomPage"
          @update:show="setIsMenuOpened"
        />
      </transition>
    </div>
    <div :class="$style.bottomSectionWrap">
      <div v-if="!isCheckoutOrSuccessPage" :class="$style.sectionOnlineStore">
        <aw-square-link
          :tag="isShop ? LvLink : 'div'"
          :to="isShop ? localePath('/shop') : null"
          :disabled-style="!isShop"
          :is-active="isEcomPage"
          :[`data-aw-${buttonDataAttr}`]="1"
          @touchstart="prefetchCats"
          @mouseover="prefetchCats"
        >
          <template #icon>
            <lv-icon name="store-16" :size="16" />
          </template>
          <template #text>
            <span v-if="isShop" :class="$style.textGroup">
              <span v-text="$awt('aw.dh_header.online_store_block.online_store_a')" />
              <span v-text="$awt('aw.dh_header.online_store_block.online_store_b')" />
            </span>
            <adc-user-store v-else />
          </template>
        </aw-square-link>
        <aw-svg-border :class="[$style.svgBorder, $style.sectionOnlineStoreSvgBorder]" />
      </div>
      <div :class="$style.sectionLogin">
        <aw-square-link
          id="userDropdown"
          ref="userDropdownElement"
          :styling="!isEcomUser ? 'link-primary-version2' : null"
          :loading-id="[LOGGED_OUT_TOASTER_HEADER,
                        LOGGED_OUT_TOASTER_TIMEFRAME_MODAL,
                        LOGGED_OUT_TOASTER_CHECKOUT,
                        LOGGED_OUT_TOASTER_PROFILE_EMPTY,
                        LOGGED_OUT_TOASTER_FAVOURITE_LOGIN,
                        LOGGED_OUT_TOASTER_FAVOURITE_REGISTER,
                        LOGGED_OUT_TOASTER_PRIZE_GAME,
                        LOGGED_OUT_TOASTER_PROFILE_FORM]"
          :loading-spinner-attributes="isCareerUser ? { widthWithUnit: '1.25rem', heightWithUnit: '1.25rem'} : null"
          :tag="isEcomUser ? 'button' : LvButton"
          :href="isEcomUser ? null : 'javascript:void(0)'"
          :type="isEcomUser ? 'button' : null"
          :aria-expanded="isEcomUser ? isSelfcareMenuOpened : false"
          :aria-haspopup="isEcomUser"
          :aria-label="profileLines.lineJoined"
          @click="isEcomUser ?
            isSelfcareMenuOpened = !isSelfcareMenuOpened :
            openLoginModal(isCareerUser ? {
              loadingId: LOGGED_OUT_TOASTER_HEADER,
              showToaster: true,
              toasterAttributes: {
                title: $awt('aw.career.toast.logged_out_from_career_profile')
              }
            } : {})"
        >
          <template #icon>
            <lv-icon name="profile-16" :size="16" />
          </template>
          <template #text>
            <span v-if="isEcomUser" :class="$style.textGroup">
              <template v-if="isShop">
                <span :class="$style.profileAccountText" v-text="profileLines.line0" />
                <span :class="$style.profilePointText" v-text="profileLines.line1" />
              </template>
              <template v-else>
                <span v-text="profileLines.line0" />
                <span v-text="profileLines.line1" />
              </template>
            </span>
            <span v-else :class="[$style.textGroup, $style.textGroupGapless]">
              <span v-text="profileLines.line0" />
              <span v-text="profileLines.line1" />
            </span>
          </template>
        </aw-square-link>
        <aw-svg-border :class="[$style.svgBorder, {[$style.hideOnTablet]: isCheckoutOrSuccessPage}]" />
        <div v-show="isSelfcareMenuOpened" aria-labelledby="userDropdown" :class="$style.dropdown">
          <aw-selfcare-dropdown-menu
            v-if="isSelfcareMenuOpened"
            :show="isSelfcareMenuOpened"
            @update:show="setIsSelfcareMenuOpened"
          />
        </div>
      </div>
      <div ref="basketWrapperElement" :class="$style.sectionCart">
        <aw-square-link
          ref="smallBasketBtnElement"
          tag="button"
          type="button"
          :aria-label="`${$awt('aw.dh_header.cart_block.cart')} ${getCartSumPriceString}`"
          :aria-expanded="isSmallBasketOpen"
          :aria-disabled="isBasketButtonDisabled"
          :disabled-style="isBasketButtonDisabled"
          :class="AnaliticsClasses.cartButton"
          @click="toggleSmallBasket"
        >
          <template #icon>
            <lv-icon name="cart-16" :size="16" />
          </template>
          <template #text>
            <span :class="$style.textGroup">
              <span :class="$style.cartText" v-text="$awt('aw.dh_header.cart_block.cart')" />
              <span v-text="getCartSumPriceString" />
            </span>
          </template>
        </aw-square-link>
        <transition v-bind="transitionX">
          <aw-small-basket
            v-if="isSmallBasketOpen"
            v-model:show="isSmallBasketOpen"
            :class="AnaliticsClasses.minicart.wrapper"
            @update:show="hideSmallBasket"
          />
        </transition>
      </div>
    </div>
    <transition v-bind="transitionOpacity">
      <div v-if="isSearchBackdropShown" :class="$style.backdrop" style="z-index: 2" />
      <div
        v-else-if="isMenuOpened"
        :class="$style.backdrop"
        style="z-index: 4"
        @click="setIsMenuOpened(false)"
      />
    </transition>
  </div>
</template>

<script setup>
  import { ref, computed, watch, onMounted, onUnmounted, defineAsyncComponent, nextTick } from 'vue';
  import { useRoute, useNuxtApp, useId } from 'nuxt/app';
  import { useUserInterfaceStore } from '~~/common/stores/userInterface.js';
  import { useUserStore } from '~~/common/stores/user.js';
  import { useLoyaltyCardStore } from '~~/shop/stores/loyaltyCard';
  import { LvButton, LvIcon, LvLink } from '~~/common/components/loginet-vue-shop/index.mjs';
  import { openLoginModal } from '~~/common/mixins/userLoginAndRegistrationMixin.js';
  import { getDeliveryLocationText, openDeliveryModal } from '~~/common/mixins/deliveryTimeSlot.js';
  import AnaliticsClasses from '~~/common/config/AnaliticsClasses.js';
  import { uuid4 } from '~~/common/utils';
  import { transitionOpacity, transitionX } from '~~/common/utils/transition.js';
  import { formatPoints, price } from '~~/common/plugins/price.js';
  import AwSquareLink from '~~/common/components/Common/AwSquareLink.vue';
  import AwSvgBorder from '~~/common/components/AwSvgBorder.vue';
  import { useCategoryStore } from '~~/common/stores/category.js';
  import { useOrderStore } from '~~/common/stores/order.js';
  import AwCheckoutIndicator from './AwCheckoutIndicator.vue';
  import {
    LOGGED_OUT_TOASTER_CHECKOUT,
    LOGGED_OUT_TOASTER_FAVOURITE_LOGIN,
    LOGGED_OUT_TOASTER_FAVOURITE_REGISTER,
    LOGGED_OUT_TOASTER_HEADER,
    LOGGED_OUT_TOASTER_PRIZE_GAME,
    LOGGED_OUT_TOASTER_PROFILE_EMPTY,
    LOGGED_OUT_TOASTER_PROFILE_FORM,
    LOGGED_OUT_TOASTER_TIMEFRAME_MODAL,
  } from '~~/common/config/LoadingIdConfig.js';

  const AdcUserStore = defineAsyncComponent(() => import('~~/dealer/components/AdcUserStore.vue'));
  const AwSearchInput = defineAsyncComponent(() => import('~~/common/components/Common/AwSearchInput.vue'));
  const AwMenuSidebar = defineAsyncComponent(() => import('~~/common/components/Common/Header/AwMenuSidebar.vue'));
  const AwSmallBasket = defineAsyncComponent(() => import('~~/common/components/Common/Header/AwSmallBasket.vue'));
  const AwSelfcareDropdownMenu = defineAsyncComponent(() => import('~~/common/components/Common/Header/AwSelfcareDropdownMenu.vue'));

  const props = defineProps({
    isMenuOpened: {
      type: Boolean,
      required: true,
    },
    isOnTop: {
      type: Boolean,
      required: true,
    },
    isPinned: {
      type: Boolean,
      required: true,
    },
    isEcomPage: {
      type: Boolean,
      required: true,
    },
    isProductListPage: {
      type: Boolean,
      required: true,
    },
    isBoutique: {
      type: Boolean,
      required: true,
    },
  });
  const emits = defineEmits(['update:isMenuOpened']);

  const nuxtApp = useNuxtApp();
  const { isShop } = nuxtApp.$awMergedConfig;
  const { $awt } = nuxtApp;

  const buttonDataAttr = useId();

  const isSearchBackdropShown = ref(false);
  const isSelfcareMenuOpened = ref(false);
  const isSmallBasketOpen = ref(false);
  const menuBtnElement = ref(null);
  const menuSidebar = ref(null);
  const userDropdownElement = ref(null);
  const smallBasketBtnElement = ref(null);
  const basketWrapperElement = ref(null);

  const getCartSumPrice = computed(() => {
    return calcCartSumPrice(useOrderStore().order);
  });
  const getCartSumPriceString = computed(() => {
    const cartSumPrice = getCartSumPrice.value;
    return price(
      useNuxtApp().$i18n.locale.value,
      cartSumPrice,
      cartSumPrice.currency,
      'gross',
    );
  });

  function calcCartSumPrice (order) {
    const splits = order?.splits || [];
    const currency = splits[0]?.summary?.itemsSum?.currency || 'HUF';
    const sum = splits.reduce((acc, {
      summary: {
        itemsSum,
        discounts,
      },
    }) => {
      return acc + itemsSum.grossDiscounted - discounts.items.gross;
    }, 0);
    return {
      gross: sum,
      currency,
    };
  }

  const isEcomUser = computed(() => {
    return useUserStore().isEcomUser;
  });
  const isCareerUser = computed(() => {
    return useUserStore().isCareerUser;
  });

  const isBasketButtonDisabled = computed(() => {
    const route = useRoute();
    const { basketOperationLoading } = useUserInterfaceStore();
    const isForbiddenPage = /.*?\/shop\/(basket|checkout)/gi.test(route.fullPath);
    return isForbiddenPage || basketOperationLoading;
  });

  const isCheckoutOrSuccessPage = computed(() => {
    const { isCheckoutPage } = useUserInterfaceStore();
    return isCheckoutPage;
  });

  const deliveryLines = computed(() => {
    const computedText = getDeliveryLocationText.value;
    return {
      line0: computedText ? '' : $awt('aw.dh_header.delivery_block.button_pre'),
      line1: computedText || $awt('aw.dh_header.delivery_block.button_text'),
      line2: computedText ? '' : $awt('aw.dh_header.delivery_block.button_post'),
    };
  });

  const profileLines = computed(() => {
    const { loyaltyCard } = useLoyaltyCardStore();
    let line0;
    let line1;
    if (isEcomUser.value) {
      if (isShop) {
        line0 = $awt('aw.dh_header.login_register_block.account');
        line1 = loyaltyCard?.balance?.points ? $awt(
          'aw.dh_header.login_register_block.point',
          {
            point: formatPoints({
              number: loyaltyCard?.balance?.points || 0,
            }),
          },
        ) : $awt('aw.dh_header.login_register_block.account');
      } else {
        const {
          lastName,
          firstName,
        } = useUserStore().data?.personalInfo || {};
        line0 = '';
        line1 = `${lastName} ${firstName}`;
      }
    } else {
      line0 = $awt('aw.dh_header.login_register_block.login_register_a');
      line1 = $awt('aw.dh_header.login_register_block.login_register_b');
    }
    return {
      line0,
      line1,
      lineJoined: [line0, line1].join(' '),
    };
  });

  const UUID = uuid4();
  watch(() => props.isMenuOpened, setMenuBackdropLock);

  onUnmounted(() => {
    setMenuBackdropLock(false);
  });

  onMounted(() => {
    if (isEcomUser.value) {
      const loyaltyCardStore = useLoyaltyCardStore();
      try {
        loyaltyCardStore.fetchLoyaltyCard();
      } catch (error) {
        this.$logger.error(error);
      }
    }
  });

  function setMenuBackdropLock (newVal) {
    useUserInterfaceStore().setBackdropChildren({
      uuid: UUID,
      value: newVal,
    });
  }

  function setIsMenuOpened (newVal) {
    if (!newVal && props.isMenuOpened) {
      menuBtnElement.value?.$el?.focus?.();
    }
    emits('update:isMenuOpened', newVal);
  }

  function toggleIsMenu () {
    emits('update:isMenuOpened', !props.isMenuOpened);
    if (props.isProductListPage && !props.isBoutique) {
      const currentCategory = useCategoryStore().getCategoryFromTree;
      setTimeout(() => {
        menuSidebar.value.openCategoryMenu(currentCategory.parentsNameArr.map(c => c.categoryId).slice(0, -1));
      }, 500);
    }
  }

  function setIsSelfcareMenuOpened (newVal, { focusInside }) {
    if (!newVal && isSelfcareMenuOpened.value && focusInside) {
      userDropdownElement.value?.$el?.focus?.();
    }
    nextTick(() => {
      isSelfcareMenuOpened.value = newVal;
    });
  }

  async function toggleSmallBasket () {
    if (!isBasketButtonDisabled.value) {
      isSmallBasketOpen.value = !isSmallBasketOpen.value;
      if (isSmallBasketOpen.value) {
        nextTick(() => {
          document.body.addEventListener('click', hideBasketOutsideClick);
        });
      }
    }
  }

  function hideSmallBasket () {
    smallBasketBtnElement.value?.$el?.focus?.();
  }

  function hideBasketOutsideClick (event) {
    if (!basketWrapperElement.value || !basketWrapperElement.value.contains(event.target)) {
      if (event.target.classList.contains('hideBasketOutsideClick') || event.target.id === 'aw-remove-basket-item-modal') {
        return;
      }
      isSmallBasketOpen.value = false;
      document.body.removeEventListener('click', hideBasketOutsideClick);
    }
  }

  const categoryStore = useCategoryStore();

  function prefetchCats (event) {
    /* NOTE: mouseover runs for the node and all it's children, but we only want it to run once */
    if (event.target.hasAttribute(`data-aw-${buttonDataAttr}`)) {
      categoryStore.fetchCategories();
    }
  }

  function openOnlineStoreMenu () {
    setTimeout(() => {
      menuSidebar.value.openOnlineStoreMenu();
    }, 500);
  }

  function focusOnTop () {
    setTimeout(() => {
      menuSidebar.value.focusOnTop();
    }, 500);
  }

  defineExpose({
    openOnlineStoreMenu,
    focusOnTop,
  });

</script>

<style module lang="scss">
.userHeaderDh {
  --pad: 16px;
  --numberOfButtons: 3;
  --buttonWidth: calc((100% - 2 * var(--pad)) / var(--numberOfButtons));
  --logoWidth: 72px;
  --transitionValue: all 0.32s ease-in-out;
  font-family: $secondary-font;

  display: grid;
  align-items: center;
  padding-bottom: 20px;
  transition: var(--transitionValue);
  border-radius: 0 0 24px 24px;
  background: $color-white;
  grid-template-columns: var(--pad) 32px 1fr 40px var(--pad);
  grid-template-areas:
  "delivery delivery delivery delivery delivery"
  ". logo search menu ."
  ". bottomWrap bottomWrap bottomWrap .";

  &.isCheckoutOrSuccessPage {
    grid-template-columns: var(--pad) 32px 1fr 1fr 40px var(--pad);
    grid-template-areas:
      "delivery delivery delivery delivery delivery delivery"
      ". logo bottomWrap bottomWrap menu ."
      ". checkout checkout checkout checkout .";
  }

  @include tablet(min) {
    --pad: 48px;
    --numberOfButtons: 4;
    grid-template-columns: var(--pad) 32px 1fr repeat(calc(var(--numberOfButtons) - 1), var(--buttonWidth)) var(--pad);
    grid-template-areas:
    "delivery delivery delivery delivery delivery delivery delivery"
    ". logo search search search search ."
    ". menu menu store login cart .";
    padding-bottom: 28px;

    &.isCheckoutOrSuccessPage {
      grid-template-columns: var(--pad) 32px 1fr max-content max-content var(--pad);
      grid-template-areas:
      "delivery delivery delivery delivery delivery delivery"
      ". logo checkout login cart .";
    }
  }

  @include tablet(only) {
    border-radius: 0 0 32px 32px;
  }

  @include desktop-small(min) {
    --pad: 60px;
    grid-template-columns: var(--pad) var(--logoWidth) 80px 129px 1fr max-content auto var(--pad);
    grid-template-areas:
    "delivery delivery delivery delivery delivery delivery delivery delivery"
    ". logo menu store search login cart .";
    padding-bottom: 0;

    &.isCheckoutOrSuccessPage {
      grid-template-columns: var(--pad) var(--logoWidth) 80px 129px 1fr max-content max-content var(--pad);
      grid-template-areas:
      "delivery delivery delivery delivery delivery delivery delivery delivery"
      ". logo checkout checkout checkout login cart .";
    }
  }
  @include desktop-medium(min) {
    --pad: 96px;
    --logoWidth: 114.67px;
  }

  @include desktop-large(min) {
    --pad: calc((100% - 1736px) / 2);
  }

  &.isOnTop {
    @include tablet(min) {
      border-radius: 0 0 32px 32px;
    }

    @include desktop-small(min) {
      grid-template-columns: var(--pad) var(--logoWidth) 80px 129px 1fr max-content 120px var(--pad);
    }

    @include desktop-medium(min) {
      --logoWidth: 152px;

      .logoDesktop {
        width: 112px;
        height: 36px;
      }
    }
  }


}

%hideMobileIcon {
  @include mobile(max) {
    --awSquareLinkIconDisplay: none;
  }
}

.sectionDelivery {
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 32px;
  transition: var(--transitionValue);
  border-bottom: 1px solid $color-border;
  grid-area: delivery;

  &Button {
    border-radius: 0 !important;
  }

  * {
    transition: var(--transitionValue);
  }
}

.sectionLogo {
  grid-area: logo;

  * {
    transition: var(--transitionValue);
  }
}

.sectionSearch {
  grid-area: search;
  padding: 12px;

  @include tablet(min) {
    padding: 28px 0 20px 24px;
  }
  @include desktop-small(min) {
    padding: 38px 12px;
  }
}

.sectionMenu {
  grid-area: menu;
  position: relative;
}

.sectionOnlineStore {
  grid-area: store;
  position: relative;
  @extend %hideMobileIcon;
}

.sectionLogin {
  grid-area: login;
  position: relative;
  @extend %hideMobileIcon;
}

.sectionCart {
  grid-area: cart;
  @extend %hideMobileIcon;
}

.sectionCheckout {
  grid-area: checkout;

  @include mobile(max) {
    padding-top: 16px;
  }
}

.sectionLogin, .sectionCart {
  @include desktop-small(min) {
    padding: 0 20px;
  }
}

.bottomSectionWrap {
  @include mobile(max) {
    grid-area: bottomWrap;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas: "store login cart";
    padding-top: 8px;
  }
  @include tablet(min) {
    display: contents;
  }
}

.sectionDelivery,
.sectionMenu,
.sectionOnlineStore,
.sectionLogin,
.sectionCart,
.sectionCheckout {
  text-align: center;
}

.sectionDeliveryButton {
  font-size: inherit;
  line-height: inherit;
  max-width: unset;
  margin: 0 3px;
  text-decoration: underline;
}

.sectionDeliveryIcon {
  margin-right: 8px;
}

.sectionMenu,
.sectionOnlineStore,
.sectionLogin,
.sectionCart {
  display: flex;
  align-items: center;
  justify-content: center;
}

.svgBorder {
  position: absolute;
  right: 0;
  height: 100%;
}

.sectionOnlineStoreSvgBorder {
  @include desktop-small(min) {
    display: none;
  }
}

.hideOnMobile {
  @include mobile(max) {
    display: none;
  }
}

.hideOnTablet {
  @include tablet(max) {
    @include tablet(min) {
      display: none;
    }
  }
}

%showOnMobile {
  @include tablet(min) {
    display: none;
  }
}

.textGroup {
  display: flex;

  &:not(&Gapless) {
    column-gap: 3px;
  }

  @include mobile(max) {
    display: flex;
    flex-direction: column;
  }
}

.logoMobile {
  @include desktop-medium(min) {
    display: none;
  }
}

.logoDesktop {
  width: 74.67px;
  height: 24px;
  @include desktop-small(max) {
    display: none;
  }
}

.dropdown {
  position: absolute;
  z-index: 10000;
  top: calc(100% + 8px);
  @include tablet(min) {
    right: calc(50% - 38px);
  }
}

.profileAccountText {
  @extend %showOnMobile;

  &::after {
    content: ":";
  }
}

.cartText {
  @extend %showOnMobile;
}

.searchInput {
  font-size: 12px;
  font-weight: 400;
  z-index: 3;
  height: 40px;

  @include tablet(min) {
    font-size: 14px;
    font-weight: 500;
    height: 48px;
  }

  &Wrapper {
    height: 40px;
    @include tablet(min) {
      height: 48px;
    }
  }
}

@mixin shrinkIcon() {
  --awSquareLinkIconHeight: 0;
  --awSquareLinkIconMinHeight: 0;
  --awSquareLinkGap: 0;
  --awSquareLinkIconBorder: 0 solid transparent;
  --awSquareLinkIconScale: 0;
}

@mixin shrunkStyle() {
  @include tablet(min) {
    @include tablet(max) {
      padding-top: 20px;
      padding-bottom: 20px;
    }

    .sectionSearch {
      padding-top: 20px;
    }
  }
  @include desktop-small(min) {
    padding-top: 16px;
    padding-bottom: 16px;

    .sectionSearch {
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }

  .sectionOnlineStore,
  .sectionLogin,
  .sectionCart {
    @include shrinkIcon();
  }

  .sectionMenu {
    @include tablet(min) {
      @include shrinkIcon();
    }
  }

  .sectionDelivery:not(:focus-within, :focus, :hover) {
    font-size: 1px;
    line-height: 1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    min-height: 1px;
    margin: -1px;
    padding: 0;
    opacity: 0.01;
    border: 0;

    * {
      font-size: 1px;
      line-height: 1px;
      margin: -1px;
      padding: 0;
    }

    .sectionDeliveryIcon {
      scale: 0;
    }
  }

  .profileAccountText,
  .cartText {
    display: initial;
  }

  @include desktop-small(min) {
    .profileAccountText {
      &::after {
        display: none;
      }
    }

    .profilePointText {
      display: none;
    }
  }

  .searchInput {
    font-size: 12px;
    font-weight: 400;
    height: 40px;
  }
}

.userHeaderDh {
  @include mobile(max) {
    &:not(.isOnTop, .isPinned) {
      @include shrunkStyle();
    }
  }
  @include tablet(min) {
    &:not(.isOnTop) {
      @include shrunkStyle();
    }
  }
}

.backdrop {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
  background: change-color($color-olive-750, $alpha: 0.2);
}

.isCheckoutOrSuccessPage {
  padding-top: 16px;
  padding-bottom: 20px;

  @include tablet(min) {
    padding-top: 28px;
    padding-bottom: 28px;
  }

  .sectionLogo {
    @include tablet(min) {
      @include tablet(max) {
        grid-row: 2;
      }
    }
  }

  @include tablet(only) {
    .sectionLogin {
      padding: 0 12px;
    }

    .sectionCart {
      padding: 0 12px;
    }
  }

  .sectionMenu > * {
    display: none !important;
  }

  .bottomSectionWrap {
    @include mobile(max) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-areas: "login cart";
    }
  }
}
</style>
