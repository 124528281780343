import { getCurrentInstance } from 'vue';
import { useRoute } from 'nuxt/app';

export function fetchKey () {
  // Custom implementation of node_modules/nuxt/dist/app/composables/component.js:8 runLegacyAsyncData
  // to solve this issue: https://github.com/nuxt/nuxt/discussions/16012#discussion-4773317
  // NuxtJS includes the route.fullPath when fetchKey is not defined,
  // but route.fullPath contains the url fragment only on client side so
  // that causes a cache miss.
  const instance = getCurrentInstance();
  const route = useRoute();
  const generatedKey = [instance.type._fetchKeyBase, route.fullPath.replace(route.hash, ''), route.matched.findIndex(r => Object.values(r.components || {}).includes(instance.type))].join(':');
  return generatedKey;
}
