<template>
  <div :class="$style.video" :style="{ paddingBottom }">
    <iframe
      :src="`https://player.vimeo.com/video/${id}`"
      :width="width"
      :height="height"
      frameborder="0"
      :title="title"
      webkitallowfullscreen
      mozallowfullscreen
      allowfullscreen
    />
  </div>
</template>

<script>
  export default {
    name: 'AwVimeoVideo',
    props: {
      id: {
        type: String,
        required: true,
      },
      title: {
        type: String,
        default: '',
      },
      width: {
        type: Number,
        default: 400,
      },
      height: {
        type: Number,
        default: 225,
      },
      paddingBottom: {
        type: String,
        default: '70%',
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
.video {
  position: relative;

  iframe {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }

  & + & {
    @include md() {
      margin-top: 20px;
    }
    margin-top: 10px;
  }
}
</style>
