<template>
  <div :class="$style.componentWrapper">
    <div :class="$style.cardWrapper">
      <div v-for="(card, index) in cards" :key="index" :class="$style.card">
        <img
          :src="card.image"
          alt=""
          width="96"
          height="96"
          loading="lazy"
        >
        <p :class="$style.cardText">
          {{ card.text }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  import firstStep from '~~/common/assets/images/whyChoseAuchan/1.svg';
  import secondStep from '~~/common/assets/images/whyChoseAuchan/2.svg';
  import thirdStep from '~~/common/assets/images/whyChoseAuchan/3.svg';
  import fourhStep from '~~/common/assets/images/whyChoseAuchan/4.svg';
  import analyticsBannerInteractionMixin from '~~/common/mixins/analyticsBannerInteractionMixin.js';

  export default {
    name: 'AwWhyChooseAuchan',
    mixins: [analyticsBannerInteractionMixin],
    inheritAttrs: false,
    data () {
      return {
        cards: [
          {
            image: firstStep,
            text: this.$awt('aw.cms.why_choose_auchan.cheap_delivery'),
          },
          {
            image: secondStep,
            text: this.$awt('aw.cms.why_choose_auchan.fresh_products'),
          },
          {
            image: thirdStep,
            text: this.$awt('aw.cms.why_choose_auchan.remote_help'),
          },
          {
            image: fourhStep,
            text: this.$awt('aw.cms.why_choose_auchan.same_day_pickup'),
          },
        ],
      };
    },
    mounted () {
      this.sendBannerViewInteraction();
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
.componentWrapper {
  .cardWrapper {
    display: grid;
    grid-template-rows: repeat(4, auto);
    grid-template-columns: auto;
    gap: 20px;

    @include tablet(only) {
      grid-template-rows: repeat(2, 1fr);
      grid-template-columns: repeat(2, 1fr);
      gap: 24px;
    }

    @include desktop-small(min) {
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: auto;
      gap: 32px;
    }

    @include desktop-medium(min) {
      gap: 40px;
    }

    .card {

      font-family: $secondary-font;
      font-size: 14px;
      font-weight: $font-weight-normal-v2;
      line-height: 20px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: flex-start;

      padding: 24px;
      text-align: center;
      color: $color-text-primary;

      border-radius: 16px;
      background-color: $color-white;
      gap: 24px;

      @include tablet(min) {
        font-size: 16px;
        line-height: 24px;
      }

      &Text {
        @include desktop-small(only) {
          min-height: 120px;
        }
      }
    }
  }
}
</style>
