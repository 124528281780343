import { defineNuxtPlugin, useRouter } from 'nuxt/app';
import { extractResourceFromPath } from '~~/common/utils/urlResource';
import { crossPlatformHashNotExceptionSafe, epsRound, isCategoryProductList } from '~~/common/utils';
import { getAnaliticsUnit, itemToQuantity, unitConverter } from '~~/common/utils/variant';
import { DELIVERY_METHODS, PAYMENT_METHODS } from '~~/shop/awPlugins/app-config';
import { HASH_SHA_512 } from '~~/common/config/CrossPlatformHashFunctions.js';
import { BILLING_METHOD_COMPANY, BILLING_METHOD_DELIVERY, BILLING_METHOD_PERSONAL } from '~~/common/config/BillingConfig';
import deliveryMethodsEnum from '~~/shop/components/Common/Modals/AwDeliveryMethodAndTimeframeModal/enum/deliveryMethodsEnum';
import { useUrlResourceStore } from '~~/common/stores/urlResource';
import { useOrderStore } from '~~/common/stores/order';
import { useProductStore } from '~~/common/stores/product';
import { useSeoStore } from '~~/common/stores/seo';
import { useUserStore } from '~~/common/stores/user';
import { useAnalyticsQueueStore } from '~~/common/stores/analyticsQueue';
import { useCategoryStore } from '~~/common/stores/category';
import { useCheckoutStore } from '~~/common/stores/checkout';
import { useDeliveryStore } from '~~/shop/stores/delivery';
import { usePromotionStore } from '~~/shop/stores/promotion';
import { calculatePageType } from '~~/common/config/RoutePageTypeMap.js';
import { AnalyticsBannerInteraction } from '~~/common/utils/AnalyticsBannerInteraction';
import { getTypeByTimeFrame, timeFrameTypes } from '~~/shop/components/Common/Modals/AwDeliveryMethodAndTimeframeModal/timeframe/timeFrameLengthHelper.js';

export const CLICK_MAP_TYPE_DEPARTMENT_STORE = Symbol('clickMapTypeDepartmentStore');
export const CLICK_MAP_TYPE_AW_SHOP_AREA = Symbol('clickMapTypeAwShopArea');
export const PCAT_ADD_PRODUCT = Symbol('pcatAddProduct');
export const PCAT_CANCEL = Symbol('pcatCancel');
export const PCAT_ACCEPT = Symbol('pcatAccept');
export const CDM_RES = Symbol('cdmRes');
export const CDM_POSTCODE = Symbol('cdmPostcode');
export const KS_MANUAL = Symbol('keywordSearchManual');
export const KS_LIST = Symbol('keywordSearchList');
export const KS_SUGGESTION = Symbol('keywordSearchSuggestion');
export const KS_CATEGORY = Symbol('keywordSearchCategory');
export const KS_PRODUCT = Symbol('keywordSearchProduct');
const LISTEN_FOR_SEO_CHANGE = Symbol('listenForSeoChange');
export const PUSH_LOCALE_CODE_CHANGE = Symbol('pushLocaleCodeChange');
export const PUSH_TRACK_PAGE_VIEW = Symbol('pushTrackPageView');
export const PUSH_KEYWORD_SEARCH = Symbol('pushKeywordSearch');
export const PUSH_PRODUCT_VIEW = Symbol('pushProductView');
export const PUSH_CATEGORY_VIEW = Symbol('pushCartegoryView');
const PUSH_CART_VIEW = Symbol('pushCartView');
export const PUSH_ADD_NEW_ITEM_TO_CART_PRE = Symbol('pushAddNewItemToCart');
export const PUSH_TRACK_CART_STATE = Symbol('pushTrackCartState');
export const PUSH_TRUNCATE_CART = Symbol('pushTruncateCart');
export const PUSH_REMOVE_CART_ITEM = Symbol('pushRemoveCartItem');
export const PUSH_ITEM_TO_FAVOURITE = Symbol('pushItemToFavourite');
export const PUSH_BASKET_TO_FAVOURITE = Symbol('pushBasketToFavourite');
export const PUSH_ADD_VOUCHER = Symbol('pushAddVoucher');
export const PUSH_ADD_TO_CART = Symbol('pushAddToCart');
export const PUSH_REMOVE_FROM_CART = Symbol('pushRemoveFromCart');
export const PUSH_CLICK_CATEGORY = Symbol('pushClickCategory');
export const PUSH_FILTER_PRODUCT = Symbol('pushFilterProduct');
export const PUSH_SELECT_ITEM = Symbol('pushSelectItem');
export const PUSH_CHANGE_CHECKOUT_DELIVERY_DATA = Symbol('pushChangeCheckoutDeliveryData');
export const PUSH_CHANGE_CHECKOUT_BILLING_DATA = Symbol('pushChangeCheckoutBillingData');
export const PUSH_BEGIN_CHECKOUT = Symbol('pushBeginCheckout');
export const PUSH_COMPARE_PRODUCT = Symbol('pushCompareProduct');
export const PUSH_CHANGE_CHECKOUT_PAYMENT_DATA = Symbol('pushChangeCheckoutPaymentMethod');
export const PUSH_CLICK_MAP = Symbol('pushClickMap');
export const PUSH_CHANGE_DELIVERY_METHOD = Symbol('pushChangeDeliveryMethod');
export const PUSH_PRE_ORDER = Symbol('pushPreOrder');
export const PUSH_POST_ORDER = Symbol('pushPostOrder');
export const PUSH_DELETE_ORDER = Symbol('pushDeleteOrder');
export const PUSH_VOUCHER = Symbol('pushVoucher');
export const PUSH_ZOOM_PRODUCT = Symbol('pushZoomProduct');
export const PUSH_CLICK_BANNER = Symbol('pushClickBanner');
export const PUSH_VIEW_BANNER = Symbol('pushViewBanner');
export const FLUSH_VIEW_BANNER = Symbol('flushViewBanner');
export const PUSH_REVIEW_PRODUCT = Symbol('pushReviewProduct');
export const PUSH_NEWSLETTER = Symbol('pushNewsletter');
export const PUSH_REGISTRATION_SUCCESS = Symbol('pushRegistrationSuccess');
export const PUSH_REGISTRATION_ERROR = Symbol('pushRegistrationError');
export const PUSH_LOGOUT = Symbol('pushLogout');
export const PUSH_LOGIN_SUCCESS = Symbol('pushLoginSuccess');
export const PUSH_LOGIN_ERROR = Symbol('pushLoginError');
export const PUSH_RANGE_FILTER = Symbol('pushRangeFilter');

const PUSH_PAGE_INIT_EVENT = Symbol('pushPageInitEvent');
export const PUSH_PURCHASE = Symbol('pushPurchase');
export const PUSH_VIEW_PRODUCT_LIST = Symbol('pushViewProductList');
export const PUSH_VIEW_CART = Symbol('pushViewCart');

class AnalyticsProduct {
  constructor ({
    product,
    variantProperty,
    checkoutType,
  }) {
    const checkoutTypeMap = {
      FOOD: 'food',
      NON_FOOD: 'non-food',
    };

    this.item_name = product[variantProperty].name;
    this.item_id = product[variantProperty].sku;
    this.price = product[variantProperty].price.grossDiscounted?.toFixed(2);
    this.item_brand = product.brandName || product[variantProperty]?.brandName;
    this.item_category = product.categories && product.categories.length > 0 && product.categories[0] ? product.categories[0].name : '-';
    this.item_category2 = product.categories && product.categories.length > 0 && product.categories[1] ? product.categories[1].name : '-';
    this.item_category3 = product.categories && product.categories.length > 0 && product.categories[2] ? product.categories[2].name : '-';
    this.item_category4 = product.categories && product.categories.length > 0 && product.categories[3] ? product.categories[3].name : '-';
    this.item_type = checkoutTypeMap?.[checkoutType] || product.isNonFood ? checkoutTypeMap.NON_FOOD : checkoutTypeMap.FOOD;
    this.loyalty_discount = product[variantProperty].isLoyaltyPriceValid ? 'yes' : 'no';
    this.discount = product[variantProperty].price.gross === product[variantProperty].price.grossDiscounted ? null : (product[variantProperty].price.gross - product[variantProperty].price.grossDiscounted).toFixed(2);
  }
}

export default defineNuxtPlugin((nuxtApp) => {
  const {
    $i18n,
    $logger,
    $api,
    $awGtm,
    $date,
    $pinia,
    $awMergedConfig,
    $getHref,
    $getHost,
    $awt,
  } = nuxtApp;
  const router = useRouter();
  const categoryStore = useCategoryStore($pinia);
  const promotionStore = usePromotionStore($pinia);
  const seoStore = useSeoStore($pinia);
  const orderStore = useOrderStore($pinia);
  const productStore = useProductStore($pinia);
  const deliveryStore = useDeliveryStore($pinia);
  const checkoutStore = useCheckoutStore($pinia);
  const urlResourceStore = useUrlResourceStore($pinia);
  const userStore = useUserStore($pinia);
  const analyticsQueueStore = useAnalyticsQueueStore($pinia);
  const T_GTM = 'trackerGtm';
  const T_TRACKERS = new Set([T_GTM]);
  let counter = 0;
  const awAnalytics = {
    [LISTEN_FOR_SEO_CHANGE] () {
      router.afterEach(() => {
        counter++;
        this[PUSH_TRACK_PAGE_VIEW]();
        if ($awMergedConfig.googleTagManagerId && $awMergedConfig.isGtmTrackingEnabled && counter > 1) {
          this[PUSH_PAGE_INIT_EVENT]();
        }
      });
    },
    _push (...analytics) {
      try {
        if (import.meta.client && $awMergedConfig.googleTagManagerId && $awMergedConfig.isGtmTrackingEnabled) {
          if (window.awIsGtmLoaded === false) {
            window.dataLayer = [];
          } else if (window.awIsGtmLoaded === true) {
            let idx;
            window.dataLayer.some((v, i) => {
              // Gets first element of not 0 indexed array.
              // https://stackoverflow.com/questions/37990756/get-first-element-in-array-with-index-not-starting-from-0#answer-37991354
              idx = i;
              return true;
            });
            if (window.dataLayer.length - idx > 2 * $awMergedConfig.gtmDataLayerItemRetention) {
              for (const i in window.dataLayer) {
                if ((window.dataLayer.length - i) > $awMergedConfig.gtmDataLayerItemRetention) {
                  delete window.dataLayer[i];
                } else {
                  break;
                }
              }
            }
          }
        }
      } catch (err) {
        $logger.error(err);
      }
      analytics.forEach(function () {
        try {
          const {
            type,
            data,
          } = arguments[0];
          const dataResult = data();
          if (type === T_GTM) {
            if ($awMergedConfig.googleTagManagerId && $awMergedConfig.isGtmTrackingEnabled) {
              if ((typeof window === 'undefined' || window?.awIsGtmLoaded !== false)) {
                $awGtm.push(dataResult);
              }
            }
          } else if (T_TRACKERS.has(type)) {
            $logger.error(`type ${type} is in trackers ${[...T_TRACKERS]}, but it's unspecified how it should be handled.`);
          } else {
            $logger.error(`type should be ${[...T_TRACKERS]} it is: ${type}.`);
          }
        } catch (err) {
          $logger.error(err);
        }
      });
    },
    [PUSH_PAGE_INIT_EVENT] () {
      const oldBlock = document.getElementById('data-layer-page-init-script');
      if (oldBlock) {
        oldBlock.remove();
      }
      const pageType = calculatePageType(window.location.pathname, urlResourceStore.data);
      const pageTitle = seoStore.fullTitle || document?.title || false;
      const pageLanguage = $i18n.locale.value.toUpperCase();
      const userId = userStore.data?.personalInfo?.cdExternalId;
      const userLoggedIn = userStore.isEcomUser ? 'yes' : 'no';

      const newScriptBlock = document.createElement('script');
      newScriptBlock.id = 'data-layer-page-init-script';
      const pageInitData = {
        event: 'pageInit',
        page_type: pageType,
        page_name: pageTitle,
        page_language: pageLanguage,
        user_logged: userLoggedIn,
      };
      if (userId) {
        pageInitData.user_id = userId;
      }
      newScriptBlock.innerHTML = `window.dataLayer.push(${JSON.stringify(pageInitData)});`;
      const defaultGTMScriptBlock = document.getElementById('gtm-default-script');
      document.head.insertBefore(newScriptBlock, defaultGTMScriptBlock);
    },
    [PUSH_LOCALE_CODE_CHANGE] ({ localeCode }) {
      try {
        this._push({
          type: T_GTM,
          data: () => ({
            event: 'eventTracker',
            eventCat: 'Nyelvválasztás',
            eventAct: localeCode?.toUpperCase?.(),
            eventLbl: $getHref(),
          }),
        });
      } catch (err) {
        $logger.error(err);
      }
    },
    [PUSH_KEYWORD_SEARCH] ({
      analiticsType,
      searchKeyword,
    }) {
      try {
        this._push({
          type: T_GTM,
          data: () => ({
            event: 'search',
            term: searchKeyword,
            method: analiticsType === KS_SUGGESTION ? 'suggest' : 'typing',
          }),
        });
      } catch (err) {
        $logger.error(err);
      }
    },
    async [PUSH_PRODUCT_VIEW] ({ sku }) {
      try {
        if ($awMergedConfig.googleTagManagerId && $awMergedConfig.isGtmTrackingEnabled) {
          const product = productStore?.data?.selectedVariant?.sku === sku
            ? productStore?.data
            : await productStore.fetchMainProductForProductPage({
              loc: router.currentRoute.value.query.loc,
              identifierType: 'sku',
              id: sku,
            })
          ;
          const item = new AnalyticsProduct({
            product,
            variantProperty: 'selectedVariant',
          });
          this._push({
            type: T_GTM,
            data: () => ({
              event: 'cleanup',
              ecommerce: null,
            }),
          });
          this._push({
            type: T_GTM,
            data: () => ({
              event: 'view_item',
              number_of_products: 1,
              ecommerce: {
                items: [item],
              },
            }),
          });
        }
      } catch (err) {
        $logger.error(err);
      }
    },
    [PUSH_CATEGORY_VIEW] ({ categoryName }) {
      try {
        if ($awMergedConfig.googleTagManagerId && $awMergedConfig.isGtmTrackingEnabled) {
          this._push({
            type: T_GTM,
            data: () => ({
              event: 'category_page_view',
              // this.campaign = "Csokit vagy csalunk"; TODO Implement later when BE is ready
              category: categoryName,
            }),
          });
        }
      } catch (err) {
        $logger.error(err);
      }
    },
    [PUSH_CART_VIEW] () {
      try {
        if ($awMergedConfig.googleTagManagerId && $awMergedConfig.isGtmTrackingEnabled) {
          const cartItems = (orderStore.order.splits || []).flatMap((s) => {
            return s.packages.flatMap(p => p.items).map(item => ({
              checkoutType: s.checkoutType,
              cartItem: item,
              quantity: itemToQuantity(item),
            }));
          });
          this._push({
            type: T_GTM,
            data: () => ({
              event: 'cart_page_view',
              basket_type: (orderStore.order?.splits?.length > 1 ? 'mixed' : orderStore.order?.splits?.[0]?.checkoutType)?.toLowerCase?.(),
              cart_amount: (orderStore.order?.splits || []).reduce((a, b) => {
                return a + b.summary.sum.grossDiscounted;
              }, 0),
              products_quantity: cartItems.reduce((a, i) => a + (i.quantity || 0), 0),
              products: cartItems.map(({
                checkoutType,
                cartItem,
              }) => {
                try {
                  return new AnalyticsCartItem({
                    checkoutType,
                    cartItem,
                  });
                } catch (err) {
                  $logger.error(err);
                  return {};
                }
              }),
            }),
          });
        }
      } catch (err) {
        $logger.error(err);
      }
    },
    [PUSH_TRACK_PAGE_VIEW] () {
      try {
        if (!($awMergedConfig.googleTagManagerId && $awMergedConfig.isGtmTrackingEnabled)) {
          return;
        }
        Promise.race([
          new Promise(resolve => setTimeout(() => resolve('timeout'), 1000)),
          Promise.allSettled([seoStore.seoRequestsEnRoute]),
        ]).finally(() => {
          // NOTE: document.title as set only very very late in the seoMixin
          // head method and we need this information beforehand.
          const newTitle = seoStore.fullTitle || document?.title || false;
          const newHref = $getHref();

          try {
            const urlResource = extractResourceFromPath(newHref) || {};
            if (urlResource.resourceType === 'product') {
              this[PUSH_PRODUCT_VIEW]({ sku: urlResource.resourceId });
            } else if (urlResource.resourceType === 'category') {
              const currentCat = categoryStore.getCategoryFromTree;
              const currentCatId = parseInt(currentCat?.id, 10);
              const searchedCatId = parseInt(urlResource.resourceId, 10);
              const searchedCat = currentCatId === searchedCatId ? currentCat : categoryStore.getCategoryFromTreeById({ treeId: searchedCatId });
              if (!isCategoryProductList(searchedCat)) {
                this[PUSH_CATEGORY_VIEW]({ categoryName: newTitle });
              }
            } else if (router.currentRoute.value.path.startsWith('/shop/basket')) {
              this[PUSH_CART_VIEW]();
            }
          } catch (err) {
            $logger.error(err);
          }
        });
      } catch (err) {
        $logger.error(err);
      }
    },
    async [PUSH_ADD_NEW_ITEM_TO_CART_PRE] ({ objectType }) {
      try {
        if ($awMergedConfig.googleTagManagerId && $awMergedConfig.isGtmTrackingEnabled) {
          switch (objectType) {
            case 'variant': {
              // NOTE: For perf reasons does not update cart for
              // objectType variant.
              break;
            }
            case 'order':
            case 'shoppingList': {
              // NOTE: Updates cart before analitics calculates the
              // changes between old vs new cart.
              await orderStore.fetchOrder({
                id: 'current',
                canStartSecondAction: true,
                canRunSimultaneous: true,
              });
              break;
            }
          }
        }
      } catch (err) {
        $logger.error(err);
      }
    },
    [PUSH_TRACK_CART_STATE] ({
      newCart,
      oldCart,
      newObject,
      modifiedObject,
    }) {
      try {
        if (!($awMergedConfig.googleTagManagerId && $awMergedConfig.isGtmTrackingEnabled)) {
          return;
        }
        const [oldCartItems, newCartItems] = [oldCart, newCart]
          .map((order) => {
            return (order.splits || []).flatMap((s) => {
              return s.packages.flatMap(p => p.items).map(item => ({
                checkoutType: s.checkoutType,
                cartItem: item,
                quantity: itemToQuantity(item),
              }));
            });
          })
          .map(flatO => Object.fromEntries(flatO.map(i => [i.cartItem.uuid, i])))
        ;
        const uniqueUuids = [...new Set([oldCartItems, newCartItems].flatMap(Object.keys))];
        const removedUuids = uniqueUuids.filter((uuid) => {
          return newCartItems[uuid]?.quantity !== oldCartItems[uuid]?.quantity && oldCartItems[uuid]?.quantity;
        });
        const addedUuids = uniqueUuids.filter((uuid) => {
          return newCartItems[uuid]?.quantity !== oldCartItems[uuid]?.quantity && newCartItems[uuid]?.quantity;
        });
        if (removedUuids.length + addedUuids.length) {
          const newCartItemsValues = Object.values(newCartItems);
          try {
            if (newObject) {
              const newlyAddedCartItems = ['order', 'shoppingList'].includes(newObject.objectType)
                ? addedUuids.filter((uuid) => {
                  return !oldCartItems[uuid]?.quantity;
                }).map((uuid) => {
                  return newCartItems[uuid];
                })
                : newCartItemsValues.filter(({ cartItem }) => {
                  return newObject.objectId.toString() === cartItem.variant.id.toString();
                })
              ;
              if (newlyAddedCartItems.length) {
                this._push({
                  type: T_GTM,
                  data: () => ({
                    event: 'added_to_cart',
                    'saved basket': newObject.objectType === 'shoppingList' ? 'yes' : 'no',
                    products: newlyAddedCartItems.map(({
                      checkoutType,
                      cartItem,
                    }) => {
                      try {
                        return new AnalyticsCartItem({
                          checkoutType,
                          cartItem,
                        });
                      } catch (err) {
                        $logger.error(err);
                        return {};
                      }
                    }),
                  }),
                });
              }
            }
            if (['order', 'shoppingList'].includes(newObject?.objectType) || modifiedObject) {
              const modifiedCartItems = (newObject ? addedUuids : [modifiedObject?.uuid])
                .filter((uuid) => {
                  return oldCartItems[uuid]?.quantity;
                })
                .map((uuid) => {
                  return newCartItems[uuid];
                })
              ;
              modifiedCartItems.forEach(({
                checkoutType,
                cartItem,
              }) => {
                const oldCi = oldCartItems[cartItem.uuid].cartItem;
                const newUnit = getAnaliticsUnit({ quantityType: cartItem?.quantity?.type });
                const oldQuantity = unitConverter({
                  from: {
                    value: oldCi.quantity.quantity,
                    unit: oldCi.quantity.type,
                  },
                  to: newUnit,
                  weightPerPiece: cartItem.variant?.loose?.weightPerPiece,
                });
                this._push({
                  type: T_GTM,
                  data: () => ({
                    event: 'product_quantity_change',
                    name: cartItem?.variant?.name,
                    type: checkoutType?.toLowerCase?.(),
                    change: itemToQuantity(cartItem) - itemToQuantity(oldCi),
                    package: `${cartItem?.quantity?.quantity - oldQuantity.value} ${newUnit}`,
                  }),
                });
              });
            }
          } catch (err) {
            $logger.error(err);
          }
          const cartPrice = Object.values(newCartItems).reduce((a, {
            quantity,
            cartItem: { variant },
          }) => {
            return a + epsRound((variant?.piecePrice?.grossDiscounted || 0) * (quantity || 0));
          }, 0);
          this._push({
            type: T_GTM,
            data: () => {
              return {
                event: 'cart_update',
                cart_amount: cartPrice,
                products_quantity: newCartItemsValues.reduce((a, i) => a + (i.quantity || 0), 0),
                products: newCartItemsValues.map(({
                  checkoutType,
                  cartItem,
                }) => {
                  try {
                    return new AnalyticsCartItem({
                      checkoutType,
                      cartItem,
                    });
                  } catch (err) {
                    $logger.error(err);
                    return {};
                  }
                }),
              };
            },
          });
        }
      } catch (err) {
        $logger.error(err);
      }
    },
    [PUSH_REMOVE_CART_ITEM] ({
      uuid,
      newQuantity,
    }) {
      try {
        if ($awMergedConfig.googleTagManagerId && $awMergedConfig.isGtmTrackingEnabled) {
          let cartItem;
          if (orderStore.order.splits?.some?.((split) => {
            const foundItem = (split?.packages || []).flatMap(p => p.items).find(i => i.uuid === uuid);
            if (foundItem) {
              cartItem = foundItem;
            }
            return Boolean(foundItem);
          })) {
            const item = new AnalyticsProduct({
              product: cartItem,
              variantProperty: 'variant',
            });
            const newConvertedQuantity = unitConverter({
              from: {
                value: newQuantity?.value || 0,
                unit: newQuantity?.unit || 'piece',
              },
              to: 'piece',
              weightPerPiece: cartItem.variant?.loose?.weightPerPiece,
            }).value;
            const itemQuantity = itemToQuantity(cartItem);
            item.quantity = itemQuantity - newConvertedQuantity;
            return () => {
              this._push({
                type: T_GTM,
                data: () => ({
                  event: 'cleanup',
                  ecommerce: null,
                }),
              });
              this._push({
                type: T_GTM,
                data: () => ({
                  event: 'remove_from_cart',
                  number_of_products: 1,
                  ecommerce: {
                    items: [item],
                  },
                }),
              });
            };
          }
        }
      } catch (err) {
        $logger.error(err);
      }
      return () => {
      };
    },
    [PUSH_ITEM_TO_FAVOURITE] ({
      productData,
      variantProperty,
    }) {
      try {
        const item = new AnalyticsProduct({
          product: productData,
          variantProperty,
        });
        this._push({
          type: T_GTM,
          data: () => ({
            event: 'cleanup',
            ecommerce: null,
          }),
        });
        this._push({
          type: T_GTM,
          data: () => ({
            event: 'add_to_wishlist',
            number_of_products: 1,
            ecommerce: {
              items: [item],
            },
          }),
        });
      } catch (err) {
        $logger.error(err);
      }
    },
    [PUSH_BASKET_TO_FAVOURITE] ({ checkoutType }) {
      try {
        this._push({
          type: T_GTM,
          data: () => {
            const split = orderStore.order.splits?.find?.(s => s.checkoutType === checkoutType);
            const cartItems = getCartItemAndQuantityFromSplit({ split });
            return {
              event: 'save_cart',
              save_type: 'Kedvencek',
              basket_type: checkoutType?.toLowerCase?.(),
              cart_amount: split?.summary?.sum?.grossDiscounted,
              products_quantity: cartItems.reduce((a, i) => a + (i.quantity || 0), 0),
              products: cartItems.map(({ cartItem }) => {
                try {
                  return new AnalyticsCartItem({
                    checkoutType,
                    cartItem,
                  });
                } catch (err) {
                  $logger.error(err);
                  return {};
                }
              }),
            };
          },
        });
      } catch (err) {
        $logger.error(err);
      }
    },
    [PUSH_TRUNCATE_CART] ({ checkoutType }) {
      try {
        this._push({
          type: T_GTM,
          data: () => {
            const split = orderStore.order.splits?.find?.(s => s.checkoutType === checkoutType);
            const cartItems = getCartItemAndQuantityFromSplit({ split });
            return {
              event: 'clear_cart',
              basket_type: checkoutType?.toLowerCase?.(),
              cart_amount: split?.summary?.sum?.grossDiscounted,
              products_quantity: cartItems.reduce((a, i) => a + (i.quantity || 0), 0),
              products: cartItems.map(({ cartItem }) => {
                try {
                  return new AnalyticsCartItem({
                    checkoutType,
                    cartItem,
                  });
                } catch (err) {
                  $logger.error(err);
                  return {};
                }
              }),
            };
          },
        });
      } catch (err) {
        $logger.error(err);
      }
    },
    [PUSH_ADD_VOUCHER] ({ objectId }) {
      try {
        this._push({
          type: T_GTM,
          data: () => {
            const vouchers = orderStore.getCurrentFlattenedVouchers
              .filter(voucher => voucher.objectId === objectId)
            ;
            return {
              event: 'discount_added',
              discount_name: vouchers[0]?.name,
              discount: vouchers.reduce((a, b) => a + (b?.appliedAmount?.gross || 0), 0),
            };
          },
        });
      } catch (err) {
        $logger.error(err);
      }
    },
    [PUSH_ADD_TO_CART] ({
      productData,
      productList,
      quantityData,
    }) {
      try {
        const item = new AnalyticsProduct({
          product: productData,
          variantProperty: 'selectedVariant',
        });
        const quantity = unitConverter({
          from: {
            value: quantityData?.quantity,
            unit: quantityData?.type,
          },
          to: 'piece',
          weightPerPiece: productData?.selectedVariant?.loose?.weightPerPiece,
        })?.value;
        item.quantity = Math.round(quantity);
        item.item_list_name = productList;
        this._push({
          type: T_GTM,
          data: () => ({
            event: 'cleanup',
            ecommerce: null,
          }),
        });
        this._push({
          type: T_GTM,
          data: () => ({
            event: 'add_to_cart',
            number_of_products: 1,
            ecommerce: {
              items: [item],
            },
          }),
        });
      } catch (err) {
        $logger.error(err);
      }
    },
    [PUSH_REMOVE_FROM_CART] ({ name }) {
      this._push({
        type: T_GTM,
        data: () => ({
          event: 'eventTracker',
          eventCat: 'changeCart',
          eventAct: 'törlés',
          eventLbl: name,
        }),
      });
    },
    [PUSH_CLICK_CATEGORY] ({ parentsNameArr }) {
      this._push({
        type: T_GTM,
        data: () => ({
          event: 'eventTracker',
          eventCat: 'Kategória választás',
          eventAct: parentsNameArr?.[0]?.name,
          eventLbl: parentsNameArr?.slice(-1)?.[0]?.name,
        }),
      });
    },
    [PUSH_FILTER_PRODUCT] ({
      key,
      value,
    }) {
      try {
        const shortListName = promotionStore.getShortlistByUrl(router.currentRoute.value.path)?.title;
        const categoryName = categoryStore.getCategoryFromTree?.name;
        const loyaltyOffersName = router.currentRoute.value.path.includes('loyalty-offers') && $awt('aw.loyalty_offer.title');
        const searchListName = router.currentRoute.value.path.includes('search') && 'Search';
        this._push({
          type: T_GTM,
          data: () => ({
            event: 'item_list_filtering',
            item_list_name: categoryName || shortListName || loyaltyOffersName || searchListName,
            filter_name: key,
            filter_value: value,
          }),
        });
      } catch (err) {
        $logger.error(err);
      }
    },
    [PUSH_CHANGE_CHECKOUT_PAYMENT_DATA] () {
      try {
        const order = orderStore.order;
        const checkoutType = checkoutStore.checkoutType;
        const checkout = checkoutStore.checkout[checkoutType === 'FOOD' ? 'food' : 'nonfood'];
        const items = order.splits.flatMap((s) => {
          return s.packages.flatMap(p => p.items).map((item) => {
            const newItem = new AnalyticsProduct({
              product: item,
              variantProperty: 'variant',
            });
            newItem.quantity = itemToQuantity(item);
            return newItem;
          });
        });
        this._push({
          type: T_GTM,
          data: () => {
            return {
              event: 'cleanup',
              ecommerce: null,
            };
          },
        });
        this._push({
          type: T_GTM,
          data: () => {
            return {
              event: 'add_payment_info',
              number_of_products: items.length,
              ecommerce: {
                payment_type: checkout?.payment?.type === PAYMENT_METHODS.ONSITE ? 'on-delivery' : 'card',
                items,
              },
            };
          },
        });
      } catch (err) {
        $logger.error(err);
      }
    },
    [PUSH_CHANGE_CHECKOUT_BILLING_DATA] ({ billingMethod }) {
      this._push({
        type: T_GTM,
        data: () => ({
          event: 'billing_information_added',
          billing_type: {
            [BILLING_METHOD_COMPANY]: 'Céges számlázási adat',
            [BILLING_METHOD_PERSONAL]: 'Lakossági számlázási adat',
            [BILLING_METHOD_DELIVERY]: 'Lakossági számlázási adat',
          }[billingMethod],
        }),
      });
    },
    [PUSH_COMPARE_PRODUCT] ({
      productCompareAnalyticsType,
      productsArr,
    }) {
      if (productCompareAnalyticsType === PCAT_ACCEPT) {
        this._push({
          type: T_GTM,
          data: () => {
            return {
              event: 'product_comparing',
              item_id: productsArr?.[0]?.selectedVariant?.sku,
              item_id2: productsArr?.[1]?.selectedVariant?.sku,
              item_id3: productsArr?.[2]?.selectedVariant?.sku,
              item_id4: productsArr?.[3]?.selectedVariant?.sku,
            };
          },
        });
      }
    },
    [PUSH_SELECT_ITEM] ({
      productData,
      searchText,
      categoryName,
      index,
    }) {
      try {
        const item = new AnalyticsProduct({
          product: productData,
          variantProperty: 'selectedVariant',
        });
        const searchListName = router.currentRoute.value.path.includes('search') && 'Search';
        item.item_list_name = categoryName || searchListName || null;
        item.search_term = searchText?.join(',') || null;
        item.index = index;
        this._push({
          type: T_GTM,
          data: () => {
            return {
              event: 'cleanup',
              ecommerce: null,
            };
          },
        });
        this._push({
          type: T_GTM,
          data: () => ({
            event: 'select_item',
            number_of_products: 1,
            ecommerce: {
              items: [item],
            },
          }),
        });
      } catch (err) {
        $logger.error(err);
      }
    },
    [PUSH_CHANGE_CHECKOUT_DELIVERY_DATA] () {
      try {
        const order = orderStore.order;
        const checkoutType = checkoutStore.checkoutType;
        const checkout = checkoutStore.checkout[checkoutType === 'FOOD' ? 'food' : 'nonfood'];
        const isDelivery = checkout?.delivery?.deliveryMethod === DELIVERY_METHODS.DELIVERY_ADDRESS;
        const shippingTier = [
          {
            name: 'delivery',
            methods: [DELIVERY_METHODS.DELIVERY_ADDRESS],
          },
          {
            name: 'pickup',
            methods: [DELIVERY_METHODS.DELIVERY_CLICK_AND_COLLECT],
          },
          {
            name: 'currier',
            methods: [
              DELIVERY_METHODS.GLS_PICKUP,
              DELIVERY_METHODS.GLS_LOCKER,
              DELIVERY_METHODS.DELIVERY_FOXPOST_LOCKER,
            ],
          },
        ].find(tier => tier.methods.includes(checkout?.delivery?.deliveryMethod))?.name;
        const items = order.splits.flatMap((s) => {
          return s.packages.flatMap(p => p.items).map((item) => {
            const newItem = new AnalyticsProduct({
              product: item,
              variantProperty: 'variant',
            });
            newItem.quantity = itemToQuantity(item);
            return newItem;
          });
        });
        const formatReservation = (reservation) => {
          if (!reservation) {
            return;
          }
          const type = getTypeByTimeFrame(reservation);
          if (type === timeFrameTypes.FULL_DAY) {
            return reservation.from.split('T')[0];
          }
          return reservation.from;
        };
        this._push({
          type: T_GTM,
          data: () => {
            return {
              event: 'cleanup',
              ecommerce: null,
            };
          },
        });
        this._push({
          type: T_GTM,
          data: () => ({
            event: 'add_shipping_info',
            number_of_products: items.length,
            delivery_datetime: shippingTier === 'delivery' ? formatReservation(checkout?.reservation) : undefined,
            department: isDelivery ? null : checkout?.delivery?.departmentStore?.name,
            ecommerce: {
              shipping_tier: shippingTier,
              items,
            },
          }),
        });
      } catch (err) {
        $logger.error(err);
      }
    },
    [PUSH_BEGIN_CHECKOUT] ({ split }) {
      try {
        const items = split.packages.flatMap(p => p.items).map((item) => {
          const newItem = new AnalyticsProduct({
            product: item,
            variantProperty: 'variant',
          });
          newItem.quantity = itemToQuantity(item);
          return newItem;
        });
        this._push({
          type: T_GTM,
          data: () => ({
            event: 'cleanup',
            ecommerce: null,
          }),
        });
        this._push({
          type: T_GTM,
          data: () => ({
            event: 'begin_checkout',
            number_of_products: items.length,
            ecommerce: {
              items,
            },
          }),
        });
      } catch (err) {
        $logger.error(err);
      }
    },
    [PUSH_CLICK_MAP] ({
      clickType,
      mapAreas,
    }) {
      this._push({
        type: T_GTM,
        data: () => ({
          event: 'eventTracker',
          eventCat: 'Térkép',
          eventAct: {
            [CLICK_MAP_TYPE_DEPARTMENT_STORE]: 'Auchan áruházak, ahol átveheted rendelésed',
            [CLICK_MAP_TYPE_AW_SHOP_AREA]: 'Területek, ahová kiszállítjuk rendelésed',
          }[clickType],
          eventLbl: mapAreas.join(' - '),
        }),
      });
    },
    [PUSH_CHANGE_DELIVERY_METHOD] ({
      deliveryMethodId,
      checkoutType,
      changeTypes,
    }) {
      try {
        if ($awMergedConfig.googleTagManagerId && $awMergedConfig.isGtmTrackingEnabled) {
          const receiptType = {
            [DELIVERY_METHODS.DELIVERY_CLICK_AND_COLLECT]: 'Áruházban',
            [DELIVERY_METHODS.DELIVERY_ADDRESS]: 'Házhozszállítással',
            // NOTE Uncomment and add some values for these keys if
            // customer orders nonfood and specifies the values.
            /*
             * [DELIVERY_METHODS.GLS_PICKUP]: DELIVERY_METHODS.GLS_PICKUP,
             * [DELIVERY_METHODS.GLS_LOCKER]: DELIVERY_METHODS.GLS_LOCKER,
             * [DELIVERY_METHODS.DELIVERY_FOXPOST_LOCKER]: DELIVERY_METHODS.DELIVERY_FOXPOST_LOCKER,
            */
          }[deliveryMethodId];
          if (receiptType) {
            const checkout = checkoutStore.checkout[checkoutType.toLowerCase()];
            let destination;
            switch (deliveryMethodId) {
              case DELIVERY_METHODS.GLS_PICKUP:
              case DELIVERY_METHODS.GLS_LOCKER:
                destination = deliveryStore.glsDetailData?.zipcode || checkout?.delivery?.pickupPointData?.postCode;
                break;
              case DELIVERY_METHODS.DELIVERY_FOXPOST_LOCKER:
                destination = deliveryStore.foxpostDetailData?.zip || checkout?.delivery?.pickupPointData?.postCode;
                break;
              default: {
                destination = checkout?.nonfoodReservations?.postCode || checkout?.delivery?.departmentStore?.name || checkout?.delivery?.address?.address?.postCode || (deliveryStore?.setup?.type === 'department_store' ? deliveryStore?.setup?.name : deliveryStore?.setup?.postCode);
                break;
              }
            }
            let deliveryTime;
            try {
              const timeFrame = checkout?.reservation;
              if (timeFrame) {
                const [s0, s1] = [timeFrame.from, timeFrame.to].map(s => new Date(s));
                const [f, ...o] = $date(s0, { weekday: 'long' });
                deliveryTime = `${f.toUpperCase() + o.join('')} - ${[s0, s1].map(s => [s.getHours(), s.getMinutes()].map(d => d.toString().padStart(2, '0')).join(':')).join('-')}`;
              }
            } catch (err) {
              $logger.error(err);
            }

            if (changeTypes.includes(CDM_POSTCODE)) {
              this._push({
                type: T_GTM,
                data: () => ({
                  event: 'receipt_method_added',
                  receipt_type: receiptType,
                  location: destination,
                  time: deliveryTime,
                }),
              });
            }
            if (changeTypes.includes(CDM_RES) && deliveryTime) {
              this._push({
                type: T_GTM,
                data: () => ({
                  event: 'eventTracker',
                  eventCat: 'Idősáv foglalás',
                  eventAct: receiptType,
                  eventLbl: destination,
                  eventValue: deliveryTime,
                }),
              });
            }
          }
        }
      } catch (err) {
        $logger.error(err);
      }
    },
    [PUSH_PRE_ORDER] ({
      checkoutType,
      split,
      checkoutDelivery,
    }) {
      this._push({
        type: T_GTM,
        data: () => ({
          event: `${checkoutType.toLowerCase()}_transaction`,
          transaction_value: split?.summary?.sum?.grossDiscounted,
          transaction_area: split?.packages?.[0]?.discriminator?.companyNo,
          transaction_zipcode: checkoutDelivery?.address?.address?.postCode || checkoutDelivery?.departmentStore?.name,
        }),
      });
    },
    async [PUSH_POST_ORDER] ({
      user,
      split,
      orderIdent,
    }) {
      try {
        const {
          shipping: shippingPrice,
        } = getAnalyticsPricesFromSplit({ split });
        try {
          this[PUSH_TRACK_CART_STATE]({
            newCart: { splits: [split] },
            oldCart: orderStore.order,
          });
        } catch (err) {
          $logger.error(err);
        } finally {
          this[PUSH_TRACK_CART_STATE]({
            newCart: orderStore.order,
            oldCart: { splits: [split] },
          });
        }
        if ($awMergedConfig.googleTagManagerId && $awMergedConfig.isGtmTrackingEnabled) {
          const flattenItems = (split?.packages || []).flatMap(({
            shipsFrom,
            items,
          }) => {
            return items.map(item => ({
              item,
              shippingDepot: shipsFrom,
            }));
          });
          const transactionShipping = shippingPrice ? `${shippingPrice} ${split.summary.fees.delivery.sum.currency}` : '-';
          this._push(
            {
              type: T_GTM,
              data: () => ({
                transactionId: orderIdent,
                transactionAffiliation: $getHost(),
                transactionTotal: split?.summary?.sum?.grossDiscounted,
                transactionTax: '0',
                transactionShipping,
                transactionProducts: flattenItems.map(({ item }) => {
                  return {
                    sku: item.variant?.sku,
                    name: item.variant?.name,
                    category: '-', // item.variant?.,old code had some categoryForGTM and fallback for '-'
                    price: item.variant?.price?.gross,
                    quantity: item.quantity?.quantity,
                  };
                }),
              }),
            },
            {
              // https://developers.google.com/tag-manager/enhanced-ecommerce#purchases
              // Clear the previous ecommerce object.
              type: T_GTM,
              data: () => ({
                ecommerce: null,
              }),
            },
          );
          try {
            // TODO backenddel és mobillal egyeztetni, hogy kell-e még ez a végpont és ha nem, akkor ki lehet írtani backendről a végpontot
            // const analytics = await $api.$get(`/orders/${orderIdent}/analytics`);
            this._push({
              type: T_GTM,
              data: () => ({
                ecommerce: {
                  purchase: {
                    actionField: {
                      id: orderIdent,
                      affiliation: $getHost(),
                      revenue: split?.summary?.sum?.grossDiscounted + transactionShipping,
                      tax: '0',
                      shipping: transactionShipping,
                      coupon: '',
                    },
                    products: flattenItems.map(({ item }) => {
                      return {
                        name: item.variant?.name,
                        id: item.variant?.sku,
                        price: item.variant?.price?.gross,
                        brand: item.variant?.brandName || '',
                        category: item.categories?.slice?.(-1)?.[0]?.name || '',
                        variant: item.variant?.name,
                        quantity: item.quantity?.quantity,
                        coupon: '',
                      };
                    }),
                  },
                },
              }),
            });
          } catch (err) {
            $logger.error(err);
          }
          try {
            this._push({
              type: T_GTM,
              data: () => {
                const products = flattenItems.map(({
                  shippingDepot,
                  item,
                }) => {
                  try {
                    return new AnalyticsOrderCartItem({
                      checkoutType: split?.checkoutType,
                      cartItem: item,
                      shippingDepot,
                    });
                  } catch (err) {
                    $logger.error(err);
                    return {};
                  }
                });
                return {
                  event: 'order_confirmation',
                  order_id: orderIdent,
                  userID: user?.personalInfo?.cdEncodedExternalId,
                  ...getAnalyticsPricesFromSplit({ split }),
                  products_quantity: products.reduce((a, p) => a + (p?.quantity || 0), 0),
                  products,
                };
              },
            });
          } catch (err) {
            $logger.error(err);
          }
        }
      } catch (err) {
        $logger.error(err);
      }
    },
    [PUSH_PURCHASE] ({
      user,
      split,
      orderIdent,
      delivery,
    }) {
      try {
        const items = (split?.packages?.flatMap?.(({ items }) => items) || []).map((product) => {
          return new AnalyticsProduct({
            product,
            variantProperty: 'variant',
          });
        });
        const address = delivery?.address?.address || delivery?.departmentStore?.address;

        this._push({
          type: T_GTM,
          data: () => {
            return {
              event: 'cleanup',
              ecommerce: null,
            };
          },
        });
        this._push({
          type: T_GTM,
          data: () => {
            return {
              event: 'purchase',
              first_name: user.personalInfo.firstName,
              last_name: user.personalInfo.lastName,
              email: user.personalInfo.email,
              phone: delivery.address.contactPhone,
              country: 'HU',
              postal_code: address?.postCode,
              city: address.city,
              street: address.streetName,
              number_of_products: items.length,
              ecommerce: {
                currency: split.summary.sum.currency,
                value: split.summary.sum.grossDiscounted?.toFixed(2)?.toString(),
                tax: null,
                shipping: (split?.summary?.fees?.delivery?.sum?.grossDiscounted || 0)?.toFixed(2)?.toString(),
                affiliation: 'online.auchan.hu',
                transaction_id: orderIdent,
                coupon: split?.vouchers?.[0]?.objectId,
                items,
              },
            };
          },
        });
      } catch (err) {
        $logger.error(err);
      }
    },
    [PUSH_VIEW_PRODUCT_LIST] ({
      categoryName,
      searchText,
      products,
    }) {
      try {
        const items = products.map((product, index) => {
          const item = new AnalyticsProduct({
            product,
            variantProperty: 'selectedVariant',
          });
          const searchListName = router.currentRoute.value.path.includes('search') && 'Search';
          item.item_list_name = categoryName || searchListName || undefined;
          item.search_term = searchText?.length ? searchText.join(',') : null;
          item.index = index + 1;
          return item;
        });
        this._push({
          type: T_GTM,
          data: () => ({
            event: 'cleanup',
            ecommerce: null,
          }),
        });
        this._push({
          type: T_GTM,
          data: () => ({
            event: 'view_item_list',
            number_of_products: products.length,
            ecommerce: {
              items,
            },
          }),
        });
      } catch (err) {
        $logger.error(err);
      }
    },
    async [PUSH_DELETE_ORDER] ({
      user,
      orderIdent,
    }) {
      try {
        if ($awMergedConfig.googleTagManagerId && $awMergedConfig.isGtmTrackingEnabled) {
          const cart = await $api.$get(`/orders/${orderIdent}/cart`);
          const split = cart?.splits?.[0];
          const products = (split?.packages || []).flatMap(({
            shipsFrom,
            items,
          }) => {
            return items.map((item) => {
              try {
                return new AnalyticsOrderCartItem({
                  checkoutType: split?.checkoutType,
                  cartItem: item,
                  shippingDepot: shipsFrom,
                });
              } catch (err) {
                $logger.error(err);
                return {};
              }
            });
          });
          this._push({
            type: T_GTM,
            data: () => ({
              event: 'delete_order',
              order_id: orderIdent,
              userID: user?.personalInfo?.cdEncodedExternalId,
              ...getAnalyticsPricesFromSplit({ split }),
              products_quantity: products.reduce((a, p) => a + (p?.quantity || 0), 0),
              products,
            }),
          });
        }
      } catch (err) {
        $logger.error(err);
      }
    },
    [PUSH_VOUCHER] ({
      couponCode,
      discountsSum,
    }) {
      this._push({
        type: T_GTM,
        data: () => ({
          event: 'eventTracker',
          eventCat: 'Kupon érvényesítés',
          eventAct: couponCode,
          eventLbl: discountsSum,
        }),
      });
    },
    [PUSH_REVIEW_PRODUCT] ({
      productSku,
      numberOfStars,
    }) {
      this._push({
        type: T_GTM,
        data: () => ({
          event: 'product_rating',
          item_id: productSku,
          stars: numberOfStars,
        }),
      });
    },
    [PUSH_ZOOM_PRODUCT] () {
      this._push({
        type: T_GTM,
        data: () => ({
          event: 'product_zoom',
        }),
      });
    },
    [PUSH_CLICK_BANNER] ({ analyticsBannerInteraction }) {
      try {
        if (!AnalyticsBannerInteraction.isValid(analyticsBannerInteraction)) {
          $logger.error(new TypeError(`${analyticsBannerInteraction} is not a valid AnalyticsBannerInteraction`));
        }
        const item = AnalyticsBannerInteraction.format(analyticsBannerInteraction);
        this._push({
          type: T_GTM,
          data: () => ({
            event: 'cleanup',
            ecommerce: null,
          }),
        });
        this._push({
          type: T_GTM,
          data: () => ({
            event: 'select_promotion',
            number_of_products: 1,
            ecommerce: {
              items: [item],
            },
          }),
        });
      } catch (err) {
        $logger.error(err);
      }
    },
    [PUSH_VIEW_BANNER] ({ analyticsBannerInteraction }) {
      try {
        if (!AnalyticsBannerInteraction.isValid(analyticsBannerInteraction)) {
          $logger.error(new TypeError(`${analyticsBannerInteraction} is not a valid AnalyticsBannerInteraction`));
        }
        const item = AnalyticsBannerInteraction.format(analyticsBannerInteraction);
        analyticsQueueStore.addToQueue(item);
      } catch (err) {
        $logger.error(err);
      }
    },
    [FLUSH_VIEW_BANNER] ({ isHistoryFlushed } = {}) {
      try {
        const items = analyticsQueueStore.getQueue();
        analyticsQueueStore.clearQueue();
        if (isHistoryFlushed) {
          analyticsQueueStore.clearQueueHistory();
        }
        if (items.length) {
          this._push({
            type: T_GTM,
            data: () => ({
              event: 'cleanup',
              ecommerce: null,
            }),
          });
          this._push({
            type: T_GTM,
            data: () => ({
              event: 'view_promotion',
              number_of_products: items.length,
              ecommerce: {
                items,
              },
            }),
          });
        }
      } catch (err) {
        $logger.error(err);
      }
    },
    [PUSH_NEWSLETTER] () {
      this._push({
        type: T_GTM,
        data: () => ({
          event: 'eventTracker',
          eventCat: 'Hírlevél feliratkozás',
          eventAct: $getHref(),
          eventLbl: '',
        }),
      });
    },
    [PUSH_REGISTRATION_SUCCESS] () {
      this._push({
        type: T_GTM,
        data: () => ({
          event: 'eventTracker',
          eventCat: 'Regisztráció',
          eventAct: 'Sikeres',
          eventLbl: '',
        }),
      });
    },
    [PUSH_REGISTRATION_ERROR] ({ error }) {
      this._push({
        type: T_GTM,
        data: () => ({
          event: 'eventTracker',
          eventCat: 'Regisztráció',
          eventAct: 'Hiba',
          eventLbl: error,
        }),
      });
    },
    [PUSH_LOGOUT] ({ isB2B }) {
      this._push({
        type: T_GTM,
        data: () => ({
          event: 'logout',
        }),
      }, {
        // NOTE: legacy PUSH_LOGOUT event
        type: T_GTM,
        data: () => ({
          event: 'eventTracker',
          eventCat: 'Kijelentkezés',
          eventAct: new Map([
            [true, 'vállalkozás'],
            [false, 'magánszemély'],
          ]).get(isB2B),
          eventValue: $getHref(),
        }),
      });
    },
    async [PUSH_LOGIN_SUCCESS] () {
      try {
        const {
          value: sha512CdExternalId,
          error: hashingErr,
        } = await crossPlatformHashNotExceptionSafe({
          hashFunction: HASH_SHA_512,
          hashableStr: userStore.data?.personalInfo?.cdExternalId,
        });
        if (hashingErr) {
          $logger.error(hashingErr);
        }
        this._push({
          type: T_GTM,
          data: () => ({
            event: 'login',
            user_id: userStore.data?.personalInfo?.cdExternalId, // user id
            user_id_sha512: sha512CdExternalId, // user id sha512 hash
            environment: $awMergedConfig.isGtmRunningInProduction ? 'prod' : 'dev', // prod|dev
            direct_id: userStore.data?.id, // Direct id, integer. Ha nem elérhető, akkor ne küldjük.
            // direct_name: 'auchan_plock', // Direct name. Ha nem elérhető, akkor ne küldjük.
            user_logged: 'yes', // Konstans ennél az event
            user_age: userStore.data?.personalInfo?.age, // Látogató életkora, integer
            user_gender: ({
              1: 'male',
              2: 'female',
            })[userStore.data?.personalInfo?.gender], // Látogató neme
            user_category: 'client', // Konstans ennél az eventnél
            // service_name: 'hipermarket', // hipermarket|supermarket, azaz store type. Ha nem elérhető, akkor ne küldjük
            country: 'Hungary', // Konstans ennél az eventnél
          }),
        }, {
          // NOTE: legacy PUSH_LOGIN_SUCCESS event
          type: T_GTM,
          data: () => ({
            event: 'eventTracker',
            eventCat: 'Bejelentkezés',
            eventAct: 'Sikeres',
            eventLbl: '',
          }),
        });
      } catch (err) {
        $logger.error(err);
      }
    },
    [PUSH_LOGIN_ERROR] ({ error }) {
      this._push({
        type: T_GTM,
        data: () => ({
          event: 'eventTracker',
          eventCat: 'Bejelentkezés',
          eventAct: 'Hiba',
          eventLbl: error,
        }),
      });
    },
    [PUSH_RANGE_FILTER] ({
      value0,
      value1,
    }) {
      this._push({
        type: T_GTM,
        data: () => ({
          event: 'eventTracker',
          eventCat: 'Szűrés',
          eventAct: 'Ár',
          eventLbl: `${value0}-${value1}`,
        }),
      });
    },
    [PUSH_VIEW_CART] ({ splits }) {
      try {
        const items = splits.flatMap(s => s.packages.flatMap(p => p.items)).map((item) => {
          const currentItem = new AnalyticsProduct({
            product: item,
            variantProperty: 'variant',
          });
          currentItem.quantity = Math.round(itemToQuantity(item));
          return currentItem;
        });
        if (!items.length) {
          return;
        }
        this._push({
          type: T_GTM,
          data: () => ({
            event: 'cleanup',
            ecommerce: null,
          }),
        });
        this._push({
          type: T_GTM,
          data: () => ({
            event: 'view_cart',
            number_of_products: items.length,
            ecommerce: {
              items,
            },
          }),
        });
      } catch (err) {
        $logger.error(err);
      }
    },
  };

  if (import.meta.client) {
    awAnalytics[LISTEN_FOR_SEO_CHANGE]();
  }
  nuxtApp.provide('awAnalytics', awAnalytics);
});

class POJOProp {
  toJSON () {
    const o = {};
    for (const key in this) {
      if (typeof this[key] !== 'function') {
        o[key] = this[key];
      }
    }
    return o;
  }
}

class AnalyticsCartItem extends POJOProp {
  constructor ({
    checkoutType,
    cartItem,
  }) {
    super();
    this.sku = cartItem?.variant?.sku;
    this.name = cartItem?.variant?.name;
    this.type = checkoutType?.toLowerCase?.();
    // this.campaign = "Csokit vagy csalunk"; TODO Implement later when BE is ready
    this.label = cartItem?.variant?.flags?.map?.(f => f?.name);
    this.category = cartItem?.categories?.map?.(c => c?.name);
    this.price = cartItem?.variant?.piecePrice?.grossDiscounted;
    this.quantity = itemToQuantity(cartItem);
    this.package = `${cartItem?.quantity?.quantity} ${getAnaliticsUnit({ quantityType: cartItem?.quantity?.type })}`;
  }
}

class AnalyticsOrderCartItem extends AnalyticsCartItem {
  constructor ({
    checkoutType,
    cartItem,
    shippingDepot,
  }) {
    super({
      checkoutType,
      cartItem,
    });
    this.depot = shippingDepot;
  }
}

function getAnalyticsPricesFromSplit ({ split }) {
  return {
    grand_total: split?.summary?.sum?.grossDiscounted,
    subtotal: split?.summary?.itemsSum?.gross,
    // tax: 970, NOTE: We do not have tax information
    shipping: split?.summary?.fees?.delivery?.sum?.grossDiscounted - split?.summary?.discounts?.delivery?.grossDiscounted || 0,
    discount: split?.summary?.discounts?.sum?.gross,
  };
}

function getCartItemAndQuantityFromSplit ({ split }) {
  return (split?.packages || [])
    .flatMap(p => p.items)
    .map(item => ({
      cartItem: item,
      quantity: itemToQuantity(item),
    }))
  ;
}

export function deliveryMethodsEnumToDeliveryMethod (deliveryMethodEnumElement) {
  return {
    [deliveryMethodsEnum.HOME_DELIVERY]: DELIVERY_METHODS.DELIVERY_ADDRESS,
    [deliveryMethodsEnum.PICK_UP_FROM_STORE]: DELIVERY_METHODS.DELIVERY_CLICK_AND_COLLECT,
  }[deliveryMethodEnumElement];
}

export function genClickEventHandlers (eventCallback) {
  return {
    click () {
      eventCallback(...arguments);
    },
    auxclick () {
      eventCallback(...arguments);
    },
    contextmenu () {
      eventCallback(...arguments);
    },
  };
}
