import axios from 'axios';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useNuxtApp, useRoute, useRuntimeConfig } from 'nuxt/app';
import { useCategoryStore } from './category';
import { useConfigStore } from './config';
import { useProductStore } from './product';
import { useProductsStore } from './products';
import { useModalStore } from './modal';
import { useOrderStore } from './order';
import { useQueueStore } from './queue';
import { setErrorNotification } from '~~/common/utils/helper';
import { useGdprStore } from '~~/shop/stores/gdpr';
import { useLoyaltyCardStore } from '~~/shop/stores/loyaltyCard';
import { LT_ANON, LT_CAREER, LT_ECOM } from '~~/common/config/LoginTypeConfig.js';

export const useUserStore = defineStore('user', () => {
  const productsStore = useProductsStore();
  const productStore = useProductStore();
  const gdprStore = useGdprStore();
  const modalStore = useModalStore();
  const orderStore = useOrderStore();
  const loyaltyCardStore = useLoyaltyCardStore();
  const queueStore = useQueueStore();
  const configStore = useConfigStore();
  const categoryStore = useCategoryStore();
  const nuxtApp = useNuxtApp();
  const config = useRuntimeConfig();
  const route = useRoute();

  //data
  const data = ref({
    personalInfo: {},
    careerStatus: {},
    mailingInfos: [],
    mailingInfosCount: 0,
    deliveryInfos: [],
    deliveryInfosCount: 0,
    billingInfos: [],
    billingInfosCount: 0,
  });

  const isItemsPerPageSectionOpened = ref(false);
  const forceLogoutToRedirect = ref(false);
  const loginType = ref('');
  // showed at once the alert modal before logged in the user, we store info this variable ->
  const showAlcoholAlertModalBeforeLogIn = ref(false);
  const shoppingListNames = ref([]);
  const shoppingListsPagination = ref({
    itemsPerPage: 6,
    page: 1,
  });
  const shoppingListSessionData = ref([]);

  //actions
  function toggleIsItemsPerPageSectionOpened () {
    isItemsPerPageSectionOpened.value = !isItemsPerPageSectionOpened.value;
  }

  function resetShoppingListSessionData () {
    shoppingListSessionData.value = [];
  }

  function addShoppingListToSessionData (listId) {
    if (!shoppingListSessionData.value.includes(listId)) {
      shoppingListSessionData.value.push(listId);
    }
  }

  function removeShoppingListFromSessionData (listId) {
    shoppingListSessionData.value = shoppingListSessionData.value.filter(id => id !== listId);
  }

  function setUserData (newData) {
    data.value = { ...data.value, ...newData };
  }

  function resetUserData () {
    data.value = {
      personalInfo: {},
      careerStatus: {},
      mailingInfos: [],
      mailingInfosCount: 0,
      deliveryInfos: [],
      deliveryInfosCount: 0,
      billingInfos: [],
      billingInfosCount: 0,
    };
  }

  function setLoginType (newLoginType) {
    loginType.value = newLoginType;
  }

  function setForceLogoutToRedirect () {
    forceLogoutToRedirect.value = true;
  }

  function setShoppingLists (newData) {
    data.value.shoppingLists = newData;
  }

  function updateShoppingLists (newData) {
    data.value.shoppingLists.results = data.value.shoppingLists.results.concat(newData.results);
    data.value.shoppingLists.currentPage = newData.currentPage;
  }

  function setShoppingListsPagination (newData) {
    shoppingListsPagination.value = newData;
  }

  function setShowAlcoholAlertModalBeforeLogIn (status) {
    showAlcoholAlertModalBeforeLogIn.value = status;
  }

  async function refreshProductsData () {
    const { $logger } = nuxtApp;
    try {
      if (productStore.data.id) {
        productStore.fetchMainProduct({
          identifierType: 'id',
          id: productStore.data.id,
        });
      }
      await productsStore.throttledRefresh();
      productsStore.resetFilterBy();
    } catch (err) {
      $logger.error(err);
    }
  }

  async function fetchUser ({ refreshData } = { refreshData: true }) {
    if (import.meta.server) {
      return await fetchUserRequest({ refreshData });
    } else {
      return await queueStore.add({
        action: fetchUserRequest,
        actionName: 'user/fetchUserRequest',
        payload: { refreshData },
        canStartSecondAction: true,
        canRunSimultaneous: true,
      });
    }
  }

  async function fetchUserRequest ({ refreshData } = { refreshData: true }) {
    const {
      $api,
      $cookies,
      $logger,
    } = nuxtApp;
    const loginTypeFromCookie = $cookies.get('login_type');
    const loggedIn = loginTypeFromCookie !== LT_ANON;
    const isUserLoginTypeChanged = loginTypeFromCookie !== loginType.value;
    if (loginTypeFromCookie) {
      setLoginType(loginTypeFromCookie);
    }

    if (loggedIn) {
      try {
        const result = await $api.$get('/me');
        if (result != null) {
          setUserData(result);

          await Promise.all([orderStore.fetchOrder({
            id: 'current',
            canStartSecondAction: true,
            canRunSimultaneous: true,
            fetchFoodCheckout: config.public.isShop,
            fetchNonfoodCheckout: config.public.isShop,
            mode: /.*?\/(basket|checkout)/gi.test(route.fullPath) ? '' : 'MINICART',
          }), orderStore.fetchOrderModification()]);
        } else {
          resetUserData();
          loyaltyCardStore.resetLoyaltyData();
          setLoginType(LT_ANON);

          await orderStore.fetchOrder({
            id: 'current',
            canStartSecondAction: true,
            canRunSimultaneous: true,
            fetchFoodCheckout: config.public.isShop,
            fetchNonfoodCheckout: config.public.isShop,
            mode: /.*?\/(basket|checkout)/gi.test(route.fullPath) ? '' : 'MINICART',
          });
        }

        const proms = [];

        // refresh product, category, deliveryArea after fetchUser
        if (refreshData) {
          proms.push(refreshProductsData());
        }

        if (config.public.isShop && isEcomUser.value) {
          proms.push(gdprStore.fetchAcceptance().then(() => {
            if (gdprStore?.acceptance?.updateNeeded) {
              modalStore.openGdprAcceptanceModal();
            } else if (configStore.isSalesForceLogin && !data.value?.personalInfo?.birthDate && !data.value?.personalInfo?.isB2B) {
              modalStore.openProfileModal();
            }
          }));
        }
        await Promise.all(proms);
      } catch (error) {
        resetUserData();
        loyaltyCardStore.resetLoyaltyData();
        setLoginType(LT_ANON);

        // refresh product, category, deliveryArea after fetchUser
        if (productStore.data.id) {
          productStore.fetchMainProduct({
            identifierType: 'id',
            id: productStore.data.id,
          });
        }
        productsStore.resetFilterBy();
        await Promise.all([
          orderStore.fetchOrder({
            id: 'current',
            canStartSecondAction: true,
            canRunSimultaneous: true,
            fetchFoodCheckout: config.public.isShop,
            fetchNonfoodCheckout: config.public.isShop,
          }),
          productsStore.throttledRefresh(),
          categoryStore.fetchCategories()]);

        if (error?.response?.status !== 403) {
          $logger.error(error);
        } else if (error?.response?.status !== 401) {
          const queueStore = useQueueStore();

          queueStore.removeAllElementFromQueue(false);
        }
      }
    } else {
      resetUserData();
      loyaltyCardStore.resetLoyaltyData();
      await Promise.all([orderStore.fetchOrder({
        id: 'current',
        canStartSecondAction: true,
        canRunSimultaneous: true,
        fetchFoodCheckout: config.public.isShop,
        fetchNonfoodCheckout: config.public.isShop,
      }), isUserLoginTypeChanged ? productsStore.throttledRefresh() : undefined]);
      // refresh product, category, deliveryArea after fetchUser
      if (productStore.data.id && refreshData) {
        productStore.fetchMainProduct({
          identifierType: 'id',
          id: productStore.data.id,
        });
      }
      if (refreshData) {
        productsStore.resetFilterBy();
      }
    }
  }

  async function fetchShoppingListItems ({
    shoppingListId,
    page,
    itemsPerPage,
    abortSignal,
  }) {
    const {
      $api,
      $logger,
    } = nuxtApp;
    try {
      return await $api.$get(`/me/shopping_lists/${shoppingListId}/items?page=${page}&itemsPerPage=${itemsPerPage}`, {
        signal: abortSignal,
      });
    } catch (error) {
      if (!axios.isCancel(error)) {
        $logger.error(error);
        setErrorNotification({ nuxtApp, error });
      }
      return {};
    }
  }

  async function fetchShoppingLists ({
    itemsPerPage,
    page,
    mergeOldAndNewItems,
    updatePagination,
  } = {
    itemsPerPage: shoppingListsPagination.value.itemsPerPage,
    page: shoppingListsPagination.value.page,
    mergeOldAndNewItems: false,
    updatePagination: true,
  }) {
    const {
      $api,
      $logger,
    } = nuxtApp;
    try {
      const result = config.public.isShop ? await $api.$get(`/me/shopping_lists?page=${page}&itemsPerPage=${itemsPerPage}&responseWithItems=0`) : [];
      if (mergeOldAndNewItems) {
        updateShoppingLists(result);
      } else if (updatePagination) {
        setShoppingLists(result);
      } else {
        setShoppingLists({
          ...result,
          pageCount: Math.ceil(result.itemCount / shoppingListsPagination.value.itemsPerPage),
        });
      }
      if (updatePagination) {
        setShoppingListsPagination({
          itemsPerPage: shoppingListsPagination.value.itemsPerPage,
          page: result.currentPage,
        });
      }
    } catch (error) {
      if (error?.response?.status !== 403) {
        $logger.error(error);
      }
    }
  }

  async function fetchShoppingListNames () {
    const {
      $api,
      $logger,
    } = nuxtApp;
    try {
      shoppingListNames.value = await $api.$get('/me/shopping_list_names');
    } catch (error) {
      if (error?.response?.status !== 403) {
        $logger.error(error);
      }
    }
  }

  async function fetchPersonalInfo () {
    const {
      $api,
      $logger,
    } = nuxtApp;
    try {
      data.value.personalInfo = await $api.$get('/me/personal_info');
    } catch (error) {
      if (error?.response?.status !== 403) {
        $logger.error(error);
      }
    }
  }

  async function fetchChildrenInfo () {
    const {
      $api,
      $logger,
    } = nuxtApp;
    try {
      const result = await $api.$get('/me/children');
      data.value.children = result?.children;
    } catch (error) {
      if (error?.response?.status !== 403) {
        $logger.error(error);
      }
    }
  }

  async function fetchBillingInfos () {
    const {
      $api,
      $logger,
    } = nuxtApp;

    try {
      const result = await $api.$get('/me/billing_infos?page=1');
      data.value.billingInfos = result;
      data.value.billingInfosCount = result.length;
    } catch (error) {
      if (error?.response?.status !== 403) {
        $logger.error(error);
      }
    }
  }

  async function fetchDeliveryInfos () {
    const {
      $api,
      $logger,
    } = nuxtApp;
    try {
      const result = await $api.$get('/me/delivery_infos?page=1');
      data.value.deliveryInfos = result;
      data.value.deliveryInfosCount = result.length;
    } catch (error) {
      if (error?.response?.status !== 403) {
        $logger.error(error);
      }
    }
  }

  async function fetchMailingInfos () {
    const {
      $api,
      $logger,
    } = nuxtApp;
    try {
      const result = await $api.$get('/me/mailing_infos?page=1');
      data.value.mailingInfos = result;
      data.value.mailingInfosCount = result.length;
    } catch (error) {
      if (error?.response?.status !== 403) {
        $logger.error(error);
      }
    }
  }

  async function fetchCareerStatus () {
    const {
      $api,
      $logger,
    } = nuxtApp;

    try {
      data.value.careerStatus = await $api.$get('/career/applications_status');
    } catch (err) {
      $logger.error(err);
    }
  }

  //getters
  const isEcomUser = computed(() => loginType.value === LT_ECOM);
  const isCareerUser = computed(() => loginType.value === LT_CAREER);
  const isAnonUser = computed(() => loginType.value === LT_ANON);

  return {
    data,
    isItemsPerPageSectionOpened,
    forceLogoutToRedirect,
    loginType,
    showAlcoholAlertModalBeforeLogIn,
    shoppingListNames,
    shoppingListsPagination,
    shoppingListSessionData,
    toggleIsItemsPerPageSectionOpened,
    resetShoppingListSessionData,
    addShoppingListToSessionData,
    removeShoppingListFromSessionData,
    setUserData,
    resetUserData,
    setLoginType,
    setForceLogoutToRedirect,
    setShoppingLists,
    updateShoppingLists,
    setShoppingListsPagination,
    setShowAlcoholAlertModalBeforeLogIn,
    refreshProductsData,
    fetchUser,
    fetchUserRequest,
    fetchShoppingListItems,
    fetchShoppingLists,
    fetchShoppingListNames,
    fetchPersonalInfo,
    fetchChildrenInfo,
    fetchBillingInfos,
    fetchDeliveryInfos,
    fetchMailingInfos,
    fetchCareerStatus,
    isEcomUser,
    isCareerUser,
    isAnonUser,
  };
});
