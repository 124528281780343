<template>
  <div :class="[$style.mainContainer]">
    <label>
      <input
        v-bind="awAttrsNoStyleNoClassNoListeners"
        :id="radioId"
        type="radio"
        :name="radioName"
        :disabled="disabled"
        :checked="checked"
        :class="$style.radioInput"
        @change="$parent.$emit('update:modelValue', value)"
      >
    </label>
    <div :class="$style.labelContainer">
      <label
        v-if="$slots.label?.()?.[0]?.children?.length || label"
        :for="radioId"
        :class="[
          $style.labelContainerLabel,
          {
            [$style.disabled]: disabled,
            [$style.checked]: checked,
          },
        ]"
      >
        <slot v-if="$slots.label?.()?.[0]?.children?.length" name="label" />
        <template v-else>
          {{ label }}
        </template>
      </label>
    </div>
  </div>
</template>

<script>
  import { uuid4 } from '~~/common/utils';
  import awListenersMixin from '~~/common/mixins/awListenersMixin.js';

  export default {
    name: 'AwRadioToggle',
    mixins: [awListenersMixin],
    props: {
      disabled: {
        type: Boolean,
        default: false,
      },
      label: {
        type: String,
        default: '',
      },
      value: {
        type: [String, Number],
        default: '',
        required: true,
      },
    },
    data () {
      return {
        inputId: null,
      };
    },
    computed: {
      radioId () {
        return `radio-${this.$parent.groupId}-|-${this.inputId}`;
      },
      radioName () {
        return `radio-${this.$parent.groupId}`;
      },
      checked () {
        return this.value && this.$parent.modelValue === this.value;
      },
    },
    mounted () {
      this.inputId = uuid4();
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
.mainContainer {
  display: flex;
  align-items: center;

  .radioInput {
    position: absolute;
    width: 0;
    height: 0;
    border: 0;
    -webkit-border-radius: 0;
    -webkit-appearance: none;
    appearance: none;
  }

  .labelContainer {
    font-size: 10px;
    text-align: center;

    &Label {
      line-height: 20px;
      display: block;
      padding-right: 24px;
      padding-left: 24px;
      cursor: pointer;
      user-select: none;

      &.disabled {
        cursor: not-allowed;
        color: $color-silver-chalice;
      }

      &.checked {
        color: $color-white;
        border-radius: 24px;
        background-color: $color-dusty-gray;
      }
    }
  }
}
</style>
