import { defineStore } from 'pinia';
import { computed, ref, watch } from 'vue';
import { useNuxtApp, useRoute } from 'nuxt/app';
import { isOver18 as isOver18Util } from '~~/common/utils';

export const useUserInterfaceStore = defineStore('userInterface', () => {
  const routeHistoryPath = ref(null);
  const loading = ref([]);
  const backdropChildren = ref({});
  const mobileMenuLevel = ref(null);
  const openSidebarSide = ref(null);
  const mediaQueries = ref({
    query: null,
    'sm-min': true,
    'md-min': true,
    'lg-min': true,
    'xl-min': true,
    'xs-max': true,
    'sm-max': true,
    'md-max': true,
    'lg-max': true,
    'mobile-min': true,
    'tablet-min': true,
    'desktop-medium-min': true,
    'desktop-large-min': true,
    'mobile-small-max': true,
    'mobile-max': true,
    'tablet-max': true,
    'desktop-medium-max': true,
    initialized: false,
  });

  const timeZoneOffsetInHours = ref(null);
  const validators = ref(null);
  const background = ref({
    backgroundBehaviour: null,
    backgroundColor: null,
    backgroundMedia: null,
  });
  const isExtraBodyClassNeeded = ref(false);
  const profilePaging = ref({
    page: 1,
    itemsPerPage: 3,
  });
  const profilePagingTotalRows = ref(0);
  const showPaging = ref(true);

  function setRouteHistoryPathIfChanged ({ fullPath }) {
    const newRouteHistoryPath = fullPath.split('#')[0];
    const isPathSame = routeHistoryPath.value === newRouteHistoryPath;
    if (!isPathSame) {
      routeHistoryPath.value = newRouteHistoryPath;
    }
    return { isSame: isPathSame };
  }

  function startLoading (data) {
    const id = data?.id || null;
    if (id && !loading.value.includes(id)) {
      loading.value.push(id);
    }
  }

  function endLoading (data) {
    const id = data?.id || null;
    if (id) {
      const index = loading.value.indexOf(id);
      if (index > -1) {
        loading.value.splice(index, 1);
      }
    }
  }

  function setBackdropChildren ({
    uuid,
    value,
  }) {
    if (import.meta.server) {
      return;
    }
    if (value) {
      backdropChildren.value[uuid] = Boolean(value);
    } else {
      delete backdropChildren.value[uuid];
    }
  }

  function setMobileMenuLevel (payload) {
    mobileMenuLevel.value = payload;
  }

  function setOpenSidebarSide (payload) {
    openSidebarSide.value = payload;
  }

  function setMediaQueries (payload) {
    mediaQueries.value = payload;
  }

  function setValidators (payload) {
    const phoneValidator = payload.find(({ validatorName }) => validatorName === 'phone_number');
    phoneValidator.metaDataCombined = { ...phoneValidator.metaDataCombined, ...phoneValidator.metaData };
    phoneValidator.metaData = phoneMetaDataTransformer(phoneValidator.metaDataCombined);
    validators.value = payload;
  }

  function resetBackground () {
    background.value = {
      backgroundBehaviour: null,
      backgroundColor: null,
      backgroundMedia: null,
    };
  }

  function setBackground ({
    backgroundRepeat,
    backgroundColor,
    media,
  }) {
    background.value = {
      backgroundRepeat,
      backgroundColor: backgroundColor || {
        hex: '#F3F0ED',
        alpha: 1,
      },
      backgroundMedia: media,
    };
  }

  function setExtraBodyClassNeeded (payload) {
    isExtraBodyClassNeeded.value = payload;
  }

  function setProfilePaging ({
    page,
    itemsPerPage,
  }) {
    profilePaging.value = {
      page,
      itemsPerPage,
    };
  }

  function resetProfilePaging () {
    profilePaging.value = {
      page: 1,
      itemsPerPage: 3,
    };
  }

  function setProfilePagingTotalRows ({ total }) {
    profilePagingTotalRows.value = total;
  }

  function setShowPaging (val) {
    showPaging.value = val;
  }

  function getValidator (vName) {
    return validators.value.find(({ validatorName }) => validatorName === vName);
  }

  const isBasketPage = computed(() => {
    const route = useRoute();
    const localeCodes = useNuxtApp().$i18n.localeCodes.value.map(x => `${x}/`).join('|');
    const basketRegex = new RegExp(`^/(${localeCodes})*shop/basket($|\\?|#)`);
    return basketRegex.test(route.fullPath);
  });

  const isCheckoutPage = computed(() => {
    const route = useRoute();
    const localeCodes = useNuxtApp().$i18n.localeCodes.value.map(x => `${x}/`).join('|');
    const basketRegex = new RegExp(`^/(${localeCodes})*shop/checkout/`);
    return basketRegex.test(route.fullPath);
  });

  const isCheckoutSuccessPage = computed(() => {
    const route = useRoute();
    const localeCodes = useNuxtApp().$i18n.localeCodes.value.map(x => `${x}/`).join('|');
    const basketRegex = new RegExp(`^/(${localeCodes})*shop/checkout-success/`);
    return basketRegex.test(route.fullPath);
  });

  const isBodyLocked = computed(() => {
    return Boolean(Object.keys(backdropChildren.value).length);
  });
  const str2Phone = computed(() => (pstr, disableDefaultAc = false) => {
    const validator = getValidator('phone_number');
    const ccc = Object.keys(validator.metaData).find(countryCallingCode => pstr.startsWith(countryCallingCode)) || Object.keys(validator.metaData)[0];
    const ac = validator.metaData[ccc].areaCodes.find(areaCode => pstr.startsWith(ccc + areaCode)) || (disableDefaultAc ? '' : validator.metaData[ccc].areaCodes[0]) || '';
    const num = pstr.slice((ccc + ac).length);
    return [ccc, ac, num];
  });
  const isOver18 = computed(() => {
    return isOver18Util;
  });
  const isValidDate = computed(() => (value) => {
    const dateRegex = /^[12]\d{3}-\d{2}-\d{2}(?:T\d{2}:\d{2}:\d{2}(?:\.\d+)?)?$/;
    return dateRegex.test(value);
  });
  const phoneValidator = computed(() => (value) => {
    const v = getValidator('phone_number');
    const fullRegex = new RegExp(v.validationString);
    let partialRegex;
    {
      const regexString = v.metaData[value[0]]?.regex;
      if (regexString === undefined) {
        return false;
      }
      partialRegex = new RegExp(regexString);
    }
    const validAreaCodes = v.metaData[value[0]]?.areaCodes;
    return Boolean(validAreaCodes.includes(value[1]) && partialRegex.test(value[2]) && fullRegex.test(value.join('')));
  });
  const ucbAddressValidator = computed(() => (value) => {
    const v = getValidator('ucb_address');
    return (new RegExp(v.metaData.regex)).test(value);
  });
  const groupIdNumberValidator = computed(() => (value) => {
    const v = getValidator('group_id_number');
    return (new RegExp(v.metaData.regex)).test(value);
  });
  const taxNumberValidator = computed(() => (value, config) => {
    const v = getValidator('tax_number');
    return (new RegExp(v.validationString)).test(value) && isValidNip(value);

    function isValidNip (nip) {
      if (config.appInstance === 'PL') {
        if (typeof nip !== 'string') {
          return false;
        }
        nip = nip.replace(/[\\-]/gi, '');
        if ([
          '1111111111',
          '2222222222',
          '3333333333',
          '4444444444',
          '5555555555',
          '6666666666',
          '7777777777',
          '8888888888',
          '9999999999',
        ].includes(nip)) {
          return false;
        }
        const weight = [6, 5, 7, 2, 3, 4, 5, 6, 7];
        let sum = 0;
        const controlNumber = parseInt(nip.substring(9, 10));
        const weightCount = weight.length;
        for (let i = 0; i < weightCount; i++) {
          sum += (parseInt(nip.substr(i, 1)) * weight[i]);
        }
        return sum % 11 === controlNumber;
      }
      return true;
    }
  });
  // TODO: later get this validator from backend
  const postCodeValidator = computed(() => (value, config, disable = false) => {
    if (disable) {
      return true;
    }
    return /^([0-9]{4})$/.test(value);
  });
  const taxNumberInputLength = computed(() => {
    const {
      metaData: {
        lengths,
        separator,
      },
    } = getValidator('tax_number');
    return lengths.reduce((a, b) => a + b) + (lengths.length - 1) * separator.length;
  });
  const hypenateTax = computed(() => (value) => {
    const v = getValidator('tax_number');
    const {
      separator,
      lengths,
    } = v.metaData;
    const separatorless = value.replace(new RegExp(separator, 'g'), '');
    // https://stackoverflow.com/questions/47095238/sum-of-previous-elements-from-an-array-in-javascript
    const indexes = lengths.map((s => (a) => {
      s += a;
      return s;
    })(0));
    const s = indexes.map((a, n) => separatorless.slice(indexes[n - 1], a)).filter(e => e);
    return s.join('-');
  });
  const updateCartLoading = computed(() => {
    const cartLoadingIds = ['update-cart-item-', 'remove-cart-item-', 'remove-all-item-'];
    return loading.value.some(id => cartLoadingIds.some(a => id.startsWith(a)));
  });
  const basketOperationLoading = computed(() => {
    const cartLoadingIds = ['add-to-cart-', 'update-cart-item-', 'remove-cart-item-', 'remove-all-item-', 'fetch-order-', 'add-to-coupon-'];
    return loading.value.some(id => cartLoadingIds.some(a => id.startsWith(a)));
  });

  if (import.meta.client) {
    watch(isExtraBodyClassNeeded, (newValue) => {
      document.body.classList.toggle('version2', newValue);
    }, { immediate: true });
    watch(isBodyLocked, (newValue) => {
      document.body.classList.toggle('body-locked', newValue);
      newValue
        ? document.body.style.overflow = 'hidden'
        : document.body.style.removeProperty('overflow')
      ;
    }, { immediate: true });
  }

  function setTimeZoneOffsetInHours () {
    timeZoneOffsetInHours.value = getRealtimeTimeZoneOffsetInHours();
  }

  const timeZoneOffsetDiffInHours = computed(() => {
    return getRealtimeTimeZoneOffsetInHours() - timeZoneOffsetInHours.value;
  });

  return {
    timeZoneOffsetInHours,
    setTimeZoneOffsetInHours,
    timeZoneOffsetDiffInHours,
    routeHistoryPath,
    loading,
    backdropChildren,
    mediaQueries,
    openSidebarSide,
    validators,
    background,
    isExtraBodyClassNeeded,
    profilePaging,
    profilePagingTotalRows,
    showPaging,
    setRouteHistoryPathIfChanged,
    startLoading,
    endLoading,
    setBackdropChildren,
    setMobileMenuLevel,
    setOpenSidebarSide,
    setMediaQueries,
    setValidators,
    resetBackground,
    setBackground,
    setExtraBodyClassNeeded,
    setProfilePaging,
    resetProfilePaging,
    setProfilePagingTotalRows,
    setShowPaging,
    isCheckoutPage,
    isCheckoutSuccessPage,
    isBasketPage,
    isBodyLocked,
    getValidator,
    str2Phone,
    isOver18,
    isValidDate,
    phoneValidator,
    ucbAddressValidator,
    groupIdNumberValidator,
    taxNumberValidator,
    postCodeValidator,
    taxNumberInputLength,
    hypenateTax,
    updateCartLoading,
    basketOperationLoading,
  };
});

function phoneMetaDataTransformer ({
  prefix,
  areaCodes,
  regex,
  length,
}) {
  return {
    [prefix]: {
      areaCodes,
      regex,
      maxlength: length,
    },
  };
}
function getRealtimeTimeZoneOffsetInHours () {
  return new Date().getTimezoneOffset() / 60;
}
