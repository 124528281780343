import { HASH_SHA_256, HASH_SHA_512 } from '~~/common/config/CrossPlatformHashFunctions.js';
const numberFormatters = {};

/**
 * Courtesy of StackOverflow
 * https://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid
 *
 * @description The originally used function wouldn't work nicely in our
 * case as this webpack version would strip crypto for some reason:
 *
 * function uuid4() {
 *   return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
 *     (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
 *   );
 * }
 */
export function uuid4 () {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = Math.random() * 16 | 0;
    const v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

export function uuid4FormData (formData, postfix = uuid4()) {
  Object.values(formData).forEach(({ labelAttrs, widgetAttrs }) => {
    labelAttrs.for = labelAttrs.for + postfix;
    widgetAttrs.id = widgetAttrs.id + postfix;
  });
}

export function epsRound (x) {
  return Math.round((x + Number.EPSILON) * 100) / 100;
}

/* https://stackoverflow.com/questions/54246477/how-to-convert-camelcase-to-snake-case-in-javascript */
export function camelToSnakeCase (str) {
  return str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
}

export function webpFallback (baseSources) {
  const MIME_WEBP = 'image/webp';
  const getMimeType = url => url.endsWith('.webp') ? { mimeType: MIME_WEBP } : null;
  const mimeTypedSources = baseSources
    .filter(bs => bs?.url)
    .map(bs => ({
      ...bs,
      ...getMimeType(bs.url),
    }))
  ;
  return [
    ...(mimeTypedSources
      .filter(({ mimeType }) => {
        return mimeType !== MIME_WEBP;
      })
      .map(s => ({ ...s, url: `${s.url}.webp`, mimeType: MIME_WEBP }))
    ),
    ...mimeTypedSources,
  ];
}

export function isCategoryProductList (category) {
  return category?.level >= 2;
}

export function colorModelToHex (color) {
  const hex = color.hex;
  const alpha = color.alpha;
  return `${hex}${(Math.round(255 * alpha)).toString(16)}`;
}

export function getLocaleNum (locale, num) {
  if (!numberFormatters[locale]) {
    numberFormatters[locale] = new Intl.NumberFormat(locale);
  }
  return numberFormatters[locale].format(num);
}

export function isOver18 (dateDescription) {
  return isOver18AsObj({
    dateValue: dateDescription,
    currentDate: Date.now(),
  });
}
export function isOver18AsObj ({ dateValue, currentDate }) {
  const dateOfAdulthood = new Date(dateValue);
  dateOfAdulthood.setFullYear(dateOfAdulthood.getFullYear() + 18);
  return dateOfAdulthood <= new Date(currentDate);
}
export async function crossPlatformHashNotExceptionSafe ({
  hashFunction,
  hashableStr,
} = {}) {
  // Based on: https://stackoverflow.com/questions/55926281/how-do-i-hash-a-string-using-javascript-with-sha512-algorithm#answer-55926440
  try {
    let hashFunctionModule = '';
    let hashFunctionSubtle = '';
    switch (hashFunction) {
      case HASH_SHA_256: {
        hashFunctionModule = 'sha256';
        hashFunctionSubtle = 'SHA-256';
      }
        break;
      case HASH_SHA_512: {
        hashFunctionModule = 'sha512';
        hashFunctionSubtle = 'SHA-512';
      }
        break;
      default: {
        throw new TypeError(
          'Provided hashFunction has no implementation.',
        );
      }
    }
    // https://nodejs.org/docs/latest-v18.x/api/crypto.html#determining-if-crypto-support-is-unavailable
    // When using ESM, if there is a chance that the code may be run on a
    // build of Node.js where crypto support is not enabled, consider using
    // the import() function instead of the lexical import keyword.
    const cryptoLib = ((typeof global !== 'undefined')
      ? (await import('node:crypto'))
      : crypto
    );
    if (cryptoLib.createHash) {
      return {
        error: false,
        value: (cryptoLib.createHash(hashFunctionModule)
          .update(hashableStr)
          .digest('hex')
        ),
      };
    } else if (cryptoLib.subtle) {
      const buf = await cryptoLib.subtle.digest(
        hashFunctionSubtle,
        (new TextEncoder('utf-8')
          .encode(hashableStr)
        ),
      );
      return {
        error: false,
        value: (Array.prototype.map.call(
          new Uint8Array(buf),
          x => (('00' + x.toString(16)).slice(-2)),
        )
          .join('')
        ),
      };
    } else {
      throw new Error(
        'crypto methods are unavailable on this platform. This may be due to crypto\'s methods only available on https protocol or localhost domain or nodejs.',
      );
    }
  } catch (error) {
    return {
      error,
      value: '',
    };
  }
}

/* > When the time zone offset is absent, date-only forms are interpreted
 *   as a UTC time and date-time forms are interpreted as local time.
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date#date_time_string_format
 * Outputs the format YYYY-MM-DDThh:mm:ss which then can be parsed as local
 * time independently from the original timezone This is useful for timezone
 * independent dates like birth dates.
*/
export function removeTZInfoFromDateStr (dateStr) {
  return dateStr?.slice(0, 19);
}

export function base64Decode (encoded) {
  return import.meta.server ? Buffer.from(encoded, 'base64') : window.atob(encoded);
}
