<template>
  <lv-button
    :type="isInvalid ? 'button' : 'submit'"
    :[disabledAttribute]="isInvalid"
    :disabled-style="isInvalid"
    v-bind="$attrs"
  >
    <slot />
  </lv-button>
</template>

<script>
  import { LvButton } from '~~/common/components/loginet-vue-shop/index.mjs';

  export default {
    name: 'AwFormButton',
    components: {
      LvButton,
    },
    inheritAttrs: false,
    props: {
      modelValue: {
        type: Object,
        required: true,
      },
      isAriaUsed: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      disabledAttribute () {
        return this.isAriaUsed ? 'aria-disabled' : 'disabled';
      },
      isInvalid () {
        return Boolean(this.modelValue.$errors?.length);
      },
    },
  };
</script>
