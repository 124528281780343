<template>
  <div :class="[$style.awSpring, $style[`size${desktopSize}`]]" data-spring-wrapper>
    <div :class="$style.awSpringContainer" data-spring-container>
      <slot />
    </div>
  </div>
</template>

<script>
  // 100 means full width
  const validSizes = [100, 12, 10];

  export default {
    name: 'AwSpring',
    props: {
      desktopSize: {
        type: Number,
        required: false,
        default: 10,
        validator: value => validSizes.includes(value),
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
.awSpring {
  display: grid;
  gap: 8px;
  grid-template-areas: "content content";
  grid-template-columns: repeat(2, 1fr);
  max-width: calc(100% - 2 * 16px);
  margin: 0 auto;

  @include tablet(only) {
    grid-template-areas: "content content content content content content content content";
    grid-template-columns: repeat(8, 1fr);
    gap: 24px;
    max-width: calc(100% - 2 * 48px);
  }

  @include desktop-small(only) {
    grid-template-areas: "content content content content content content content content content content content content";
    grid-template-columns: repeat(12, 1fr);
    gap: 32px;
    max-width: calc(100% - 2 * 60px);
  }

  @include desktop-medium(only) {
    grid-template-columns: repeat(12, 1fr);
    gap: 40px;
    max-width: calc(100% - 2 * 96px);
  }

  @include desktop-large(only) {
    grid-template-columns: repeat(12, 108px);
    gap: 40px;
    max-width: 1736px;
  }

  &.size100 {
    max-width: 100%;
    @include desktop-medium(min) {
      grid-template-areas: "content content content content content content content content content content content content";
    }

    @include desktop-large(min) {
      grid-template-areas: "content content content content content content content content content content content content content content";
      grid-template-columns: 1fr repeat(12, 108px) 1fr;
    }
  }

  &.size12 {
    @include desktop-medium(min) {
      grid-template-areas: "content content content content content content content content content content content content";
    }
  }

  &.size10 {
    @include desktop-medium(min) {
      grid-template-areas: ". content content content content content content content content content content .";
    }
  }

  &Container {
    grid-area: content;
  }
}

</style>
