const BANNER_STRIP_LAYOUT = {
  /* AW_POSEIDON: 'awposeidon_layout', */
};

export default {
  /* [BANNER_STRIP_LAYOUT.AW_POSEIDON]: {
    component: defineAsyncComponent(() => import('../components/Strip/AwPoseidon'))
  }, */
};

export {
  BANNER_STRIP_LAYOUT,
};
