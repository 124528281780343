const seoIdExtractors = {
  product: resourceId => resourceId,
  category: resourceId => resourceId?.matchAll(/[0-9]+/g).next().value?.[0],
  boutique: resourceId => resourceId?.match(/[0-9]*\|([0-9]+)/)?.[1],
  cms_content: resourceId => resourceId,
};
const seoModules = {
  product: 'productSeoResource',
  category: 'categorySeoResource',
  boutique: 'categorySeoResource',
  cms_content: 'cms_content',
};

export function getSeoModuleFromUrlResource (urlResource, urlResourceStore, to) {
  let seoModule;
  let seoModuleId;
  if (urlResource) {
    const {
      resourceId,
      resourceType,
    } = urlResource;
    seoModule = seoModules[resourceType];
    seoModuleId = seoIdExtractors[resourceType]?.(resourceId);
  }
  if (seoModule === undefined) {
    if (urlResourceStore?.data?.resourceType && urlResourceStore?.data?.slug === to.fullPath) {
      seoModule = seoModules[urlResourceStore.data.resourceType];
      seoModuleId = urlResourceStore.data.seoResourceId;
    } else {
      seoModule = 'global';
      seoModuleId = -1;
    }
  }
  return {
    seoModule,
    id: seoModuleId,
  };
}
