<template>
  <div
    v-bind="awAttrsNoListeners"
    :class="[$style.wrapper, {[$style.wrapperDisabled]: modelValue?.widgetAttrs?.disabled}]"
  >
    <client-only>
      <aw-date-picker
        v-if="modelValue.version2"
        v-model="modelValue.model"
        :is-focused="isFocused"
        :is-invalid="isInvalid"
        :has-label="!!modelValue.label"
        :has-success="hasSuccess"
        :is-required="isRequired"
        :has-error="hasError"
        :row-unique-id="rowUniqueId"
        :widget-attrs="modelValue.widgetAttrs"
        :widget-icon-attrs="modelValue.widgetIconAttrs"
        :config="modelValue.datePickerConfig"
        v-bind="awListeners"
      />
      <aw-simple-date-picker
        v-else
        v-model="modelValue.model"
        :row-unique-id="rowUniqueId"
        :visible-value="visibleValue"
        input-size="lg"
        :has-success="hasSuccess"
        :has-error="hasError"
        :is-focused="isFocused"
        :disabled="Boolean(!modelValue.widgetAttrs?.disabled)"
        v-bind="awListeners"
      />
    </client-only>
  </div>
</template>

<script>
  import { defineAsyncComponent } from 'vue';
  import formMixin from '~~/common/mixins/formMixin';
  import awListenersMixin from '~~/common/mixins/awListenersMixin.js';

  export default {
    name: 'AwFormDatePicker',
    components: {
      AwDatePicker: defineAsyncComponent(() => import('~~/common/components/Common/DatePicker/AwDatePicker')),
      AwSimpleDatePicker: defineAsyncComponent(() => import('~~/common/components/Common/DatePicker/AwSimpleDatePicker')),
    },
    mixins: [
      formMixin,
      awListenersMixin,
    ],
    inheritAttrs: false,
    props: {
      isFocused: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      visibleValue () {
        const model = this.modelValue.model;
        if (model) {
          if (!isNaN(Date.parse(model))) {
            return this.$date(model, {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            }, {
              removeSpaces: true,
            });
          } else {
            return model;
          }
        } else {
          return '';
        }
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">

.wrapper {
  position: relative;
  cursor: pointer !important;

  &Disabled {
    cursor: not-allowed !important;
  }

  .calendarIcon {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
  }
}
</style>
