// since JS can call this, everything is defined as nullable so ?. is mandatory
type Arg0 = {
  $i18n?: {
    defaultLocale?: string,
    locale?: {
      value?: string,
    },
  },
} | null | undefined;

export function getLocaleCodeForUrl (arg0: Arg0) {
  const locale = arg0?.$i18n?.defaultLocale === arg0?.$i18n?.locale?.value ? '' : arg0?.$i18n?.locale?.value;
  return locale || '';
}
