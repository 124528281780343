import { computed } from 'vue';
import { useNuxtApp } from 'nuxt/app';

export function useNameOrderConfig () {
  const { $i18n, $awt } = useNuxtApp();
  const isRev = computed(() => {
    return $i18n.locale.value === 'hu';
  });
  return {
    isRev,
    // TOKEN
    firstDisplayName: computed(() => {
      return isRev.value ? $awt('aw.bandd.last_name') : $awt('aw.bandd.first_name');
    }),
    lastDisplayName: computed(() => {
      return isRev.value ? $awt('aw.bandd.first_name') : $awt('aw.bandd.last_name');
    }),
  };
}
