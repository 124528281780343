<template>
  <aw-cms-page :content-data="previewData" :class="$style.page" />
</template>

<script setup>
  import { defineAsyncComponent } from 'vue';
  import { useNuxtApp, useHead, useRoute, useAsyncData, createError } from 'nuxt/app';
  import { updateSeoHeadReactive } from '~~/common/mixins/seoMixin';
  import { useContentsStore } from '~~/common/stores/contents';
  import { useUserInterfaceStore } from '~~/common/stores/userInterface';
  import { useSeoStore } from '~~/common/stores/seo';
  const AwCmsPage = defineAsyncComponent(() => import('~~/common/components/Page/Content/AwCmsPage.vue'));

  defineOptions({
    name: 'AwContentPreviewPageHash',
  });
  // eslint-disable-next-line no-undef
  definePageMeta({
    layout: 'full-width',
  });
  useHead({
    meta: [
      {
        name: 'robots',
        content: 'noindex,nofollow',
      },
    ],
  });
  updateSeoHeadReactive();
  const { $logger } = useNuxtApp();
  const contentsStore = useContentsStore();
  const previewData = computed(() => contentsStore.previewData);
  const { error: previewDataError } = await useAsyncData(
    async () => {
      try {
        const hash = useRoute().params.hash;
        await contentsStore.fetchContentPreview({ hash });
        const { fetchSeoData } = useSeoStore();
        await fetchSeoData({
          seoModule: 'cms_content',
          id: contentsStore.previewData.id,
        });
      } catch (err) {
        if (err?.response?.status === 404) {
          throw createError({
            statusCode: err.response.status,
            statusMessage: err.response.statusText,
            fatal: true,
            cause: err,
          });
        } else {
          $logger.error(err);
        }
      }
      return true;
    },
  );
  watchEffect(() => {
    if (previewDataError.value?.statusCode === 404) {
      throw previewDataError.value;
    }
  });
  onMounted(() => {
    const { setBackground } = useUserInterfaceStore();
    setBackground({ ...previewData.value });
  });
</script>

<style module lang="scss" rel="stylesheet/scss">
.page {
  padding-bottom: 20px;

  @include tablet(min) {
    padding-bottom: 40px;
  }
}
</style>
