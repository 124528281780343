import { defineStore } from 'pinia';
import { useNuxtApp } from 'nuxt/app';
import { ref, computed } from 'vue';
import { useUserInterfaceStore } from './userInterface';
import { uuid4 } from '~~/common/utils';

const LOADING_IDS_BY_ACTION_NAME = {
  'order/addToCartRequest': 'add-to-cart-{data.objectId}-{data.stockType}',
  'order/fetchOrderRequest': 'fetch-order-{id}',
  'order/addCouponRequest': 'add-to-coupon-{objectId}',
  'order/updateCartItemRequest': 'update-cart-item-{uuid}',
  'order/removeCartItemRequest': 'remove-cart-item-{uuid}',
  'order/removeAllItemRequest': 'remove-all-item-{checkoutType}',
  'delivery/pushMethodRequest': 'push-method-{areaId}-{type}',
};

export const useQueueStore = defineStore('queue', () => {
  const nuxtApp = useNuxtApp();

  const queue = ref([]);
  const errorQueue = ref([]);
  const workingQueueElements = ref([]);
  const workingErrorQueueElements = ref([]);
  const isErrorWorking = ref(false);
  const isBasketDependentWorking = ref(false);

  const hasActionInQueue = computed(() => (actionName) => {
    return queue.value.some(queueElement => queueElement.actionName === actionName);
  });
  const hasActionsInQueue = computed(() => (actionNameArr) => {
    return queue.value.some(queueElement => actionNameArr.includes(queueElement.actionName));
  });
  const hasFetchCheckoutInQueue = computed(() => (actionName, type) => {
    return queue.value.some(queueElement => queueElement.actionName === actionName && queueElement?.payload?.type === type);
  });

  function addActionToQueue (data) {
    queue.value.push(data);
  }
  function addActionToErrorQueue (data) {
    errorQueue.value.push(data);
  }
  function removeActionFromQueue (paramUuid) {
    const index = queue.value.findIndex(element => element.uuid === paramUuid);
    if (index > -1) {
      queue.value.splice(index, 1);
    }
  }
  function removeFirstActionFromErrorQueue () {
    errorQueue.value.shift();
  }

  function addQueueElementToWorkingState (queueElement) {
    workingQueueElements.value.push(queueElement.uuid);
  }
  function removeQueueElementToWorkingState (queueElementUuid) {
    const index = workingQueueElements.value.findIndex(uuid => uuid === queueElementUuid);
    if (index > -1) {
      workingQueueElements.value.splice(index, 1);
    }
  }

  function removeAllElementFromQueue (forceAllRemove) {
    if (forceAllRemove) {
      queue.value = [];
    } else {
      // remove loading state first
      queue.value.forEach((element) => {
        let loadingId = LOADING_IDS_BY_ACTION_NAME[element.actionName];
        if (loadingId) {
          loadingId = loadingId.replaceAll('{data.objectId}', element?.payload?.data?.objectId)
            .replaceAll('{data.stockType}', element?.payload?.data?.stockType)
            .replaceAll('{id}', element?.payload?.id)
            .replaceAll('{objectId}', element?.payload?.objectId)
            .replaceAll('{uuid}', element?.payload?.uuid)
            .replaceAll('{checkoutType}', element?.payload?.checkoutType)
            .replaceAll('{areaId}', element?.payload?.areaId)
            .replaceAll('{type}', element?.payload?.type);
          useUserInterfaceStore().endLoading({ id: loadingId });
        }
      });

      queue.value = queue.value.filter(element => workingQueueElements.value.includes(element.uuid));
    }
  }

  function add ({ action, actionName, payload, canRunSimultaneous, canStartSecondAction }) {
    const uuid = uuid4();
    return new Promise((resolve, reject) => {
      const data = { action, actionName, payload, canStartSecondAction, canRunSimultaneous, uuid, resolve, reject };

      if (payload?.resolveOption) {
        addActionToErrorQueue(data);
      } else {
        addActionToQueue(data);
      }
    });
  }

  function startWorker () {
    const { $logger } = nuxtApp;
    if (queue.value.length) {
      if (workingQueueElements.value.length) {
        const canRunSimultaneousElements = queue.value.find(element => element.canRunSimultaneous && !workingQueueElements.value.includes(element.uuid));

        if (canRunSimultaneousElements) {
          const cannotStartSecondAction = queue.value.some(element => !element.canStartSecondAction && workingQueueElements.value.includes(element.uuid));
          if (!cannotStartSecondAction) {
            runAction(canRunSimultaneousElements);
          }
        }
      } else {
        const element = queue.value[0];
        runAction(element);
      }
    } else if (workingQueueElements.value.length) {
      $logger.error('Houston, There is a problem, there aren\'t any acton in the queue, but there are some element in the workingQueueElements array. UUIDs: ', workingQueueElements.value);
    }
  }

  function startErrorWorker () {
    if (!isErrorWorking.value && errorQueue.value.length) {
      isErrorWorking.value = true;

      const { action, payload, resolve, reject } = errorQueue.value[0];

      action(payload)
        .then((r) => {
          removeFirstActionFromErrorQueue();
          isErrorWorking.value = false;

          resolve(r);
        })
        .catch((e) => {
          removeFirstActionFromErrorQueue();
          isErrorWorking.value = false;

          reject(e);
        });
    }
  }

  function runAction (element) {
    const { action, payload, resolve, reject, uuid } = element;
    addQueueElementToWorkingState(element);

    action(payload)
      .then((r) => {
        removeQueueElementToWorkingState(uuid);
        removeActionFromQueue(uuid);
        resolve(r);
      })
      .catch((e) => {
        removeQueueElementToWorkingState(uuid);
        removeActionFromQueue(uuid);
        reject(e);
      });
  }

  return {
    queue,
    errorQueue,
    workingQueueElements,
    workingErrorQueueElements,
    isErrorWorking,
    isBasketDependentWorking,
    hasActionInQueue,
    hasActionsInQueue,
    hasFetchCheckoutInQueue,
    addActionToQueue,
    addActionToErrorQueue,
    removeActionFromQueue,
    removeFirstActionFromErrorQueue,
    addQueueElementToWorkingState,
    removeQueueElementToWorkingState,
    removeAllElementFromQueue,
    add,
    startWorker,
    startErrorWorker,
    runAction,
  };
});
