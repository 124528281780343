export function getBackgroundImages (images) {
  return filterImagesBasedOnExisting(images);
}

export function removeImageSourceTagOnError (target) {
  const {
    currentSrc,
    parentElement,
  } = target;
  [...parentElement.children].find(c => c.srcset === currentSrc)?.remove?.();
}

export function onImageComplete (imgElement, {
  onImageLoad,
  onImageError,
} = {}) {
  if (imgElement?.complete) {
    if (imgElement.naturalHeight || imgElement.naturalWidth) {
      onImageLoad?.({ target: imgElement });
    } else {
      onImageError?.({ target: imgElement });
    }
  }
}


function filterImagesBasedOnExisting (images) {
  const imagesArr = (Array.isArray(images) ? images : []);
  const existImages = (imagesArr
    .filter((image) => image?.url)
  );

  if (existImages.length === 1) {
    existImages[0].media = null;
  }

  return existImages;
}