import { useSeoStore } from '~~/common/stores/seo';
import { useHead } from 'nuxt/app';
import { onMounted, nextTick, watch } from 'vue';
import { mapState } from 'pinia';

export default {
  computed: {
    ...mapState(useSeoStore, {
      fullTitle: state => state.fullTitle,
    }),
  },
  mounted () {
    nextTick(() => {
      updateSeoHead();
    });
  },
  watch: {
    fullTitle () {
      updateSeoHead();
    },
  },
};

export function seoMixin () {
  onMounted(() => {
    nextTick(() => {
      updateSeoHead();
    });
  });

  const seoStore = useSeoStore();

  watch(() => seoStore.fullTitle, () => {
    updateSeoHead();
  });
}

function updateSeoHead () {
  useHead(getUseHeadComputed().value);
}

export function updateSeoHeadReactive () {
  useHead(getUseHeadComputed());
}

function getUseHeadComputed () {
  return computed(() => {
    const seoStore = useSeoStore();
    const seo = seoStore.data;

    const {
      description,
      keywords,
      ogTitle,
      ogDescription,
      ogType,
      ogUrl,
      ogImage,
      noIndex,
    } = {
      description: seo?.description,
      keywords: seo?.keywords,
      ogTitle: seo?.ogTitle,
      ogDescription: seo?.ogDescription,
      ogType: seo?.ogType,
      ogUrl: seo?.ogUrl,
      ogImage: {
        url: seo?.ogImage,
        mimeType: 'image/png',
      },
      noIndex: seo?.noIndex,
    };
    return {
      title: seoStore?.fullTitle ?? 'Auchan',
      meta: [
        description
          ? {
            hid: 'description',
            name: 'description',
            content: description,
          }
          : {},
        keywords
          ? {
            hid: 'keywords',
            name: 'keywords',
            content: keywords,
          }
          : {},
        ogTitle
          ? {
            hid: 'ogTitle',
            name: 'og:title',
            content: ogTitle,
          }
          : {},
        ogDescription
          ? {
            hid: 'ogDescription',
            name: 'og:description',
            content: ogDescription,
          }
          : {},
        ogType
          ? {
            hid: 'ogType',
            name: 'og:type',
            content: ogType,
          }
          : {},
        ogUrl
          ? {
            hid: 'ogUrl',
            name: 'og:url',
            content: ogUrl,
          }
          : {},
        ogImage
          ? {
            hid: 'ogImageUrl',
            name: 'og:image',
            content: ogImage.url,
          }
          : {},
        ogImage
          ? {
            hid: 'ogImageMimeType',
            name: 'og:image:type',
            content: ogImage.mimeType,
          }
          : {},
        noIndex
          ? {
            hid: 'noIndex',
            name: 'robots',
            content: 'noindex,nofollow',
          }
          : {},
      ],
    };
  });
}
