import { defineNuxtRouteMiddleware, useNuxtApp } from 'nuxt/app';
import { useCategoryStore } from '~~/common/stores/category.js';
import { ecomShopPages } from '~~/common/config/EcomPagesConfig.js';
import { extractResourceFromPath } from '~~/common/utils/urlResource.js';

export default defineNuxtRouteMiddleware(async (to) => {
  const resource = extractResourceFromPath(to.fullPath);

  if (!(ecomShopPages.includes(to.name) || resource)) {
    return;
  }
  const { $pinia } = useNuxtApp();

  const categoryStore = useCategoryStore($pinia);
  await categoryStore.fetchCategories();
});
