<template>
  <span
    v-if="dealerUserStore.store"
    :title="dealerUserStore.store.name"
    v-text="dealerUserStore.store.id"
  />
  <span v-else v-text="'-'" />
</template>

<script setup>
  import { computed, watch, onMounted } from 'vue';
  import { useUserStore } from '~~/common/stores/user';
  import { useAuchanStoresStore } from '~~/dealer/stores/auchanStore';

  const { fetchDealerUserStore } = useAuchanStoresStore();

  const dealerUserStore = computed(() => {
    return useAuchanStoresStore().dealerUserStore || {};
  });
  const user = computed(() => {
    return useUserStore().data;
  });

  onMounted(fetchDealerUserStore);

  watch(user, fetchDealerUserStore);
</script>
