<template>
  <div
    :class="[
      $style.label,
      $style[position]
    ]"
  >
    <lv-icon
      :class="$style.labelIcon"
      name="basket-40"
      :size="16"
      inherit-color
      aria-hidden="true"
    />
    {{ $awt('aw.common.add_to_basket.in_your_cart') }}
  </div>
</template>

<script>

  import {
    LvIcon,
  } from '~~/common/components/loginet-vue-shop/index.mjs';

  export default {
    name: 'AwInYourCartLabel',
    components: {
      LvIcon,
    },
    props: {
      position: {
        type: String,
        default: 'center',
        validator: p => ['left', 'center', 'right'].includes(p),
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
$border-radius: 16px;

.label {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: $color-green-haze;
  background-color: $color-rice-flower;

  &.center {
    border-radius: $border-radius;
  }

  &.left {
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }

  &.right {
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
  }

  @include md(max) {
    display: none;
  }
}

.labelIcon {
  margin-right: 5px;
}
</style>
