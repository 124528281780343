import { defineStore } from 'pinia';
import { useNuxtApp } from 'nuxt/app';
import { ref } from 'vue';
import { AW_AX_CACHE_SEGMENTATION } from '~~/common/plugins/axios/awAxCache.js';
import { CCP_UNDER_FIRST_ROW, CCP_UNDER_SECOND_ROW, CCP_UNDER_THIRD_ROW } from '~~/common/config/ContentPageTypePosition.js';

export const useContentsStore = defineStore('contents', () => {
  const nuxtApp = useNuxtApp();

  const data = ref({});
  const staticContent = ref({});
  const staticContentUsers = ref({});
  const previewData = ref({});
  const dataByResourceId = ref([]);
  const needPetrolContent = ref(false);
  const modalContent = ref({});

  function clearDataByResourceId () {
    dataByResourceId.value = [];
  }

  async function fetchContent ({ pageId }) {
    const { $api, $logger } = nuxtApp;
    try {
      data.value = await $api.$get(`/cms/content/${pageId}`, { awAxCache: AW_AX_CACHE_SEGMENTATION });
    } catch (error) {
      if (error?.response?.status !== 404) {
        $logger.error(error);
      }
    }
  }

  async function fetchModalContent ({ pageId, awCmsContentHref }) {
    const { $api, $logger } = nuxtApp;
    try {
      const data = await $api.$get(`/cms/content/${pageId}`, { awAxCache: AW_AX_CACHE_SEGMENTATION });
      if (data && awCmsContentHref) {
        data.awCmsContentHref = awCmsContentHref;
      }
      modalContent.value = data;
    } catch (error) {
      if (error?.response?.status !== 404) {
        $logger.error(error);
      }
    }
  }

  function increaseStaticContentUsers ({ type }) {
    if (typeof staticContentUsers.value[type] === 'number') {
      staticContentUsers.value[type]++;
    } else {
      staticContentUsers.value[type] = 1;
    }
  }
  function decreaseStaticContentUsers ({ type }) {
    if (typeof staticContentUsers.value[type] === 'number' && staticContentUsers.value[type] > 0) {
      staticContentUsers.value[type]--;
    } else {
      staticContentUsers.value[type] = 0;
    }
    if (typeof staticContentUsers.value[type] !== 'number' || staticContentUsers.value[type] < 1) {
      delete staticContent.value[type];
    }
  }

  async function fetchStaticContent ({ type }) {
    const { $api, $logger } = nuxtApp;

    try {
      data.value = await $api.$get(`/static_content_pages/${type}`, { awAxCache: AW_AX_CACHE_SEGMENTATION });

      if (type === 'homepage' || type === 'dh_homepage') {
        staticContent.value[type] = data.value;
      }
    } catch (error) {
      if (error?.response?.status !== 404) {
        $logger.error(error);
      }
    }
  }

  async function fetchStaticContentByResourceId ({ type, resourceId }) {
    const { $api, $logger } = nuxtApp;
    try {
      const resp = await $api.$get(`/connected_content_pages/${type}/${resourceId}`, { awAxCache: AW_AX_CACHE_SEGMENTATION });
      const contentPosition = resp.position;
      if (
        resp &&
          Array.isArray(resp.layoutBlocks)
      ) {
        resp.layoutBlocks.forEach((lb) => {
          const component = lb?.components?.[0];
          if (typeof component === 'object' && lb.components.length === 1) {
            /* TODO: it would be a nice feature if backend could implement this */
            if (component.type === 'standard' || component.layoutTemplate === 'large_banner') {
              lb.awFeOverrideSpacing = [
                {
                  at: 'each',
                  css: {
                    'border-radius': '24px',
                  },
                },
              ];
            } else if (['last_viewed_products', 'recommendation_carousel'].includes(component.type) && [CCP_UNDER_FIRST_ROW, CCP_UNDER_SECOND_ROW, CCP_UNDER_THIRD_ROW].includes(contentPosition)) {
              lb.awFeOverrideSpacing = [
                {
                  at: 'each',
                  css: {
                    'border-radius': '24px',
                  },
                },
                {
                  at: 'desktop-small-min',
                  css: {
                    paddingLeft: '24px',
                    paddingRight: '24px',
                  },
                },
                {
                  at: 'tablet-max',
                  css: {
                    paddingLeft: '8px',
                    paddingRight: '8px',
                  },
                },
              ];
            }
          }
        });
      }
      dataByResourceId.value = resp;
    } catch (error) {
      dataByResourceId.value = [];
      if (error?.response?.status !== 404) {
        $logger.error(error);
      }
    }
  }

  async function fetchContentPreview ({ hash }) {
    const { $api } = nuxtApp;
    previewData.value = await $api.$get(`/cms/content_preview/${hash}`);
  }

  function setNeedPetrolContent (val) {
    needPetrolContent.value = val;
  }

  return {
    data,
    staticContent,
    staticContentUsers,
    increaseStaticContentUsers,
    decreaseStaticContentUsers,
    previewData,
    dataByResourceId,
    clearDataByResourceId,
    fetchContent,
    fetchStaticContent,
    fetchStaticContentByResourceId,
    fetchContentPreview,
    needPetrolContent,
    setNeedPetrolContent,
    modalContent,
    fetchModalContent,
  };
});
