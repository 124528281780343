<template>
  <aw-cms-page v-if="contentData" :content-data="contentData" :class="$style.page" />
</template>

<script setup>
  import { computed, onMounted, onBeforeUnmount, defineAsyncComponent } from 'vue';
  import { useAsyncData } from 'nuxt/app';
  import { fetchKey } from '~~/common/utils/awRunLegacyAsyncData.js';
  import { seoMixin } from '~~/common/mixins/seoMixin';

  import { useContentsStore } from '~~/common/stores/contents';
  import { useUserInterfaceStore } from '~~/common/stores/userInterface';
  import { useSeoStore } from '~~/common/stores/seo';

  const AwCmsPage = defineAsyncComponent(() => import('~~/common/components/Page/Content/AwCmsPage'));

  defineOptions({
    name: 'ShopIndex',
  });
  // eslint-disable-next-line no-undef
  definePageMeta({
    layout: 'full-width',
  });

  seoMixin();

  const STATIC_CONTENT_TYPE = 'homepage';

  const contentsStore = useContentsStore();
  const userInterfaceStore = useUserInterfaceStore();

  await useAsyncData(fetchKey(), async ({ $logger }) => {
    try {
      const seoStore = useSeoStore();
      contentsStore.increaseStaticContentUsers({ type: STATIC_CONTENT_TYPE });
      await contentsStore.fetchStaticContent({ type: STATIC_CONTENT_TYPE });
      if (contentsStore.staticContent[STATIC_CONTENT_TYPE]?.id) {
        await seoStore.fetchSeoData({
          seoModule: 'cms_content',
          id: contentsStore.staticContent[STATIC_CONTENT_TYPE].id,
        });
      }
      return {};
    } catch (error) {
      if (error.response?.status !== 404) {
        $logger.error(error);
      }
      return {};
    }
  });

  const contentData = computed(() => contentsStore.staticContent[STATIC_CONTENT_TYPE]);

  onMounted(() => {
    if (contentData.value) {
      userInterfaceStore.setBackground({ ...contentData.value });
    }
  });
  onBeforeUnmount(() => {
    contentsStore.decreaseStaticContentUsers({ type: STATIC_CONTENT_TYPE });
  });
</script>

<style module lang="scss" rel="stylesheet/scss">
.page {
  padding-bottom: 20px;

  @include tablet(min) {
    padding-bottom: 40px;
  }
}
</style>
