export default {
  props: {
    content: {
      type: Object,
      required: true,
    },
    hasLink: {
      type: Boolean,
      required: true,
    },
    size: {
      type: String,
      default: 'md',
    },
    translation: {
      type: Object,
      default () {
        return {};
      },
    },
  },
  computed: {
    link () {
      if (this.content.urls.length) {
        return { to: this.$path(this.content) };
      } else if (this.content.externalUrl) {
        return { href: this.content.externalUrl };
      } else {
        return {};
      }
    },
  },
};
