import { defineNuxtPlugin, navigateTo, useRequestEvent, useRuntimeConfig } from 'nuxt/app';
import { grantTypeTransform, setLoginCookies } from '../../server/grantsHelper.js';
import { AW_AX_CACHE_DEFAULT, AW_AX_CACHE_SEGMENTATION } from './awAxCache.js';
import {
  createApiNetwork,
  createAuthenticationNetwork,
  createAuthorizationNetwork,
  createMsgTokenNetwork,
} from './network.js';

import { useNotificationStore } from '~~/common/stores/notification';
import { useAuthenticationStore } from '~~/common/stores/authentication';
import { useAwCacheKeyStore } from '~~/common/stores/awCacheKey';
import { useUserStore } from '~~/common/stores/user';
import { useDeliveryStore } from '~~/shop/stores/delivery';
import { useLoyaltyCardStore } from '~~/shop/stores/loyaltyCard';
import { handleRedirectAfterTokenExpired } from '~~/common/server/globalMiddlewareContans.js';
import { LT_ANON } from '~~/common/config/LoginTypeConfig.js';

const QUERY_STRING_CACHE_SEGMENTATION_CODE = 'cacheSegmentationCode';

const requestWithoutDeliveryAreaParams = new Set(['/seo/global/-1', '/config']);

export default defineNuxtPlugin(function (nuxtApp) {
  const {
    $i18n,
    $pinia,
    $logger,
    $localePath,
    $cookies,
    $awt,
    $awMergedConfig: {
      xDebug,
    },
  } = nuxtApp;
  const reqHeaders = useRequestEvent()?.node?.req?.headers;
  const proxiedHeaders = { ...reqHeaders || null };
  if (reqHeaders) {
    for (const h of [
      // list of headers is from:
      // https://github.com/nuxt-community/axios-module/blob/2436566b06638aa204a10e48ae63cdc58d7ba9a1/lib/module.js#L82
      'accept',
      'cf-connecting-ip',
      'cf-ray',
      'content-length',
      'content-md5',
      'content-type',
      'host',
      'x-forwarded-host',
      'x-forwarded-port',
      'x-forwarded-proto',
    ]) {
      delete proxiedHeaders[h];
    }
  }

  const authenticationNetwork = createAuthenticationNetwork(proxiedHeaders);
  const authorizationNetwork = (import.meta.server
    ? createAuthorizationNetwork(proxiedHeaders)
    : null
  );
  const apiNetwork = createApiNetwork(proxiedHeaders);
  const messageTokenNetwork = createMsgTokenNetwork(proxiedHeaders);

  const refreshCalls = {
    current: 0,
    max: 3,
  };
  let refreshingCall = null;
  let prePersistLoginCookiesToStore = null;
  const awCacheKeyStore = useAwCacheKeyStore($pinia);
  const authenticationStore = useAuthenticationStore($pinia);
  const notificationStore = useNotificationStore($pinia);
  const userStore = useUserStore($pinia);
  const loyaltyCardStore = useLoyaltyCardStore($pinia);
  const deliveryStore = useDeliveryStore($pinia);

  function refreshTokens (payload) {
    if (refreshingCall) {
      return refreshingCall;
    }

    if (refreshCalls.max < refreshCalls.current) {
      const errorMessage = `Authentication failed ${refreshCalls.max} times!`;
      authenticationStore.clearAuthCookies().then(() => {
        prePersistLoginCookiesToStore = null;
        userStore.setLoginType(LT_ANON);
        userStore.resetUserData();
        loyaltyCardStore.resetLoyaltyData();
        deliveryStore.fetchMethod();

        return Promise.reject(new Error(errorMessage));
      });
    }

    refreshCalls.current++;

    refreshingCall = (async () => {
      if (!authorizationNetwork) {
        return authenticationNetwork.$post('/login', payload);
      }
      const {
        auchanApiClientId: clientId,
        auchanApiClientSecret: clientSecret,
      } = useRuntimeConfig();
      const body = await authorizationNetwork.$post('/token', grantTypeTransform({
        body: payload,
        cookies: $cookies,
        clientId,
        clientSecret,
      }));
      const { isChangeAsync } = setLoginCookies({
        cookies: $cookies,
        body,
      });
      prePersistLoginCookiesToStore = isChangeAsync ? body : null;
      setAxiosTokenFromCookie();
      return body;
    })().then((data) => {
      refreshCalls.current = 0;
      return Promise.resolve(data);
    }).catch((error) => {
      if (error?.response?.data?.error === 'invalid_request' && error?.response?.data?.error_description.toLowerCase().includes('refresh token is invalid')) {
        userStore.setLoginType(LT_ANON);
        userStore.resetUserData();
        loyaltyCardStore.resetLoyaltyData();
        refreshingCall = null;
        const refreshPromise = refreshTokens({ grant_type: 'anonymous' });
        if (import.meta.client) {
          notificationStore.pushError({
            text: {
              title: $awt('aw.token_expired'),
            },
          });
          setTimeout(() => {
            navigateTo($localePath('/'));
          }, 3000);
        } else {
          userStore.setForceLogoutToRedirect();

          //NOTE: this is a hack, the global middleware won't triggered if the user has an invalid access token, so we need to manually redirect the user the /LOCALE/?t=UUID page
          handleRedirectAfterTokenExpired($i18n, $cookies, nuxtApp.$router.push);
        }
        return refreshPromise;
      }
      return Promise.resolve({});
    }).finally(() => {
      refreshingCall = null;
    });
    return refreshingCall;
  }

  function setAxiosTokenFromCookie () {
    const accessToken = prePersistLoginCookiesToStore?.access_token || $cookies.get('access_token');
    const tokenType = prePersistLoginCookiesToStore?.token_type || $cookies.get('token_type');
    const authHeader = `${tokenType ?? ''} ${accessToken ?? ''}`;
    apiNetwork.defaults.headers.Authorization = authHeader;
    authenticationNetwork.defaults.headers.Authorization = authHeader;

    const loginType = prePersistLoginCookiesToStore?.login_type || $cookies.get('login_type');
    if (loginType) {
      userStore.setLoginType(loginType);
    }
  }

  function handleUnauthorizedRequest (axNetwork, errorCall) {
    const userIsLoggedIn = $cookies.get('login_type') !== LT_ANON;
    return refreshTokens(userIsLoggedIn ? { grant_type: 'refresh_token' } : { grant_type: 'anonymous' })
      .then((response) => {
        const authHeader = `${response.token_type ?? ''} ${response.access_token ?? ''}`;
        apiNetwork.defaults.headers.Authorization = authHeader;
        authenticationNetwork.defaults.headers.Authorization = authHeader;
        errorCall.config.headers.Authorization = authHeader;
        errorCall.config.baseURL = undefined;

        return axNetwork.request(errorCall.config);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401 && refreshCalls.max < refreshCalls.current) {
          return Promise.reject(error);
        } else if (
          errorCall?.response?.status >= 500 || (
            error?.response?.status === 401 &&
          error?.response?.data?.error === 'invalid_request' &&
          error?.response?.data?.error_description.toLowerCase().includes('refresh token is invalid')
          )
        ) {
          return refreshTokens({ grant_type: 'anonymous' });
        } else {
          return Promise.reject(error);
        }
      });
  }

  apiNetwork.interceptors.request.use((config) => {
    configureCache(config);
    const {
      urlPath,
      urlQuery,
    } = getUrlSections(config.url);

    urlQuery.set('hl', $i18n.locale.value);

    config.url = `${urlPath}?${urlQuery}`;
    configureXDebug(config);
    configureCookies(config);
    return config;
  });

  authenticationNetwork.interceptors.request.use((config) => {
    configureXDebug(config);
    configureCookies(config);
    return config;
  });

  if (authorizationNetwork) {
    authorizationNetwork.interceptors.request.use((config) => {
      config.headers['Accept-Language'] = $i18n.locale.value;
      configureXDebug(config);
      configureCookies(config);
      return config;
    });
  }

  apiNetwork.interceptors.response.use((response) => {
    if (typeof process !== 'undefined' && process && process.pid && process.send) {
      process.send({
        cmd: 'notifyRequest',
        pid: process.pid,
      });
    }
    if (isCacheHit({
      isCacheRequested: Boolean(response.config.awAxCache),
      responseHeaders: response.headers,
      $logger,
    })) {
      response.awAxCacheHit = response.config.awAxCache;
    } else {
      response.awAxCacheHit = false;
    }
    return response;
  }, (error) => {
    if (typeof process !== 'undefined' && process && process.pid && process.send) {
      process.send({
        cmd: 'notifyRequest',
        pid: process.pid,
      });
    }
    if (error?.response?.status === 409) {
      if (error?.config?.stopRecursion) {
        $logger.error(new CacheSegmentationError(`stopRecursion reached on ${error?.config?.url}`));
        return Promise.reject(error);
      }
      const segmentationCacheKey = extractNewSegmentationCacheKey({ responseHeaders: error.response.headers });
      if (segmentationCacheKey) {
        awCacheKeyStore.setCacheKeySegmentation(segmentationCacheKey[1] ? `${segmentationCacheKey[1]}` : '');
        const urlSearch = new URLSearchParams(error.config.url.split('?')[1]);
        const newCacheKey = awCacheKeyStore.cacheKeySegmentation;
        if (urlSearch.get(QUERY_STRING_CACHE_SEGMENTATION_CODE) !== newCacheKey) {
          urlSearch.set(QUERY_STRING_CACHE_SEGMENTATION_CODE, newCacheKey);
          error.config.url = `${error.config.url.split('?')[0]}?${urlSearch}`;
          error.config.stopRecursion = true;

          return apiNetwork.request(error.config);
        } else {
          $logger.error(new CacheSegmentationError(`unchanged cacheKey on ${error?.config?.url}`));
        }
      }
    } else if (error?.response?.status === 401) {
      handleUnauthorizedRequest(apiNetwork, error).catch((error) => {
        $logger.error('Unauthorized API Network', error);
      });
    } else if (error?.response?.status >= 500) {
      $logger.error('Api Network', error);
    }
    return Promise.reject(error);
  });

  authenticationNetwork.interceptors.response.use((response) => {
    setAxiosTokenFromCookie();
    return response;
  }, (error) => {
    const status = error.response ? error.response.status : null;

    if (status === 401) {
      $logger.error('Unauthorized Authentication', error);
    } else if (status >= 500) {
      $logger.error('Authentication Network', error);
    }

    return Promise.reject(error);
  });

  if (authorizationNetwork) {
    authorizationNetwork.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status !== 401) {
          $logger.error('Authorization', error);
        }
        return Promise.reject(error);
      },
    );
  }

  setAxiosTokenFromCookie();

  nuxtApp.provide('api', apiNetwork);

  nuxtApp.provide('authentication', authenticationNetwork);

  nuxtApp.provide('axiosRefreshTokens', refreshTokens);

  nuxtApp.provide('messageTokenNetwork', messageTokenNetwork);
  return refreshingCall;

  function getUrlSections (url) {
    const [urlPath, urlQuery] = url.split('?');
    return {
      urlPath,
      urlQuery: new URLSearchParams(urlQuery),
    };
  }

  function configureCache (config) {
    const appendPrefix = (url, prefix, fallback) => url.startsWith(prefix) ? fallback : prefix;
    const getCachePrefix = url => appendPrefix(url, '/cache', '');
    const getStartingSlash = url => appendPrefix(url, '/', '');
    const getQuerySeparator = url => url.includes('?') ? '&' : '?';
    if (config.awAxCache === AW_AX_CACHE_SEGMENTATION) {
      const {
        urlPath,
        urlQuery,
      } = getUrlSections(config.url);
      urlQuery.set(QUERY_STRING_CACHE_SEGMENTATION_CODE, awCacheKeyStore.cacheKeySegmentation);
      config.url = `${getCachePrefix(config.url)}${getStartingSlash(config.url)}${urlPath}?${urlQuery}`;
    }
    if (config.awAxCache === AW_AX_CACHE_DEFAULT) {
      config.url = `${getCachePrefix(config.url)}${getStartingSlash(config.url)}${config.url}`;
    }
    if ((!config.awAxCache || config.awAxCache === AW_AX_CACHE_DEFAULT) && !requestWithoutDeliveryAreaParams.has(config.url) && !config.url.includes(awCacheKeyStore.cacheKeyLegacy)) {
      config.url += `${getQuerySeparator(config.url)}${awCacheKeyStore.cacheKeyLegacy}`;
    }
  }

  function configureCookies (config) {
    if (import.meta.server) {
      try {
        config.headers.cookie = Object.entries($cookies.getAll()).filter(([key]) => key).map(([key, value]) => `${key}=${value}`).join('; ');
      } catch (error) {
        $logger.error('Error getting cookies:', error);
      }
    }
  }

  function configureXDebug (config) {
    if (xDebug && !config.url.includes('XDEBUG=')) {
      const querySeparator = (config.url.includes('?') ? '&' : '?');
      config.url = `${config.url}${querySeparator}XDEBUG=PHP_STORM`;
    }
  }
});

function isCacheHit ({
  isCacheRequested,
  responseHeaders,
  $logger,
}) {
  const isCacheHit = Object.entries(responseHeaders).some(([headerName, headerValue]) => {
    return headerName?.toLowerCase?.() === 'x-cached' && headerValue?.toLowerCase?.() === 'hit';
  });
  if (!isCacheRequested && isCacheHit) {
    $logger.warn('Got a response with Cache Hit, for an endpoint that was not supposed to be cached.');
  }
  return isCacheHit;
}

function extractNewSegmentationCacheKey ({ responseHeaders }) {
  return Object.entries(responseHeaders).find(([headerName]) => {
    return headerName?.toLowerCase?.() === 'x-valid-cache-segmentation-code';
  });
}

class CacheSegmentationError extends Error {
  constructor () {
    super(...arguments);
    this.name = 'CacheSegmentationError';
  }
}
