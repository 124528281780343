<template>
  <div
    :id="`accordionItem-${uuid}`"
    ref="componentEl"
    :class="[$style.accordionItem, $style[styling || parentStyling], {[$style.accordionItemHeaderBottom]: accordionHeaderBottom}, {[$style.accordionItemOpened]: isAccordionOpened}, {[$style.accordionItemDisabled]: !clickable}]"
  >
    <component :is="controlTag" :class="$style.accordionControl">
      <button
        :id="`accordionHeader-${uuid}`"
        type="button"
        :disabled="!clickable"
        :aria-expanded="isAccordionOpened.toString()"
        :aria-controls="`accordionContent-${uuid}`"
        :class="['buttonReset', $style.accordionItemHeader, classes.accordionItemHeader]"
        @click="toggleAccordion"
      >
        <slot name="header" :is-accordion-opened="isAccordionOpened">
          <span :class="$style.accordionItemHeaderContent">
            <span :class="$style.accordionItemHeaderText">
              <slot name="headerText" />
            </span>
            <span :class="$style.accordionItemHeaderIcon">
              <slot name="headerIcon" :is-accordion-opened="isAccordionOpened">
                <lv-icon
                  v-bind="toggleIconAttrs"
                  :class="[$style.chevronIcon, {[$style.chevronIconOpened]: isAccordionOpened}]"
                />
              </slot>
            </span>
          </span>
        </slot>
      </button>
    </component>
    <transition v-bind="accordionTransition">
      <div
        v-show="isAccordionOpened"
        :id="`accordionContent-${uuid}`"
        :aria-labelledby="`accordionHeader-${uuid}`"
        :class="$style.accordionItemContentWrapper"
      >
        <div v-if="!removeFromDom" :class="$style.accordionItemContent">
          <div :class="[$style.accordionItemContentContainer]">
            <slot name="content" />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup>
  import { inject, ref, watch, useCssModule, onBeforeMount, onMounted, onBeforeUnmount, computed } from 'vue';
  import { LvIcon } from '~~/common/components/loginet-vue-shop/index.mjs';
  import { uuid4 } from '~~/common/utils';
  import { createAccordionTransition } from '~~/common/utils/accordion.js';

  const $style = useCssModule();
  const updateAccordion = inject('updateAccordion');
  const removeAccordion = inject('removeAccordion');
  const controlTag = inject('controlTag');
  const accordions = inject('accordions');
  const parentStyling = inject('styling');
  const parentEmit = inject('emit');

  const props = defineProps({
    accordionOpened: {
      type: Boolean,
      default: true,
    },
    accordionHeaderBottom: {
      type: Boolean,
      default: false,
    },
    clickable: {
      type: Boolean,
      default: true,
    },
    classes: {
      type: Object,
      default () {
        return {};
      },
    },
    styling: {
      type: String,
      default: '',
    },
  });
  const emits = defineEmits([
    'accordionItemToggle',
    'afterOpened',
    'afterClosed',
  ]);
  const toggleIconAttrs = {
    name: 'arrow-left-16',
    size: 16,
  };
  const componentEl = ref(null);
  const uuid = ref(null);
  const isAccordionOpened = ref(props.accordionOpened);
  const removeFromDom = ref(!props.accordionOpened);

  const headerHeight = computed(() => {
    return document.getElementById('aw_header')?.getBoundingClientRect()?.height || 0;
  });

  watch(() => props.accordionOpened, (newVal) => {
    isAccordionOpened.value = newVal;
  });
  watch(accordions, async (accordions) => {
    if (!accordions[componentEl.value.id]) {
      isAccordionOpened.value = accordions[componentEl.value.id];
    }
  });
  onBeforeMount(() => {
    uuid.value = uuid4();
  });
  onMounted(() => {
    updateAccordion(componentEl.value.id, isAccordionOpened.value);
  });
  onBeforeUnmount(() => {
    removeAccordion(componentEl.value.id);
  });

  function toggleAccordion () {
    if (props.clickable) {
      isAccordionOpened.value = !isAccordionOpened.value;
      updateAccordion(componentEl.value.id, isAccordionOpened.value);
    }
  }

  const accordionTransition = {
    name: $style.accordionItem,
    enterActiveClass: $style.accordionItemEnterActive,
    enterFromClass: $style.accordionItemEnter,
    leaveActiveClass: $style.accordionItemLeaveActive,
    leaveToClass: $style.accordionItemLeaveTo,
    ...createAccordionTransition({
      safeToRemoveCb (newVal) {
        removeFromDom.value = newVal;
      },
      async endTransitionCb () {
        if (isAccordionOpened.value) {
          window.scroll(0, componentEl.value.getBoundingClientRect().top + document.documentElement.scrollTop - headerHeight.value);
        }
        parentEmit('accordionItemToggle', componentEl.value, isAccordionOpened.value);
        emits(isAccordionOpened.value ? 'afterOpened' : 'afterClosed');
      },
    }),
  };
</script>
<style module lang="scss" rel="stylesheet/scss">

.accordionControl {
  /* Accordions w/ border radius has a nagative margin on content so a
  z-index is needed. */
  z-index: 1;
}

.accordionItem {
  display: flex;
  flex-direction: column;

  &HeaderBottom {
    flex-direction: column-reverse;
  }

  &Opened {
    display: flex;
  }

  &Header {
    cursor: pointer;

    &Content {
      display: flex;
      align-items: center;
      gap: 10px;
      flex: 1 1 0;
    }

    &Text {
      font-family: $secondary-font;
      flex: 1 1 0;
    }

    &Icon {
      display: flex;
      align-items: center;
      flex: 0 1 auto;
      justify-content: center;
      width: 32px;
      height: 32px;
    }
  }

  &EnterActive,
  &LeaveActive {
    overflow: hidden;
    transition: height $animation-speed-medium $animation-timing-function;
  }

  &Enter,
  &LeaveTo {
    height: 0 !important;
  }
}

.chevronIcon {
  transition: transform $animation-speed-medium $animation-timing-function;
  transform: rotate(270deg);

  &Opened {
    transform: rotate(90deg);
  }
}

.accordionItemHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  transition-delay: 150ms;
  transition-timing-function: ease;
  transition-duration: 280ms;
  transition-property: color, background;
  text-align: left;
}

@mixin generate-accordion-item($th) {
  &.accordionItemOpened {
    .accordionItemHeader {
      color: map-get($th, "header-open-color");
      border-radius: map-get($th, "header-open-border-radius");
      background: map-get($th, "header-open-background");
    }
  }

  .accordionItem {
    &Header {
      font-size: map-get($th, "header-font-size");
      font-weight: map-get($th, "header-font-weight");
      line-height: map-get($th, "header-line-height");
      min-height: map-get($th, "header-height");
      padding: map-get($th, "header-padding");
      color: map-get($th, "header-color");
      border: map-get($th, "header-border");
      border-radius: map-get($th, "header-border-radius");
      background: map-get($th, "header-background");
      box-shadow: map-get($th, "header-box-shadow");

      @include tablet(min) {
        font-size: map-get($th, "header-font-size-tablet");
        line-height: map-get($th, "header-line-height-tablet");
        padding: map-get($th, "header-padding-tablet");
      }

      &:hover,
      &:focus,
      &:active {
        color: map-get($th, "header-active-color");
        background: map-get($th, "header-active-background");
      }

      &Icon {
        border: map-get($th, "header-icon-border");
        border-radius: map-get($th, "header-icon-border-radius");
      }
    }

    &Content {
      font-size: map-get($th, "content-font-size");
      font-weight: map-get($th, "content-font-weight");
      line-height: map-get($th, "content-line-height");
      padding: map-get($th, "content-padding");
      color: map-get($th, "content-color");
      border-right: map-get($th, "content-border");
      border-bottom: map-get($th, "content-border");
      border-left: map-get($th, "content-border");
      border-radius: map-get($th, "content-border-radius");
      background: map-get($th, "content-background");
      box-shadow: map-get($th, "content-box-shadow");


      @include tablet(min) {
        font-size: map-get($th, "content-font-size-tablet");
        line-height: map-get($th, "content-line-height-tablet");
      }

      &Wrapper {
        @if map-get($th, "content-under-sliding") {
          margin-top: calc(-1 * #{map-get($th, "content-under-sliding")});
        }
      }

      &Container {
        padding-top: map-get($th, "content-under-sliding");
      }
    }
  }

  &.accordionItemDisabled {
    .accordionItem {
      &Header {
        color: map-get($th, "header-disabled-color");
        background: map-get($th, "header-disabled-background");
      }

      &Content {
        color: map-get($th, "content-disabled-color");
        background: map-get($th, "content-disabled-background");
      }
    }
  }
}

@each $th-name, $th in $aw-accordion-themes {
  .#{$th-name} {
    @include generate-accordion-item($th);
  }
}

</style>
