<template>
  <component :is="tag" @submit.prevent="onSubmit">
    <slot />
  </component>
</template>

<script setup>
  import { provide, computed, nextTick } from 'vue';
  import { useId } from 'nuxt/app';
  import { useUserInterfaceStore } from '~~/common/stores/userInterface';

  defineOptions({
    // NOTE: defining name is necessary so the formMixin's findComponent
    // function finds this component by $options.name.
    name: 'AwForm',
  });

  const props = defineProps({
    tag: {
      type: String,
      default: 'form',
      validator: v => ['form', 'div'].includes(v),
    },
    modelValue: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
  });

  defineExpose({
    modelValue: props.modelValue,
  });

  const emits = defineEmits([
    'update:modelValue',
    'onSubmit',
    'success',
  ]);

  const uuid = useId();

  provide('uuid', uuid);

  const apiIsLoading = computed(() => {
    return !!useUserInterfaceStore().loading.length;
  });

  async function onSubmit () {
    if (apiIsLoading.value) {
      return;
    }
    props.modelValue.$touch();
    await nextTick();

    if (!props.modelValue.$errors.length) {
      emits('onSubmit');
      emits('success');
    }
  }
</script>
