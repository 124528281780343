import { computed } from 'vue';
import { useRoute, useRuntimeConfig } from 'nuxt/app';

import { useModalStore } from '~~/common/stores/modal';
import { useAuthenticationStore } from '~~/common/stores/authentication';
import { useConfigStore } from '~~/common/stores/config';
import { useUserInterfaceStore } from '~~/common/stores/userInterface.js';
import { useToasterStore } from '~~/common/stores/toaster.js';

const isSalesforceLoginMaintenanceEnabled = computed(() => {
  return useConfigStore().configCommon?.persistentConfig?.salesforce_login_maintenance;
});
const isSalesForceLogin = computed(() => {
  return useConfigStore().isSalesForceLogin;
});

export async function openLoginModal (data) {
  const userInterfaceStore = useUserInterfaceStore();

  const navigateToLogin = async () => {
    const modalStore = useModalStore();
    const route = useRoute();
    if (!isSalesForceLogin.value) {
      modalStore.showLoginAndRegistrationModal(data);
    } else if (isSalesforceLoginMaintenanceEnabled.value) {
      openLoginMaintenanceModal();
    } else {
      const authenticationStore = useAuthenticationStore();
      await authenticationStore.customerDiamondLogin({ path: route.fullPath });
    }
  };

  if (data?.loadingId) {
    userInterfaceStore.startLoading({ id: data.loadingId });
  }
  if (data?.showToaster && data?.toasterAttributes) {
    const toasterStore = useToasterStore();
    const authenticationStore = useAuthenticationStore();

    const logout = authenticationStore.logout();
    toasterStore.push({
      ...data.toasterAttributes,
      closeAfter: useRuntimeConfig().public.careerToasterTimeoutMs,
      callback: async () => {
        await logout;
        await navigateToLogin();
        if (data?.loadingId) {
          userInterfaceStore.endLoading({ id: data.loadingId });
        }
      },
    });
  } else {
    await navigateToLogin();
    if (data?.loadingId) {
      userInterfaceStore.endLoading({ id: data.loadingId });
    }
  }
}

export async function openRegistrationModal (data) {
  const modalStore = useModalStore();
  const config = useRuntimeConfig();
  const route = useRoute();
  if (config.public.isRegistrationDisabled) {
    alert('A funkció egyenlőre nem elérhető!');
    return;
  }
  if (!isSalesForceLogin.value) {
    modalStore.showLoginAndRegistrationModal(data);
  } else if (isSalesforceLoginMaintenanceEnabled.value) {
    openLoginMaintenanceModal();
  } else {
    const authenticationStore = useAuthenticationStore();
    await authenticationStore.customerDiamondRegistration({ path: route.fullPath });
  }
}

function openLoginMaintenanceModal () {
  const modalStore = useModalStore();
  modalStore.openStartupPopup();
}

export default {
  methods: {
    openLoginModal,
    openRegistrationModal,
  },
};
