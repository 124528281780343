import { defineStore } from 'pinia';
import { useNuxtApp } from 'nuxt/app';
import { computed, ref } from 'vue';
import { getErrorNotification, setErrorNotification } from '~~/common/utils/helper';
import otqs from '~~/common/utils/objectToQueryString';
import { useContentsStore } from '~~/common/stores/contents';
import { useQueueStore } from '~~/common/stores/queue';
import { useOrderStore } from '~~/common/stores/order';
import { useUserInterfaceStore } from '~~/common/stores/userInterface';
import { useAwCacheKeyStore } from '~~/common/stores/awCacheKey';
import { useProductsStore } from '~~/common/stores/products';
import { useProductStore } from '~~/common/stores/product';
import { useUserStore } from '~~/common/stores/user';
import { useCategoryStore } from '~~/common/stores/category';
import { useCheckoutStore } from '~~/common/stores/checkout.js';
import { useAuthenticationStore } from '~~/common/stores/authentication.js';

let globalDepartmentStores = null;
export const useDeliveryStore = defineStore('delivery', () => {
  const nuxtApp = useNuxtApp();

  const checkoutStore = useCheckoutStore();
  const awCacheKeyStore = useAwCacheKeyStore();
  const contentsStore = useContentsStore();
  const userInterfaceStore = useUserInterfaceStore();
  const userStore = useUserStore();
  const productsStore = useProductsStore();
  const orderStore = useOrderStore();
  const productStore = useProductStore();
  const categoryStore = useCategoryStore();
  const queueStore = useQueueStore();
  const authenticationStore = useAuthenticationStore();

  const timeframes = ref([]);
  const underApprovalTimeframe = ref(null);
  const selectedTimeframe = ref(null);
  const timeframeListSelectedTimeframe = ref(null);
  const selectedDay = ref(null);
  const selectedDeliveryMethod = ref(null);
  const timeZone = ref('Europe/Budapest');
  const setup = ref(null);
  const departmentStores = ref([]);
  const allDepartmentStores = ref([]);
  const departmentStoreFilters = ref([]);
  const glsDetailData = ref(null);
  const foxpostDetailData = ref(null);
  const foodDeliveryAvailable = ref(false);
  const expiredTimeframeVisible = ref({
    expiredVisible: null,
    expireSoonVisible: null,
  });
  const secondSeparatorWithoutMarginTop = ref(null);

  // getters
  const bestTimeFrameOptions = computed(() => {
    if (!timeframes.value.length) {
      return [];
    }
    const activeTimeframes = timeframes.value.filter(timeframe => timeframe.status);
    const cheapestTimeframePrice = activeTimeframes.reduce((previous, current) => previous.price.grossDiscounted < current.price.grossDiscounted ? previous : current).price.grossDiscounted;
    const cheapestTimeframe = activeTimeframes.filter(current => current.price.grossDiscounted === cheapestTimeframePrice).sort((current, previous) => new Date(current.date) - new Date(previous.date))[0];
    const earliestTimeframeFrom = activeTimeframes.reduce((previous, current) => previous.from < current.from ? previous : current).from;
    const earliestTimeframe = activeTimeframes.filter(timeframe => timeframe.from === earliestTimeframeFrom).sort((previous, current) => new Date(current.date) - new Date(previous.date))[0];

    return {
      cheapestTimeframe,
      earliestTimeframe,
    };
  });
  const dayTimeframes = computed(() => (day, locale) => {
    if (!(day instanceof Date)) {
      throw new TypeError('`day` should be type Date');
    }
    const sameDayConf = [locale, {
      timeZone: timeZone.value,
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
    }];

    return timeframes.value.filter(({ from }) => {
      return day.toLocaleString(...sameDayConf) === (new Date(from)).toLocaleString(...sameDayConf);
    }).sort((a, b) => {
      if (a.from === b.from) {
        return 0;
      }
      return (a.from < b.from) ? -1 : 1;
    });
  });
  const hours = computed(() => {
    return (day, locale) => {
      const config = [locale, {
        timeZone: timeZone.value,
        hour: '2-digit',
      }];

      if (!(day instanceof Date)) {
        throw new TypeError('`day` should be type Date');
      }
      return dayTimeframes.value(day, locale)
        .map(({
          from,
          to,
        }) => [
          parseInt((new Date(from)).toLocaleString(...config), 10),
          parseInt((new Date(to)).toLocaleString(...config), 10),
        ])
      ;
    };
  });
  const extremaHours = computed(() => {
    return (day) => {
      if (!(day instanceof Date)) {
        throw new TypeError('`day` should be type Date');
      }
      const hoursValue = hours.value(day);
      const minHours = Math.min(...hoursValue.map(h => h[0]));
      const maxHours = Math.max(...hoursValue.map(h => h[1]));

      if (minHours === Infinity || maxHours === Infinity) {
        return [0, 0];
      }
      return [minHours, maxHours];
    };
  });
  const getPostCode = computed(() => {
    return setup.value?.postCode ? setup.value.postCode : '';
  });

  // actions
  function selectTimeframeForApproval (timeframe) {
    underApprovalTimeframe.value = timeframe;
  }

  function clearTimeframeForApproval () {
    underApprovalTimeframe.value = null;
  }

  function selectTimeframe (tframe) {
    selectedTimeframe.value = tframe;
  }

  function setTimeframeListSelectedTimeframe (payload) {
    timeframeListSelectedTimeframe.value = payload;
  }

  function selectDay (day) {
    selectedDay.value = day;
  }

  function clearDay () {
    selectedDay.value = null;
  }

  function setSelectedDeliveryMethod (payload) {
    selectedDeliveryMethod.value = payload;
  }

  function setDepartmentStores ({
    newDepartmentStores,
    isFiltered,
  }) {
    departmentStores.value = newDepartmentStores;
    if (!isFiltered) {
      allDepartmentStores.value = newDepartmentStores;
    }
  }

  function setGlsDetailedData (payload) {
    glsDetailData.value = payload;
  }

  function setFoxpostDetailedData (payload) {
    foxpostDetailData.value = payload;
  }

  function setExpiredVisible (expiredVisible) {
    expiredTimeframeVisible.value.expiredVisible = expiredVisible;
  }

  function setExpireSoonVisible (expireSoonVisible) {
    expiredTimeframeVisible.value.expireSoonVisible = expireSoonVisible;
  }

  function setFoodDeliveryAvailable (payload) {
    foodDeliveryAvailable.value = payload;
  }

  function setSecondSeparatorWithoutMarginTop (payload) {
    secondSeparatorWithoutMarginTop.value = payload;
  }

  async function fetchMethod () {
    const {
      $api,
      $logger,
    } = nuxtApp;
    try {
      const result = await $api.$get('/delivery-area');
      setup.value = result;

      setFoodDeliveryAvailable(Boolean(result?.foodDeliveryAvailable));
      awCacheKeyStore.setCacheKeyLegacy(setup.value?.id ? `delivery_area_id=${setup.value.id}&delivery_area_type=${setup.value.type}` : '');
      awCacheKeyStore.setCacheKeySegmentation(setup.value?.cacheSegmentationCode ? `${setup.value.cacheSegmentationCode}` : '');
    } catch (error) {
      $logger.error(error);
      await setErrorNotification({ nuxtApp, error });
    }

    try {
      const type = 'homepage';
      if (contentsStore.staticContent[type]) {
        await contentsStore.fetchStaticContent({ type });
        userInterfaceStore.setBackground({ ...contentsStore.data });
      }
    } catch (error) {
      if (error.response?.status !== 404) {
        $logger.error(error);
        await setErrorNotification({ nuxtApp, error });
      }
    }
  }

  async function pushMethod (payload) {
    userInterfaceStore.startLoading({ id: `push-method-${payload.areaId}-${payload.type}` });

    if (import.meta.server) {
      return await pushMethodRequest(payload);
    } else {
      return await queueStore.add({
        action: pushMethodRequest,
        actionName: 'delivery/pushMethodRequest',
        payload,
        canStartSecondAction: true,
      });
    }
  }

  async function pushMethodRequest ({
    type,
    areaId,
    resolveOption,
    nonfood,
  }) {
    const {
      $api,
      $cookies,
    } = nuxtApp;

    await authenticationStore.reInitToken();

    try {
      let headerParameter = {};
      if (resolveOption) {
        headerParameter = {
          headers: {
            'X-Error-Resolve-Option': resolveOption,
          },
        };
      }
      await $api.$post('/delivery-area',
        {
          type,
          areaId,
        },
        headerParameter);
      selectedTimeframe.value = null;
      clearTimeframeForApproval();
      clearDay();
      setExpiredVisible(null);
      setExpireSoonVisible(null);

      if (productStore.data.id) {
        productStore.fetchMainProduct({
          identifierType: 'id',
          id: productStore.data.id,
        });
      }
      productsStore.resetFilterBy();
      productsStore.throttledRefresh();
      await fetchMethod();

      if (!nonfood) {
        await fetchTimeframes();
      }

      const orderResult = await orderStore.fetchOrder({
        id: 'current',
        fetchNonfoodCheckout: !nonfood,
        fetchFoodCheckout: false,
        canStartSecondAction: true,
        canRunSimultaneous: true,
      });

      if (!orderResult.fetchOrderErrorStatus) {
        await categoryStore.fetchCategories();
        // get shopping list, when user is logged in
        if (userStore.isEcomUser && userStore.data.shoppingLists) {
          await userStore.fetchShoppingLists({
            itemsPerPage: userStore.shoppingListsPagination.itemsPerPage * userStore.shoppingListsPagination.page,
            page: 1,
            mergeOldAndNewItems: false,
            updatePagination: false,
          });
        }

        $cookies.set('selected_postcode', JSON.stringify({
          type,
          areaId,
        }), {
          path: '/',
          maxAge: 7 * 24 * 60 * 60,
        });
      }

      return orderResult;
    } catch (error) {
      setFoodDeliveryAvailable(false);

      queueStore.removeAllElementFromQueue(false);

      return getErrorNotification(nuxtApp, error, false, {
        storeAction: pushMethod,
        storeActionParameters: {
          type,
          areaId,
          resolveOption,
          nonfood,
        },
      });
    } finally {
      userInterfaceStore.endLoading({ id: `push-method-${areaId}-${type}` });
    }
  }

  async function fetchDepartmentStoreFilters () {
    const { $api } = nuxtApp;
    departmentStoreFilters.value = await $api.$get('department_store_filters');
  }

  async function fetchDepartmentStores (params) {
    const { $api } = nuxtApp;
    try {
      globalDepartmentStores = $api.$get(`/department_stores${otqs({ city: params?.city || null })}`).then((dps) => {
        dps.forEach((dp) => {
          if (dp?.coordinates) {
            dp.coordinates = {
              latitude: parseFloat(dp?.coordinates?.latitude),
              longitude: parseFloat(dp?.coordinates?.longitude),
            };
          }
        });
        return dps;
      });

      setDepartmentStores({
        newDepartmentStores: await globalDepartmentStores,
        isFiltered: params?.city,
      });
    } catch (error) {
      setErrorNotification({ nuxtApp, error });
    }
    await globalDepartmentStores;
  }

  async function fetchTimeframes () {
    const {
      $api,
      $logger,
    } = nuxtApp;

    try {
      await Promise.all([
        $api.get('delivery_time_frames')
          .then(({ data }) => {
            timeframes.value = data;
          }),
        $api.get('checkouts/FOOD')
          .then(({ data }) => {
            checkoutStore.setCheckout({
              checkout: data,
              type: 'FOOD',
            });
          })
          .catch((e) => {
            $logger.error(e);
          }),
      ]);

      const selectedTimeframe = timeframes.value.find(({ id }) => id === checkoutStore.checkout?.food?.reservation?.id) || null;
      if (selectedTimeframe) {
        const selectedDay = selectedTimeframe.date;
        selectTimeframe(selectedTimeframe);
        selectTimeframeForApproval(selectedTimeframe);
        selectDay(new Date(selectedDay));
      } else {
        selectTimeframe(null);
        selectTimeframeForApproval(null);
        selectDay(null);
      }
    } catch (error) {
      setErrorNotification({ nuxtApp, error });
    }
  }

  return {
    timeframes,
    underApprovalTimeframe,
    selectedTimeframe,
    timeframeListSelectedTimeframe,
    selectedDay,
    selectedDeliveryMethod,
    timeZone,
    setup,
    departmentStores,
    allDepartmentStores,
    departmentStoreFilters,
    glsDetailData,
    foxpostDetailData,
    foodDeliveryAvailable,
    expiredTimeframeVisible,
    secondSeparatorWithoutMarginTop,
    bestTimeFrameOptions,
    dayTimeframes,
    hours,
    extremaHours,
    getPostCode,
    selectTimeframeForApproval,
    clearTimeframeForApproval,
    selectTimeframe,
    setTimeframeListSelectedTimeframe,
    selectDay,
    clearDay,
    setSelectedDeliveryMethod,
    setDepartmentStores,
    setGlsDetailedData,
    setFoxpostDetailedData,
    setExpiredVisible,
    setExpireSoonVisible,
    setFoodDeliveryAvailable,
    setSecondSeparatorWithoutMarginTop,
    fetchMethod,
    pushMethod,
    pushMethodRequest,
    fetchDepartmentStoreFilters,
    fetchDepartmentStores,
    fetchTimeframes,
  };
});
