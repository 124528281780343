import { defineStore } from 'pinia';
import { useNuxtApp } from 'nuxt/app';
import { ref } from 'vue';

const cacheGlobalSeo = {};

export const useSeoStore = defineStore('seo', () => {
  const nuxtApp = useNuxtApp();
  const seoRequestsEnRoute = ref([]);
  const data = ref({});
  const fullTitle = ref('');
  const module = ref(null);
  const id = ref(null);

  function removeSeoRequestsEnRoute (payload) {
    const i = seoRequestsEnRoute.value.indexOf(payload);
    if (i !== -1) {
      seoRequestsEnRoute.value.splice(i, 1);
    }
  }

  function setSeoData (payload) {
    data.value = payload;
    fullTitle.value = payload.title + (payload.titlePostfix ?? '');
    module.value = payload.module;
    id.value = payload.id;
  }

  function setPageTitlePrefix (payload) {
    if (payload.pageTitle) {
      fullTitle.value = payload.pageTitle + ' - ' + data.value.title + (data.value.titlePostfix ?? '');
    }
  }

  function setCareerPageTitle (payload) {
    const { $awt } = useNuxtApp();
    data.value.title = payload.title ?? $awt('aw.career.seo.title');
    data.value.titlePostfix = payload.titlePostfix ?? data.value.titlePostfix;
    if (payload.pageTitle) {
      setPageTitlePrefix(payload);
    }
  }

  async function fetchSeoData (payload) {
    const { $logger } = nuxtApp;
    let seoReq;
    try {
      seoReq = getSeoData(payload);
      seoRequestsEnRoute.value.push(payload);
      setSeoData({ ...await seoReq, module: payload?.seoModule ?? 'global', id: payload?.id ?? -1 });
    } catch (error) {
      setSeoData({ title: 'Auchan', titlePostfix: '', module: payload?.seoModule ?? 'global', id: payload?.id ?? -1 });
      $logger.error(error);
    } finally {
      removeSeoRequestsEnRoute(seoReq);
    }
  }

  function getSeoData ({ seoModule, id } = { seoModule: 'global', id: -1 }) {
    const { $logger, $api, $i18n } = nuxtApp;
    try {
      const getSeo = () => $api.$get(`/seo/${seoModule}/${id}`);
      if (import.meta.client && seoModule === 'global' && id === -1) {
        const cacheKey = $i18n.locale.value;
        if (!cacheGlobalSeo[cacheKey]) {
          cacheGlobalSeo[cacheKey] = getSeo().catch((err) => {
            $logger.error(err);
            delete cacheGlobalSeo[cacheKey];
          });
        }
        return cacheGlobalSeo[cacheKey];
      }
      return getSeo();
    } catch (err) {
      $logger.error(err);
    }
    return {};
  }

  return {
    seoRequestsEnRoute,
    data,
    fullTitle,
    module,
    id,
    fetchSeoData,
    getSeoData,
    setPageTitlePrefix,
    setCareerPageTitle,
  };
});
