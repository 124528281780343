<template>
  <div>
    <aw-spring>
      <div :class="$style.wrap">
        <h2 v-text="$awt('aw.order_modify_title_row.heading', { orderIdent })" />
        <div>
          <lv-button styling="small-primary-dark" shrink @click="cancelOrderModification">
            {{ $awt('aw.order_modify_title_row.abort') }}
          </lv-button>
        </div>
      </div>
    </aw-spring>
  </div>
</template>

<script setup>
  import { navigateTo, useNuxtApp } from 'nuxt/app';
  import { useOrderStore } from '~~/common/stores/order';
  import { useCheckoutStore } from '~~/common/stores/checkout';
  import { useDeliveryStore } from '~~/shop/stores/delivery';
  import { LvButton } from '~~/common/components/loginet-vue-shop/index.mjs';
  import AwSpring from '~~/common/components/AwSpring.vue';

  defineProps({
    orderIdent: {
      type: String,
      required: true,
    },
  });

  async function cancelOrderModification () {
    const { $localePath } = useNuxtApp();
    const { isShop } = useNuxtApp().$awMergedConfig;
    const { deleteOrderModification } = useOrderStore();
    const { fetchMethod } = useDeliveryStore();
    const checkoutStore = useCheckoutStore();

    await navigateTo($localePath(isShop
      ? '/shop/profile/orders'
      : '/orders-list',
    ));

    await deleteOrderModification();
    await fetchMethod();
    await checkoutStore.fetchCheckout({ type: checkoutStore.checkoutType });
  }
</script>

<style module lang="scss" rel="stylesheet/scss">
.wrap {
  @include font(900, 12px, 16px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
  gap: 16px;

  @include tablet(min) {
    @include font(null, 14px, 20px);
    padding: 20px 0;
  }
}
</style>
