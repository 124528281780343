<template>
  <aw-page />
</template>

<script setup>
  import { defineAsyncComponent } from 'vue';
  import { navigateTo, useNuxtApp } from 'nuxt/app';
  import { extractResourceFromPath, resetPath } from '~~/common/utils/urlResource';
  import { URL_PREFIX } from '~/awPlugins/app-config';
  import { useUrlResourceStore } from '~~/common/stores/urlResource';
  import { useOrderStore } from '~~/common/stores/order';
  import { updateSeoHeadReactive } from '~~/common/mixins/seoMixin';
  const AwPage = defineAsyncComponent(() => import('~~/common/components/Page/AwPage'));

  defineOptions({
    name: 'FallbackPageShop',
  });

  updateSeoHeadReactive();

  const {
    $logger,
  } = useNuxtApp();

  // eslint-disable-next-line no-undef
  definePageMeta({
    layout: 'dynamic-content',
    validate: async (route) => {
      const { fullPath } = route;
      const urlResourceStore = useUrlResourceStore();

      if (fullPath.startsWith('//')) {
        return navigateTo({ path: fullPath.replace(/\/+/, '/') });
      }

      if (fullPath.includes('/shop/termekek')) {
        return navigateTo(fullPath.replace('shop/termekek', 'shop/list'));
      }

      const resource = extractResourceFromPath(fullPath);
      if (resource) {
        urlResourceStore.setData({
          type: 'simple',
          resourceType: resource.resourceType,
          resourceId: resource.resourceId,
          slug: fullPath,
        });
        return true;
      } else { // Other URL resource. eg.:
        const anchorlessPath = fullPath.split('#')[0];
        await urlResourceStore.fetchByUrl(resetPath(anchorlessPath, URL_PREFIX) || '404');
        return Boolean(urlResourceStore.data?.resourceId);
      }
    },
  });
  try {
    const orderStore = useOrderStore();
    // reset paymentData on category list page or product page or search
    if (orderStore.paymentData) {
      orderStore.setPaymentData({
        paymentData: null,
        checkoutType: null,
      });
    }
    // reset completedOrders data on category list page or product page or search
    if (orderStore.completedOrders) {
      orderStore.resetCompletedOrders();
    }
  } catch (err) {
    $logger.error(err);
  }
</script>
