import { defineNuxtPlugin } from 'nuxt/app';
import { THROTTLE_TIMEOUT } from '~/awPlugins/app-config';
import { useUserInterfaceStore } from '~~/common/stores/userInterface';

export default defineNuxtPlugin(function (nuxtApp) {
  const { $pinia, $awEvents, $styleVariables } = nuxtApp;
  const userInterfaceStore = useUserInterfaceStore($pinia);
  let resizeThrottle = null;

  const sm = window.matchMedia(`(min-width: ${$styleVariables.breakpointSm})`);
  const md = window.matchMedia(`(min-width: ${$styleVariables.breakpointMd})`);
  const lg = window.matchMedia(`(min-width: ${$styleVariables.breakpointLg})`);
  const xl = window.matchMedia(`(min-width: ${$styleVariables.breakpointXl})`);

  const mobile = window.matchMedia(`(min-width: ${$styleVariables.breakpointMobile})`);
  const tablet = window.matchMedia(`(min-width: ${$styleVariables.breakpointTablet})`);
  const desktopSmall = window.matchMedia(`(min-width: ${$styleVariables.breakpointDesktopSmall})`);
  const desktopMedium = window.matchMedia(`(min-width: ${$styleVariables.breakpointDesktopMedium})`);
  const desktopLarge = window.matchMedia(`(min-width: ${$styleVariables.breakpointDesktopLarge})`);

  sm.addListener(setQueries);
  md.addListener(setQueries);
  lg.addListener(setQueries);
  xl.addListener(setQueries);

  mobile.addListener(setQueries);
  tablet.addListener(setQueries);
  desktopSmall.addListener(setQueries);
  desktopMedium.addListener(setQueries);
  desktopLarge.addListener(setQueries);

  window.addEventListener('resize', throttleResize);
  window.addEventListener('orientationchange', throttleResize);

  setQueries();

  function throttleResize () {
    clearTimeout(resizeThrottle);
    resizeThrottle = setTimeout(() => {
      $awEvents.triggerEvent('throttled-resize');
    }, THROTTLE_TIMEOUT);
  }

  function setQueries () {
    const smMin = sm.matches;
    const mdMin = md.matches;
    const lgMin = lg.matches;
    const xlMin = xl.matches;

    const mobileMin = mobile.matches;
    const tabletMin = tablet.matches;
    const desktopSmallMin = desktopSmall.matches;
    const desktopMediumMin = desktopMedium.matches;
    const desktopLargeMin = desktopLarge.matches;

    let query = 'xl';

    if (!smMin) {
      query = 'xs';
    } else if (!mdMin) {
      query = 'sm';
    } else if (!lgMin) {
      query = 'md';
    } else if (!xlMin) {
      query = 'lg';
    }

    userInterfaceStore.setMediaQueries({
      'sm-min': smMin,
      'md-min': mdMin,
      'lg-min': lgMin,
      'xl-min': xlMin,
      'xs-max': !smMin,
      'sm-max': !mdMin,
      'md-max': !lgMin,
      'lg-max': !xlMin,
      'mobile-min': mobileMin,
      'tablet-min': tabletMin,
      'desktop-small-min': desktopSmallMin,
      'desktop-medium-min': desktopMediumMin,
      'desktop-large-min': desktopLargeMin,
      'mobile-small-max': !mobileMin,
      'mobile-max': !tabletMin,
      'tablet-max': !desktopSmallMin,
      'desktop-small-max': !desktopMediumMin,
      'desktop-medium-max': !desktopLargeMin,
      query,
      initialized: true,
    });
  }
});
