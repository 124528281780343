import { useNuxtApp } from 'nuxt/app';
import { useOrderStore } from '~~/common/stores/order';
import { useAuthenticationStore } from '~~/common/stores/authentication';
import { useConfigStore } from '~~/common/stores/config';
import { useCheckoutStore } from '~~/common/stores/checkout';
import { useDeliveryStore } from '~~/shop/stores/delivery';

export async function clickLogout (sendNotificationToOtherTabs = true) {
  const configStore = useConfigStore();
  const authenticationStore = useAuthenticationStore();
  const deliveryStore = useDeliveryStore();
  const orderStore = useOrderStore();
  const checkoutStore = useCheckoutStore();
  const { isShop } = useNuxtApp().$awMergedConfig;

  if (configStore.isSalesForceLogin) {
    authenticationStore.setCustomerDiamondLogoutIframeKey();
  }
  await authenticationStore.logout();
  if (isShop) {
    await checkoutStore.fetchCheckout({
      type: 'FOOD',
      canStartSecondAction: true,
      canRunSimultaneous: true,
    });
  } else {
    checkoutStore.setCheckout({});
  }
  await orderStore.fetchOrder({
    id: 'current',
    canStartSecondAction: true,
    canRunSimultaneous: true,
    fetchFoodCheckout: false,
  });
  await orderStore.fetchOrderModification();

  if (isShop) {
    await deliveryStore.fetchMethod();
    await deliveryStore.fetchTimeframes();
  }

  if (sendNotificationToOtherTabs) {
    window.localStorage.setItem(
      'clearDataInAllTabs',
      Number(window.localStorage.getItem('clearDataInAllTabs')) + 1,
    );
  }
}
