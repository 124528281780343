import { useNuxtApp, useRuntimeConfig } from 'nuxt/app';
import { onBeforeUnmount } from 'vue';
import {
  genClickEventHandlers,
  PUSH_CLICK_BANNER,
  PUSH_VIEW_BANNER,
} from '~~/common/plugins/aw-analytics.js';
import { AnalyticsBannerInteraction, EMPTY_ANALYTICS_BANNER_INTERACTION } from '~~/common/utils/AnalyticsBannerInteraction';

export default {
  props: {
    analyticsBannerInteraction: {
      type: [Object, Symbol],
      default: null,
      validator: !AnalyticsBannerInteraction || ((val) => {
        return (val === EMPTY_ANALYTICS_BANNER_INTERACTION) || AnalyticsBannerInteraction.isValid(val);
      }),
    },
    analyticsMeasurable: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    genBannerInteractionClickEventHandlers () {
      return genBannerInteractionClickEventHandlers(this, ...arguments);
    },
    sendBannerInteractionAnalytics () {
      return sendBannerInteractionAnalytics(this, ...arguments);
    },
    sendBannerViewInteraction () {
      const config = useRuntimeConfig();
      this.sendBannerViewInteractionTimeout = setTimeout(() => {
        clearTimeout(this.sendBannerViewInteractionTimeout);
        sendBannerViewInteraction(this, ...arguments);
      }, (config.public.viewPromotionAnalyticsIntervalLength / 4));
    },
  },
  beforeUnmount () {
    clearTimeout(this.sendBannerViewInteractionTimeout);
  },
  data () {
    return {
      sendBannerViewInteractionTimeout: null,
    };
  },
};

export function genBannerInteractionClickEventHandlers () {
  try {
    return genClickEventHandlers(sendBannerInteractionAnalytics.bind(null, ...arguments));
  } catch (err) {
    const { $logger } = useNuxtApp();
    $logger.error(err);
    return null;
  }
}

export function sendBannerInteractionAnalytics (props) {
  const {
    $awAnalytics,
    $logger,
  } = useNuxtApp();
  try {
    if (!props.analyticsMeasurable) {
      return;
    }
    $awAnalytics[PUSH_CLICK_BANNER]({ analyticsBannerInteraction: props.analyticsBannerInteraction });
  } catch (err) {
    $logger.error(err);
  }
}

export function sendBannerViewInteraction (props, payload) {
  const {
    $awAnalytics,
    $logger,
  } = useNuxtApp();
  try {
    if (!props.analyticsMeasurable) {
      return;
    }
    const interaction = payload || props.analyticsBannerInteraction;
    $awAnalytics[PUSH_VIEW_BANNER]({ analyticsBannerInteraction: interaction });
  } catch (err) {
    $logger.error(err);
  }
}

export function createThrottledSendBannerViewInteraction () {
  const {
    $logger,
  } = useNuxtApp();
  try {
    const { public: { viewPromotionAnalyticsIntervalLength } } = useRuntimeConfig();
    let timeoutId = null;
    onBeforeUnmount(() => {
      clearTimeout(timeoutId);
    });
    return function () {
      timeoutId = setTimeout(
        () => {
          clearTimeout(timeoutId);
          sendBannerViewInteraction(...arguments);
        },
        viewPromotionAnalyticsIntervalLength / 4,
      );
    };
  } catch (err) {
    $logger.error(err);
  }
}
