export const EMPTY_ANALYTICS_BANNER_INTERACTION = Symbol('emptyAnalyticsBannerInteraction');

export const AnalyticsBannerInteraction = {
  create ({
    promotionName,
    promotionPageHref,
    promotionCompanyNo,
    promotionComponentId,
    promotionIndex,
    promotionBannerIndex,
  }: PromotionSchema): PromotionSchema {
    // full_carousel: 'teljes_szelessegu_carousel',
    return {
      promotionName,
      promotionPageHref,
      promotionCompanyNo,
      promotionComponentId,
      promotionIndex,
      promotionBannerIndex,
    };
  },
  isValid (o: PromotionSchema): o is PromotionSchema {
    try {
      const {
        promotionName,
        promotionPageHref,
        promotionComponentId,
        promotionIndex,
        promotionBannerIndex,
        promotionCompanyNo,
      } = o;
      return ((typeof promotionName === 'string' || promotionName === null) &&
        (typeof promotionPageHref === 'string') &&
        (typeof promotionCompanyNo === 'string' || promotionCompanyNo === null) &&
        (typeof promotionComponentId === 'string') &&
        (typeof promotionIndex === 'number') &&
        (typeof promotionBannerIndex === 'number')
      );
    } catch (err) {
      return false;
    }
  },
  format ({
    promotionName,
    promotionPageHref,
    promotionCompanyNo,
    promotionComponentId,
    promotionIndex,
    promotionBannerIndex,
  }: PromotionSchema) {
    return {
      promotion_id: (
        `[${
          escapeSpecialChars(promotionPageHref)
        }][${
          escapeSpecialChars(promotionCompanyNo || 'default')
        }][${
          escapeSpecialChars(promotionComponentId)
        }][${
          escapeSpecialChars(promotionIndex)
        }][${
          escapeSpecialChars(promotionBannerIndex)
        }]`
      ),
      promotion_name: promotionName || null,
    };
  },
};

function escapeSpecialChars (str: string | number) {
  return `${str}`.replace(/(\[|\])/g, '\\$1');
}

interface PromotionSchema {
  promotionName: string | null;
  promotionPageHref: string;
  promotionCompanyNo: string | null;
  promotionComponentId: string;
  promotionIndex: number;
  promotionBannerIndex: number;
}
