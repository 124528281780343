// Pinia Store
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useCookieConsentStore = defineStore('cookieConsent', () => {
  const agreement = ref(null);
  const marketing = ref(false);

  function setAgreement (payload) {
    agreement.value = payload;
  }

  function setMarketing (payload) {
    marketing.value = payload;
  }

  return {
    agreement,
    marketing,
    setAgreement,
    setMarketing,
  };
});
