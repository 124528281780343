import { defineNuxtPlugin, useNuxtApp } from 'nuxt/app';

const intlCache = {};

export default defineNuxtPlugin(function (nuxtApp) {
  const { $i18n } = nuxtApp;

  nuxtApp.provide('price', function (priceValue, currency, priceType = 'gross', intlOptions = {}) {
    return price($i18n.locale.value, priceValue, currency, priceType, intlOptions);
  });

  nuxtApp.provide('priceToNumber', function (priceValue, currency, intlOptions = {}) {
    return priceToNumber($i18n.locale.value, priceValue, currency, intlOptions);
  });

  nuxtApp.provide('formatPoints', function (opts = {}) {
    return formatPoints(opts);
  });
});

const forceHufFormat = currency => (currency === 'HUF') ? { minimumFractionDigits: 0, maximumFractionDigits: 0 } : {};

export function price (locale, priceValue, currency, priceType, intlOptions = {}) {
  const options = { style: 'currency', currency, ...forceHufFormat(currency), ...intlOptions };
  try {
    return new Intl.NumberFormat(locale, options).format(priceValue[priceType]);
  } catch (error) {
    return `${priceValue[priceType]}\xA0${currency}`;
  }
}

export function priceToNumber (locale, priceValue) {
  try {
    const intlSimple = new Intl.NumberFormat(locale);
    const DECIMAL = intlSimple.format(1.1).replace(/1/g, '');
    const REVERSED = Number(priceValue.match(new RegExp('(\\d|\\' + DECIMAL + ')+', 'g')).join('').replace(DECIMAL, '.'));
    return REVERSED;
  } catch (error) {
    return NaN;
  }
}

export function formatToLoyaltyPoints () {
  const { $awt } = useNuxtApp();
  return `${formatPoints(...arguments)}\xA0${$awt('aw.global.loyalty_point_currency')}`;
}

export function formatPoints ({
  number,
  pointChange = false,
  locale = useNuxtApp().$i18n.locale.value,
}) {
  const signDisplay = pointChange ? 'always' : 'auto';
  if (!intlCache[locale]) {
    intlCache[locale] = {};
  }
  if (!intlCache[locale].formatPoints) {
    intlCache[locale].formatPoints = {};
  }
  if (!intlCache[locale].formatPoints[signDisplay]) {
    intlCache[locale].formatPoints[signDisplay] = new Intl.NumberFormat(
      locale,
      {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        signDisplay,
      },
    );
  }
  return intlCache[locale].formatPoints[signDisplay].format(number);
}
