<template>
  <div :class="$style.mainContainer">
    <div v-if="$slots.label?.()?.[0]?.children?.length" :class="[{[$style.groupLabel]: $slots.label?.()?.[0]?.children?.length}, $style[labelSize]]">
      <slot name="label" />
    </div>
    <div v-else-if="label" :class="[$style.label, $style[labelSize]]" v-text="label" />
    <div
      :class="[
        $style[`rowSpace${rowSpace}`],
        $style[`columnSpace${columnSpace}`],
        {
          [$style.row]: row,
          [$style.column]: !row,
          [$style.rowWidthHack]: isWidthHacked,
          [$style.rowCentered]: centered,
          [$style.toggle]: toggle,
          [$style[toggleBackground]]: toggle,
          [childClasses]: childClasses,
        }]"
    >
      <slot />
    </div>
  </div>
</template>

<script>
  import { uuid4 } from '~~/common/utils';

  export default {
    name: 'AwRadioGroup',
    props: {
      modelValue: {
        type: [String, Number, Boolean],
        default: '',
      },
      label: {
        type: String,
        default: '',
      },
      labelSize: {
        type: String,
        default: 'lg',
        validator: value => ['xs', 'md', 'lg'].includes(value),
      },
      row: {
        type: Boolean,
        default: false,
      },
      isWidthHacked: {
        type: Boolean,
        default: false,
      },
      toggle: {
        type: Boolean,
        default: false,
      },
      centered: {
        type: Boolean,
        default: false,
      },
      toggleBackground: {
        type: String,
        default: 'grey',
      },
      rowSpace: {
        type: String,
        validator: value => ['none', 'md'].includes(value),
        default: 'none',
      },
      columnSpace: {
        type: String,
        validator: value => ['none', 'sm', 'md'].includes(value),
        default: 'sm',
      },
      childClasses: {
        type: [String, Array, Object, null],
        default: null,
      },
    },
    emits: [
      'update:modelValue',
    ],
    data () {
      const groupId = uuid4();
      return {
        groupId,
      };
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
.mainContainer {
  .groupLabel {
    margin: 8px 0;
  }

  .label,
  .groupLabel {
    &.xs {
      font-size: $font-size-lg;
    }

    &.md {
      font-size: $font-size-md;
    }

    &.lg {
      font-size: $font-size-xs;
    }
  }

  .row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-start;

    &WidthHack {
      position: relative;
      left: -25%;
      justify-content: center;
      min-width: 150%;
    }

    &Centered {
      flex-wrap: nowrap;
      justify-content: center;
      width: 100%;
    }

    &Spacenone {
      gap: 0;
    }

    &Spacemd {
      gap: 20px;

      @include tablet(min) {
        gap: 24px;
      }

      @include desktop-small(min) {
        gap: 32px;
      }

      @include desktop-medium(min) {
        gap: 40px;
      }
    }
  }

  .column {
    display: grid;
    align-items: center;
    flex-flow: column;
    flex-wrap: wrap;
    grid-template-columns: minmax(auto, 320px);

    &Space {
      &none {
        gap: 0;
      }

      &sm {
        gap: 16px;
      }

      &md {
        gap: 20px;
      }
    }
  }

  .toggle {
    flex-wrap: nowrap;
    padding: 2px;
    border-radius: 24px;

    &.grey {
      background-color: $color-mercury-alt;
    }

    &.white {
      background-color: $color-white;
    }
  }
}
</style>
