// todo move appconfig to common

import { defineNuxtPlugin, useRuntimeConfig } from 'nuxt/app';
import StripTypeConfig from '../config/StripTypeConfig';
import BannerTypeConfig from '../config/BannerTypeConfig';
import PhoneNumberConfig from '../config/PhoneNumberConfig';
import AuchanShopAreaSprite from '~~/shop/assets/icons/auchan-shop-area.svg';
import defaultImage from '~~/common/assets/images/shop-entity-default.jpg';
import SimpleWidgetTypeConfig from '~~/common/config/SimpleWidgetTypeConfig';
import { useNameOrderConfig } from '~~/shop/config/NameOrderConfig';
import { useUserInterfaceStore } from '~~/common/stores/userInterface';

import markerImage from '~~/common/assets/images/auchan_A_marker.svg';

const THROTTLE_TIMEOUT = 400;
const URL_PREFIX = '/shop';
const IS_SHOP = true;

const RESOURCE_TYPES = {
  STORE: 'store',
  PRODUCT: 'product',
  VARIANT: 'variant',
  COLLECTION: 'collection',
  CATEGORY: 'category',
  CMS_CONTENT: 'cms_content',
};

const DELIVERY_AREA_MODEL = {
  PUBLIC_AREA: 'public_area',
  PICK_UP_FROM_STORE: 'department_store',
};

const DELIVERY_METHODS = {
  DELIVERY_CLICK_AND_COLLECT: 'delivery_click_and_collect',
  GLS_PICKUP: 'delivery_gls_pickup',
  GLS_LOCKER: 'delivery_gls_locker',
  DELIVERY_ADDRESS: 'delivery_address',
  DELIVERY_FOXPOST_LOCKER: 'delivery_foxpost_locker',
  DELIVERY_PICKUP_OR_LOCKER: 'delivery_pickup_or_locker',
  DELIVERY_PICKUP: 'delivery_pickup',
  DELIVERY_LOCKER: 'delivery_locker',
};

const PAYMENT_METHODS = {
  ONSITE: 'onsite',
  ONLINE_CREDIT_CARD: 'onlineCreditCard',
  ONLINE: 'online',
  CREDIT: 'credit',
};

const PAYMENT_STATUSES = {
  PAYMENT_STATUS_OFFLINE: 'offline',
  PAYMENT_STATUS_PAID: 'paid',
  PAYMENT_STATUS_IN_PROGRESS: 'inProgress',
  PAYMENT_STATUS_FAILED: 'failed',
};

const YOUTUBE_IMAGE_URL_PREFIX = 'https://img.youtube.com/vi/';

const YOUTUBE_IMAGE_VARIATIONS = ['/maxresdefault.jpg'];

const COUNTRY_CODE_LIST = ['AD', 'AE', 'AF', 'AG', 'AI', 'AL', 'AM', 'AO', 'AQ', 'AR', 'AS', 'AT', 'AU', 'AW', 'AX', 'AZ', 'BA', 'BB', 'BD', 'BE', 'BF', 'BG', 'BH', 'BI', 'BJ', 'BL', 'BM', 'BN', 'BO', 'BQ', 'BR', 'BS', 'BT', 'BV', 'BW', 'BY', 'BZ', 'CA', 'CC', 'CD', 'CF', 'CG', 'CH', 'CI', 'CK', 'CL', 'CM', 'CN', 'CO', 'CR', 'CU', 'CV', 'CW', 'CX', 'CY', 'CZ', 'DE', 'DJ', 'DK', 'DM', 'DO', 'DZ', 'EC', 'EE', 'EG', 'EH', 'ER', 'ES', 'ET', 'FI', 'FJ', 'FK', 'FM', 'FO', 'FR', 'GA', 'GB', 'GD', 'GE', 'GF', 'GG', 'GH', 'GI', 'GL', 'GM', 'GN', 'GP', 'GQ', 'GR', 'GS', 'GT', 'GU', 'GW', 'GY', 'HK', 'HM', 'HN', 'HR', 'HT', 'HU', 'ID', 'IE', 'IL', 'IM', 'IN', 'IO', 'IQ', 'IR', 'IS', 'IT', 'JE', 'JM', 'JO', 'JP', 'KE', 'KG', 'KH', 'KI', 'KM', 'KN', 'KP', 'KR', 'KW', 'KY', 'KZ', 'LA', 'LB', 'LC', 'LI', 'LK', 'LR', 'LS', 'LT', 'LU', 'LV', 'LY', 'MA', 'MC', 'MD', 'ME', 'MF', 'MG', 'MH', 'MK', 'ML', 'MM', 'MN', 'MO', 'MP', 'MQ', 'MR', 'MS', 'MT', 'MU', 'MV', 'MW', 'MX', 'MY', 'MZ', 'NA', 'NC', 'NE', 'NF', 'NG', 'NI', 'NL', 'NO', 'NP', 'NR', 'NU', 'NZ', 'OM', 'PA', 'PE', 'PF', 'PG', 'PH', 'PK', 'PL', 'PM', 'PN', 'PR', 'PS', 'PT', 'PW', 'PY', 'QA', 'RE', 'RO', 'RS', 'RU', 'RW', 'SA', 'SB', 'SC', 'SD', 'SE', 'SG', 'SH', 'SI', 'SJ', 'SK', 'SL', 'SM', 'SN', 'SO', 'SR', 'SS', 'ST', 'SV', 'SX', 'SY', 'SZ', 'TC', 'TD', 'TF', 'TG', 'TH', 'TJ', 'TK', 'TL', 'TM', 'TN', 'TO', 'TR', 'TT', 'TV', 'TW', 'TZ', 'UA', 'UG', 'UM', 'US', 'UY', 'UZ', 'VA', 'VC', 'VE', 'VG', 'VI', 'VN', 'VU', 'WF', 'WS', 'YE', 'YT', 'ZA', 'ZM', 'ZW'];

const DEFAULT_RANGE_FILTER_NUMBER_LIMIT = 15;

// input components size for: common/mixins/addressFormMixin.js
const INPUT_SIZE = 'lg';
const INPUT_LABEL_PLACE_OUTER = false;

const CMS_PAGE_MENU_WRAPPER_COMPONENTS_MAP = {
  'career-menu': 'aw-career-menu',
  'petrol-menu': 'aw-petrol-menu',
  'loyalty-program-menu': 'aw-loyalty-program-menu',
};

export default defineNuxtPlugin((nuxtApp) => {
  const { $pinia } = nuxtApp;
  const config = useRuntimeConfig();
  const osmFullUrl = config.public.osmFullUrl;
  const userInterfaceStore = useUserInterfaceStore($pinia);
  const appConfig = {
    getDefaultCountryCode () {
      return 'HU';
    },
    NameOrderConfig: useNameOrderConfig(),
    StripTypeConfig,
    BannerTypeConfig,
    PhoneNumberConfig,
    ModalConfig: {
      closeIconAttrs: {
        name: 'times-16',
        size: 16,
      },
    },
    SimpleWidgetTypeConfig,
    defaultImage,
    AuchanShopAreaSprite,
    $mediaQueries (query) {
      if (query) {
        return userInterfaceStore.mediaQueries[query];
      } else {
        return userInterfaceStore.mediaQueries;
      }
    },
    OSMConfig: {
      tileLayer: [
        osmFullUrl,
        {
          attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        },
      ],
      icon: {
        iconUrl: markerImage,
        iconSize: [52, 64],
        iconAnchor: [26, 64],
        popupAnchor: [0, -64],
      },
    },
  };

  nuxtApp.provide('appConfig', appConfig);
});

export {
  IS_SHOP,
  COUNTRY_CODE_LIST,
  THROTTLE_TIMEOUT,
  YOUTUBE_IMAGE_URL_PREFIX,
  YOUTUBE_IMAGE_VARIATIONS,
  RESOURCE_TYPES,
  URL_PREFIX,
  DELIVERY_AREA_MODEL,
  DELIVERY_METHODS,
  PAYMENT_METHODS,
  INPUT_SIZE,
  INPUT_LABEL_PLACE_OUTER,
  DEFAULT_RANGE_FILTER_NUMBER_LIMIT,
  PAYMENT_STATUSES,
  CMS_PAGE_MENU_WRAPPER_COMPONENTS_MAP,
};
