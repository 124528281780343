<template>
  <div :class="[$style.card, {[$style.cardHasMobileNegativeMargin]: mobileNegativeMargin}]">
    <div v-if="isHead" :class="$style.cardHead">
      <slot name="head" />
      <aw-separator />
    </div>
    <slot />
  </div>
</template>

<script>
  import AwSeparator from '~~/common/components/Common/AwSeparator.vue';
  import { computed } from 'vue';

  export default {
    name: 'AwCmsWhiteCard',
    components: {
      AwSeparator,
    },
    props: {
      mobileNegativeMargin: {
        type: Boolean,
        default: false,
      },
    },
    setup (props, context) {
      const isHead = computed(() => {
        return !!context.slots?.head;
      });

      return {
        isHead,
      };
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
.card {
  padding: 16px;
  border-radius: 32px;
  background-color: white;

  &HasMobileNegativeMargin {
    @include mobile(max) {
      margin-right: -16px;
      margin-left: -16px;
    }
  }

  @include tablet(min) {
    padding: 40px 24px;
  }
  @include desktop-small(min) {
    padding: 40px;
  }
}
</style>
