import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { useNuxtApp } from 'nuxt/app';
import {
  M_ALERT,
  M_BARCODE,
  M_BASKET_ITEM_REMOVE,
  M_CART_MERGE,
  M_CHANGE_PWD,
  M_CHECKOUT_ERROR_MODAL,
  M_COMPARE_CAT,
  M_CONFIRM_USER_PWD,
  M_DELETE_ORDER,
  M_DELIVERY_METHOD,
  M_DELIVERY_TIMEFRAME_EXPIRED,
  M_EDIT_ORDER,
  M_GDPR,
  M_LIST_SEARCH,
  M_LOG_AND_REG,
  M_MAX_ITEM_WARNING,
  M_PROF_SAVED_BASKETS,
  M_PROFILE,
  M_QUEUE_SAFE_CONFIRM,
  M_START_POPUP,
  M_STATIC_CONTENT,
  M_WARNING,
} from '~~/common/config/Modal.js';

function useLogger () {
  try {
    const $logger = useNuxtApp().$logger;
    if ($logger?.error) {
      return $logger;
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
  return console;
}

function ModalQueueItem ({
  usePromise = true,
  show,
  data,
  closable,
}) {
  this.show = show;
  this.data = data;
  this.closable = closable;
  this.res = null;
  this.promise = (usePromise
    ? new Promise((resolve) => {
      this.res = (name) => {
        resolve({
          name,
          message: data.resolveOption,
        });
      };
    })
    : null
  );
  if (import.meta.server) {
    return { ...this };
  }
}

export const ctorO = {
  show: true,
  data: {},
  closable: true,
};
export const mFilter = (q, type) => {
  return q.filter((qItem) => {
    if (qItem.type === type) {
      qItem.item.show = false;
      if (qItem.item.res) {
        qItem.item.res();
      }
      return false;
    } else {
      return true;
    }
  });
};
const mRemoveModal = (q, modal) => {
  const i = q.findIndex(m => m === modal);
  if (i !== -1) {
    q.splice(i, 1).forEach((qItem) => {
      qItem.item.show = false;
      if (qItem.item.res) {
        qItem.item.res();
      }
    });
  }
};
export const mAddOrReplace = (q, type, item) => {
  const i = q.findIndex(qi => qi.type === type);
  if (i === -1) {
    q.push({
      type,
      item,
    });
  } else {
    q.splice(i, 1, {
      type,
      item,
    }).forEach((qi) => {
      qi.item.show = false;
      if (qi.item.res) {
        qi.item.res();
      }
    });
  }
};

export const useModalStore = defineStore('modal', () => {
  const modals = ref({
    confirmModal: {
      show: false,
      backgroundBlured: false,
      confirmOk: {
        type: false,
      },
      confirmCancel: {
        type: false,
      },
      data: {},
      closable: false,
    },
    alertModal: {
      alertButtonLoginOpenClick: {
        value: false,
        backgroundBlured: false,
      },
      laterOpen: false,
    },
    genericModalQueue: [],
    apiConfirmModal: {
      apiConfirmModalQueue: [],
    },
  });
  const sureAddToCartModal = ref({
    isOpen: false,
    productId: null,
    forbiddenSelections: [],
    selection: null,
  });
  const savedAddToBasketData = ref(null);

  function addQueueSafeConfirmModal ({
    uuid,
    data,
  } = {}) {
    if (typeof uuid !== 'string' || !uuid) {
      const $logger = useLogger();
      $logger.error('Missing uuid for modal.');
      return;
    }
    const { genericModalQueue } = modals.value;
    mAddOrReplace(genericModalQueue, M_QUEUE_SAFE_CONFIRM + uuid, new ModalQueueItem({ ...ctorO, ...data }));
  }

  function removeQueueSafeConfirmModal ({ uuid }) {
    if (typeof uuid !== 'string' || !uuid) {
      const $logger = useLogger();
      $logger.error('Missing uuid for modal.');
      return;
    }
    modals.value.genericModalQueue = mFilter(modals.value.genericModalQueue, M_QUEUE_SAFE_CONFIRM + uuid, M_QUEUE_SAFE_CONFIRM);
  }

  function showFavModal ({ modalType }) {
    const { genericModalQueue } = modals.value;
    mAddOrReplace(genericModalQueue, modalType, new ModalQueueItem(ctorO));
  }

  function hideFavModal ({ modalType }) {
    modals.value.genericModalQueue = mFilter(modals.value.genericModalQueue, modalType);
  }

  function showProdGallery ({ modalType }) {
    const { genericModalQueue } = modals.value;
    mAddOrReplace(genericModalQueue, modalType, new ModalQueueItem(ctorO));
  }

  function hideProdGallery ({ modalType }) {
    modals.value.genericModalQueue = mFilter(modals.value.genericModalQueue, modalType);
  }

  function showFullVideoCardsModal ({ modalType }) {
    const { genericModalQueue } = modals.value;
    mAddOrReplace(genericModalQueue, modalType, new ModalQueueItem(ctorO));
  }

  function hideFullVideoCardsModal ({ modalType }) {
    modals.value.genericModalQueue = mFilter(modals.value.genericModalQueue, modalType);
  }

  function showVideoCardModal ({ modalType }) {
    const { genericModalQueue } = modals.value;
    mAddOrReplace(genericModalQueue, modalType, new ModalQueueItem(ctorO));
  }

  function hideVideoCardModal ({ modalType }) {
    modals.value.genericModalQueue = mFilter(modals.value.genericModalQueue, modalType);
  }

  function showCareerRegistrationModal ({ modalType, isEcomUserExtended }) {
    const { genericModalQueue } = modals.value;
    mAddOrReplace(genericModalQueue, modalType, new ModalQueueItem({ ...ctorO, data: { isEcomUserExtended } }));
  }

  function hideCareerRegistrationModal ({ modalType }) {
    modals.value.genericModalQueue = mFilter(modals.value.genericModalQueue, modalType);
  }

  function showCareerRecommendationToAcquaintanceModal ({ modalType }) {
    const { genericModalQueue } = modals.value;
    mAddOrReplace(genericModalQueue, modalType, new ModalQueueItem(ctorO));
  }

  function hideCareerRecommendationToAcquaintanceModal ({ modalType }) {
    modals.value.genericModalQueue = mFilter(modals.value.genericModalQueue, modalType);
  }

  function showProdOld ({ modalType }) {
    const { genericModalQueue } = modals.value;
    mAddOrReplace(genericModalQueue, modalType, new ModalQueueItem({
      ...ctorO,
      closable: false,
    }));
  }

  function hideProdOld ({ modalType }) {
    modals.value.genericModalQueue = mFilter(modals.value.genericModalQueue, modalType);
  }

  function showFilterModal ({ modalType }) {
    const { genericModalQueue } = modals.value;
    mAddOrReplace(genericModalQueue, modalType, new ModalQueueItem(ctorO));
  }

  function hideFilterModal ({ modalType }) {
    modals.value.genericModalQueue = mFilter(modals.value.genericModalQueue, modalType);
  }

  function showBasketItemRemoveModal ({ id }) {
    const { genericModalQueue } = modals.value;
    genericModalQueue.push({
      type: M_BASKET_ITEM_REMOVE + id,
      item: new ModalQueueItem(ctorO),
    });
  }

  function hideBasketItemRemoveModal ({ id }) {
    modals.value.genericModalQueue = mFilter(modals.value.genericModalQueue, M_BASKET_ITEM_REMOVE + id);
  }

  function showLoginAndRegistrationModal ({ customClose } = {}) {
    const termsContentShow = false; // Currently this is always false.
    const { genericModalQueue } = modals.value;
    mAddOrReplace(genericModalQueue, M_LOG_AND_REG, new ModalQueueItem({
      ...ctorO,
      closable: !termsContentShow,
      data: {
        customClose,
        termsContentShow,
      },
    }));
  }

  function hideLoginAndRegistrationModal () {
    modals.value.genericModalQueue = mFilter(modals.value.genericModalQueue, M_LOG_AND_REG);
  }

  function showBarcodeModal () {
    const { genericModalQueue } = modals.value;
    mAddOrReplace(genericModalQueue, M_BARCODE, new ModalQueueItem(ctorO));
  }

  function hideBarcodeModal () {
    modals.value.genericModalQueue = mFilter(modals.value.genericModalQueue, M_BARCODE);
  }

  function showListSearchModal () {
    const { genericModalQueue } = modals.value;
    mAddOrReplace(genericModalQueue, M_LIST_SEARCH, new ModalQueueItem(ctorO));
  }

  function hideListSearchModal () {
    modals.value.genericModalQueue = mFilter(modals.value.genericModalQueue, M_LIST_SEARCH);
  }

  function showCompareCategoryConfirmModal (productData) {
    const { genericModalQueue } = modals.value;
    mAddOrReplace(genericModalQueue, M_COMPARE_CAT, new ModalQueueItem({
      ...ctorO,
      data: { productData },
    }));
  }

  function hideCompareCategoryConfirmModal () {
    modals.value.genericModalQueue = mFilter(modals.value.genericModalQueue, M_COMPARE_CAT);
  }

  /* See: 8cbe590a9dd6f5340ca8685fbfcc22164bf966dd
  showProductStockNotificationRequestModal ( productData) {
    const { genericModalQueue } = modals.value;
    mAddOrReplace(genericModalQueue, M_PROD_STOCK, new ModalQueueItem({ ...ctorO, data: { productData } }));
  },
  hideProductStockNotificationRequestModal () {

    modals.value.genericModalQueue = mFilter(modals.value.genericModalQueue, M_PROD_STOCK);
  }, */
  function showMaxItemWarningModal () {
    const { genericModalQueue } = modals.value;
    mAddOrReplace(genericModalQueue, M_MAX_ITEM_WARNING, new ModalQueueItem(ctorO));
  }

  function hideMaxItemWarningModal () {
    modals.value.genericModalQueue = mFilter(modals.value.genericModalQueue, M_MAX_ITEM_WARNING);
  }

  function showConfirmModal ({
    data,
    closable,
    backgroundBlured,
  }) {
    modals.value.confirmModal.backgroundBlured = backgroundBlured;
    modals.value.confirmModal.confirmOk.type = false;
    modals.value.confirmModal.confirmCancel.type = false;
    modals.value.confirmModal.show = true;
    modals.value.confirmModal.data = data;
    modals.value.confirmModal.closable = closable;
  }

  function setConfirmOk (payload) {
    modals.value.confirmModal.confirmOk.type = payload;
  }

  function resetConfirmOk () {
    modals.value.confirmModal.confirmOk.type = false;
  }

  function setConfirmCancel (payload) {
    modals.value.confirmModal.confirmCancel.type = payload;
  }

  function resetConfirmCancel () {
    modals.value.confirmModal.confirmCancel.type = false;
  }

  function hideConfirmModal () {
    modals.value.confirmModal.show = false;
    modals.value.confirmModal.data = {};
  }

  function showAlertModal ({
    data,
    closable,
    backgroundBlured,
  }) {
    modals.value.genericModalQueue.push({
      type: M_ALERT,
      item: new ModalQueueItem({
        ...ctorO,
        usePromise: false,
        closable,
        data: {
          ...data,
          backgroundBlured,
        },
      }),
    });
  }

  function hideAlertModal (modal) {
    mRemoveModal(modals.value.genericModalQueue, modal);
  }

  function setAlertModalButtonLoginOpenClick ({
    value,
    backgroundBlured,
  }) {
    modals.value.alertModal.alertButtonLoginOpenClick = {
      value,
      backgroundBlured,
    };
  }

  function resetAlertModalButtonLoginOpenClick () {
    modals.value.alertModal.alertButtonLoginOpenClick = {
      value: false,
      backgroundBlured: false,
    };
  }

  function setAlertModalLaterOpen () {
    modals.value.alertModal.laterOpen = true;
  }

  function resetAlertModalLaterOpen () {
    modals.value.alertModal.laterOpen = false;
  }

  function showApiConfirmModal ({
    data,
    closable,
  }) {
    modals.value.apiConfirmModal.apiConfirmModalQueue.push(new ModalQueueItem({
      show: true,
      data,
      closable,
    }));
  }

  function resolveApiConfirmModal (acceptance) {
    modals.value.apiConfirmModal.apiConfirmModalQueue.shift()?.res?.(acceptance);
  }

  function showConfirmUserPasswordModal () {
    const { genericModalQueue } = modals.value;
    mAddOrReplace(genericModalQueue, M_CONFIRM_USER_PWD, new ModalQueueItem(ctorO));
  }

  function hideConfirmUserPasswordModal () {
    modals.value.genericModalQueue = mFilter(modals.value.genericModalQueue, M_CONFIRM_USER_PWD);
  }

  function openSureAddToCartModal () {
    sureAddToCartModal.value.isOpen = true;
  }

  function closeSureAddToCartModal () {
    sureAddToCartModal.value.isOpen = false;
  }

  function setSureAddToCartModalProduct (productId) {
    sureAddToCartModal.value.productId = productId;
  }

  function setSureAddToCartModalSelection (payload) {
    sureAddToCartModal.value.selection = payload;
  }

  function setSureAddToCartModalForbiddenSelection (payload) {
    if (!Array.isArray(payload)) {
      throw new TypeError(`forbiddenSelections has to be an array ${payload} received.`);
    }
    sureAddToCartModal.value.forbiddenSelections = payload;
  }

  function showSavedBasketsModal (payload, viewMode) {
    const { genericModalQueue } = modals.value;
    mAddOrReplace(genericModalQueue, M_PROF_SAVED_BASKETS, new ModalQueueItem({
      ...ctorO,
      data: {
        ...payload,
        viewMode,
      },
    }));
  }

  function hideSavedBasketsModal () {
    modals.value.genericModalQueue = mFilter(modals.value.genericModalQueue, M_PROF_SAVED_BASKETS);
  }

  function showEditOrderModal () {
    const { genericModalQueue } = modals.value;
    mAddOrReplace(genericModalQueue, M_EDIT_ORDER, new ModalQueueItem(ctorO));
  }

  function hideEditOrderModal () {
    modals.value.genericModalQueue = mFilter(modals.value.genericModalQueue, M_EDIT_ORDER);
  }

  function showDeleteOrderModal () {
    const { genericModalQueue } = modals.value;
    mAddOrReplace(genericModalQueue, M_DELETE_ORDER, new ModalQueueItem(ctorO));
  }

  function hideDeleteOrderModal () {
    modals.value.genericModalQueue = mFilter(modals.value.genericModalQueue, M_DELETE_ORDER);
  }

  function showCheckoutSaveAddrModal ({ modalType }) {
    const { genericModalQueue } = modals.value;
    mAddOrReplace(genericModalQueue, modalType, new ModalQueueItem(ctorO));
  }

  function hideCheckoutSaveAddrModal ({ modalType }) {
    modals.value.genericModalQueue = mFilter(modals.value.genericModalQueue, modalType);
  }

  function showCheckoutErrorModal () {
    const { genericModalQueue } = modals.value;
    mAddOrReplace(genericModalQueue, M_CHECKOUT_ERROR_MODAL, new ModalQueueItem(ctorO));
  }

  function hideCheckoutErrorModal () {
    modals.value.genericModalQueue = mFilter(modals.value.genericModalQueue, M_CHECKOUT_ERROR_MODAL);
  }

  function showChangePwdModal () {
    const { genericModalQueue } = modals.value;
    mAddOrReplace(genericModalQueue, M_CHANGE_PWD, new ModalQueueItem(ctorO));
  }

  function hideChangePwdModal () {
    modals.value.genericModalQueue = mFilter(modals.value.genericModalQueue, M_CHANGE_PWD);
  }

  function openDeliveryMethodModal (payload) {
    mAddOrReplace(modals.value.genericModalQueue, M_DELIVERY_METHOD, new ModalQueueItem({
      ...ctorO,
      data: {
        step: payload?.step,
        onlyTimeframes: payload?.onlyTimeframes,
      },
    }));
    savedAddToBasketData.value = payload?.cartData;
  }

  function resetAddToBasketData () {
    savedAddToBasketData.value = null;
  }

  function closeDeliveryMethodModal () {
    modals.value.genericModalQueue = mFilter(modals.value.genericModalQueue, M_DELIVERY_METHOD);
    savedAddToBasketData.value = null;
  }

  function openExpireTimeFrameModal () {
    const { genericModalQueue } = modals.value;
    mAddOrReplace(genericModalQueue, M_DELIVERY_TIMEFRAME_EXPIRED, new ModalQueueItem(ctorO));
  }

  function closeExpireTimeFrameModal () {
    modals.value.genericModalQueue = mFilter(modals.value.genericModalQueue, M_DELIVERY_TIMEFRAME_EXPIRED);
  }

  function openGdprAcceptanceModal () {
    const { genericModalQueue } = modals.value;
    mAddOrReplace(genericModalQueue, M_GDPR, new ModalQueueItem({
      ...ctorO,
      closable: false,
      usePromise: false,
    }));
  }

  function closeGdprAcceptanceModal () {
    modals.value.genericModalQueue = mFilter(modals.value.genericModalQueue, M_GDPR);
  }

  function openStaticContentModal (payload) {
    const { genericModalQueue } = modals.value;
    genericModalQueue.unshift({
      type: M_STATIC_CONTENT,
      item: new ModalQueueItem({
        ...ctorO,
        data: { staticContentType: payload },
      }),
    });
  }

  function closeStaticContentModal () {
    modals.value.genericModalQueue = mFilter(modals.value.genericModalQueue, M_STATIC_CONTENT);
  }

  function openProfileModal () {
    const { genericModalQueue } = modals.value;
    mAddOrReplace(genericModalQueue, M_PROFILE, new ModalQueueItem({
      ...ctorO,
      closable: false,
    }));
  }

  function closeProfileModal () {
    modals.value.genericModalQueue = mFilter(modals.value.genericModalQueue, M_PROFILE);
  }

  function openCartMergeModal () {
    const { genericModalQueue } = modals.value;
    mAddOrReplace(genericModalQueue, M_CART_MERGE, new ModalQueueItem({
      ...ctorO,
      closable: false,
    }));
  }

  function closeCartMergeModal () {
    modals.value.genericModalQueue = mFilter(modals.value.genericModalQueue, M_CART_MERGE);
  }

  function openStartupPopup () {
    const { genericModalQueue } = modals.value;
    mAddOrReplace(genericModalQueue, M_START_POPUP, new ModalQueueItem(ctorO));
  }

  function closeStartupPopup () {
    modals.value.genericModalQueue = mFilter(modals.value.genericModalQueue, M_START_POPUP);
  }

  function showCouponDetails ({
    modalType,
    payload,
  }) {
    const { genericModalQueue } = modals.value;
    mAddOrReplace(genericModalQueue, modalType, new ModalQueueItem({
      ...ctorO,
      data: payload,
    }));
  }

  function hideCouponDetails ({ modalType }) {
    modals.value.genericModalQueue = mFilter(modals.value.genericModalQueue, modalType);
  }

  function showCmsContentModalModal ({ modalType }) {
    const { genericModalQueue } = modals.value;
    mAddOrReplace(genericModalQueue, modalType, new ModalQueueItem(ctorO));
  }

  function hideCmsContentModal ({ modalType }) {
    modals.value.genericModalQueue = mFilter(modals.value.genericModalQueue, modalType);
  }

  function showReplacementModal ({ modalType }) {
    const { genericModalQueue } = modals.value;
    mAddOrReplace(genericModalQueue, modalType, new ModalQueueItem(ctorO));
  }

  function hideReplacementModal ({ modalType }) {
    modals.value.genericModalQueue = mFilter(modals.value.genericModalQueue, modalType);
  }

  function showWarningModal () {
    const { genericModalQueue } = modals.value;
    mAddOrReplace(genericModalQueue, M_WARNING, new ModalQueueItem(ctorO));
  }

  function hideWarningModal () {
    modals.value.genericModalQueue = mFilter(modals.value.genericModalQueue, M_WARNING);
  }

  function showInfoModal ({ modalType }) {
    const { genericModalQueue } = modals.value;
    mAddOrReplace(genericModalQueue, modalType, new ModalQueueItem(ctorO));
  }

  function hideInfoModal ({ modalType }) {
    modals.value.genericModalQueue = mFilter(modals.value.genericModalQueue, modalType);
  }

  const activeApiConfirmModal = computed(() => {
    return modals.value.apiConfirmModal.apiConfirmModalQueue[0] || {
      show: false,
      data: {},
      closable: false,
      promise: null,
    };
  });
  const lastApiConfirmModal = computed(() => {
    return modals.value.apiConfirmModal.apiConfirmModalQueue[modals.value.apiConfirmModal.apiConfirmModalQueue.length - 1] || {
      show: false,
      data: {},
      closable: false,
      promise: null,
    };
  });
  const activeGenericModal = computed(() => {
    return modals.value.genericModalQueue[0] || {
      type: null,
      item: {
        show: false,
        data: {},
        closable: false,
        promise: null,
      },
    };
  });

  let modalUnSub;

  /* showConfirmModalWithCallback is only tested on client side */
  function showConfirmModalWithCallback ({
    onConfirmClose,
    confirmModalConfig,
    description = '',
  }) {
    const $logger = useLogger();
    if (typeof onConfirmClose !== 'function') {
      $logger.error(new TypeError('typeof onConfirmClose is not function but ${typeof onConfirmClose}'));
    }
    if (typeof confirmModalConfig !== 'object') {
      $logger.error(new TypeError('typeof confirmModalConfig is not object but ${typeof confirmModalConfig}'));
    }
    const {
      modals,
      showConfirmModal,
    } = useModalStore();
    const CONF_OK = Symbol(description + '_ok');
    const CONF_CANCEL = Symbol(description + '_cancel');
    confirmModalConfig.data.confirmOkActionType = CONF_OK;
    confirmModalConfig.data.confirmCancelActionType = CONF_CANCEL;
    showConfirmModal(confirmModalConfig);
    if (!modalUnSub) {
      modalUnSub = () => {
      };
    }
    modalUnSub();
    const unSub = watch(
      () => {
        const {
          confirmOk,
          confirmCancel,
        } = modals.confirmModal;
        return (confirmOk.type === CONF_OK
          ? CONF_OK
          : confirmCancel.type === CONF_CANCEL
            ? CONF_CANCEL
            : false
        );
      },
      (newVal) => {
        if ([CONF_OK, CONF_CANCEL].includes(newVal)) {
          modalUnSub();
          onConfirmClose(newVal === CONF_OK);
        }
      },
    );
    modalUnSub = () => {
      modalUnSub = () => {
      };
      unSub();
    };
  }

  return {
    modals,
    sureAddToCartModal,
    savedAddToBasketData,
    addQueueSafeConfirmModal,
    removeQueueSafeConfirmModal,
    showFavModal,
    hideFavModal,
    showProdGallery,
    hideProdGallery,
    showFullVideoCardsModal,
    hideFullVideoCardsModal,
    showVideoCardModal,
    hideVideoCardModal,
    showProdOld,
    hideProdOld,
    showFilterModal,
    hideFilterModal,
    showBasketItemRemoveModal,
    hideBasketItemRemoveModal,
    showLoginAndRegistrationModal,
    hideLoginAndRegistrationModal,
    showBarcodeModal,
    hideBarcodeModal,
    showListSearchModal,
    hideListSearchModal,
    showCompareCategoryConfirmModal,
    hideCompareCategoryConfirmModal,
    showMaxItemWarningModal,
    hideMaxItemWarningModal,
    showConfirmModal,
    setConfirmOk,
    resetConfirmOk,
    setConfirmCancel,
    resetConfirmCancel,
    hideConfirmModal,
    showAlertModal,
    hideAlertModal,
    setAlertModalButtonLoginOpenClick,
    resetAlertModalButtonLoginOpenClick,
    setAlertModalLaterOpen,
    resetAlertModalLaterOpen,
    showApiConfirmModal,
    resolveApiConfirmModal,
    showConfirmUserPasswordModal,
    hideConfirmUserPasswordModal,
    openSureAddToCartModal,
    closeSureAddToCartModal,
    setSureAddToCartModalProduct,
    setSureAddToCartModalSelection,
    setSureAddToCartModalForbiddenSelection,
    showSavedBasketsModal,
    hideSavedBasketsModal,
    showEditOrderModal,
    hideEditOrderModal,
    showDeleteOrderModal,
    hideDeleteOrderModal,
    showCheckoutSaveAddrModal,
    hideCheckoutSaveAddrModal,
    showCheckoutErrorModal,
    hideCheckoutErrorModal,
    showChangePwdModal,
    hideChangePwdModal,
    openDeliveryMethodModal,
    resetAddToBasketData,
    closeDeliveryMethodModal,
    openExpireTimeFrameModal,
    closeExpireTimeFrameModal,
    openGdprAcceptanceModal,
    closeGdprAcceptanceModal,
    openStaticContentModal,
    closeStaticContentModal,
    openProfileModal,
    closeProfileModal,
    openCartMergeModal,
    closeCartMergeModal,
    openStartupPopup,
    closeStartupPopup,
    showCouponDetails,
    hideCouponDetails,
    showCmsContentModalModal,
    hideCmsContentModal,
    showCareerRegistrationModal,
    hideCareerRegistrationModal,
    showCareerRecommendationToAcquaintanceModal,
    hideCareerRecommendationToAcquaintanceModal,
    showReplacementModal,
    hideReplacementModal,
    activeApiConfirmModal,
    lastApiConfirmModal,
    activeGenericModal,
    showConfirmModalWithCallback,
    showWarningModal,
    hideWarningModal,
    showInfoModal,
    hideInfoModal,
  };
});
