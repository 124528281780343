import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useMediaHelperStore = defineStore('mediaHelper', () => {
  const isWebpSupportedInBrowser = ref(true);

  function setIsWebpSupportedInBrowser (payload) {
    if (isWebpSupportedInBrowser.value !== payload) {
      isWebpSupportedInBrowser.value = payload;
    }
  }
  return {
    isWebpSupportedInBrowser,
    setIsWebpSupportedInBrowser,
  };
});
