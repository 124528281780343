import { ref } from 'vue';
import { defineStore } from 'pinia';
import { useNuxtApp, useRuntimeConfig } from 'nuxt/app';
import { uuid4 } from '~~/common/utils';
import { useQueueStore } from './queue';
import { useUserStore } from './user';
import { useUserInterfaceStore } from './userInterface';
import { setLoginCookies } from '~~/common/server/grantsHelper.js';
import { PUSH_LOGOUT } from '~~/common/plugins/aw-analytics.js';

const GRANT_TYPES = {
  PASSWORD: 'password',
  CAREER_PASSWORD: 'career_password',
  ANONYMOUS: 'anonymous',
  ONE_TIME_TOKEN: 'one_time_token',
  THIRD_PARTY_ACCESS_TOKEN: '3rd_party_access_token',
  REFRESH_TOKEN: 'refresh_token',
};

const PROVIDER_TYPES = {
  SALESFORCE: 'salesforce',
  FACEBOOK: 'facebook',
  GOOGLE: 'google',
};
export const useAuthenticationStore = defineStore('authentication', () => {
  const nuxtApp = useNuxtApp();
  const config = useRuntimeConfig();
  const queueStore = useQueueStore();
  const userStore = useUserStore();

  const customerDiamondLogoutIframeKey = ref(false);

  function setCustomerDiamondLogoutIframeKey () {
    customerDiamondLogoutIframeKey.value = `aw_${uuid4()}`;
  }

  function loginWithOneTimeToken (payload) {
    const { $authentication } = nuxtApp;
    return $authentication.$post('/login', {
      token: payload.oneTimeToken,
      grant_type: GRANT_TYPES.ONE_TIME_TOKEN,
    });
  }

  function loginWithUsernameAndPassword (payload) {
    const { $authentication } = nuxtApp;
    return $authentication.$post('/login', {
      ...payload,
      grant_type: GRANT_TYPES.PASSWORD,
    });
  }

  function loginWithCareerUsernameAndPassword (payload) {
    const { $authentication } = nuxtApp;
    return $authentication.$post('/login', {
      ...payload,
      grant_type: GRANT_TYPES.CAREER_PASSWORD,
    });
  }

  function loginWithFacebook (response) {
    const { $authentication } = nuxtApp;

    return $authentication.$post('/login', {
      token: response.accessToken,
      provider: PROVIDER_TYPES.FACEBOOK,
      grant_type: GRANT_TYPES.THIRD_PARTY_ACCESS_TOKEN,
    });
  }

  function loginWithGoogle (response) {
    const { $authentication } = nuxtApp;

    return $authentication.$post('/login', {
      token: response.access_token,
      provider: PROVIDER_TYPES.GOOGLE,
      grant_type: GRANT_TYPES.THIRD_PARTY_ACCESS_TOKEN,
    });
  }

  function loginWithSalesForce (data) {
    const { $authentication } = nuxtApp;

    return $authentication.$post('/login', {
      token: data.code,
      provider: PROVIDER_TYPES.SALESFORCE,
      grant_type: GRANT_TYPES.THIRD_PARTY_ACCESS_TOKEN,
    });
  }

  async function loginAnonymous () {
    const { $axiosRefreshTokens } = nuxtApp;
    return $axiosRefreshTokens({
      grant_type: GRANT_TYPES.ANONYMOUS,
    });
  }

  async function loginWithRefreshToken () {
    const { $axiosRefreshTokens } = nuxtApp;
    return $axiosRefreshTokens({
      grant_type: GRANT_TYPES.REFRESH_TOKEN,
    });
  }

  function registerWithUsernameAndPassword (payload) {
    const { $api } = nuxtApp;
    return $api.$put('/customer', {
      ...payload,
    });
  }

  function registerWithFacebook (response) {
    const { $api } = nuxtApp;

    return $api.$put('/customer', {
      credential: {
        token: response.accessToken,
        provider: PROVIDER_TYPES.FACEBOOK,
      },
    });
  }

  function registerWithGoogle (response) {
    const { $api } = nuxtApp;

    return $api.$put('/customer', {
      credential: {
        token: response.access_token,
        provider: PROVIDER_TYPES.GOOGLE,
      },
    });
  }

  async function logout ({ accountDelete } = { accountDelete: false }) {
    useUserInterfaceStore().startLoading({ id: 'logout' });
    return await queueStore.add({
      action: logoutRequest,
      actionName: 'authentication/logoutRequest',
      payload: { accountDelete },
      canStartSecondAction: true,
    });
  }

  function clearAuthCookies () {
    const {
      $cookies,
      $logger,
    } = nuxtApp;

    const { isChangeRequiresServer } = setLoginCookies({
      cookies: $cookies,
      body: {
        access_token: undefined,
        token_type: undefined,
        refresh_token: undefined,
        expires_in: undefined,
        login_type: undefined,
      },
    });
    $cookies.remove('login_type', false, {
      path: '/',
      sameSite: 'lax',
    });
    return (isChangeRequiresServer
      ? window.fetch('/fe-api/revoke-token', { method: 'DELETE' }).catch(err => $logger.error(err))
      : Promise.resolve()
    ).then(() => {
    });
  }

  async function logoutRequest ({ accountDelete }) {
    const {
      $cookies,
      $logger,
      $api,
      $authentication,
      $awAnalytics,
    } = nuxtApp;
    try {
      $cookies.remove('login_type', false, {
        path: '/',
        sameSite: 'lax',
      });

      if (!accountDelete) {
        await Promise.allSettled([
          $api.$delete('/token/revoke').catch(err => $logger.error(err)),
          $authentication.$post('/login', { grant_type: GRANT_TYPES.ANONYMOUS }).catch((err) => {
            $logger.error(err);
            clearAuthCookies();
          }),
        ]);
        try {
          $awAnalytics[PUSH_LOGOUT]({ isB2B: userStore.data?.personalInfo?.isB2B });
        } catch (error) {
          $logger.error(error);
        }
      }

      await userStore.fetchUser({ refreshData: config.public.isShop });
      userStore.resetShoppingListSessionData();
      // await userStore.setShoppingLists( []);
    } catch (error) {
      $logger.error(error);
    } finally {
      useUserInterfaceStore().endLoading({ id: 'logout' });
    }
  }

  async function customerDiamondLogin ({ path }) {
    useUserInterfaceStore().startLoading({ id: 'login' });
    const queueStore = useQueueStore();
    return await queueStore.add({
      action: customerDiamondLoginRequest,
      actionName: 'authentication/customerDiamondLoginRequest',
      payload: { path },
    });
  }

  function customerDiamondLoginRequest ({ path }) {
    const {
      $i18n,
      $awRuntimeConfig,
    } = nuxtApp;
    useUserInterfaceStore().endLoading({ id: 'login' });
    window.open(`${($awRuntimeConfig.customerDiamondLoginUrl).replace('expid_XX', ('expid_' + $i18n.locale.value))}&state=${path}`, '_self');
    return Promise.resolve();
  }

  async function customerDiamondRegistration ({ path }) {
    useUserInterfaceStore().startLoading({ id: 'register' });
    const queueStore = useQueueStore();
    return await queueStore.add({
      action: customerDiamondLoginRequest,
      actionName: 'authentication/customerDiamondLoginRequest',
      payload: { path },
    });
  }

  function customerDiamondRegistrationRequest ({ path }) {
    const {
      $i18n,
      $awRuntimeConfig,
    } = nuxtApp;
    useUserInterfaceStore().endLoading({ id: 'register' });
    window.open(`${($awRuntimeConfig.customerDiamondRegisterUrl).replace('expid_XX', ('expid_' + $i18n.locale.value))}&state=${path}`, '_self');
  }

  async function reInitToken () {
    const {
      $cookies,
    } = nuxtApp;
    const accessToken = $cookies.get('access_token');
    const refreshToken = $cookies.get('refresh_token');
    const isServer = import.meta.server;
    if (isServer) {
      if (!accessToken && !refreshToken) {
        await loginAnonymous();
      } else if (!accessToken && refreshToken) {
        await loginWithRefreshToken();
      }
    } else {
      if (!accessToken) {
        await loginWithRefreshToken();
      }
    }
  }

  return {
    customerDiamondLogoutIframeKey,
    setCustomerDiamondLogoutIframeKey,
    loginWithOneTimeToken,
    loginWithUsernameAndPassword,
    loginWithCareerUsernameAndPassword,
    loginWithFacebook,
    loginWithGoogle,
    loginWithSalesForce,
    loginAnonymous,
    loginWithRefreshToken,
    registerWithUsernameAndPassword,
    registerWithFacebook,
    registerWithGoogle,
    logout,
    logoutRequest,
    clearAuthCookies,
    customerDiamondLogin,
    customerDiamondLoginRequest,
    customerDiamondRegistration,
    customerDiamondRegistrationRequest,
    reInitToken,
  };
});
