<template>
  <component
    :is="createTag"
    :class="[
      $style.heading,
      $style[`heading${level}`],
      $style[align],
      {
        [$style.headingLeading]: leading,
        [$style.headingTrailing]: trailing,
        [$style[`headingNoGutter`]]: noGutter,
        [$style.regular]: regular,
        [$style.uppercase]: upperCase,
        [$style.italic]: italic,
        [$style.version2]: version2
      }
    ]"
  >
    <slot />
  </component>
</template>

<script>
  const validLevel = ['1', '2', '3', '4', '5', '6'];
  const validAlignment = ['left', 'center', 'right'];

  export default {
    name: 'LvHeading',
    props: {
      tag: {
        type: String,
        required: true,
      },
      level: {
        type: String,
        required: true,
        validator: level => validLevel.includes(level),
      },
      align: {
        type: String,
        required: false,
        default: undefined,
        validator: align => validAlignment.includes(align),
      },
      regular: {
        type: Boolean,
        required: false,
        default: false,
      },
      upperCase: {
        type: Boolean,
        required: false,
        default: false,
      },
      italic: {
        type: Boolean,
        required: false,
        default: false,
      },
      leading: {
        type: Boolean,
        default: false,
      },
      trailing: {
        type: Boolean,
        default: false,
      },
      noGutter: {
        type: Boolean,
        default: false,
      },
      version2: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      createTag () {
        return this.tag || 'h' + this.level;
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
$lv-heading-display: $heading-display !default;

$lv-heading-1-font-size: $heading-1-font-size !default;
$lv-heading-1-line-height: $heading-1-line-height !default;
$lv-heading-1-font-weight: $heading-1-font-weight !default;
$lv-heading-1-margin: $heading-1-margin !default;

$lv-heading-2-font-size: $heading-2-font-size !default;
$lv-heading-2-line-height: $heading-2-line-height !default;
$lv-heading-2-font-weight: $heading-2-font-weight !default;
$lv-heading-2-margin: $heading-2-margin !default;

$lv-heading-3-font-size: $heading-3-font-size !default;
$lv-heading-3-line-height: $heading-3-line-height !default;
$lv-heading-3-font-weight: $heading-3-font-weight !default;
$lv-heading-3-margin: $heading-3-margin !default;

$lv-heading-4-font-size: $heading-4-font-size !default;
$lv-heading-4-line-height: $heading-4-line-height !default;
$lv-heading-4-font-weight: $heading-4-font-weight !default;
$lv-heading-4-margin: $heading-4-margin !default;

$lv-heading-5-font-size: $heading-5-font-size !default;
$lv-heading-5-line-height: $heading-5-line-height !default;
$lv-heading-5-font-weight: $heading-5-font-weight !default;
$lv-heading-5-margin: $heading-5-margin !default;

$lv-heading-6-font-size: $heading-6-font-size !default;
$lv-heading-6-line-height: $heading-6-line-height !default;
$lv-heading-6-font-weight: $heading-6-font-weight !default;
$lv-heading-6-margin: $heading-6-margin !default;

$lv-heading-gutter-clean-value: $heading-gutter-clean-value !default;
$lv-heading-regular-font-weight: $heading-regular-font-weight !default;

.heading {
  display: $lv-heading-display;

  &1 {
    font-size: $lv-heading-1-font-size;
    font-weight: $lv-heading-1-font-weight;
    line-height: $lv-heading-1-line-height;
    margin: $lv-heading-1-margin;
  }

  &2 {
    font-size: $lv-heading-2-font-size;
    font-weight: $lv-heading-2-font-weight;
    line-height: $lv-heading-2-line-height;
    margin: $lv-heading-2-margin;
  }

  &3 {
    font-size: $lv-heading-3-font-size;
    font-weight: $lv-heading-3-font-weight;
    line-height: $lv-heading-3-line-height;
    margin: $lv-heading-3-margin;
  }

  &4 {
    font-size: $lv-heading-4-font-size;
    font-weight: $lv-heading-4-font-weight;
    line-height: $lv-heading-4-line-height;
    margin: $lv-heading-4-margin;
  }

  &5 {
    font-size: $lv-heading-5-font-size;
    font-weight: $lv-heading-5-font-weight;
    line-height: $lv-heading-5-line-height;
    margin: $lv-heading-5-margin;
  }

  &6 {
    font-size: $lv-heading-6-font-size;
    font-weight: $lv-heading-6-font-weight;
    line-height: $lv-heading-6-line-height;
    margin: $lv-heading-6-margin;
  }

  &Leading {
    margin-top: $lv-heading-gutter-clean-value;
  }

  &Trailing {
    margin-bottom: $lv-heading-gutter-clean-value;
  }

  &NoGutter {
    margin-top: $lv-heading-gutter-clean-value;
    margin-bottom: $lv-heading-gutter-clean-value;
  }
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}

.regular {
  font-weight: $lv-heading-regular-font-weight;
}

.uppercase {
  text-transform: uppercase;
}

.italic {
  font-style: italic;
}

.version2 {
  &.heading {
    display: $lv-heading-display;

    &1 {
      font-size: $heading-1-font-size-mobile-version2;
      font-weight: $heading-1-font-weight-mobile-version2;
      line-height: $heading-1-line-height-mobile-version2;
      margin: $heading-1-margin-mobile-version2;
    }

    &2 {
      font-size: $heading-2-font-size-mobile-version2;
      font-weight: $heading-2-font-weight-mobile-version2;
      line-height: $heading-2-line-height-mobile-version2;
      margin: $heading-2-margin-mobile-version2;
    }

    &3 {
      font-size: $heading-3-font-size-mobile-version2;
      font-weight: $heading-3-font-weight-mobile-version2;
      line-height: $heading-3-line-height-mobile-version2;
      margin: $heading-3-margin-mobile-version2;
    }

    &4 {
      font-size: $heading-4-font-size-mobile-version2;
      font-weight: $heading-4-font-weight-mobile-version2;
      line-height: $heading-4-line-height-mobile-version2;
      margin: $heading-4-margin-mobile-version2;
    }

    &5 {
      font-size: $lv-heading-5-font-size;
      font-weight: $lv-heading-5-font-weight;
      line-height: $lv-heading-5-line-height;
      margin: $lv-heading-5-margin;
    }

    &6 {
      font-size: $lv-heading-6-font-size;
      font-weight: $lv-heading-6-font-weight;
      line-height: $lv-heading-6-line-height;
      margin: $lv-heading-6-margin;
    }

    &Leading {
      margin-top: $heading-gutter-clean-value-version2;
    }

    &Trailing {
      margin-bottom: $heading-gutter-clean-value-version2;
    }

    &NoGutter {
      margin-top: $heading-gutter-clean-value-version2;
      margin-bottom: $heading-gutter-clean-value-version2;
    }

    @include tablet(min) {
      &1 {
        font-size: $heading-1-font-size-desktop-version2;
        font-weight: $heading-1-font-weight-desktop-version2;
        line-height: $heading-1-line-height-desktop-version2;
        margin: $heading-1-margin-desktop-version2;
      }

      &2 {
        font-size: $heading-2-font-size-desktop-version2;
        font-weight: $heading-2-font-weight-desktop-version2;
        line-height: $heading-2-line-height-desktop-version2;
        margin: $heading-2-margin-desktop-version2;
      }

      &3 {
        font-size: $heading-3-font-size-desktop-version2;
        font-weight: $heading-3-font-weight-desktop-version2;
        line-height: $heading-3-line-height-desktop-version2;
        margin: $heading-3-margin-desktop-version2;
      }

      &4 {
        font-size: $heading-4-font-size-desktop-version2;
        font-weight: $heading-4-font-weight-desktop-version2;
        line-height: $heading-4-line-height-desktop-version2;
        margin: $heading-4-margin-desktop-version2;
      }

      &5 {
        font-size: $lv-heading-5-font-size;
        font-weight: $lv-heading-5-font-weight;
        line-height: $lv-heading-5-line-height;
        margin: $lv-heading-5-margin;
      }

      &6 {
        font-size: $lv-heading-6-font-size;
        font-weight: $lv-heading-6-font-weight;
        line-height: $lv-heading-6-line-height;
        margin: $lv-heading-6-margin;
      }
    }
  }
}
</style>
