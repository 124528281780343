import { ref } from 'vue';
import { defineStore } from 'pinia';
import { useNuxtApp } from 'nuxt/app';
import { YOUTUBE_IMAGE_URL_PREFIX, YOUTUBE_IMAGE_VARIATIONS } from '~~/shop/awPlugins/app-config';
import { vimeoMeta } from '~~/common/utils/video.js';

export const useVideoStore = defineStore('video', () => {
  const nuxtApp = useNuxtApp();
  const videoCache = ref({});

  function fetchVideoToCache (id) {
    return videoCache.value[id] || vimeoMeta(id, nuxtApp.$logger).then(
      ({ thumbnail_url: thumbnailUrl }) => {
        if (thumbnailUrl) {
          videoCache.value[id] = [{
            url: thumbnailUrl,
          }];
        }
      },
    );
  }
  function getVideoFallbackImage (id) {
    return YOUTUBE_IMAGE_VARIATIONS.map((variation) => ({
      url: `${YOUTUBE_IMAGE_URL_PREFIX}${id}${variation}`,
    }));
  }
  function getYoutubeThumbnailSources (id) {
    if (videoCache.value[id]) {
      return videoCache.value[id];
    }
    if (id) {
      fetchVideoToCache(id);
    }
    return getVideoFallbackImage(id);
  }
  async function asyncGetYoutubeThumbnailSources (id) {
    if (videoCache.value[id]) {
      return videoCache.value[id];
    }
    if (id) {
      return await fetchVideoToCache(id).then(() => {
        if (videoCache.value[id]) {
          return videoCache.value[id];
        }
        return getVideoFallbackImage(id);
      });
    }
  }

  return {
    videoCache,
    fetchVideoToCache,
    getVideoFallbackImage,
    getYoutubeThumbnailSources,
    asyncGetYoutubeThumbnailSources,
  };
});
