export function collectLeaves (values) {
  const filterValues = [];
  const collectSubLeaves = (node) => {
    if (!node.children) {
      filterValues.push(node);
    } else {
      for (const child of node.children) {
        collectSubLeaves(child);
      }
    }
  };
  collectSubLeaves({ children: values });
  return filterValues;
}

export function findNodeByValue (categoryTree, value) {
  const findSubNode = (node) => {
    if (node.value === value) {
      return node;
    } else if (node.children) {
      for (const child of node.children) {
        const found = findSubNode(child);
        if (found) {
          return found;
        }
      }
    } else {
      return null;
    }
  };
  return value === 'root' ? categoryTree : findSubNode(categoryTree);
}
