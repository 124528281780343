<template>
  <shop-default-layout is-aw-spring :spring-desktop-size="10">
    <slot />
  </shop-default-layout>
</template>

<script>

  import ShopDefaultLayout from '~~/shop/layouts/default.vue';

  export default {
    name: 'Authenticated10Column',
    components: { ShopDefaultLayout },
  };
</script>
