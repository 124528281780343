import { defineStore } from 'pinia';
import { useNuxtApp, useRuntimeConfig } from 'nuxt/app';
import { ref } from 'vue';
import { setErrorNotification } from '~~/common/utils/helper';

import { useNotificationStore } from '~~/common/stores/notification';
import { useUserInterfaceStore } from '~~/common/stores/userInterface';
import { useUserStore } from '~~/common/stores/user';
import { useOrderStore } from '~~/common/stores/order';

export const useLoyaltyCardStore = defineStore('loyaltyCard', () => {
  const nuxtApp = useNuxtApp();
  const config = useRuntimeConfig();

  const notificationStore = useNotificationStore();
  const userInterfaceStore = useUserInterfaceStore();

  const loyaltyCard = ref(null);
  const loyaltyCardLevel = ref(null);
  const loyaltyCardLevelInfo = ref(null);
  const expectedLoyaltyPoints = ref(null);

  const loyaltyCoupons = ref({});
  const loyaltySticker = ref({});
  const loyaltyStickers = ref({});
  const loyaltyCardSettings = ref(null);

  const visibleBottomBlock = ref(true);
  const expectedLoyaltyPointsKey = computed(() => useUserStore().data?.id && useOrderStore().order);

  function resetLoyaltyState () {
    loyaltyCard.value = null;
    expectedLoyaltyPoints.value = null;
    loyaltyCoupons.value = {};
    loyaltySticker.value = {};
    loyaltyStickers.value = {};
    loyaltyCardSettings.value = null;
  }

  function resetLoyaltyData () {
    if (config.public.isShop) {
      resetLoyaltyState();
    }
  }

  function setLoyaltyCard (newLoyaltyCard) {
    if (config.public.isShop) {
      loyaltyCard.value = newLoyaltyCard;
    }
  }

  function setLoyaltyCardLevel (newLoyaltyCardLevel) {
    if (config.public.isShop) {
      loyaltyCardLevel.value = newLoyaltyCardLevel;
    }
  }

  function setLoyaltyCardLevelInfo (newLoyaltyCardLevelInfo) {
    if (config.public.isShop) {
      loyaltyCardLevelInfo.value = newLoyaltyCardLevelInfo;
    }
  }

  function setExpectedLoyaltyPoints (newExpectedLoyaltyPoints) {
    if (config.public.isShop) {
      expectedLoyaltyPoints.value = newExpectedLoyaltyPoints;
    }
  }

  function setLoyaltyCoupons (payload) {
    if (config.public.isShop) {
      loyaltyCoupons.value = payload;
    }
  }

  function setLoyaltySticker (payload, id) {
    if (config.public.isShop) {
      loyaltySticker.value[id] = payload;
    }
  }

  function setLoyaltyStickers (payload) {
    if (config.public.isShop) {
      loyaltyStickers.value = payload;
    }
  }

  function setLoyaltyCardSettings (settings) {
    if (config.public.isShop) {
      loyaltyCardSettings.value = settings;
    }
  }

  async function fetchLoyaltyCard () {
    const {
      $api,
      $logger,
    } = nuxtApp;
    if (config.public.isShop) {
      try {
        const result = await $api.$get('/loyalty_card/current');
        setLoyaltyCard(result);
      } catch (error) {
        // if error.response.status === 404 we don't have loyalty card, this is not error
        if (error?.response?.status === 404) {
          setLoyaltyCard(null);
        } else {
          $logger.error(error);
          await setErrorNotification({ nuxtApp, error });
        }
      }
    }
  }

  async function fetchLoyaltyCardLevel () {
    const {
      $api,
      $logger,
    } = nuxtApp;
    if (config.public.isShop) {
      try {
        const result = await $api.$get('/loyalty_card/current/level');
        setLoyaltyCardLevel(result);
      } catch (error) {
        // if error.response.status === 404 we don't have loyalty card, this is not error
        if (error?.response?.status === 404) {
          setLoyaltyCardLevel(null);
        } else {
          $logger.error(error);
          await setErrorNotification({ nuxtApp, error });
        }
      }
    }
  }

  async function fetchLoyaltyCardLevelInfo () {
    const {
      $api,
      $logger,
    } = nuxtApp;
    if (config.public.isShop) {
      try {
        const result = await $api.$get('/loyalty_card_level_info');
        setLoyaltyCardLevelInfo(result);
      } catch (error) {
        // if error.response.status === 404 we don't have loyalty card, this is not error
        if (error?.response?.status === 404) {
          setLoyaltyCardLevelInfo(null);
        } else {
          $logger.error(error);
          await setErrorNotification({ nuxtApp, error });
        }
      }
    }
  }

  async function createLoyaltyCard () {
    const {
      $api,
      $logger,
    } = nuxtApp;

    if (config.public.isShop) {
      try {
        const result = await $api.$post('/loyalty_card', {});
        setLoyaltyCard(result);
        notificationStore.pushSuccess({
          manualClose: false,
          text: {
            titleToken: 'aw.profile.loyalty_card.card_info.success_message',
          },
        });
      } catch (error) {
        $logger.error(error);
        await setErrorNotification({ nuxtApp, error });
      }
    }
  }

  async function addLoyaltyCard (payload) {
    const {
      $api,
      $logger,
    } = nuxtApp;

    if (config.public.isShop) {
      try {
        await $api.$put('/loyalty_card', payload);
        await fetchLoyaltyCard();

        notificationStore.pushSuccess({
          manualClose: false,
          text: {
            titleToken: 'aw.common.form.success_msg',
          },
        });
      } catch (error) {
        $logger.error(error);
        await setErrorNotification({ nuxtApp, error });
      } finally {
        userInterfaceStore.endLoading(null);
      }
    }
  }

  async function deleteLoyaltyCard () {
    const { $api } = nuxtApp;
    if (config.public.isShop) {
      try {
        await $api.delete('/loyalty_card/current');
        await fetchLoyaltyCard();
      } catch (error) {
        await setErrorNotification({ nuxtApp, error });
      }
    }
  }

  async function fetchExpectedLoyaltyPoints ({
    id = 'current',
    type = 'ALL',
  }) {
    const {
      $api,
      $logger,
    } = nuxtApp;

    if (config.public.isShop) {
      const userStore = useUserStore();
      // todo csak akkor kérjük ezt le, ha a usernek van loyalty cardja
      if (userStore.isEcomUser) {
        try {
          const result = await $api.$get(`/orders/${id}/cart/${type}/expected_loyalty_points`);
          setExpectedLoyaltyPoints(result);
        } catch (error) {
          // if error.response.status === 404 we don't have loyalty card, this is not error
          if (error?.response?.status === 404) {
            setExpectedLoyaltyPoints(null);
          } else {
            $logger.error(error);
            await setErrorNotification({ nuxtApp, error });
          }
        }
      } else {
        setExpectedLoyaltyPoints(null);
      }
    }
  }

  async function fetchLoyaltyCoupons () {
    const {
      $api,
      $logger,
    } = nuxtApp;

    if (config.public.isShop) {
      let resp;
      try {
        resp = await $api.$get('me/loyalty/coupons');
      } catch (error) {
        if (error?.response?.status !== 404) {
          $logger.error(error);
          setErrorNotification({ nuxtApp, error });
        }
      } finally {
        if (!resp || typeof resp !== 'object') {
          resp = {};
        }
      }
      setLoyaltyCoupons(resp);
    }
  }

  async function activateLoyaltyCoupon ({ id }) {
    const {
      $api,
      $logger,
    } = nuxtApp;
    if (config.public.isShop) {
      try {
        await $api.$post('me/loyalty/coupon/activate', { id });
      } catch (error) {
        $logger.error(error);
        setErrorNotification({ nuxtApp, error });
      } finally {
        fetchLoyaltyCoupons();
      }
    }
  }

  async function fetchLoyaltyStickerById (id) {
    const {
      $api,
      $logger,
    } = nuxtApp;
    if (config.public.isShop) {
      let result;
      try {
        result = await $api.$get(`me/loyalty/sticker/${id}/details`);
      } catch (error) {
        if (error?.response?.status !== 404) {
          $logger.error(error);
          setErrorNotification({ nuxtApp, error });
        }
      } finally {
        if (!result || typeof result !== 'object') {
          result = {};
        }
      }
      setLoyaltySticker(result, id);
    }
  }

  async function fetchLoyaltyStickers () {
    const {
      $api,
      $logger,
    } = nuxtApp;
    if (config.public.isShop) {
      let resp;
      try {
        resp = await $api.$get('me/loyalty/stickers');
      } catch (error) {
        if (error?.response?.status !== 404) {
          $logger.error(error);
          setErrorNotification({ nuxtApp, error });
        }
      } finally {
        if (!resp || typeof resp !== 'object') {
          resp = {};
        }
      }
      setLoyaltyStickers(resp);
    }
  }

  async function fetchLoyaltyTransactions ({
    page,
    count,
  }) {
    const {
      $api,
      $logger,
    } = nuxtApp;
    if (config.public.isShop) {
      let resp;
      try {
        resp = await $api.$get(`me/loyalty/transactions?page=${page || 1}&itemsPerPage=${count || 10}`);
      } catch (error) {
        if (error?.response?.status !== 404) {
          $logger.error(error);
          setErrorNotification({ nuxtApp, error });
        }
      } finally {
        if (!resp || !Array.isArray(resp)) {
          resp = [];
        }
      }

      return resp;
    }
    return null;
  }

  async function fetchLoyaltyCardSettings () {
    const {
      $api,
      $logger,
    } = nuxtApp;
    if (config.public.isShop) {
      try {
        const result = await $api.$get('/loyalty_card/current/settings');
        setLoyaltyCardSettings(result);
      } catch (error) {
        // if e.response.status === 404 we don't have loyalty card, this is not error
        if (error?.response?.status === 404) {
          setLoyaltyCardSettings(null);
        } else {
          $logger.error(error);
          await setErrorNotification({ nuxtApp, error });
        }
      }
    }
  }

  function hideBottomBlock () {
    visibleBottomBlock.value = false;
  }

  function showBottomBlock () {
    visibleBottomBlock.value = true;
  }

  return {
    loyaltyCard,
    loyaltyCardLevel,
    loyaltyCardLevelInfo,
    expectedLoyaltyPoints,
    expectedLoyaltyPointsKey,
    loyaltyCoupons,
    loyaltySticker,
    loyaltyStickers,
    loyaltyCardSettings,
    visibleBottomBlock,
    resetLoyaltyData,
    setLoyaltyCard,
    setLoyaltyCardLevel,
    setLoyaltyCardLevelInfo,
    setLoyaltyCoupons,
    setLoyaltySticker,
    setLoyaltyStickers,
    fetchLoyaltyCard,
    fetchLoyaltyCardLevel,
    fetchLoyaltyCardLevelInfo,
    createLoyaltyCard,
    addLoyaltyCard,
    deleteLoyaltyCard,
    fetchExpectedLoyaltyPoints,
    fetchLoyaltyCoupons,
    activateLoyaltyCoupon,
    fetchLoyaltyStickerById,
    fetchLoyaltyStickers,
    fetchLoyaltyTransactions,
    fetchLoyaltyCardSettings,
    hideBottomBlock,
    showBottomBlock,
  };
});
