import * as checkoutTypes from '../config/CheckoutTypes.js';

export function parseGuestCheckoutStringToArray (guestCheckoutString) {
  return guestCheckoutString?.split(/\s+/)?.filter(str => str);
}

export function throwIfGuestCheckoutIsInvalid (...enabledGuestCheckouts) {
  const validGuestCheckouts = new Set(Object.values(checkoutTypes));
  const isSomeGuestCheckoutInvalid = enabledGuestCheckouts.some((checkoutType) => {
    return !validGuestCheckouts.has(checkoutType);
  });
  if (isSomeGuestCheckoutInvalid) {
    throw new Error(
      `ENABLED_GUEST_CHECKOUTS can only contain:
${[...validGuestCheckouts]}
instead received:
${enabledGuestCheckouts}`,
    );
  }
}
