import { defineStore } from 'pinia';
import { navigateTo, useNuxtApp, useRuntimeConfig } from 'nuxt/app';
import { computed, ref } from 'vue';
import { setErrorNotification } from '~~/common/utils/helper';
import { useQueueStore } from '~~/common/stores/queue';
import { useOrderStore } from '~~/common/stores/order';
import { useUserInterfaceStore } from '~~/common/stores/userInterface';
import { useDeliveryStore } from '~~/shop/stores/delivery';

const cache = {};
const fetchCheckoutActions = ['order/fetchOrderRequest', 'order/addToCartRequest', 'order/addCouponRequest', 'order/updateCartItemRequest'];

const CT_DEALER = 'DEALER';
const checkoutTypes = {
  [CT_DEALER]: CT_DEALER,
};
export const useCheckoutStore = defineStore('checkout', () => {
  const nuxtApp = useNuxtApp();
  const config = useRuntimeConfig();
  const queueStore = useQueueStore();
  const orderStore = useOrderStore();
  const userInterfaceStore = useUserInterfaceStore();
  const deliveryStore = useDeliveryStore();

  const checkout = ref({
    food: {},
    nonfood: {},
  });
  const shopCheckoutType = ref('FOOD');
  const dealerCheckoutType = ref(checkoutTypes[CT_DEALER]);
  const availableAgreeableStatements = ref([]);
  const availableBillingMethods = ref([]);
  const availablePaymentMethods = ref([]);
  const violations = ref([]);
  const availableDeliveryMethods = ref([]);
  const violationsIsVisible = ref(false);

  const updateCheckoutIsInprogress = ref(false);

  const frame = ref({
    availables: {},
    reservations: {},
    comparisonOptions: ['hu', { timeZone: 'Europe/Budapest', year: '2-digit', month: '2-digit', day: '2-digit' }],
  });

  // getters
  const checkoutType = computed(() => {
    return config.public.isShop ? shopCheckoutType.value : dealerCheckoutType.value;
  });

  const getFoodCheckout = computed(() => {
    return checkout.value.food;
  });
  const getNonfoodCheckout = computed(() => {
    return checkout.value.nonfood;
  });
  const getCurrentCheckout = computed(() => {
    return checkout.value[shopCheckoutType.value.toLowerCase()];
  });

  const getSelection = computed(() => (k) => {
    return frame.value.availables[k]?.availableDates?.find(a => a.date === frame.value.reservations[k]?.reservableDate);
  });
  /**
   * @param {regex|function} filterParam
   * */
  const violationToNoti = computed(() => (filterParam) => {
    return Object.fromEntries(
      violations.value
        .filter((v) => {
          if (typeof filterParam === 'function') {
            return filterParam(v.propertyPath);
          } else { // regex
            return filterParam.test(v.propertyPath);
          }
        })
        .map(v => ({
          type: 'warning',
          manualClose: false,
          thinContent: true,
          text: {
            // subtitleToken: v.message,
            subtitle: v.message,
            footer: `ID: ${v.code}`,
          },
        }))
        .map((v, i) => [i, v]),
    );
  });

  // actions
  function setCheckoutType (type) {
    shopCheckoutType.value = type;
  }

  function setCheckout (payload) {
    if (config.public.isShop) {
      setShopCheckout(payload);
    } else {
      setDealerCheckout(payload);
    }
  }

  function setShopCheckout ({ checkout: newCheckout, type }) {
    checkout.value = {
      ...checkout.value,
      [type.toLowerCase()]: newCheckout,
    };
  }

  function setDealerCheckout (newCheckout) {
    checkout.value = newCheckout;
  }

  function setAvailableAgreeableStatements (newAvailableAgreeableStatements) {
    availableAgreeableStatements.value = newAvailableAgreeableStatements;
  }

  function setAvailableBillingMethods (newAvailableBillingMethods) {
    availableBillingMethods.value = newAvailableBillingMethods;
  }

  function setAvailableDeliveryMethods (newAvailableDeliveryMethods) {
    availableDeliveryMethods.value = newAvailableDeliveryMethods;
  }

  function setAvailablePaymentMethods (newAvailablePaymentMethods) {
    availablePaymentMethods.value = newAvailablePaymentMethods;
  }

  function setViolations (newViolations) {
    violations.value = newViolations;
  }

  function setAvailableFrames (payload) {
    frame.value.availables = payload;
  }

  function setReservedFrames (payload) {
    frame.value.reservations = payload;
  }

  function showViolations () {
    violationsIsVisible.value = true;
  }

  function hideViolations () {
    violationsIsVisible.value = false;
  }

  async function fetchAvailables () {
    const { $api, $logger } = nuxtApp;
    try {
      const data = await $api.$get(`/checkouts/${dealerCheckoutType.value}/available_delivery_dates`);
      for (const a in data) {
        data[a].availableDates.forEach((e) => {
          e._comparisonValue = new Date(e.date).toLocaleString(...frame.value.comparisonOptions);
        });
      }
      setAvailableFrames(data);
    } catch (error) {
      $logger.error(error);
      setErrorNotification({ nuxtApp, error });
    }
  }

  async function deleteReservations () {
    const { $api, $logger } = nuxtApp;

    try {
      await $api.$delete(`/checkouts/${dealerCheckoutType.value}/reserved_delivery_dates`);
      setReservedFrames({});
    } catch (error) {
      $logger.error(error);
      setErrorNotification({ nuxtApp, error });
    }
  }

  async function fetchReservations () {
    const { $api, $logger } = nuxtApp;

    try {
      setReservedFrames(await $api.$get(`/checkouts/${dealerCheckoutType.value}/reserved_delivery_dates`));
    } catch (error) {
      $logger.error(error);
      setErrorNotification({ nuxtApp, error });
    }
  }

  async function patchReservations ({ discriminator, reservableDate }) {
    const { $api, $logger } = nuxtApp;
    try {
      await $api.$patch(
        `checkouts/${dealerCheckoutType.value}/reserved_delivery_dates`,
        {
          discriminator,
          reservableDate,
        },
        {
          headers: {
            'Content-Type': 'application/merge-patch+json',
          },
        },
      );
    } catch (error) {
      $logger.error(error);
      setErrorNotification({ nuxtApp, error });
    }
  }

  async function deleteCheckout () {
    const { $api, $logger } = nuxtApp;

    try {
      userInterfaceStore.startLoading(null);
      await $api.$delete(`checkouts/${dealerCheckoutType.value}`);
      setDealerCheckout({});
    } catch (error) {
      $logger.error(error);
      setErrorNotification({ nuxtApp, error });
    } finally {
      userInterfaceStore.endLoading(null);
    }
  }

  function clearCheckoutAvailableDeliveryMethods () {
    setAvailableDeliveryMethods(null);
  }

  async function updateCheckoutData (data) {
    while (updateCheckoutIsInprogress.value) {
      await new Promise(resolve => setTimeout(resolve, 512));
    }

    let response = null;
    updateCheckoutIsInprogress.value = true;

    if (config.public.isShop) {
      response = await updateShopCheckoutData(data);
    } else {
      response = await updateDealerCheckoutData(data);
    }

    updateCheckoutIsInprogress.value = false;

    return response;
  }

  async function updateShopCheckoutData ({ data, type, isErrorPopupRequested = true }) {
    const { $api, $logger, $localePath } = nuxtApp;
    let dontShowThisErrorPopup;
    try {
      const config = {
        headers: {
          'Content-Type': 'application/merge-patch+json',
        },
      };
      if (data.resolveOption) {
        config.headers['X-Error-Resolve-Option'] = data.resolveOption;
      }

      const r = await $api.patch(`checkouts/${type}`, data, config).catch((err) => {
        if (!isErrorPopupRequested) {
          dontShowThisErrorPopup = err;
        }
        throw err;
      });
      cache.updateCheckoutData = r.status;

      setShopCheckout({ checkout: r.data, type });
      const orderResult = await orderStore.fetchOrder({
        id: 'current',
        fetchNonfoodCheckout: false,
        canStartSecondAction: true,
        canRunSimultaneous: true,
      });
      if (data.resolveOption) {
        await deliveryStore.fetchMethod();
      }
      return {
        status: !orderResult.fetchOrderErrorStatus,
      };
    } catch (error) {
      const previous = cache.updateCheckoutData;
      cache.updateCheckoutData = error?.response?.status;

      const isUserExistsError = error?.response?.data?.message?.includes('user_exists');
      if ((previous === error?.response?.status && previous === 412) || isUserExistsError || (!isErrorPopupRequested && dontShowThisErrorPopup === error)) {
        return {
          status: false,
          error,
          isUserExistsError,
        };
      }

      const { name, message } = await setErrorNotification({ nuxtApp, error, closable: error.response?.status !== 422 });
      if (name === 'accept' && error.response?.status === 422) {
        if (config.public.isShop) {
          await navigateTo($localePath('/shop/profile/orders'));
        } else {
          await navigateTo($localePath('/orders-list'));
        }
      } else if (name === 'accept' && message) {
        updateShopCheckoutData({ data: { ...data, resolveOption: message }, type });
      } else if (name === 'cancel') {
        // do nothing
      } else {
        $logger.error(error);
      }
      return {
        status: false,
        error,
      };
    }
  }

  async function updateDealerCheckoutData (data) {
    const { $api, $logger } = nuxtApp;

    try {
      hideViolations();
      userInterfaceStore.startLoading(null);
      await $api.$patch(
        `checkouts/${dealerCheckoutType.value}`,
        data,
        {
          headers: {
            'Content-Type': 'application/merge-patch+json',
          },
        },
      ).then((result) => {
        setDealerCheckout(result);
      });
      await Promise.all([
        $api.$get(`checkouts/${dealerCheckoutType.value}/violations`).then((violations) => {
          setViolations(violations);
        }),
        orderStore.fetchOrder({ id: 'current', fetchNonfoodCheckout: false, fetchFoodCheckout: false }),
      ]);
      return true;
    } catch (error) {
      $logger.error(error);
      setErrorNotification({ nuxtApp, error });
      return false;
    } finally {
      userInterfaceStore.endLoading(null);
    }
  }

  async function fetchCheckout (params) {
    const { type, canRunSimultaneous, canStartSecondAction } = params || {};

    if (config.public.isShop) {
      return await fetchShopCheckout({ type, canRunSimultaneous, canStartSecondAction });
    } else {
      return await fetchDealerCheckout(type ? { type } : { type: CT_DEALER });
    }
  }

  async function fetchDealerCheckout ({ type } = { type: CT_DEALER }) {
    const { $api, $logger } = nuxtApp;

    const t = type || checkoutType.value;
    if (checkoutTypes[t]) {
      try {
        setDealerCheckout(await $api.$get(`checkouts/${t}`));
      } catch (error) {
        $logger.error(error);
        setErrorNotification({ nuxtApp, error });
      }
    }
  }

  async function fetchShopCheckout ({ type, canRunSimultaneous, canStartSecondAction }) {
    if (import.meta.server) {
      return await fetchCheckoutRequest({ type });
    } else if (queueStore.hasActionsInQueue(fetchCheckoutActions)) {
      if (queueStore.hasFetchCheckoutInQueue('checkout/fetchCheckoutRequest', type)) {
        // return promise with value true to simulate good request
        return new Promise(resolve => resolve(true));
      } else {
        queueStore.add({
          action: fetchCheckoutRequest,
          actionName: 'checkout/fetchCheckoutRequest',
          payload: { type, canRunSimultaneous },
          canRunSimultaneous: false,
          canStartSecondAction,
        });
        return new Promise(resolve => resolve(true));
      }
    } else if (queueStore.hasFetchCheckoutInQueue('checkout/fetchCheckoutRequest', type)) {
      // return promise with value true to simulate good request
      return new Promise(resolve => resolve(true));
    } else {
      return await queueStore.add({
        action: fetchCheckoutRequest,
        actionName: 'checkout/fetchCheckoutRequest',
        payload: { type, canRunSimultaneous },
        canRunSimultaneous: false,
        canStartSecondAction,
      });
    }
  }

  async function fetchCheckoutRequest ({ type, canRunSimultaneous }) {
    const { $api, $logger, $localePath } = nuxtApp;
    try {
      const data = await $api.$get(`checkouts/${type}`);
      setShopCheckout({ checkout: data, type });
      return true;
    } catch (error) {
      queueStore.removeAllElementFromQueue(false);

      $logger.error(error);

      // in DB -> Auchan_DeliveryRound.webOrderCloseTime has expired we got 422 HTTP code
      if (error.response?.status === 422) {
        const { name } = await setErrorNotification({ nuxtApp, error, closable: false });
        if (name === 'accept') {
          await orderStore.deleteOrderModification();
          await deliveryStore.fetchMethod();
          await fetchCheckout({ type: checkout.value.checkoutType, canRunSimultaneous });
          if (config.public.isShop) {
            await navigateTo($localePath('/shop/profile/orders'));
          } else {
            await navigateTo($localePath('/orders-list'));
          }
          return false;
        }
      }
      return false;
    }
  }

  async function fetchCheckoutMetaData (type) {
    if (config.public.isShop) {
      return await fetchShopCheckoutMetaData(type);
    } else {
      return await fetchDealerCheckoutMetaData(type);
    }
  }

  async function fetchDealerCheckoutMetaData (type) {
    const { $api, $logger } = nuxtApp;
    type = type || dealerCheckoutType.value;
    const [
      availableAgreeableStatements,
      availableBillingMethods,
      availableDeliveryMethods,
    ] = await Promise.all([
      $api.$get(`checkouts/${type}/available_agreeable_statements`).catch(handle),
      $api.$get(`checkouts/${type}/available_billing_methods`).catch(handle),
      $api.$get(`checkouts/${type}/available_delivery_methods`).catch(handle),
      fetchDealerCheckoutAvailablePaymentMethods(type),
    ]);

    setAvailableAgreeableStatements(availableAgreeableStatements);
    setAvailableBillingMethods(availableBillingMethods);
    setAvailableDeliveryMethods(availableDeliveryMethods);

    function handle (err) {
      $logger.error(err);
      return [];
    }
  }

  async function fetchShopCheckoutMetaData (type) {
    const { $logger } = nuxtApp;

    try {
      await Promise.all([
        fetchCheckoutViolations(type),
        fetchCheckoutAvailableAgreeableStatements(type),
        fetchCheckoutAvailableBillingMethods(type),
        fetchCheckoutAvailableDeliveryMethods(type),
        fetchShopCheckoutAvailablePaymentMethods(type),
      ]);
    } catch (error) {
      $logger.error(error);
    }
  }

  async function fetchCheckoutAvailablePaymentMethods (type) {
    if (config.public.isShop) {
      return await fetchShopCheckoutAvailablePaymentMethods(type);
    } else {
      return await fetchDealerCheckoutAvailablePaymentMethods(type);
    }
  }

  async function fetchDealerCheckoutAvailablePaymentMethods (type) {
    const { $api, $logger } = nuxtApp;

    type = type || dealerCheckoutType.value;
    try {
      if (!availablePaymentMethods.value?.length) {
        const availablePaymentMethods = await $api.$get(`checkouts/${type}/available_payment_methods`);
        setAvailablePaymentMethods(availablePaymentMethods);
      }
    } catch (err) {
      $logger.error(err);
      setAvailablePaymentMethods([]);
    }
  }

  async function fetchShopCheckoutAvailablePaymentMethods (type) {
    const { $api, $logger } = nuxtApp;

    try {
      const availablePaymentMethods = await $api.$get(`checkouts/${type}/available_payment_methods`);
      setAvailablePaymentMethods(availablePaymentMethods);
    } catch (error) {
      $logger.error(error);
      await setErrorNotification({ nuxtApp, error });
    }
  }

  async function fetchCheckoutViolations (type) {
    const { $api, $logger } = nuxtApp;

    try {
      const violations = await $api.$get(`checkouts/${type}/violations`);
      setViolations(violations);
    } catch (error) {
      $logger.error(error);
      await setErrorNotification({ nuxtApp, error });
    }
  }

  async function fetchCheckoutAvailableAgreeableStatements (type) {
    const { $api, $logger } = nuxtApp;

    try {
      const availableAgreeableStatements = await $api.$get(`checkouts/${type}/available_agreeable_statements`);
      setAvailableAgreeableStatements(availableAgreeableStatements);
    } catch (error) {
      $logger.error(error);
      await setErrorNotification({ nuxtApp, error });
    }
  }

  async function fetchCheckoutAvailableBillingMethods (type) {
    const { $api, $logger } = nuxtApp;

    try {
      const availableBillingMethods = await $api.$get(`checkouts/${type}/available_billing_methods`);
      setAvailableBillingMethods(availableBillingMethods);
    } catch (error) {
      $logger.error(error);
      await setErrorNotification({ nuxtApp, error });
    }
  }

  async function fetchCheckoutAvailableDeliveryMethods (type) {
    const { $api, $logger } = nuxtApp;

    try {
      const availableDeliveryMethods = await $api.$get(`checkouts/${type}/available_delivery_methods`);
      setAvailableDeliveryMethods(availableDeliveryMethods);
    } catch (error) {
      $logger.error(error);
      await setErrorNotification({ nuxtApp, error });
    }
  }

  return {
    checkout,
    shopCheckoutType,
    dealerCheckoutType,
    availableAgreeableStatements,
    availableBillingMethods,
    availablePaymentMethods,
    violations,
    availableDeliveryMethods,
    violationsIsVisible,
    frame,
    checkoutType,
    getFoodCheckout,
    getNonfoodCheckout,
    getCurrentCheckout,
    getSelection,
    violationToNoti,
    setCheckoutType,
    setCheckout,
    setShopCheckout,
    setDealerCheckout,
    setAvailableAgreeableStatements,
    setAvailableBillingMethods,
    setAvailableDeliveryMethods,
    setAvailablePaymentMethods,
    setViolations,
    setAvailableFrames,
    setReservedFrames,
    showViolations,
    hideViolations,
    fetchAvailables,
    deleteReservations,
    fetchReservations,
    patchReservations,
    deleteCheckout,
    clearCheckoutAvailableDeliveryMethods,
    updateCheckoutData,
    updateShopCheckoutData,
    updateDealerCheckoutData,
    fetchCheckout,
    fetchDealerCheckout,
    fetchShopCheckout,
    fetchCheckoutRequest,
    fetchCheckoutMetaData,
    fetchDealerCheckoutMetaData,
    fetchShopCheckoutMetaData,
    fetchCheckoutAvailablePaymentMethods,
    fetchDealerCheckoutAvailablePaymentMethods,
    fetchShopCheckoutAvailablePaymentMethods,
    fetchCheckoutViolations,
    fetchCheckoutAvailableAgreeableStatements,
    fetchCheckoutAvailableBillingMethods,
    fetchCheckoutAvailableDeliveryMethods,

  };
});
