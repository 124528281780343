<template>
  <nuxt-link
    v-slot="{ href, navigate, isActive, isExactActive }"
    :to="to"
    custom
  >
    <lv-button
      v-if="type === 'button'"
      v-bind="$attrs"
      :href="href"
      :title="title"
      @click="navigate"
    >
      <slot />
    </lv-button>
    <a
      v-else
      v-bind="$attrs"
      :class="[
        $style.link,
        $style[`size-${size}`],
        {
          [$style.linkActive]: isActive,
          [$style.linkExactActive]: isExactActive,
          [$style.medium]: mediumFont,
        },
      ]"
      :href="href"
      :title="title"
      :data-aw-is-active="isActive || null"
      @click="navigate"
    >
      <slot />
    </a>
  </nuxt-link>
</template>

<script>
  import LvButton from './LvButton';

  const validSizes = ['', 'xs', 'sm', 'md'];
  const validType = ['link', 'button'];

  export default {
    name: 'LvLink',
    components: { LvButton },
    props: {
      to: {
        type: [String, Object],
        required: true,
      },
      size: {
        type: String,
        default: '',
        validator: value => validSizes.includes(value),
      },
      title: {
        type: String,
        default: undefined,
      },
      mediumFont: {
        type: Boolean,
        default: false,
      },
      type: {
        type: String,
        default: 'link',
        validator: value => validType.includes(value),
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
$link-xs-font-size: 12px !default;
$link-xs-line-height: 13px !default;

$link-sm-font-size: 14px !default;
$link-sm-line-height: 17px !default;

$link-md-font-size: 16px !default;
$link-md-line-height: 19px !default;

.link {
  &:hover,
  &:focus {
    text-decoration: underline;
  }

  &Active {
    /*! noop */
  }

  &ExactActive {
    /*! noop */
  }

  &.size-xs {
    font-size: $link-xs-font-size;
    line-height: $link-xs-line-height;
  }

  &.size-sm {
    font-size: $link-sm-font-size;
    line-height: $link-sm-line-height;
  }

  &.size-md {
    font-size: $link-md-font-size;
    line-height: $link-md-line-height;
  }

  &.medium {
    font-weight: $font-weight-medium;
  }
}
</style>
