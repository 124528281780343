<template>
  <aw-full-flow-chart-cards :content="content" :show-bottom-image="true" :row-id="rowId" />
</template>


<script>
  import analyticsBannerInteractionMixin from '~~/common/mixins/analyticsBannerInteractionMixin.js';
  import { defineAsyncComponent } from 'vue';

  export default {
    name: 'AwFlowChartWithImageCards',
    components: {
      AwFullFlowChartCards: defineAsyncComponent(() => import('./AwFullFlowChartCards')),
    },
    mixins: [analyticsBannerInteractionMixin],
    props: {
      content: {
        type: Object,
        required: true,
      },
      rowId: {
        type: Number,
        default: null,
      },
    },
  };

</script>
