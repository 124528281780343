export function getProductCardSizeV2 (clientWidth) {
  if (typeof clientWidth === 'number') {
    return (clientWidth >= 320
      ? 'desktop'
      : (clientWidth >= 224
        ? 'tablet'
        : 'mobile'
      )
    );
  }
}
