const resources = [
  {
    id: 'product',
    check (p) {
      return p.matchAll(/^.*?\.p[-]?([0-9]+)\/([0-9]+)\.v[-]?([0-9]+)(\?|#|$)/gi).next().value?.[2] || // get SKU number, if URL contain .p and .v and SKU
        p.matchAll(/^.*?\.p[-]?([0-9]+)(\?|#|$)/gi).next().value?.[1] // get p. as SKU number
      ;
    },
  },
  {
    id: 'boutique',
    check (p) {
      return (p.matchAll(/.*?\.b[-]?([0-9]+).*?\.c[-]?([0-9]+)/gi).next().value || []).splice(1).join('|');
    },
  },
  {
    id: 'category',
    check (p) {
      return p.matchAll(/.*?\.c[-]?([0-9]+)/gi).next().value?.[1];
    },
  },
  {
    id: 'search',
    check (p) {
      const q = p.matchAll(/.*?\/search\?(.*?)((?=#)|$)/gi).next().value?.[1];
      if (q) {
        const p = new URLSearchParams(q);
        return 'q[]=' + p.getAll('q[]').join('&q[]=');
      }
    },
  },
  {
    id: 'compare',
    check (p) {
      return p.split('#')[0].split('?')[0].endsWith('/compare') ? 'cmp' : false;
    },
  },
  {
    id: 'shortlist',
    check (p) {
      return p.matchAll(/.*?\/list\/([^?#]*)/gi).next().value?.[1];
    },
  },
];

export function extractResourceFromPath (path) {
  for (const res of resources) {
    const resourceId = res.check(path);
    if (resourceId) {
      return {
        resourceType: res.id,
        resourceId,
      };
    }
  }
}

export function getSubcategoryId ({ href }) {
  const qq = new URLSearchParams(href.split('?').slice(1).join('')).get('qq');
  if (qq) {
    return JSON.parse(qq)?.filterParams?.subcategory?.[0];
  }
}

export function resetPath (path, urlPrefix) {
  return path.replace(urlPrefix, '').replace(/[/]+$/, '').replace(/^[/]+/, '');
}

export function loadBreadcrumb ({
  urlResource,
  category,
  productCategories,
  cmsToken,
  urlPrefix,
  contentRootTypes,
  $awt,
}) {
  return ({
    category () {
      return (category?.parentsNameArr || []).map(item => ({
        token: item.name,
        to: `${urlPrefix}/${item.slug}.c-${item.categoryId}`,
        categoryId: item.categoryId,
      }));
    },
    boutique () {
      return (category?.parentsNameArr || []).map(item => ({
        token: item.name,
        to: `${urlPrefix}/${item.slug}`,
      }));
    },
    product () {
      let tmpSlug = '';
      return productCategories.map((c) => {
        const boutique = c.isBoutique ? `boutique.b-${c.boutiqueContentRef}/` : '';
        tmpSlug = (tmpSlug ? tmpSlug + '/' : '') + c.slug.split('_')[0];
        return {
          token: c.name,
          to: `${urlPrefix}/${boutique}${tmpSlug}.c-${c.id}`,
        };
      });
    },
    cms_content () {
      const result = [];

      if (contentRootTypes?.[0]) {
        let rootType = contentRootTypes[0];
        let to = '';
        let token = rootType;
        switch (rootType) {
          case 'career':
            to = '/karrier';
            break;
          case 'press_release':
            to = '/press';
            token = 'press';
            break;
          case 'career_news':
            to = '/karrier/hirek';
            token = 'career.news';
            break;
          case 'prize_draw':
            to = '/prize-game';
            break;
          default:
            to = `/${rootType.replace('_', '-')}`;
            break;
        }

        result.push({
          token: $awt(`aw.pages.${token}.title`),
          to: to,
          disabled: false,
        });
      }

      return [...result, {
        token: cmsToken,
        to: `/${urlResource.slug}`,
      }];
    },
  })[urlResource?.resourceType];
}
