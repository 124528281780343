import { defineStore } from 'pinia';
import { useNuxtApp } from 'nuxt/app';
import { ref } from 'vue';
import { uuid4 } from '~~/common/utils';
import { useToasterStore } from '~~/common/stores/toaster.js';
import { notificationTokenizer } from '~~/common/utils/helper.js';

let indexCounter = 0;
export const useNotificationStore = defineStore('notification', () => {
  const nuxtApp = useNuxtApp();
  const notifications = ref({});

  function setNotification (indexCounter, notification) {
    const uuid = uuid4();
    notifications.value[indexCounter] = { ...notification, uuid };
  }

  function pushWarning ({ text, manualClose, screenReaderOnly, promise }) {
    setNotification(indexCounter++, {
      type: 'warning',
      manualClose,
      screenReaderOnly,
      text,
      promise,
    });
  }

  function pushInfo ({ text, manualClose, screenReaderOnly, promise }) {
    setNotification(indexCounter++, {
      type: 'info',
      manualClose,
      screenReaderOnly,
      text,
      promise,
    });
  }

  function pushError ({ text, manualClose, screenReaderOnly, promise }) {
    const toasterStore = useToasterStore();
    const { $awt } = nuxtApp;
    const notification = notificationTokenizer({ text }, { awt: $awt });

    if (text?.title?.message?.includes('Request aborted')) {
      return;
    }
    if (text?.title?.message?.includes('Network Error')) {
      toasterStore.push({
        title: $awt('aw.network_error'),
        text: notification.subtitle,
        manualClose,
        screenReaderOnly,
        promise,
      });

    } else {
      toasterStore.push({
        title: notification.title,
        text: notification.subtitle,
        manualClose,
        screenReaderOnly,
        promise,
      });
    }
  }

  function pushSuccess ({ text, manualClose, screenReaderOnly, promise }) {
    const toasterStore = useToasterStore();
    const { $awt } = nuxtApp;
    const notification = notificationTokenizer({ text }, { awt: $awt });
    toasterStore.push({
      title: notification.title,
      text: notification.subtitle,
      manualClose,
      screenReaderOnly,
      promise,
    });
  }

  function remove (key) {
    delete notifications.value[key];
  }

  return {
    notifications,
    setNotification,
    pushError,
    pushWarning,
    pushSuccess,
    pushInfo,
    remove,
  };
});
