import { LT_ANON } from '../../common/config/LoginTypeConfig.js';

export function grantTypeTransform ({
  body,
  cookies,
  clientId,
  clientSecret,
}) {
  const newReqData = {
    ...(body || null),
    client_id: clientId,
    client_secret: clientSecret,
  };

  if (newReqData.grant_type === 'refresh_token') {
    if (cookies.get('login_type') !== LT_ANON && cookies.get('refresh_token')) {
      newReqData.refresh_token = cookies.get('refresh_token');
    } else {
      newReqData.grant_type = 'anonymous';
    }
  }

  return newReqData;
}

export function setLoginCookies ({
  cookies,
  body,
}) {
  // NOTE: Checking !process.client works always, while checking
  // process.server does not. These two vars are not defined in case of
  // modules for seems. (eg proxy module)
  const isServer = !import.meta.client;
  [{
    name: 'token_type',
    value: body.token_type,
    opts: {
      path: '/',
      maxAge: body.expires_in,
      sameSite: 'lax',
    },
  }, {
    name: 'access_token',
    value: body.access_token,
    opts: {
      path: '/',
      maxAge: body.expires_in,
      sameSite: 'lax',
    },
  }, {
    name: 'refresh_token',
    value: body.refresh_token,
    opts: {
      path: '/',
      sameSite: 'lax',
      httpOnly: true,
    },
  }, {
    name: 'login_type',
    value: body.login_type,
    opts: {
      path: '/',
      maxAge: body.expires_in,
      sameSite: 'lax',
    },
  }].filter(c => isServer || !c?.opts?.httpOnly).forEach((c) => {
    if (c?.value === undefined) {
      cookies.remove(c.name, c.opts);
    } else {
      cookies.set(c.name, c.value, c.opts);
    }
  });
  if (body?.refresh_token) {
    delete body.refresh_token;
  }
  // NOTE: Cookies set with cookies.set won't be available with cookies.get
  // until response is sent with the Set-Cookie header so the browser is
  // able to set them.
  return {
    isChangeAsync: isServer,
    isChangeRequiresServer: !isServer,
  };
}
