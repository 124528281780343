<template>
  <client-only>
    <div :class="$style.wrapper">
      <!-- CHART -->

      <div :class="[$style.chartContainer, $style.center]">
        <div :class="[$style.chartBorder, $style.center]">
          <div :class="[$style.chartBackground, $style.center]">
            <div ref="progress" :class="[$style.chartProgress, $style.center]">
              <div :class="[$style.chartDataContainer, $style.center]">
                <div v-if="showBadge || isViewMode" :class="$style.chartDataBadgeWrapper">
                  <span class="awSrOnlyAbs" v-text="levelText" />
                  <lv-icon
                    :key="selectedLevel ?? showedLevel.cardLevel"
                    :size="50"
                    :name="badges[selectedLevel ?? showedLevel.cardLevel]"
                    fill
                    :square="false"
                    :class="$style.chartDataBadge"
                  />
                </div>
                <div v-else :class="$style.chartInfoWrapper">
                  <div :class="$style.chartInfoTextWrapper">
                    <span :class="$style.chartInfoLevelText">{{ levelText }}</span>
                    <span :class="$style.chartInfoLevelSubText">{{ levelSubText }}</span>
                  </div>
                  <div>
                    <span :class="$style.chartInfoSpendingCounter">
                      {{ $price({ gross: displayedSpending }, CURRENCY) }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <template v-if="showProgressBar">
        <div
          v-for="section in sections"
          :key="section.key"
          :class="$style.pointerLayer"
          :style="dynamicPointerLayerStyle(section)"
        >
          <!-- PROGRESS -->
          <svg
            v-if="section.progress"
            width="208"
            height="208"
            :class="[
              $style.progressSvg,
              {
                [$style.progressSvgRotateIn]: !section.needProgressAnimation && section.showProgress,
                [$style.progressSvgRotateOut]: !section.needProgressAnimation && !section.showProgress,
              }
            ]"
          >
            <circle
              :style="dynamicProgressStyle(section)"
              cx="104"
              cy="104"
              r="70"
              v-bind="dynamicProgressAttributes(section)"
            >
              <aw-animate v-if="section.needProgressAnimation" :attributes="dynamicProgressAnimateAttributesUp(section)" />
            </circle>
          </svg>
          <!-- PROGRESS -->

          <!-- POINTERS -->
          <div :class="$style.pointerContainer">
            <div
              v-if="section.showActivePointer"
              :class="[
                $style.pointerActive,
                {
                  [$style.pointerActiveRotateIn]: !section.needProgressAnimation && section.showProgress,
                  [$style.pointerActiveRotateOut]: !section.needProgressAnimation && !section.showProgress,
                }
              ]"
              :style="dynamicPointerStyle(section)"
            >
              <lv-icon
                v-if="section.needPipe"
                :size="10"
                name="pipe"
                fill
                :square="false"
                :class="$style.pointerPipe"
                :style="dynamicPipeStyle(section)"
              />
            </div>
            <div v-if="section.showInactivePointer" :class="$style.pointerInactive" :style="dynamicPointerStyle(section)" />
          </div>
          <!-- POINTERS -->

          <!-- BUMM EFFECT CIRCLES -->
          <div v-if="section.bumm" :class="$style.bummWrapper">
            <div :class="$style.bummLineWaveOut" />
            <div :class="$style.bummLineWaveOut2" />
            <div :class="$style.bummLineWaveIn" />
          </div>
          <!-- BUMM EFFECT CIRCLES -->
        </div>
      </template>

      <!-- CHART -->
    </div>
  </client-only>
</template>

<script setup>

  import { LvIcon } from '~~/common/components/loginet-vue-shop/index.mjs';
  import AwAnimate from './AwAnimate.vue';
  import { useCssModule } from 'vue';
  import { useNuxtApp } from 'nuxt/app';

  const $style = useCssModule();
  const { $awt, $price, $styleVariables } = useNuxtApp();

  const CARD_LEVEL_BASE = 0;
  const CARD_LEVEL_MIDDLE = 1;
  const CARD_LEVEL_GOLD = 2;

  const COLOR_BASE = $styleVariables.colorBronze;
  const COLOR_MIDDLE = $styleVariables.colorSilver;
  const COLOR_GOLD = $styleVariables.colorGold;

  const COLORS = {
    [CARD_LEVEL_BASE]: COLOR_BASE,
    [CARD_LEVEL_MIDDLE]: COLOR_MIDDLE,
    [CARD_LEVEL_GOLD]: COLOR_GOLD,
  };

  const TRANS_COLORS = {
    [CARD_LEVEL_BASE]: $styleVariables.colorBronze12,
    [CARD_LEVEL_MIDDLE]: $styleVariables.colorSilver12,
    [CARD_LEVEL_GOLD]: $styleVariables.colorGold12,
  };

  const CURRENCY = 'HUF';
  const COUNTING_DURATION = 2000;
  const CHART_VIEW_DELAY = 600;
  const SVG_OPACITY_DELAY = 100;
  const ANIMATION_ENDED_DELAY = 1000;

  const props = defineProps({
    cardLevels: {
      type: Array,
      required: true,
    },
    selectedLevel: {
      type: Number,
      required: false,
      default: null,
    },
    actualCardLevel: {
      type: Number,
      required: false,
      default: null,
    },
    actualMonthlySpending: {
      type: Number,
      required: false,
      default: null,
    },
  });

  const badges = ref([
    'base-level-badge',
    'middle-level-badge',
    'gold-level-badge',
  ]);

  const showBadge = ref(false);

  const completedSections = ref([]);

  const displayedSpending = ref(0);
  const startTimestamp = ref(null);

  const showProgressBar = ref(false);

  const isViewMode = computed(() => {
    return props.actualCardLevel == null && props.actualMonthlySpending == null;
  });

  const sections = computed(() => {
    if (completedSections.value.length > 0) {
      return completedSections.value;
    }

    let sections = [
      {
        key: actualCardLevelObj.value.cardLevel,
        progress: calculateProgressFromCardLevelDataAndMonthlySpending(actualCardLevelObj.value, props.actualMonthlySpending),
        background: COLORS[props.actualCardLevel],
        needPipe: false,
        pipeRotate: 0,
        rotate: 0,
        showProgress: true,
        showActivePointer: true,
        showInactivePointer: false,
        animationDelay: CHART_VIEW_DELAY,
        needProgressAnimation: true,
        animationStart: () => {},
        animationEnd: () => { createCompletedSection(); },
      },
    ];

    if (isViewMode.value) {
      sections = [
        {
          key: 0,
          progress: 120,
          background: COLOR_BASE,
          needPipe: false,
          pipeRotate: 0,
          rotate: 0,
          showProgress: props.selectedLevel >= CARD_LEVEL_BASE,
          showActivePointer: true,
          showInactivePointer: false,
          animationDelay: CHART_VIEW_DELAY,
          needProgressAnimation: true,
          zIndex: 3,
          animationStart: () => {},
          animationEnd: () => {},
        },
        {
          key: 1,
          progress: 120,
          background: COLOR_MIDDLE,
          needPipe: true,
          pipeRotate: 180,
          rotate: 120,
          showProgress: props.selectedLevel >= CARD_LEVEL_MIDDLE,
          showActivePointer: true,
          showInactivePointer: !props.selectedLevel >= CARD_LEVEL_MIDDLE,
          needProgressAnimation: false,
          zIndex: 2,
          animationStart: () => {},
          animationEnd: () => {},
        },
        {
          key: 2,
          progress: 120,
          background: COLOR_GOLD,
          needPipe: true,
          pipeRotate: 75,
          rotate: 240,
          showProgress: props.selectedLevel >= CARD_LEVEL_GOLD,
          showActivePointer: true,
          showInactivePointer: props.selectedLevel !== CARD_LEVEL_GOLD,
          needProgressAnimation: false,
          zIndex: 1,
          animationStart: () => {},
          animationEnd: () => {},
        },
      ];
    }

    if (props.actualCardLevel === CARD_LEVEL_MIDDLE) {
      const progress = calculateProgressFromCardLevelDataAndMonthlySpending(actualCardLevelObj.value, props.actualMonthlySpending);

      sections = [
        {
          key: 0,
          progress: progress < 180 ? progress : 180,
          background: COLOR_BASE,
          needPipe: false,
          pipeRotate: 0,
          rotate: 0,
          showProgress: !!progress,
          showActivePointer: !!progress,
          showInactivePointer: !progress,
          animationDelay: CHART_VIEW_DELAY,
          needProgressAnimation: true,
          animationStart: () => {},
          animationEnd: (section) => {
            section.showActivePointer = progress < 180;
            section.showInactivePointer = progress > 180;
          },
        },
        {
          key: 1,
          progress: progress > 180 ? progress - 180 : 0,
          background: COLOR_MIDDLE,
          needPipe: true,
          pipeRotate: 135,
          rotate: 180,
          showProgress: progress > 180,
          showActivePointer: false,
          showInactivePointer: true,
          animationDelay: CHART_VIEW_DELAY + COUNTING_DURATION,
          needProgressAnimation: true,
          animationStart: (section) => {
            section.showActivePointer = progress > 180;
            section.showInactivePointer = !(progress > 180);
          },
          animationEnd: () => { createCompletedSection(); },
        },
      ];
    }

    return sections;
  });

  const actualCardLevelObj = computed(() => {
    return props.cardLevels.find((cardLevel) => cardLevel.cardLevel === (props.actualCardLevel ?? props.selectedLevel)) || {};
  });

  const nextCardLevelObj = computed(() => {
    const { cardLevel } = actualCardLevelObj.value;
    return cardLevel <= 1 ? props.cardLevels[cardLevel + 1] : actualCardLevelObj.value;
  });

  const showedLevel = computed(() => {
    const actualObj = actualCardLevelObj.value;
    const nextObject = nextCardLevelObj.value;
    const { monthlySpendingFrom } = actualCardLevelObj.value;
    if (props.actualCardLevel === CARD_LEVEL_MIDDLE) {
      return (props.actualMonthlySpending < monthlySpendingFrom
        ? actualObj
        : props.actualMonthlySpending > monthlySpendingFrom ? nextObject : actualObj
      );
    }

    return props.actualMonthlySpending < monthlySpendingFrom ? actualObj : nextObject;
  });

  const levelText = computed(() => {
    return $awt('aw.loyalty.chart.level_text', { level: !isViewMode.value ? showedLevel.value.displayName : actualCardLevelObj.value.displayName });
  });

  const levelSubText = computed(() => {
    return props.actualMonthlySpending >= actualCardLevelObj.value.monthlySpendingFrom ? $awt('aw.loyalty.chart.level_text_to_reach') : $awt('aw.loyalty.chart.level_text_to_keep');
  });

  const levelColor = computed(() => {
    return props.actualMonthlySpending < actualCardLevelObj.value.monthlySpendingFrom ? COLORS[props.actualCardLevel] : COLORS[nextCardLevelObj.value.cardLevel];
  });

  const levelTransColor = computed(() => {
    return props.actualMonthlySpending < actualCardLevelObj.value.monthlySpendingFrom ? TRANS_COLORS[props.actualCardLevel] : TRANS_COLORS[nextCardLevelObj.value.cardLevel];
  });

  const calculatedSpending = computed(() => {
    const calculatedSpending = props.actualMonthlySpending < actualCardLevelObj.value.monthlySpendingFrom
      ? actualCardLevelObj.value.monthlySpendingFrom - props.actualMonthlySpending
      : (actualCardLevelObj.value.monthlySpendingTo ? nextCardLevelObj.value.monthlySpendingFrom - props.actualMonthlySpending : 0);

    return props.actualMonthlySpending > actualCardLevelObj.value.monthlySpendingTo ? props.actualMonthlySpending : calculatedSpending;
  });

  function counting (timestamp) {
    if (!startTimestamp.value) startTimestamp.value = timestamp;
    const progress = timestamp - startTimestamp.value;

    const startCountig = actualCardLevelObj.value.monthlySpendingTo !== undefined ? nextCardLevelObj.value.monthlySpendingFrom : actualCardLevelObj.value.monthlySpendingFrom;
    const endCounting = actualCardLevelObj.value.monthlySpendingTo > calculatedSpending.value ? calculatedSpending.value : (actualCardLevelObj.value.monthlySpendingFrom === 0 && props.actualMonthlySpending === 0 ? nextCardLevelObj.value.monthlySpendingFrom : 0);

    const countingDuration = COUNTING_DURATION * sections.value.filter((item) => item.showProgress).length;

    displayedSpending.value = startCountig - ((startCountig - endCounting) * (progress / countingDuration));

    if (progress < countingDuration) {
      window.requestAnimationFrame(counting);
    } else {
      displayedSpending.value = props.actualMonthlySpending > actualCardLevelObj.value.monthlySpendingTo ? 0 : calculatedSpending.value;
    }
  }

  function calculateProgressFromCardLevelDataAndMonthlySpending (cardLevel, monthlySpending) {
    const calculatedProgress = 360 / (cardLevel.monthlySpendingTo ?? cardLevel.monthlySpendingFrom) * monthlySpending;

    return calculatedProgress < 360 ? calculatedProgress : 360;
  }

  function dynamicPointerStyle (section) {
    return `background: ${section.background};`;
  }

  function dynamicPointerLayerStyle (section) {
    return `
      transform: rotate(${section.rotate}deg);
      z-index: ${section.zIndex ?? 'unset'};
    `;
  }

  function dynamicProgressStyle (section) {
    return `
      width: 148px;
      height: 148px;
      stroke: ${section.background};
      stroke-width: 8px;
      fill: transparent;
    `;
  }

  function dynamicPipeStyle (section) {
    return `
      transform: rotate(${section.pipeRotate}deg);
      margin-left: -1px;
      margin-top: -2px;
    `;
  }

  function dynamicProgressAttributes (section) {
    const angle = parseFloat(section.progress) > 20 ? parseFloat(section.progress) - 10 : parseFloat(section.progress) || 0;

    const radius = 70;
    const circumference = 2 * Math.PI * radius;

    const strokeOffset = (section.rotate - 95 / 360) * circumference;
    const strokeDasharray = (angle / 360) * circumference;

    return {
      'stroke-linecap': 'round',
      'stroke-dashoffset': strokeOffset,
      'stroke-dasharray': `${strokeDasharray} ${circumference - strokeDasharray}`,
    };
  }

  function dynamicProgressAnimateAttributesUp (section) {
    const angle = parseFloat(section.progress) - 10 || 0;

    const radius = 70;
    const circumference = 2 * Math.PI * radius;

    const strokeDasharrayFrom = ((angle - parseFloat(section.progress) - 10) / 360) * circumference;
    const strokeDasharrayTo = (angle / 360) * circumference;

    return {
      properties: {
        id: section.key,
        attributeName: 'stroke-dasharray',
        from: `${strokeDasharrayFrom} ${circumference - strokeDasharrayFrom}`,
        to: `${strokeDasharrayTo} ${circumference - strokeDasharrayTo}`,
        dur: `${(COUNTING_DURATION - SVG_OPACITY_DELAY) / 1000}s`,
        begin: `${((section.animationDelay ?? 0) / 1000)}s`,
        keyTimes: '0; 1',
        calcMode: 'spline',
        keySplines: '0.1 0.4 0.1 1',
      },
      onbegin: (event) => {
        setTimeout(() => {
          requestAnimationFrame(() => {
            event.target.parentNode.parentNode.style.opacity = 1;
          });
          section.animationStart(section);
        }, SVG_OPACITY_DELAY);
      },
      onend: () => {
        setTimeout(() => {}, ANIMATION_ENDED_DELAY);
        section.animationEnd(section);
      },
    };
  }

  function createCompletedSection () {
    if (isLevelCompleted()) {
      showBadge.value = true;

      completedSections.value = [
        {
          key: actualCardLevelObj.value.cardLevel,
          progress: 380,
          background: COLORS[showedLevel.value.cardLevel],
          needPipe: false,
          rotate: 0,
          showProgress: true,
          showActivePointer: false,
          showInactivePointer: false,
          animationDelay: CHART_VIEW_DELAY,
          needProgressAnimation: false,
          bumm: true,
        },
      ];
    }
  }

  function isLevelCompleted () {
    return (props.actualMonthlySpending > actualCardLevelObj.value.monthlySpendingTo) || (actualCardLevelObj.value.monthlySpendingTo == null && props.actualMonthlySpending > actualCardLevelObj.value.monthlySpendingFrom);
  }


  onMounted(() => {
    window.setTimeout(() => {
      window.requestAnimationFrame(counting);
    }, CHART_VIEW_DELAY + ANIMATION_ENDED_DELAY + SVG_OPACITY_DELAY);

    window.setTimeout(() => {
      showProgressBar.value = true;
    }, CHART_VIEW_DELAY + ANIMATION_ENDED_DELAY);

  });

</script>

<style module lang="scss" rel="stylesheet/scss">
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 208px;
  height: 208px;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.chart {
  &Border {
    width: 180px;
    height: 180px;

    // animation
    animation-name: chart-border;
    animation-duration: 600ms;
    border: 1px dashed $color-dash-separator;
    border-radius: 50%;
  }

  &Container {
    position: absolute;
    width: 208px;
    height: 208px;
    background-color: $color-background-4;
  }

  &Background {
    width: 160px;
    height: 160px;

    // animation
    animation-name: chart-background;
    animation-duration: 600ms;
    border: 1px solid $color-border-disabled;
    border-radius: 50%;
    background: $color-background-2;
    box-shadow: 0 0 20.1px 0 #433F321F inset;
  }

  &Data {
    &Container {
      width: 120px;
      height: 120px;

      // animation
      animation-name: chart-data-container;
      animation-duration: 600ms;
      border: 1px solid $color-border-disabled;
      border-radius: 50%;
      background-color: $color-background-4;
      box-shadow: 0 0 20px 0 #433F321F;
    }

    &Badge {
      padding-top: 15px;
      // animations
      animation-name: badge;
      animation-duration: 200ms;
      animation-timing-function: cubic-bezier(0.5, 0.9, 0.45, 1.5);

      &Wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100px;
        height: 100px;
      }
    }
  }

  &Progress {
    width: 148px;
    height: 148px;
    border-radius: 50%;
  }

  &Info {
    &Wrapper {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      gap: 4px;
    }

    &TextWrapper {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
    }

    &LevelText {
      font-size: 12px;
      font-weight: $font-weight-bold-v2;
      line-height: 16px;
      color: v-bind(levelColor);

      &::first-letter {
        text-transform: uppercase;
      }
    }

    &LevelSubText {
      font-size: 12px;
      line-height: 16px;
    }

    &SpendingCounter {
      font-size: 16px;
      font-weight: $font-weight-bold-v2;
      line-height: 22px;
      padding: 4px 8px;
      color: v-bind(levelColor);
      border-radius: 12px;
      background-color: v-bind(levelTransColor);
    }
  }
}

.pointer {
  &Container {
    position: relative;
    top: 95px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &Layer {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 208px;
    height: 208px;
    border-radius: 50%;
  }

  &Active {

    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;

    width: 15px;
    height: 15px;

    //animation
    animation-name: pointer-active;
    animation-duration: 600ms;
    border-radius: 100% 100% 500%;
    background: $color-sage-450;
    rotate: 45deg;

    &RotateIn {
      top: -11px;
      right: -15px;
      transform-origin: 0 0;
      animation-name: pointer-active-in;
      animation-duration: 400ms;
      animation-timing-function: cubic-bezier(0.5, 0.9, 0.45, 1.2);
      opacity: 0;
      animation-fill-mode: forwards;
    }

    &RotateOut {
      top: -11px;
      right: -15px;
      transform-origin: 0 0;
      animation-name: pointer-active-out;
      animation-duration: 400ms;
      animation-timing-function: cubic-bezier(0.5, 0.9, 0.45, 1.2);
      opacity: 1;
      animation-fill-mode: forwards;
    }
  }

  &Inactive {

    position: absolute;
    top: -8px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: $color-sage-450;
  }

  &Pipe {
    width: 10px;
    height: 7px;

    &Show {
      display: block;
    }
  }
}

.progress {
  &Layer {
    position: absolute;
    border-radius: 50%;
  }

  &Svg {
    position: absolute;
    opacity: 0;
    border-radius: 50%;

    &RotateIn {
      animation-name: svg;
      animation-duration: 400ms;
      animation-timing-function: cubic-bezier(0.5, 0.9, 0.45, 1.2);
      opacity: 0;
      animation-fill-mode: forwards;
    }

    &RotateOut {
      animation-name: svg-out;
      animation-duration: 400ms;
      animation-timing-function: cubic-bezier(0.5, 0.9, 0.45, 1.2);
      opacity: 1;
      animation-fill-mode: forwards;
    }
  }
}

.bumm {
  &Wrapper {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }

  &LineWave {
    &Out{
      position: absolute;

      //width: 200px;
      //height: 200px;

      //animation
      animation-name: bumm-line-wave-out;
      animation-duration: 1s;
      animation-timing-function: cubic-bezier(.10,.60,.05,1);
      border: 1px solid v-bind(levelColor);
      border-radius: 50%;
      animation-fill-mode: forwards;
    }

    &Out2{
      position: absolute;

      //width: 180px;
      //height: 180px;

      //animation
      animation-name: bumm-line-wave-out-2;
      animation-duration: 1s;
      animation-timing-function: cubic-bezier(.10,.60,.05,1);
      border: 1px solid v-bind(levelColor);
      border-radius: 50%;
      animation-fill-mode: forwards;
    }

    &In{
      position: absolute;

      //width: 82px;
      //height: 82px;

      //animation
      animation-name: bumm-line-wave-in;
      animation-duration: 1s;
      animation-timing-function: cubic-bezier(.10,.60,.05,1);
      border: 1px solid v-bind(levelColor);
      border-radius: 50%;
      animation-fill-mode: forwards;
    }
  }

}

@keyframes chart-border {
  from { width: 90px; height: 90px; opacity: 0; }
  to { width: 180px; height: 180px; opacity: 1; }
}

@keyframes chart-background {
  from { width: 80px; height: 80px; opacity: 0; }
  to { width: 160px; height: 160px; opacity: 1; }
}

@keyframes chart-data-container {
  from { width: 75px; height: 75px; opacity: 0;}
  to { width: 140px; height: 140px; opacity: 1;}
}

@keyframes pointer-active {
  from {
    width: 6px;
    height: 6px;
    opacity: 0;
    border-radius: 50%;
  }

  to {
    width: 15px;
    height: 15px;
    opacity: 1;
    border-radius: 100% 100% 500%;
  }
}

@keyframes badge {
  from {
    transform: translateX(-2.89px) translateY(46.52px) rotate(-45deg);
    opacity: 0;
  }

  to {
    transform: translateX(0) translateY(0) rotate(0deg);
    opacity: 1;
  }
}

@keyframes svg {
  from {
    opacity: 0;
    rotate: -120deg;
  }

  to {
    rotate: 0deg;
    opacity: 1;
  }
}

@keyframes svg-out {
  from {
    rotate: 0deg;
    opacity: 1;
  }

  to {
    rotate: -120deg;
    opacity: 0;
  }
}

@keyframes bumm-line-wave-out {
  from {
    width: 158px;
    height: 158px;
    opacity: 0;
  }

  50% {
    opacity: 0.6;
  }

  to {
    width: 200px;
    height: 200px;
    opacity: 0;
  }
}

@keyframes bumm-line-wave-out-2 {
  from {
    width: 132px;
    height: 132px;
    opacity: 0;
  }

  50% {
    opacity: 0.6;
  }

  to {
    width: 180px;
    height: 180px;
    opacity: 0;
  }
}

@keyframes bumm-line-wave-in {
  from {
    width: 122px;
    height: 122px;
    opacity: 0;
  }

  50% {
    opacity: 0.6;
  }

  to {
    width: 82px;
    height: 82px;
    opacity: 0;
  }
}

@keyframes pointer-active-in {
  from {
    opacity: 0;
    rotate: 0deg;
  }

  to {
    rotate: 45deg;
    opacity: 1;
  }
}

@keyframes pointer-active-out {
  from {
    rotate: 45deg;
    opacity: 1;
  }

  to {
    rotate: 0deg;
    opacity: 0;
  }
}

</style>
