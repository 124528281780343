import { defineNuxtPlugin } from 'nuxt/app';
import { useQueueStore } from '~~/common/stores/queue';

export default defineNuxtPlugin(function ({ $pinia }) {
  const queueStore = useQueueStore($pinia);

  queueStore.$subscribe((mutation, { queue, errorQueue }) => {
    if (queue.length) {
      queueStore.startWorker();
    }
    if (errorQueue.length) {
      queueStore.startErrorWorker();
    }
  });
});
