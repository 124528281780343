<template>
  <div id="recon-test" class="recon-test" />
</template>
<script>

  import { defineNuxtComponent } from 'nuxt/app';

  export default defineNuxtComponent({
    name: 'ReconTestPage',
    head () {
      return {
        meta: [
          {
            name: 'robots',
            content: 'noindex, nofollow',
          },
        ],
      };
    },
  });
</script>

<style module lang="scss" rel="stylesheet/scss">
</style>
