import { computed } from 'vue';
import { useNuxtApp } from 'nuxt/app';
import { DELIVERY_AREA_MODEL } from '~~/shop/awPlugins/app-config';
import { useDeliveryStore } from '~~/shop/stores/delivery';
import { useModalStore } from '~~/common/stores/modal';

const deliveryMethod = computed(() => {
  return useDeliveryStore().setup;
});
const getDeliveryLocation = computed(() => {
  const { type, name, postCode } = deliveryMethod.value || {};
  if (type === DELIVERY_AREA_MODEL.PICK_UP_FROM_STORE) {
    return name;
  }
  return postCode + ' ' + name;
});
const getDeliveryLocationPrefix = computed(() => {
  const { $awt } = useNuxtApp();
  return $awt(deliveryMethod.value?.type === DELIVERY_AREA_MODEL.PICK_UP_FROM_STORE
    ? 'aw.common.header.time_slot_btn.store_here'
    : 'aw.common.header.time_slot_btn.deliver_here',
  );
});
export const getDeliveryLocationText = computed(() => {
  return (deliveryMethod.value
    ? `${getDeliveryLocationPrefix.value} ${getDeliveryLocation.value}`
    : ''
  );
});
export function openDeliveryModal (payload) {
  const modalStore = useModalStore();
  modalStore.openDeliveryMethodModal(payload);
}
