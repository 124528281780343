export class BrowserStats {
  constructor ({ config, $cookies, $logger }) {
    try {
      this._$cookies = $cookies;
      this._$logger = $logger;
      this._browserStats = [];
      this._isSimplePayEnabled = config.isSimplePayEnabled;
    } catch (err) {
      this._$logger.error(err);
    }
  }

  _getSimplePayStats () {
    return (this._isSimplePayEnabled
      ? {
        java: navigator.javaEnabled(),
        lang: navigator.language,
        color: screen.colorDepth,
        height: screen.height,
        width: screen.width,
        tz: new Date().getTimezoneOffset(),
      }
      : {}
    );
  }

  addStats () {
    try {
      this._browserStats = Object.entries(this._getSimplePayStats()).map(([key, value]) => [
        `browserStat_${key}`,
        value,
        {
          path: '/',
          maxAge: 30,
          sameSite: true, // strict
        },
      ]);
      this._browserStats.forEach((bs) => {
        try {
          this._$cookies.set(...bs);
        } catch (err) {
          this._$logger.error(err);
        }
      });
    } catch (err) {
      this._$logger.error(err);
    }
    return this;
  }

  removeStats () {
    try {
      this._browserStats.forEach(([key, , opts]) => {
        try {
          this._$cookies.remove(key, opts);
        } catch (err) {
          this._$logger.error(err);
        }
      });
      this._browserStats = [];
    } catch (err) {
      this._$logger.error(err);
    }
    return this;
  }
}
