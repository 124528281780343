/* eslint no-console: 0 */
import Cookie from 'cookie';

export function awCookieUniversalFactory ({ req, res, parseJSON = true, awIsSecureByDefault = false }) {
  // NOTE: This is an edited version of 2.1.5:
  // https://raw.githubusercontent.com/microcipcip/cookie-universal/v2.1.5/packages/cookie-universal/index.js
  // Added awIsSecureByDefault so secure doesn't have to be set manually each time.
  // This version only supports secure on set, setAll, remove:
  // https://github.com/microcipcip/cookie-universal/tree/v2.1.5/packages/cookie-universal
  const isClient = (
    typeof document === 'object' && typeof document.cookie === 'string'
  );
  const isServer = (
    typeof req === 'object' && typeof res === 'object'
  );
  const isNeither = (!isClient && !isServer) || (isClient && isServer);
  if (isNeither) {
    console.error('awCookieUniversalFactory is not supported in this environment', 'isNeither', isNeither);
  }

  const getHeaders = () => {
    if (isServer) {
      // WARNING awOverwrite
      let requestCookie = req.headers.cookie || '';
      let responseCookiesArr = res?.getHeaders?.()?.['set-cookie']?.map(c => c.split(';')[0]) || [];
      const requestCookiesArr = requestCookie.split(';') || [];
      let headersArr = requestCookiesArr.concat(responseCookiesArr);
      let map = {};
      headersArr.forEach(item => {
        const [key, value] = item.trim().split('=');
        map[key] = value;
      });
      return Object.keys(map).map(key => `${key}=${map[key]}`).join(';');
    }
    if (isClient) {
      return document.cookie || '';
    }
  };

  const getResponseCookies = () => {
    let cookies = res.getHeader('Set-Cookie');
    cookies = typeof cookies === 'string' ? [cookies] : cookies;
    return cookies || [];
  };
  const setResponseCookie = (cookieList) => {
    // WARNING awOverwrite
    const map = {};

    // Iterate over the input array
    cookieList.forEach(item => {
      const [keyValue, ...attributes] = item.split('; '); // Split into key-value pair and attributes
      const [key, value] = keyValue.split('='); // Split key and value

      // If the key already exists, update the value
      // Otherwise, add a new entry
      map[key] = { value, attributes: attributes.join('; ') };
    });

    // Convert the map back into an array
    const uniqueArray = Object.keys(map).map(key => `${key}=${map[key].value}; ${map[key].attributes}`);
    return res.setHeader('Set-Cookie', uniqueArray);
  };

  const parseToJSON = (val, enableParsing) => {
    if (!enableParsing) {
      return val;
    }
    try {
      return JSON.parse(val);
    } catch (err) {
      return val;
    }
  };

  // public api
  const state = {
    parseJSON,
    set (name = '', value = '', opts = { path: '/' }) {
      if (isNeither) {
        return;
      }
      value = typeof value === 'object' ? JSON.stringify(value) : value;
      // WARNING awOverwrite
      const awOpts = {
        secure: awIsSecureByDefault,
        ...opts,
      };

      if (isServer) {
        const cookies = getResponseCookies();
        cookies.push(Cookie.serialize(name, value, awOpts));
        setResponseCookie(cookies);
      } else {
        document.cookie = Cookie.serialize(name, value, awOpts);
      }
    },

    setAll (cookieList = []) {
      if (isNeither) {
        return;
      }
      if (!Array.isArray(cookieList)) {
        return;
      }
      cookieList.forEach((cookie) => {
        const { name = '', value = '', opts = { path: '/' } } = cookie;
        // WARNING awOverwrite
        const awOpts = {
          secure: awIsSecureByDefault,
          ...opts,
        };
        state.set(name, value, awOpts);
      });
    },

    get (name = '', opts = { parseJSON: state.parseJSON }) {
      if (isNeither) {
        return '';
      }
      const cookies = Cookie.parse(getHeaders());
      const cookie = cookies[name];
      return parseToJSON(cookie, opts.parseJSON);
    },

    getAll (opts = { parseJSON: state.parseJSON }) {
      if (isNeither) {
        return {};
      }
      const cookies = Cookie.parse(getHeaders());
      for (const cookie in cookies) {
        cookies[cookie] = parseToJSON(cookies[cookie], opts.parseJSON);
      }
      return cookies;
    },

    remove (name = '', opts = { path: '/' }) {
      if (isNeither) {
        return;
      }
      const cookie = state.get(name);
      if (typeof cookie !== 'undefined') {
        // WARNING awOverwrite
        const awOpts = {
          secure: awIsSecureByDefault,
          ...opts,
          expires: new Date(0),
        };
        state.set(name, '', awOpts);
      }
    },

    removeAll () {
      if (isNeither) {
        return;
      }
      const cookies = Cookie.parse(getHeaders());
      for (const cookie in cookies) {
        state.remove(cookie);
      }
    },

    // expose cookie library
    nodeCookie: Cookie,
  };

  return state;
}
