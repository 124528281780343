<template>
  <div
    :class="[$style.separator, $style[`separatorMarginTop${marginTop}`], $style[`separatorMarginBottom${marginBottom}`]]"
  />
</template>

<script>

  const validMargins = ['none', 'xs', 'sm', 'md', 'search', 'checkout'];

  export default {
    name: 'AwSeparator',
    props: {
      marginTop: {
        type: String,
        default: 'md',
        validator: value => validMargins.includes(value),
      },
      marginBottom: {
        type: String,
        default: 'md',
        validator: value => validMargins.includes(value),
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">

.separator {
  width: 100%;
  border-bottom: 1px dashed $color-dash-separator;
  grid-area: separator;

  &Margin {
    &Top {
      &none {
        margin-top: 0;
      }

      &sm {
        margin-top: 16px;

        @include tablet(min) {
          margin-top: 16px;
        }
      }

      &xs {
        margin-top: 4px;
      }

      &md {
        margin-top: 20px;

        @include tablet(min) {
          margin-top: 40px;
        }
      }

      &search {
        margin-top: 20px;
      }

      &checkout {
        margin-top: 24px;
      }
    }

    &Bottom {
      &none {
        margin-bottom: 0;
      }

      &xs {
        margin-bottom: 4px;
      }

      &sm {
        margin-bottom: 16px;

        @include tablet(min) {
          margin-bottom: 16px;
        }
      }

      &md {
        margin-bottom: 20px;

        @include tablet(min) {
          margin-bottom: 40px;
        }
      }

      &search {
        margin-bottom: 20px;
      }

      &checkout {
        margin-bottom: 20px;

        @include tablet(min) {
          margin-bottom: 24px;
        }
      }
    }
  }
}
</style>
