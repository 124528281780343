import { useUserInterfaceStore } from '~~/common/stores/userInterface';
import { useNuxtApp, useRouter } from 'nuxt/app';

export const ID_HEADER = Symbol('idHeader');
export const ID_MAIN = Symbol('idMain');
export const ID_BASKET_SPLIT = Symbol('idBasketSplit');
export const ID_BASKET_TOP = Symbol('idBasketTop');
export const ID_CHECKOUT_TOP = Symbol('idCheckoutTop');
export const ID_CHECKOUT_CUSTOMER = Symbol('idCheckoutCustomer');
export const ID_CHECKOUT_DELIVERY_INFO = Symbol('idCheckoutDeliveryInfo');
export const ID_CHECKOUT_DELIVERY_DATA = Symbol('idCheckoutDeliveryData');
export const ID_CHECKOUT_BILLING_INFO = Symbol('idCheckoutBillingInfo');
export const ID_CHECKOUT_PAYMENT_INFO = Symbol('idCheckoutPaymentInfo');
export const ID_PRODUCT_TOP = Symbol('idProductTop');
export const ID_PRODUCT_STOCK_INFORMATION = Symbol('idProductStockInformation');
export const ID_PRODUCT_REVIEWS = Symbol('idProductReviews');
export const ID_CATEGORY_TOP = Symbol('idCategoryTop');
export const ID_PRODUCT_LIST_TOP = Symbol('idProductListTop');
export const ID_SEARCH_LIST_TOP = Symbol('idSearchListTop');
export const ID_CMS = Symbol('idCmsTop');
export const ID_FAQ_TOP = Symbol('idFaqTop');

export const Navigation = {
  [ID_HEADER]: 'aw_header',
  [ID_MAIN]: 'aw_main',
  [ID_BASKET_SPLIT]: checkoutType => `basket_${checkoutType}`,
  [ID_BASKET_TOP]: 'basket_top',
  [ID_CHECKOUT_TOP]: 'checkout_top',
  [ID_CHECKOUT_CUSTOMER]: 'checkout_customer',
  [ID_CHECKOUT_DELIVERY_INFO]: 'checkout_delivery_info',
  [ID_CHECKOUT_DELIVERY_DATA]: 'checkout_delivery_data',
  [ID_CHECKOUT_BILLING_INFO]: 'checkout_billing_info',
  [ID_CHECKOUT_PAYMENT_INFO]: 'checkout_payment_info',
  [ID_PRODUCT_TOP]: 'product_top',
  [ID_PRODUCT_STOCK_INFORMATION]: 'product_stock_information',
  [ID_PRODUCT_REVIEWS]: 'product_reviews',
  [ID_CATEGORY_TOP]: 'category_top',
  [ID_PRODUCT_LIST_TOP]: 'product_list_top',
  [ID_SEARCH_LIST_TOP]: 'search_list_top',
  [ID_CMS]: 'cms_top',
  [ID_FAQ_TOP]: 'faq_top',
};

export function focusRef (/* VueComponent */{
  $router = useRouter(),
  $logger = useNuxtApp().$logger,
  $refs,
}, /* Config */{
  refId,
  focusElementRefId,
  scrollElementRef,
}) {
  const { isBodyLocked } = useUserInterfaceStore();
  const routeHash = $router.currentRoute.hash;
  if (!routeHash || routeHash === `#${refId}`) {
    if ($refs?.[refId]) {
      // ref can be both a VueComponent instance or a HTMLElement instance.
      const navigationElement = $refs[refId]?.$el || $refs[refId];
      if (!isBodyLocked) {
        focusAndScrollToElement(navigationElement);
      }
    } else if ($refs?.[focusElementRefId] && scrollElementRef) {
      const navigationFocusElement = $refs[focusElementRefId]?.$el || $refs[focusElementRefId];
      const navigationScrollElement = scrollElementRef || document.body;
      if (!isBodyLocked) {
        focusElement(navigationFocusElement);
        scrollToElement(navigationScrollElement);
      }
    } else {
      $logger.error(`Autofocus failed, target not in refs: ${refId}, ${focusElementRefId}, ${scrollElementRef}.`);
    }
  }
}

export function focusAndScrollToElement (navigationElement) {
  if (navigationElement && document.activeElement !== navigationElement) {
    if (navigationElement.getAttribute('tabindex') === null) {
      navigationElement.setAttribute('tabindex', '-1');
    }
    navigationElement.focus();
    const {
      top: elementTop,
      left: elementLeft,
    } = navigationElement.getBoundingClientRect();
    window.scrollTo({
      top: elementTop + window.pageYOffset - document.getElementById(Navigation[ID_HEADER])?.offsetHeight,
      left: elementLeft + window.pageXOffset,
      behavior: 'smooth',
    });
  }
}

export function focusElement (navigationElement) {
  if (navigationElement && document.activeElement !== navigationElement) {
    if (navigationElement.getAttribute('tabindex') === null) {
      navigationElement.setAttribute('tabindex', '-1');
    }
    navigationElement.focus();
  }
}

export function scrollToElement (navigationElement) {
  const {
    top: elementTop,
    left: elementLeft,
  } = navigationElement.getBoundingClientRect();
  window.scrollTo({
    top: elementTop + window.pageYOffset - document.getElementById(Navigation[ID_HEADER])?.offsetHeight,
    left: elementLeft + window.pageXOffset,
    behavior: 'smooth',
  });
}
