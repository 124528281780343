import { defineStore } from 'pinia';
import { ref } from 'vue';

export const PPS_PLAY = Symbol('PlayPauseStatePlaying');
export const PPS_PAUSE = Symbol('PlayPauseStatePaused');
const acceptedStates = [PPS_PLAY, PPS_PAUSE];

export const usePlayPauseStore = defineStore('playPause', () => {
  const playPauseState = ref({});
  function setPlayPauseState ({ uuid, value }) {
    if (acceptedStates.includes(value)) {
      playPauseState.value[uuid] = value;
    }
  }
  /* removePlayPauseState ({ uuid }) {
    del(this.playPauseState, uuid);
  }, */
  return {
    playPauseState,
    setPlayPauseState,
  };
});
