<template>
  <aw-spinner />
</template>

<script setup>
  import { defineAsyncComponent, markRaw, h } from 'vue';
  import { useNuxtApp, useHead, useId, navigateTo, useRoute } from 'nuxt/app';
  import { useContentMenusStore } from '~~/common/stores/contentMenus.js';
  import { useNotificationStore } from '~~/common/stores/notification';
  import { useModalStore } from '~~/common/stores/modal.js';
  const AwPasswordChangeForm = defineAsyncComponent(() => import('~~/shop/components/AwPasswordChangeForm.vue'));
  const AwSpinner = defineAsyncComponent(() => import('~~/common/components/Common/AwSpinner.vue'));

  defineOptions({
    name: 'CareerPasswordChangePage',
  });

  const { $logger, $awt } = useNuxtApp();

  useHead({
    meta: [
      {
        name: 'robots',
        content: 'noindex,nofollow',
      },
    ],
  });

  const modalId = useId();
  const formId = useId();

  onMounted(async () => {
    try {
      const userHash = useRoute().params.hash;
      const redirectUrl = await getCareerRedirect();
      await navigateTo(`${redirectUrl}`);
      onShowCareerPwdChangeModal({ userHash });
    } catch (err) {
      $logger.error(err);
      try {
        const notificationStore = useNotificationStore();
        notificationStore.pushError({
          text: {
            title: `${err?.message}`,
          },
        });
      } catch (err1) {
        err1.cause = err;
        $logger.error(err1);
      }
    }
  });

  async function getCareerRedirect () {
    try {
      const { fetchContentMenu, getFirstLinkFromContentMenu } = useContentMenusStore();
      return getFirstLinkFromContentMenu(await fetchContentMenu('career-menu')) || '/';
    } catch (err) {
      $logger.error(err);
    }
  }

  function onShowCareerPwdChangeModal ({ userHash }) {
    const {
      addQueueSafeConfirmModal,
      removeQueueSafeConfirmModal,
    } = useModalStore();
    addQueueSafeConfirmModal({
      uuid: modalId,
      data: {
        closable: false,
        data: {
          confirmTitle: $awt('aw.password_change.title'),
          confirmText: '',
          confirmComponent: markRaw({
            render: () => h(
              AwPasswordChangeForm,
              {
                formId,
                userHash,
                isModal: true,
                onSuccess () {
                  removeQueueSafeConfirmModal({ uuid: modalId });
                },
              },
            ),
          }),
          buttons: {
            ok: {
              attrs: {
                type: 'submit',
                form: formId,
                onClick () {},
              },
            },
            cancel: {},
          },
        },
      },
    });
  }
</script>
