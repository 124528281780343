<template>
  <page />
</template>

<script>
  import { defineAsyncComponent } from 'vue';
  import { defineNuxtComponent, useRoute } from 'nuxt/app';
  import { fetchKey } from '~~/common/utils/awRunLegacyAsyncData.js';
  import { useProductStore } from '~~/common/stores/product';

  export default defineNuxtComponent({
    name: 'Product',
    components: {
      Page: defineAsyncComponent(() => import('~/components/Page/Product/Page')),
    },
    fetchKey,
    // IMPORTANT: this method call only if entered this URL: /shop/product/<product-id>
    async asyncData ({
      $logger,
      $pinia,
    }) {
      const { params } = useRoute();
      const productStore = useProductStore($pinia);
      try {
        const productId = params.id;
        await productStore.fetchMainProduct({
          identifierType: 'id',
          id: productId,
        });
      } catch (err) {
        $logger.error(err);
      }
      return {};
    },
  });
</script>
