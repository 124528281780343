<template>
  <div
    :class="[
      $style.awTinyPlusMinusChooser,
      {
        [$style.awTinyPlusMinusChooserIsDoubleSided]: isDoubleSided,
        [$style.awTinyPlusMinusChooserIsDoubleSidedSmall]: isDoubleSidedSmall,
        [$style.awTinyPlusMinusChooserIsHorizontal]: direction === 'horizontal',
        [$style.awTinyPlusMinusChooserIsVertical]: direction === 'vertical',
        [$style.awTinyPlusMinusChooserIsDisabled]: disabled,
        [$style.awTinyPlusMinusChooserIsDoubleSidedSmallInBasket]: isCartPage,
        [$style.awTinyPlusMinusChooserIsHorizontalInBasket]: isCartPage,
      },
    ]"
  >
    <fieldset class="awFieldsetContents">
      <legend class="awSrOnlyAbs" v-text="legendText" />
      <span
        :class="[
          $style.text,
          {
            [$style.textSmall]: isSmall,
          },
        ]"
        v-text="visibleText"
      />
      <button
        v-for="sign in '+-'"
        :key="sign"
        :class="[
          'buttonReset',
          $style.button,
          $style[`button${sign === '+' ? 'Plus' : 'Minus'}`],
          {
            [$style.buttonDisabled]: sign === '+' ? isIncreaseDisabled : isDecreaseDisabled,
          },
        ]"
        type="button"
        :aria-disabled="isSignDisabled(sign)"
        :aria-label="sign === '+' ? increaseLabel : decreaseLabel"
        @click="onClick(sign)"
      >
        <lv-icon :name="sign === '+' ? 'plus-small-16' : 'minus-small-16'" :size="16" />
      </button>
    </fieldset>
  </div>
</template>
<script>
  import { LvIcon } from '~~/common/components/loginet-vue-shop/index.mjs';

  export default {
    name: 'AwTinyPlusMinusChooser',
    components: {
      LvIcon,
    },
    inject: {
      isCartPage: {
        from: 'isCartPage',
        default: false,
      },
    },
    props: {
      legendText: {
        type: String,
        required: true,
      },
      visibleText: {
        type: String,
        required: true,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      isIncreaseDisabled: {
        type: Boolean,
        required: true,
      },
      isDecreaseDisabled: {
        type: Boolean,
        required: true,
      },
      increaseLabel: {
        type: String,
        required: true,
      },
      decreaseLabel: {
        type: String,
        required: true,
      },
      isSmall: {
        type: Boolean,
        required: true,
      },
      isDoubleSided: {
        type: Boolean,
        required: true,
      },
      direction: {
        type: String,
        required: true,
        validator: v => v === 'horizontal' || v === 'vertical',
      },
      isDoubleSidedSmall: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['change'],
    methods: {
      isSignDisabled (sign) {
        return this.disabled || (sign === '+' ? this.isIncreaseDisabled : this.isDecreaseDisabled);
      },
      onClick (sign) {
        if (!this.isSignDisabled(sign)) {
          this.$emit('change', sign === '+' ? 1 : -1);
        }
      },
    },
  };
</script>
<style module lang="scss" rel="stylesheet/scss">
$color-background: $color-white;

.awTinyPlusMinusChooser {
  display: grid;
  overflow: hidden;
  border: 1px solid $color-border;
  border-radius: 12px;
  background: $color-background;
  grid-template-areas:
    "textArea btnPlusArea"
    "textArea btnMinusArea";
  grid-template-columns: 1fr auto;
  gap: 0 4px;

  &IsDoubleSided {
    min-height: 52px;
    grid-template-areas: "btnMinusArea textArea btnPlusArea";
    grid-template-columns: auto 1fr auto;

    .button {
      height: 100%;
    }

    .buttonPlus {
      position: relative;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    .buttonPlus::before {
      position: absolute;
      top: -50%;
      right: -50%;
      bottom: -50%;
      left: -50%;
      display: block;
      content: "";
      transform: scale(0.5);
      border-left: 1px solid $color-border;
    }

    .buttonMinus {
      position: relative;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    .buttonMinus::before {
      position: absolute;
      top: -50%;
      right: -50%;
      bottom: -50%;
      left: -50%;
      display: block;
      content: "";
      transform: scale(0.5);
      border-right: 1px solid $color-border;
    }


    &Small {
      min-height: 28px;

      &InBasket {
        min-height: 30px;
        border-radius: 8px;

        .button {
          padding: 7px 5px 7px 5.5px;
        }
      }
    }
  }

  &IsVertical:not(&IsDoubleSided) {
    padding: 3px 3px 3px 7px;

    .button {
      border: 0.5px solid $color-border;
    }

    .buttonPlus {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    .buttonMinus {
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
    }
  }

  &IsHorizontal:not(&IsDoubleSided) {
    grid-template-areas: "textArea btnMinusArea btnPlusArea";
    gap: 0;
    padding: 3px 3px 3px 7px;
    border-radius: 8px;

    .button {
      border: 0.5px solid $color-border;
    }

    .buttonPlus {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    .buttonMinus {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    .text {
      margin-right: 8px;
    }
  }

  &IsHorizontalInBasket:not(&IsDoubleSided) {
    padding: 3px 3px 3px 11px;

    .button {
      padding: 3.5px;
      border: 0.5px solid $color-border;
    }

    .buttonPlus {
      padding-left: 4px;
      border-left: unset;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }

    .buttonMinus {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }
  }

  &IsDisabled {
    cursor: not-allowed;
    opacity: 0.5;

    .button {
      cursor: not-allowed;
    }
  }

  .button:focus-visible {
    border-color: currentColor;
    box-shadow: 0 0 0 1px $color-background, 0 0 0 2px currentColor;
  }

  &:not(&IsDisabled) {
    .button:not(.buttonDisabled) {
      &:hover {
        color: $color-white;
        background: $color-button-hover;
      }

      &:active {
        color: $color-white;
        background: $color-button-pressed;
      }
    }
  }
}

.button {
  display: flex;
  align-items: center;
  padding: 2px 3px;
  transition: color $animation-speed-fast $animation-timing-function, background $animation-speed-fast $animation-timing-function;
  background: $color-white;
}

.buttonPlus {
  grid-area: btnPlusArea;
}

.buttonMinus {
  grid-area: btnMinusArea;
}

.buttonDisabled {
  color: $color-border;
  background: rgba(243, 240, 237, 0.80);
}

.text {
  font-size: 12px;
  font-weight: 900;
  line-height: 16px;
  align-self: center;
  text-align: center;
  grid-area: textArea;
}

.textSmall {
  font-size: 10px;
}
</style>
