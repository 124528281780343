<template>
  <span :title="$awt('awd.common.aided_product')" :class="$style.isAided">
    <lv-icon :name="`hand-holding-dollar-solid-${sizes[size]}`" :size="sizes[size]" />
  </span>
</template>

<script>
  import { LvIcon } from '~~/common/components/loginet-vue-shop/index.mjs';

  const sizes = {
    md: 32,
    lg: 40,
  };

  const validSizes = Object.keys(sizes);

  export default {
    name: 'AdcIsAided',
    components: {
      LvIcon,
    },
    props: {
      size: {
        type: String,
        required: true,
        validator (val) {
          return validSizes.includes(val);
        },
      },
    },
    data () {
      return {
        sizes,
      };
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
.isAided {
  color: $color-government-aid;
}
</style>
