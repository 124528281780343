// Pinia Store
import { defineStore } from 'pinia';
import { useNuxtApp } from 'nuxt/app';
import { ref } from 'vue';
import { setErrorNotification } from '~~/common/utils/helper.js';

export const useDealerNotificationStore = defineStore('dealerNotification', () => {
  const nuxtApp = useNuxtApp();


  const notifications = ref(null);

  const modalsFinished = ref(true);

  async function fetchNotifications () {
    const {
      $api,
      $logger,
    } = nuxtApp;
    try {
      notifications.value = await $api.$get('/dealer/actual_messages');
      modalsFinished.value = false;
    } catch (error) {
      $logger.error(error);
      setErrorNotification({ nuxtApp, error });
    }
  }

  function finishModals () {
    modalsFinished.value = true;
  }

  return {
    notifications,
    modalsFinished,
    finishModals,
    fetchNotifications,
  };
});
