export default function objectToQueryString (obj) {
  const state = {
    isFirst: true,
    getDelimiter () { return this.isFirst ? '?' : '&'; },
  };
  return Object.keys(obj).reduce(function (str, key) {
    if (Array.isArray(obj[key])) {
      return obj[key].reduce(function (str, objval) {
        return generateURIComponent(str, state, obj, key + '[]', objval);
      }, str);
    } else {
      return generateURIComponent(str, state, obj, key, obj[key]);
    }
  }, '');
}

function generateURIComponent (str, state, obj, key, objval) {
  const encKey = encodeURIComponent(key);
  const encVal = encodeURIComponent(objval);
  let encoded = str;
  if (objval !== null) {
    encoded = [str, state.getDelimiter(), encKey, '=', encVal].join('');
    state.isFirst = false;
  }
  return encoded;
}
