export const M_DELIVERY_METHOD = 'mDeliveryMethod';
export const M_DELIVERY_TIMEFRAME_EXPIRED = 'mDeliveryTimeframeExpired';
export const M_GDPR = 'mGdpr';
export const M_STATIC_CONTENT = 'mStaticContent';
export const M_PROFILE = 'mProfile';
export const M_CART_MERGE = 'mCartMerge';
export const M_START_POPUP = 'mStartPopup';
export const M_CONFIRM_USER_PWD = 'mConfirmUserPwd';
export const M_MAX_ITEM_WARNING = 'mMaxItemWarning';
export const M_LIST_SEARCH = 'mListSearch';
export const M_FILTER = 'mFilter';
export const M_CAREER_FILTER = 'mCareerFilter';
export const M_COMPARE_CAT = 'mCompareCat';
export const M_PROD_GALLERY = 'mProdGallery';
export const M_PROD_STOCK = 'mProdStock';
export const M_PROD_OLD = 'mProdOld';
export const M_QUEUE_SAFE_CONFIRM = 'mQueueSafeConfirm_';
export const M_FAV = 'mFav';
export const M_FAV_VERSION2 = 'mFavVersion2';
export const M_BARCODE = 'mBarcode';
export const M_CHANGE_PWD = 'mChangePwd';
export const M_ALERT = 'mAlert';
export const M_LOG_AND_REG = 'mLogAndReg';
export const M_CHECKOUT_ERROR_MODAL = 'mCheckoutErrorModal';
export const M_DELETE_ORDER = 'mDeleteOrder';
export const M_EDIT_ORDER = 'mEditOrder';
export const M_PROF_SAVED_BASKETS = 'mProfSavedBaskets';
export const M_BASKET_ITEM_REMOVE = 'mBasketItemRemove';
export const M_COUPON_DETAILS = 'mCouponDetails';
export const M_FULL_VIDEO_CARDS = 'mFullVideoCards';
export const M_VIDEO_CARD = 'mVideoCard';
export const M_BUTTONS_CMS_CONTENT = 'mButtonsCmsContent';
export const M_CAREER_ACCOUNT_REGISTRATION = 'mCareerAccountRegistration';
export const M_CAREER_RECOMMENDATION_TO_ACQUAINTANCE = 'mCareerRecommendationToAcquaintance';
export const M_CART_REPLACEMENT_PRODUCTS = 'mCartReplacementProducts';
export const M_WARNING = 'mWarning';
export const M_INFO = 'mInfo';
