import { defineStore } from 'pinia';
import { navigateTo, useNuxtApp } from 'nuxt/app';
import { ref } from 'vue';
import { setErrorNotification } from '~~/common/utils/helper';
import { useUserStore } from '~~/common/stores/user';
import { useAuthenticationStore } from '~~/common/stores/authentication.js';

let storePromise;
let dealerUserStorePromise = {
  key: null,
  promise: null,
};

export const useAuchanStoresStore = defineStore('auchanStore', () => {
  const nuxtApp = useNuxtApp();

  const userStore = useUserStore();
  const authenticationStore = useAuthenticationStore();

  const dealerUserStore = ref({});
  const stores = ref([]);
  const sessionStore = ref(null);
  const sessionStoreInProgress = ref(false);

  function setDealerUserStore ({
    userId,
    store,
  }) {
    dealerUserStore.value = {
      userId,
      store,
    };
  }

  function setAuchanStores (payload) {
    stores.value = payload;
  }

  function setSessionAuchanStore (payload) {
    sessionStore.value = payload;
  }

  function setSessionAuchanStoreInProgress (payload) {
    sessionStoreInProgress.value = payload;
  }

  async function fetchDealerUserStore () {
    const {
      $api,
      $logger,
    } = nuxtApp;
    try {
      const userId = userStore?.data?.id;
      if (dealerUserStorePromise.promise && dealerUserStorePromise.key === userId) {
        await dealerUserStorePromise.promise;
      } else if (userId !== dealerUserStore.value?.userId) {
        if (userId) {
          dealerUserStorePromise = {
            key: userId,
            promise: $api.$get('/me/store'),
          };
          setDealerUserStore({
            userId,
            store: await dealerUserStorePromise.promise,
          });
          dealerUserStorePromise = {
            key: null,
            promise: null,
          };
        } else {
          setDealerUserStore({});
        }
      }
    } catch (error) {
      $logger.error(error);
      setErrorNotification({ nuxtApp, error });
    }
  }

  async function fetchAuchanStores () {
    const {
      $api,
      $logger,
    } = nuxtApp;

    if (!storePromise) {
      try {
        storePromise = $api.$get('stores');
        setAuchanStores(await storePromise);
      } catch (error) {
        $logger.error(error);
        setErrorNotification({ nuxtApp, error });
      }
    } else {
      return storePromise;
    }
  }

  async function fetchSessionAuchanStore () {
    const {
      $api,
      $logger,
    } = nuxtApp;

    try {
      setSessionAuchanStoreInProgress(true);
      setSessionAuchanStore(await $api.$get('me/session/store'));
    } catch (error) {
      $logger.error(error);
      setErrorNotification({ nuxtApp, error });
    }
    setSessionAuchanStoreInProgress(false);
  }

  async function pushSessionAuchanStore (storeCode) {
    const {
      $api,
      $logger,
    } = nuxtApp;

    const userStore = useUserStore();

    await authenticationStore.reInitToken();

    try {
      setSessionAuchanStoreInProgress(true);
      setSessionAuchanStore(await $api.$put('me/session/store', { storeCode }));
    } catch (error) {
      $logger.error(error);
      setErrorNotification({ nuxtApp, error });
    }
    await userStore.refreshProductsData();
    setSessionAuchanStoreInProgress(false);
  }

  async function revokeSessionAuchanStore () {
    const {
      $api,
      $logger,
      $localePath,
    } = nuxtApp;
    try {
      setSessionAuchanStoreInProgress(true);
      await $api.$delete('me/session/store');
      setSessionAuchanStore(null);
    } catch (error) {
      $logger.error(error);
      setErrorNotification({ nuxtApp, error });
    }
    navigateTo($localePath('/'));
    setSessionAuchanStoreInProgress(false);
  }

  return {
    dealerUserStore,
    stores,
    sessionStore,
    sessionStoreInProgress,
    setDealerUserStore,
    setAuchanStores,
    setSessionAuchanStore,
    setSessionAuchanStoreInProgress,
    fetchDealerUserStore,
    fetchAuchanStores,
    fetchSessionAuchanStore,
    pushSessionAuchanStore,
    revokeSessionAuchanStore,
  };

});
