<template>
  <div :class="wrapClasses ? wrapClasses : $style.mainContainer">
    <fieldset class="awFieldsetContents">
      <legend class="awSrOnlyAbs" v-text="legendText" />
      <div
        :class="childClasses ? childClasses : [
          $style[`rowSpace${rowSpace}`],
          $style[`columnSpace${columnSpace}`],
          {
            [$style.row]: row,
            [$style.column]: !row,
            [$style.rowCentered]: centered,
          },
        ]"
      >
        <slot />
      </div>
    </fieldset>
  </div>
</template>

<script setup>
  import { provide, computed } from 'vue';
  import { useId } from 'nuxt/app';

  const props = defineProps({
    modelValue: {
      type: [String, Number, Boolean],
      default: '',
    },
    legendText: {
      type: String,
      required: true,
    },
    row: {
      type: Boolean,
      default: false,
    },
    rowSpace: {
      type: String,
      validator: value => ['none', 'md'].includes(value),
      default: 'none',
    },
    columnSpace: {
      type: String,
      validator: value => ['none', 'sm', 'md'].includes(value),
      default: 'sm',
    },
    centered: {
      type: Boolean,
      default: false,
    },
    wrapClasses: {
      type: [String, Array, Object, null],
      default: null,
    },
    childClasses: {
      type: [String, Array, Object, null],
      default: null,
    },
  });
  const emits = defineEmits([
    'update:modelValue',
  ]);
  provide('radioGroupUuid', useId());
  provide('radioGroupModelValue', {
    radioGroupValueComputed: computed(() => props.modelValue),
    changeRadioGroupValueTo: changeRadioGroupValue,
  });
  function changeRadioGroupValue (newVal) {
    emits('update:modelValue', newVal);
  }
</script>

<style module lang="scss" rel="stylesheet/scss">
.mainContainer {
  .row,
  .column {
    &Spacenone {
      gap: 0;
    }
  }

  .row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-start;

    &Spacemd {
      gap: 20px;

      @include tablet(min) {
        gap: 24px;
      }

      @include desktop-small(min) {
        gap: 32px;
      }

      @include desktop-medium(min) {
        gap: 40px;
      }
    }

    &Centered {
      flex-wrap: nowrap;
      justify-content: center;
      width: 100%;
    }

  }

  .column {
    display: grid;
    align-items: center;
    flex-flow: column;
    flex-wrap: wrap;
    grid-template-columns: minmax(auto, 320px);

    &Space {
      &sm {
        gap: 16px;
      }

      &md {
        gap: 20px;
      }
    }
  }
}
</style>
