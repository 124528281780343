<template>
  <common-default-layout
    :spring-size="springSize"
    :small-header="smallHeader"
    :is-aw-spring="isAwSpring"
    :spring-desktop-size="springDesktopSize"
  >
    <template v-if="$slots.categoryMenu" #categoryMenu />
    <template #content="{ defaultLayoutClasses }">
      <slot name="content" :default-layout-classes="defaultLayoutClasses">
        <div :class="[defaultLayoutClasses.container]">
          <slot />
        </div>
      </slot>
    </template>
    <template #modal>
      <aw-expire-timeframe-modal v-if="isExpireTimeframeModalOpened" />
      <aw-gdpr-acceptance-modal v-if="isGdprOpened" :key="`gdpr-acceptance-modal-${isGdprOpened}`" />
      <aw-static-content-modal v-if="isStaticContentModalOpened" />
      <aw-profile-modal v-if="isProfileModalOpened" />
      <aw-cart-merge-modal v-if="isCartMergeOpened" :key="`cart-merge-modal-modal-${isCartMergeOpened}`" />
      <aw-delivery-method-modal v-if="isDeliveryMethodOpened" :key="`delivery-modal-${isDeliveryMethodOpened}`" />
      <aw-startup-popup v-if="isStartupPopupOpened" />
    </template>
  </common-default-layout>
</template>

<script>
  import { defineAsyncComponent } from 'vue';
  import { mapActions, mapState } from 'pinia';
  import { navigateTo, useNuxtApp } from 'nuxt/app';
  import CommonDefaultLayout from '~~/common/layouts/default';
  import { commitAlcoholAlertModal, commitAlcoholConfirmModal } from '~~/common/utils/alcoholManagement';
  import {
    M_GDPR,
    M_DELIVERY_METHOD,
    M_CART_MERGE,
    M_DELIVERY_TIMEFRAME_EXPIRED,
    M_STATIC_CONTENT,
    M_PROFILE,
    M_START_POPUP,
  } from '~~/common/config/Modal.js';
  import { setErrorNotification } from '~~/common/utils/helper';
  import { useCookieConsentStore } from '~~/shop/stores/cookieConsent';
  import { useDeliveryStore } from '~~/shop/stores/delivery';

  import { useModalStore } from '~~/common/stores/modal';
  import { useOrderStore } from '~~/common/stores/order';
  import { useUserInterfaceStore } from '~~/common/stores/userInterface';
  import { useConfigStore } from '~~/common/stores/config';
  import { useProductsStore } from '~~/common/stores/products';
  import { useProductStore } from '~~/common/stores/product';
  import { useUserStore } from '~~/common/stores/user';
  import pageTypeMixin from '~~/common/mixins/pageTypeMixin.js';

  export default {
    name: 'ShopDefaultLayout',
    components: {
      AwCartMergeModal: defineAsyncComponent(() => import('~~/common/components/Common/Modals/AwCartMergeModal')),
      AwProfileModal: defineAsyncComponent(() => import('~~/shop/components/Common/Modals/AwProfileModal')),
      AwGdprAcceptanceModal: defineAsyncComponent(() => import('~~/shop/components/Common/Modals/AwGdprAcceptanceModal')),
      AwStaticContentModal: defineAsyncComponent(() => import('~~/shop/components/Common/Modals/AwStaticContentModal')),
      AwExpireTimeframeModal: defineAsyncComponent(() => import('~~/shop/components/Common/Modals/AwExpireTimeframeModal')),
      CommonDefaultLayout,
      AwDeliveryMethodModal: defineAsyncComponent(() => import('~~/shop/components/Common/Modals/AwDeliveryMethodAndTimeframeModal')),
      AwStartupPopup: defineAsyncComponent(() => import('~~/shop/components/Common/Modals/AwStartupPopup')),
    },
    mixins: [
      pageTypeMixin,
    ],
    props: {
      springSize: {
        type: [String, Boolean],
        default: 'md',
      },
      springDesktopSize: {
        type: Number,
        default: undefined,
      },
      isAwSpring: {
        type: Boolean,
        default: false,
      },
      smallHeader: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        pageLoader: true,
      };
    },
    computed: {
      ...mapState(useUserStore, {
        user: state => state.data,
        isEcomUser: state => state.isEcomUser,
        showAlcoholAlertModalBeforeLogIn: state => state.showAlcoholAlertModalBeforeLogIn,
        userAgeConfirmed: state => state.data?.personalInfo?.ageConfirmed,
      }),
      ...mapState(useConfigStore, {
        startPopup: state => state.configCommon?.startPopup,
      }),
      ...mapState(useUserInterfaceStore, {
        screenRange: state => state.mediaQueries,
      }),
      ...mapState(useProductsStore, {
        containsAgeRestrictedProduct: state => state.containsAgeRestrictedProduct,
      }),
      ...mapState(useModalStore, {
        isCartMergeOpened: state => state.activeGenericModal.type === M_CART_MERGE,
        isDeliveryMethodOpened: state => state.activeGenericModal.type === M_DELIVERY_METHOD,
        isGdprOpened: state => state.activeGenericModal.type === M_GDPR,
        isExpireTimeframeModalOpened: state => state.activeGenericModal.type === M_DELIVERY_TIMEFRAME_EXPIRED,
        isStaticContentModalOpened: state => state.activeGenericModal.type === M_STATIC_CONTENT,
        isProfileModalOpened: state => state.activeGenericModal.type === M_PROFILE,
        isStartupPopupOpened: state => state.activeGenericModal.type === M_START_POPUP,
        confirmModal: state => state.modals.confirmModal,
      }),
      ...mapState(useProductStore, {
        currentPageContainAgeRestrictedProduct (state) {
          return (this.containsAgeRestrictedProduct && this.isProductListPage) || // we are on product list page
            (this.containsAgeRestrictedProduct && this.isSearchPage) || // we are on search page
            (state?.data?.adultsOnly && this.isProductDetailsPage) // we are on product page
          ;
        },
      }),
      ...mapState(useDeliveryStore, {
        deliverySetup: state => state.setup,
      }),
    },
    watch: {
      isEcomUser (newValue) {
        if (newValue) {
          if (this.showAlcoholAlertModalBeforeLogIn && this.userAgeConfirmed == null) {
            commitAlcoholConfirmModal(this.$awt);
            useUserStore().setShowAlcoholAlertModalBeforeLogIn(false);
          }
        }
      },
      // check delivery area is changed -> if changed, then save laterOpen flag. If close modal we check this.
      deliverySetup (newValue) {
        const modalStore = useModalStore();
        // if change delivery area/store
        if (newValue) {
          modalStore.setAlertModalLaterOpen();
        }
      },
      // check delivery modal is currently close the user
      isDeliveryMethodOpened (newValue, oldValue) {
        const modalStore = useModalStore();
        const selectedPostCode = this.$cookies.get('selected_postcode');
        if (
          !newValue &&
          oldValue &&
          (selectedPostCode?.closed || modalStore.modals.alertModal.laterOpen) &&
          this.currentPageContainAgeRestrictedProduct
        ) {
          if (this.isEcomUser && this.userAgeConfirmed == null) {
            commitAlcoholConfirmModal(this.$awt);
          } else if (!this.isEcomUser) {
            commitAlcoholAlertModal(this);
          }
        } else {
          modalStore.resetAlertModalLaterOpen();
        }
      },
      // watch ageConfirm modal YES answer
      async 'confirmModal.confirmOk.type' (newValue) {
        const modalStore = useModalStore();
        if (newValue === 'ageConfirm') {
          await this.saveAgeConfirmation(true);
          modalStore.resetConfirmOk();
        }
      },
      // watch ageConfirm modal NO answer
      async 'confirmModal.confirmCancel.type' (newValue) {
        const modalStore = useModalStore();
        if (newValue === 'ageConfirm') {
          await this.saveAgeConfirmation(false);
          modalStore.resetConfirmCancel();
        }
      },
    },
    mounted () {
      const modalStore = useModalStore();
      this.pageLoader = false;

      window.addEventListener('CookiebotOnAccept', this.cookieAcceptance, { once: true });
      window.addEventListener('CookiebotOnDecline', this.cookieDeclination, { once: true });

      if (!this.$cookies.get('startup_popup_closed') && Boolean(this.startPopup)) {
        modalStore.openStartupPopup();
      }
    },
    beforeUnmount () {
      window.removeEventListener('CookiebotOnAccept', this.cookieAcceptance);
      window.removeEventListener('CookiebotOnAccept', this.cookieDeclination);
    },
    methods: {
      async saveAgeConfirmation (status) {
        const productsStore = useProductsStore();
        const orderStore = useOrderStore();
        try {
          // save confirm status
          await this.$api.$put('/me/age_confirmation', {
            confirmed: status,
          });
          // remove NO confirm cookie if user press YES
          if (status) {
            this.$cookies.remove('under18');
          }
          // refresh user data and shopping lists
          const userResult = await this.$api.$get('/me');
          useUserStore().setUserData({
            ...userResult,
            shoppingLists: this.user.shoppingLists,
          });

          // refresh shopLists
          await this.$nuxt.refresh();

          // refresh product list
          if (this.isProductListPage) {
            // set NO confirm to cookie during session if we are on product list page, because on server side not stored this value
            if (!status) {
              this.$cookies.set('under18', true, { path: '/' });
            }
            await productsStore.throttledRefresh();
          }

          // refresh search page list
          if (this.isSearchPage) {
            // set NO confirm to cookie during session if we are on search page, because on server side not stored this value
            if (!status) {
              this.$cookies.set('under18', true, { path: '/' });
            }
            await productsStore.throttledRefresh();
          }

          // refresh product page AND redirect to HOME page if press no
          if (this.isProductDetailsPage) {
            if (!status) {
              await navigateTo(this.localePath('/'));
            }
          }

          // if we confirm from profile/orders page
          orderStore.setAgeConfirm(status);
        } catch (error) {
          this.$logger.error(error);
          await setErrorNotification({ nuxtApp: useNuxtApp(), error });
        }
      },
      ...mapActions(useCookieConsentStore, ['setAgreement', 'setMarketing']),

      cookieDeclination () {
        this.setAgreement(false);
        this.setMarketing(false);
      },
      cookieAcceptance () {
        this.setAgreement(true);
        if (window.Cookiebot?.consent?.marketing) {
          this.setMarketing(true);
        }
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: $color-seashell;
}

.container {
  flex: 1;
  margin-top: 60px;
  padding-bottom: 24px;

  @include md(max) {
    margin-top: 24px;
    padding-bottom: 16px;
  }
}

.overflowHidden {
  overflow: hidden;
}

.sticky {
  position: sticky;
  z-index: 7;
  top: 0;
}

.headerSearch {
  margin: 16px;

  &Wrapper {
    position: relative;
    width: 100%;
    max-width: 288px;
    margin: auto;
  }

  &Bar {
    width: 100%;
    max-width: 288px;
    height: 56px;
    padding: 8px 56px 8px 16px;
    border: 1px solid $color-alto;
    border-radius: 8px;
    background: $color-white;
  }

  &Icon {
    position: absolute;
    top: 16px;
    right: 16px;
    bottom: 16px;
  }
}
</style>
