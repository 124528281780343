import { useNotificationStore } from '~~/common/stores/notification';
import { useModalStore } from '~~/common/stores/modal';

export function notificationTokenizer ({ text }, { awt }) {
  const textObject = text || {};
  const result = {};
  for (const key of [
    'title',
    'subtitle',
    'footer',
    'tooltip',
  ] ) {
    const text = textObject[key]; // title
    const token = textObject[key + 'Token']; // titleToken
    const params = textObject[key + 'Params']; // titleParams
    result[key] = token ? awt(token, params) : text;
  }
  return result;
}

function collectPayload (nuxtApp, error, manualClose, enableTitleMerge) {
  const $awt = nuxtApp.$awt || function (arg) {
    /* eslint-disable-next-line no-console */
    console.warn('awt is not found!, nuxtApp is probably not defined');
    return arg;
  };
  let title = typeof error === 'string' ? error : '';
  let mainTitle = title; // for AwApiConfirmModal.vue
  let subtitle = '';
  let titleMerge = false;

  if (error?.response) {
    if (error.response?.data?.title) {
      title = error.response.data.title;
      titleMerge = true;
    }
    if (error.response?.data?.message) {
      title = (titleMerge && enableTitleMerge ? `${title} ` : '') + error.response.data.message;
      titleMerge = true;
      mainTitle = title;
    } else if (error.response?.data?.detail) { // for dealer instance response
      subtitle = error.response.data.detail;
      mainTitle = title;
    }
    if (error.response?.data?.messageToken) {
      title = (titleMerge && enableTitleMerge ? `${title} ` : '') + $awt(error.response.data.messageToken);
      titleMerge = true;
      mainTitle = title;
    }
    // if error contain detailed information
    if (Array.isArray(error.response?.data?.errors)) {
      error.response.data.errors.forEach((e) => {
        title = (titleMerge && enableTitleMerge ? `${title} ` : '') + e.message;
        // because this message type is too long
        manualClose = true;
      });
    }
  }

  return {
    httpCode: error?.response?.status,
    manualClose,
    mainTitle,
    text: {
      title,
      subtitle,
    },
  };
}

/**
 * @returns Promise<{ name: string, message: string|undefined }>
 * */
export function setErrorNotification ({ nuxtApp, error, manualClose = false, closable = true, forceModal = false, fromSelfcare = false, enableTitleMerge = true } = {}) {
  const modalStore = useModalStore();
  const payload = collectPayload(nuxtApp, error, manualClose, enableTitleMerge);
  const resolveOption = { 428: 'auto', 412: 'force' }[payload.httpCode];

  // open confirm modal and save API data for second API call
  if ([422, 428].includes(payload.httpCode) || forceModal) { // Unprocessable Entity, Precondition Required
    if (import.meta.server) {
      return new Promise((resolve) => {
        resolve({ name: 'accept', message: resolveOption });
      });
    }

    modalStore.showApiConfirmModal({
      data: {
        resolveOption,
        errorData: { ...payload, errors: error?.response?.data?.errors, error_scope: error?.response?.data?.scope },
        fromSelfcare,
      },
      closable: payload.httpCode === 422 ? false : closable,
    });

    return modalStore.lastApiConfirmModal.promise;
  } else if (payload.httpCode !== 412) {
    const notificationStore = useNotificationStore();
    notificationStore.pushError(payload);
  }

  return Promise.resolve({ name: 'accept', message: resolveOption });
}

export function getErrorNotification (nuxtApp, error, manualClose = false, apiData = false) {
  let payload = collectPayload(nuxtApp, error, manualClose);

  //
  if (payload.httpCode === 428) { // Precondition Required
    payload = {
      ...error,
      ...payload,
      ...{
        errorData: { ...payload, errors: error?.response?.data?.errors },
        apiData: {
          ...apiData,
          ...{
            ...apiData.storeActionParameters,
            ...{
              resolveOption: 'auto',
            },
          },
        },
      },
      type: 'info',
      iconName: 'education-16',
      manualClose: false,
    };
  } else if (payload.httpCode === 412) { //  Precondition Failed
    payload = {
      ...payload,
      ...{
        errorData: payload,
        apiData: {
          ...apiData,
          ...{
            ...apiData.storeActionParameters,
            ...{
              resolveOption: 'force',
            },
          },
        },
      },
    };
  }
  return {
    ...{
      type: 'error',
      iconName: 'error-16',
    },
    ...payload,
  };
}

function getErrorConstraintValidations (error) {
  if (error.response?.data?.scope !== 'data_validation') {
    return false;
  }

  const propertyViolations = {};
  const violations = error.response?.data?.constraintViolations || error.response?.data?.violations || {};

  for (const v in violations) {
    if (!Object.prototype.hasOwnProperty.call(violations, v)) {
      continue;
    }

    const key = violations[v].propertyPath;
    if (typeof propertyViolations[key] === 'undefined') {
      propertyViolations[key] = [];
    }
    propertyViolations[key].push(violations[v].message);
  }

  return propertyViolations;
}

export function backendValidator (formVm, validateIf) {
  return (value, fieldVm) => {
    const propertyViolations = formVm.backendValidation.errors;
    const propertyPath = formVm.backendValidation.propertyPaths[fieldVm.name] || null;
    if (typeof formVm[fieldVm.name] !== 'undefined') {
      if (!formVm[fieldVm.name].tokens) {
        formVm[fieldVm.name].tokens = {};
      }
      formVm[fieldVm.name].tokens.backend = (propertyViolations[propertyPath] || []).join(' | ');
    }
    return !value || !propertyPath || !propertyViolations || (validateIf && !validateIf()) || (typeof propertyViolations[propertyPath] === 'undefined');
  };
}

function getErrorTypeTokenError (error) {
  const errorData = error?.response?.data || {};
  if (!errorData?.errorTypeToken) {
    return false;
  }
  const errorTypeTokenMap = {
    'user.error.wrong_current_password': 'currentPassword',
    'user.error.same_passwords': 'password',
    'user.error.passwords_no_match': 'confirmPassword',
    'user.error.repeated_password': 'password',
  };

  const result = {};
  const fieldName = errorTypeTokenMap[errorData.errorTypeToken] || null;
  if (!fieldName) {
    return false;
  }
  result[fieldName] = [errorData.message];
  return result;
}

export function setValidationFromError (formVm, error, nuxtApp) {
  const constraintViolations = getErrorConstraintValidations(error);
  const errorTypeTokenErrors = getErrorTypeTokenError(error);
  if (constraintViolations || errorTypeTokenErrors) {
    if (constraintViolations !== false) {
      formVm.backendValidation.errors = constraintViolations;
    }
    if (errorTypeTokenErrors !== false) {
      formVm.backendValidation.errors = errorTypeTokenErrors;
    }
  } else {
    formVm.apiError = getErrorNotification(nuxtApp, error);
  }
}

// Find an element in an array recursively
export function findCategoryById (id, arr) {
  return arr.reduce((a, item) => {
    if (a) {
      return a;
    }
    if (item.id === id) {
      return item;
    }
    if (item.children) {
      return findCategoryById(id, item.children);
    }
    return null;
  }, null);
}
