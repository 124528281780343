// Pinia Store
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useAwAnalyticsStore = defineStore('awAnalytics', () => {
  const AUCHAN_GTM_0 = ref(null);
  const analyticsStoreRefereces = {
    AUCHAN_GTM_0,
  };

  function setAnalytics ({
    id,
    value,
  }) {
    analyticsStoreRefereces[id].value = JSON.parse(JSON.stringify(value));
  }

  function pushToAnalytics ({
    id,
    value,
  }) {
    analyticsStoreRefereces[id].value.push(JSON.parse(JSON.stringify(value)));
  }

  return {
    AUCHAN_GTM_0,
    setAnalytics,
    pushToAnalytics,
  };
});
