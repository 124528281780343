<template>
  <div>
    <transition-group
      tag="div"
      :aria-live="ariaLive"
      :name="$style.notiList"
      :class="[
        $style.notiList,
        ...(wrapperClasses?.length
          ? wrapperClasses
          : []
        ),
      ]"
      :enter-from-class="$style.notiListEnterFrom"
      :leave-to-class="$style.notiListLeaveTo"
      :enter-active-class="$style.notiListEnterActive"
      :leave-active-class="$style.notiListLeaveActive"
      @enter="startTransition"
      @after-enter="endTransition"
      @before-leave="startTransition"
      @after-leave="endTransition"
    >
      <component
        :is="notification.is"
        v-for="(notification, key) in notSrOnlyNotifications"
        :key="notification.uuid"
        :close="close"
        :index="key"
        :thick-content="!!notification.thickContent"
        :thin-content="!!notification.thinContent"
        :notification="notification"
        :is-margin-less="isMarginLess"
      />
    </transition-group>
    <div aria-live="assertive" :class="$style.hiddenNotificationList">
      <component
        :is="notification.is"
        v-for="(notification, key) in srOnlyNotifications"
        :key="notification.uuid"
        :close="close"
        :index="key"
        :thick-content="!!notification.thickContent"
        :thin-content="!!notification.thinContent"
        :notification="notification"
        :is-margin-less="isMarginLess"
      />
    </div>
  </div>
</template>

<script>
  import { defineAsyncComponent } from 'vue';

  import { useNotificationStore } from '~~/common/stores/notification';

  const AwNotificationItem = defineAsyncComponent(() => import('~~/common/components/Common/AwNotificationItem.vue'));

  const validAriaLiveProps = ['off', 'polite', 'assertive'];
  export default {
    name: 'AwNotification',
    components: {
      AwNotificationItem,
    },
    props: {
      ariaLive: {
        type: String,
        required: true,
        validator: v => validAriaLiveProps.includes(v),
      },
      notifications: {
        type: Object,
        required: true,
      },
      closeAfter: {
        type: Number,
        default: 6000,
      },
      isMarginLess: {
        type: Boolean,
        default: false,
      },
      wrapperClasses: {
        type: Array,
        default: () => [],
      },
    },
    emits: ['remove-notification'],
    data () {
      return {
        timeout: new Set(),
      };
    },
    computed: {
      notSrOnlyNotifications () {
        return Object.fromEntries(Object.entries(this.processedNotifications).filter(([, value]) => !value.screenReaderOnly));
      },
      srOnlyNotifications () {
        return Object.fromEntries(Object.entries(this.processedNotifications).filter(([, value]) => value.screenReaderOnly));
      },
      processedNotifications () {
        const notifications = {};
        const iconNameMap = {
          default: 'education-16',
          info: 'education-16',
          success: 'success-16',
          error: 'error-16',
          warning: 'maintenence-16',
        };

        for (const key in this.notifications) {
          if (Object.prototype.hasOwnProperty.call(this.notifications, key)) {
            const {
              is = 'aw-notification-item',
              uuid,
              type = 'default',
              manualClose,
              thickContent,
              thinContent,
              screenReaderOnly,
              button,
            } = this.notifications[key];
            notifications[key] = {
              is,
              uuid,
              type,
              manualClose,
              thickContent,
              thinContent,
              screenReaderOnly,
              iconName: iconNameMap[type],
              text: this.notifications[key]?.text,
              promise: this.notifications[key]?.promise,
              button,
            };
          }
        }

        return notifications;
      },
    },
    watch: {
      processedNotifications: {
        immediate: true,
        handler () {
          if (import.meta.server || !this.closeAfter) {
            return;
          }
          const freshNotifications = Object.entries(this.notifications).filter(([key]) => !this.timeout.has(key));
          for (const [key, val] of freshNotifications) {
            if (!val.manualClose) {
              this.timeout.add(key);
              setTimeout(() => {
                this.close(key);
                this.timeout.delete(key);
              }, this.closeAfter);
            }
          }
        },
      },
    },
    methods: {
      close (key) {
        const notificationStore = useNotificationStore();
        if (this.notifications === notificationStore.notifications) {
          notificationStore.remove(key);
        } else {
          this.$emit('remove-notification', key);
        }
      },

      startTransition (el) {
        el.style.height = el.scrollHeight + 'px';
      },
      endTransition (el) {
        el.style.height = '';
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
.notiList {
  perspective: 1024px;

  &EnterActive,
  &LeaveActive {
    overflow: hidden;
    transition: all $animation-speed-medium $animation-timing-function;
    transform: rotateX(0deg) scale(1);
    pointer-events: none;
    opacity: 1;
  }

  &EnterFrom,
  &LeaveTo {
    height: 0 !important;
    opacity: 0;
  }

  &EnterFrom {
    transform: rotateX(-30deg) scale(.95);
  }

  &LeaveTo {
    transform: rotateX(30deg) scale(.95);
  }
}

.hiddenNotificationList {
  position: absolute;
}
</style>
