/*
* NOTE: This is a WCAG helper navigation. It implements the fee design pattern described here: https://www.w3.org/WAI/ARIA/apg/patterns/feed/examples/feed/
* For using it required to add the following attributes to the feed element:
*  - role="feed"
* - aria-setsize="N" where N is the number of articles in the feed
* - aria-posinset="N" where N is the index of the article in the feed
* - tabindex="0" for each article
* - role="article" for each article
* */

export default class FeedHelper {
  feedElement = null;
  constructor (feedElement) {
    if (!feedElement) {
      throw new Error('Feed element is required');
    }
    this.feedElement = feedElement;
    feedElement.addEventListener('keydown', this.onKeyDown.bind(this));
  }

  destroy () {
    this.feedElement.removeEventListener('keydown', this.onKeyDown.bind(this));
  }

  onKeyDown (event) {
    const key = event.which || event.keyCode;
    const target = event.target.getAttribute('role') === 'article' ? event.target : null;
    if (!target) {
      return;
    }
    const KeyCode = {
      PAGE_UP: 33,
      PAGE_DOWN: 34,
      HOME: 36,
      END: 35,
    };
    switch (key) {
      case KeyCode.PAGE_UP:
        event.preventDefault();
        this.prevArticle(target);
        break;
      case KeyCode.PAGE_DOWN:
        event.preventDefault();
        this.nextArticle(target);
        break;
      case KeyCode.HOME:
        if (event.ctrlKey) {
          event.preventDefault();
          this.jumpBeforeFeed();
        }
        break;
      case KeyCode.END:
        if (event.ctrlKey) {
          event.preventDefault();
          this.jumpAfterFeed(target);
          break;
        }
    }
  }

  getIndexByArticle (element) {
    const focusedIndex = element.getAttribute('aria-posinset');
    if (!focusedIndex) {
      // eslint-disable-next-line no-console
      console.warn('This should never happen');
      return;
    }
    return Number(focusedIndex);
  }

  findArticleByIndex (index) {
    const elementsWithPosinset = this.feedElement.querySelectorAll('[aria-posinset]');
    return Array.from(elementsWithPosinset).find((element) => {
      return Number(element.getAttribute('aria-posinset')) === index;
    });
  }

  nextArticle (focusedArticle) {
    const focusedIndex = this.getIndexByArticle(focusedArticle);
    const setSize = Number(this.feedElement.getAttribute('aria-setsize'));
    if (focusedIndex === setSize) {
      return;
    }
    const newArticle = this.findArticleByIndex(focusedIndex + 1);
    newArticle?.focus();
  }

  prevArticle (focusedArticle) {
    const focusedIndex = this.getIndexByArticle(focusedArticle);
    if (focusedIndex === 1) {
      return;
    }
    const newArticle = this.findArticleByIndex(focusedIndex - 1);
    newArticle?.focus();
  }

  jumpBeforeFeed () {
    const firstArticle = this.findArticleByIndex(1);
    firstArticle?.focus();
  }

  jumpAfterFeed (focusedArticle) {
    const setSize = Number(focusedArticle.getAttribute('aria-setsize'));
    const lastArticle = this.findArticleByIndex(setSize);
    lastArticle?.focus();
  }
}
