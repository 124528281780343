export default class EventEmitter {
  constructor () {
    this.callbacks = {};
  }

  on (event, callback) {
    if (!this.callbacks[event]) {
      this.callbacks[event] = [];
    }
    this.callbacks[event].push(callback);
  }

  off (event, callback) {
    if (this.callbacks[event]) {
      if (!callback) {
        this.callbacks[event] = [];
      } else {
        const index = this.callbacks[event].indexOf(callback);
        if (index > -1) {
          this.callbacks[event].splice(index, 1);
        }
      }
    }
  }

  emit (event, data) {
    const callbacks = this.callbacks[event];
    if (callbacks) {
      callbacks.forEach(callback => callback(data));
    }
  }
}
