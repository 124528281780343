<template>
  <div ref="eventWrapperElement">
    <div v-if="content.contentText" ref="script" />
  </div>
</template>

<script>
  import { nextTick } from 'vue';
  import analyticsBannerInteractionMixin from '~~/common/mixins/analyticsBannerInteractionMixin.js';

  const events = ['click', 'auxclick', 'contextmenu'];

  export default {
    name: 'AwJsBlock',
    mixins: [analyticsBannerInteractionMixin],
    props: {
      content: {
        type: Object,
        required: true,
      },
    },
    mounted () {
      const postscribeImport = () => import('postscribe');
      try {
        postscribeImport().then((postscribe) => {
          postscribe.default(this.$refs.script, this.content.contentText);
        });
      } catch (error) {
        this.$logger.error(error);
      }
      nextTick(() => {
        if (this.$refs?.eventWrapperElement) {
          events.forEach((e) => {
            this.$refs.eventWrapperElement.removeEventListener(e, this.clickHandler);
            this.$refs.eventWrapperElement.addEventListener(e, this.clickHandler);
          });
        }
      });
    },
    beforeUnmount () {
      if (this.$refs?.eventWrapperElement) {
        events.forEach((e) => {
          this.$refs.eventWrapperElement.removeEventListener(e, this.clickHandler);
        });
      }
    },
    methods: {
      clickHandler (evt) {
        if (evt.target instanceof HTMLAnchorElement || evt.target instanceof HTMLButtonElement) {
          this.sendBannerInteractionAnalytics();
        }
      },
    },
  };
</script>
