<template>
  <div :class="$style.empty">
    <p :class="$style.emptyText">
      <slot />
    </p>
    <img
      :src="$addWebpPostfixIfSupported({ url: abstract77, webpUrl: abstract77Webp })"
      alt=""
      :class="$style.emptyImage"
      loading="lazy"
    >
    <slot name="button" />
  </div>
</template>

<script>
  import abstract77 from '~~/common/assets/images/abstract-77.png';
  import abstract77Webp from '~~/common/assets/images/abstract-77.png.webp';
  export default {
    name: 'AwEmpty',
    data () {
      return {
        abstract77,
        abstract77Webp,
      };
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
.empty {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 40px;
  color: $color-text-secondary;
  border: 1px dashed $color-dash-separator;
  border-radius: 24px;
  gap: 24px;
}
</style>
