<template>
  <product-list-page />
</template>

<script>
  import { defineNuxtComponent, useRoute } from 'nuxt/app';
  import { fetchKey } from '~~/common/utils/awRunLegacyAsyncData.js';

  import { useCategoryStore } from '~~/common/stores/category';
  import { useProductsStore } from '~~/common/stores/products';
  import { defineAsyncComponent } from 'vue';

  export default defineNuxtComponent({
    name: 'ProductList',
    components: {
      ProductListPage: defineAsyncComponent(() => import('~~/shop/components/Page/ProductList/Page')),
    },
    // run asyncData if request: /product-list/{category-id}
    fetchKey,
    async asyncData ({
      $logger,
      $pinia,
    }) {
      const { params } = useRoute();
      const categoryStore = useCategoryStore($pinia);
      const productsStore = useProductsStore($pinia);
      try {
        await Promise.all([
          productsStore.fetchProducts({
            isCached: true,
            categoryId: params.id,
            itemsPerPage: productsStore.itemsPerPage,
            filterParams: productsStore.filterBy,
            sortKey: productsStore.sortKey || null,
            sortBy: productsStore.sortBy || null,
            page: 1,
          }),
          categoryStore.fetchCategoryById(params.id),
        ]);
      } catch (err) {
        $logger.error(err);
      }
      return {};
    },
  });
</script>
