export function generatePwdIcons (keys) {
  const arrOfKeys = (Array.isArray(keys) ? keys : []);
  const eyeIconsByType = {
    password: 'view-off-16',
    text: 'view-16',
  };
  const pwdIconTypes = reactive(Object.fromEntries(arrOfKeys.map(key => [key, 'password'])));
  const pwdIconAttrs = computed(() => (Object.fromEntries(arrOfKeys.map(key => [key, {
    size: 16,
    name: eyeIconsByType[pwdIconTypes[key]],
    position: 'after',
    onTouchstart: () => showPassword(key),
    onMousedown: () => showPassword(key),
    onTouchend: () => hidePassword(key),
    onMouseup: () => hidePassword(key),
    onMouseout: () => hidePassword(key),
    style: { cursor: 'pointer' },
  }]))));
  return {
    pwdIconTypes,
    pwdIconAttrs,
  };
  function showPassword (key) {
    pwdIconTypes[key] = 'text';
  }
  function hidePassword (key) {
    pwdIconTypes[key] = 'password';
  }
}
