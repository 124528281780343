const timeFrameLengthMap = {
  SHORT_TIME_FRAME: 3,
  FULL_DAY_TIME_FRAME: 7,
};

const timeFrameTypes = {
  SHORT: 'SHORT',
  EXTENDED: 'EXTENDED',
  FULL_DAY: 'FULL_DAY',
};

const timeFrameLength = (first, second) => {
  const between = Math.abs(first - second);
  return between / (3600 * 1000);
};

const getTypeByTimeFrame = (timeframe) => {
  const hours = timeFrameLength(new Date(timeframe.from), new Date(timeframe.to));
  if (hours <= timeFrameLengthMap.SHORT_TIME_FRAME) {
    return timeFrameTypes.SHORT;
  } else if (hours < timeFrameLengthMap.FULL_DAY_TIME_FRAME) {
    return timeFrameTypes.EXTENDED;
  } else {
    return timeFrameTypes.FULL_DAY;
  }
};

export { timeFrameTypes, getTypeByTimeFrame };
