<template>
  <div :class="$style.card">
    <div :class="$style.cardImageWrapper">
      <img
        :src="catalog.cover"
        alt=""
        :class="$style.cardImage"
        loading="lazy"
      >
    </div>
    <div :class="$style.cardContent">
      <nuxt-link :to="localePath(`/catalog/${catalog.slug}`)" :class="$style.cardContentTitle">
        <h3 :title="catalog.title">
          {{ catalog.title }}
        </h3>
      </nuxt-link>
      <p :class="$style.cardContentDescription">
        {{ catalogAvailabilityText }}
      </p>
      <lv-button
        styling="small-secondary-dark"
        shrink
        :class="$style.cardContentButton"
        :to="localePath(`/catalog/${catalog.slug}`)"
        v-on="genBannerInteractionClickEventHandlers({
          analyticsBannerInteraction: analyticsBannerInteraction,
          analyticsMeasurable: analyticsMeasurable,
        })"
      >
        {{ $awt('aw.cms.catalog.card.button_text') }}
      </lv-button>
    </div>
  </div>
</template>

<script>
  import { LvButton } from '~~/common/components/loginet-vue-shop/index.mjs';
  import analyticsBannerInteractionMixin from '~~/common/mixins/analyticsBannerInteractionMixin.js';

  export default {
    name: 'AwCatalogCard',
    components: {
      LvButton,
    },
    mixins: [analyticsBannerInteractionMixin],
    props: {
      catalog: {
        type: Object,
        required: true,
      },
    },
    computed: {
      catalogAvailabilityText () {
        const dateOptions = {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        };
        const extraOptions = {
          removeSpaces: true,
        };

        return `${this.$date(this.catalog.availabilityFromDate, dateOptions, extraOptions)} - ${this.$date(this.catalog.availabilityToDate, dateOptions, extraOptions)}`;
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
.card {
  display: grid;

  justify-content: space-between;
  min-width: 160px;
  border: 1px solid $color-border;
  border-radius: 16px;
  background: $color-background-2;
  grid-template-columns: 1fr;
  justify-items: center;

  &Image {
    width: auto;
    height: 144px;

    &Wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      border-radius: 16px;
      background: white;
    }
  }

  &Content {
    display: grid;
    width: 100%;
    padding: 24px;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, max-content);
    gap: 8px;

    &Title {
      font-size: 18px;
      font-weight: $heading-3-font-weight-desktop-version2;
      line-height: 24px;

      display: -webkit-inline-box;
      overflow: hidden;
      min-height: 48px;
      text-overflow: ellipsis;
      word-break: break-word;
      hyphens: auto;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    &Description {
      font-size: 12px;
      font-weight: $font-weight-regular-v2;
      line-height: 16px;
      margin-bottom: 8px;
      color: $color-text-secondary;
    }

    &Button {
      max-width: max-content;
    }
  }
}

</style>
