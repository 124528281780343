<template>
  <div>
    <aw-select-version-2
      v-if="modelValue.version2"
      :id="widgetUniqueId"
      :row-unique-id="rowUniqueId"
      :outer-label-id="labelUniqueId"
      :widget-attrs="modelValue.widgetAttrs"
      :options="modelValue.options"
      :option-label-property="modelValue.optionLabelProperty"
      :option-id-property="modelValue.optionIdProperty"
      :selected-options="modelValue.model"
      :dropdown-icon-attrs="modelValue.dropdownIconAttrs"
      :dropdown-side="modelValue.dropdownSide"
      :input-size="modelValue.inputSize"
      :shrink="modelValue.shrink"
      :disabled="modelValue.disabled"
      :multiple="modelValue.multiple"
      :inline-label-text="modelValue.inlineLabelText || ''"
      :show-empty-selected-value="modelValue.showEmptySelectedValue ?? false"
      :center-value="modelValue.centerValue ?? false"
      :has-error="hasError"
      @update:selected-options="setModelValueData"
    />
    <aw-select
      v-else
      :id="widgetUniqueId"
      :row-unique-id="rowUniqueId"
      :outer-label-id="labelUniqueId"
      :widget-attrs="modelValue.widgetAttrs"
      :options="modelValue.options"
      :option-label-property="modelValue.optionLabelProperty"
      :option-id-property="modelValue.optionIdProperty"
      :selected-options="modelValue.model"
      :dropdown-icon-attrs="modelValue.dropdownIconAttrs"
      :dropdown-side="modelValue.dropdownSide"
      :input-size="modelValue.inputSize"
      :shrink="modelValue.shrink"
      :disabled="modelValue.disabled"
      :multiple="modelValue.multiple"
      :inline-label-text="modelValue.inlineLabelText || ''"
      @update:selected-options="setModelValueData"
    />
  </div>
</template>

<script>
  import { defineAsyncComponent } from 'vue';
  import formMixin from '~~/common/mixins/formMixin';

  export default {
    name: 'AwFormSelect',
    components: {
      AwSelect: defineAsyncComponent(() => import('~~/common/components/Common/AwSelect')),
      AwSelectVersion2: defineAsyncComponent(() => import('~~/common/components/Common/AwSelectVersion2')),
    },
    mixins: [formMixin],
    methods: {
      setModelValueData (options) {
        this.modelValueData.model = options;
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">

</style>
