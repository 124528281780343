import { ref, computed, onMounted } from 'vue';
import { useUserInterfaceStore } from '~~/common/stores/userInterface.js';

export function createVideoAspectRatio () {
  const isMounted = ref(false);
  const screenRange = computed(() => {
    return useUserInterfaceStore().mediaQueries;
  });
  onMounted(() => {
    isMounted.value = true;
  });
  return computed(() => {
    return ((isMounted.value && screenRange.value['desktop-medium-min'])
      ? `${(9 / 21) * 100}%`
      : `${(9 / 16) * 100}%`
    );
  });
}
