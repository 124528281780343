<template>
  <aw-spinner />
</template>

<script>
  import { defineAsyncComponent } from 'vue';
  import { defineNuxtComponent, navigateTo, useRoute } from 'nuxt/app';
  import { fetchKey } from '~~/common/utils/awRunLegacyAsyncData.js';
  import { getLocaleCodeForUrl } from '~~/common/utils/localeCode';

  import { useOrderStore } from '~~/common/stores/order';
  import { useCheckoutStore } from '~~/common/stores/checkout';

  export default defineNuxtComponent({
    name: 'PaymentRedirect',
    components: { AwSpinner: defineAsyncComponent(() => import('~~/common/components/Common/AwSpinner')) },
    fetchKey,
    async asyncData ({
      $i18n,
      $api,
      $logger,
      $pinia,
    }) {
      const route = useRoute();
      const orderStore = useOrderStore($pinia);
      const checkoutStore = useCheckoutStore($pinia);
      try {
        const result = await $api.$post('/payment_return', {
          transactionNumber: route.query.transactionNumber,
        });
        const successOrderCart = await $api.$get(`/orders/${result.orderIdent}/cart`);
        const paymentData = await $api.$get(`/orders/${result.orderIdent}/payment_transactions`);

        const checkoutType = successOrderCart.splits[0]?.checkoutType;
        const cartData = successOrderCart.splits[0];
        orderStore.setPaymentData({
          paymentData,
          checkoutType,
        });

        await orderStore.fetchOrder({
          id: 'current',
          fetchNonfoodCheckout: false,
          fetchFoodCheckout: false,
        });

        await checkoutStore.fetchCheckout({
          type: checkoutType,
          canStartSecondAction: true,
          canRunSimultaneous: true,
        });

        const isCardInfoShown = Boolean(parseInt(route.query.isCardInfoShown, 10));
        if (checkoutType === 'FOOD') {
          orderStore.setCompletedFoodOrder({
            ...cartData,
            orderIdent: result.orderIdent,
            showCardInfo: isCardInfoShown,
          });
        } else if (checkoutType === 'NONFOOD') {
          orderStore.setCompletedNonFoodOrder({
            ...cartData,
            orderIdent: result.orderIdent,
            showCardInfo: isCardInfoShown,
          });
        }
        return {
          orderIdent: result.orderIdent,
          checkoutType: checkoutType.toLowerCase(),
          isCardInfoShown,
        };
      } catch (e) {
        $logger.error(e);
        navigateTo(`/${getLocaleCodeForUrl({ $i18n })}`);
        return {};
      }
    },
    mounted () {
      const orderStore = useOrderStore();
      navigateTo({
        path: this.localePath(`/shop/checkout-success/${this.orderIdent}`),
        query: {
          orderIdent: this.orderIdent,
          ...(orderStore.editedOrder ? { edited: true } : {}),
        },
      });
    },
  });
</script>
