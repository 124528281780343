<template>
  <content-page />
</template>

<script>
  import { defineNuxtComponent, useRoute } from 'nuxt/app';
  import { fetchKey } from '~~/common/utils/awRunLegacyAsyncData.js';
  import { useContentsStore } from '~~/common/stores/contents';
  import { useUserInterfaceStore } from '~~/common/stores/userInterface';
  import { defineAsyncComponent } from 'vue';

  export default defineNuxtComponent({
    name: 'ContentPage',
    components: {
      ContentPage: defineAsyncComponent(() => import('~~/shop/components/Page/Content/Page')),
    },

    setup () {
      // eslint-disable-next-line no-undef
      definePageMeta({
        layout: 'full-width',
      });
    },
    fetchKey,
    async asyncData ({
      $logger,
      $pinia,
    }) {
      const { params } = useRoute();
      const contentsStore = useContentsStore($pinia);
      const userInterfaceStore = useUserInterfaceStore($pinia);
      try {
        const id = params.id;
        await contentsStore.fetchContent({ pageId: id });
        userInterfaceStore.setBackground({ ...contentsStore.data });
      } catch (err) {
        $logger.error(err);
      }
      return {};
    },

  });
</script>
