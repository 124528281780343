<template>
  <component :is="tag" :class="$style.card">
    <div :class="$style.cardImageAndTitle">
      <div :class="$style.productImageWrapper">
        <lv-image :class="$style.productImage" :sources="sources" />
      </div>
      <div :class="$style.titleAndPrice">
        <lv-heading
          tag="h3"
          level="3"
          version2
          :class="$style.title"
        >
          {{ item.variant.name }}
        </lv-heading>
        <client-only>
          <div :class="$style.price">
            {{ price }}
          </div>
        </client-only>
      </div>
      <div :class="$style.separator" />
    </div>
    <div :class="$style.quantity">
      {{ quantityText }}
    </div>
  </component>
</template>

<script setup>
  import { epsRound, webpFallback } from '~~/common/utils/index.js';
  import { computed } from 'vue';
  import { LvImage, LvHeading } from '~~/common/components/loginet-vue-shop/index.mjs';
  import { useNuxtApp } from 'nuxt/app';

  const {
    $price,
    $awt,
  } = useNuxtApp();

  const props = defineProps({
    item: {
      type: Object,
      required: true,
    },
    tag: {
      type: String,
      default: 'li',
    },
  });

  const sources = computed(() => {
    const media = props.item.variant?.media;
    return webpFallback([{ url: media?.listImage || media?.mainImage || media?.images?.[0] || '' }]);
  });

  const price = computed(() => {
    return $price({ gross: props.item.variant.price.grossDiscounted }, props.item.variant.price.currency);
  });

  const quantityText = computed(() => {
    return `${epsRound(props.item.quantity.quantity)} ${$awt('aw.global.quantity.' + props.item.quantity.type)}`;
  });
</script>


<style module lang="scss" rel="stylesheet/scss">
.card {
  display: grid;
  align-items: center;
  padding: 8px;
  border-radius: 12px;
  background: $color-background-2;
  gap: 8px;
  grid-template-columns: auto 60px;

  &ImageAndTitle {
    display: grid;
    gap: 12px;
    grid-template-columns: 40px auto 1px;
    height: 40px;

    .productImage {
      width: 24px;
      height: 24px;

      &Wrapper {
        width: 40px;
        height: 40px;
        padding: 8px;
        border-radius: 8px;
        background: $color-background-4;
      }
    }

    .titleAndPrice {
      display: flex;
      gap: 4px;
      flex-flow: column;
      justify-content: center;
    }

    .title {
      display: -webkit-box;
      overflow: hidden;
      height: 16px;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;

      @include font($font-weight-normal-v2, 12px, 16px);
    }

    .price {
      @include font($font-weight-bold-v2, 12px, 16px);
      text-align: left;
    }

    .separator {
      width: 1px;
      height: 40px;
      border-left: 1px dashed $color-dash-separator;
    }
  }


  .quantity {
    @include font($font-weight-bold-v2, 12px, 16px);
    padding-right: 8px;
    text-align: right;
  }
}
</style>
