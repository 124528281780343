<template>
  <span :class="$style.awSpinner">
    <div :class="$style.vMoon1" :style="spinnerStyle">
      <div
        :class="$style.vMoon2"
        :style="[spinnerMoonStyle, animationStyle2]"
      />
      <div
        :class="$style.vMoon3"
        :style="[spinnerStyle, animationStyle3]"
      />
    </div>
  </span>
</template>

<script setup>
  import { computed } from 'vue';

  const props = defineProps({
    color: {
      type: String,
      default: '#433F32',
    },
    widthWithUnit: {
      type: String,
      default: '2rem',
    },
    heightWithUnit: {
      type: String,
      default: '2rem',
    },
    styling: {
      type: Object,
      default: () => ({}),
    },
  });

  const spinnerStyle = computed(() => {
    return {
      ...props.styling,
      height: props.heightWithUnit,
      width: props.widthWithUnit,
      borderRadius: '100%',
    };
  });

  const moonSize = computed(() => {
    return 'calc(' + props.heightWithUnit + ' / 3.5)';
  });

  const spinnerMoonStyle = computed(() => {
    return {
      height: moonSize.value,
      width: moonSize.value,
      borderRadius: '100%',
    };
  });

  const animationStyle2 = computed(() => {
    return {
      ...props.styling,
      top: 'calc(calc(' + props.widthWithUnit + ' / 2) - calc(' + moonSize.value + ' / 2))',
      backgroundColor: props.color,
    };
  });

  const animationStyle3 = computed(() => {
    return {
      border: moonSize.value + ' solid ' + props.color,
    };
  });
</script>

<style module lang="scss" rel="stylesheet/scss">
.awSpinner {
  display: flex;
  justify-content: center;
}

.vMoon1 {
  position: relative;
  -webkit-animation: vMoonStretchDelay 0.6s 0s infinite linear;
  animation: vMoonStretchDelay 0.6s 0s infinite linear;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.vMoon2 {
  position: absolute;
  -webkit-animation: vMoonStretchDelay 0.6s 0s infinite linear;
  animation: vMoonStretchDelay 0.6s 0s infinite linear;
  opacity: 1;
  background-color: $color-text-primary;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.vMoon3 {
  opacity: 0.1;
}

@-webkit-keyframes vMoonStretchDelay {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes vMoonStretchDelay {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
