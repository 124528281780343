<template>
  <ul :class="[$style.sidebar, 'ulReset']">
    <li
      v-for="option in options"
      :key="option.id"
    >
      <lv-link
        v-if="option.link"
        :to="option.link"
        :aria-current="(selectedOptions?.id === option.id).toString()"
        :class="[
          $style.sidebarOption,
          {[$style.selected]: selectedOptions?.id === option.id},
          {[$style.hasNotification]: notifications.some(notification => notification === option.id)}
        ]"
        @click="emitSelected(option)"
      >
        <div :class="$style.sidebarOptionContent">
          <span :class="$style.sidebarOptionContentText">
            {{ option.name }}
          </span>
          <lv-icon name="arrow-right-24" :size="16" :class="$style.sidebarOptionIcon" />
        </div>
      </lv-link>
      <a
        v-else-if="option.anchor"
        :href="`#${option.anchor}`"
        :aria-current="(selectedOptions?.id === option.id).toString()"
        :class="[
          $style.sidebarOption,
          {[$style.selected]: selectedOptions?.id === option.id},
          {[$style.hasNotification]: notifications.some(notification => notification === option.id)}
        ]"
        @click="emitSelected(option)"
      >
        <div :class="$style.sidebarOptionContent">
          <span :class="$style.sidebarOptionContentText">
            {{ option.name }}
          </span>
          <lv-icon name="arrow-right-24" :size="16" :class="$style.sidebarOptionIcon" />
        </div>
      </a>
    </li>
  </ul>
</template>

<script setup>
  import { LvLink, LvIcon } from '~~/common/components/loginet-vue-shop/index.mjs';

  // IO definitions
  defineProps({
    options: {
      type: Array,
      required: true,
      validator: (value) => {
        return value.every(item => ('id' in item) && item.name && (item.link || item.anchor));
      },
    },
    selectedOptions: {
      type: [Object],
      required: true,
    },
    notifications: {
      type: Array,
      default: () => [],
    },
  });

  const emits = defineEmits(['navigate']);

  // Methods
  function emitSelected (option) {
    emits('navigate', option);
  }
</script>

<style module lang="scss" rel="stylesheet/scss">

.sidebar {
  display: grid !important;
  padding: 8px;
  border-radius: 12px;
  background-color: $color-background-3;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  @include tablet(min) {
    grid-template-columns: repeat(3, 1fr);
  }
  @include sidebar-desktop-medium-large(min) {
    grid-template-columns: repeat(1, 1fr);
  }

  &Option {
    position: relative;
    width: 100%;
    height: 100%;
    color: $color-text-primary;
    border: 1px solid $color-background-4;
    border-radius: 8px;
    background-color: $color-background-4;
    box-shadow: 0 2px 8px 0 rgba($color-olive-750, 0.08);

    &:hover {
      text-decoration: none;
    }

    @include desktop-medium(min) {
      font-size: 14px;
      line-height: 20px;
    }

    &.selected, &:active {
      color: $color-white;
      border: 1px solid $color-strawberry-300;
      background-color: $color-strawberry-450;
    }

    &.selected &Icon {
      display: none;
    }

    &.hasNotification::before {
      position: absolute;
      top: calc(50% - 4px);
      left: -4px;
      width: 8px;
      height: 8px;
      content: "";
      border-radius: 50%;
      outline: 2px solid $color-background-4;
      background-color: $color-strawberry-450;
    }

    &:hover:not(.selected):not(&:active) {
      text-decoration: none;
      border: 1px solid $color-border;
      background-color: $color-background-3;
    }

    &.selected, &:active, &:hover:not(.selected):not(&:active) {
      transition: all 400ms ease;
    }

    &Icon {
      width: 16px;
      height: 16px;
    }

    &Content {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      display: grid;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      padding: 8px;
      grid-template-columns: auto 16px;
      gap: 8px;

      &Text {
        @include multiline-ellipsis(2);
      }

      @include sidebar-desktop-medium-large(min) {
        font-size: 14px;
        font-weight: $font-weight-normal-v2;
        line-height: 20px;
      }
    }
  }
}
</style>
