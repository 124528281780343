import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useBreadcrumbStore = defineStore('breadcrumb', () => {
  const breadcrumbs = ref([]);

  function setBreadcrumbs (newBreadcrumbs) {
    breadcrumbs.value = newBreadcrumbs;
  }

  return {
    breadcrumbs,
    setBreadcrumbs,
  };
});
