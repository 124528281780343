import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { useNuxtApp, useRuntimeConfig } from 'nuxt/app';
import { ORDER_STATUS_CREATED, ORDER_STATUS_WAITING_FOR_PAYMENT_IN_STORE } from '~~/common/config/OrderStatus';
import {
  CBDS_MODIFICATION_STRATEGY_DISALLOWS_CART_OPERATIONS,
  CBDS_NOT_AVAILABLE_ADULT_DELIVERY_MODE,
  CBDS_NOT_AVAILABLE_DURING_ORDER_MODIFICATION,
  CBDS_NOT_AVAILABLE_OFFLINE_PRODUCT,
  CBDS_NOT_AVAILABLE_ONLINE,
  CBDS_UNAVAILABLE,
  CBDS_UNDECIDABLE,
} from '~~/common/config/CartButtonDisplayStatus';
import { setErrorNotification } from '~~/common/utils/helper';
import {
  PUSH_ADD_NEW_ITEM_TO_CART_PRE,
  PUSH_ADD_VOUCHER,
  PUSH_DELETE_ORDER,
  PUSH_REMOVE_CART_ITEM,
  PUSH_TRACK_CART_STATE,
  PUSH_TRUNCATE_CART,
} from '~~/common/plugins/aw-analytics';
import { M_DELIVERY_METHOD } from '~~/common/config/Modal.js';
import { useQueueStore } from '~~/common/stores/queue';
import { useModalStore } from '~~/common/stores/modal';
import { useUserInterfaceStore } from '~~/common/stores/userInterface';
import { useCheckoutStore } from '~~/common/stores/checkout';
import { useUserStore } from '~~/common/stores/user';

const cache = {};
let isPriceChangeMessageViewed = false;

let deletableStatusesCache;
let orderStatusByIdCache;

const strategies = {
  before_payment_modification: 1,
  after_payment_modification: 2,
};

export const useOrderStore = defineStore('order', () => {
  const nuxtApp = useNuxtApp();
  const config = useRuntimeConfig();

  const modalStore = useModalStore();
  const userInterfaceStore = useUserInterfaceStore();
  const queueStore = useQueueStore();
  const checkoutStore = useCheckoutStore();

  const order = ref({
    'splits': [],
    'mergeNeeded': false,
    'merged': false,
  });
  const completedOrders = ref({
    food: {},
    nonFood: {},
  });
  const returnUsedItem = ref({});
  const cartMerge = ref({});
  const statuses = ref([]);
  const orders = ref(null);
  const editedOrder = ref(null);
  const editedOriginalOrder = ref(null);

  const paymentData = ref({});
  const ageConfirm = ref(false);
  const getCurrentOrderIsInProgress = ref(false);

  // getters
  const getStatusById = computed(() => {
    if (!orderStatusByIdCache && statuses.value.length) {
      orderStatusByIdCache = Object.fromEntries(statuses.value.map(s => [s.id, s]));
    }
    return orderStatusByIdCache || {};
  });
  const isStatusDeletable = computed(() => (statusId) => {
    if (!deletableStatusesCache) {
      deletableStatusesCache = statuses.value.filter(s => [ORDER_STATUS_CREATED, ORDER_STATUS_WAITING_FOR_PAYMENT_IN_STORE].includes(s.id));
    }
    return deletableStatusesCache.find(s => s.id === statusId);
  });
  const getStaticInfosByCheckoutType = computed(() => {
    const ignoredKeysFilter = new Set([
      'blocked',
      'freeDeliveryInfo',
      'creditableButtons',
    ]);
    return (order.value?.splits || []).reduce((acc, {
      checkoutType,
      infos,
    }) => {
      acc[checkoutType] = (
        (Object.entries(infos || {})
          .filter(([k]) => !ignoredKeysFilter.has(k))
        )
      );
      return acc;
    }, {});
  });
  const getRawStaticInfo = computed(() => {
    return (order.value?.splits || []).reduce((acc, {
      checkoutType,
      infos,
    }) => {
      acc[checkoutType] = infos;
      return acc;
    }, {});
  });
  const getOrderItemDiscriminator = computed(() => (order, item) => {
    return order.splits
      ?.flatMap(s => s.packages)
      ?.flatMap(({
        discriminator,
        items,
      }) => ({
        discriminator,
        items,
      }))
      ?.find(({ items }) => items?.find(({ uuid }) => item.uuid === uuid)).discriminator;
  });
  const getCurrentFlattenedVouchers = computed(() => {
    return (order.value.splits || [])
      .flatMap(({ vouchers }) => vouchers);
  });
  const getFlattenedOrderItems = computed(() => (order) => {
    return (order.splits || [])
      .flatMap(s => s.packages)
      .flatMap(p => p.items);
  });
  // NOTE: it's more performant than getFlattenedOrderItems when current order is desired
  const getCurrentFlattenedOrderItems = computed(() => {
    return getFlattenedOrderItems.value(order.value);
  });
  const getOrderPackagesByObjectId = computed(() => (order, objectId) => {
    return (order.splits || [])
      .flatMap(s => s.packages)
      .filter(p => p.items.some(i => i.objectId === objectId));
  });
  const getOrderItemSplit = computed(() => (order, item) => {
    return order.splits
      ?.find(spl => spl.packages
        ?.flatMap(p => p.items)
        ?.some(({ uuid }) => item.uuid === uuid));
  });
  const getDeliveryModeAvailability = computed(() => (discrim, shipmentMethod = 'ALL') => {
    return [{
      value: 'store',
      label: 'awd.common.delivery_mode.selector.store.store',
      labelTfr: 'awd.common.delivery_mode.timeframe.store.store',
      labelSum: 'awd.common.delivery_mode.summary.store.store',
      disabled: discrim.servingMode === 'logistic',
      icon: {
        name: 'basket-with-handle-32',
        size: 32,
        fill: true,
      },
    }, {
      value: 'store',
      label: 'awd.common.delivery_mode.selector.store.logistic',
      labelTfr: 'awd.common.delivery_mode.timeframe.store.logistic',
      labelSum: 'awd.common.delivery_mode.summary.store.logistic',
      disabled: discrim.servingMode === 'store',
      icon: {
        name: 'timer-clock-32',
        size: 32,
        fill: true,
      },
    }, {
      value: 'home',
      label: `awd.common.delivery_mode.selector.home.${discrim.servingMode}`,
      labelTfr: `awd.common.delivery_mode.timeframe.home.${discrim.servingMode}`,
      labelSum: `awd.common.delivery_mode.summary.home.${discrim.servingMode}`,
      disabled: shipmentMethod === 'PACKET' && discrim.servingMode === 'store',
      icon: {
        name: `dealer-pick-${discrim.servingMode}-32`,
        size: 32,
        fill: true,
      },
    }, {
      value: 'courierapp',
      label: `awd.common.delivery_mode.selector.courierapp.${discrim.servingMode}`,
      labelTfr: `awd.common.delivery_mode.timeframe.courierapp.${discrim.servingMode}`,
      labelSum: `awd.common.delivery_mode.summary.courierapp.${discrim.servingMode}`,
      disabled: false,
      icon: {
        name: 'shipping-32',
        size: 32,
      },
    }, {
      value: 'extra_palett',
      label: `awd.common.delivery_mode.selector.extra_palett.${discrim.servingMode}`,
      labelTfr: `awd.common.delivery_mode.timeframe.extra_palett.${discrim.servingMode}`,
      labelSum: `awd.common.delivery_mode.summary.extra_palett.${discrim.servingMode}`,
      disabled: !['ALL', 'EXT_PALETT'].includes(shipmentMethod),
      icon: {
        name: `dealer-pick-${discrim.servingMode}-32`,
        size: 32,
        fill: true,
      },
    }];
  });
  const cartButtonDisplayStatuses = computed(() => {
    return {
      [CBDS_UNDECIDABLE]: {
        alertStyleClass: 'info',
        shortText: 'aw.global.prod_status_short.no_shipping_to_zip_code',
        alertText: 'aw.global.no_shipping_to_zip_code',
        notificationItem: {
          type: 'info',
          iconName: 'education-16',
          manualClose: false,
          text: {
            title: '',
            subtitleToken: 'aw.global.no_shipping_to_zip_code',
          },
        },
      },
      [CBDS_UNAVAILABLE]: {
        alertStyleClass: 'warning',
        shortText: 'aw.global.prod_status_short.product_out_of_stock',
        alertText: 'aw.global.product_out_of_stock',
        notificationItem: {
          type: 'warning',
          iconName: 'maintenence-16',
          manualClose: false,
          text: {
            titleToken: 'aw.global.product_out_of_stock',
            subtitle: '',
          },
        },
      },
      [CBDS_NOT_AVAILABLE_ONLINE]: {
        alertStyleClass: 'warning',
        shortText: 'aw.global.prod_status_short.product_out_of_stock',
        alertText: 'aw.global.product_out_of_stock',
        notificationItem: {
          type: 'warning',
          iconName: 'maintenence-16',
          manualClose: false,
          text: {
            title: '',
            subtitleToken: 'aw.global.product_out_of_stock',
          },
        },
      },
      [CBDS_NOT_AVAILABLE_ADULT_DELIVERY_MODE]: {
        alertStyleClass: 'warning',
        shortText: 'aw.global.prod_status_short.product_not_available_adult_delivery_mode',
        alertText: 'aw.global.product_not_available_adult_delivery_mode',
        notificationItem: {
          type: 'warning',
          iconName: 'maintenence-16',
          manualClose: false,
          text: {
            title: '',
            subtitleToken: 'aw.global.product_not_available_adult_delivery_mode',
          },
        },
      },
      [CBDS_MODIFICATION_STRATEGY_DISALLOWS_CART_OPERATIONS]: {
        alertStyleClass: 'info',
        shortText: 'aw.global.prod_status_short.product_not_available_during_modification',
        alertText: 'aw.global.product_not_available_during_modification',
        notificationItem: {
          type: 'info',
          iconName: 'education-16',
          manualClose: false,
          text: {
            title: '',
            subtitleToken: 'aw.global.product_not_available_during_modification',
          },
        },
      },
      [CBDS_NOT_AVAILABLE_DURING_ORDER_MODIFICATION]: {
        alertStyleClass: 'info',
        shortText: 'aw.global.prod_status_short.product_not_available_during_modification',
        alertText: 'aw.global.product_not_available_during_modification',
        notificationItem: {
          type: 'info',
          iconName: 'education-16',
          manualClose: false,
          text: {
            title: '',
            subtitleToken: 'aw.global.product_not_available_during_modification',
          },
        },
      },
      [CBDS_NOT_AVAILABLE_OFFLINE_PRODUCT]: {
        alertStyleClass: 'info',
        shortText: 'aw.global.prod_status_short.offline_product',
        alertText: 'aw.global.offline_product',
        notificationItem: {
          type: 'info',
          iconName: 'education-16',
          manualClose: false,
          text: {
            title: '',
            subtitleToken: 'aw.global.offline_product',
          },
        },
      },
    };
  });
  const getModStrategy = computed(() => strategies[editedOrder.value?.strategy] || 0);

  // actions
  function findCompletedOrderTypeByOrderIdent (orderIdent) {
    return completedOrders.value.food?.orderIdent === orderIdent ? completedOrders.value.food : completedOrders.value.nonFood?.orderIdent === orderIdent ? completedOrders.value.nonFood : null;
  }

  function setCompletedOrderToOnline (orderIdent) {
    const completedOrder = findCompletedOrderTypeByOrderIdent(orderIdent);
    if (completedOrder) {
      completedOrder.paymentMethod = 'online';
    }
  }

  function setCompletedOrderToCod (orderIdent) {
    const completedOrder = findCompletedOrderTypeByOrderIdent(orderIdent);
    if (completedOrder) {
      completedOrder.paymentMethod = 'onsite';
    }
  }

  function setOrder (newOrder) {
    order.value = newOrder;
  }

  function setCompletedFoodOrder (split) {
    completedOrders.value.food = split;
  }

  function setCompletedNonFoodOrder (split) {
    completedOrders.value.nonFood = split;
  }

  function resetCompletedOrders () {
    completedOrders.value.food = {};
    completedOrders.value.nonFood = {};
  }

  function setReturnUsedItemFromOrder (newOrder) {
    const newReturnUsedItem = {};

    newOrder.splits.forEach((split) => {
      split.packages.forEach((splitPackage) => {
        splitPackage.items.forEach((item) => {
          if (item.usedItem.canReturn && item.usedItem.return) {
            newReturnUsedItem[item.variant.id] = item.usedItem.return;
          }
        });
      });
    });

    returnUsedItem.value = { ...returnUsedItem.value, ...newReturnUsedItem };
  }

  function setReturnUsedItem (newReturnUsedItem) {
    returnUsedItem.value = { ...returnUsedItem.value, ...newReturnUsedItem };
  }

  function removeReturnUsedItem (returnUsedItemId) {
    delete returnUsedItem.value[returnUsedItemId];
  }

  function setEditedOrder (payload) {
    editedOrder.value = payload;
  }

  function removeEditedOrder () {
    editedOrder.value = null;
  }

  function setEditedOriginalOrder (payload) {
    editedOriginalOrder.value = payload;
  }

  function setPaymentData ({
    paymentData: newPaymentData,
    checkoutType,
  }) {
    if (newPaymentData && checkoutType) {
      paymentData.value[checkoutType] = newPaymentData;
    } else {
      paymentData.value = {};
    }
  }

  function setAgeConfirm (payload) {
    ageConfirm.value = payload;
  }

  async function fetchOrder ({
    id = 'current',
    resolveOption,
    canRunSimultaneous,
    canStartSecondAction,
    fetchNonfoodCheckout = true,
    fetchFoodCheckout = true,
    mode = null,
  }) {
    if (import.meta.server) {
      return await fetchOrderRequest({
        id,
        resolveOption,
        fetchNonfoodCheckout,
        fetchFoodCheckout,
        canRunSimultaneous,
        canStartSecondAction,
        mode,
      });
    } else {
      userInterfaceStore.startLoading({ id: `fetch-order-${id}` });
      return await queueStore.add({
        action: fetchOrderRequest,
        actionName: 'order/fetchOrderRequest',
        payload: {
          id,
          resolveOption,
          fetchNonfoodCheckout,
          fetchFoodCheckout,
          canRunSimultaneous,
          canStartSecondAction,
          mode,
        },
        canRunSimultaneous,
        canStartSecondAction,
      });
    }
  }

  async function fetchOrderRequest ({
    id = 'current',
    fetchNonfoodCheckout = true,
    fetchFoodCheckout = true,
    resolveOption,
    canRunSimultaneous,
    canStartSecondAction,
    mode,
  }) {
    // while (getCurrentOrderIsInProgress.value) {
    //   await new Promise(resolve => setTimeout(resolve, 256));
    // }

    const {
      $api,
      $awAnalytics,
      $logger,
      $awSFMCClient,
    } = nuxtApp;

    getCurrentOrderIsInProgress.value = true;

    try {
      // TODO: kell nekünk valamire a summary végpont?
      const modeMap = {
        BASKET: '',
        MINICART: '/unsplit',
      };
      const modePart = config.public.isShop ? modeMap[mode || 'BASKET'] : modeMap.BASKET;
      const r = await $api.get(`/orders/${id}/cart${modePart}`, addResolveOptionsHeader(resolveOption));
      cache.fetchOrder = r?.status;
      const p = [];
      p.push($awAnalytics[PUSH_TRACK_CART_STATE]({
        newCart: r.data,
        oldCart: order.value,
      }));
      setOrder(r.data);
      setReturnUsedItemFromOrder(r.data);
      if (r.data.merged) {
        $awSFMCClient[PUSH_TRACK_CART_STATE]({ cart: r.data });
      }
      if (fetchNonfoodCheckout && r.data.splits.some(s => s.checkoutType === 'NONFOOD')) {
        p.push(checkoutStore.fetchCheckout({
          type: 'NONFOOD',
          canRunSimultaneous,
          canStartSecondAction,
        }));
      }
      if (fetchFoodCheckout) {
        p.push(checkoutStore.fetchCheckout({
          type: 'FOOD',
          canRunSimultaneous,
          canStartSecondAction,
        }));
      }
      await Promise.all(p);
      return {
        fetchOrderErrorStatus: false,
      };
    } catch (error) {
      const previous = cache.fetchOrder;
      cache.fetchOrder = error.response?.status;

      if (previous === error.response?.status && previous === 412) {
        return {
          fetchOrderErrorStatus: true,
        };
      }

      // setErrorNotification() method `closable` parameter depends on whether M_DELIVERY_METHOD opened or not
      const {
        name,
        message,
      } = await setErrorNotification({ nuxtApp, error, closable: modalStore.activeGenericModal.type === M_DELIVERY_METHOD });
      if (name === 'accept' && message) {
        await fetchOrder({
          ...arguments[0],
          resolveOption: message,
        });
      } else if (name === 'cancel') {
        // do nothing
      } else {
        queueStore.removeAllElementFromQueue(false);
        $logger.error(error);
      }
      return {
        fetchOrderErrorStatus: true,
        fetchOrderErrorResult: {
          name,
          message,
          status: error.response?.status,
        },
      };
    } finally {
      getCurrentOrderIsInProgress.value = false;
      userInterfaceStore.endLoading({ id: `fetch-order-${id}` });
    }
  }

  async function fetchCartMerge (resolveOption) {
    const {
      $api,
      $logger,
      $awSFMCClient,
    } = nuxtApp;
    try {
      const r = await $api.get('/orders/current/cart/merge', (resolveOption
        ? {
          headers: {
            'X-Error-Resolve-Option': resolveOption,
          },
        }
        : {}));
      cache.fetchCartMerge = r?.status;
      cartMerge.value = r.data;
      if (r.data.merged) {
        $awSFMCClient[PUSH_TRACK_CART_STATE]({ cart: r.data });
      }
    } catch (error) {
      const previous = cache.fetchCartMerge;
      cache.fetchCartMerge = error.response?.status;

      if (previous === error.response?.status && previous === 412) {
        return;
      }

      const {
        name,
        message,
      } = await setErrorNotification({ nuxtApp, error, closable: false });
      if (name === 'accept' && message) {
        fetchCartMerge({
          ...arguments[0],
          resolveOption: message,
        });
      } else if (name === 'cancel') {
        // do nothing
      } else {
        $logger.error(error);
      }
    }
  }

  async function fetchStatuses () {
    const {
      $api,
      $logger,
    } = nuxtApp;
    try {
      const { data } = await $api.get('statuses');
      statuses.value = data;
    } catch (e) {
      $logger.error(e);
    } finally {
      userInterfaceStore.endLoading(null);
    }
  }

  async function fetchOrderModification () {
    const {
      $api,
      $logger,
    } = nuxtApp;
    if (config.public.isShop) {
      try {
        const result = await $api.$get('/order_modification');
        setEditedOrder({
          id: result?.orderId,
          strategy: result?.strategy,
        });

        const originalOrder = await $api.$get(`/orders/${result.orderId}`);
        setEditedOriginalOrder(originalOrder);

        fetchOrder({
          id: 'current',
          canStartSecondAction: true,
          canRunSimultaneous: true,
        });
      } catch (error) {
        // if error.response.status === 404 or 403 we don't have pending order modification or user is not logged in, this is not error
        if (error?.response?.status === 404 || error?.response?.status === 403) {
          setEditedOrder(null);
          setEditedOriginalOrder(null);
        } else {
          $logger.error(error);
          await setErrorNotification({ nuxtApp, error });
        }
      }
    }
  }

  async function checkPriceChange ({
    objectId,
    objectType,
    referenceId,
  }) {
    const {
      $api,
      $logger,
    } = nuxtApp;
    try {
      if (!config.public.isPriceChangeChecked || isPriceChangeMessageViewed) {
        return;
      }
      await $api.get(`orders/${referenceId}/check_price_change/${objectType}/${objectId}`);
    } catch (error) {
      if (error?.response?.status === 409) {
        setErrorNotification({ nuxtApp, error, closable: false, forceModal: true });
        isPriceChangeMessageViewed = true;
      } else {
        $logger.error(error);
        setErrorNotification({ nuxtApp, error });
      }
    }
  }

  async function addToCart ({
    id,
    objectType,
    data,
    resolveOption,
    fromSelfcare,
    /* fromBasket = false, */
  }) {
    userInterfaceStore.startLoading({ id: `add-to-cart-${data.objectId}-${data.stockType}` });
    return await queueStore.add({
      action: addToCartRequest,
      actionName: 'order/addToCartRequest',
      payload: {
        id,
        objectType,
        data,
        resolveOption,
        fromSelfcare,
      },
      canStartSecondAction: true,
    });
  }

  async function addToCartRequest ({
    id,
    objectType,
    data,
    resolveOption,
    fromSelfcare,
  }) {
    const {
      $api,
      $awAnalytics,
      $logger,
      $awSFMCClient,
    } = nuxtApp;

    try {
      try {
        await $awAnalytics[PUSH_ADD_NEW_ITEM_TO_CART_PRE]({ objectType });
      } catch (error) {
        $logger.error(error);
      }
      const r = await $api.post(`orders/${id}/cart/cartables/${objectType}`, data, (resolveOption
        ? {
          headers: {
            'X-Error-Resolve-Option': resolveOption,
          },
        }
        : {}));
      cache.addToCart = r.status;
      let analytics;
      try {
        analytics = $awAnalytics[PUSH_TRACK_CART_STATE]({
          newCart: r.data,
          oldCart: order.value,
          newObject: {
            objectType,
            objectId: data?.objectId,
          },
        });
        $awSFMCClient[PUSH_TRACK_CART_STATE]({ cart: r.data });
      } catch (error) {
        $logger.error(error);
      }
      if (r.data.splits.some(split => split.checkoutType === 'NONFOOD')) {
        await checkoutStore.fetchCheckout({
          type: 'NONFOOD',
          canRunSimultaneous: true,
        });
      }
      // Here we can use the splitted cart data from the response, because when we hit this button, the minicart is closed, and then when we open it again the minicart will fetch the unsplitted data for itself (this way we can save cart resource
      setOrder(r.data);
      setReturnUsedItemFromOrder(r.data);

      const isInCart = getCurrentFlattenedOrderItems.value?.some(i => i.variant.id === parseInt(data?.objectId));

      await analytics;
      return isInCart;
    } catch (error) {
      userInterfaceStore.endLoading({ id: `add-to-cart-${data.objectId}-${data.stockType}` });

      const previous = cache.addToCart;
      cache.addToCart = error.response?.status;

      if (previous === error.response?.status && previous === 412) {
        return false;
      }

      const {
        name,
        message,
      } = await setErrorNotification({ nuxtApp, error, closable: (error.response?.status === 428 ? true : !!fromSelfcare), fromSelfcare, enableTitleMerge: false });
      if (name === 'accept' && message) {
        return await addToCart({
          ...arguments[0],
          resolveOption: message,
        });
      } else if (name === 'cancel') {
        // do nothing
        return null;
      } else {
        queueStore.removeAllElementFromQueue(false);
        $logger.error(error);
      }
    } finally {
      userInterfaceStore.endLoading({ id: `add-to-cart-${data.objectId}-${data.stockType}` });
    }
  }

  async function addCoupon ({
    id,
    objectType,
    objectId,
    resolveOption,
  }) {
    userInterfaceStore.startLoading({ id: `add-to-coupon-${objectId}` });
    return await queueStore.add({
      action: addCouponRequest,
      actionName: 'order/addCouponRequest',
      payload: {
        id,
        objectType,
        objectId,
        resolveOption,
      },
      canStartSecondAction: true,
    });
  }

  async function addCouponRequest ({
    id,
    objectType,
    objectId,
    resolveOption,
  }) {
    const {
      $api,
      $awAnalytics,
      $logger,
    } = nuxtApp;

    try {
      const r = await $api.post(`orders/${id}/cart/cartables/${objectType}`, { objectId }, (resolveOption
        ? {
          headers: {
            'X-Error-Resolve-Option': resolveOption,
          },
        }
        : {}));
      cache.addCoupon = r.status;
      setOrder(r.data);
      setReturnUsedItemFromOrder(r.data);
      if (r.data.splits.some(split => split.checkoutType === 'NONFOOD')) {
        await checkoutStore.fetchCheckout({
          type: 'NONFOOD',
          canRunSimultaneous: true,
        });
      }
      try {
        $awAnalytics[PUSH_ADD_VOUCHER]({ objectId });
      } catch (error) {
        $logger.error(error);
      }
      return true;
    } catch (error) {
      userInterfaceStore.endLoading({ id: `add-to-coupon-${objectId}` });

      const previous = cache.addCoupon;
      cache.addCoupon = error.response?.status;
      if (previous === error.response?.status && previous === 412) {
        return false;
      }
      const {
        name,
        message,
      } = await setErrorNotification({ nuxtApp, error });
      if (name === 'accept' && message) {
        await addCoupon({
          ...arguments[0],
          resolveOption: message,
        });
      } else if (name === 'cancel') {
        // do nothing
      } else {
        queueStore.removeAllElementFromQueue(false);
        $logger.error(error);
      }
      return false;
    } finally {
      userInterfaceStore.endLoading({ id: `add-to-coupon-${objectId}` });
    }
  }

  async function updateCartItemQuantity ({
    uuid,
    quantity,
    quantityType,
    fromBasket = false,
  }) {
    await updateCartItem({
      uuid,
      fromBasket,
      data: {
        quantity: {
          quantity,
          type: ['db', 'piece', 'szt.'].includes(quantityType) ? 'piece' : 'weight',
        },
      },
    });
  }

  async function updateCartItemReturnUsed ({
    uuid,
    returnUsed,
    fromBasket,
  }) {
    await updateCartItem({
      uuid,
      fromBasket,
      data: {
        usedItem: {
          return: returnUsed,
        },
      },
    });
  }

  async function updateCartItemStickerUsed ({
    uuid,
    selectedOption,
    fromBasket,
  }) {
    await updateCartItem({
      uuid,
      fromBasket,
      data: {
        stickerRedemption: selectedOption,
      },
    });
  }

  async function updateCartItem ({
    uuid,
    data,
    resolveOption,
    fromBasket = false,
  }) {
    userInterfaceStore.startLoading({ id: `update-cart-item-${uuid}` });
    return await queueStore.add({
      action: updateCartItemRequest,
      actionName: 'order/updateCartItemRequest',
      payload: {
        uuid,
        data,
        resolveOption,
        fromBasket,
      },
      canStartSecondAction: true,
    });
  }

  async function updateCartItemRequest ({
    uuid,
    data,
    resolveOption,
    fromBasket,
  }) {
    const {
      $api,
      $awAnalytics,
      $logger,
      $awSFMCClient,
    } = nuxtApp;

    try {
      const r = await $api.patch(`orders/current/cart/items/${uuid}${data.barcode ? '/yellowBarcode' : ''}`, data, {
        headers: {
          'Content-Type': 'application/merge-patch+json', ...{ [resolveOption ? 'X-Error-Resolve-Option' : null]: resolveOption },
        },
      });
      cache.updateCartItem = r.status;

      const analytics = $awAnalytics[PUSH_TRACK_CART_STATE]({
        newCart: r.data,
        oldCart: order.value,
        modifiedObject: { uuid },
      });
      $awSFMCClient[PUSH_TRACK_CART_STATE]({ cart: r.data });
      if (fromBasket) {
        setOrder(r.data);
        setReturnUsedItemFromOrder(r.data);
      } else {
        await fetchOrderRequest({
          id: 'current',
          resolveOption,
          fetchNonfoodCheckout: true,
          fetchFoodCheckout: true,
          canRunSimultaneous: true,
          canStartSecondAction: true,
          mode: 'MINICART',
        });
      }
      if (r.data.splits.some(split => split.checkoutType === 'NONFOOD')) {
        await checkoutStore.fetchCheckout({
          type: 'NONFOOD',
          canRunSimultaneous: true,
        });
      }
      await analytics;
    } catch (error) {
      userInterfaceStore.endLoading({ id: `update-cart-item-${uuid}` });
      const previous = cache.updateCartItem;
      cache.updateCartItem = error.response?.status;
      if (previous === error.response?.status && previous === 412) {
        return false;
      }
      const {
        name,
        message,
      } = await setErrorNotification({ nuxtApp, error });
      if (name === 'accept' && message) {
        await updateCartItem({
          ...arguments[0],
          resolveOption: message,
        });
      } else if (name === 'cancel') {
        await fetchOrder({
          id: 'current',
          canStartSecondAction: true,
          canRunSimultaneous: true,
        });
      } else {
        queueStore.removeAllElementFromQueue(false);

        $logger.error(error);
        await fetchOrder({
          id: 'current',
          canStartSecondAction: true,
          canRunSimultaneous: true,
        });
      }
    } finally {
      userInterfaceStore.endLoading({ id: `update-cart-item-${uuid}` });
    }
  }

  async function updateCartItemYellowBarcode ({
    uuid,
    barcode,
  }) {
    userInterfaceStore.startLoading({ id: `update-cart-item-${uuid}` });
    const data = {
      barcode: barcode,
    };
    return await queueStore.add({
      action: updateCartItemRequest,
      actionName: 'order/updateCartItemRequest',
      payload: {
        uuid,
        data,
      },
      canStartSecondAction: true,
    });
  }

  /**
   * @returns whether modification is started
   */
  async function startOrderModification ({
    orderId,
    resolveOption,
  }) {
    const {
      $api,
      $logger,
    } = nuxtApp;
    try {
      const r = await $api.post('/order_modification', { orderId }, {
        headers: {
          ...{ [resolveOption ? 'X-Error-Resolve-Option' : null]: resolveOption },
        },
      });
      cache.startOrderModification = r.status;
      await fetchOrderModification();
      return true;
    } catch (error) {
      const previous = cache.startOrderModification;
      cache.startOrderModification = error.response?.status;
      if (previous === error.response?.status && previous === 412) {
        return false;
      }
      const {
        name,
        message,
      } = await setErrorNotification({ nuxtApp, error });
      if (name === 'accept' && message) {
        return await startOrderModification({
          ...arguments[0],
          resolveOption: message,
        });
      } else if (name === 'cancel') {
        // do nothing
      } else {
        $logger.error(error);
      }
      return false;
    }
  }

  async function removeCartItem ({
    uuid,
    fromBasket,
  }) {
    userInterfaceStore.startLoading({ id: `remove-cart-item-${uuid}` });
    return await queueStore.add({
      action: removeCartItemRequest,
      actionName: 'order/removeCartItemRequest',
      payload: {
        uuid,
        fromBasket,
      },
      canStartSecondAction: true,
    });
  }

  async function removeCartItemRequest ({
    uuid,
    fromBasket = false,
  }) {
    const {
      $api,
      $awAnalytics,
      $logger,
      $awSFMCClient,
    } = nuxtApp;

    try {
      let callWhenSucceeds = () => {
      };
      try {
        callWhenSucceeds = $awAnalytics[PUSH_REMOVE_CART_ITEM]({
          uuid,
          newQuantity: {
            unit: 'piece',
            value: 0,
          },
        });
      } catch (error) {
        $logger.error(error);
      }

      const r = await $api.delete(`orders/current/cart/items/${uuid}`);
      cache.removeCartItem = r.status;

      try {
        callWhenSucceeds();
      } catch (error) {
        $logger.error(error);
      }

      await fetchOrder({
        id: 'current',
        canStartSecondAction: true,
        canRunSimultaneous: true,
        mode: fromBasket ? 'BASKET' : 'MINICART',
      });
      $awSFMCClient[PUSH_TRACK_CART_STATE]({ cart: order.value });
    } catch (error) {
      userInterfaceStore.endLoading({ id: `remove-cart-item-${uuid}` });
      const previous = cache.removeCartItem;
      cache.removeCartItem = error.response?.status;
      if (previous === error.response?.status && previous === 412) {
        return false;
      }
      const {
        name,
        message,
      } = await setErrorNotification({ nuxtApp, error });
      if (name === 'accept' && message) {
        await removeCartItem({
          ...arguments[0],
          resolveOption: message,
        });
      } else if (name === 'cancel') {
        await fetchOrder({
          id: 'current',
          canStartSecondAction: true,
          canRunSimultaneous: true,
        });
      } else {
        queueStore.removeAllElementFromQueue(false);

        $logger.error(error);
        await fetchOrder({
          id: 'current',
          canStartSecondAction: true,
          canRunSimultaneous: true,
        });
      }
    } finally {
      userInterfaceStore.endLoading({ id: `remove-cart-item-${uuid}` });
    }
  }

  async function removeAllUncreditableItem (payload) {
    userInterfaceStore.startLoading({ id: 'remove-all-uncreditable-item' });
    return await queueStore.add({
      action: removeAllUncreditableItemRequest,
      actionName: 'order/removeAllUncreditableItemRequest',
      payload,
      canStartSecondAction: true,
    });
  }

  async function removeAllUncreditableItemRequest ({
    baremId,
    resolveOption,
    isCartFetchNeeded = true,
  } = {}) {
    const {
      $api,
      $logger,
      $awSFMCClient,
    } = nuxtApp;
    try {
      try {
        const r = await $api.delete(
          `/orders/current/cart/delete-uncreditables/${baremId}`,
          addResolveOptionsHeader(resolveOption),
        );
        cache.removeAllUncreditableItem = r.status;
      } catch (error) {
        const previous = cache.removeAllUncreditableItem;
        cache.removeAllUncreditableItem = error.response?.status;
        if (previous === error.response?.status && previous === 412) {
          return false;
        }
        const {
          name,
          message,
        } = await setErrorNotification({ nuxtApp, error, enableTitleMerge: false });
        if (name === 'accept' && message) {
          await removeAllUncreditableItem({
            ...arguments[0],
            resolveOption: message,
            isCartFetchNeeded: false,
          });
        } else if (name === 'cancel') {
          // do nothing
        } else {
          $logger.error(error);
          queueStore.removeAllElementFromQueue(false);
        }
      }
      if (isCartFetchNeeded) {
        await fetchOrder({
          id: 'current',
          canStartSecondAction: true,
          canRunSimultaneous: true,
        });
        $awSFMCClient[PUSH_TRACK_CART_STATE]({ cart: order.value });
      }
    } catch (error) {
      $logger.error(error);
      queueStore.removeAllElementFromQueue(false);
    } finally {
      userInterfaceStore.endLoading({ id: 'remove-all-uncreditable-item' });
    }
  }

  async function getAllUncreditableItem (baremId) {
    const {
      $api,
      $logger,
    } = nuxtApp;

    try {
      return await $api.$get(`/orders/current/cart/uncreditables/${baremId}`);
    } catch (error) {
      $logger.error(error);
    }
  }

  async function removeAllItem (checkoutType) {
    const {
      $awAnalytics,
      $logger,
    } = nuxtApp;
    userInterfaceStore.startLoading({ id: `remove-all-item-${checkoutType}` });
    try {
      $awAnalytics[PUSH_TRUNCATE_CART]({ checkoutType });
    } catch (error) {
      $logger.error(error);
    }
    return await queueStore.add({
      action: removeAllItemRequest,
      actionName: 'order/removeAllItemRequest',
      payload: { checkoutType },
      canStartSecondAction: true,
    });
  }

  async function removeAllItemRequest ({ checkoutType }) {
    const {
      $api,
      $logger,
      $awSFMCClient,
    } = nuxtApp;
    try {
      const r = await $api.delete('orders/current/cart/splits?checkoutTypes%5B%5D=' + checkoutType);
      cache.removeAllItem = r.status;

      await fetchOrder({
        id: 'current',
        canStartSecondAction: true,
        canRunSimultaneous: true,
      });
      $awSFMCClient[PUSH_TRACK_CART_STATE]({ cart: order.value });
    } catch (error) {
      const previous = cache.removeAllItem;
      cache.removeAllItem = error.response?.status;
      if (previous === error.response?.status && previous === 412) {
        return false;
      }
      const {
        name,
        message,
      } = await setErrorNotification({ nuxtApp, error });
      if (name === 'accept' && message) {
        await removeAllItem({
          ...arguments[0],
          resolveOption: message,
        });
      } else if (name === 'cancel') {
        await fetchOrder({
          id: 'current',
          canStartSecondAction: true,
          canRunSimultaneous: true,
        });
      } else {
        queueStore.removeAllElementFromQueue(false);
        $logger.error(error);
        await fetchOrder({
          id: 'current',
          canStartSecondAction: true,
          canRunSimultaneous: true,
        });
      }
    } finally {
      userInterfaceStore.endLoading({ id: `remove-all-item-${checkoutType}` });
    }
  }

  async function deleteOrder (id) {
    const {
      $api,
      $awAnalytics,
      $logger,
    } = nuxtApp;
    const userStore = useUserStore();

    try {
      const r = await $api.delete(`/orders/${id}`);
      cache.deleteOrder = r.status;
      try {
        $awAnalytics[PUSH_DELETE_ORDER]({
          user: userStore.data,
          orderIdent: id,
        });
      } catch (error) {
        $logger.error(error);
      }
      return true;
    } catch (error) {
      const previous = cache.removeAllItem;
      cache.removeAllItem = error.response?.status;
      if (previous === error.response?.status && previous === 412) {
        return false;
      }
      const {
        name,
        message,
      } = await setErrorNotification({ nuxtApp, error });
      if (name === 'accept' && message) {
        return deleteOrder({
          ...arguments[0],
          resolveOption: message,
        });
      } else if (name === 'cancel') {
        // do nothing
      } else {
        $logger.error(error);
      }
      return false;
    }
  }

  async function deleteOrderModification () {
    const {
      $api,
      $logger,
    } = nuxtApp;
    try {
      await $api.delete('/order_modification');
      await fetchOrderModification();
      await fetchOrder({
        id: 'current',
        canStartSecondAction: true,
        canRunSimultaneous: true,
      });
    } catch (error) {
      $logger.error(error);
      setErrorNotification({ nuxtApp, error });
    }
  }

  return {
    order,
    completedOrders,
    returnUsedItem,
    cartMerge,
    statuses,
    orders,
    editedOrder,
    editedOriginalOrder,
    paymentData,
    ageConfirm,
    getCurrentOrderIsInProgress,
    getStatusById,
    isStatusDeletable,
    getStaticInfosByCheckoutType,
    getRawStaticInfo,
    getOrderItemDiscriminator,
    getCurrentFlattenedVouchers,
    getFlattenedOrderItems,
    getCurrentFlattenedOrderItems,
    getOrderPackagesByObjectId,
    getOrderItemSplit,
    getDeliveryModeAvailability,
    cartButtonDisplayStatuses,
    getModStrategy,
    findCompletedOrderTypeByOrderIdent,
    setCompletedOrderToOnline,
    setCompletedOrderToCod,
    setOrder,
    setCompletedFoodOrder,
    setCompletedNonFoodOrder,
    resetCompletedOrders,
    setReturnUsedItemFromOrder,
    setReturnUsedItem,
    removeReturnUsedItem,
    setEditedOrder,
    removeEditedOrder,
    setPaymentData,
    setAgeConfirm,
    fetchOrder,
    fetchOrderRequest,
    fetchCartMerge,
    fetchStatuses,
    fetchOrderModification,
    checkPriceChange,
    addToCart,
    addToCartRequest,
    addCoupon,
    addCouponRequest,
    updateCartItemQuantity,
    updateCartItemReturnUsed,
    updateCartItemStickerUsed,
    updateCartItem,
    updateCartItemYellowBarcode,
    updateCartItemRequest,
    startOrderModification,
    removeCartItem,
    removeCartItemRequest,
    removeAllUncreditableItem,
    removeAllUncreditableItemRequest,
    removeAllItem,
    removeAllItemRequest,
    deleteOrder,
    deleteOrderModification,
    getAllUncreditableItem,
  };
});

function addResolveOptionsHeader (resolveOption) {
  return (resolveOption
    ? {
      headers: {
        'X-Error-Resolve-Option': resolveOption,
      },
    }
    : {}
  );
}
