export const ecomShopPages = [
  'shop',
  'shop-profile-billing-addresses',
  'shop-profile-data',
  'shop-profile-delivery-addresses',
  'shop-profile-manage',
  'shop-profile-order-again',
  'shop-profile-orders',
  'shop-profile-saved-baskets',
  'shop-checkout-type',
  'shop-list-list_name',
  'shop-basket',
  'shop-compare',
  'product-list-id',
  'product-id',
  'food-delivery-map',
].reduce((acc, curr) => {
  acc.push(
    `${curr}___hu`,
    `${curr}___en`,
  );
  return acc;
}, []);
