import { defineNuxtPlugin, useRequestEvent } from 'nuxt/app';

export default defineNuxtPlugin((nuxtApp) => {
  const requestEvent = useRequestEvent()?.node?.req;

  nuxtApp.provide('urlParts', function (url) {
    return urlParts(getHost(requestEvent), url);
  });
  nuxtApp.provide('getHref', function () {
    return getHref(requestEvent);
  });
  nuxtApp.provide('getHost', function () {
    return getHost(requestEvent);
  });
  nuxtApp.provide('hyperlinkComputeBindFromUrl', function (url) {
    return hyperlinkComputeBindFromUrl(getHost(requestEvent), url);
  });
});

export function getHref (request) {
  return import.meta.server ? `${request.headers.host}${request.url}` : window.location.href;
}

export function getHost (request) {
  return import.meta.server ? request.headers.host : window.location.host;
}

const acceptedProtocols = ['//', 'http://', 'https://'];

export function urlParts (hostString, url) {
  if (url[0] === '/' && url[1] !== '/') {
    return {
      isOuter: false,
      link: url,
    };
  }
  if (['', ...acceptedProtocols].some(protocol => url === `${protocol}${hostString}`)) {
    return {
      isOuter: false,
      link: '/',
    };
  }
  const protocol = ['', ...acceptedProtocols].find(protocol => url.startsWith(`${protocol}${hostString}/`));
  if (typeof protocol === 'string') {
    return {
      isOuter: false,
      link: url.slice(`${protocol}${hostString}`.length),
    };
  }
  if (acceptedProtocols.some(p => url.startsWith(p))) {
    return {
      isOuter: true,
      link: url,
    };
  }
  return {
    isOuter: false,
    link: `/${url}`,
  };
}

function hyperlinkComputeBindFromUrl () {
  const { isOuter, link } = urlParts(...arguments);
  return {
    ...(isOuter ? { target: '_blank' } : null),
    [isOuter ? 'href' : 'to']: link,
  };
}
