<template>
  <div :class="$style.panelWrapper">
    <div :class="[$style.panel, {[$style.panelShow]: show}]">
      <slot />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'AwHeaderInfoPanel',
    props: {
      show: {
        type: Boolean,
        required: true,
      },
    },

  };
</script>

<style module lang="scss" rel="stylesheet/scss">
.panel {
  padding: 12px 16px;
  transition: all $animation-speed-medium $animation-timing-function;
  transform: translateY(-1000px);
  border-top: 1px solid $color-border;
  border-radius: 0 0 16px 16px;
  background-color: $color-background-3;

  @include tablet(min) {
    padding: 12px 48px;
  }
  @include desktop-small(min) {
    padding: 12px 60px;
  }
  @include desktop-medium(min) {
    padding: 12px 96px;
  }

  &Wrapper {
    overflow: visible;
    height: 0;
  }

  &Show {
    transition: all $animation-speed-medium $animation-timing-function;
    transform: translateY(0);
  }
}
</style>
