import { envIsBoolean } from './pEnv.js';

/**
 * CRUD JS object cache
 * */
export default class ObjectCache {
  static cacheLog () {
    if (envIsBoolean(process.env.IS_NUXT_CACHE_LOG_ENABLED)) {
      // eslint-disable-next-line no-console
      console.log(process.pid, ...arguments);
    }
  }

  constructor (id, separator) {
    this._id = `object_cache/${id}`;
    this._separator = separator;
    this._storage = {};
  }

  _getCacheKey (keyParts) {
    return (
      typeof keyParts === 'string'
        ? keyParts
        : keyParts.join(this._separator)
    );
  }

  _ttlExpired (data) {
    return !data?.ttl || data.ttl < new Date();
  }

  initCache (keyParts, cb) {
    const CACHE_KEY = this._getCacheKey(keyParts);
    ObjectCache.cacheLog(CACHE_KEY, 'ttl', !!this._storage[CACHE_KEY], this._storage[CACHE_KEY]?.ttl, this._ttlExpired(this._storage[CACHE_KEY]));
    if (!this._storage[CACHE_KEY] || this._ttlExpired(this._storage[CACHE_KEY])) {
      this.updateCache(keyParts, cb);
    }
  }

  updateCache (keyParts, cb) {
    const CACHE_KEY = this._getCacheKey(keyParts);
    this._storage[CACHE_KEY] = new ObjectCacheStorage(`${this._id}/${CACHE_KEY}`, cb());
    ObjectCache.cacheLog(CACHE_KEY, 'Update new ttl ->', this._storage[CACHE_KEY].ttl);
  }

  deleteCache (keyParts) {
    const CACHE_KEY = this._getCacheKey(keyParts);
    delete this._storage[CACHE_KEY];
  }

  getCache (keyParts) {
    const CACHE_KEY = this._getCacheKey(keyParts);
    return this._storage[CACHE_KEY];
  }
}

class ObjectCacheStorage {
  constructor (id, value) {
    this._id = `object_cache_storage?id=${id}`;
    const nuxtCleanCacheInMinutes = parseInt(process.env.CLEAN_NUXT_CACHE_IN_MINUTES || 10);
    const createdTtl = new Date();
    createdTtl.setMinutes(createdTtl.getMinutes() + nuxtCleanCacheInMinutes);
    this.ttl = createdTtl;
    this.value = value;
  }
}
