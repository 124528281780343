export default {
  computed: {
    awListeners () {
      return filterAllAttrs('listeners', this.$attrs);
    },
    awAttrsNoListeners () {
      return {
        ...this.awAttrsNoStyleNoClassNoListeners,
        style: this.$attrs.style,
        class: this.$attrs.class,
      };
    },
    awAttrsNoStyleNoClassNoListeners () {
      return filterAllAttrs('attrs-no-style-no-class', this.$attrs);
    },
  },
};

function filterAllAttrs (type, allAttrs) {
  const obj = {};
  for (const attrKey in allAttrs) {
    const isEvent = attrKey.startsWith('on');
    if (
      (type === 'listeners' && isEvent) ||
      (type === 'attrs-no-style-no-class' && !isEvent && attrKey !== 'style' && attrKey !== 'class')
    ) {
      obj[attrKey] = allAttrs[attrKey];
    }
  }
  return obj;
}

export function awAttrsNoStyleNoClassNoListeners (attrs) {
  return filterAllAttrs('attrs-no-style-no-class', attrs);
}
