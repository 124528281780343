import { useRuntimeConfig } from 'nuxt/app';

export default {
  data () {
    const config = useRuntimeConfig();
    return {
      isShop: config.public.isShop,
    };
  },
};
