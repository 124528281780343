import { defineStore } from 'pinia';
import { ref } from 'vue';

const VARIANT_PROP = 'selectedVariant';
export const useCompareStore = defineStore('compare', () => {
  const compareItems = ref([]);
  const compareItemIndex = ref(0);
  const maxCompareCount = ref(4);
  const isComparePage = ref(false);
  function pushNewCompareItem (compareItem) {
    if (compareItems.value.length <= maxCompareCount.value) {
      if (!compareItems.value.find(item => item.id === compareItem.id)) {
        // reset details array
        compareItem.selectedVariant.details = [];
        compareItems.value[compareItems.value.length] = compareItem;
      }
    }
  }
  function removeCompareItem (index) {
    compareItems.value.splice(index, 1);
  }
  function removeAllCompareItems () {
    compareItems.value = [];
  }

  function setIsComparePage (payload) {
    isComparePage.value = payload;
  }

  function setProductDetails (payload) {
    if (compareItems.value[payload.index][VARIANT_PROP].details) {
      compareItems.value[payload.index][VARIANT_PROP].details[compareItems.value[payload.index][VARIANT_PROP].details.length] = payload.result;
    }
  }

  function changeCompareItemIndex (payload) {
    compareItemIndex.value = payload;
  }

  return {
    compareItems,
    compareItemIndex,
    maxCompareCount,
    isComparePage,
    pushNewCompareItem,
    removeCompareItem,
    removeAllCompareItems,
    setIsComparePage,
    setProductDetails,
    changeCompareItemIndex,
  };
});
