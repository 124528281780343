<template>
  <aw-spinner />
</template>
<script>
  import { defineNuxtComponent, navigateTo, useRoute } from 'nuxt/app';
  import { fetchKey } from '~~/common/utils/awRunLegacyAsyncData.js';
  import { defineAsyncComponent } from 'vue';

  export default defineNuxtComponent({
    name: 'BlueMediaPaymentReturn',
    components: { AwSpinner: defineAsyncComponent(() => import('~~/common/components/Common/AwSpinner')) },

    fetchKey,
    asyncData ({
      $logger,
    }) {
      const route = useRoute();
      try {
        navigateTo(`/payment-redirect?transactionNumber=${route.query.OrderID}&isCardInfoShown=0`, { redirectCode: 302 });
        return {};
      } catch (err) {
        $logger.error(err);
      }
      return {};
    },
  });
</script>
