<template>
  <div :class="[$style.mainContainer, {[$style.row]: $parent.row}, $style[inputSize]]">
    <label>
      <input
        v-bind="awAttrsNoStyleNoClassNoListeners"
        :id="radioId"
        type="radio"
        :name="radioName"
        :disabled="disabled"
        :checked="checked"
        @change="$parent.$emit('update:modelValue', value)"
        @blur="$emit('blur', $event)"
      >
      <lv-icon
        v-if="checked"
        :key="disabled ? 'radio-checked-disabled-24' : 'radio-checked-24'"
        :class="[$style.icon, {[$style.disabled]: disabled}]"
        :name="disabled ? 'radio-checked-disabled-24' : 'radio-checked-24'"
        :size="24"
        :fill="false"
      />
      <lv-icon
        v-else
        :key="disabled ? 'radio-unchecked-disabled-24' : 'radio-unchecked-24'"
        :class="[$style.icon, {[$style.disabled]: disabled}]"
        :name="disabled ? 'radio-unchecked-disabled-24' : 'radio-unchecked-24'"
        :size="24"
        :fill="false"
      />
    </label>
    <div :class="[$style.labelContainer]">
      <label
        v-if="$slots.label?.()?.[0]?.children?.length"
        :class="[{[$style.disabled]: disabled}, {[$style.checked]: checked}, $style[labelSize]]"
        :for="radioId"
      >
        <slot name="label" />
      </label>
      <label
        v-else-if="label"
        :class="[{[$style.disabled]: disabled}, {[$style.checked]: checked}, $style[labelSize]]"
        :for="radioId"
      >
        {{ label }}
      </label>
    </div>
  </div>
</template>

<script>
  import { LvIcon } from '~~/common/components/loginet-vue-shop/index.mjs';
  import { uuid4 } from '~~/common/utils';
  import awListenersMixin from '~~/common/mixins/awListenersMixin.js';

  export default {
    name: 'AwRadio',
    components: {
      LvIcon,
    },
    mixins: [awListenersMixin],
    props: {
      disabled: {
        type: Boolean,
        default: false,
      },
      label: {
        type: String,
        default: '',
      },
      labelSize: {
        type: String,
        default: 'lg',
        validator: value => ['xs', 'md', 'lg'].includes(value),
      },
      inputSize: {
        type: String,
        default: 'lg',
        validator: value => ['xs', 'md', 'lg'].includes(value),
      },
      value: {
        type: [Boolean, String, Number],
        default: '',
        required: true,
      },
    },
    emits: ['blur', 'update:modelValue'],
    data () {
      return {
        inputId: null,
      };
    },
    computed: {
      radioId () {
        return `radio-${this.$parent.groupId}-|-${this.inputId}`;
      },
      radioName () {
        return `radio-${this.$parent.groupId}`;
      },
      checked () {
        return this.value.toString() && this.$parent.modelValue.toString() === this.value.toString();
      },
    },
    mounted () {
      this.inputId = uuid4();
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
.mainContainer {
  &.lg {
    --spacing: 16px;
  }

  &.md {
    --spacing: 12px;
  }

  &.xs {
    --spacing: 8px;
  }

  display: flex;
  align-items: center;
  margin: 3px 0;

  &.row {
    margin-right: var(--spacing);

    &:last-child {
      margin-right: 0;
    }
  }

  label {
    input {
      appearance: none;
      position: absolute;
      width: 0;
      height: 0;
      opacity: 0.01;
    }

    .icon {
      cursor: pointer;
      stroke: none;

      &.disabled {
        cursor: not-allowed;
      }
    }
  }

  .labelContainer {
    margin-left: 8px;

    label {
      cursor: pointer;
      user-select: none;

      &.xs {
        font-size: $font-size-lg;
      }

      &.md {
        font-size: $font-size-md;
      }

      &.lg {
        font-size: $font-size-xs;
      }

      &.disabled {
        cursor: not-allowed;
      }

      &.checked {
        font-weight: $font-weight-bold;
      }
    }
  }
}
</style>
