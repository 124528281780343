import { defineStore } from 'pinia';
import { useNuxtApp } from 'nuxt/app';
import { useOrderStore } from './order';

export const useSearchStore = defineStore('search', () => {
  const nuxtApp = useNuxtApp();

  const orderStore = useOrderStore();

  async function searchesLog ({ type, keyword, resultId, resultCallback }) {
    const { $api, $logger, $awRuntimeConfig } = nuxtApp;
    if (!$awRuntimeConfig.searchLogType) {
      return;
    }
    if (resultCallback) {
      resultId = await resultCallback();
    }
    try {
      let addedResults;
      if (type === 'addToCart') {
        const itemIdsInBasket = orderStore.getCurrentFlattenedOrderItems
          .map(({ variant: { productId } }) => productId)
        ;
        addedResults = (Array.isArray(resultId) ? resultId : [resultId])
          .filter(res => !itemIdsInBasket.includes(res))
        ;
        if (!addedResults.length) {
          return;
        } else if (addedResults.length === 1) {
          addedResults = addedResults[0];
        }
      }
      const dataToSend = addedResults || resultId;
      const dataToSendIsArray = Array.isArray(dataToSend);

      await $api.$put(
        dataToSendIsArray ? '/searches/log/batch' : '/searches/log',
        {
          type,
          keyword,
          [dataToSendIsArray ? 'results' : 'resultId']: dataToSend,
        },
      );
    } catch (e) {
      $logger.error(e);
    }
  }

  async function searchesInvalidateQuery () {
    const { $api, $logger, $awRuntimeConfig } = nuxtApp;
    if (!$awRuntimeConfig.searchLogType) {
      return;
    }
    try {
      await $api.$delete('/searches/invalidate-query');
    } catch (e) {
      $logger.error(e);
    }
  }

  return {
    searchesLog,
    searchesInvalidateQuery,
  };
});
