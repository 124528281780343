export default {
  props: {
    headingTag: {
      type: String,
      required: true,
    },
  },
  computed: {
    childrenHeadingTag () {
      return this.headingTagMixinCalculateHeadingTag(this.headingTag);
    },
    grandChildrenHeadingTag () {
      return this.headingTagMixinCalculateHeadingTag(this.childrenHeadingTag);
    },
  },
  methods: {
    headingTagMixinCalculateHeadingTag (parentTag) {
      const childrenHeading = calculateHeadingChildren(parentTag);
      if (childrenHeading) {
        return childrenHeading;
      } else {
        this.$logger.warn('childrenHeadingTag can not be determined');
        return parentTag;
      }
    },
  },
};

export function calculateHeadingChildren (parentHeadingTag) {
  const headingLevel = parseInt(parentHeadingTag.match(/h(\d)/, '$1')?.[1], 10);
  if (headingLevel) {
    return `h${headingLevel + 1}`;
  }
}
