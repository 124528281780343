export const envIsBoolean = val => ['1', 'true'].includes(val);
export const pEnv = new Proxy(process.env, {
  get (envs, key) {
    const value = envs[key];
    if (value === undefined) {
      throw new Error(`${key} doesnt exist inside envs.`);
    }
    return value;
  },
});
