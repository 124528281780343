import { defineAsyncComponent } from 'vue';

const BANNER_TEMPLATES = {
  AW_TILE: 'awtile_template',
  AW_NARWHAL: 'awnarwhal_template',
};

export default {
  [BANNER_TEMPLATES.AW_TILE]: {
    component: defineAsyncComponent(() => import('../components/Banner/AwTile.vue')),
  },
  [BANNER_TEMPLATES.AW_NARWHAL]: {
    component: defineAsyncComponent(() => import('../components/Banner/AwNarwhal.vue')),
  },
};

export {
  BANNER_TEMPLATES,
};
