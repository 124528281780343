// Pinia Store
import { defineStore } from 'pinia';
import { useNuxtApp } from 'nuxt/app';
import { ref } from 'vue';
import { setErrorNotification } from '~~/common/utils/helper.js';

export const useNotificationBarStore = defineStore('notificationBar', () => {
  const nuxtApp = useNuxtApp();

  const notifications = ref(null);

  async function fetchNotifications () {
    const {
      $api,
      $logger,
    } = nuxtApp;
    try {
      notifications.value = await $api.$get('/notification_list');
    } catch (error) {
      $logger.error(error);
      setErrorNotification({ nuxtApp, error });
    }
  }

  return {
    notifications,
    fetchNotifications,
  };
});
