<template>
  <div
    ref="searchWrapperElement"
    :class="[
      $style.headerSearch,
      {
        [$style.headerSearchIsSmall]: isSmall,
      },
    ]"
    @keyup.escape="closeAndFocusBack"
  >
    <label :for="`aw_input_${componentId}`" class="awSrOnlyAbs" v-text="$awt(srOnlyLabel)" />
    <input
      :id="`aw_input_${componentId}`"
      ref="inputElement"
      data-search-input
      :data-search-input-filled="searchText.length ? true: null"
      :value="searchText"
      type="text"
      autocomplete="off"
      autocorrect="on"
      spellcheck="true"
      aria-autocomplete="none"
      :class="[
        $style.headerSearchInput,
        {
          [$style.headerSearchInputOpened]: showHeaderSearch,
          [$style.headerSearchInputFilled]: searchText.length,
        },
      ]"
      :placeholder="placeholder"
      @input="evt => emits('update:search-text', evt.target.value)"
      @click="openInput"
      @touchstart="openInput"
      @mousedown="openInput"
      @keydown.shift.tab.exact="closeAndResetInput"
      @keydown.enter="emitSearch(searchText)"
    >
    <div :class="$style.headerSearchInputContent">
      <div
        ref="group1"
        :class="$style.headerSearchInputContentButtonContainer"
      >
        <aw-spinner :class="[$style.spinner, {[$style.spinnerHidden]: !isSpinning}]" />
        <lv-button
          v-show="showHeaderSearch || searchText.length"
          styling="small-secondary-dark-just-icon"
          :class="['buttonReset', ]"
          :title="$awt('aw.common.header.search_reset')"
          :style="isSearchBtnShowed ? null : 'grid-column-end: -1'"
          @click="clearInput"
        >
          <lv-icon name="close-24" :size="16" style="vertical-align: unset;" />
        </lv-button>
        <lv-button
          v-show="isSearchBtnShowed || !(showHeaderSearch || searchText.length)"
          styling="small-primary-dark-just-icon"
          :class="[
            'buttonReset',
            {
              [$style.headerSearchInactiveSearchButton]: !(showHeaderSearch || searchText.length),
            },
          ]"
          :title="$awt('aw.common.header.search_icon')"
          @click="emitSearch(searchText)"
          @keydown.tab.exact="closeAndResetInput"
        >
          <lv-icon name="search-16" :size="16" style="vertical-align: unset;" />
        </lv-button>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref, nextTick, defineAsyncComponent } from 'vue';
  import { useId } from 'nuxt/app';
  import { LvIcon, LvButton } from '~~/common/components/loginet-vue-shop/index.mjs';

  const AwSpinner = defineAsyncComponent(() => import('~~/common/components/Common/AwSpinner'));

  defineProps({
    searchText: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    isSpinning: {
      type: Boolean,
      default: false,
    },
    isSmall: {
      type: Boolean,
      default: true,
    },
    isSearchBtnShowed: {
      type: Boolean,
      default: true,
    },
    srOnlyLabel: {
      type: String,
      default: 'aw.common.header.user.search_input_placeholder',
    },
  });
  const emits = defineEmits(['update:search-text', 'search', 'clear']);
  const componentId = useId();
  const showHeaderSearch = ref(false);
  const searchWrapperElement = ref(null);
  const inputElement = ref(null);

  function onClickoutSide (target) {
    if (!searchWrapperElement.value.contains(target.target)) {
      closeAndResetInput();
    }
  }

  function clearInput () {
    emits('update:search-text', '');
    nextTick(() => {
      closeAndResetInput();
      emits('clear');
    });
  }

  function closeAndResetInput () {
    showHeaderSearch.value = false;
    inputElement.value.blur();
    document.removeEventListener('click', onClickoutSide);
    document.removeEventListener('keyup', onClickoutSide);
  }

  function openInput () {
    showHeaderSearch.value = true;
    document.addEventListener('click', onClickoutSide);
    document.addEventListener('keyup', onClickoutSide);
  }

  function emitSearch (searchTerm) {
    closeAndResetInput();
    emits('search', searchTerm);
  }
</script>

<style module lang="scss" rel="stylesheet/scss">

.headerSearch {
  --centeredButtonPosition: -43px;
  position: relative;
  z-index: 10;
  height: 54px;
  margin: 0;

  @include desktop-medium(min) {
    margin: 0;
  }

  &IsSmall {
    --centeredButtonPosition: -40px;
    height: 48px;
  }

  &InactiveSearchButton {
    position: absolute;
    top: 0;
    left: 0;
    color: $color-text-secondary !important;
    background: transparent !important;
    box-shadow: none !important;

    &:hover, &:focus, &:active {
      background: transparent !important;
      box-shadow: none !important;
    }

    &:focus-visible {
      box-shadow: inset 0 0 0 1px currentColor !important;
    }
  }

  &Input {
    font-size: 14px;
    line-height: 20px;
    width: 100%;
    height: 100%;
    padding: 7px 7px 7px 39px;
    color: $color-text-primary;
    border: 1px solid $color-background-2;
    border-radius: 12px;
    background: $color-background-2;

    &:hover:not(&Opened, &Filled) {
      border-color: $color-border;
    }

    &::placeholder {
      color: $color-text-secondary;
    }

    &:focus::placeholder {
      color: transparent;
    }

    &Content {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      width: calc(100% - 16px);
      height: 0;
      transform: translate(12px, var(--centeredButtonPosition));

      &ButtonContainer {
        display: grid;
        align-items: center;
        justify-content: center;
        grid-template-columns: auto 1fr 1fr;
        gap: 8px;

        .spinner {
          margin-right: 2px;
          color: $color-text-primary;

          &Hidden {
            visibility: hidden;
          }
        }
      }

    }

    &Opened,
    &Filled {
      padding: 7px 95px 7px 15px;
      border-color: $color-border-active;
      background: $color-background-4;
    }
  }
}
</style>
