export function createAccordionTransition ({ safeToRemoveCb, startTransitionCb, endTransitionCb } = {}) {
  return {
    onEnter (el) {
      if (typeof safeToRemoveCb === 'function') {
        safeToRemoveCb(false);
      }
      window.requestAnimationFrame(() => {
        startTransition(el);
      });
    },
    onAfterEnter (el) {
      endTransition(el);
    },
    onBeforeLeave (el) {
      startTransition(el);
    },
    onAfterLeave (el) {
      if (typeof safeToRemoveCb === 'function') {
        safeToRemoveCb(true);
      }
      window.requestAnimationFrame(() => {
        endTransition(el);
      });
    },
  };
  function startTransition (el) {
    if (typeof startTransitionCb === 'function') {
      startTransitionCb();
    }
    el.style.height = el.scrollHeight + 'px';
  }
  function endTransition (el) {
    if (typeof endTransitionCb === 'function') {
      endTransitionCb();
    }
    el.style.height = '';
  }
}
