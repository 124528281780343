<template>
  <div :class="$style.accordion" :aria-label="label">
    <client-only>
      <slot />
    </client-only>
  </div>
</template>

<script>

  export default {
    name: 'AwAccordion',
    provide () {
      return {
        updateAccordion: this.updateAccordion,
        removeAccordion: this.removeAccordion,
        controlTag: this.controlTag,
        accordions: this.accordions,
        styling: this.styling,
        emit: this.emit,
      };
    },
    props: {
      styling: {
        type: String,
        default: '',
      },
      label: {
        type: String,
        required: true,
      },
      controlTag: {
        type: String,
        default: 'div',
      },
    },
    data () {
      return {
        accordions: {},
      };
    },
    methods: {
      updateAccordion (id, status) {
        this.accordions[id] = status;
        if (status) {
          Object.keys(this.accordions).forEach(key => {
            if (key !== id) {
              this.accordions[key] = false;
            }
          });
        }
      },
      removeAccordion (id) {
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete this.accordions[id];
      },
      emit (event, payload) {
        this.$emit(event, payload);
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
.accordion {
  display: block;
  overflow: hidden;
}
</style>
