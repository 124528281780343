export function getPropOrObject (opt, prop) {
  if (opt == null) {
    return opt;
  } else {
    return (Object.prototype.hasOwnProperty.call(opt, prop) ? opt[prop] : opt);
  }
}
export function isOptEq (opt1, opt2, prop) {
  return getPropOrObject(opt1, prop) === getPropOrObject(opt2, prop);
}
export function isEventIgnorable ($event) {
  return ($event.ctrlKey || $event.shiftKey) && ['ArrowUp', 'ArrowRight', 'ArrowDown', 'ArrowLeft', 'Home', 'End'].includes($event.key);
}
export function isEventAutoOpens ($event) {
  return ['ArrowUp', 'ArrowDown', 'Home', 'End', 'Enter', ' '].includes($event.key) || isEventSearchAble($event);
}
export function isEventSearchAble ($event) {
  return $event.key.length === 1 && /\S/u.test($event.key);
}
export function searchFunction ({ $event, search, searchLabel, searchId, searchOptions, currentVisualFocusId }) {
  search.searchString += $event.key.toLowerCase();
  clearTimeout(search.idThrottleSearch);
  search.idThrottleSearch = setTimeout(() => {
    search.searchString = '';
  }, 1000);
  const textSearch = (query, arr) => {
    return arr.find(option => `${option[searchLabel]}`.toLowerCase().startsWith(query));
  };
  const searchResult = textSearch(search.searchString, searchOptions);
  if (searchResult) {
    return {
      newVisualFocusId: searchResult[searchId],
    };
  } else {
    const [ch1, ch2] = search.searchString.slice(-2);
    if (ch1 === ch2) {
      const currentIdx = currentVisualFocusId === null ? -1 : searchOptions.findIndex(option => option[searchId] === currentVisualFocusId);
      const forwardSearch = textSearch(ch1, searchOptions.slice(currentIdx + 1));
      if (forwardSearch) {
        return {
          newVisualFocusId: forwardSearch[searchId],
        };
      } else {
        const backwardSearch = textSearch(ch1, searchOptions.slice(0, currentIdx + 1));
        if (backwardSearch) {
          return {
            newVisualFocusId: backwardSearch[searchId],
          };
        }
      }
    }
  }
}
export function getNewIdByEventKey ({ $event, currentVisualFocusId, idKey, options }) {
  if (!$event.altKey) {
    const currentIndex = options.findIndex(opt => isOptEq(opt, currentVisualFocusId, idKey));
    const basicOffset = {
      ArrowUp: currentIndex - 1,
      ArrowDown: currentIndex + 1,
      Home: 0,
      End: options.length - 1,
      PageUp: currentIndex - 10,
      PageDown: currentIndex + 10,
    };
    if (Object.prototype.hasOwnProperty.call(basicOffset, $event.key)) {
      const newIndex = Math.max(0, Math.min(basicOffset[$event.key], options.length - 1));
      return {
        id: getPropOrObject(options[newIndex], idKey),
      };
    }
  }
}
export function getToggleAbleItem ({ $event, isSingle, currentVisualFocusId, idKey, options }) {
  if (['Enter', ' '].includes($event.key) || ($event.key === 'ArrowUp' && $event.altKey) || ($event.key === 'Tab' && isSingle)) {
    return options.find(option => isOptEq(option, currentVisualFocusId, idKey));
  }
}
