<template>
  <aw-empty>
    <template #default>
      <lv-heading level="1" tag="h1" version2>
        {{ $awt('aw.pages.profile.empty.information') }}
      </lv-heading>
    </template>
    <template #button>
      <lv-button
        styling="primary-dark"
        :loading-id="LOGGED_OUT_TOASTER_PROFILE_EMPTY"
        @click="openLoginModal(isCareerUser ? {
          loadingId: LOGGED_OUT_TOASTER_PROFILE_EMPTY,
          showToaster: true,
          toasterAttributes: {
            title: $awt('aw.career.toast.logged_out_from_career_profile'),
          },
        } : {})"
      >
        {{ $awt('aw.pages.profile.empty.button') }}
      </lv-button>
    </template>
  </aw-empty>
</template>

<script setup>
  import { defineAsyncComponent } from 'vue';
  import { LvHeading, LvButton } from '~~/common/components/loginet-vue-shop/index.mjs';
  import { openLoginModal } from '~~/common/mixins/userLoginAndRegistrationMixin.js';
  import { useUserStore } from '~~/common/stores/user.js';
  import { LOGGED_OUT_TOASTER_PROFILE_EMPTY } from '~~/common/config/LoadingIdConfig.js';

  const AwEmpty = defineAsyncComponent(() => import('~~/common/components/Common/AwEmpty'));

  const isCareerUser = computed(() => useUserStore().isCareerUser);

</script>
