<template>
  <animate
    v-bind="attributes.properties"
    ref="animateComponent"
  />
</template>

<script setup>
  import { onMounted, onBeforeUnmount } from 'vue';

  const props = defineProps({
    attributes: {
      type: Object,
      required: true,
    },
  });

  const animateComponent = ref(null);

  onMounted(() => {
    if (animateComponent.value) {
      animateComponent.value.addEventListener('beginEvent', beginEvent);
      animateComponent.value.addEventListener('endEvent', endEvent);
    }
  });

  onBeforeUnmount(() => {
    if (animateComponent.value) {
      animateComponent.value.removeEventListener('beginEvent', beginEvent);
      animateComponent.value.removeEventListener('endEvent', endEvent);
    }
  });

  function beginEvent (event) {
    props.attributes?.onbegin(event);
  }
  function endEvent (event) {
    props.attributes?.onend(event);
  }

</script>
