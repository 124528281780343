<template>
  <span
    :class="[{[$style.fill]: fill, [$style.fillAndStroke]: fillAndStroke, [$style.inheritColor]: inheritColor, [$style.square]: square}, $style.icon, $style[sizeClass]]"
    @click="$emit('click')"
    v-html="svgModule || ''"
  />
</template>

<script>
  import { uuid4 } from '~~/common/utils';

  const auchanIcons = {
    auchanIconModules: {
      loadScript: import.meta.glob('~~/common/aw_icons/src/*.svg', {
        query: '?raw',
        import: 'default',
      }),
      modulePath: '',
    },
    auchanIconModulesSocial: {
      loadScript: () => import('~~/common/aw_icons/social.js'),
      modulePath: 'social/',
      files: import.meta.glob('~~/common/aw_icons/src/social/*.svg'),
    },
    auchanIconModulesSocial2: {
      loadScript: () => import('~~/common/aw_icons/social2.js'),
      modulePath: 'social2/',
      files: import.meta.glob('~~/common/aw_icons/src/social2/*.svg'),
    },
    auchanIconModulesControl: {
      loadScript: () => import('~~/common/aw_icons/controls.js'),
      modulePath: 'controls/',
      files: import.meta.glob('~~/common/aw_icons/src/controls/*.svg'),
    },
    auchanIconModulesForm: {
      loadScript: () => import('~~/common/aw_icons/forms.js'),
      modulePath: 'forms/',
      files: import.meta.glob('~~/common/aw_icons/src/forms/*.svg'),
    },
    auchanIconModulesFaq: {
      loadScript: () => import('~~/common/aw_icons/faq.js'),
      modulePath: 'faq/',
      files: import.meta.glob('~~/common/aw_icons/src/faq/*.svg'),
    },
    auchanIconModulesProduct: {
      loadScript: () => import('~~/common/aw_icons/product.js'),
      modulePath: 'product/',
      files: import.meta.glob('~~/common/aw_icons/src/product/*.svg'),
    },
    auchanIconModulesNoti: {
      loadScript: () => import('~~/common/aw_icons/noti.js'),
      modulePath: 'noti/',
      files: import.meta.glob('~~/common/aw_icons/src/noti/*.svg'),
    },
    auchanIconModulesProfile: {
      loadScript: () => import('~~/common/aw_icons/profile.js'),
      modulePath: 'profile/',
      files: import.meta.glob('~~/common/aw_icons/src/profile/*.svg'),
    },
    auchanIconModulesHeader: {
      loadScript: () => import('~~/common/aw_icons/header.js'),
      modulePath: 'header/',
      files: import.meta.glob('~~/common/aw_icons/src/header/*.svg'),
    },
    auchanIconModulesCareer: {
      loadScript: () => import('~~/common/aw_icons/career.js'),
      modulePath: 'career/',
      files: import.meta.glob('~~/common/aw_icons/src/career/*.svg'),
    },
    auchanIconModulesLoyalty: {
      loadScript: () => import('~~/common/aw_icons/loyalty.js'),
      modulePath: 'loyalty/',
      files: import.meta.glob('~~/common/aw_icons/src/loyalty/*.svg'),
    },
    auchanIconModulesCheckout: {
      loadScript: () => import('~~/common/aw_icons/checkout.js'),
      modulePath: 'checkout/',
      files: import.meta.glob('~~/common/aw_icons/src/checkout/*.svg'),
    },
    auchanIconModulesDealer: {
      loadScript: () => import('~~/common/aw_icons/dealer.js'),
      modulePath: 'dealer/',
      files: import.meta.glob('~~/common/aw_icons/src/dealer/*.svg'),
    },
    auchanIconModules404: {
      loadScript: () => import('~~/common/aw_icons/404.js'),
      modulePath: '404/',
      files: import.meta.glob('~~/common/aw_icons/src/404/*.svg'),
    },
  };
  export default {
    name: 'LvIcon',
    props: {
      fill: {
        type: Boolean,
        default: false,
      },
      fillAndStroke: {
        type: Boolean,
        default: false,
      },
      square: {
        type: Boolean,
        default: true,
      },
      name: {
        type: String,
        required: true,
      },
      size: {
        type: Number,
        required: true,
      },
      prefix: {
        type: String,
        required: false,
        default: 'icon',
      },
      inheritColor: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['click'],
    data () {
      return {
        svgModule: null,
      };
    },
    computed: {
      sizeClass () {
        return `size-${this.size}`;
      },
    },
    watch: {
      name (newVal, oldVal) {
        if (newVal !== oldVal) {
          if (import.meta.client) {
            this.getModule(newVal);
          }
        }
      },
    },
    mounted () {
      this.getModule(this.name);
    },
    methods: {
      setSvgModule (newVal) {
        this.svgModule = newVal.replace(/awGenerateId_/g, `aw${uuid4()}_`);
      },
      getModule (iconModuleName) {
        if (iconModuleName) {
          let importName = '';
          for (const key in auchanIcons) {
            importName = `../common/aw_icons/src/${auchanIcons[key].modulePath}${iconModuleName}.svg`;
            if (auchanIcons[key].files) {
              if (Object.keys(auchanIcons[key].files).includes?.(importName)) {
                return auchanIcons[key].loadScript().then((c) => {
                  this.setSvgModule(c.default[importName]);
                });
              }
            }
          }
          importName = `../common/aw_icons/src/${iconModuleName}.svg`;
          return auchanIcons.auchanIconModules.loadScript[importName]().then((c) => {
            this.setSvgModule(c);
          });
        }
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
.icon {
  fill: none;
  stroke: currentColor;
  display: inline-block;
  stroke-linejoin: round;
  vertical-align: middle;
}

.square {
  width: 1em;
  height: 1em;
}

.size {
  @each $lv-icon-size-name, $lv-icon-size-font-size, $lv-icon-size-line-height in $lv-icon-sizes {
    &-#{$lv-icon-size-name} {
      font-size: $lv-icon-size-font-size;
      @if ($lv-icon-size-line-height) {
        stroke-width: $lv-icon-size-line-height;
      }
    }
  }
}

.fill {
  stroke: none;
  fill: currentColor;
}

.fillAndStroke {
  fill: currentColor;
}

.inheritColor {
  @each $lv-icon-inherit-color-variable in $lv-icon-inherit-color-variables {
    #{$lv-icon-inherit-color-variable}: currentColor !important;
  }
}
</style>
