<template>
  <aw-radio-group
    v-model="localActiveFilters[filter.id]"
    @update:model-value="activateFilter({[filter.id]: $event}, true)"
  >
    <aw-radio-version2
      v-for="(item, j) in filter.values"
      :key="`rd-${j}${checkBoxKey(filter, item)}`"
      :label="item.name"
      :value="item.value"
    />
  </aw-radio-group>
</template>

<script>
  import AwRadioVersion2 from '~~/common/components/Common/Widgets/AwRadioVersion2.vue';
  import AwRadioGroup from '~~/common/components/Common/Widgets/AwRadioGroup.vue';

  export default {
    name: 'RadioFilter',
    components: {
      AwRadioGroup,
      AwRadioVersion2,
    },
    props: {
      checkBoxKey: {
        type: Function,
        required: true,
      },
      filter: {
        type: Object,
        required: true,
      },
      activeFilters: {
        type: Object,
        required: true,
      },
      activateFilter: {
        type: Function,
        required: true,
      },
    },
    computed: {
      localActiveFilters: {
        get () {
          return this.activeFilters;
        },
        set () {
          // NOOP
        },
      },
    },
  };
</script>
