<template>
  <div :class="$style.checkoutIndicator">
    <lv-link :class="$style.basketLink" :to="localePath(`/shop/basket?split=${currentCheckoutType}`)">
      <div :class="[$style.checkoutIndicatorItem, $style.checkoutIndicatorGreen]">
        <div :class="$style.checkoutIndicatorItemGreen">
          <lv-icon name="check-in-circle-16" :size="16" />
        </div>
        <div>
          {{ $awt('aw.dh_header.checkout_indicator.cart') }}
        </div>
      </div>
    </lv-link>
    <lv-icon name="indicator-line-16" :size="16" :class="$style.checkoutIndicatorShortLine" />
    <lv-icon name="indicator-line-32" :size="32" :class="$style.checkoutIndicatorLongLine" />
    <div :class="$style.checkoutIndicatorItem">
      <lv-icon name="notebook-16" :size="16" />
      <div>
        {{ $awt('aw.dh_header.checkout_indicator.data') }}
      </div>
    </div>
    <lv-icon name="indicator-line-16" :size="16" :class="$style.checkoutIndicatorShortLine" />
    <lv-icon name="indicator-line-32" :size="32" :class="$style.checkoutIndicatorLongLine" />
    <div :class="[$style.checkoutIndicatorItem, $style.checkoutIndicatorGrey]">
      <lv-icon name="money-16" :size="16" />
      <div>
        {{ $awt('aw.dh_header.checkout_indicator.payment') }}
      </div>
    </div>
  </div>
</template>

<script setup>
  import { LvIcon, LvLink } from '~~/common/components/loginet-vue-shop/index.mjs';
  import { useCheckoutStore } from '~~/common/stores/checkout.js';

  const checkoutStore = useCheckoutStore();
  const currentCheckoutType = computed(() => checkoutStore.checkoutType);
</script>

<style module lang="scss" rel="stylesheet/scss">
.checkoutIndicator {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;

  .dashedLine {
    width: 32px;
    border: 1px dashed $color-olive-750;

    @include tablet(max) {
      width: 16px;
    }
  }
}

.checkoutIndicatorItem {
  font-size: 16px;
  font-weight: 900;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;

  @include tablet(max) {
    font-size: 14px;
  }
}

.checkoutIndicatorGreen {
  color: $color-sage-450;
}

.checkoutIndicatorGrey {
  color: $color-text-tertiary;
}

.checkoutIndicatorItemGreen {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 8px;
  background-color: rgba($color-sage-450, 0.12);
}

.checkoutIndicatorShortLine {
  display: none;
  @include tablet(max) {
    display: block;
  }
}

.checkoutIndicatorLongLine {
  display: block;
  @include tablet(max) {
    display: none;
  }
}

.basketLink:hover {
  text-decoration: none;
}
</style>
