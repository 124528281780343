<template>
  <aw-radio-group
    v-model="radioValue"
    v-bind="appliedAttrs"
  >
    <aw-radio-toggle
      v-for="(radio, i) in modelValue.radioOptions"
      :key="i"
      :label="radio.label"
      :value="radio.value"
      :disabled="radio.disabled"
      v-bind="awListeners"
      @blur="onBlur"
    >
      <template #label>
        <slot name="label" />
      </template>
    </aw-radio-toggle>
  </aw-radio-group>
</template>

<script>
  import formMixin from '~~/common/mixins/formMixin';
  import awListenersMixin from '~~/common/mixins/awListenersMixin.js';
  import AwRadioGroup from '~~/common/components/Common/Widgets/AwRadioGroup';
  import AwRadioToggle from '~~/common/components/Common/Widgets/AwRadioToggle';

  export default {
    name: 'AwFormRadioToggleGroup',
    components: {
      AwRadioToggle,
      AwRadioGroup,
    },
    mixins: [
      formMixin,
      awListenersMixin,
    ],
    inheritAttrs: false,
    computed: {
      radioValue: {
        get () {
          return this.modelValue.model;
        },
        set (value) {
          this.modelValueData.model = value;
          if (this.blur) {
            this.validateTheRow();
          }
          this.emitData(this.modelValueData);
        },
      },
      appliedAttrs () {
        return {
          ...this.awAttrsNoListeners,
          ...this.modelValue.widgetAttrs,
        };
      },
    },
  };
</script>
