import { defineNuxtPlugin } from 'nuxt/app';
import { useAwAnalyticsStore } from '~~/common/stores/awAnalytics';

const AUCHAN_GTM_0 = 'AUCHAN_GTM_0';

export default defineNuxtPlugin(function (nuxtApp) {
  const {
    $gtmLogger,
    $pinia,
    $awMergedConfig,
  } = nuxtApp;
  const awAnalyticsStore = useAwAnalyticsStore($pinia);

  class G {
    /* getSharedData is good for persisting data between SSR and browser */
    constructor ({
      getSharedData,
      pushSharedData,
    }) {
      this._isTrackingConfigValid = Boolean($awMergedConfig.googleTagManagerId);
      this._getSharedData = getSharedData;
      this._pushSharedData = pushSharedData;
      this._isSharedDataPushed = false;
    }

    push (...data) {
      try {
        if (this._isTrackingConfigValid) {
          if (typeof window === 'undefined') {
            data.forEach((d) => {
              $gtmLogger.info('pushPre |', d);
              this._pushSharedData({ value: d });
            });
          } else {
            this.serializeSharedData();
            data.forEach((d) => {
              $gtmLogger.info('pushPost |', d);
              window.dataLayer.push(d);
            });
          }
        }
      } catch (err) {
        $gtmLogger.error(err);
      }
    }

    serializeSharedData () {
      if (!this._isSharedDataPushed && typeof window !== 'undefined') {
        this._isSharedDataPushed = true;
        $gtmLogger.info('initializedData', this._getSharedData());
        const dataCopy = JSON.parse(JSON.stringify(this._getSharedData()));
        if (window.dataLayer) {
          dataCopy.forEach(d => window.dataLayer.push(d));
        } else {
          window.dataLayer = dataCopy;
        }
      }
    }
  }

  const awGtm = new G({
    getSharedData () {
      return awAnalyticsStore[AUCHAN_GTM_0];
    },
    pushSharedData ({ value }) {
      awAnalyticsStore.pushToAnalytics({
        id: AUCHAN_GTM_0,
        value,
      });
    },
  });

  awGtm.serializeSharedData();

  if (!awAnalyticsStore[AUCHAN_GTM_0]) {
    awAnalyticsStore.setAnalytics({
      id: AUCHAN_GTM_0,
      value: [],
    });
  }

  nuxtApp.provide('awGtm', awGtm);
});
