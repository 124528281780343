<template>
  <div :class="[$style.moreItemWrapper]">
    <aw-spinner v-if="!loadCompleted" color="currentColor" :class="$style.spinner" />
    <lv-button
      v-else
      :class="{ awSrOnlyAbs: isButtonDisabled }"
      styling="secondary-dark"
      :aria-disabled="isButtonDisabled"
      @click="loadMoreItems"
    >
      {{ loadMoreButtonText }}
      <lv-icon name="arrow-down-16" :size="16" />
    </lv-button>
    <div ref="focusDiv" tabindex="-1" />
  </div>
</template>

<script>
  import { defineAsyncComponent } from 'vue';
  import {
    LvButton, LvIcon,
  } from '~~/common/components/loginet-vue-shop/index.mjs';

  export default {
    name: 'AwMoreItemsVersion2',
    components: {
      LvIcon,
      LvButton,
      AwSpinner: defineAsyncComponent(() => import('~~/common/components/Common/AwSpinner')),
    },
    props: {
      loadMoreButtonText: {
        type: String,
        default: '',
      },
      loadCompleted: {
        type: Boolean,
        default: true,
      },
      sumItems: {
        type: Number,
        default: 0,
      },
      loadedItems: {
        type: Number,
        default: 0,
      },
    },
    emits: ['load-more-items'],
    data () {
      return {
        loadMoreItemsFired: false,
      };
    },
    computed: {
      isButtonDisabled () {
        const allLoaded = this.sumItems === this.loadedItems;
        return !this.loadCompleted || allLoaded;
      },
    },
    watch: {
      loadCompleted (newVal) {
        if (!newVal && this.loadMoreItemsFired) {
          this.$refs.focusDiv?.focus();
          this.loadMoreItemsFired = true;
        }
      },
    },
    methods: {
      loadMoreItems () {
        if (!this.isButtonDisabled) {
          this.loadMoreItemsFired = true;
          this.$emit('load-more-items');
        }
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
.moreItemWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  .spinner {
    color: $color-text-primary;
  }
}
</style>
