const analyticsClasses = {
  search: {
    input: 'searchInput',
    multipleSearchButton: 'multipleSearchButton',
    searchIconButton: 'searchIconButton',
    closeIconButton: 'closeIconButton',
    barCodeIconButton: 'barCodeIconButton',
    popupWrapper: 'searchPopupWrapper',
    suggestionsWrapper: 'searchSuggestionsWrapper',
    categoriesWrapper: 'searchCategoriesWrapper',
    informationsWrapper: 'searchInformationsWrapper',
    productsWrapper: 'searchProductsWrapper',
    popupAddToCartButtons: 'searchPopupAddToCartButtons',
  },
  cartButton: 'cartButton',
  timeSlotReservationPopup: 'timeSlotReservationPopup',
  carouselMainContainer: 'carouselMainContainer',
  addToCart: {
    recommendedProduct: 'addToCartRecommendedProduct',
    category: 'addToCartCategory',
    boutique: 'addToCartBoutique',
  },
  minicart: {
    wrapper: 'minicartWrapper',
    productQuantityWrapper: 'minicartProductQuantityWrapper',
    productDeleteButton: 'minicartProductDeleteButton',
  },
  storeSelectorStep: {
    DELIVERY_METHOD: 'deliveryMethodAndTimeframeSelectorStep1',
    DELIVERY_POSTCODE: 'deliveryMethodAndTimeframeSelectorStep2',
    DELIVERY_TIMEFRAME: 'deliveryMethodAndTimeframeSelectorStep3',
  },
  checkoutStep: {
    1: {
      confirmButton: 'checkoutStep1ConfirmButton',
    },
    2: {
      confirmButton: 'checkoutStep2ConfirmButton',
    },
    3: {
      confirmButton: 'checkoutStep3ConfirmButton',
    },
  },
  notification: {
    error: 'errorNotification',
  },
};

export default analyticsClasses;
