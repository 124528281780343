import { defineNuxtPlugin } from 'nuxt/app';
import { useTranslationStore } from '~~/common/stores/translation';

// export default function ({ app, i18n, $pinia }, inject) {
export default defineNuxtPlugin((nuxtApp) => {
  const { $pinia, $i18n } = nuxtApp;

  const translationStore = useTranslationStore($pinia);
  /*
   * Frontend implementation of InlineDefinedPluralizer
   */
  const pluralize = (text, placeholders) => {
    return text.replace(/~#%(\w+)%([^~]+)~~/g, (_match, placeholderIndex, groups) => {
      const placeholderValue = placeholders[!isNaN(placeholderIndex) ? (placeholderIndex - 1) : placeholderIndex];

      const matches = groups.matchAll(/([{[\]](\d+|\*)(,?\d+|\*)*[}[\]])([^|{[\]]+)\|?/g);
      let defaultMatch = null;
      for (const match of matches) {
        defaultMatch = defaultMatch || match[4];
        if (matchesInterval(match[1], placeholderValue)) {
          return match[4];
        }
      }
      return defaultMatch;
    });
  };

  const matchesInterval = (interval, value) => {
    if (interval.match(/^{.*/)) {
      const values = interval.replace(/[{}]/g, '').split(',');
      if (values.length === 1 && values[0] === '*') {
        return true;
      }
      return values.includes('' + value);
    }

    const matches = interval.match(/([[\]])([^[\]]+)([[\]])/);
    if (matches.length !== 4) {
      return false;
    }
    const ld = matches[1];
    const rd = matches[3];
    const values = matches[2].split(',');
    if (values.length !== 2 || values[0] > values[1]) {
      return false;
    }

    let contains = true;

    if (values[0] !== '*') {
      contains = (ld === '[') ? (value >= values[0]) : (value > values[0]);
    }

    if (values[1] !== '*' && contains) {
      contains = (rd === ']') ? (value <= values[1]) : (value < values[1]);
    }

    return contains;
  };
  nuxtApp.provide('awt', (key, values) => {
    // NOTE: getting i18n.messages would result in deepcopy (perf problems)
    // also this the lib author also uses _Messges
    let translation = translationStore[$i18n.locale.value]?.[key];

    if (typeof translation === 'object' && translation !== null) {
      translation = translation.text;
    }

    if (translation == null) {
      translation = `UNREG: ${key}`;
    } else if (translation === '') {
      translation = `FIXME: ${key}`;
    }

    let pluralized = pluralize(translation, values);

    if (values) {
      Object.entries(values).forEach(([key, value]) => {
        if (!isNaN(parseInt(key))) {
          key = parseInt(key) + 1;
        }
        pluralized = pluralized.replace('%' + (key) + '%', value);
        pluralized = pluralized.replace('{' + (key) + '}', value);
      });
    }
    return pluralized;
  });
});
