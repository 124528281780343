<template>
  <div :id="null" :class="[$style.select, {[$style.shrink]: shrink}, {[$style.dropdownSideTop]: dropdownSide === 'top'}]">
    <template v-if="multiple">
      <span :id="idRemoveBtnDesc" style="display: none" v-text="$awt('aw.common.aw_select.btn_unselect_option')" />
      <ul :id="idRemoveBtnList" :class="['ulReset', $style.selectedOptions]">
        <li v-for="item in getOptions.filter(o => o.optionSelected)" :key="item[optionIdProperty]">
          <button
            type="button"
            :disabled="disabled || item.disabled"
            :aria-describedby="idRemoveBtnDesc"
            :class="[$style.selectedOptionsButton, $style[inputSize], { [$style.selectedOptionsButtonDisabled]: disabled || item.disabled }]"
            @click="toggleItem(item)"
          >
            <span v-text="getItemText(item)" />
            <lv-icon name="times-small-16" :size="16" />
          </button>
        </li>
      </ul>
    </template>
    <span class="awSrOnlyAbs">
      <span v-if="inlineLabelText" :id="idInlineLabelText" v-text="inlineLabelText" />
      <span v-if="!multiple" :id="idSelectedValueLabelText" v-text="(isAnyValueSelected && getSelectedOption !== inlineLabelText) ? getSelectedOption : ''" />
    </span>
    <div
      :id="comboboxId"
      :class="[
        $style[inputSize],
        $style.selectInput,
        {
          [$style.disabled]: disabled,
          [$style.active]: isOpened,
        }
      ]"
      role="combobox"
      tabindex="0"
      :aria-describedby="rowUniqueId || null"
      :aria-labelledby="[
        outerLabelId ? outerLabelId : '',
        inlineLabelText ? idInlineLabelText : '',
        multiple ? idRemoveBtnList : idSelectedValueLabelText,
      ].filter(s=>s).join(' ')"
      :aria-disabled="disabled"
      :aria-expanded="isOpened.toString()"
      :aria-controls="`aw_listbox_${uuid}`"
      aria-autocomplete="none"
      aria-haspopup="listbox"
      :aria-activedescendant="isOpened ? (visualFocusId === null ? '' : generateListboxId(visualFocusId)) : null"
      @click.stop="toggleOpenState({ limit: true })"
      @keydown="onKeyDown"
    >
      <span aria-hidden="true" :class="[$style.inputText, {[$style.titleLabel]: isTitle(localValue)}]" v-text="(isAnyValueSelected ? getSelectedOption : inlineLabelText) || '\xA0'" />
      <lv-icon v-if="dropdownIconAttrs.name" v-bind="dropdownIconAttrs" :class="[$style.chevronIcon, {[$style.active]: isOpened}]" />
    </div>
    <ul
      v-show="isOpened"
      :id="`aw_listbox_${uuid}`"
      role="listbox"
      :class="[$style[inputSize], $style.selectList]"
    >
      <template v-if="isOpened">
        <li
          v-for="item in getOptions"
          :id="generateListboxId(item[optionIdProperty])"
          :key="item[optionIdProperty]"
          :class="[
            'hideBasketOutsideClick',
            $style.selectListItem,
            {
              [$style.disabled]: item.disabled,
              [$style.active]: item.optionSelected,
              [$style.visualFocus]: item[optionIdProperty] === visualFocusId,
            },
          ]"
          role="option"
          :aria-disabled="item.disabled"
          :aria-selected="item.optionSelected.toString()"
          @click="toggleItem(item)"
          @keydown="onKeyDown"
        >
          <span :class="['hideBasketOutsideClick', $style.itemText, {[$style.titleLabel]: isTitle(item)}]" v-text="`${getItemText(item)}` || '\xA0'" />
          <lv-icon
            v-if="item.disabled"
            inherit-color
            name="stop-24"
            :size="25"
            :class="['hideBasketOutsideClick', $style.checkIcon]"
          />
          <lv-icon
            v-if="item.optionSelected"
            inherit-color
            name="check-24"
            :size="25"
            :class="['hideBasketOutsideClick', $style.checkIcon]"
          />
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
  import { nextTick } from 'vue';
  import { LvIcon } from '~~/common/components/loginet-vue-shop/index.mjs';
  import { uuid4 } from '~~/common/utils';
  import { isOptEq, getPropOrObject, isEventIgnorable, isEventAutoOpens, isEventSearchAble, searchFunction, getNewIdByEventKey, getToggleAbleItem } from '~~/common/utils/select.js';

  export default {
    name: 'AwSelect',
    components: { LvIcon },
    props: {
      rowUniqueId: {
        type: String,
        default: '',
      },
      outerLabelId: {
        type: String,
        default: '',
      },
      inlineLabelText: {
        type: String,
        default: '',
      },
      /**
       * @param {Array<{id: Number, name: String, disabled: Boolean}>} options
       */
      options: {
        type: Array,
        default: () => [],
      },
      /**
       * @param {(Array<{id: Number, name: String, disabled: Boolean}>, String, Number)} selectedOptions
       * if multiple is true selectedOptions have to be an array
       */
      selectedOptions: {
        type: [Object, Array, String, Number],
        required: true,
      },
      widgetAttrs: {
        type: Object,
        default () {
          return {};
        },
      },
      /**
       * @param {{name: String, size: Number}} dropdownIconAttrs
       */
      dropdownIconAttrs: {
        type: Object,
        default: Object,
      },
      /**
       * @param String size
       */
      inputSize: {
        type: String,
        default: 'lg',
      },
      /**
       * @param Boolean shrink
       */
      shrink: {
        type: Boolean,
        default: false,
      },
      dropdownSide: {
        type: String,
        default: 'bottom',
        validator: s => ['bottom', 'top'].includes(s),
      },
      /**
       * @param Boolean disabled
       */
      disabled: {
        type: Boolean,
        default: false,
      },
      /**
       * @param Boolean multiple
       */
      multiple: {
        type: Boolean,
        default: true,
      },
      optionLabelProperty: {
        type: String,
        default: 'name',
      },
      optionIdProperty: {
        type: String,
        default: 'id',
      },
    },
    emits: [
      'update:selectedOptions',
      'blur',
      'open',
      'close',
    ],
    data () {
      return {
        uuid: null,
        isOpened: false,
        localValue: this.selectedOptions,
        visualFocusId: null,
        search: {
          idThrottleSearch: null,
          searchString: '',
        },
        idDebounceToggle: null,
        toggleLimit: 0,
      };
    },
    computed: {
      comboboxId () {
        return ((this.widgetAttrs && 'id' in this.widgetAttrs) ? this.widgetAttrs.id : this.$attrs.id) || null;
      },
      idInlineLabelText () {
        return `aw_${this.uuid}_inline_label_text`;
      },
      idSelectedValueLabelText () {
        return `aw_${this.uuid}_selected_value_label_text`;
      },
      idRemoveBtnList () {
        return `aw_${this.uuid}_remove_btns`;
      },
      idRemoveBtnDesc () {
        return `aw_${this.uuid}_remove_btn`;
      },
      isAnyValueSelected () {
        return (Array.isArray(this.localValue) ? this.localValue[0] : this.localValue) !== '';
      },
      getSelectedOption () {
        return ([this.localValue || `${this.localValue}`]
          .flat()
          .map((item) => {
            return this.getItemText(item);
          })
          .join(', ')
        );
      },
      getOptions () {
        return this.options.map(option => ({
          originalOption: option,
          [this.optionIdProperty]: option,
          [this.optionLabelProperty]: option,
          disabled: false,
          optionSelected: [this.localValue].flat().some((selOpt) => {
            return isOptEq(selOpt, option, this.optionIdProperty);
          }),
          ...option,
        }));
      },
    },
    watch: {
      selectedOptions (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.localValue = newVal;
        }
      },
      visualFocusId: function (newVal) {
        if (newVal) {
          nextTick(() => {
            const e = document.getElementById(this.generateListboxId(newVal));
            if (e) {
              e.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
              });
            }
          });
        }
      },
    },
    mounted () {
      this.uuid = uuid4();
    },
    beforeUnmount () {
      document.removeEventListener('click', this.hide);
      document.removeEventListener('keyup', this.hide);
      if (this.isOpened) {
        this.isOpened = false;
      }
    },
    methods: {
      generateListboxId (id) {
        return `aw_listbox_${this.uuid}_${id}`;
      },
      onKeyDown ($event) {
        if (isEventIgnorable($event)) {
          return;
        }

        const isOriginallyOpened = this.isOpened;
        const isSearchAble = isEventSearchAble($event);

        if (!this.isOpened && isEventAutoOpens($event)) {
          this.toggleOpenState();
        }

        if (isSearchAble) {
          const searchOutput = searchFunction({
            $event,
            search: this.search,
            searchLabel: this.optionLabelProperty,
            searchId: this.optionIdProperty,
            searchOptions: this.getOptions,
            currentVisualFocusId: this.visualFocusId,
          });
          if (searchOutput) {
            this.visualFocusId = searchOutput.newVisualFocusId;
          }
        }

        const newId = getNewIdByEventKey({
          $event,
          currentVisualFocusId: this.visualFocusId,
          idKey: this.optionIdProperty,
          options: this.getOptions,
        });
        if (newId) {
          this.visualFocusId = newId.id;
        }

        if (isOriginallyOpened) {
          const item = getToggleAbleItem({
            $event,
            isSingle: !this.multiple,
            currentVisualFocusId: this.visualFocusId,
            idKey: this.optionIdProperty,
            options: this.getOptions,
          });
          if (item) {
            this.toggleItem(item);
          }
          if ($event.key === 'Tab' && this.isOpened) {
            this.toggleOpenState();
          }
        }

        if (['ArrowUp', 'ArrowDown', 'Home', 'End', 'PageUp', 'PageDown', ' '].includes($event.key)) {
          $event.stopPropagation();
          $event.preventDefault();
        }
      },
      isTitle (item) {
        return Boolean(item?.title);
      },
      toggleOpenState ({ limit = false } = { limit: false }) {
        if (this.disabled) {
          return;
        }
        if (limit) {
          this.toggleLimit++;
          if (this.toggleLimit > 1) {
            return;
          }
          this.idDebounceToggle = setTimeout(() => {
            // too fast click assumes this is a dbclick
            this.toggleLimit = 0;
          }, 400);
        } else {
          clearTimeout(this.idDebounceToggle);
          this.toggleLimit = 0;
        }
        this.isOpened = !this.isOpened;
        document.removeEventListener('click', this.hide);
        document.removeEventListener('keyup', this.hide);
        if (this.isOpened) {
          nextTick(() => {
            document.addEventListener('click', this.hide);
            document.addEventListener('keyup', this.hide);
          });
        } else {
          this.$emit('blur');
        }
        this.$emit(this.isOpened ? 'open' : 'close');
      },
      hide (evt) {
        if (evt.type === 'click' || (evt.type === 'keyup' && evt.key === 'Escape')) {
          document.removeEventListener('click', this.hide);
          document.removeEventListener('keyup', this.hide);
          this.$emit('blur');
          this.isOpened = false;
        }
      },

      toggleItem (item) {
        if (item.disabled) {
          return;
        }
        if (this.multiple) {
          if (this.localValue.some(selectedItem => selectedItem[this.optionIdProperty] === item[this.optionIdProperty])) {
            this.localValue = this.localValue.filter(selectedItem => selectedItem[this.optionIdProperty] !== item[this.optionIdProperty]);
          } else {
            this.localValue.push(item);
          }
        } else {
          this.localValue = this.localValue === item[this.optionLabelProperty] ? '' : item;
          this.toggleOpenState();
        }
        const originalOptions = Array.isArray(this.localValue) ? this.localValue.map(option => getPropOrObject(option, 'originalOption')) : getPropOrObject(this.localValue, 'originalOption');
        this.$emit('update:selectedOptions', originalOptions);
      },
      getItemText (item) {
        return getPropOrObject(item, this.optionLabelProperty);
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
$aw-select-sizes: (
  "md": (
    "padding": 6px,
    "font-size": 12px,
    "line-height": 16px,
    "icon-size": 16px,
  ),
  "md-thin": (
    "padding": 11px 15px,
    "font-size": 13px,
    "line-height": 16px,
    "icon-size": 16px,
  ),
  "lg": (
    "padding": 15px,
    "font-size": 16px,
    "line-height": 24px,
    "icon-size": 24px,
  ),
  "lg-wide": (
    "padding": 15px 5px 15px 8px,
    "font-size": 16px,
    "line-height": 24px,
    "icon-size": 24px,
  ),
);

.selectedOptions {
  display: flex;
  flex-wrap: wrap;

  &Button {
    font-weight: bold;
    display: flex;
    align-items: center;
    margin: 2px;
    padding: 2px;
    color: $color-white;
    border-radius: 4px;
    background: $color-brand-secondary;

    &:not(&Disabled):is(:hover, :focus, :active) {
      background: $color-brand-secondary-active;
    }

    &Disabled {
      cursor: not-allowed;
      background: $color-alto;
    }
  }
}

.select {
  position: relative;

  &.shrink {
    display: inline-block;
  }

  .selectInput {
    font-weight: $font-weight-bold;
    display: flex;
    justify-content: space-between;
    width: 100%;
    color: $color-mine-shaft;
    border: 1px solid $color-silver-chalice;
    border-radius: 8px;
    background-color: $color-white;

    &:hover {
      background-color: $color-alto;
    }

    &.disabled {
      opacity: .6;
      border-color: $color-silver-chalice;
      background-color: $color-seashell !important;

      .chevronIcon, .inputText {
        color: $color-silver-chalice;
      }
    }

    &.active {
      border: 1px solid $color-green-haze;
      border-radius: 8px 8px 0 0;
      background-color: $color-green-haze;

      .inputText {
        color: $color-white;
      }

      .chevronIcon {
        transform: rotate(180deg);
        color: $color-white;
      }
    }

    .inputText {
      overflow: hidden;
      white-space: nowrap;

      &.titleLabel {
        font-weight: $font-weight-regular;
        color: $color-silver-chalice;
      }
    }

    .chevronIcon {
      flex-shrink: 0;
      color: $color-black;
    }
  }

  .selectList {
    position: absolute;
    z-index: 1000;
    right: 0;
    left: 0;
    overflow-y: auto;
    max-height: 310px;
    margin: 0;
    padding: 0;
    list-style-type: none;
    color: $color-mine-shaft;
    border: 1px solid $color-silver-chalice;
    border-radius: 0 0 8px 8px;
    background-color: $color-white;

    &::-webkit-scrollbar {
      border-radius: 0 0 8px;
      background-color: $color-alto;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 0 0 8px;
    }

    .selectListItem {
      position: relative;
      display: flex;
      justify-content: space-between;
      min-width: 100%;
      text-align: left;
      color: $color-mine-shaft;
      border: 0;
      background-color: $color-white;

      &:first-child {
        margin-top: 2px;
      }

      &:not(:last-child) {
        margin-bottom: 2px;
      }

      &:hover,
      &.visualFocus {
        background-color: $color-alto;
        box-shadow: inset -2px 0 0 2px $color-white, inset 4px 4px 0 0 $color-brand-secondary, inset -6px -4px 0 0 $color-brand-secondary;

        &:last-child {
          box-shadow: inset -2px 2px 0 2px $color-white, inset 4px 6px 0 0 $color-brand-secondary, inset -6px -2px 0 0 $color-brand-secondary;
        }

        &.active {
          background-color: $color-brand-secondary-active;
        }
      }

      &.active {
        color: $color-white;
        border-right: 0;
        border-left: 0;
        background-color: $color-brand-secondary;

        &:last-child {
          border-bottom: 1px solid $color-brand-secondary;
        }

        .itemText {
          overflow: hidden;
          text-align: left;
          /* white-space: nowrap; */
          text-overflow: clip;
        }
      }

      &.disabled {
        cursor: not-allowed;
        color: $color-shuttle-gray;
        background-color: $color-seashell;
      }

      .itemText {
        font-weight: 400;
        text-align: left;

        &.titleLabel {
          color: $color-silver-chalice
        }
      }

      .checkIcon {
        align-self: flex-end;
        flex-shrink: 0;
      }
    }
  }

  @each $aw-select-size-name, $aw-select-size in $aw-select-sizes {
    .selectedOptionsButton,
    .selectInput {
      &.#{$aw-select-size-name} {
        font-size: map-get($aw-select-size, "font-size");
      }
    }

    .selectInput.#{$aw-select-size-name} {
      font-size: map-get($aw-select-size, "font-size");
      line-height: map-get($aw-select-size, "line-height");
      padding: map-get($aw-select-size, "padding");

      .chevronIcon {
        font-size: map-get($aw-select-size, "icon-size");
      }
    }

    .selectList.#{$aw-select-size-name} {
      .selectListItem {
        padding: map-get($aw-select-size, "padding");

        .itemText {
          font-size: map-get($aw-select-size, "font-size");
          line-height: map-get($aw-select-size, "line-height");
        }

        .checkIcon {
          font-size: map-get($aw-select-size, "icon-size");
        }
      }
    }
  }

  &.dropdownSideTop {
    .selectInput.active {
      border-radius: 0 0 8px 8px;
    }

    .selectList {
      transform: translateY(calc(-100% - 56px));
      border-radius: 8px 8px 0 0;
    }
  }
}
</style>
