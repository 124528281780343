import validate from "/builds/auchan-webshop/auchan-vue-client/node_modules/nuxt/dist/pages/runtime/validate.js";
import aa_45global_45middleware_45global from "/builds/auchan-webshop/auchan-vue-client/shop/middleware/aa_globalMiddleware.global.js";
import authenticated_45global from "/builds/auchan-webshop/auchan-vue-client/shop/middleware/authenticated.global.js";
import category_45tree_45middleware_45global from "/builds/auchan-webshop/auchan-vue-client/shop/middleware/categoryTreeMiddleware.global.js";
import route_45history_45and_45product_45filters_45reset_45global from "/builds/auchan-webshop/auchan-vue-client/shop/middleware/routeHistoryAndProductFiltersReset.global.js";
import manifest_45route_45rule from "/builds/auchan-webshop/auchan-vue-client/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  aa_45global_45middleware_45global,
  authenticated_45global,
  category_45tree_45middleware_45global,
  route_45history_45and_45product_45filters_45reset_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}