import { defineNuxtPlugin } from 'nuxt/app';

const defaultDateFormat = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour12: false,
  hour: 'numeric',
  minute: 'numeric',
};

export default defineNuxtPlugin(function (nuxtApp) {
  const { $i18n, $logger } = nuxtApp;

  nuxtApp.provide('date', (entity, localOptions, { removeLastDot, removeSpaces, removeAllDot } = {}) => {
    const options = localOptions || import(`../utils/locales/date_formats/${$i18n.locale.value}.json`) || defaultDateFormat;

    try {
      let d = new Date(entity).toLocaleString($i18n.locale.value, options);
      if (removeLastDot) {
        d = d.replace(/\.$/, '');
      }
      if (removeSpaces) {
        d = d.replace(/\s/g, '');
      }
      if (removeAllDot) {
        d = d.replace(/\./g, '');
      }
      return d;
    } catch (error) {
      return entity;
    }
  });

  /**
   * format: YYYY-MM-DD
   * @description this is just the standard toISOString splitted to only
   * include the date and timezoneoffset is recalculated.
   * */
  nuxtApp.provide('dateISO8601', (d) => {
    if (!(d instanceof Date)) {
      $logger.error(TypeError('dateRangeOfDays expexts an array of Date objects'));
      return;
    }
    const o = d.getTimezoneOffset();
    return (new Date(d.getTime() - (o * 60 * 1000))).toISOString().split('T')[0];
  });

  /**
   * Loops through all the days between the max and min of `dateArray`.
   */
  nuxtApp.provide('dateRangeOfDays', function* (dateArray = [], ignoreTime = true) {
    for (const d of dateArray) {
      if (!(d instanceof Date)) {
        $logger.error(TypeError('dateRangeOfDays expexts an array of Date objects'));
        return;
      }
    }
    const loop = new Date(Math.min(...dateArray));
    const to = new Date(Math.max(...dateArray));
    if (ignoreTime) {
      loop.setHours(0);
      loop.setMinutes(0);
      loop.setSeconds(0);
      loop.setMilliseconds(0);
      to.setHours(0);
      to.setMinutes(0);
      to.setSeconds(0);
      to.setMilliseconds(0);
    }
    while (loop <= to) {
      yield (new Date(loop));
      loop.setDate(loop.getDate() + 1);
    }
  });
});
