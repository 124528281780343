import { stringify } from 'qs';
import axios from 'axios';
import { useNuxtApp, useRuntimeConfig } from 'nuxt/app';
import { caseInsensitiveKeyReplace } from '~~/common/utils/headerHelpers.js';

export const createMsgTokenNetwork = (proxiedHeaders) => {
  const { $i18n, $awRuntimeConfig } = useNuxtApp();
  return extendNetwork(axios.create({
    baseURL: $awRuntimeConfig.messageTokenURL,
    headers: caseInsensitiveKeyReplace(
      proxiedHeaders,
      {
        'Accept-Language': $i18n.locale.value,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    ),
    responseType: 'json',
    crossdomain: true,
    rejectUnauthorized: false,
    paramsSerializer: params => stringify(params, {
      arrayFormat: 'brackets',
    }),
    validateStatus (status) {
      return status < 400;
    },
  }));
};

export const createAuthenticationNetwork = (proxiedHeaders) => {
  const { $i18n, $awRuntimeConfig } = useNuxtApp();
  return extendNetwork(axios.create({
    baseURL: $awRuntimeConfig.authenticationNetworkBaseURL,
    headers: caseInsensitiveKeyReplace(
      proxiedHeaders,
      {
        'Accept-Language': $i18n.locale.value,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    ),
    responseType: 'json',
    crossdomain: true,
    rejectUnauthorized: false,
    paramsSerializer: params => stringify(params, {
      arrayFormat: 'brackets',
    }),
    validateStatus (status) {
      return status < 400;
    },
  }));
};

export const createAuthorizationNetwork = (proxiedHeaders) => {
  const { $i18n, $awMergedConfig } = useNuxtApp();
  const config = useRuntimeConfig();

  const authorizationURL = config[`auchanAuthorizationHost${config.public.isShop ? 'Shop' : 'Dealer'}`];
  return extendNetwork(axios.create({
    baseURL: `${authorizationURL}${$awMergedConfig.auchanAuthorizationPrefix}`,
    headers: caseInsensitiveKeyReplace(
      proxiedHeaders,
      {
        'Accept-Language': $i18n.locale.value,
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
      },
    ),
    responseType: 'json',
    crossdomain: true,
    rejectUnauthorized: false,
    paramsSerializer: params => stringify(params, {
      arrayFormat: 'brackets',
    }),
    validateStatus (status) {
      return status < 400;
    },
  }));
};

export const createApiNetwork = (proxiedHeaders) => {
  const { $i18n, $awRuntimeConfig } = useNuxtApp();
  return extendNetwork(axios.create({
    baseURL: $awRuntimeConfig.apiNetworkBaseURL,
    headers: caseInsensitiveKeyReplace(
      proxiedHeaders,
      {
        'Accept-Language': $i18n.locale.value,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    ),
    responseType: 'json',
    crossdomain: true,
    rejectUnauthorized: false,
    paramsSerializer: params => stringify(params, {
      arrayFormat: 'brackets',
    }),
    validateStatus (status) {
      return status < 400;
    },
  }));
};

function extendNetwork (network) {
  const axiosExtra = {
    setHeader (name, value) {
      if (!value) {
        delete this.defaults.headers[name];
      }
      this.defaults.headers[name] = value;
    },
  };
  for (const method of ['request', 'delete', 'get', 'head', 'options', 'post', 'put', 'patch']) {
    axiosExtra['$' + method] = function () {
      return this[method].apply(this, arguments).then(res => res && res.data);
    };
  }

  for (const key in axiosExtra) {
    network[key] = axiosExtra[key].bind(network);
  }

  return network;
}
