import { defineStore } from 'pinia';
import { useNuxtApp, useRuntimeConfig } from 'nuxt/app';
import { ref } from 'vue';
import { useUserInterfaceStore } from './userInterface';
import { setErrorNotification } from '~~/common/utils/helper';
import ObjectCache from '~~/common/utils/ObjectCache.js';
import globalMiddlewareHeaders from '~~/common/server/globalMiddlewareHeaders.js';

const configCache = new ObjectCache('config', '__');
export const useConfigStore = defineStore('config', () => {
  const nuxtApp = useNuxtApp();
  const config = useRuntimeConfig();

  const configCommon = ref(null);
  const configDealer = ref(null);
  const isSalesForceLogin = ref(null);

  async function fetchConfigCommon () {
    const { $i18n, $api, $logger, $awRuntimeConfig } = nuxtApp;
    try {
      const cacheKey = ['config', 'shop', $i18n.locale.value];
      configCache.initCache(cacheKey, async () => {
        const configResponse = await $api.$get('/config');
        try {
          if (configResponse && typeof configResponse === 'object') {
            if (import.meta.server) {
              globalMiddlewareHeaders['Content-Security-Policy'] = (
                config.public.isShop &&
                  Array.isArray(configResponse.csp) &&
                  configResponse.csp.length &&
                  configResponse.csp.every(v => typeof v?.url === 'string')
              )
                ? `default-src 'self' 'unsafe-inline' 'unsafe-eval' data: ${configResponse.csp.map(v => v.url.trim()).join(' ')}`
                : null
              ;
            }
            if ('csp' in configResponse) {
              delete configResponse.csp;
            }
          }
        } catch (err) {
          $logger.error(err);
        }
        return configResponse;
      });
      // if we don't copy the 'delete result.validators' line will BREAK the validators set
      const result = { ...(await configCache.getCache(cacheKey).value) };
      useUserInterfaceStore().setValidators(result?.validators);
      delete result.validators;
      configCommon.value = result;

      const KEY_IS_SALESFORCE_LOGIN_ENABLED = 'salesforce_login_enabled';
      isSalesForceLogin.value = (config.public.auchanIsCustomerDiamondApiControlEnabled &&
          KEY_IS_SALESFORCE_LOGIN_ENABLED in (result.persistentConfig || {})
      )
        ? result.persistentConfig[KEY_IS_SALESFORCE_LOGIN_ENABLED]
        : $awRuntimeConfig.authMode === 'CD';
    } catch (error) {
      $logger.error('error', error);
      setErrorNotification({ nuxtApp, error, manualClose: true });
    }
  }

  async function fetchConfigDealer () {
    const { $i18n, $api, $logger } = nuxtApp;
    try {
      const cacheKey = ['config', 'dealer', $i18n.locale.value];
      configCache.initCache(cacheKey, () => $api.$get('config/dealer'));
      configDealer.value = await configCache.getCache(cacheKey).value;
    } catch (error) {
      $logger.error(error);
      setErrorNotification({ nuxtApp, error, manualClose: true });
    }
  }

  return {
    configCommon,
    configDealer,
    isSalesForceLogin,
    fetchConfigCommon,
    fetchConfigDealer,
  };
});
