<template>
  <div :class="$style.componentWrapper">
    <div :class="$style.text">
      <lv-icon
        name="auchan-logo"
        :size="32"
        fill
        :class="$style.auchanLogo"
      />
      <lv-heading
        level="3"
        tag="h2"
        :class="$style.title"
        version2
      >
        {{ $awt('aw.home.mobile_store.title') }}
      </lv-heading>
    </div>
    <div :class="$style.links">
      <lv-button
        v-for="(linkData, i) in linkDatas"
        :key="linkData.key"
        styling="reset"
        v-bind="linkData.hyperlinkBind"
        v-on="bannerEvtListeners[i]"
      >
        <img v-bind="linkData.imgBind" loading="lazy" :class="$style.picture">
      </lv-button>
    </div>
  </div>
</template>

<script>
  import { LvHeading, LvButton, LvIcon } from '~~/common/components/loginet-vue-shop/index.mjs';

  import appStoreBadge from '~~/common/assets/images/app_store_badge.svg';
  import googlePlayBadge from '~~/common/assets/images/google_play_badge.svg';
  import analyticsBannerInteractionMixin from '~~/common/mixins/analyticsBannerInteractionMixin.js';

  export default {
    name: 'AwMobileApplicationBlock',
    components: {
      LvHeading,
      LvButton,
      LvIcon,
    },
    mixins: [analyticsBannerInteractionMixin],
    inheritAttrs: false,
    computed: {
      bannerEvtListeners () {
        const titlePrefix = this.$awt('aw.home.mobile_store.title');
        return this.linkDatas.map(linkData => this.genBannerInteractionClickEventHandlers({ title: `${titlePrefix} ${linkData.applicationType}` }));
      },
      linkDatas () {
        return [
          {
            url: this.$awt('aw.home.mobile_store.android_link'),
            src: googlePlayBadge,
            applicationType: 'Google Play',
            alt: this.$awt('aw.common.mobile_app_stores.play_store'),
          },
          {
            url: this.$awt('aw.home.mobile_store.ios_link'),
            src: appStoreBadge,
            applicationType: 'App Store',
            alt: this.$awt('aw.common.mobile_app_stores.app_store'),
          },
        ].map(({
          url,
          src,
          applicationType,
          alt,
        }) => {
          return {
            key: url,
            imgBind: {
              src,
              alt,
            },
            hyperlinkBind: {
              ...this.$hyperlinkComputeBindFromUrl(url),
              'aria-label': alt,
            },
            applicationType,
          };
        });
      },
    },
    mounted () {
      this.sendBannerViewInteraction();
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
.componentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px;
  word-break: break-word;
  hyphens: auto;
  border-radius: 24px;
  background-color: $color-background-3;

  @include tablet(only) {
    gap: 40px;
  }

  @include tablet(min) {
    align-items: center;
    flex-direction: row;
  }

  .text {
    display: grid;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
    grid-template-columns: auto auto;
    gap: 24px;

    @include tablet(min) {
      margin-bottom: 0;
    }
  }

  .auchanLogo {
    width: 32px;
    height: 32px;

    @include tablet(min) {
      width: 40px;
      height: 36px;
    }
  }

  .title {
    font-family: $secondary-font;
    color: $color-text-primary;
  }

  .links {
    display: grid;
    grid-template-columns: 107px 95px;
    gap: 24px;
    justify-content: center;

    @include mobile(min) {
      grid-template-columns: 135px 117px;
    }

    @include tablet(only) {
      grid-template-columns: 1fr;
    }

    @include tablet(min) {
      justify-content: flex-start;
    }

    a {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: max-content;
    }

    .picture {
      height: 32px;
      @include mobile(min) {
        height: 40px;
      }
    }
  }

}
</style>
