<template>
  <label
    :class="[
      $style.mainContainer,
      $style[`inputWidth${inputWidth}`],
      {
        [$style.mainContainerDisabled]: disabled,
        [$style.mainContainerChecked]: checked,
        [$style.mainContainerCenter]: center,
        [$style.mainContainerBreakpointIsTablet]: breakpoint === 'tablet',
      },
    ]"
    :for="radioId"
    @mouseup="$emit('radio-mouseup', value)"
  >
    <input
      v-bind="awAttrsNoStyleNoClassNoListeners($attrs)"
      :id="radioId"
      type="radio"
      :name="radioName"
      :disabled="disabled"
      :checked="checked"
      @change="radioGroupModelValue.changeRadioGroupValueTo(value)"
      @blur="$emit('blur', $event)"
      @keydown.enter="event => $emit('enter', event)"
    >
    <span
      :class="[
        $style.labelText,
        $style[labelSize],
        {
          [$style.labelTextDisabled]: disabled,
        },
      ]"
    >
      {{ label || '' }}
      <slot name="label" />
    </span>
    <aw-spinner v-if="isLoading" width-with-unit="24px" height-with-unit="24px" />
    <lv-icon
      v-else
      :size="24"
      name="check-version2-16"
      :class="[$style.icon, {[$style.iconChecked]: checked}]"
    />
  </label>
</template>

<script setup>
  import { inject, defineAsyncComponent } from 'vue';
  import { useId } from 'nuxt/app';
  import { LvIcon } from '~~/common/components/loginet-vue-shop/index.mjs';
  import { awAttrsNoStyleNoClassNoListeners } from '~~/common/mixins/awListenersMixin.js';
  const AwSpinner = defineAsyncComponent(() => import('~~/common/components/Common/AwSpinner.vue'));

  const props = defineProps({
    nameProp: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    labelSize: {
      type: String,
      default: 'lg',
      validator: value => [/*'xs', */'md', 'lg'].includes(value),
    },
    inputWidth: {
      type: String,
      default: 'lg',
      validator: value => ['none', 'md', 'lg'].includes(value),
    },
    value: {
      type: [Boolean, String, Number],
      default: '',
      required: true,
    },
    modelValue: {
      type: [Boolean, String, Number],
      default: '',
    },
    center: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    breakpoint: {
      type: String,
      default: 'tablet',
      validator: value => ['desktop-medium', 'tablet'].includes(value),
    },
  });
  const emits = defineEmits([
    'blur',
    'enter',
    'radio-mouseup',
    'update:model-value',
  ]);
  const radioGroupUuid = (props.nameProp
    ? computed(() => props.nameProp)
    : inject('radioGroupUuid')
  );
  const radioGroupModelValue = (props.nameProp
    ? {
      radioGroupValueComputed: computed(() => props.modelValue),
      changeRadioGroupValueTo: () => emits('update:model-value', props.value),
    }
    : inject('radioGroupModelValue')
  );
  const inputId = useId();
  const radioId = computed(() => {
    return `${radioName.value ? radioName.value + '_' : ''}${inputId}`;
  });
  const radioName = computed(() => {
    return props.nameProp || radioGroupUuid.value || null;
  });
  const checked = computed(() => {
    const radioInputValue = props.value.toString();
    return radioInputValue && radioInputValue === radioGroupModelValue?.radioGroupValueComputed?.value?.toString();
  });
</script>

<style module lang="scss" rel="stylesheet/scss">
@mixin breakpoint () {
  @at-root {
    @include tablet(min) {
      .mainContainer.mainContainerBreakpointIsTablet {
        @content;
      }
    }
    @include desktop-medium(min) {
      .mainContainer:not(.mainContainerBreakpointIsTablet) {
        @content;
      }
    }
  }
}

.mainContainer {
  position: relative;
  display: grid;
  align-items: flex-start;
  justify-content: space-between;
  padding: 11px 11px 11px 15px;
  cursor: pointer;
  border: 1px solid $color-border-disabled;
  border-radius: 16px;
  background-color: $color-background-4;
  box-shadow: 0 2px 8px $color-olive-750--8;
  gap: 8px;
  grid-template-columns: auto 24px;

  @include breakpoint () {
    width: var(--input-width);
    padding: 27px 15px;
    box-shadow: 0 8px 24px $color-olive-750--12;
  }

  &Center {
    align-items: center;
  }

  &.inputWidth {
    &md {
      --input-width: 200px;
    }

    &lg {
      --input-width: 320px;
    }

    &none {
      --input-width: auto;
    }
  }

  &Disabled {
    color: $color-text-secondary !important;
    border: 1px solid $color-border-disabled !important;
    outline: none !important;
    background: $color-background-3 !important;
    box-shadow: none !important;

    &, > * {
      cursor: not-allowed !important;
    }

    .icon {
      opacity: 0 !important;
    }
  }

  &Disabled:is(&Checked) {
    box-shadow: inset 0 0 0 4px rgba($color-background-1, 0.8) !important;
    $p: &;
    @include breakpoint () {
      &#{$p} {
        box-shadow: inset 0 0 0 8px rgba($color-background-1, 0.8) !important;
      }
    }

    .icon {
      opacity: 1 !important;
      color: $color-white !important;
      border-color: $color-border !important;
      background: $color-border !important;
    }
  }

  &:not(&Checked):hover {
    box-shadow: 0 2px 8px $color-olive-750--8;
  }

  &:hover {
    border: 1px solid $color-sage-450;

    .icon {
      color: $color-sage-450;
      border-color: $color-sage-450;
    }
  }

  &:not(&Checked):active {
    box-shadow: none;

    .icon {
      opacity: 0.5;
      color: $color-sage-450;
      background: $color-sage-450;
    }
  }

  &:active {
    border: 1px solid $color-sage-450;
    outline: 1px solid $color-sage-450;
  }

  &Checked {
    border: 1px solid $color-sage-450;

    &:not(:hover) {
      box-shadow: inset 0 0 0 4px $color-sage-450--12;

      $p: &;
      @include breakpoint () {
        &#{$p} {
          box-shadow: inset 0 0 0 8px $color-sage-450--12;
        }
      }
    }

    &:hover {
      border-color: rgba($color-border-success, 0.5);

      .icon {
        color: $color-white;
      }
    }
  }

  input {
    position: absolute;

    &:not(:focus-visible) {
      appearance: none;
      width: 0;
      height: 0;
      opacity: 0.01;
    }
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    color: $color-border;
    border: 1px solid currentColor;
    border-radius: 50%;
    background-color: $color-white;

    &Checked {
      color: $color-white;
      border: 1.5px solid $color-sage-450;
      background-color: $color-sage;
    }
  }

  .labelText {
    font-weight: $font-weight-bold-v2;
    cursor: pointer;
    user-select: none;
    word-break: break-word;

    hyphens: auto;
    color: $color-text-primary;

    // &.xs {
    //   font-size: $font-size-lg;
    //   line-height: 16px;
    // }

    &.md {
      font-size: $font-size-md;
      line-height: 20px;
    }

    &.lg {
      font-size: $font-size-xs;
      line-height: 24px;

    }

    &.labelTextDisabled {
      font-weight: $font-weight-bold-v2;
      color: $color-text-secondary;
    }
  }
}
</style>
