import { onMounted, onBeforeUnmount } from 'vue';
import { useNuxtApp } from 'nuxt/app';
import { FLUSH_VIEW_BANNER } from '~~/common/plugins/aw-analytics.js';

export default function () {
  let flushInterval = null;
  onMounted(() => {
    const {
      $awAnalytics,
      $awMergedConfig: {
        viewPromotionAnalyticsIntervalLength,
      },
    } = useNuxtApp();
    if (!viewPromotionAnalyticsIntervalLength) {
      return;
    }
    flushInterval = setInterval(() => {
      $awAnalytics[FLUSH_VIEW_BANNER]({ isHistoryFlushed: false });
    }, viewPromotionAnalyticsIntervalLength);
  });
  onBeforeUnmount(() => {
    clearInterval(flushInterval);
  });
}
