import { defineStore } from 'pinia';
import { ref } from 'vue';
import { uuid4 } from '~~/common/utils/index.js';

let indexCounter = 0;

export const useToasterStore = defineStore('toaster', () => {
  const toasters = ref({});

  function setToaster (indexCounter, toaster) {
    const uuid = uuid4();
    toasters.value[indexCounter] = {
      ...toaster,
      uuid,
      toasterKey: indexCounter,
    };
  }

  function push ({
    title,
    text,
    manualClose,
    screenReaderOnly,
    showModifyButton,
    modifyButtonCallback,
    promise,
    closeAfter,
    callback,
  }) {
    setToaster(indexCounter, {
      title,
      text,
      manualClose,
      screenReaderOnly,
      showModifyButton,
      modifyButtonCallback,
      promise,
      closeAfter,
      callback,
    });
    return indexCounter++;
  }

  async function remove (key) {
    if (toasters.value[key]?.callback) {
      await toasters.value[key].callback();
      if (toasters.value[key]) {
        toasters.value[key].callback = null; // To prevent double callback call on toast removal.
      }
    }
    delete toasters.value[key];
  }

  return {
    toasters,
    push,
    remove,
  };
});
