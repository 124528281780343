<template>
  <div id="services">
    <lv-heading
      level="2"
      tag="h2"
      version2
      :class="$style.title"
    >
      {{ $awt('aw.pages.stores.services.title') }}
    </lv-heading>
    <div v-if="services.length" :class="$style.content">
      <div :class="$style.servicesContainer">
        <div v-for="(service, i) in services" :key="`service-${i}`" :class="$style.services">
          <lv-image
            v-if="service.image?.url"
            :sources="[{ url: $addWebpPostfixIfSupported({ url: service.image?.url, webpUrl: `${service.image?.url}.webp` }) }]"
            :alt="service.image?.alt"
            :class="$style.servicesImage"
            sizing="cover"
          />
          <div v-else :class="$style.servicesImageFallbackWrapper">
            <img
              :src="fallbackImage"
              alt="Auchan"
              :class="$style.servicesImageFallback"
              loading="lazy"
            >
          </div>
          <span :class="$style.servicesName">{{ service.name }}</span>
        </div>
      </div>
    </div>
    <aw-empty v-else>
      {{ $awt('aw.pages.stores.services.empty') }}
    </aw-empty>
  </div>
</template>

<script>
  import { defineNuxtComponent } from 'nuxt/app';
  import { LvHeading, LvImage } from '~~/common/components/loginet-vue-shop/index.mjs';
  import fallbackImage from '~~/shop/assets/images/stores/fallback.png';
  import { defineAsyncComponent } from 'vue';

  export default defineNuxtComponent(
    {
      name: 'AwServicesList',
      emits: [
        'is-empty',
      ],
      components: {
        LvHeading,
        AwEmpty: defineAsyncComponent(() => import('~~/common/components/Common/AwEmpty')),
        LvImage,
      },
      props: {
        services: {
          type: Array,
          require: true,
          default: () => ([]),
        },
      },
      data () {
        return {
          fallbackImage,
        };
      },
      mounted () {
        this.$emit('is-empty', this.services.length === 0);
      },
    });
</script>

<style module lang="scss" rel="stylesheet/scss">

.title {
  padding-bottom: 20px;

  @include tablet(min) {
    padding-bottom: 40px;
  }
}

.content {
  display: grid;
  width: auto;
  padding-bottom: 20px;

  @include tablet(min) {
    padding-bottom: 40px;
  }
}

.services {
  display: grid;
  justify-content: center;
  min-width: 104px;
  gap: 12px;
  grid-template-columns: 1fr;
  grid-template-rows: min-content min-content;

  @include tablet(min) {
    min-width: unset;
    gap: 4px;
  }

  @include desktop-small(min) {
    gap: 12px;
  }

  &Container {
    display: flex;
    overflow: auto;
    flex-direction: row;
    gap: 8px;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    @include tablet(min) {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      overflow: hidden;
      gap: 20px 8px;
    }

    @include desktop-small(min) {
      gap: 40px 20px;
    }

    @include desktop-medium(min) {
      grid-template-columns: repeat(4, 1fr);
    }

    @media (min-width: 1320px) {
      grid-template-columns: repeat(5, 1fr);
    }

    @media (min-width: 1420px) {
      grid-template-columns: repeat(6, 1fr);
    }
  }

  &Image {
    height: 80px;
    border-radius: 12px;

    &Fallback {
      width: 64px;
      height: 20px;

      &Wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 80px;
        border-radius: 12px;
        background-color: $color-background-3;
      }

    }
  }

  &Name {
    font-size: 12px;
    font-weight: $font-weight-normal-v2;
    line-height: 16px;
    display: block;
    text-align: center;
    hyphens: auto;
    overflow-wrap: break-word;

    @include desktop-small(min) {
      font-size: 14px;
      line-height: 20px;


    }
  }

}

</style>
