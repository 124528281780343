// Pinia Store
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useAnalyticsQueueStore = defineStore('analyticsQueue', () => {
  const queue = ref([]);
  const queueHistory = ref([]);

  const addToQueue = (data) => {
    if (isInHistory(data) || isInQueue(data)) {
      return;
    }
    queue.value.push(data);
  };

  const getQueue = () => {
    return queue.value;
  };

  const clearQueue = () => {
    if (!queue.value.length) {
      return;
    }
    queue.value?.forEach((item) => {
      const elementIdentifier = getItemIdentifier(item);
      // Use hash or something to identify the element
      queueHistory.value.push(elementIdentifier);
    });
    queue.value = [];
  };

  const isInHistory = (data) => {
    const elementIdentifier = getItemIdentifier(data);
    return queueHistory.value.includes(elementIdentifier);
  };

  const isInQueue = (data) => {
    const elementIdentifier = getItemIdentifier(data);
    return queue.value.some(item => getItemIdentifier(item) === elementIdentifier);
  };

  const clearQueueHistory = () => {
    queueHistory.value = [];
  };

  const getItemIdentifier = (data) => {
    return JSON.stringify(data);
  };

  return {
    queue,
    addToQueue,
    getQueue,
    clearQueueHistory,
    clearQueue,
  };
});
