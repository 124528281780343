import { defineNuxtRouteMiddleware, navigateTo, useNuxtApp } from 'nuxt/app';
import * as checkoutTypes from '~~/common/config/CheckoutTypes.js';
import { getLocaleCodeForUrl } from '~~/common/utils/localeCode';
import { useNotificationStore } from '~~/common/stores/notification';
import { useUserStore } from '~~/common/stores/user';

export default defineNuxtRouteMiddleware((to) => {
  const {
    $awt,
    $i18n,
  } = useNuxtApp();

  const notificationStore = useNotificationStore();
  if (isRedirectRequired(to)) {
    // push an error notification
    notificationStore.pushError({
      text: {
        title: $awt('aw.global.not_authenticated_warning'),
      },
    });
    return navigateTo(`/${getLocaleCodeForUrl({ $i18n })}`);
  }
});

function isRedirectRequired ({
  path,
  meta: { layout },
}) {
  const { $awMergedConfig } = useNuxtApp();
  const userStore = useUserStore();
  if (userStore.isEcomUser) {
    // USER login type is ecom user
    // no reason to redirect if logged in
    return false;
  } else {
    // USER login type is not ecom user
    // we do not want the not ecom user to reach some pages
    if ([
      // forbidden layouts
      'authenticated-10-column',
    ].includes(layout)) {
      return true;
    }
    // not allowed guest checkouts
    if (createCheckoutRegexFromCheckoutTypes(Object.values(checkoutTypes)).test(path)) {
      return !createCheckoutRegexFromCheckoutTypes($awMergedConfig.enabledGuestCheckouts).test(path);
    }
    // allow everything else
    return false;
  }
}

function createCheckoutRegexFromCheckoutTypes (checkoutTypes) {
  return new RegExp(`^/shop/checkout/(${checkoutTypes.join('|').toLowerCase()})($|/|\\?|#)`);
}
