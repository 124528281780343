<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :viewBox="isHorizontal ? '0 0 16 1' : '0 0 1 16'"
    fill="none"
    preserveAspectRatio="none"
    :stroke="$styleVariables.colorDashSeparator"
    stroke-dasharray="3 5"
    stroke-linecap="round"
    :style="dimensions"
  >
    <line
      v-if="isHorizontal"
      x1="-0.5"
      y1="0.5"
      x2="16"
      y2="0.5"
      vector-effect="non-scaling-stroke"
    />
    <line
      v-else
      x1="0.5"
      y1="-0.5"
      x2="0.5"
      y2="16"
      vector-effect="non-scaling-stroke"
    />
    ),
  </svg>
</template>
<script setup>
  import { computed } from 'vue';
  const props = defineProps({
    isHorizontal: {
      type: Boolean,
      default: false,
    },
  });
  const dimensions = computed(() => ({
    [props.isHorizontal ? 'height' : 'width']: '2px',
  }));
</script>
<style module lang="scss" rel="stylesheet/scss">
.noop {/* initialize css module */}
</style>
