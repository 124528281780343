<template>
  <div :class="[{[$style.fill]: fill}, {[$style.fillAndStroke]: fillAndStroke}, {[$style.inheritColor]: inheritColor}, {[$style.square]: square}, $style.icon, $style[sizeClass]]" @click="$emit('click')" v-html="decodedSvg" />
</template>

<script>
  import { base64Decode } from '~~/common/utils';
  export default {
    name: 'AwBase64Icon',
    props: {
      fill: {
        type: Boolean,
        default: false,
      },
      fillAndStroke: {
        type: Boolean,
        default: false,
      },
      square: {
        type: Boolean,
        default: true,
      },
      source: {
        type: String,
        required: true,
      },
      size: {
        type: Number,
        required: true,
      },
      inheritColor: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['click'],
    computed: {
      sizeClass () {
        return `size-${this.size}`;
      },
      decodedSvg () {
        const encoded = this.source;
        return base64Decode(encoded);
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
.icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  fill: none;
  stroke-linejoin: round;
  stroke: none;
}

.square {
  width: 1em;
  height: 1em;
}

.size {
  @each $lv-icon-size-name, $lv-icon-size-font-size, $lv-icon-size-line-height in $lv-icon-sizes {
    &-#{$lv-icon-size-name} {
      font-size: $lv-icon-size-font-size;
    }
  }
}

.fill {
  stroke: none;
  fill: currentColor;
}

.fillAndStroke {
  fill: currentColor;
}

.inheritColor {
  path {
    stroke: currentColor !important;
  }
}
</style>
