<template>
  <div :class="[visibleNotifications.length > 0 && $style.notificationBarWrapper]">
    <div
      v-if="visibleNotifications.length > 0"
      v-show="displayComponent"
      :class="[$style.notificationBar, {[$style.notificationBarMore]: visibleNotifications.length > 1}]"
    >
      <aw-spring :desktop-size="12">
        <client-only>
          <b-carousel
            ref="notificationCarouselRef"
            v-model="slideNavigation"
            :interval="4200"
            ride="carousel"
            :indicators="visibleNotifications.length > 1"
            :class="{'carousel-notification-bar': true}"
            style="position: relative;"
            @keyup="onPress"
          >
            <b-carousel-slide
              v-for="notification in visibleNotifications"
              :key="`notification-${notification.id}`"
              background="transparent"
            >
              <template #img>
                <div
                  :class="$style.notificationBarItemWrapper"
                  :style="{'--notification-item-height': notificationItemHeight || null}"
                >
                  <div
                    :class="$style.notificationBarItem"
                    v-html="notification.content"
                  />
                </div>
              </template>
            </b-carousel-slide>
          </b-carousel>
        </client-only>
        <div
          :class="[
            $style.notificationBarBackground,
            {
              [$style.notificationBarBackgroundOneItem]: visibleNotifications.length === 1,
            },
          ]"
        />
        <template v-if="visibleNotifications.length > 1">
          <lv-button styling="reset" :class="$style.notificationBarButton" @click="toggleAutoPlay">
            <lv-icon
              v-if="carosuelAutoPlay"
              :size="16"
              name="pause-version2-16"
              style="vertical-align: unset;"
            />
            <lv-icon
              v-else
              :size="16"
              name="play-version2-16"
              style="vertical-align: unset;"
            />
          </lv-button>
        </template>
        <div
          v-if="visibleNotifications.length > 0"
          :class="$style.hiddenNotificationBar"
          :aria-hidden="true"
        >
          <div
            v-for="(notification, index) in visibleNotifications"
            :key="`notification-hidden-${index}`"
            ref="notificationCarouselItemRef"
            :class="[$style.notificationBarItem, $style.hiddenNotificationBarItem]"
            v-html="notification.content"
          />
        </div>
      </aw-spring>
    </div>
  </div>
</template>

<script setup>
  import AwSpring from '~~/common/components/AwSpring';
  import { LvButton, LvIcon } from '~~/common/components/loginet-vue-shop/index.mjs';

  import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';
  import { useUserInterfaceStore } from '~~/common/stores/userInterface.js';
  import { useNotificationStore } from '~~/common/stores/notification.js';
  import { notificationTokenizer } from '~~/common/utils/helper.js';
  import { useNuxtApp, useRouter, useRuntimeConfig } from 'nuxt/app';
  import { useOrderStore } from '~~/common/stores/order.js';
  import { CT_FOOD } from '~~/common/config/CheckoutTypes.js';
  import { useNotificationBarStore } from '~~/shop/stores/notificationBar.js';
  import { useDealerNotificationStore } from '~~/dealer/stores/dealerNotification.js';

  const {
    $awt,
    $cookies,
  } = useNuxtApp();
  const config = useRuntimeConfig();

  const router = useRouter();

  const userInterfaceStore = useUserInterfaceStore();
  const notificationStore = useNotificationStore();
  const notificationBarStore = useNotificationBarStore();
  const dealerNotificationStore = useDealerNotificationStore();
  const orderStore = useOrderStore();
  const screenRange = computed(() => {
    return userInterfaceStore.mediaQueries;
  });
  const isShop = config.public.isShop;

  const emit = defineEmits(['notification-changed']);

  const notificationInfoFromCookie = $cookies.get('aw_notification_info');
  const now = ref(new Date());
  const notificationInfos = ref({});
  const notificationsFromNotificationBarStore = computed(() => {
    return notificationBarStore.notifications?.notifications;
  });

  if (import.meta.server) {
    notificationsFromNotificationBarStore.value?.forEach((notification) => {
      notificationInfos.value[notification.id] = {
        view_count: (notificationInfoFromCookie?.[notification.id]?.view_count ?? 0) + 1,
        force_hidden: notificationInfoFromCookie?.[notification.id]?.force_hidden ?? false,
      };
    });
    $cookies.set('aw_notification_info', notificationInfos.value, {
      path: '/',
      sameSite: 'lax',
    });
  }

  const notifications = computed(() => {

    let shopNotifications = [];
    if (isShop) {
      const orderResult = [];
      const notificationsOrder = notificationBarStore.notifications?.priority || [];

      if (orderStore.order?.infos?.loyaltyCardNextLevelInfo) {
        orderResult.push({
          id: 'loyalty-program-next-level',
          content: orderStore.order?.infos?.loyaltyCardNextLevelInfo,
          whitelist: true,
          urlList: [],
        });
      }
      if (orderStore.getRawStaticInfo?.[CT_FOOD]?.freeDeliveryInfo) {
        orderResult.push({
          id: 'free-food-delivery',
          content: orderStore.getRawStaticInfo?.[CT_FOOD]?.freeDeliveryInfo,
          whitelist: true,
          urlList: [],
        });
      }
      shopNotifications = notificationsOrder.map((id) => {
        const fixOrderNotification = orderResult.find((n) => n.id === id);
        const notification = notificationsFromNotificationBarStore.value?.find((n) => n.id === id);
        if (notification?.content?.includes('awCloseNotificationBar')) {
          notification.content = notification.content.replace('awCloseNotificationBar', `javascript:closeNotificationBar(${id});`);
        }
        return fixOrderNotification || notification;
      }).filter(notification => {

        if (!notification) {
          return false;
        }

        if (['loyalty-program-next-level', 'free-food-delivery'].includes(notification?.id)) {
          return true;
        }

        if (notificationInfoFromCookie?.[notification?.id]?.force_hidden) {
          notification.hidden = true;
          return false;
        }

        if (notification?.appearanceNumber !== 0 && notificationInfoFromCookie?.[notification?.id]?.view_count > notification?.appearanceNumber) {
          notification.hidden = true;
          return false;
        }

        if (new Date(notification.viewToDate) < now.value) {
          notification.hidden = true;
          return false;
        }

        if (notification.urlList.length === 0) {
          return true;
        }

        if (notification.whitelist) {
          return notification.urlList.some((url) => router.currentRoute.value.fullPath.startsWith(url));
        } else {
          return notification.urlList.every((url) => !router.currentRoute.value.fullPath.startsWith(url));
        }
      });
    } else {
      const notificationsFromStore = dealerNotificationStore.notifications;
      if (notificationsFromStore) {
        shopNotifications = notificationsFromStore
          .filter(n => n.type === 'header')
          .map((n) => {
            return { content: `<strong>${n.title}</strong> ${n.message}` };
          });
      }
    }

    const infoOrWarningNotifications = Object.values(notificationStore.notifications)?.map((notification) => {
      const noti = notificationTokenizer({ text: notification?.text }, { awt: $awt });
      return noti.title + ' ' + noti.subtitle;
    });

    const result = [...infoOrWarningNotifications, ...shopNotifications];
    // slice the LAST 4 items
    return result.length > 4 ? result.slice(result.length - 4, result.length) : result;
  });

  const visibleNotifications = computed(() => notifications.value.filter(notification => !notification.hidden));

  const displayComponent = ref(false);
  const notificationCarouselRef = ref(null);
  const notificationCarouselItemRef = ref([]);
  const slideNavigation = ref(0);
  const carosuelAutoPlay = ref(true);
  const notificationItemHeight = ref(0);

  const pause = () => notificationCarouselRef.value?.pause();
  const resume = () => notificationCarouselRef.value?.resume();
  const next = () => notificationCarouselRef.value?.next();
  const prev = () => notificationCarouselRef.value?.prev();

  function toggleAutoPlay () {
    if (carosuelAutoPlay.value) {
      pause();
    } else {
      resume();
    }
    carosuelAutoPlay.value = !carosuelAutoPlay.value;
  }

  function calculateNotificationsHeight () {
    let maxHeight = 0;
    notificationCarouselItemRef.value?.forEach(item => {
      maxHeight = Math.max(maxHeight, item.getBoundingClientRect().height);
    });
    const screenRangeMax = screenRange.value['mobile-max'] ? 3 : (screenRange.value['tablet-max'] ? 2 : 1);
    notificationItemHeight.value = Math.min(maxHeight / 16, screenRangeMax);
  }

  function onPress (event) {
    switch (event.key) {
    case 'ArrowLeft':
    case 'ArrowUp':
      event.preventDefault();
      event.stopPropagation();
      prev();
      break;
    case 'ArrowRight':
    case 'ArrowDown':
      event.preventDefault();
      event.stopPropagation();
      next();
      break;
    default:
      break;
    }
  }

  function hideNotification (id) {
    notificationInfoFromCookie[id].force_hidden = true;
    $cookies.set('aw_notification_info', notificationInfoFromCookie, {
      path: '/',
      sameSite: 'lax',
    });
  }

  watch(
    notifications,
    (newValue, oldValue) => {
      if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
        calculateNotificationsHeight();
        emit('notification-changed');
      }
    },
    { deep: true },
  );

  watch(
    visibleNotifications,
    (newValue, oldValue) => {
      if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
        slideNavigation.value = 0;
        calculateNotificationsHeight();
        emit('notification-changed');
      }
    },
    {
      deep: true,
      immediate: true,
    },
  );
  const timer = ref(null);

  onMounted(() => {
    calculateNotificationsHeight();
    notificationInfos.value = notificationInfoFromCookie.value;
    window.closeNotificationBar = (id) => {
      hideNotification(id);
    };
    timer.value = setInterval(() => {
      now.value = new Date();
    }, 1000);
    setTimeout(() => {
      displayComponent.value = true;
    }, 1000);
  });

  onBeforeUnmount(() => {
    clearInterval(timer.value);
  });

</script>


<style module lang="scss" rel="stylesheet/scss">
.notificationBar {
  font-family: $secondary-font;
  z-index: 100;
  width: 100%;
  color: $color-text-inverse;

  background: $color-olive-750;

  [data-spring-container] {
    display: grid;
    grid-template-columns: auto;
    gap: 4px;
    align-items: center;

  }

  &More {
    [data-spring-container] {
      grid-template-columns: auto 24px;
    }
  }

  &Item {
    font-size: 10px;
    font-weight: $font-weight-normal-v2;
    line-height: 16px;

    display: -webkit-box;
    overflow: hidden;
    height: calc(var(--notification-item-height, 1) * 16px);
    text-align: left;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: var(--notification-item-height, 1);

    @include tablet(min) {
      font-size: 12px;
      line-height: 16px;
      text-align: center;
    }

    &Wrapper {
      height: calc(var(--notification-item-height, 1) * 16px + 16px);
      padding: 8px 12px 8px 31px;

      @include tablet(min) {
        padding: 8px 20px 8px 39px;
      }
    }

    a {
      text-decoration: underline;
    }
  }

  &Background {
    height: calc(100% - 2 * 8px);
    margin: 8px 0 8px 19px;
    border-radius: 12px;
    background: $color-black-32;
    grid-area: 1 / 1 / 1 / 1;

    &OneItem {
      margin-left: 0;
    }
  }

  &Button {
    width: 24px;
    height: 32px;
    padding: 8px 4px;
    border-radius: 12px;
    background: $color-black-32;

    &:focus, &:hover, &:active {
      background: $color-black-32;
    }
  }

  &Wrapper {
    min-height: 48px;
  }
}

.hiddenNotificationBar {
  position: absolute;
  top: -1000px;
  visibility: hidden;

  &Item {
    display: block;
    height: unset;
    padding-top: 0;
    padding-bottom: 0;
  }
}
</style>
