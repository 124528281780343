<template>
  <aw-tab-schematic
    v-model:selected-id="selectedTab"
    :aria-labelledby="ariaLabelledby"
    :visible-tab-items="schematicVisibility"
    :allow-close-on-click="isInAccordionMode || screenRange['mobile-max']"
    :no-default-value="noDefaultValue"
    :class="[
      $style.tabAccordion,
      {
        [$style.tabAccorionIsInAccordionMode]: isInAccordionMode,
      },
    ]"
    :style="`
      --awProductPageTabCount: ${tabCount}
    `"
  >
    <template
      v-for="(vTabItem, idx) in visibleTabItems"
      :key="idx"
      #[`tab_${idx}`]="slotProps"
    >
      <div
        :class="[
          $style.tabAccordionTab,
          {
            [$style.tabAccordionTabIsSelected]: slotProps.isSelected,
          },
        ]"
        v-bind="slotProps.attrs"
        :style="{
          ...(vTabItem.tabStyle || null),
          order: slotProps.tabIdx,
        }"
        data-aw-tab-accordion-tab
      >
        <slot :name="`tab_${idx}`">
          tab {{ idx }}
        </slot>
        <span
          :class="[
            $style.tabAccordionTabArrow,
            {
              [$style.tabAccordionTabArrowUp]: slotProps.isSelected,
            },
          ]"
        >
          <lv-icon
            :size="16"
            name="arrow-down-16"
            :class="$style.tabAccordionTabArrowIcon"
          />
        </span>
      </div>
    </template>
    <template
      v-for="(vTabItem, idx) in visibleTabItems"
      :key="idx"
      #[`tabpanel_${idx}`]="slotProps"
    >
      <transition v-bind="accordionTransition">
        <div
          v-show="slotProps.isSelected"
          :class="$style.tabAccordionTabpanel"
          v-bind="slotProps.attrs"
          :style="{
            ...(vTabItem.tabpanelStyle || null),
            display: null,
            order: slotProps.tabIdx,
          }"
        >
          <transition v-bind="accordionTransition">
            <div
              v-if="slotProps.isSelected"
              :class="$style.tabAccordionTabpanelContent"
            >
              <slot :name="`tabpanel_${idx}`">
                tabpanel {{ idx }}
              </slot>
            </div>
          </transition>
        </div>
      </transition>
    </template>
  </aw-tab-schematic>
</template>
<script setup>
  import { computed, defineAsyncComponent, useCssModule } from 'vue';
  import { LvIcon } from '~~/common/components/loginet-vue-shop/index.mjs';
  import { createAccordionTransition } from '~~/common/utils/accordion.js';
  import { useUserInterfaceStore } from '~~/common/stores/userInterface.js';

  const AwTabSchematic = defineAsyncComponent(() => import('~~/common/components/Common/AwTabSchematic.vue'));

  const props = defineProps({
    visibleTabItems: {
      type: Object,
      default: () => ({}),
      validator (obj) {
        return !Object.values(obj).some((val) => {
          return !val || (typeof val !== 'object');
        });
      },
    },
    ariaLabelledby: {
      type: String,
      required: true,
    },
    isInAccordionMode: {
      type: Boolean,
      default: false,
    },
    noDefaultValue: {
      type: Boolean,
      default: false,
    },
  });
  const screenRange = computed(() => {
    return useUserInterfaceStore().mediaQueries;
  });
  const selectedTab = ref(null);
  const $style = useCssModule();
  const accordionTransition = {
    enterActiveClass: $style.accordionItemEnterActive,
    enterFromClass: $style.accordionItemEnter,
    leaveActiveClass: $style.accordionItemLeaveActive,
    leaveToClass: $style.accordionItemLeaveTo,
    ...createAccordionTransition(),
  };
  const schematicVisibility = computed(() => {
    return Object.fromEntries(
      Object.entries(props.visibleTabItems).map(([key, value]) => {
        return [key, value?.isVisible];
      }),
    );
  });
  const tabCount = computed(() => {
    return Object.keys(props.visibleTabItems).map(k => props.visibleTabItems[k]?.isVisible).filter(t => t).length;
  });
</script>
<script>
  export const tabAccordionTabWhite = {
    tabStyle: {
    },
    tabpanelStyle: {
      '--tabBackgroundColor': 'white',
    },
  };
  export const tabAccordionAccordionBordered = {
    tabStyle: {
    },
    tabpanelStyle: {
      '--accordionBorder': '1px solid #E7E4E1',
    },
  };
  export const tabAccordionBordered = {
    tabStyle: {
    },
    tabpanelStyle: {
      border: '1px solid #E7E4E1',
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
@mixin modeAccordion () {
  // $parent="#{if(&, " &", "")}";
  $p: &;
  $isRoot: "#{nth($p, 1)}" == ".tabAccordion";
  @at-root .tabAccordion.tabAccorionIsInAccordionMode {
    @if $isRoot {
      @content;
    } @else {
      #{$p} {
        @content;
      }
    }
  }
  @at-root .tabAccordion:not(.tabAccorionIsInAccordionMode) {
    @include mobile(max) {
      @if $isRoot {
        @content;
      } @else {
        #{$p} {
          @content;
        }
      }
    }
  }
}
@mixin modeTab () {
  $p: &;
  $isRoot: "#{nth($p, 1)}" == ".tabAccordion";
  @include tablet(min) {
    @at-root .tabAccordion:not(.tabAccorionIsInAccordionMode) {
      @if $isRoot {
        @content;
      } @else {
        #{$p} {
          @content;
        }
      }
    }
  }
}

.tabAccordion {
  $gap: 8px;
  $radius: 12px;

  @include modeAccordion() {
    display: flex;
    flex-direction: column;
    gap: $gap;
  }
  @include modeTab() {
    display: grid;
    grid-template-columns: repeat(var(--awProductPageTabCount), auto) 1fr;
    gap: 40px 0;
  }

  &Tab {
    font-weight: 900;
    cursor: pointer;

    @include modeAccordion() {
      font-size: 12px;
      line-height: 16px;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 3.5px 16px;
      transition: border-color $animation-speed-medium $animation-timing-function;
      border: 1px solid $color-border-disabled;
      border-radius: $radius;
      background: $color-white;
      gap: 16px;
    }
    @include modeTab() {
      font-size: 14px;
      line-height: 20px;
      grid-row: 1;
      padding: 12px 16px;
      border-bottom: 1px solid $color-border;
      outline: 1px solid transparent;

      &IsSelected {
        border-color: currentColor;
      }

      &:not(&IsSelected) {
        color: $color-text-secondary;
      }

      &:focus-visible {
        outline-color: currentColor;
      }
    }

    &:focus-visible &Arrow {
      outline-color: currentColor;
    }

    &Arrow {
      padding: 8px;
      border-radius: 8px;
      outline: 1px solid transparent;

      @include modeTab() {
        display: none;
      }
    }

    &ArrowIcon {
      transition: rotate $animation-speed-medium $animation-timing-function;
    }

    &ArrowUp &ArrowIcon {
      rotate: 180deg;
    }
  }

  &Tabpanel {
    outline: 1px solid transparent;
    background: $color-background-3;

    &:focus-visible {
      outline-color: currentColor;
    }
    @include modeAccordion() {
      font-size: 12px;
      line-height: 16px;
      margin-top: #{-($gap + $radius)};
      border: var(--accordionBorder, none);
      border-bottom-right-radius: 12px;
      border-bottom-left-radius: 12px;

      &.accordionItem {
        &EnterActive,
        &LeaveActive {
          overflow: hidden;
          transition: height $animation-speed-medium $animation-timing-function;
        }

        &Enter,
        &LeaveTo {
          height: $radius !important;
        }
      }

      &Content {
        padding: $radius 16px 20px;
      }
    }
    @include modeTab() {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      padding: 40px;
      border-radius: 16px;
      background: var(--tabBackgroundColor, $color-background-3);
      grid-column: 1 / -1;
    }
  }
}
</style>
