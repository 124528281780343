import { defineStore } from 'pinia';
import { useNuxtApp, useRouter } from 'nuxt/app';
import { computed, ref } from 'vue';
import { setErrorNotification } from '~~/common/utils/helper';
import { commitAlcoholAlertModal, commitAlcoholConfirmModal } from '~~/common/utils/alcoholManagement';
import { M_DELIVERY_METHOD } from '~~/common/config/Modal.js';
import otqs from '~~/common/utils/objectToQueryString';
import { extractResourceFromPath, getSubcategoryId } from '~~/common/utils/urlResource.js';
import { AW_AX_CACHE_SEGMENTATION } from '~~/common/plugins/axios/awAxCache.js';
import { PUSH_CATEGORY_VIEW } from '~~/common/plugins/aw-analytics.js';
import { useNotificationStore } from '~~/common/stores/notification';
import { useModalStore } from '~~/common/stores/modal';
import { useUserInterfaceStore } from '~~/common/stores/userInterface';
import { useSeoStore } from '~~/common/stores/seo';
import { useUserStore } from '~~/common/stores/user';
import { useAuthenticationStore } from '~~/common/stores/authentication.js';

export class UnCachedProduct {
  constructor ({
    productId,
    shoppingListsContain,
    status,
    isLoyaltyPriceValid,
  }) {
    this.productId = productId;
    this.shoppingListsContain = shoppingListsContain;
    this.status = status;
    this.isLoyaltyPriceValid = isLoyaltyPriceValid;
  }
}

export function updateProdWithUnCachedData ({
  productsToMutate,
  payload,
  $logger,
}) {
  const idsToMutate = new Set(Object.keys(payload));
  productsToMutate
    .filter(prod => idsToMutate.has(prod.id.toString()))
    .forEach((prod) => {
      for (const v of ['selectedVariant', 'defaultVariant']) {
        try {
          const payloadProd = payload[prod.id];
          if (!(payloadProd instanceof UnCachedProduct)) {
            $logger.warn('payloadProd should be UnCachedProduct, continuing but we may fail.');
          }
          if (payloadProd.shoppingListsContain !== prod[v].shoppingListsContain) {
            prod[v].shoppingListsContain = payloadProd.shoppingListsContain;
          }
          if (payloadProd.status !== prod[v].cartInfo.availability) {
            prod[v].cartInfo.availability = payloadProd.status;
          }
          if (payloadProd.isLoyaltyPriceValid !== prod[v].isLoyaltyPriceValid) {
            prod[v].isLoyaltyPriceValid = payloadProd.isLoyaltyPriceValid;
          }
          if (prod?.feMeta?.isCached === true) {
            prod.feMeta.isCached = false;
          }
        } catch (err) {
          $logger.error(err);
        }
      }
    })
  ;
}

const throttledRefreshObject = {
  id: null,
  promise: null,
  resolve: null,
};

export const useProductsStore = defineStore('products', () => {
  const nuxtApp = useNuxtApp();
  const router = useRouter();
  const notificationStore = useNotificationStore();
  const userStore = useUserStore();
  const seoStore = useSeoStore();
  const modalStore = useModalStore();
  const authenticationStore = useAuthenticationStore();

  // products from result
  const products = ref([]);
  const productsCategories = ref(null);

  const containsAgeRestrictedProduct = ref(false);
  // pagination data
  const currentPage = ref(1);
  const itemCount = ref(null);
  const pageCount = ref(null);
  const itemsPerPage = ref(null);

  // order and filter data
  const sortValues = ref(null);
  const filters = ref([]);
  const sortKey = ref(null);
  const sortBy = ref(null);
  const filterBy = ref({});
  const searchText = ref([]);
  const noResultsFor = ref([]);
  const productListHistoryInformation = ref(null);
  const flagFilter = ref(null);
  /**
   * product should be refreshed on state changes. it's used as an
   * 'event' state variable. It's value is not relevant.
   * */
  const refreshProducts = ref(0);
  const alcoholProductIsLoading = ref(false);

  // visited page history
  const visitedPages = ref([]);
  const lastFilterableResource = ref(null);

  // checkboxFilter helper
  const filterKeyword = ref({});

  // Filtering
  const filterPanelVisible = ref(false);

  function pushProducts (productsArg) {
    for (const productsKey in productsArg) {
      products.value.push(productsArg[productsKey]);
    }
  }

  function setCategoryPageAttrs (attrs) {
    containsAgeRestrictedProduct.value = Boolean(attrs?.containsAgeRestrictedProduct);
    currentPage.value = attrs?.currentPage || currentPage?.value;
    if (attrs?.itemCount || attrs?.itemCount === 0) {
      itemCount.value = attrs?.itemCount;
    }
    pageCount.value = attrs?.pageCount || pageCount.value;
    itemsPerPage.value = attrs?.itemsPerPage || itemsPerPage.value;
    sortValues.value = attrs?.sortValues || sortValues.value;
    filters.value = attrs?.filters || filters.value;
    noResultsFor.value = attrs?.noResultsFor || noResultsFor.value;
    sortKey.value = attrs?.sortKey;
    sortBy.value = attrs?.sortBy;
    productsCategories.value = attrs?.productsCategories;
  }

  function setSearchText (searchTextArg) {
    const st = Array.isArray(searchTextArg) ? searchTextArg : searchTextArg.split('\n');
    searchText.value = Array.from(new Set(st.filter(f => f.trim())));
  }

  function clearFilterAndSort () {
    filters.value = [];
    sortKey.value = null;
    sortBy.value = null;
    filterBy.value = {};
    flagFilter.value = null;
  }

  function setActiveFilter (filter) {
    Object.entries(filter).forEach(([key, value]) => {
      if (filterBy.value[key] != null && !Array.isArray(value)) {
        if (filterBy.value[key].includes(value)) {
          if (filterBy.value[key].length > 1) {
            delete filterBy.value[key][filterBy.value[key].indexOf(value)];
          } else {
            delete filterBy.value[key];
          }
        } else {
          filterBy.value[key][filterBy.value[key].length] = value;
        }
      } else {
        filterBy.value[key] = [value];
      }
    });
  }

  function setActiveFilterSingleValue (filter) {
    Object.entries(filter).forEach(([key, value]) => {
      filterBy.value[key] = value;
    });
  }

  function setFilterFromQuery (query) {
    if (query.qq) {
      const p = JSON.parse(decodeURIComponent(query.qq));
      filterBy.value = p.filterParams || {};
      sortKey.value = p.sortKey || null;
      sortBy.value = p.sortBy || null;
    } else {
      filterBy.value = {};
      sortKey.value = null;
      sortBy.value = null;
    }
  }

  function resetFilterBy () {
    filterBy.value = {};
  }

  function resetFilterByCategory () {
    delete filterBy.value.subcategory;
  }

  function setProductDetails ({
    id,
    data,
    VARIANT_PROP,
  }) {
    const currentProduct = products.value.find(product => parseInt(product.id) === parseInt(id));
    if (currentProduct[VARIANT_PROP]) {
      currentProduct[VARIANT_PROP].parameterList = data;
    }
  }

  function resetFilterByType (key) {
    if (filterBy.value[key]) {
      delete filterBy.value[key];
    }
  }

  function setAlcoholProductIsLoading (payload) {
    alcoholProductIsLoading.value = payload;
  }

  function setVisitedPage (page) {
    visitedPages.value.push(page);
  }

  function setLastFilterableResource (resource) {
    lastFilterableResource.value = resource;
  }

  function saveProductListHistoryInformation ({
    productId,
    route,
  }) {
    productListHistoryInformation.value = {
      products: products.value,
      location: route,
      productId,
      currentPage: currentPage.value,
      returnEnabled: false,
    };
  }

  function enableProductListHistoryInformation () {
    productListHistoryInformation.value.returnEnabled = true;
  }

  function restoreFromProductListHistoryInformation () {
    products.value = productListHistoryInformation.value.products;
    currentPage.value = productListHistoryInformation.value.currentPage;
  }

  function setFlagFilter (flagId) {
    flagFilter.value = flagId;
  }

  async function getResult (params) {
    const {
      $api,
      $logger,
    } = nuxtApp;

    await authenticationStore.reInitToken();

    function keyValueFlattener (obj) {
      return Object.entries(obj).filter(([, v]) => {
        return v !== null;
      }).map(([k, v]) => {
        return [k, Array.isArray(v) ? v : [v]];
      }).map(([k, v]) => {
        return v.flat().map(n => [k, n]);
      }).flat();
    }

    try {
      if (params?.isCached === undefined) {
        $logger.warn('getResult fallbacking to uncached content. To silence this warning specify the cache parameter.');
      }
      const isCacheRequested = Boolean(params?.isCached);
      const subCategoryId = params.filterParams?.subcategory?.[0] || params.filterParams?.category?.[0] || null;
      const fp = keyValueFlattener(params.filterParams || {}).filter(([k]) => k !== 'subcategory' && k !== 'category');
      const filters = fp.map(([k]) => k);
      const filterValues = fp.map(([, v]) => v);
      return await $api.get(`/products${otqs({
        ...params,
        isCached: null,
        filterParams: null,
        filters,
        filterValues,
        subCategoryId,
      })}`, { awAxCache: isCacheRequested ? AW_AX_CACHE_SEGMENTATION : undefined })
        .then((ax) => {
          const isHit = Boolean(ax.awAxCacheHit);
          if (Array.isArray(ax.data?.results)) {
            ax.data.results.forEach((r) => {
              r.feMeta = { isCached: isHit };
            });
          }
          return {
            isCached: isHit,
            data: ax.data,
          };
        });
    } catch (error) {
      $logger.error(error);
      setErrorNotification({ nuxtApp, error });
    }
  }

  async function fetchCachedProductOrFallback ({ params }) {
    try {
      return await getResult(params).then(({
        isCached: isCachedFirstReq,
        data: dataFirst,
      }) => {
        if (isCachedFirstReq && dataFirst?.containsAgeRestrictedProduct) {
          return getResult({
            ...params,
            isCached: false,
          }).then(({
            isCached: isCachedSecondReq,
            data: dataSec,
          }) => {
            return {
              isCached: isCachedSecondReq,
              value: dataSec,
            };
          });
        } else {
          return {
            isCached: isCachedFirstReq,
            value: dataFirst,
          };
        }
      });
    } catch (error) {
      return {
        isCached: false,
        value: undefined,
      };
    }
  }

  async function fetchUnCachedPartsReqAndUpdateStore ({ productIds }) {
    const { $logger } = nuxtApp;
    const resp = await getUnCachedPartsReq({ productIds });
    if (resp?.list && Object.keys(resp.list).length) {
      for (const k in resp.list) {
        resp.list[k] = new UnCachedProduct(resp.list[k]);
      }
      updateProdWithUnCachedData({
        productsToMutate: products.value,
        payload: resp.list,
        $logger,
      });
    }
  }

  async function getUnCachedPartsReq ({ productIds }) {
    const {
      $api,
      $logger,
    } = nuxtApp;
    try {
      if (productIds?.length) {
        const result = await $api.$get(`/products/cart_buttons_display_status${otqs({ productIds })}`);
        const mapped = {};
        result.list.forEach((item) => {
          mapped[item.productId] = item;
        });
        return {
          ...result,
          list: mapped,
        };
      }
    } catch (err) {
      $logger.error(err);
    }
  }

  async function fetchSimilarProductFilters ({
    productId,
    categoryId,
  }) {
    const {
      $api,
      $awt,
      $logger,
    } = nuxtApp;
    const pushSimilarError = () => {
      notificationStore.pushError({
        text: {
          title: $awt('awd.common.similar_products.empty'),
        },
      });
    };

    const generateProductFilters = (originFilters) => {
      const result = {};
      originFilters.forEach((currentFilter) => {
        result[currentFilter?.parameter] = currentFilter?.values;
      });
      return result;
    };

    try {
      const response = await $api.$get(`similar_product_filters/${productId}/${categoryId}`);

      const productFilters = response.filters;

      if (!productFilters.length) {
        pushSimilarError();
        return;
      }

      const generatedFilters = generateProductFilters(productFilters);
      return JSON.stringify(Object.keys(generatedFilters || {}).length ? { filterParams: generatedFilters } : {});
    } catch (error) {
      $logger.error(error);
      setErrorNotification({ nuxtApp, error });
      return null;
    }
  }

  async function fetchProducts (params) {
    const {
      $awAnalytics,
      $awt,
      $cookies,
      $getHref,
      $logger,
    } = nuxtApp;

    try {
      const newQuery = JSON.stringify({
        ...(Object.keys(params.filterParams || {}).length ? { filterParams: params.filterParams } : null),
        ...(params.sortKey ? { sortKey: params.sortKey } : null),
        ...(params.sortBy ? { sortBy: params.sortBy } : null),
      });

      const {
        value: result,
        isCached,
      } = await fetchCachedProductOrFallback({ params });

      const uncachedReq = (isCached
        ? getUnCachedPartsReq({ productIds: (result?.results || []).map(p => p.id) })
        : Promise.resolve(undefined)
      );

      if (typeof window !== 'undefined') {
        const s = new URLSearchParams(window.location.search);
        if (newQuery !== '{}') {
          s.set('qq', newQuery);
        } else {
          s.delete('qq');
        }
        const search = s.toString() ? `?${s.toString()}` : '';
        const newUrl = `${window.location.pathname}${search}`;
        window.history.replaceState(null, null, newUrl);
        useUserInterfaceStore().setRouteHistoryPathIfChanged({ fullPath: router.resolve(`${window.location.pathname}${window.location.search}`).href });
      }

      const subCategoryAnaliticsReq = (async () => {
        try {
          const newHref = $getHref();
          const urlResource = extractResourceFromPath(newHref);
          const subCategoryId = getSubcategoryId({ href: newHref });
          const categoryId = urlResource?.resourceType === 'category' ? urlResource.resourceId : null;
          const finalId = subCategoryId || categoryId;
          const result = finalId ? await seoStore.getSeoData({
            seoModule: 'categorySeoResource',
            id: finalId,
          }) : null;
          if (result) {
            $awAnalytics[PUSH_CATEGORY_VIEW]({ categoryName: result?.title });
          }
        } catch (err) {
          $logger.error(err);
        }
      })();

      const tempObj = {
        containsAgeRestrictedProduct: result?.containsAgeRestrictedProduct,
        itemCount: result?.itemCount,
        sortValues: result?.sortValues.filter(sv => sv.value !== 'default'),
        filters: result?.filters,
        sortKey: params?.sortKey,
        sortBy: params?.sortBy,
        itemsPerPage: itemsPerPage.value,
        currentPage: result?.currentPage,
        pageCount: result?.pageCount,
        noResultsFor: result?.noResultsFor,
        productsCategories: result?.categories,
      };

      if (result?.currentPage === 1) {
        products.value = result?.results;
      } else {
        pushProducts(result?.results);
      }

      setCategoryPageAttrs(tempObj);

      if (result?.containsAgeRestrictedProduct && modalStore.activeGenericModal.type !== M_DELIVERY_METHOD) {
        if (!modalStore.modals.genericModalQueue.some(m => m?.item?.data?.alcoholModalUniquenessGuarantee)) {
          if (!userStore.isEcomUser) {
            commitAlcoholAlertModal({ $awt });
          } else if (!result.ageConfirmed && !$cookies.get('under18')) {
            commitAlcoholConfirmModal($awt);
          }
        }
      }

      const resp = await uncachedReq;
      if (resp?.list && Object.keys(resp.list).length) {
        for (const k in resp.list) {
          resp.list[k] = new UnCachedProduct(resp.list[k]);
        }
        updateProdWithUnCachedData({
          productsToMutate: products.value,
          payload: resp.list,
          $logger,
        });
      }

      await subCategoryAnaliticsReq;

      return !!result;
    } catch (error) {
      $logger.error(error);
      setErrorNotification({ nuxtApp, error });
      return false;
    }
  }

  /* async fetchProductDetailsByProduct (product) {
    const { $api } = nuxtApp;
    const VARIANT_PROP = 'selectedVariant';
    if (product?.[VARIANT_PROP]?.details?.includes('parameterList') && !(product?.[VARIANT_PROP]?.parameterList)) {
      const result = await $api.$get(`products/${product.id}/variants/${product[VARIANT_PROP].id}/details/parameterList`);
      this.setProductDetails({ data: result, id: product.id, VARIANT_PROP });
    }
  }, */
  function throttledRefresh () {
    if (!throttledRefreshObject.promise) {
      throttledRefreshObject.promise = new Promise((resolve) => {
        throttledRefreshObject.resolve = resolve;
      });
    }
    clearTimeout(throttledRefreshObject.id);
    throttledRefreshObject.id = setTimeout(() => {
      refreshProducts.value++;
      throttledRefreshObject.promise = null;
      throttledRefreshObject.resolve();
    }, 400);
    return throttledRefreshObject.promise;
  }

  function setFilterKeyword (key, payload) {
    filterKeyword.value[key] = payload;
  }

  function setupProductList () {
    const { $logger } = nuxtApp;
    if (import.meta.server) {
      $logger.warn('setupProductList should not run on server side');
      return;
    }
    itemsPerPage.value = getItemsPerPage.value;
    if (!(productListHistoryInformation.value?.returnEnabled)) {
      products.value = products.value.slice(0, itemsPerPage.value);
    }
  }

  const getCategorySelectionFilter = computed(() => {
    return filters.value?.find(filter => filter.selectionType === 'categorySelection');
  });
  const getItemsPerPage = computed(() => {
    const userInterfaceStore = useUserInterfaceStore();
    if (userInterfaceStore.mediaQueries['desktop-large-min']) {
      return 16;
    }
    if (userInterfaceStore.mediaQueries['desktop-small-min']) {
      return 12;
    }
    return 8;
  });
  const highlightedProductCount = computed(() => {
    const isHighlighted = (productData) => {
      return !!productData?.highlightedParameters?.length || false;
    };
    const idxOfFirstNonHighlighted = products.value.findIndex(productData => !isHighlighted(productData));
    return idxOfFirstNonHighlighted === -1 ? products.value.length : idxOfFirstNonHighlighted;
  });
  const renderedProducts = computed(() => {
    // todo add comment for what happens here
    // If itemsPerPage is 12 use MAGIC
    const difference = getItemsPerPage.value === 12
      ? Math.max(products.value.length - (Math.floor(highlightedProductCount.value / 2) + Math.ceil(highlightedProductCount.value % 2 * 0.5)), getItemsPerPage.value / 2 * 4 / 3)
      : products.value.length - Math.min(highlightedProductCount.value, getItemsPerPage.value / 2);

    return currentPage.value === pageCount.value ? products.value : products.value.slice(0, difference);
  });

  return {
    products,
    productsCategories,
    containsAgeRestrictedProduct,
    currentPage,
    itemCount,
    pageCount,
    itemsPerPage,
    sortValues,
    filters,
    sortKey,
    sortBy,
    filterBy,
    searchText,
    noResultsFor,
    productListHistoryInformation,
    refreshProducts,
    alcoholProductIsLoading,
    visitedPages,
    lastFilterableResource,
    filterKeyword,
    filterPanelVisible,
    pushProducts,
    setCategoryPageAttrs,
    setSearchText,
    clearFilterAndSort,
    setActiveFilter,
    setActiveFilterSingleValue,
    setFilterFromQuery,
    resetFilterBy,
    resetFilterByCategory,
    setProductDetails,
    resetFilterByType,
    setAlcoholProductIsLoading,
    setVisitedPage,
    setLastFilterableResource,
    saveProductListHistoryInformation,
    enableProductListHistoryInformation,
    restoreFromProductListHistoryInformation,
    getResult,
    fetchCachedProductOrFallback,
    fetchUnCachedPartsReqAndUpdateStore,
    getUnCachedPartsReq,
    fetchSimilarProductFilters,
    fetchProducts,
    throttledRefresh,
    setFilterKeyword,
    setupProductList,
    getCategorySelectionFilter,
    getItemsPerPage,
    renderedProducts,
    highlightedProductCount,
    flagFilter,
    setFlagFilter,
  };
});
