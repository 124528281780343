<template>
  <div :class="[$style.badge, {[$style.sleek]: sleek}, $style[styling]]" :style="badge.style">
    <slot>
      {{ badge.text }}
    </slot>
  </div>
</template>

<script>
  export default {
    name: 'AwBadge',
    props: {
      sleek: {
        type: Boolean,
        required: false,
        default: false,
      },
      styling: {
        type: String,
        default: 'primary',
      },
      badge: {
        type: Object,
        default: () => ({
          text: 'Specify bagde or use slot',
          style: {},
        }),
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">

$aw-badge-themes: (
  "primary": (
    "color": $color-white,
    "background": $color-brand-primary,
  ),
  "secondary": (
    "color": $color-white,
    "background": $color-brand-secondary,
  ),
  "tertiary": (
    "color": $color-white,
    "background": $color-brand-secondary-active,
  ),
  "quarternary": (
    "color": $color-alto,
    "background": $color-brand-primary,
  ),
  "transparent": (
    "color": $color-white,
    "background": transparent,
  ),
  "secondary-outline": (
    "color": $color-brand-secondary,
    "background": $color-white,
    "border": 1px solid $color-brand-secondary,
  ),
);

.badge {
  font-size: 13px;
  font-weight: $font-weight-bold;
  display: inline-block;
  padding: 8px 16px;
  border-radius: 8px;

  @each $th-name, $th in $aw-badge-themes {
    &.#{$th-name} {
      color: map-get($th, "color");
      border: map-get($th, "border");
      background: map-get($th, "background");
    }
  }

  &.sleek {
    font-size: 12px;
    padding: 4px 10px;
  }
}
</style>
