import { defineStore } from 'pinia';
import { useNuxtApp } from 'nuxt/app';
import { ref } from 'vue';

export const useUrlResourceStore = defineStore('urlResource', () => {
  const data = ref({});
  const modalData = ref({});

  async function fetchByUrl (url) {
    const { $api, $logger } = useNuxtApp();
    try {
      const data = await $api.$get('/url-resource', {
        params: {
          slug: url,
        },
      });
      setData(data || {});
    } catch (err) {
      $logger.error(err);
      setData({});
    }
  }

  async function fetchModalUrlResourceByUrl (url) {
    const { $api, $logger } = useNuxtApp();
    try {
      const data = await $api.$get('/url-resource', {
        params: {
          slug: url,
        },
      });
      setModalData(data || {});
    } catch (err) {
      $logger.error(err);
      setModalData({});
    }
  }

  function setData (newData) {
    data.value = newData;

    if (import.meta.server) {
      global.storeHelper = global.storeHelper || {};
      global.storeHelper.urlResourceStoreData = newData;
    }
  }

  function setModalData (newData) {
    modalData.value = newData;
  }

  return {
    data,
    modalData,
    fetchByUrl,
    fetchModalUrlResourceByUrl,
    setData,
    setModalData,
  };
});
