import { defineStore } from 'pinia';
import { createError, useNuxtApp } from 'nuxt/app';
import { computed, ref } from 'vue';
import { categorySlugify } from '~~/common/utils/category.js';
import { setErrorNotification } from '~~/common/utils/helper.js';
import { AW_AX_CACHE_SEGMENTATION } from '~~/common/plugins/axios/awAxCache.js';
import { useDeliveryStore } from '~~/shop/stores/delivery.js';
import { useAwCacheKeyStore } from '~~/common/stores/awCacheKey.js';
import ObjectCache from '~~/common/utils/ObjectCache.js';

const cache = new ObjectCache('category', '__');

export const useCategoryStore = defineStore('category', () => {
  const nuxtApp = useNuxtApp();

  const deliveryStore = useDeliveryStore();
  const awCacheKeyStore = useAwCacheKeyStore();

  const categories = ref(null);
  const category = ref(null);
  const treeId = ref(null);
  const categoryIdBeforeVisitProductPage = ref(false);

  function setCategories (newCategories) {
    categories.value = categorySlugify(newCategories);
  }

  function setTreeId (newTreeId) {
    if (typeof newTreeId === 'number') {
      treeId.value = newTreeId;
    } else {
      treeId.value = null;
    }
  }

  function setCategory (newCategory) {
    category.value = newCategory;
  }

  function setCategoryIdBeforeVisitProductPage (payload) {
    categoryIdBeforeVisitProductPage.value = payload;
  }

  async function fetchCategories () {
    const { $api, $logger, $i18n } = nuxtApp;

    const postCode = deliveryStore.getPostCode;
    const cacheKey = ['category', '0', $i18n.locale.value, awCacheKeyStore.cacheKeySegmentation || postCode];
    try {
      cache.initCache(cacheKey, () => $api.$get('/tree/0', { awAxCache: AW_AX_CACHE_SEGMENTATION }));
      const categories = await cache.getCache(cacheKey).value;

      setCategories(categories);
    } catch (error) {
      $logger.error(error);
      setErrorNotification({ nuxtApp, error });
      setCategories(null);
    }
  }

  async function fetchCategoryById (id) {
    const { $api, $awt, $logger, $i18n } = nuxtApp;
    const postCode = deliveryStore.getPostCode;
    const cacheKey = ['category', id, $i18n.locale.value, awCacheKeyStore.cacheKeySegmentation || postCode];

    let is404Redirect = false;
    try {
      cache.initCache(cacheKey, () => $api.get(`/tree/${id}`, { awAxCache: AW_AX_CACHE_SEGMENTATION }));
      const result = await cache.getCache(cacheKey).value;

      if (result.status === 307) {
        if (result?.data?.message) {
          setErrorNotification({ nuxtApp, error: {
            response: {
              data: {
                title: result.data.message,
              },
            },
          } });
        }
        is404Redirect = true;
      }
      const newCategory = result.data;
      category.value = newCategory;
      setTreeId(newCategory?.id);
    } catch (error) {
      if (error?.response?.status === 404) {
        setErrorNotification({ nuxtApp, error: {
          response: {
            data: {
              title: $awt('aw.category.notfound'),
            },
          },
        } });
        is404Redirect = true;
      } else {
        setErrorNotification({ nuxtApp, error });
        $logger.error(error);
        throw error;
      }
    }
    if (is404Redirect) {
      throw createError({
        statusCode: 404,
        fatal: true,
      });
    }
  }

  async function fetchCategoryByIdSafe ({ categoryId: defaultCategoryId, inCategories }) {
    const { $api, $logger } = nuxtApp;

    try {
      const newCategory = await $api.$get(`/tree/${defaultCategoryId}`, { awAxCache: AW_AX_CACHE_SEGMENTATION });
      category.value = newCategory;
      setTreeId(newCategory?.id);
    } catch (e) {
      for (const currentCategoryId of inCategories) {
        try {
          const newCategory = await $api.$get(`/tree/${currentCategoryId}`, { awAxCache: AW_AX_CACHE_SEGMENTATION });
          category.value = newCategory;
          setTreeId(newCategory?.id);
          return;
        } catch (e) {
          $logger.error(e);
        }
      }
    }
  }

  function extractPromotionContentFromObject (parentObject) {
    return {
      type: parentObject?.promotionContentType,
      id: parentObject?.promotionContentId,
    };
  }

  function getCategoryFromTreeById ({ treeId }) {
    if (typeof treeId === 'number' && categories.value?.children) {
      return findByFunc(categories.value, ({ id }) => id === treeId, Infinity) || null;
    } else {
      return null;
    }
  }

  // getters
  const getCategoryFromTree = computed(() => {
    return getCategoryFromTreeById({ treeId: treeId.value });
  });


  const getPromotionContent = computed(() => {
    return extractPromotionContentFromObject(getCategoryFromTree.value || category.value);
  });

  const hasCategoryInTree = computed(() => { // bool when known, or null when not yet known
    return categories.value?.children ? Boolean(getCategoryFromTree) : null;
  });


  return {
    categories,
    category,
    treeId,
    categoryIdBeforeVisitProductPage,
    setCategories,
    setTreeId,
    setCategory,
    setCategoryIdBeforeVisitProductPage,
    fetchCategories,
    fetchCategoryById,
    fetchCategoryByIdSafe,
    extractPromotionContentFromObject,
    getPromotionContent,
    hasCategoryInTree,
    getCategoryFromTree,
    getCategoryFromTreeById,
  };
});

function findByFunc ({ children: arr }, searchFun, maxDepth, currentDepth = 0) {
  const result = arr.find(searchFun);
  if (!result && currentDepth <= maxDepth) {
    for (const arrChild of arr) {
      const subResult = findByFunc(arrChild, searchFun, maxDepth, currentDepth + 1);
      if (subResult) {
        return subResult;
      }
    }
  }
  return result;
}
