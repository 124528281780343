<template>
  <picture
    v-bind="awAttrsNoListeners"
    :class="[$style.picture, $style[`picture${type}`], $style[`pictureSizing${sizing}`], {[$style[`picture${type}AutoHeight`]]: autoHeight}]"
  >
    <source
      v-for="(source, index) in sourceData"
      :key="`src-${index}`"
      :media="source.media"
      :type="source.mimeType"
      :srcset="source.url"
    >
    <img
      ref="imgElement"
      v-aw-lazy-media
      :src="defaultImage"
      :alt="createAltTag"
      :loading="loading"
      :class="$style.image"
      v-bind="awListeners"
      @error="onImageError"
    >
  </picture>
</template>

<script>
  import awListenersMixin from '~~/common/mixins/awListenersMixin.js';
  import { onImageComplete, removeImageSourceTagOnError } from '~~/common/utils/imageHelper.js';

  const validImageTypes = ['', 'default', 'variant', 'brand', 'banner', 'text', 'youtube', 'store', 'category'];
  const validImageSizing = ['', 'cover', 'contain'];

  const ALT_EMPTY = Symbol('emptyAltTag');

  export default {
    name: 'LvImage',
    mixins: [awListenersMixin],
    inheritAttrs: false,
    props: {
      autoHeight: {
        type: Boolean,
        default: false,
      },
      sources: {
        type: Array,
        default () {
          return [];
        },
      },
      type: {
        type: String,
        default: 'default',
        validator: value => validImageTypes.includes(value),
      },
      sizing: {
        default: '',
        type: String,
        validator: value => validImageSizing.includes(value),
      },
      alt: {
        type: [Symbol, String],
        default: ALT_EMPTY,
      },
      loading: {
        type: String,
        default: 'lazy',
      },
    },
    emits: ['image-error'],
    data () {
      return {
        defaultImage: this.$appConfig.defaultImage,
        sourceData: [],
      };
    },
    computed: {
      createAltTag () {
        if (this.alt === ALT_EMPTY) {
          // For now this would be a too aggressive log, maybe later we should turn it on:
          // this.$logger.warn('@deprecated LvImage without an alt attribute. If you don\'t want an alt tag specify it as any empty string', import.meta.client ? this : this.$.uid);
          return '';
        }
        return this.alt;
      },
    },
    watch: {
      sources: {
        handler (newVal) {
          this.sourceData = newVal;
        },
        deep: true,
      },
      sourceData () {
        this.$forceUpdate();
      },
    },
    mounted () {
      this.sourceData = this.sources;
      onImageComplete(this.$refs?.imgElement, { onImageError: this.onImageError });
    },
    methods: {
      onImageError ({ target }) {
        this.$emit('image-error');
        if (target) {
          removeImageSourceTagOnError(target);
        }
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
$picture-background-color: $color-white !default;

.picture {
  font-size: 0;
  position: relative;
  display: inline-block;
  overflow: hidden;
  width: 100%;

  &default {
    background-color: $picture-background-color;

    .image {
      object-fit: $image-default-sizing;
      object-position: $image-default-alignment;
    }
  }

  &defaultAutoHeight {
    .image {
      position: relative;
      object-fit: $image-default-sizing;
      object-position: $image-default-alignment;
    }
  }

  &variant {
    display: block;
    padding-bottom: $image-variant-ratio;

    .image {
      object-fit: $image-variant-sizing;
      object-position: $image-variant-alignment;
    }
  }

  &brand {
    display: block;
    padding-bottom: 75%;
    background-color: $color-white-transparent;

    .image {
      object-position: center center;
      object-fit: contain;
    }
  }

  &category {
    display: block;
    background-color: $color-white-transparent;

    .image {
      object-fit: $image-variant-sizing;
      object-position: $image-variant-alignment;
    }
  }

  &youtube {
    display: block;
    padding-bottom: 56.25%;
    background-color: $color-white-transparent;
  }

  &store {
    display: block;
    padding-bottom: 75%;
    background-color: $color-white-transparent;

    .image {
      object-fit: cover;
    }
  }

  &banner {
    .image {
      object-fit: cover;
    }
  }

  &text {
    display: block;
    padding-bottom: 75%;

    .image {
      object-fit: cover;
      object-position: center center;
    }
  }

  &Sizing {
    &cover {
      .image {
        object-fit: cover;
      }
    }

    &contain {
      .image {
        object-fit: contain;
      }
    }
  }
}

.image {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  transition: opacity .256s ease;
}
</style>
