<template>
  <div :class="$style.errorPage">
    <lv-heading
      ref="titleElement"
      tag="h1"
      level="2"
      :trailing="true"
      align="center"
    >
      {{ $awt(errorCode === 404 ? 'aw.global.error.404.title' : 'aw.global.error.500.title') }}
    </lv-heading>
    <p :class="$style.errorPageMessage">
      {{ $awt(errorCode === 404 ? 'aw.global.error.404.description' : 'aw.global.error.500.description') }}
    </p>
    <img
      width="768"
      alt=""
      :src="errorCode === 404 ? image404 : errorGeneralImage"
      loading="lazy"
      :class="$style.errorPageImage"
    >
  </div>
</template>

<script setup>
  import { ref, onMounted } from 'vue';
  import { focusElement } from '~~/common/config/Navigation.js';
  import { LvHeading } from '~~/common/components/loginet-vue-shop/index.mjs';
  import errorGeneralImage from '~~/common/assets/images/error-general.svg';
  import image404 from '~~/common/assets/images/error404.svg';

  defineProps({
    errorCode: {
      type: Number,
      required: true,
    },
  });

  const titleElement = ref(null);

  onMounted(() => {
    focusElement(titleElement.value.$el);
  });
</script>

<style module lang="scss" rel="stylesheet/scss">
.errorPage {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  gap: 20px;

  &Message {
    width: 450px;
    max-width: 100%;
  }

  &Image {
    display: block;
    max-width: 100%;
  }
}
</style>
