<template>
  <div>
    <div
      :class="[
        $style.formInput,
        $style[inputSize],
        {[$style.formInputFocused]: isInputFocused || widgetAttrs.placeholder},
        {[$style.formInputError]: hasError},
        {[$style.formInputSuccess]: hasSuccess},
        {[$style.formInputLabeled]: hasLabel},
        {[$style.formInputCharWidth]: charWidth},
      ]"
      :style="{'--char-width': charWidth}"
    >
      <span
        v-if="widgetIconAttrs.name && !isIconAfter"
        :class="[$style.formInputIcon, $style.formInputIconBefore]"
      >
        <lv-icon v-bind="widgetIconAttrs" />
      </span>
      <div :class="[$style.formInputNode, $style.formInputNodePrefix, { [$style.formInputNodePrefixFilled]: widgetAttrs.prefixStyling && widgetAttrs.prefixStyling.filled }]">
        <!-- eslint-disable-next-line vue/singleline-html-element-content-newline -->
        <slot name="prefix">{{ widgetProps.prefix }}</slot>
      </div>
      <input
        ref="input"
        v-bind="attributes"
        :aria-invalid="isInvalid.toString()"
        :aria-required="isRequired.toString()"
        :aria-describedby="rowUniqueId ? rowUniqueId : null"
        :class="[$style.formInputNode, $style.formInputNodeInput]"
        data-input-block
        :type="type"
        :value="transformedModelValue"
        @blur="onBlur"
        @focus="isInputFocused = true"
        @input="onInput"
      >
      <div :class="[$style.formInputNode, $style.formInputNodePostfix, { [$style.formInputNodePostfixFilled]: widgetAttrs.postfixStyling && widgetAttrs.postfixStyling.filled }]">
        <!-- eslint-disable-next-line vue/singleline-html-element-content-newline -->
        <slot name="postfix">{{ widgetAttrs.postfix }}</slot>
      </div>
      <span
        v-if="widgetIconAttrs.name && isIconAfter"
        :class="[$style.formInputIcon, $style.formInputIconAfter]"
      >
        <lv-icon v-bind="widgetIconAttrs" />
      </span>
    </div>
  </div>
</template>

<script>
  import { useRuntimeConfig } from 'nuxt/app';
  import { nextTick } from 'vue';
  import { LvIcon } from '~~/common/components/loginet-vue-shop/index.mjs';
  import { useUserInterfaceStore } from '~~/common/stores/userInterface';

  const validSpecialTypes = ['priceAllowEmpty', 'price', 'tax'];

  export default {
    name: 'AwInput',
    components: {
      LvIcon,
    },
    inheritAttrs: false,
    props: {
      modelValue: {
        type: [String, Number],
        default: '',
      },
      type: {
        type: String,
        default: 'text',
      },
      inputSize: {
        type: String,
        default: 'lg',
        validator: value => ['xs', 'md', 'lg'].includes(value),
      },
      specialType: {
        type: String,
        default: null,
        validator: st => validSpecialTypes.includes(st),
      },
      min: {
        type: Number,
        default: null,
      },
      max: {
        type: Number,
        default: null,
      },
      step: {
        type: Number,
        default: null,
      },
      isFocused: {
        type: Boolean,
        default: false,
      },
      widgetProps: {
        type: Object,
        default () {
          return {};
        },
      },
      widgetAttrs: {
        type: Object,
        default () {
          return {};
        },
      },
      widgetIconAttrs: {
        type: Object,
        default () {
          return {};
        },
      },
      hasLabel: {
        type: Boolean,
        default: false,
      },
      hasError: {
        type: Boolean,
        default: false,
      },
      hasSuccess: {
        type: Boolean,
        default: false,
      },
      charWidth: {
        type: Number,
        default: 0,
      },
      isRequired: {
        type: Boolean,
        default: false,
      },
      isInvalid: {
        type: Boolean,
        default: false,
      },
      rowUniqueId: {
        type: String,
        default: '',
      },
    },
    emits: [
      'focus',
      'update:modelValue',
    ],
    data () {
      return {
        isInputFocused: this.isFocused,
      };
    },
    computed: {
      isIconAfter () {
        return this.widgetIconAttrs?.position === 'after';
      },
      attributes () {
        return {
          max: this.max,
          min: this.min,
          step: this.step,
          ...this.$attrs,
          class: null,
          style: null,
          ...this.widgetAttrs,
        };
      },
      getCurrency () {
        return {
          HU: 'HUF',
          PL: 'PLN',
        };
      },
      transformedModelValue () {
        const config = useRuntimeConfig();
        if (this.specialType === 'price' || (this.specialType === 'priceAllowEmpty' && this.modelValue !== '')) {
          return this.$price({ gross: this.modelValue }, this.getCurrency[config.public.appInstance]);
        }
        return this.modelValue;
      },
    },
    watch: {
      isFocused (value) {
        this.isInputFocused = value;
        if (value) {
          nextTick(() => {
            this.$refs.input.select();
          });
        }
      },
    },
    mounted () {
      if (document.activeElement === this.$refs.input || this.widgetAttrs.placeholder) {
        this.$emit('focus', {
          target: this.$refs.input,
        });
      }
    },
    methods: {
      onBlur ($event) {
        this.isInputFocused = false;
        if (this.specialType === 'price' || this.specialType === 'priceAllowEmpty') {
          const price = this.$priceToNumber($event.target.value);
          const newModelValue = (this.specialType === 'priceAllowEmpty' && $event.target.value === '')
            ? ''
            : (isNaN(price)
              ? (this.min || 0)
              : price
            )
          ;
          return this.$emit('update:modelValue', newModelValue);
        }
      },
      onInput ($event) {
        if (this.specialType === null) {
          return this.$emit('update:modelValue', $event.target.value);
        } else if (this.specialType === 'tax') {
          return this.$emit('update:modelValue', useUserInterfaceStore().hypenateTax($event.target.value));
        }
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
$input-sizes: (
  "xs": (
    "padding": 3px,
    "font-size": 10px,
    "line-height": 12px,
  ),
  "md": (
    "padding": 6px,
    "font-size": 12px,
    "line-height": 16px,
  ),
  "lg": (
    "padding": 15px,
    "font-size": 17px,
    "line-height": 24px,
  ),
);

.formInput {
  position: relative;
  display: flex;
  align-items: center;
  transition: all $animation-speed-fast $animation-timing-function;
  border: 1px solid $color-boulder;
  border-radius: 8px;
  background: $color-white;
  box-shadow: none;

  &Focused {
    box-shadow: 0 0 12px $color-mystic;
  }

  &Error {
    border: 1px solid $color-error-v1;
    box-shadow: 0 12px 16px $color-error-v1-light;
  }

  &Error &Icon {
    color: $color-error-v1;
  }

  &Success {
    border: 1px solid $color-success;
    box-shadow: 0 12px 16px $color-success-light;
  }

  &Success &Icon {
    color: $color-success;
  }

  &Node {
    display: block;
    border: none;
    border-radius: 8px;

    &Prefix,
    &Postfix {
      &:empty {
        display: none;
      }

      &Filled {
        background: $color-alto;
      }
    }

    &Prefix {
      padding-right: 4px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &Postfix {
      padding-left: 4px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &Input {
      flex-grow: 1;
      min-width: 0;
    }
  }

  &Labeled &Node {
    padding: 23px 15px 7px;
  }

  &IconBefore,
  &IconAfter {
    transition: all $animation-speed-fast $animation-timing-function;
    color: $color-boulder;
  }

  &IconBefore {
    padding-left: 15px;
  }

  &IconAfter {
    padding-right: 15px;
  }

  @each $size, $values in $input-sizes {
    $p: &;
    &.#{$size} {
      font-size: map-get($values, "font-size");
      line-height: map-get($values, "line-height");

      #{$p}Node {
        padding: map-get($values, "padding");
      }

      &#{$p}CharWidth #{$p}NodeInput {
        width: calc(2 * #{map-get($values, "padding") } + var(--char-width) * 0.57em);
      }
    }
  }
}
</style>
