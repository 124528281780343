import { defineNuxtRouteMiddleware, useNuxtApp } from 'nuxt/app';
import { extractResourceFromPath, resetPath } from '~~/common/utils/urlResource';
import { URL_PREFIX } from '~/awPlugins/app-config';
import { PCAT_ACCEPT, PUSH_COMPARE_PRODUCT } from '~~/common/plugins/aw-analytics.js';
import { useCategoryStore } from '~~/common/stores/category';
import { useCompareStore } from '~~/common/stores/compare';
import { useProductsStore } from '~~/common/stores/products';
import { useUserInterfaceStore } from '~~/common/stores/userInterface';

export default defineNuxtRouteMiddleware((to) => {
  if (!['dealer', 'default', 'dynamic-content'].includes(to.meta.layout)) {
    return;
  }

  const { $awAnalytics, $logger, $api, $cookies, $pinia } = useNuxtApp();
  const categoryStore = useCategoryStore($pinia);
  const compareStore = useCompareStore($pinia);
  const userInterfaceStore = useUserInterfaceStore($pinia);
  const productsStore = useProductsStore($pinia);

  (async () => {
    const { isSame } = userInterfaceStore.setRouteHistoryPathIfChanged({ fullPath: to.fullPath });
    if (isSame) {
      return;
    }

    let { resourceId, resourceType } = extractResourceFromPath(to.fullPath) || {};
    if (!resourceType) { // get data from server
      const validSlugPath = resetPath(to.path, URL_PREFIX);
      if (validSlugPath) {
        try {
          const data = await $api.$get('/url-resource', {
            params: {
              slug: validSlugPath || '404',
            },
          });
          resourceId = data?.resourceId;
          resourceType = data?.resourceType;
        } catch (e) {
          $logger.error(e);
        }
        if (!resourceId) { // other
          resourceId = null;
          resourceType = 'other';
        }
      } else { // other
        resourceId = null;
        resourceType = 'other';
      }
    }
    productsStore.setVisitedPage({ type: resourceType, id: resourceId, path: to.path });
    if (['search', 'category', 'shortlist', 'boutique'].includes(resourceType)) {
      const currentFilterableResource = JSON.stringify({ resourceType, resourceId });
      if (productsStore.lastFilterableResource !== currentFilterableResource) {
        productsStore.resetFilterBy();
      }
      productsStore.setLastFilterableResource(currentFilterableResource);
    } else if (resourceType === 'compare') {
      try {
        $awAnalytics[PUSH_COMPARE_PRODUCT]({ productCompareAnalyticsType: PCAT_ACCEPT, productsArr: compareStore.compareItems });
      } catch (err) {
        $logger.error(err);
      }
    }

    if (resourceType !== 'category') {
      // Original commit sets category as null for whatever reason:
      // https://git.loginet.hu/auchan-webshop/auchan-vue-client/-/merge_requests/344/diffs?commit_id=e929db96213d73bb9578f15479055a47594a65a2
      // category will handle the correct id for us
      categoryStore.setCategory(null);
      categoryStore.setTreeId(null);
    }
  })();
  // remove alcohol NO confirm cookie if, navigate outside from product list page
  $cookies.remove('under18');
});
