<template>
  <div :class="$style.productCetelem">
    <lv-heading tag="h3" level="3" version2>
      <span
        v-text="(creditTypes.length === 1
          ? $awt(
            'aw.product_cetelem.title.single',
            {
              creditName: creditTypes[0]?.baremName,
            }
          )
          : $awt('aw.product_cetelem.title.multiple')
        )"
      />
    </lv-heading>
    <aw-radio-group-version2
      v-if="creditTypes.length > 1"
      v-model="baremIdx"
      row
      row-space="md"
      column-space="none"
      :legend-text="$awt('aw.product_cetelem.legend')"
    >
      <aw-radio-chooser-version2
        v-for="(creditType, i) in creditTypes"
        :key="i"
        label-size="md"
        input-width="lg"
        :value="i"
      >
        <template #label>
          {{ creditType.baremName }}
        </template>
      </aw-radio-chooser-version2>
    </aw-radio-group-version2>
    <aw-cetelem-block
      v-if="baremId"
      :key="baremId"
      :cetelem-barem-id="baremId"
      :purchase-amount="purchaseAmount"
      token-base="aw.cetelem.product_page"
    />
  </div>
</template>

<script setup>
  import { defineAsyncComponent } from 'vue';
  import { LvHeading } from '~~/common/components/loginet-vue-shop/index.mjs';
  import AwRadioGroupVersion2 from '~~/common/components/Common/Widgets/AwRadioGroupVersion2.vue';
  import AwRadioChooserVersion2 from '~~/common/components/Common/Widgets/AwRadioChooserVersion2.vue';
  const AwCetelemBlock = defineAsyncComponent(() => import('~~/common/components/Page/Common/AwCetelemBlock.vue'));

  const props = defineProps({
    creditTypes: {
      type: Array,
      required: true,
      default: () => [],
    },
    purchaseAmount: {
      type: Number,
      required: true,
      default: 0,
    },
  });
  const baremIdx = ref(null);
  const baremId = computed(() => {
    const arrIdx = (props.creditTypes?.length > 1
      ? baremIdx.value
      : 0
    );
    return props.creditTypes[arrIdx]?.baremId;
  });
</script>

<style module lang="scss" rel="stylesheet/scss">
.productCetelem {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 20px;

  @include tablet(min) {
    gap: 40px;
    padding-top: 0;
  }
}
</style>
