<template>
  <div :class="[$style.mainContainer, {[$style.disabled]: disabled}]">
    <label
      v-bind="labelAttrs"
      :class="[$style.mainLabel, {[$style.boldText]: checked}, $style[`align-items-${alignItems}`], {[$style.shrink]: shrink || (!label && !$slots.label?.()?.[0]?.children?.length)}]"
    >
      <input
        type="checkbox"
        v-bind="widgetAttrs"
        :disabled="disabled"
        :checked="checked"
        :value="value"
        :aria-describedby="rowUniqueId ? rowUniqueId : null"
        @change="onChange"
        @blur="$emit('blur', $event)"
      >
      <lv-icon
        :key="`icon-${checked}`"
        :class="[$style.icon]"
        :name="checked ? checkedIcon : uncheckedIcon"
        :size="24"
        :fill="false"
      />
      <span :class="[$style.labelText, $style[labelSize], {[$style.labelHide]: !label && !$slots.label?.()?.[0]?.children?.length}]">
        <slot v-if="$slots.label?.()?.[0]?.children?.length" name="label" />
        <template v-else-if="label">
          <span v-html="label" />
        </template>
      </span>
    </label>
  </div>
</template>

<script>
  import { LvIcon } from '~~/common/components/loginet-vue-shop/index.mjs';

  export default {
    name: 'AwCheckbox',
    components: {
      LvIcon,
    },
    props: {
      modelValue: {
        type: [Array, Object, String, Number, Boolean],
        default: () => [],
      },
      value: {
        type: [Object, String, Number],
        default: () => {
        },
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      label: {
        type: String,
        default: '',
      },
      labelSize: {
        type: String,
        default: 'lg',
        validator: value => ['xs', 'md', 'lg'].includes(value),
      },
      alignItems: {
        type: String,
        default: 'center',
        validator: alignItems => ['start', 'center', 'end'].includes(alignItems),
      },
      radioInputIconDesign: {
        type: Boolean,
        default: false,
      },
      shrink: {
        type: Boolean,
        default: false,
      },
      rowUniqueId: {
        type: String,
        default: '',
      },
      labelAttrs: {
        type: Object,
        default () {
          return {};
        },
      },
      widgetAttrs: {
        type: Object,
        default () {
          return {};
        },
      },
    },
    emits: [
      'blur',
      'update:modelValue',
    ],
    computed: {
      checked () {
        let checked = this.modelValue;
        if (this.value) {
          if (Array.isArray(this.modelValue)) {
            // array items is object?
            if (typeof this.value === 'object') {
              checked = this.modelValue.some(item => JSON.stringify(item) === JSON.stringify(this.value));
            } else {
              checked = this.modelValue.includes(this.value);
            }
          } else {
            checked = this.value === this.modelValue;
          }
        }
        return checked;
      },
      checkedIcon () {
        return (this.radioInputIconDesign)
          ? (this.disabled ? 'radio-checked-disabled-24' : 'radio-checked-24')
          : (this.disabled ? 'checkbox-checked-disabled-24' : 'checkbox-checked-24');
      },
      uncheckedIcon () {
        return (this.radioInputIconDesign)
          ? (this.disabled ? 'radio-unchecked-disabled-24' : 'radio-unchecked-24')
          : (this.disabled ? 'checkbox-unchecked-disabled-24' : 'checkbox-unchecked-24');
      },
    },
    methods: {
      onChange ($event) {
        if (this.value) {
          // check modelValue is array
          if (Array.isArray(this.modelValue)) {
            // create new array instance from prop array
            const modelArray = [].concat(this.modelValue);
            const index = modelArray.findIndex(item => JSON.stringify(item) === JSON.stringify(this.value));
            if (index !== -1) {
              modelArray.splice(index, 1);
            } else {
              modelArray.push(this.value);
            }
            this.$emit('update:modelValue', modelArray);
          } else {
            this.checked ? this.$emit('update:modelValue', '') : this.$emit('update:modelValue', this.value);
          }
        } else {
          this.$emit('update:modelValue', $event.target.checked);
        }
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
.mainContainer {
  .mainLabel {
    display: flex;
    cursor: pointer;
    user-select: none;

    &.shrink {
      display: inline-flex;
    }

    &.align-items-start {
      align-items: flex-start;
    }

    &.align-items-center {
      align-items: center;
    }

    &.align-items-end {
      align-items: flex-end;
    }

    &.boldText {
      * {
        font-weight: $font-weight-bold;
      }
    }

    .labelText {
      width: calc(100% - 32px);

      &.xs {
        font-size: $font-size-lg;
      }

      &.md {
        font-size: $font-size-md;
      }

      &.lg {
        font-size: $font-size-xs;
      }

      &.labelHide {
        display: none;
      }
    }
  }

  input {
    appearance: none;
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0.01;
  }

  .icon {
    stroke: none;
    margin-right: 8px;
  }

  &.disabled {
    cursor: not-allowed;
  }
}

.formCheckbox {
  opacity: 0.0001;
}

</style>
