<template>
  <div :class="$style.elementContainer">
    <slot v-if="isVisible === null ? initialVisibility : isVisible" />
  </div>
</template>

<script>
  import { nextTick } from 'vue';

  export default {
    name: 'AwIfVisible',

    props: {
      rootMargin: {
        type: [String, Function],
        required: true,
      },
      setWidth: {
        type: Boolean,
        default: true,
      },
      setHeight: {
        type: Boolean,
        default: true,
      },
      initialVisibility: {
        type: Boolean,
        default: true,
      },
    },

    data () {
      return {
        isVisible: null,
        observer: null,
      };
    },

    mounted () {
      let rootMargin;

      if (typeof this.rootMargin === 'function') {
        rootMargin = this.rootMargin(window);
      } else {
        rootMargin = this.rootMargin;
      }

      this.observer = new IntersectionObserver((entries) => {
        // WARNING: please avoid layout trashing.
        // Especially here.
        for (const entry of entries) {
          const {
            target: content,
            boundingClientRect,
          } = entry;

          if (!entry.isIntersecting) {
            if (this.setWidth) {
              content.style.minWidth = boundingClientRect.width + 'px';
            }
            if (this.setHeight) {
              content.style.minHeight = boundingClientRect.height + 'px';
            }
          } else {
            if (this.setWidth) {
              content.style.removeProperty('min-width');
            }
            if (this.setHeight) {
              content.style.removeProperty('min-height');
            }
          }

          nextTick(() => {
            this.isVisible = entry.isIntersecting;
          });
        }
      }, {
        root: null,
        rootMargin,
      });

      this.observer.observe(this.$el);
    },

    beforeUnmount () {
      this.observer?.disconnect();
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
.elementContainer {
  width: 100%;
}
</style>
