import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useAwCacheKeyStore = defineStore('awCacheKey', () => {
  const cacheKeyLegacy = ref('');
  const cacheKeySegmentation = ref('');
  function setCacheKeyLegacy (payload) {
    cacheKeyLegacy.value = payload;
  }
  function setCacheKeySegmentation (payload) {
    cacheKeySegmentation.value = payload;
  }
  return {
    cacheKeyLegacy,
    cacheKeySegmentation,
    setCacheKeyLegacy,
    setCacheKeySegmentation,
  };
});
