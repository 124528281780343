<template>
  <div :class="$style.awNotFoundErrorPage">
    <lv-heading tag="h1" level="1" version2>
      <span v-html="$awt('aw.404page.heading')" />
    </lv-heading>
    <div :class="$style.birdySection">
      <img
        :src="illustration404"
        alt=""
        loading="lazy"
        width="192"
        height="192"
        :class="$style.birdy"
      >
    </div>
    <div :class="$style.descriptionSection">
      <div v-html="$awt('aw.404page.description')" />
      <lv-button
        shrink
        styling="primary-dark"
        :href="toHomePageUrl"
        :loading-id="componentId"
        @click.prevent="navigateAway({ redirect: toHomePageUrl })"
      >
        <span v-text="$awt('aw.404page.cta_0.text')" />
      </lv-button>
    </div>
    <div :class="$style.recommendationSection">
      <aw-recommendation-module :class="$style.recommendations" />
    </div>
  </div>
</template>

<script setup>
  import { onUnmounted, defineAsyncComponent } from 'vue';
  import { useId, useNuxtApp, navigateTo } from 'nuxt/app';
  import { useUserInterfaceStore } from '~~/common/stores/userInterface.js';
  import illustration404 from '~~/common/assets/images/illustration-404.svg';
  import {
    LvHeading,
    LvButton,
  } from '~~/common/components/loginet-vue-shop/index.mjs';
  const AwRecommendationModule = defineAsyncComponent(() => import('~~/common/components/Page/404/AwRecommendationModule.vue'));

  const { $logger, $localePath, $awt } = useNuxtApp();
  const { startLoading, endLoading } = useUserInterfaceStore();
  const componentId = useId();
  const toHomePageUrl = computed(() => $localePath($awt('aw.404page.cta_0.href')));

  async function navigateAway ({ redirect }) {
    try {
      startLoading({ id: componentId });
      await navigateTo(redirect);
    } catch (err) {
      $logger.error(err);
      endLoading({ id: componentId });
    }
  }
  onUnmounted(() => {
    endLoading({ id: componentId });
  });
</script>

<style module lang="scss" rel="stylesheet/scss">
.awNotFoundErrorPage {
  --awNotFoundErrorPageSidePadding: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px var(--awNotFoundErrorPageSidePadding);
  text-align: center;
  gap: 40px;

  @include tablet(min) {
    display: grid;
    padding: 80px var(--awNotFoundErrorPageSidePadding) 60px;
    text-align: left;
    grid-template-columns: 1fr 235px;
    gap: 0 24px;

    @include tablet(max) {
      --awNotFoundErrorPageSidePadding: 12px;
    }
  }

  @include desktop-small(min) {
    grid-template-columns: 1fr 358px;
    gap: 0 32px;
  }

  @include desktop-medium(min) {
    grid-template-columns: 1fr 440px;
    gap: 0 40px;
  }

  @include desktop-large(min) {
    grid-template-columns: 1fr 552px;
  }
}

.birdySection {
  grid-row: span 2;
  justify-self: center;
}

.birdy {
  --height: 192px;
  width: var(--height);
  height: var(--height);

  @include desktop-small(min) {
    --height: 256px;
  }

  @include desktop-large(min) {
    --height: 320px;
  }
}

.descriptionSection {
  @include font(500, 14px, 20px);
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;

  @include tablet(min) {
    align-items: start;
    gap: 40px;
    padding-top: 20px;
  }

  @include desktop-small(min) {
    @include font(null, 16px, 24px);
  }

  @include desktop-large(min) {
    padding-top: 40px;
  }
}

.recommendationSection {
  display: flex;
  margin: 0 -16px;
  text-align: left;
  grid-column: 1 / -1;

  @include tablet(min) {
    margin: 0 calc(-1 * var(--awNotFoundErrorPageSidePadding));
    padding-top: 120px;
  }
}

.recommendations {
  flex-grow: 1;
}
</style>
