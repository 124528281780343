import { defineStore } from 'pinia';
import { useNuxtApp, useRouter } from 'nuxt/app';
import { useCategoryStore } from '~~/common/stores/category';
import { useCompareStore } from '~~/common/stores/compare';
import { useConfigStore } from '~~/common/stores/config';
import { useUserStore } from '~~/common/stores/user';
import { useUserInterfaceStore } from '~~/common/stores/userInterface';
import { useTranslationStore } from '~~/common/stores/translation';
import { useDeliveryStore } from '~~/shop/stores/delivery';
import { extractResourceFromPath } from '~~/common/utils/urlResource.js';
import { useAuthenticationStore } from '~~/common/stores/authentication.js';
import { ecomShopPages } from '~~/common/config/EcomPagesConfig.js';
import { useNotificationBarStore } from '~~/shop/stores/notificationBar.js';
import * as loginTypes from '~~/common/config/LoginTypeConfig.js';
import { LT_ANON } from '~~/common/config/LoginTypeConfig.js';

export const useRootStore = defineStore('rootStore', () => {
  const nuxtApp = useNuxtApp();
  const router = useRouter();

  async function nuxtServerInit () {
    const configStore = useConfigStore();
    const categoryStore = useCategoryStore();
    const compareStore = useCompareStore();
    const userStore = useUserStore();
    const deliveryStore = useDeliveryStore();
    const translationStore = useTranslationStore();
    const authenticationStore = useAuthenticationStore();
    const notificationBarStore = useNotificationBarStore();
    const {
      $api,
      $i18n,
      $logger,
      $cookies,
    } = nuxtApp;

    const accessToken = $cookies.get('access_token');
    const refreshToken = $cookies.get('refresh_token');
    let loginType = $cookies.get('login_type');

    if (loginType && Object.values(loginTypes).includes(loginType)) {
      userStore.setLoginType(loginType);
    } else if (accessToken) {
      try {
        const loginTypeResponse = await $api.$get('token/login_type');
        userStore.setLoginType(loginTypeResponse.login_type);
        $cookies.set('login_type', loginTypeResponse.login_type, {
          path: '/',
          maxAge: loginTypeResponse.expires_in,
          sameSite: 'lax',
        });
      } catch (error) {
        $logger.error(error);
      }
    } else {
      userStore.setLoginType(LT_ANON);
      $cookies.set('login_type', LT_ANON, {
        path: '/',
        sameSite: 'lax',
      });
    }

    const selectedPostCode = $cookies.get('selected_postcode');
    const loggerHandler = (err) => {
      $logger.error(err);
    };
    const categoryTreeNeeded = () => {
      try {
        const resource = extractResourceFromPath(router.currentRoute.value.fullPath);
        const isEcomPage = ecomShopPages.includes(router.currentRoute.value?.name);
        return isEcomPage || resource;
      } catch (error) {
        $logger.error(error);
        return false;
      }
    };
    if (!accessToken && refreshToken) {
      await authenticationStore.loginWithRefreshToken();
    }

    if (selectedPostCode && !accessToken) {
      if (refreshToken) {
        await authenticationStore.loginWithRefreshToken();
      } else {
        await authenticationStore.loginAnonymous();
      }
    }

    if (selectedPostCode || accessToken) {
      await deliveryStore.fetchMethod().catch(loggerHandler);
    }

    await Promise.all([
      // todo meg kell nézni, hogy van-e valami nuxt cache-elési lehetőség, de ez csak később
      translationStore.fetchTranslations({ localeCode: $i18n.locale.value }).catch(loggerHandler),
      configStore.fetchConfigCommon().catch(loggerHandler),
      categoryTreeNeeded() ? categoryStore.fetchCategories().catch(loggerHandler) : Promise.resolve(),
      accessToken ? userStore.fetchUser({ refreshData: false }).catch(loggerHandler) : Promise.resolve(),
      notificationBarStore.fetchNotifications().catch(loggerHandler),
    ]);

    useUserInterfaceStore().setTimeZoneOffsetInHours();

    compareStore.setIsComparePage(false);

    if (selectedPostCode && selectedPostCode?.type && selectedPostCode?.areaId && !selectedPostCode.closed && !deliveryStore.setup) {
      await deliveryStore.pushMethod(selectedPostCode).catch(loggerHandler);
    }

    if (import.meta.server) {
      global.storeHelper = global?.storeHelper || {};
      global.storeHelper.cdExternalId = useUserStore()?.data?.personalInfo?.cdExternalId;
      global.storeHelper.isEcomUser = useUserStore()?.isEcomUser;
      global.storeHelper.locale = $i18n.locale.value;
    }
  }

  async function clientInit () {
  }

  return {
    nuxtServerInit,
    clientInit,
  };
});
