export const LOGGED_OUT_TOASTER_HEADER = 'logged_out_toaster_header';

export const LOGGED_OUT_TOASTER_TIMEFRAME_MODAL = 'logged_out_toaster_timeframe_modal';

export const LOGGED_OUT_TOASTER_CHECKOUT = 'logged_out_toaster_checkout';

export const LOGGED_OUT_TOASTER_PROFILE_EMPTY = 'logged_out_toaster_profile_empty';

export const LOGGED_OUT_TOASTER_FAVOURITE_LOGIN = 'logged_out_toaster_favourite_login';

export const LOGGED_OUT_TOASTER_FAVOURITE_REGISTER = 'logged_out_toaster_favourite_register';

export const LOGGED_OUT_TOASTER_PRIZE_GAME = 'logged_out_toaster_prize_game';

export const LOGGED_OUT_TOASTER_PROFILE_FORM = 'logged_out_toaster_profile_form';

export const LOGGED_OUT_TOASTER_CAREER_LOGIN = 'logged_out_toaster_career_login';
