<template>
  <lv-icon
    name="star-version2-32"
    :size="size"
    :class="[
      $style.star,
      $style[`star${percent}`],
      {
        [$style.borderedStar]: bordered
      },
    ]"
  />
</template>

<script setup>
  import { LvIcon } from '~~/common/components/loginet-vue-shop/index.mjs';

  defineProps({
    percent: {
      type: Number,
      required: true,
      validator: v => validPercents.includes(v),
    },
    size: {
      type: Number,
      required: false,
      default: 32,
    },
    bordered: {
      type: Boolean,
      required: false,
      default: false,
    },
  });
</script>

<script>
  export const validPercents = [0, 25, 50, 75, 100];
</script>

<style module lang="scss" rel="stylesheet/scss">
.star {
  color: $color-cloud;

  &0 {
    /*noop*/
  }

  &25 {
    --color2: #{$color-marigold-300};
  }

  &50 {
    --color3: #{$color-marigold-300};
  }

  &75 {
    --color4: #{$color-marigold-300};
  }

  &100 {
    color: $color-marigold-300;
  }
}

.borderedStar.star {
  --colorStroke: #{$color-olive-750};

  &100 {
    --colorStroke: #{$color-marigold-200};
  }
}
</style>
