<template>
  <component
    :is="checkTag"
    :to="path"
    :href="href"
    :class="[$style.banner, $style[styling]]"
  >
    <div :class="[$style.bannerContainer, $style.bannerFlexItemExpand]">
      <lv-image
        :class="$style.bannerPicture"
        type="banner"
        :sources="resources"
        :alt="altAttr"
      />
      <div :class="[$style.bannerContent, {[$style[`bannerContent${size}`]]: size != null}, $style[`banner${position}`]]">
        <div :class="[$style.bannerContentInner, [position.startsWith('Stretch') ? $style.bannerContentInnerStretch : null], {[$style.bannerContentInnerGrow]: position === 'Grow'}]">
          <slot name="content" :content="content">
            [BANNER CONTENT]
          </slot>
        </div>
      </div>
    </div>
  </component>
</template>

<script>
  import { LvButton, LvImage, LvLink } from '~~/common/components/loginet-vue-shop/index.mjs';

  const validPositions = ['CenterCenter', 'CenterLeft', 'TopRight', 'TopLeft', 'StretchLeft', 'BottomRight', 'BottomLeft', 'BottomCenter', 'StretchCenter', 'Grow'];

  export default {
    name: 'AwBannerContent',
    components: {
      LvImage,
      LvButton,
      LvLink,
    },
    props: {
      content: {
        type: Object,
        required: true,
      },
      position: {
        type: String,
        default: 'CenterCenter',
        validator: position => validPositions.includes(position),
      },
      size: {
        type: String,
        default: 'md',
      },
      translation: {
        type: Object,
        default () {
          return {};
        },
      },
      styling: {
        type: String,
        default: 'primary',
      },
    },
    computed: {
      isWholeRouterLink () {
        return this.content.urls.length && !this.translation.ctaText;
      },
      checkTag () {
        if (this.translation.ctaText) {
          return 'div';
        } else {
          return this.content.urls.length ? 'lv-link' : this.content.externalUrl ? 'a' : 'div';
        }
      },
      altAttr () {
        return this.resources.find(r => r?.alt)?.alt || '';
      },
      resources () {
        return this.content.image?.resources;
      },
      path () {
        if (this.isWholeRouterLink && this.$path) {
          return this.$path(this.content, null);
        } else {
          return undefined;
        }
      },
      href () {
        if (this.isWholeRouterLink) {
          return undefined;
        } else {
          return this.content.externalUrl;
        }
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
$banner-aspect-ratio: percentage(math.div(2, 3)) !default;

$banner-sizes: (
  "lg": 500px,
  "md": 300px,
);
$banner-styling: (
  "primary": (
    "picture-height": 100%,
    "object-fit": cover,
    "padding": 30px,
    "padding-md": null,
    "max-width": percentage(math.div(1, 1)),
    "max-width-md": percentage(math.div(3, 4)),
    "max-width-lg": percentage(math.div(2, 3)),
  ),
  "secondary": (
    "picture-height": 100%,
    "object-fit": cover,
    "padding": 8px 8px 32px,
    "padding-md": 24px 30px 30px,
    "max-width": null,
    "max-width-md": null,
    "max-width-lg": null,
  ),
  "tertiary": (
    "picture-height": 50%,
    "object-fit": contain,
    "padding": 8px 8px 32px,
    "padding-md": 24px 30px 30px,
    "max-width": null,
    "max-width-md": null,
    "max-width-lg": null,
  ),
  "quarternary": (
    "picture-height": 100%,
    "object-fit": cover,
    "padding": 16px 24px 24px,
    "padding-md": 24px 30px 30px,
    "max-width": null,
    "max-width-md": null,
    "max-width-lg": null,
  ),
);

@each $sn, $s in $banner-styling {
  .banner.#{$sn} {
    .banner {
      &Content {

        @include md {
          padding: map-get($s, padding-md);
        }

        padding: map-get($s, padding);
      }

      &ContentInner {

        @include md() {
          max-width: map-get($s, max-width-md);
        }

        @include lg() {
          max-width: map-get($s, max-width-lg);
        }

        max-width: map-get($s, max-width);
      }

      &Picture {
        height: map-get($s, picture-height);

        @include md() {
          height: 75%;
        }

        @include lg() {
          height: map-get($s, picture-height)
        }

        img {
          object-fit: map-get($s, object-fit);
        }
      }
    }
  }
}

.banner {
  display: flex;
  overflow: hidden;
  flex: 0 0 100%;
  max-width: 100%;
  height: 100%;

  &Container {
    position: relative;
    max-width: 100%;
  }

  &Picture {
    img {
      height: 100%;
    }

    &,
    img {
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
    }
  }

  &Content {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;

    &%bef {
      display: block;
      flex: 0;
      width: 0;
      content: " ";
    }

    @each $sizename, $size in $banner-sizes {
      &#{$sizename} {
        &::before {
          @extend %bef;
          height: $size;
        }
      }
    }

    &2\/3::before {
      @extend %bef;
      padding-bottom: $banner-aspect-ratio;
    }

    &Inner {
      &%stretch {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: space-between;
      }

      &Grow {
        @extend %stretch;
        flex-grow: 1;
      }

      &Stretch {
        @extend %stretch;
      }
    }
  }

  &CenterCenter {
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  &CenterLeft {
    align-items: center;
    justify-content: flex-start;
    text-align: left;
  }

  &TopRight {
    align-items: flex-start;
    justify-content: flex-end;
    text-align: left;
  }

  &TopLeft {
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
  }

  &StretchLeft {
    align-items: stretch;
    justify-content: flex-start;
    text-align: left;
  }

  &BottomCenter {
    align-items: flex-end;
    justify-content: center;
    text-align: center;
  }

  &BottomRight {
    align-items: flex-end;
    justify-content: flex-end;
    text-align: left;
  }

  &BottomLeft {
    align-items: flex-end;
    justify-content: flex-start;
    text-align: left;
  }

  &StretchCenter {
    align-items: stretch;
    justify-content: center;
    text-align: left;
  }

  &FlexItemExpand {
    flex: 1;
  }

}
</style>
