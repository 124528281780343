import { defineStore } from 'pinia';
import { useNuxtApp } from 'nuxt/app';
import { ref } from 'vue';
import { setErrorNotification } from '~~/common/utils/helper';
import { useUserStore } from '~~/common/stores/user';

export const useGdprStore = defineStore('gdpr', () => {
  const userStore = useUserStore();
  const nuxtApp = useNuxtApp();

  const acceptance = ref(null);

  async function fetchAcceptance () {
    const {
      $api,
      $logger,
    } = nuxtApp;

    if (userStore.isEcomUser) {
      try {
        acceptance.value = await $api.$get('/me/gdpr/acceptance');
      } catch (error) {
        $logger.error(error);
        setErrorNotification({ nuxtApp, error });
      }
    }
  }

  async function updateAcceptance () {
    const {
      $api,
      $logger,
    } = nuxtApp;

    try {
      await $api.$post('/me/gdpr/acceptance', {});
    } catch (error) {
      $logger.error(error);
      setErrorNotification({ nuxtApp, error });
    }
  }

  return {
    acceptance,
    fetchAcceptance,
    updateAcceptance,
  };
});
