import { defineNuxtRouteMiddleware, navigateTo, useNuxtApp, useRequestEvent, useError } from 'nuxt/app';
import globalMiddlewareHeaders from '~~/common/server/globalMiddlewareHeaders.js';
import { extractResourceFromPath } from '~~/common/utils/urlResource.js';
import { getSeoModuleFromUrlResource } from '~~/common/utils/seoModule.js';
import { useNotificationStore } from '~~/common/stores/notification';
import { useSeoStore } from '~~/common/stores/seo';
import { useUserStore } from '~~/common/stores/user';
import { COOKIE_TOKEN_EXPIRED, handleRedirectAfterTokenExpired } from '~~/common/server/globalMiddlewareContans.js';
import { useUrlResourceStore } from '~~/common/stores/urlResource.js';

const automaticSeoRoutesBlacklist = [
  /^\/$/,
  /^\/admin\/cms\/content_preview\//,
];
export default defineNuxtRouteMiddleware(async (to) => {
  const {
    $awt,
    $logger,
    $cookies,
    $pinia,
    $i18n,
  } = useNuxtApp();
  const res = useRequestEvent()?.node?.res || {};

  const notificationStore = useNotificationStore($pinia);
  const userStore = useUserStore($pinia);
  const seoStore = useSeoStore($pinia);
  const urlResourceStore = useUrlResourceStore($pinia);

  // NOTE: to.meta.layoutTransition has to be disabled for the error page.
  // It caused a layout duplication error when navigating to the error page
  // on the client side.
  if (useError().value) {
    to.meta.layoutTransition = false;
  }

  if (typeof res?.setHeader === 'function') {
    if (typeof globalMiddlewareHeaders === 'object') {
      for (const headerKey in globalMiddlewareHeaders) {
        if (globalMiddlewareHeaders[headerKey] === null) {
          res.removeHeader(headerKey);
        } else {
          res.setHeader(headerKey, globalMiddlewareHeaders[headerKey]);
        }
      }
    }
  }

  if (userStore.forceLogoutToRedirect) {
    handleRedirectAfterTokenExpired($i18n, $cookies, navigateTo);
  } else if ($cookies.get(COOKIE_TOKEN_EXPIRED)) {
    $cookies.remove(COOKIE_TOKEN_EXPIRED);
    notificationStore.pushError({
      text: {
        title: $awt('aw.token_expired'),
      },
    });
  }
  try {
    if (!automaticSeoRoutesBlacklist.some((t) => {
      const langlessPath = to.path.replace(/^(\/hu|\/en|\/pl)($|\/)/, '/');
      return t.test(langlessPath);
    })) {
      const urlResource = extractResourceFromPath(to.fullPath);
      const seoModule = getSeoModuleFromUrlResource(urlResource, urlResourceStore, to);
      await seoStore.fetchSeoData(seoModule);
    }
  } catch (err) {
    $logger.error(err);
  }
});
