import { default as _91_46_46_46slug_93CnGEUccaEVMeta } from "/builds/auchan-webshop/auchan-vue-client/shop/pages/[...slug].vue?macro=true";
import { default as _91hash_939LssDLvF2dMeta } from "/builds/auchan-webshop/auchan-vue-client/shop/pages/activate_career_registration/[hash].vue?macro=true";
import { default as _91hash_93W6M2H7UycBMeta } from "/builds/auchan-webshop/auchan-vue-client/shop/pages/activate_registration/[hash].vue?macro=true";
import { default as _91hash_935FkbKzyJumMeta } from "/builds/auchan-webshop/auchan-vue-client/shop/pages/admin/cms/content_preview/[hash].vue?macro=true";
import { default as indexHw3mNPiUJUMeta } from "/builds/auchan-webshop/auchan-vue-client/shop/pages/ajandekkartya-igenyles/index.vue?macro=true";
import { default as indexh9t8EE0wejMeta } from "/builds/auchan-webshop/auchan-vue-client/shop/pages/bm-payment-return/index.vue?macro=true";
import { default as _91hash_93620IPySYdQMeta } from "/builds/auchan-webshop/auchan-vue-client/shop/pages/career_password_change/[hash].vue?macro=true";
import { default as _91slug_931x8MXj2efwMeta } from "/builds/auchan-webshop/auchan-vue-client/shop/pages/catalog/[slug].vue?macro=true";
import { default as index6lqO7wcwQzMeta } from "/builds/auchan-webshop/auchan-vue-client/shop/pages/catalog/index.vue?macro=true";
import { default as _91status_937JehcpDdM5Meta } from "/builds/auchan-webshop/auchan-vue-client/shop/pages/cetelem/back/[order_ident]/[status].vue?macro=true";
import { default as indexLjPlw24FUcMeta } from "/builds/auchan-webshop/auchan-vue-client/shop/pages/cetelem/back/index.vue?macro=true";
import { default as _91id_93K1u3taZVxGMeta } from "/builds/auchan-webshop/auchan-vue-client/shop/pages/content/[id].vue?macro=true";
import { default as indexyTuJAy6PzWMeta } from "/builds/auchan-webshop/auchan-vue-client/shop/pages/csr/index.vue?macro=true";
import { default as indexVjrDLw9WVZMeta } from "/builds/auchan-webshop/auchan-vue-client/shop/pages/faq/index.vue?macro=true";
import { default as index10n52CypFxMeta } from "/builds/auchan-webshop/auchan-vue-client/shop/pages/food-delivery-map/index.vue?macro=true";
import { default as index2NAKbLEYepMeta } from "/builds/auchan-webshop/auchan-vue-client/shop/pages/forgotten-password/index.vue?macro=true";
import { default as indexS6ZlsvNoiEMeta } from "/builds/auchan-webshop/auchan-vue-client/shop/pages/index.vue?macro=true";
import { default as indexoln1fpJS2RMeta } from "/builds/auchan-webshop/auchan-vue-client/shop/pages/inspirations/index.vue?macro=true";
import { default as _91offerId_93CWsbRD9lPCMeta } from "/builds/auchan-webshop/auchan-vue-client/shop/pages/karrier/ajanlatok/[offerId].vue?macro=true";
import { default as _91hash_93VvTjzwZDZyMeta } from "/builds/auchan-webshop/auchan-vue-client/shop/pages/karrier/allas/[slug].j-[id]/[hash].vue?macro=true";
import { default as indexbyQjBZTRReMeta } from "/builds/auchan-webshop/auchan-vue-client/shop/pages/karrier/allas/[slug].j-[id]/index.vue?macro=true";
import { default as _91_91premise_93_93wUlRw5LPqZMeta } from "/builds/auchan-webshop/auchan-vue-client/shop/pages/karrier/allasok/[[premise]].vue?macro=true";
import { default as indexTNVNvEpdQSMeta } from "/builds/auchan-webshop/auchan-vue-client/shop/pages/karrier/fiokom/index.vue?macro=true";
import { default as indexFxFxosEbbaMeta } from "/builds/auchan-webshop/auchan-vue-client/shop/pages/karrier/hirek/index.vue?macro=true";
import { default as Salesforce98in5OimMNMeta } from "/builds/auchan-webshop/auchan-vue-client/shop/pages/login/Salesforce.vue?macro=true";
import { default as indexFSlhQvp53dMeta } from "/builds/auchan-webshop/auchan-vue-client/shop/pages/loyalty-program/faq/index.vue?macro=true";
import { default as _91slug_93o0BUWPwS6OMeta } from "/builds/auchan-webshop/auchan-vue-client/shop/pages/news/[type]/[slug].vue?macro=true";
import { default as indexaPZPdBwAH8Meta } from "/builds/auchan-webshop/auchan-vue-client/shop/pages/news/index.vue?macro=true";
import { default as indexstb0UBStOOMeta } from "/builds/auchan-webshop/auchan-vue-client/shop/pages/otp/back/index.vue?macro=true";
import { default as _91hash_93f613ahf0wgMeta } from "/builds/auchan-webshop/auchan-vue-client/shop/pages/password_change/[hash].vue?macro=true";
import { default as indexmoOa5K6wi4Meta } from "/builds/auchan-webshop/auchan-vue-client/shop/pages/payment-redirect/index.vue?macro=true";
import { default as _91slug_93exsINfek6jMeta } from "/builds/auchan-webshop/auchan-vue-client/shop/pages/petrol/[slug].vue?macro=true";
import { default as indexdOkavj2Ga3Meta } from "/builds/auchan-webshop/auchan-vue-client/shop/pages/petrol/faq/index.vue?macro=true";
import { default as index20Jmft6mBEMeta } from "/builds/auchan-webshop/auchan-vue-client/shop/pages/petrol/index.vue?macro=true";
import { default as indexp9nYqhEWfCMeta } from "/builds/auchan-webshop/auchan-vue-client/shop/pages/press/index.vue?macro=true";
import { default as indexOM4BQsEmSEMeta } from "/builds/auchan-webshop/auchan-vue-client/shop/pages/prize-game/index.vue?macro=true";
import { default as _91id_93SC8KypjMRvMeta } from "/builds/auchan-webshop/auchan-vue-client/shop/pages/product-list/[id].vue?macro=true";
import { default as _91id_93UnKpLB37yuMeta } from "/builds/auchan-webshop/auchan-vue-client/shop/pages/product/[id].vue?macro=true";
import { default as index1Xl4SnB0mJMeta } from "/builds/auchan-webshop/auchan-vue-client/shop/pages/shop/basket/index.vue?macro=true";
import { default as _91orderIdent_93llvfkc9BAeMeta } from "/builds/auchan-webshop/auchan-vue-client/shop/pages/shop/checkout-success/[orderIdent].vue?macro=true";
import { default as _91type_939994qJtErpMeta } from "/builds/auchan-webshop/auchan-vue-client/shop/pages/shop/checkout/[type].vue?macro=true";
import { default as indexT1A9XLIJ0FMeta } from "/builds/auchan-webshop/auchan-vue-client/shop/pages/shop/compare/index.vue?macro=true";
import { default as indexDEWLEUnVXhMeta } from "/builds/auchan-webshop/auchan-vue-client/shop/pages/shop/index.vue?macro=true";
import { default as _91list_name_93klFtqfOCuyMeta } from "/builds/auchan-webshop/auchan-vue-client/shop/pages/shop/list/[list_name].vue?macro=true";
import { default as _91list_name_938SBkHfIva8Meta } from "/builds/auchan-webshop/auchan-vue-client/shop/pages/shop/loyalty-offers/[list_name].vue?macro=true";
import { default as indexUuzGOkfLsMMeta } from "/builds/auchan-webshop/auchan-vue-client/shop/pages/shop/profile/billing-addresses/index.vue?macro=true";
import { default as indexQYM9PrzK6tMeta } from "/builds/auchan-webshop/auchan-vue-client/shop/pages/shop/profile/data/index.vue?macro=true";
import { default as indexb7ZzSPS25ZMeta } from "/builds/auchan-webshop/auchan-vue-client/shop/pages/shop/profile/delivery-addresses/index.vue?macro=true";
import { default as coupons1Yyy1Chzk8Meta } from "/builds/auchan-webshop/auchan-vue-client/shop/pages/shop/profile/loyalty-card/coupons.vue?macro=true";
import { default as index0smsqHjGu8Meta } from "/builds/auchan-webshop/auchan-vue-client/shop/pages/shop/profile/loyalty-card/index.vue?macro=true";
import { default as partner_45programa8ErvnPhP1Meta } from "/builds/auchan-webshop/auchan-vue-client/shop/pages/shop/profile/loyalty-card/partner-program.vue?macro=true";
import { default as _91id_93eydkC0dyt7Meta } from "/builds/auchan-webshop/auchan-vue-client/shop/pages/shop/profile/loyalty-card/stickers/[id].vue?macro=true";
import { default as indexpdGOGYlASMMeta } from "/builds/auchan-webshop/auchan-vue-client/shop/pages/shop/profile/loyalty-card/stickers/index.vue?macro=true";
import { default as transactionsCS5IxkbmS5Meta } from "/builds/auchan-webshop/auchan-vue-client/shop/pages/shop/profile/loyalty-card/transactions.vue?macro=true";
import { default as indexTZdbLRcO67Meta } from "/builds/auchan-webshop/auchan-vue-client/shop/pages/shop/profile/loyalty-program/index.vue?macro=true";
import { default as indexSJIoRKJDa3Meta } from "/builds/auchan-webshop/auchan-vue-client/shop/pages/shop/profile/mailing-addresses/index.vue?macro=true";
import { default as index09c8jbQ5M1Meta } from "/builds/auchan-webshop/auchan-vue-client/shop/pages/shop/profile/manage/index.vue?macro=true";
import { default as indexdn63mjNNH2Meta } from "/builds/auchan-webshop/auchan-vue-client/shop/pages/shop/profile/order-again/index.vue?macro=true";
import { default as indexiRfuzs4ch7Meta } from "/builds/auchan-webshop/auchan-vue-client/shop/pages/shop/profile/orders/index.vue?macro=true";
import { default as indexfJZKznWE5LMeta } from "/builds/auchan-webshop/auchan-vue-client/shop/pages/shop/profile/saved-baskets/index.vue?macro=true";
import { default as indexhiKwv069G9Meta } from "/builds/auchan-webshop/auchan-vue-client/shop/pages/simple/back/index.vue?macro=true";
import { default as _91slug_93ZtvsjRyB0JMeta } from "/builds/auchan-webshop/auchan-vue-client/shop/pages/stores/[slug].vue?macro=true";
import { default as index9PTqVTy3BMMeta } from "/builds/auchan-webshop/auchan-vue-client/shop/pages/stores/index.vue?macro=true";
import { default as indexmZlieOWKQPMeta } from "/builds/auchan-webshop/auchan-vue-client/shop/pages/test/recon/index.vue?macro=true";
import { default as indexbka0q2CRcEMeta } from "/builds/auchan-webshop/auchan-vue-client/shop/pages/unsubscribe/index.vue?macro=true";
import { default as _91hash_93evWL8kGnylMeta } from "/builds/auchan-webshop/auchan-vue-client/shop/pages/user_email_update/[hash].vue?macro=true";
import { default as component_45stubJWOSpLFoYtMeta } from "/builds/auchan-webshop/auchan-vue-client/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubJWOSpLFoYt } from "/builds/auchan-webshop/auchan-vue-client/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug___hu",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_93CnGEUccaEVMeta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/[...slug].vue")
  },
  {
    name: "slug___en",
    path: "/en/:slug(.*)*",
    meta: _91_46_46_46slug_93CnGEUccaEVMeta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/[...slug].vue")
  },
  {
    name: "activate_career_registration-hash___hu",
    path: "/activate_career_registration/:hash()",
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/activate_career_registration/[hash].vue")
  },
  {
    name: "activate_career_registration-hash___en",
    path: "/en/activate_career_registration/:hash()",
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/activate_career_registration/[hash].vue")
  },
  {
    name: "activate_registration-hash___hu",
    path: "/activate_registration/:hash()",
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/activate_registration/[hash].vue")
  },
  {
    name: "activate_registration-hash___en",
    path: "/en/activate_registration/:hash()",
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/activate_registration/[hash].vue")
  },
  {
    name: "admin-cms-content_preview-hash___hu",
    path: "/admin/cms/content_preview/:hash()",
    meta: _91hash_935FkbKzyJumMeta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/admin/cms/content_preview/[hash].vue")
  },
  {
    name: "admin-cms-content_preview-hash___en",
    path: "/en/admin/cms/content_preview/:hash()",
    meta: _91hash_935FkbKzyJumMeta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/admin/cms/content_preview/[hash].vue")
  },
  {
    name: "ajandekkartya-igenyles___hu",
    path: "/ajandekkartya-igenyles",
    meta: indexHw3mNPiUJUMeta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/ajandekkartya-igenyles/index.vue")
  },
  {
    name: "ajandekkartya-igenyles___en",
    path: "/en/ajandekkartya-igenyles",
    meta: indexHw3mNPiUJUMeta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/ajandekkartya-igenyles/index.vue")
  },
  {
    name: "bm-payment-return___hu",
    path: "/bm-payment-return",
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/bm-payment-return/index.vue")
  },
  {
    name: "bm-payment-return___en",
    path: "/en/bm-payment-return",
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/bm-payment-return/index.vue")
  },
  {
    name: "career_password_change-hash___hu",
    path: "/career_password_change/:hash()",
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/career_password_change/[hash].vue")
  },
  {
    name: "career_password_change-hash___en",
    path: "/en/career_password_change/:hash()",
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/career_password_change/[hash].vue")
  },
  {
    name: "catalog-slug___hu",
    path: "/catalog/:slug()",
    meta: _91slug_931x8MXj2efwMeta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/catalog/[slug].vue")
  },
  {
    name: "catalog-slug___en",
    path: "/en/catalog/:slug()",
    meta: _91slug_931x8MXj2efwMeta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/catalog/[slug].vue")
  },
  {
    name: "catalog___hu",
    path: "/catalog",
    meta: index6lqO7wcwQzMeta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/catalog/index.vue")
  },
  {
    name: "catalog___en",
    path: "/en/catalog",
    meta: index6lqO7wcwQzMeta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/catalog/index.vue")
  },
  {
    name: "cetelem-back-order_ident-status___hu",
    path: "/cetelem/back/:order_ident()/:status()",
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/cetelem/back/[order_ident]/[status].vue")
  },
  {
    name: "cetelem-back-order_ident-status___en",
    path: "/en/cetelem/back/:order_ident()/:status()",
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/cetelem/back/[order_ident]/[status].vue")
  },
  {
    name: "cetelem-back___hu",
    path: "/cetelem/back",
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/cetelem/back/index.vue")
  },
  {
    name: "cetelem-back___en",
    path: "/en/cetelem/back",
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/cetelem/back/index.vue")
  },
  {
    name: "content-id___hu",
    path: "/content/:id()",
    meta: _91id_93K1u3taZVxGMeta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/content/[id].vue")
  },
  {
    name: "content-id___en",
    path: "/en/content/:id()",
    meta: _91id_93K1u3taZVxGMeta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/content/[id].vue")
  },
  {
    name: "csr___hu",
    path: "/csr",
    meta: indexyTuJAy6PzWMeta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/csr/index.vue")
  },
  {
    name: "csr___en",
    path: "/en/csr",
    meta: indexyTuJAy6PzWMeta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/csr/index.vue")
  },
  {
    name: "faq___hu",
    path: "/faq",
    meta: indexVjrDLw9WVZMeta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/faq/index.vue")
  },
  {
    name: "faq___en",
    path: "/en/faq",
    meta: indexVjrDLw9WVZMeta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/faq/index.vue")
  },
  {
    name: "food-delivery-map___hu",
    path: "/food-delivery-map",
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/food-delivery-map/index.vue")
  },
  {
    name: "food-delivery-map___en",
    path: "/en/food-delivery-map",
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/food-delivery-map/index.vue")
  },
  {
    name: "forgotten-password___hu",
    path: "/forgotten-password",
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/forgotten-password/index.vue")
  },
  {
    name: "forgotten-password___en",
    path: "/en/forgotten-password",
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/forgotten-password/index.vue")
  },
  {
    name: "index___hu",
    path: "/",
    meta: indexS6ZlsvNoiEMeta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    meta: indexS6ZlsvNoiEMeta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/index.vue")
  },
  {
    name: "inspirations___hu",
    path: "/inspirations",
    meta: indexoln1fpJS2RMeta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/inspirations/index.vue")
  },
  {
    name: "inspirations___en",
    path: "/en/inspirations",
    meta: indexoln1fpJS2RMeta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/inspirations/index.vue")
  },
  {
    name: "karrier-ajanlatok-offerId___hu",
    path: "/karrier/ajanlatok/:offerId()",
    meta: _91offerId_93CWsbRD9lPCMeta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/karrier/ajanlatok/[offerId].vue")
  },
  {
    name: "karrier-ajanlatok-offerId___en",
    path: "/en/karrier/ajanlatok/:offerId()",
    meta: _91offerId_93CWsbRD9lPCMeta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/karrier/ajanlatok/[offerId].vue")
  },
  {
    name: "karrier-allas-slug.j-id-hash___hu",
    path: "/karrier/allas/:slug().j-:id()/:hash()",
    meta: _91hash_93VvTjzwZDZyMeta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/karrier/allas/[slug].j-[id]/[hash].vue")
  },
  {
    name: "karrier-allas-slug.j-id-hash___en",
    path: "/en/karrier/allas/:slug().j-:id()/:hash()",
    meta: _91hash_93VvTjzwZDZyMeta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/karrier/allas/[slug].j-[id]/[hash].vue")
  },
  {
    name: "karrier-allas-slug.j-id___hu",
    path: "/karrier/allas/:slug().j-:id()",
    meta: indexbyQjBZTRReMeta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/karrier/allas/[slug].j-[id]/index.vue")
  },
  {
    name: "karrier-allas-slug.j-id___en",
    path: "/en/karrier/allas/:slug().j-:id()",
    meta: indexbyQjBZTRReMeta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/karrier/allas/[slug].j-[id]/index.vue")
  },
  {
    name: "karrier-allasok-premise___hu",
    path: "/karrier/allasok/:premise?",
    meta: _91_91premise_93_93wUlRw5LPqZMeta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/karrier/allasok/[[premise]].vue")
  },
  {
    name: "karrier-allasok-premise___en",
    path: "/en/karrier/allasok/:premise?",
    meta: _91_91premise_93_93wUlRw5LPqZMeta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/karrier/allasok/[[premise]].vue")
  },
  {
    name: "karrier-fiokom___hu",
    path: "/karrier/fiokom",
    meta: indexTNVNvEpdQSMeta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/karrier/fiokom/index.vue")
  },
  {
    name: "karrier-fiokom___en",
    path: "/en/karrier/fiokom",
    meta: indexTNVNvEpdQSMeta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/karrier/fiokom/index.vue")
  },
  {
    name: "karrier-hirek___hu",
    path: "/karrier/hirek",
    meta: indexFxFxosEbbaMeta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/karrier/hirek/index.vue")
  },
  {
    name: "karrier-hirek___en",
    path: "/en/karrier/hirek",
    meta: indexFxFxosEbbaMeta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/karrier/hirek/index.vue")
  },
  {
    name: "login-Salesforce___hu",
    path: "/login/Salesforce",
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/login/Salesforce.vue")
  },
  {
    name: "login-Salesforce___en",
    path: "/en/login/Salesforce",
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/login/Salesforce.vue")
  },
  {
    name: "loyalty-program-faq___hu",
    path: "/loyalty-program/faq",
    meta: indexFSlhQvp53dMeta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/loyalty-program/faq/index.vue")
  },
  {
    name: "loyalty-program-faq___en",
    path: "/en/loyalty-program/faq",
    meta: indexFSlhQvp53dMeta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/loyalty-program/faq/index.vue")
  },
  {
    name: "news-type-slug___hu",
    path: "/news/:type()/:slug()",
    meta: _91slug_93o0BUWPwS6OMeta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/news/[type]/[slug].vue")
  },
  {
    name: "news-type-slug___en",
    path: "/en/news/:type()/:slug()",
    meta: _91slug_93o0BUWPwS6OMeta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/news/[type]/[slug].vue")
  },
  {
    name: "news___hu",
    path: "/news",
    meta: indexaPZPdBwAH8Meta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/news/index.vue")
  },
  {
    name: "news___en",
    path: "/en/news",
    meta: indexaPZPdBwAH8Meta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/news/index.vue")
  },
  {
    name: "otp-back___hu",
    path: "/otp/back",
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/otp/back/index.vue")
  },
  {
    name: "otp-back___en",
    path: "/en/otp/back",
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/otp/back/index.vue")
  },
  {
    name: "password_change-hash___hu",
    path: "/password_change/:hash()",
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/password_change/[hash].vue")
  },
  {
    name: "password_change-hash___en",
    path: "/en/password_change/:hash()",
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/password_change/[hash].vue")
  },
  {
    name: "payment-redirect___hu",
    path: "/payment-redirect",
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/payment-redirect/index.vue")
  },
  {
    name: "payment-redirect___en",
    path: "/en/payment-redirect",
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/payment-redirect/index.vue")
  },
  {
    name: "petrol-slug___hu",
    path: "/petrol/:slug()",
    meta: _91slug_93exsINfek6jMeta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/petrol/[slug].vue")
  },
  {
    name: "petrol-slug___en",
    path: "/en/petrol/:slug()",
    meta: _91slug_93exsINfek6jMeta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/petrol/[slug].vue")
  },
  {
    name: "petrol-faq___hu",
    path: "/petrol/faq",
    meta: indexdOkavj2Ga3Meta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/petrol/faq/index.vue")
  },
  {
    name: "petrol-faq___en",
    path: "/en/petrol/faq",
    meta: indexdOkavj2Ga3Meta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/petrol/faq/index.vue")
  },
  {
    name: "petrol___hu",
    path: "/petrol",
    meta: index20Jmft6mBEMeta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/petrol/index.vue")
  },
  {
    name: "petrol___en",
    path: "/en/petrol",
    meta: index20Jmft6mBEMeta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/petrol/index.vue")
  },
  {
    name: "press___hu",
    path: "/press",
    meta: indexp9nYqhEWfCMeta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/press/index.vue")
  },
  {
    name: "press___en",
    path: "/en/press",
    meta: indexp9nYqhEWfCMeta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/press/index.vue")
  },
  {
    name: "prize-game___hu",
    path: "/prize-game",
    meta: indexOM4BQsEmSEMeta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/prize-game/index.vue")
  },
  {
    name: "prize-game___en",
    path: "/en/prize-game",
    meta: indexOM4BQsEmSEMeta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/prize-game/index.vue")
  },
  {
    name: "product-list-id___hu",
    path: "/product-list/:id()",
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/product-list/[id].vue")
  },
  {
    name: "product-list-id___en",
    path: "/en/product-list/:id()",
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/product-list/[id].vue")
  },
  {
    name: "product-id___hu",
    path: "/product/:id()",
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/product/[id].vue")
  },
  {
    name: "product-id___en",
    path: "/en/product/:id()",
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/product/[id].vue")
  },
  {
    name: "shop-basket___hu",
    path: "/shop/basket",
    meta: index1Xl4SnB0mJMeta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/shop/basket/index.vue")
  },
  {
    name: "shop-basket___en",
    path: "/en/shop/basket",
    meta: index1Xl4SnB0mJMeta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/shop/basket/index.vue")
  },
  {
    name: "shop-checkout-success-orderIdent___hu",
    path: "/shop/checkout-success/:orderIdent()",
    meta: _91orderIdent_93llvfkc9BAeMeta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/shop/checkout-success/[orderIdent].vue")
  },
  {
    name: "shop-checkout-success-orderIdent___en",
    path: "/en/shop/checkout-success/:orderIdent()",
    meta: _91orderIdent_93llvfkc9BAeMeta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/shop/checkout-success/[orderIdent].vue")
  },
  {
    name: "shop-checkout-type___hu",
    path: "/shop/checkout/:type()",
    meta: _91type_939994qJtErpMeta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/shop/checkout/[type].vue")
  },
  {
    name: "shop-checkout-type___en",
    path: "/en/shop/checkout/:type()",
    meta: _91type_939994qJtErpMeta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/shop/checkout/[type].vue")
  },
  {
    name: "shop-compare___hu",
    path: "/shop/compare",
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/shop/compare/index.vue")
  },
  {
    name: "shop-compare___en",
    path: "/en/shop/compare",
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/shop/compare/index.vue")
  },
  {
    name: "shop___hu",
    path: "/shop",
    meta: indexDEWLEUnVXhMeta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/shop/index.vue")
  },
  {
    name: "shop___en",
    path: "/en/shop",
    meta: indexDEWLEUnVXhMeta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/shop/index.vue")
  },
  {
    name: "shop-list-list_name___hu",
    path: "/shop/list/:list_name()",
    meta: _91list_name_93klFtqfOCuyMeta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/shop/list/[list_name].vue")
  },
  {
    name: "shop-list-list_name___en",
    path: "/en/shop/list/:list_name()",
    meta: _91list_name_93klFtqfOCuyMeta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/shop/list/[list_name].vue")
  },
  {
    name: "shop-loyalty-offers-list_name___hu",
    path: "/shop/loyalty-offers/:list_name()",
    meta: _91list_name_938SBkHfIva8Meta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/shop/loyalty-offers/[list_name].vue")
  },
  {
    name: "shop-loyalty-offers-list_name___en",
    path: "/en/shop/loyalty-offers/:list_name()",
    meta: _91list_name_938SBkHfIva8Meta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/shop/loyalty-offers/[list_name].vue")
  },
  {
    name: "shop-profile-billing-addresses___hu",
    path: "/shop/profile/billing-addresses",
    meta: indexUuzGOkfLsMMeta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/shop/profile/billing-addresses/index.vue")
  },
  {
    name: "shop-profile-billing-addresses___en",
    path: "/en/shop/profile/billing-addresses",
    meta: indexUuzGOkfLsMMeta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/shop/profile/billing-addresses/index.vue")
  },
  {
    name: "shop-profile-data___hu",
    path: "/shop/profile/data",
    meta: indexQYM9PrzK6tMeta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/shop/profile/data/index.vue")
  },
  {
    name: "shop-profile-data___en",
    path: "/en/shop/profile/data",
    meta: indexQYM9PrzK6tMeta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/shop/profile/data/index.vue")
  },
  {
    name: "shop-profile-delivery-addresses___hu",
    path: "/shop/profile/delivery-addresses",
    meta: indexb7ZzSPS25ZMeta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/shop/profile/delivery-addresses/index.vue")
  },
  {
    name: "shop-profile-delivery-addresses___en",
    path: "/en/shop/profile/delivery-addresses",
    meta: indexb7ZzSPS25ZMeta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/shop/profile/delivery-addresses/index.vue")
  },
  {
    name: "shop-profile-loyalty-card-coupons___hu",
    path: "/shop/profile/loyalty-card/coupons",
    meta: coupons1Yyy1Chzk8Meta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/shop/profile/loyalty-card/coupons.vue")
  },
  {
    name: "shop-profile-loyalty-card-coupons___en",
    path: "/en/shop/profile/loyalty-card/coupons",
    meta: coupons1Yyy1Chzk8Meta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/shop/profile/loyalty-card/coupons.vue")
  },
  {
    name: "shop-profile-loyalty-card___hu",
    path: "/shop/profile/loyalty-card",
    meta: index0smsqHjGu8Meta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/shop/profile/loyalty-card/index.vue")
  },
  {
    name: "shop-profile-loyalty-card___en",
    path: "/en/shop/profile/loyalty-card",
    meta: index0smsqHjGu8Meta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/shop/profile/loyalty-card/index.vue")
  },
  {
    name: "shop-profile-loyalty-card-partner-program___hu",
    path: "/shop/profile/loyalty-card/partner-program",
    meta: partner_45programa8ErvnPhP1Meta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/shop/profile/loyalty-card/partner-program.vue")
  },
  {
    name: "shop-profile-loyalty-card-partner-program___en",
    path: "/en/shop/profile/loyalty-card/partner-program",
    meta: partner_45programa8ErvnPhP1Meta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/shop/profile/loyalty-card/partner-program.vue")
  },
  {
    name: "shop-profile-loyalty-card-stickers-id___hu",
    path: "/shop/profile/loyalty-card/stickers/:id()",
    meta: _91id_93eydkC0dyt7Meta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/shop/profile/loyalty-card/stickers/[id].vue")
  },
  {
    name: "shop-profile-loyalty-card-stickers-id___en",
    path: "/en/shop/profile/loyalty-card/stickers/:id()",
    meta: _91id_93eydkC0dyt7Meta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/shop/profile/loyalty-card/stickers/[id].vue")
  },
  {
    name: "shop-profile-loyalty-card-stickers___hu",
    path: "/shop/profile/loyalty-card/stickers",
    meta: indexpdGOGYlASMMeta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/shop/profile/loyalty-card/stickers/index.vue")
  },
  {
    name: "shop-profile-loyalty-card-stickers___en",
    path: "/en/shop/profile/loyalty-card/stickers",
    meta: indexpdGOGYlASMMeta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/shop/profile/loyalty-card/stickers/index.vue")
  },
  {
    name: "shop-profile-loyalty-card-transactions___hu",
    path: "/shop/profile/loyalty-card/transactions",
    meta: transactionsCS5IxkbmS5Meta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/shop/profile/loyalty-card/transactions.vue")
  },
  {
    name: "shop-profile-loyalty-card-transactions___en",
    path: "/en/shop/profile/loyalty-card/transactions",
    meta: transactionsCS5IxkbmS5Meta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/shop/profile/loyalty-card/transactions.vue")
  },
  {
    name: "shop-profile-loyalty-program___hu",
    path: "/shop/profile/loyalty-program",
    meta: indexTZdbLRcO67Meta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/shop/profile/loyalty-program/index.vue")
  },
  {
    name: "shop-profile-loyalty-program___en",
    path: "/en/shop/profile/loyalty-program",
    meta: indexTZdbLRcO67Meta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/shop/profile/loyalty-program/index.vue")
  },
  {
    name: "shop-profile-mailing-addresses___hu",
    path: "/shop/profile/mailing-addresses",
    meta: indexSJIoRKJDa3Meta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/shop/profile/mailing-addresses/index.vue")
  },
  {
    name: "shop-profile-mailing-addresses___en",
    path: "/en/shop/profile/mailing-addresses",
    meta: indexSJIoRKJDa3Meta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/shop/profile/mailing-addresses/index.vue")
  },
  {
    name: "shop-profile-manage___hu",
    path: "/shop/profile/manage",
    meta: index09c8jbQ5M1Meta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/shop/profile/manage/index.vue")
  },
  {
    name: "shop-profile-manage___en",
    path: "/en/shop/profile/manage",
    meta: index09c8jbQ5M1Meta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/shop/profile/manage/index.vue")
  },
  {
    name: "shop-profile-order-again___hu",
    path: "/shop/profile/order-again",
    meta: indexdn63mjNNH2Meta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/shop/profile/order-again/index.vue")
  },
  {
    name: "shop-profile-order-again___en",
    path: "/en/shop/profile/order-again",
    meta: indexdn63mjNNH2Meta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/shop/profile/order-again/index.vue")
  },
  {
    name: "shop-profile-orders___hu",
    path: "/shop/profile/orders",
    meta: indexiRfuzs4ch7Meta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/shop/profile/orders/index.vue")
  },
  {
    name: "shop-profile-orders___en",
    path: "/en/shop/profile/orders",
    meta: indexiRfuzs4ch7Meta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/shop/profile/orders/index.vue")
  },
  {
    name: "shop-profile-saved-baskets___hu",
    path: "/shop/profile/saved-baskets",
    meta: indexfJZKznWE5LMeta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/shop/profile/saved-baskets/index.vue")
  },
  {
    name: "shop-profile-saved-baskets___en",
    path: "/en/shop/profile/saved-baskets",
    meta: indexfJZKznWE5LMeta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/shop/profile/saved-baskets/index.vue")
  },
  {
    name: "simple-back___hu",
    path: "/simple/back",
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/simple/back/index.vue")
  },
  {
    name: "simple-back___en",
    path: "/en/simple/back",
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/simple/back/index.vue")
  },
  {
    name: "stores-slug___hu",
    path: "/stores/:slug()",
    meta: _91slug_93ZtvsjRyB0JMeta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/stores/[slug].vue")
  },
  {
    name: "stores-slug___en",
    path: "/en/stores/:slug()",
    meta: _91slug_93ZtvsjRyB0JMeta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/stores/[slug].vue")
  },
  {
    name: "stores___hu",
    path: "/stores",
    meta: index9PTqVTy3BMMeta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/stores/index.vue")
  },
  {
    name: "stores___en",
    path: "/en/stores",
    meta: index9PTqVTy3BMMeta || {},
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/stores/index.vue")
  },
  {
    name: "test-recon___hu",
    path: "/test/recon",
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/test/recon/index.vue")
  },
  {
    name: "test-recon___en",
    path: "/en/test/recon",
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/test/recon/index.vue")
  },
  {
    name: "unsubscribe___hu",
    path: "/unsubscribe",
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/unsubscribe/index.vue")
  },
  {
    name: "unsubscribe___en",
    path: "/en/unsubscribe",
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/unsubscribe/index.vue")
  },
  {
    name: "user_email_update-hash___hu",
    path: "/user_email_update/:hash()",
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/user_email_update/[hash].vue")
  },
  {
    name: "user_email_update-hash___en",
    path: "/en/user_email_update/:hash()",
    component: () => import("/builds/auchan-webshop/auchan-vue-client/shop/pages/user_email_update/[hash].vue")
  },
  {
    name: component_45stubJWOSpLFoYtMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubJWOSpLFoYt
  }
]