import { defineNuxtPlugin } from 'nuxt/app';

const ATTR = 'data-aw-lazy-media';
let queue = [];

export default defineNuxtPlugin(function (nuxtApp) {
  nuxtApp.vueApp.directive('awLazyMedia', {
    beforeMount (el) {
      if (el.getAttribute(ATTR) === '1') {
        return;
      } else if (el.complete) {
        el.setAttribute(ATTR, '1');
      } else {
        el.setAttribute(ATTR, '0');
        el.addEventListener('load', onLoad, { once: true });
        el.addEventListener('error', onLoad, { once: true });
      }
    },
    getSSRProps () {
      return {
        [ATTR]: '0',
      };
    },
  });
});
function onLoad ({ target }) {
  target.removeEventListener('load', onLoad);
  target.removeEventListener('error', onLoad);
  if (!queue.length) {
    requestAnimationFrame(() => {
      queue.forEach(cb => cb());
      queue = [];
    });
  }
  queue.push(() => {
    target.setAttribute(ATTR, '1');
  });
}
