import { defineNuxtPlugin, useRequestEvent } from 'nuxt/app';
import { awCookieUniversalFactory } from '~~/common/server/awCookies.js';

export default defineNuxtPlugin(function (nuxtApp) {
  const event = useRequestEvent(nuxtApp) || {};
  const cookiesPlugin = awCookieUniversalFactory({
    req: event?.node?.req,
    res: event?.node?.res,
    awIsSecureByDefault: nuxtApp.$awRuntimeConfig.auchanApiBrowserHost.startsWith('https'),
  });
  // TODO: For compat reasons cookiesPlugin is injected as '$cookies'. May
  // want to add an 'aw' prefix in the future.
  nuxtApp.provide('cookies', cookiesPlugin);
});
