<template>
  <component :is="tag" :class="[$style.lvSpring, $style[size], $style[`${gutter}Gutter`]]">
    <slot />
  </component>
</template>

<script>
  // todo find better name for version2 size
  const validSizes = ['xs', 'sm', 'md', 'lg', 'xl', 'fullWithSkyBanner', 'full-width'];
  const validGutters = ['xs', 'sm', 'md', 'lg', 'xl'];

  export default {
    name: 'LvSpring',
    props: {
      tag: {
        type: String,
        default: 'div',
      },
      size: {
        type: String,
        required: false,
        default: 'lg',
        validator: value => validSizes.includes(value),
      },
      gutter: {
        type: String,
        required: false,
        default: undefined,
        validator: value => validGutters.includes(value),
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
/* region Base */
.lvSpring {
  max-width: $lv-spring-default-width;
  margin: 0 auto;
}

/* endregion */

/* region Sizes */
.xs {
  @if ($lv-spring-xs-width-xs) {
    max-width: $lv-spring-xs-width-xs;
  }

  @if ($lv-spring-xs-width-sm) {
    @include sm() {
      max-width: $lv-spring-xs-width-sm;
    }
  }

  @if ($lv-spring-xs-width-md) {
    @include md() {
      max-width: $lv-spring-xs-width-md;
    }
  }

  @if ($lv-spring-xs-width-lg) {
    @include lg() {
      max-width: $lv-spring-xs-width-lg;
    }
  }

  @if ($lv-spring-xs-width-xl) {
    @include xl() {
      max-width: $lv-spring-xs-width-xl;
    }
  }
}

.sm {
  @if ($lv-spring-sm-width-xs) {
    max-width: $lv-spring-sm-width-xs;
  }

  @if ($lv-spring-sm-width-sm) {
    @include sm() {
      max-width: $lv-spring-sm-width-sm;
    }
  }

  @if ($lv-spring-sm-width-md) {
    @include md() {
      max-width: $lv-spring-sm-width-md;
    }
  }

  @if ($lv-spring-sm-width-lg) {
    @include lg() {
      max-width: $lv-spring-sm-width-lg;
    }
  }

  @if ($lv-spring-sm-width-xl) {
    @include xl() {
      max-width: $lv-spring-sm-width-xl;
    }
  }
}

.md {
  @if ($lv-spring-md-width-xs) {
    max-width: $lv-spring-md-width-xs;
  }

  @if ($lv-spring-md-width-sm) {
    @include sm() {
      max-width: $lv-spring-md-width-sm;
    }
  }

  @if ($lv-spring-md-width-md) {
    @include md() {
      max-width: $lv-spring-md-width-md;
    }
  }

  @if ($lv-spring-md-width-lg) {
    @include lg() {
      max-width: $lv-spring-md-width-lg;
    }
  }

  @if ($lv-spring-md-width-xl) {
    @include xl() {
      max-width: $lv-spring-md-width-xl;
    }
  }
}

.fullWithSkyBanner {

  @if ($lv-spring-fullWithSkyBanner-width-xs) {
    max-width: $lv-spring-fullWithSkyBanner-width-xs;
  }

  @if ($lv-spring-fullWithSkyBanner-width-sm) {
    @include sm() {
      max-width: $lv-spring-fullWithSkyBanner-width-sm;
    }
  }

  @if ($lv-spring-fullWithSkyBanner-width-md) {
    @include md() {
      max-width: $lv-spring-fullWithSkyBanner-width-md;
    }
  }

  @if ($lv-spring-fullWithSkyBanner-width-lg) {
    @include lg() {
      max-width: $lv-spring-fullWithSkyBanner-width-lg;
    }
  }

  @if ($lv-spring-fullWithSkyBanner-width-xl) {
    @include xl() {
      max-width: $lv-spring-fullWithSkyBanner-width-xl;
    }
  }
}

.lg {
  @if ($lv-spring-lg-width-xs) {
    max-width: $lv-spring-lg-width-xs;
  }

  @if ($lv-spring-lg-width-sm) {
    @include sm() {
      max-width: $lv-spring-lg-width-sm;
    }
  }

  @if ($lv-spring-lg-width-md) {
    @include md() {
      max-width: $lv-spring-lg-width-md;
    }
  }

  @if ($lv-spring-lg-width-lg) {
    @include lg() {
      max-width: $lv-spring-lg-width-lg;
    }
  }

  @if ($lv-spring-lg-width-xl) {
    @include xl() {
      max-width: $lv-spring-lg-width-xl;
    }
  }
}

.full-width {
  @if ($lv-spring-fullWidth-width-xs) {
    max-width: $lv-spring-fullWidth-width-xs;
  }

  @if ($lv-spring-fullWidth-width-sm) {
    @include mobile(min) {
      max-width: $lv-spring-fullWidth-width-sm;
    }
  }

  @if ($lv-spring-fullWidth-width-md) {
    @include tablet(min) {
      max-width: $lv-spring-fullWidth-width-md;
    }
  }

  @if ($lv-spring-fullWidth-width-lg) {
    @include desktop-medium(min) {
      max-width: $lv-spring-fullWidth-width-lg;
    }
  }

  @if ($lv-spring-fullWidth-width-xl) {
    @include desktop-large(min) {
      max-width: $lv-spring-fullWidth-width-xl;
    }
  }
}

/* endregion */

/* region Gutters */
.xsGutter {
  padding: $lv-spring-gutter-xs;
}

.smGutter {
  padding: $lv-spring-gutter-sm;
}

.mdGutter {
  padding: $lv-spring-gutter-md;
}

.lgGutter {
  padding: $lv-spring-gutter-lg;
}

.xlGutter {
  padding: $lv-spring-gutter-xl;
}

/* endregion */
</style>
